import React from 'react'
import CopyRight from './CopyRight'
function FooterVisitor() {
    return (
        <div className='w-full flex flex-col justify-center items-center  bg-white'>

            <svg className=" relative  " preserveAspectRatio="none" viewBox="0 0 100 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M0,10  C20,35 40,5 60,15 C80,25 90.5,0 100,15 L100,30 L0,30 Z" fill="orange"></path>
            </svg>


            <div className="bg-[orange] w-full h-max p-2 flex justify-around items-center z-10 font-medium responsive3">
                <div className="flex flex-col  p-2 items-start leading-[1.6em] max-w rounded responsive31">
                    <h2 className='font-bold font-sans underline cursor-default'>Branche</h2>
                    <button className='flex w-full p-1 transition-all hover:underline'>Education</button>
                    <button className='flex w-full p-1 transition-all hover:underline'>Publicité</button>
                    <button className='flex w-full p-1 transition-all hover:underline'>Sante</button>
                    <button className='flex w-full p-1 transition-all hover:underline'>Programmation</button>
                    <button className='flex w-full p-1 transition-all hover:underline'>Transport</button>
                    <button className='flex w-full p-1 transition-all hover:underline'>Mentors</button>
                    <button className='flex w-full p-1 transition-all hover:underline'>Agriculture et Elevage</button>
                </div>
                <div className="flex flex-col  p-2 items-start leading-[1.6em] max-w rounded responsive31">
                    <h2 className='font-bold font-sans underline cursor-default'>Ressource</h2>
                    <button className='flex w-full p-1 transition-all rounded hover:underline'>Partenaire</button>
                    <button className='flex w-full p-1 transition-all rounded hover:underline'>Devellopeurs</button>
                    <button className='flex w-full p-1 transition-all rounded hover:underline'>Communaute</button>
                    <button className='flex w-full p-1 transition-all rounded hover:underline'>Application</button>
                    <button className='flex w-full p-1 transition-all rounded hover:underline'>Evenement</button>
                </div>

                <div className="flex flex-col  p-2 items-start leading-[1.6em] max-w rounded responsive31">
                    <h2 className='font-bold font-sans underline cursor-default'>Contactez-nous</h2>
                    <button className='flex w-full p-1 footers'><b className='mr-2'>Email: </b> <a href="mailto:contact@burundientempsreel.com" target='_blank'>contact@burundientempsreel.com</a></button>
                    <button className='flex w-full p-1 footers'><b className='mr-2'>FaceBook:</b><a href="https://www.facebook.com/BurundienTempReel" target='_blank'>Burundi en temps Réel</a></button>
                    <button className=' w-full p-1 footers hidden'><b className='mr-2 '>Instagram:</b><a href="#" target='_blank transition-all'>Burundi en temp reel</a></button>
                    <button className='flex w-full p-1 footers'><b className='mr-2'>Youtube:</b><a href="https://www.youtube.com/@Burundientempsreel" target='_blank'>Burundi en temps réel</a></button>
                </div>
            </div>
            <CopyRight />
        </div>
    )
}

export default FooterVisitor
