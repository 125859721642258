import React from 'react'

import StandByAdminMArket from './StandByAdminMArket'
function StandByAdminMArketComponent() {
    return (
        <>
            <div class="p-2">
                <StandByAdminMArket />
            </div>
        </>
    )
}

export default StandByAdminMArketComponent
