import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select';

const Options = [
    { value: 'Tous catégorie', label: 'Tous catégorie' },
    { value: 'Littérature classique', label: 'Littérature classique' },
    { value: ' Enfants et jeunes', label: ' Enfants et jeunes ' },
    { value: 'Livres académiques ', label: 'Livres académiques ' },
    { value: 'Sciences et de technologie ', label: 'Sciences et de technologie ' },
    { value: 'Voyage et de découverte', label: 'Voyage et de découverte' },
    { value: ' Santé et le bien-être', label: ' Santé et le bien-être' },
    { value: 'Cuisine et de gastronomie', label: 'Cuisine et de gastronomie' },
    { value: ' Religieux et spirituels', label: ' Religieux et spirituels' },
    { value: 'Loisirs et de passe-temps', label: 'Loisirs et de passe-temps' },
    { value: 'Sciences humaines', label: 'Sciences humaines' }
]

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        border: '2px solid orange',
        borderRadius: '5px',
        fontSize: '20px',
        backgroundColor: "transparent",
        width: '100%',
        borderBottom: state.isFocused ? ' ' : '',
        '&:hover': {
            borderBottom: state.isFocused ? '2px solid orange' : '2px solid orange',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '15px',
        backgroundColor: state.isSelected ? 'orange' : 'transparent',
        color: state.isSelected ? 'white' : '#666',
        '&:hover': {
            color: state.isSelected ? 'white' : '#666',
            backgroundColor: state.isSelected ? 'orange' : '#f5f5f5'

        },
        position: 'static',
        zIndex: '10000',
    }),
};


function EdicationConnaissVisitor({ displayPage }) {

    const defaultValue = Options[0]
    const [SelectValue, SetSelectValue] = useState([])

    return (
        <div
            style={{
                backgroundImage: "url('../image/bibli.jpg')",
                backgroundAttachment: "fixed",
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover'
            }}
            className='flex flex-col items-center '
        >

            <div className="  flex w-full sloger justify-around p-2 responsive1">
                <div className="w-[45%] fulls rounded-2xl overflow-hidden  livreadmimg">
                    <img draggable='false' src="../../image/BibliothequeGene.jpg" alt="" className='w-full h-full block object-cover object-center' />
                </div>
                <div className="bg-gray-200 w-[45%] fulls  flex justify-around  font-serif rounded p-2 flex-col livreadm">
                    <div className="text-[30px] font-serif text-center"> <span className="mot1">Un trésor de connai</span><span className="mot3">ssances gén</span><span className="mot2">érales à portée de clic</span></div>
                    <div className="text-[16px] ">
                        Ici, tu trouveras une multitude de ressources éducatives couvrant une vaste gamme de sujets, allant des sciences et de l'histoire à la littérature et aux arts. Notre objectif est de fournir un espace où tu pourras explorer, apprendre et grandir en acquérant de nouvelles connaissances et en élargissant ton horizon.<br /><br />
                        Que tu sois un étudiant avide de savoir, un amateur de culture ou simplement curieux d'apprendre, notre bibliothèque en ligne est conçue pour t'aider à satisfaire ta soif de connaissance. Navigue à travers nos collections soigneusement sélectionnées et plonge-toi dans des univers fascinants et enrichissants.
                    </div>
                    <div className="text-[15px] text-gray-400 w-full text-right font-serif">Burundi en temp réel</div>
                </div>

            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50" className='hidden'>
                <ellipse cx="50" cy="0" rx="50" ry="10" fill="currentColor" />
            </svg>
            <div className="flex flex-col w-[99%] items-center ">
                <div className="list bg-slate-100 w-[95%]  flex flex-col p-4 items-center fulls">
                    <div className="w-full flex justify-end">
                        <div className="pr-10  p-1 m-1 rounded search ">
                            <p className='text-gray-500 font-serif'>Rechercher par votre choix</p>
                            <div className="search py-1 w-full search ">
                                <form className='flex  w-[100%] '>
                                    <input type="text" className='p-1 search border border-blue-700 rounded transition-all outline-none focus:border-2 focus:border-blue-500' id="" placeholder='Rechercher...' />
                                    <label htmlFor='search' className='transition-all bg-blue-400 text-white hover:bg-blue-600 p-2 ml-1 cursor-pointer rounded'><i className='bi bi-search'></i></label>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="text-[22px] mot2 flex-wrap flex justify-between py-2 bg-gray-200 pl-2 w-full">
                        <p className='mb-5'>Tous les  catégories</p>
                        <div className="text-black  p-2 navFullScreen w-[13em]">
                            <Select isClearable={false} onChange={(e) => { SetSelectValue(e.value) }} closeMenuOnSelect={true} Options={SelectValue} defaultValue={defaultValue} isSearchable={false} options={Options} styles={customStyles}>
                            </Select>
                        </div>
                    </div>

                    <div className="flex flex-wrap w-full justify-around fulls">

                        <div className="flex p-1 m-1 categori fulls">
                            <div className=" w-[100%] bg-gray-300 rounded overflow-hidden ">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-left '>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg  w-max'>Titre: <span className='text-[10px]'>Fiction</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg  w-max'>Catégorie: <span className='text-[10px]'>Développement personnel</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>Description: <span className='text-[10px]'>La richesse intellectuel est pour les jeunes</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>Pages: <span className='text-[20px]'>{displayPage} <span className='text-[10px]'>pages</span></span> </h2>
                                    <div className="bg-slate-400  p-1 flex justify-around ">
                                        <Link to='/Visit/Livres/Connaiss/Lire' className='w-[30%]  h-max bg-blue-400 text-center rounded hover:bg-blue-600 text-white transition-all'>Lire</Link>
                                        <Link to="/Visit/Livres/Connaiss/Detail" className='w-[30%]  h-max bg-blue-400 rounded text-center hover:bg-blue-600 text-white transition-all'>Detail</Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <div className=" w-[100%] bg-gray-300 rounded overflow-hidden ">
                                <div className="w-[100%] rounded overflow-hidden relative">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                    <span className=" rotationsLive font-serif">✴ Prime</span>
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-left '>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg  w-max'>Titre: <span className='text-[10px]'>Non-fiction</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg  w-max'>Catégorie: <span className='text-[10px]'>Développement personnel</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>Description: <span className='text-[10px]'>La richesse intellectuel est pour les jeunes</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>Cout: <span className=''>5€ <span><s className='text-[red] ml-2'>7€</s></span></span> </h2>

                                    <div className="bg-slate-400  p-1 flex justify-around items-center">
                                        <Link to="#" className='h-max p-1 bg-blue-400 text-center rounded hover:bg-blue-600 text-white transition-all'>Ajouter au panier</Link>
                                        <Link to="/Visit/Livres/Connaiss/PremDetail" className='w-[30%]  h-max bg-blue-400 text-center rounded hover:bg-blue-600 text-white transition-all'>Detail</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-max bg-gray-200 ">
                        <div className="w-full flex justify-around items-center p-2">
                            <button
                                className="transition-all   w-[30px] mr-2 h-[30px] flex cursor-pointer justify-center rounded overflow-hidden items-center"
                            >
                                <a href="#" className=" hover:bg-blue-300 hover:text-white w-full h-full  text-[30px] flex cursor-pointer justify-center items-center pb-2">
                                    &laquo;
                                </a>

                            </button>
                            <button className=" w-[30px] h-[30px] text-[white] bg-blue-600  m-[1px] rounded-full">1</button>
                            <button className="bg-gray-400 w-[30px] h-[30px] text-[white]  m-[1px] rounded-full">2</button>
                            <button className="bg-gray-400 w-[30px] h-[30px] text-[white]  m-[1px] rounded-full">3</button>
                            <button

                                className="transition-all   w-[30px] ml-2 h-[30px] flex cursor-pointer justify-center rounded overflow-hidden items-center"
                            >

                                <a href="#" className=" hover:bg-blue-300 hover:text-white w-full h-full  text-[30px] flex cursor-pointer justify-center items-center pb-2">

                                    &raquo;
                                </a>
                            </button>
                        </div>
                    </div>
                    <div className="text-gray-500 mt-3">1-20 sur 35 resultants</div>





                </div>
            </div>
        </div>
    )
}

export default EdicationConnaissVisitor
