/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function HomeVisitorSchool() {
    const [imageLoading, SetImageLoading] = useState(true)
    const [imageLoading1, SetImageLoading1] = useState(true)
    const [imageLoading2, SetImageLoading2] = useState(true)


    const [sizeInfer, SetsizeInfer] = useState(window.innerWidth < 650)
    const [sizeInfer1, SetsizeInfer1] = useState(window.innerWidth < 650)
    const [sizeInfer2, SetsizeInfer2] = useState(window.innerWidth < 650)

    const [titre, Settitre] = useState(window.innerWidth < 800.38383838282929)

    useEffect(() => {
        const hundleSize = () => {
            SetsizeInfer(window.innerWidth < 650)
            SetsizeInfer1(window.innerWidth < 650)
            SetsizeInfer2(window.innerWidth < 650)


            Settitre(window.innerWidth < 800.38383838282929)


        }

        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    return (
        <>

            <div className="  bg-white rounded-[50px] h-max my-1 w-full flex justify-around  items-center">
                <div className="w-full h-max flex p-2 justify-around items-center responsi">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}>  Bienvenue dans l'option de notre application qui est spécialisée dans   l'éducation pour tous</h2>

                    <div className={` ${imageLoading && sizeInfer ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading(false)
                            }, 1000)
                        }} src="../image/Coursvideoetbibliotheque.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}> Bienvenue dans l'option de notre application qui est spécialisée dans   l'éducation pour tous</h2>
                        <p className="text-gray-500 p-1 text-[25px] reduitTailParagr mt-6 ">

                            Nous sommes déterminés à fournir une expérience d'apprentissage enrichissante et accessible à tous les utilisateurs, car nous croyons que chaque individu mérite la possibilité de se développer, indépendamment de ses origines ou de son niveau de compétence.


                        </p>
                    </div>
                </div>
            </div>


            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}>   Cours en ligne</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">



                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] textSizeTitre text-blue-600 font-serif w-full text-center`}>Cours en ligne</h2>

                        <p className="text-gray-500 p-1 text-[25px] reduitTailParagr ">
                            <div className="text-gray-500">
                                <div className="text-gray-500 p-1 text-[25px] reduitTailParagr mt-6">
                                    En choisissant nos cours en ligne, vous bénéficiez de nombreux avantages. Vous pouvez apprendre à votre propre rythme, selon votre emploi du temps et depuis n'importe quel endroit. Vous aurez accès à du contenu de haute qualité, des ressources pédagogiques complètes et des outils interactifs pour faciliter votre apprentissage.
                                    Préparez-vous à explorer de nouveaux horizons et à développer vos compétences grâce à nos cours en ligne de qualité.
                                </div>
                                <div className=" w-full p-2  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading1 && sizeInfer1 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize     overflow-hidden responsive1`}>
                        {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading1(false)
                            }, 1000)
                        }} src="../image/online_school.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}> Livres</h2>

                    <div className={` ${imageLoading2 && sizeInfer2 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize    overflow-hidden responsive1`}>
                        {imageLoading2 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading2(false)
                            }, 1000)
                        }} src="../image/Livres electroniques.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}> Livres</h2>
                        <p className="text-gray-500 p-1  reduitTailParagr text-[25px] ">

                            Découvrez une bibliothèque en ligne optimisée pour votre efficacité. Accédez à une multitude de ressources en un instant. Trouvez rapidement les livres, articles et documents pertinents. Simplifiez votre recherche grâce à notre interface intuitive. Gagnez un temps précieux avec des résultats instantanés. Maximisez votre productivité en explorant notre vaste collection. Plongez dans une expérience de lecture fluide et captivante. Accélérez votre recherche et enrichissez vos connaissances dès maintenant!


                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className=" bg-blue-400 relative  mr-20 transition-all hidden text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeVisitorSchool


