import React from 'react'
import DetaillDemmandeEchangeAdmin from './DetaillDemmandeEchangeAdmin'
function DetaillDemmandeEchangeAdminComponent({ isdivScroll }) {
  return (
    <>
      <DetaillDemmandeEchangeAdmin isdivScroll={isdivScroll} />
    </>
  )
}

export default DetaillDemmandeEchangeAdminComponent
