import React from "react";
import { Link } from "react-router-dom";

function SchoolCoursVisitor() {
  return (
    <div className=" w-full bg-white">
      <div className="w-full pt-10 px-10 flex  justify-around items-center responsive12 ">
        <div className="w-[45%] responsive11">
          <h2 className="text-4xl font-bold w-full flex flex-col  text-left">
            <span className="mot1">Apprendre de </span>
            <span className="mot3"> Nouvelles Choses </span>
            <span className="mot2"> En ligne.</span>
          </h2>
          <div className="text-gray-500 mt-3">
            Nos cours en ligne sont conçus pour vous offrir une expérience
            d'apprentissage flexible, accessible et enrichissante. Que vous
            souhaitiez acquérir de nouvelles compétences, approfondir vos
            connaissances dans un domaine spécifique ou vous former pour une
            carrière, nous avons une variété de cours adaptés à vos besoins.
          </div>
          <div className=" w-full p-2 mt-3  space-x-4 flex justify-start ">
            <Link
              to="/Visit/Edication/SchoolHome"
              className="flex bg-blue-400 relative  transition-all text-white px-4 py-2 justify-center w-max my-1  rounded hover:bg-blue-600"
            >
              <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
              Créer un compte
            </Link>
            <Link
              to="/Visit/Edication/SchoolHome"
              className="flex bg-blue-400 relative  transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600"
            >
              Voir Les Tutoriels
            </Link>
          </div>
        </div>
        <div className="animeTop w-[50%] h-[35%]  rounded-[20px] responsive11ImageExchangeExchange  overflow-hidden responsive11ImageExchange">
          <img draggable='false'
            src="../../image/SchoolHome.png"
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
      </div>

      <div className="w-full bg-gray-100 flex flex-col mt-[100px]  justify-around items-center responsive1">
        <div className="w-[80%] responsive11 p-[30px]">
          <h2 className="text-4xl font-bold w-full text-center">
            <span className="mot1">Apprendre grâce à </span>{" "}
            <span className="mot3">plusieurs formats</span>{" "}
            <span className="mot2">, Suivre les Cours </span>
            <span className="mot1">de votre choix </span>
          </h2>
          <div className="text-gray-500  flex items-center justify-center">
            <p class="w-[65%] text-center mt-5">
              Vous cherchez une formation complète pour apprendre de A à Z ou
              une vidéo pour découvrir un nouvel outil ? Vous devriez trouver
              votre bonheur
            </p>
            <br />
          </div>
        </div>
        <span class="bg-green-100  text-green-800 text-lg  font-medium mr-2 px-4 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
          Les Derniers Cours
        </span>
        <div className="w-[80%] border mb-5 border-green-100 bg-gray-50 rounded-2xl responsive11 p-[30px] overflow-hidden py-5 px-10   responsive11ImageExchange">
          <div class="grid md:grid-cols-2 grid-cols-1 gap-6">
            <article
              class=" flex flex-col bg-white justify-between card  border border-gray-200 shadow-md rounded-lg  "
              data-history="2053"
            >
              <Link to={`/Admin/School/Courses/${1}`}>
                <div class="flex flex-row">
                  <img draggable='false'
                    class="w-10 h-10 rounded-b-r-lg "
                    src="https://grafikart.fr/uploads/icons/javascript.svg"
                    alt="JavaScript"
                  />
                  <div class="card__body stack flex flex-col space-y-3 p-3">
                    <h2 class="card__title text-xl font-medium text-gray-900  ">
                      <a
                        href="/formations/formation-javascript"
                        title="Apprendre le JavaScript"
                        class="card__link"
                      >
                        Formation Javascript
                      </a>
                    </h2>
                    <div class="card__description text-gray-500 ">
                      <p>
                        Dans ce Cours on va vous expliquer les Fondamentaux du
                        Javascript{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <footer class="card__footer flex justify-between bg-gray-100 p-3 text-gray-500">
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                    />
                  </svg>
                  <Link target="_brank" className="text-olive-500">
                    10 Chapitres
                  </Link>
                </div>
                <div class="flex flex-row items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  12 Nov 2023
                </div>
              </footer>
            </article>
            <article
              class=" flex flex-col bg-white justify-between card  border border-gray-200 shadow-md rounded-lg  "
              data-history="2053"
            >
              <Link to={`/Admin/School/Courses/${1}`}>
                <div class="flex flex-row">
                  <img draggable='false'
                    class="w-10 h-10 rounded-b-r-lg "
                    src="https://grafikart.fr/uploads/icons/javascript.svg"
                    alt="JavaScript"
                  />
                  <div class="card__body stack flex flex-col space-y-3 p-3">
                    <h2 class="card__title text-xl font-medium text-gray-900  ">
                      <a
                        href="/formations/formation-javascript"
                        title="Apprendre le JavaScript"
                        class="card__link"
                      >
                        Formation Javascript
                      </a>
                    </h2>
                    <div class="card__description text-gray-500 ">
                      <p>
                        Dans ce Cours on va vous expliquer les Fondamentaux du
                        Javascript{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <footer class="card__footer flex justify-between bg-gray-100 p-3 text-gray-500">
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                    />
                  </svg>
                  <Link target="_brank" className="text-olive-500">
                    10 Chapitres
                  </Link>
                </div>
                <div class="flex flex-row items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  12 Nov 2023
                </div>
              </footer>
            </article>

            <article
              class=" flex flex-col bg-white justify-between card  border border-gray-200 shadow-md rounded-lg  "
              data-history="2053"
            >
              <Link to={`/Admin/School/Courses/${1}`}>
                <div class="flex flex-row">
                  <img draggable='false'
                    class="w-10 h-10 rounded-b-r-lg "
                    src="https://grafikart.fr/uploads/icons/javascript.svg"
                    alt="JavaScript"
                  />
                  <div class="card__body stack flex flex-col space-y-3 p-3">
                    <h2 class="card__title text-xl font-medium text-gray-900  ">
                      <a
                        href="/formations/formation-javascript"
                        title="Apprendre le JavaScript"
                        class="card__link"
                      >
                        Formation Javascript
                      </a>
                    </h2>
                    <div class="card__description text-gray-500 ">
                      <p>
                        Dans ce Cours on va vous expliquer les Fondamentaux du
                        Javascript{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <footer class="card__footer flex justify-between bg-gray-100 p-3 text-gray-500">
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                    />
                  </svg>
                  <Link target="_brank" className="text-olive-500">
                    10 Chapitres
                  </Link>
                </div>
                <div class="flex flex-row items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  12 Nov 2023
                </div>
              </footer>
            </article>
            <article
              class=" flex flex-col bg-white justify-between card  border border-gray-200 shadow-md rounded-lg  "
              data-history="2053"
            >
              <Link to={`/Admin/School/Courses/${1}`}>
                <div class="flex flex-row">
                  <img draggable='false'
                    class="w-10 h-10 rounded-b-r-lg "
                    src="https://grafikart.fr/uploads/icons/javascript.svg"
                    alt="JavaScript"
                  />
                  <div class="card__body stack flex flex-col space-y-3 p-3">
                    <h2 class="card__title text-xl font-medium text-gray-900  ">
                      <a
                        href="/formations/formation-javascript"
                        title="Apprendre le JavaScript"
                        class="card__link"
                      >
                        Formation Javascript
                      </a>
                    </h2>
                    <div class="card__description text-gray-500 ">
                      <p>
                        Dans ce Cours on va vous expliquer les Fondamentaux du
                        Javascript{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <footer class="card__footer flex justify-between bg-gray-100 p-3 text-gray-500">
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                    />
                  </svg>
                  <Link target="_brank" className="text-olive-500">
                    10 Chapitres
                  </Link>
                </div>
                <div class="flex flex-row items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  12 Nov 2023
                </div>
              </footer>
            </article>
          </div>
        </div>

        <span class="bg-green-100  text-green-800 text-lg  font-medium mr-2 px-4 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
          Les Derniers Tutoriels
        </span>
        <div className="w-[80%] border mb-5 border-green-100 bg-gray-50 rounded-2xl responsive11 p-[30px] overflow-hidden py-5 px-10   responsive11ImageExchange">
          <div class="grid md:grid-cols-2 grid-cols-1 gap-6">
            <article
              class=" flex flex-col bg-white justify-between card  border border-gray-200 shadow-md rounded-lg  "
              data-history="2053"
            >
              <Link to={`/Admin/School/Courses/${1}`}>
                <div class="flex flex-row">
                  <img draggable='false'
                    class="w-10 h-10 rounded-b-r-lg "
                    src="https://grafikart.fr/uploads/icons/javascript.svg"
                    alt="JavaScript"
                  />
                  <div class="card__body stack flex flex-col space-y-3 p-3">
                    <h2 class="card__title text-xl font-medium text-gray-900  ">
                      <a
                        href="/formations/formation-javascript"
                        title="Apprendre le JavaScript"
                        class="card__link"
                      >
                        Formation Javascript
                      </a>
                    </h2>
                    <div class="card__description text-gray-500 ">
                      <p>
                        Dans ce Cours on va vous expliquer les Fondamentaux du
                        Javascript{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <footer class="card__footer flex justify-between bg-gray-100 p-3 text-gray-500">
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                    />
                  </svg>
                  <Link target="_brank" className="text-olive-500">
                    10 Chapitres
                  </Link>
                </div>
                <div class="flex flex-row items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  12 Nov 2023
                </div>
              </footer>
            </article>
            <article
              class=" flex flex-col bg-white justify-between card  border border-gray-200 shadow-md rounded-lg  "
              data-history="2053"
            >
              <Link to={`/Admin/School/Courses/${1}`}>
                <div class="flex flex-row">
                  <img draggable='false'
                    class="w-10 h-10 rounded-b-r-lg "
                    src="https://grafikart.fr/uploads/icons/javascript.svg"
                    alt="JavaScript"
                  />
                  <div class="card__body stack flex flex-col space-y-3 p-3">
                    <h2 class="card__title text-xl font-medium text-gray-900  ">
                      <a
                        href="/formations/formation-javascript"
                        title="Apprendre le JavaScript"
                        class="card__link"
                      >
                        Formation Javascript
                      </a>
                    </h2>
                    <div class="card__description text-gray-500 ">
                      <p>
                        Dans ce Cours on va vous expliquer les Fondamentaux du
                        Javascript{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <footer class="card__footer flex justify-between bg-gray-100 p-3 text-gray-500">
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                    />
                  </svg>
                  <Link target="_brank" className="text-olive-500">
                    10 Chapitres
                  </Link>
                </div>
                <div class="flex flex-row items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  12 Nov 2023
                </div>
              </footer>
            </article>

            <article
              class=" flex flex-col bg-white justify-between card  border border-gray-200 shadow-md rounded-lg  "
              data-history="2053"
            >
              <Link to={`/Admin/School/Courses/${1}`}>
                <div class="flex flex-row">
                  <img draggable='false'
                    class="w-10 h-10 rounded-b-r-lg "
                    src="https://grafikart.fr/uploads/icons/javascript.svg"
                    alt="JavaScript"
                  />
                  <div class="card__body stack flex flex-col space-y-3 p-3">
                    <h2 class="card__title text-xl font-medium text-gray-900  ">
                      <a
                        href="/formations/formation-javascript"
                        title="Apprendre le JavaScript"
                        class="card__link"
                      >
                        Formation Javascript
                      </a>
                    </h2>
                    <div class="card__description text-gray-500 ">
                      <p>
                        Dans ce Cours on va vous expliquer les Fondamentaux du
                        Javascript{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <footer class="card__footer flex justify-between bg-gray-100 p-3 text-gray-500">
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                    />
                  </svg>
                  <Link target="_brank" className="text-olive-500">
                    10 Chapitres
                  </Link>
                </div>
                <div class="flex flex-row items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  12 Nov 2023
                </div>
              </footer>
            </article>
            <article
              class=" flex flex-col bg-white justify-between card  border border-gray-200 shadow-md rounded-lg  "
              data-history="2053"
            >
              <Link to={`/Admin/School/Courses/${1}`}>
                <div class="flex flex-row">
                  <img draggable='false'
                    class="w-10 h-10 rounded-b-r-lg "
                    src="https://grafikart.fr/uploads/icons/javascript.svg"
                    alt="JavaScript"
                  />
                  <div class="card__body stack flex flex-col space-y-3 p-3">
                    <h2 class="card__title text-xl font-medium text-gray-900  ">
                      <a
                        href="/formations/formation-javascript"
                        title="Apprendre le JavaScript"
                        class="card__link"
                      >
                        Formation Javascript
                      </a>
                    </h2>
                    <div class="card__description text-gray-500 ">
                      <p>
                        Dans ce Cours on va vous expliquer les Fondamentaux du
                        Javascript{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <footer class="card__footer flex justify-between bg-gray-100 p-3 text-gray-500">
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                    />
                  </svg>
                  <Link target="_brank" className="text-olive-500">
                    10 Chapitres
                  </Link>
                </div>
                <div class="flex flex-row items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  12 Nov 2023
                </div>
              </footer>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SchoolCoursVisitor;
