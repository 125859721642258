/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import axios from 'axios'
import React, { useEffect, useState } from 'react'
function MotAcceuilUserEchange() {
    const [clickSlideHidden, setclickSlideHidden] = useState(false)
    const [moblieSize, GetMobileSize] = useState(window.innerWidth < 1265)
    const [moblieSize1, GetMobileSize1] = useState(window.innerWidth < 1179)
    const [moblieSize2, GetMobileSize2] = useState(window.innerWidth < 1120)
    const [moblieSize3, GetMobileSize3] = useState(window.innerWidth < 861)
    const [moblieSize4, GetMobileSize4] = useState(window.innerWidth < 587)
    const [moblieSize5, GetMobileSize5] = useState(window.innerWidth < 510)
    const [moblieSize7, GetMobileSize7] = useState(window.innerWidth < 430)
    const [moblieSize6, GetMobileSize6] = useState(window.innerWidth < 1049)


    const [monnaieElectroniqueData, setmonnaieElectroniqueData] = useState([])
    const [monnaieElectroniqueDataDemandant, setmonnaieElectroniqueDataDemandant] = useState([])
    useEffect(() => {
        axios.get("https://btrproject.burundientempsreel.com/fashanya/donneesFashanyaEchangeur").then((response) => {
            setmonnaieElectroniqueData(response.data.mfise)
            setmonnaieElectroniqueDataDemandant(response.data.nshaka)
        }).catch((err) => {
            console.log("err:", err.message)
        })
    }, [])


    useEffect(() => {
        const windowClicked = () => {
            setclickSlideHidden(false);
        };
        const hundleSize = () => {
            GetMobileSize(window.innerWidth < 1265);
            GetMobileSize1(window.innerWidth < 1179);
            GetMobileSize2(window.innerWidth < 1120);
            GetMobileSize3(window.innerWidth < 861);
            GetMobileSize4(window.innerWidth < 587);
            GetMobileSize7(window.innerWidth < 430);
            GetMobileSize5(window.innerWidth < 510);
            GetMobileSize6(window.innerWidth < 1049);
        };

        window.addEventListener("click", windowClicked);
        window.addEventListener("resize", hundleSize);
        return () => {
            window.removeEventListener("click", windowClicked);
            window.removeEventListener("resize", hundleSize);
        };
    }, []);


    const MaxElectron = 5000000
    const MinElectron = 20000
    //pour les electron disponible
    const sommeMontantsParNomMonnaieElectronique = monnaieElectroniqueData.reduce((acc, datas) => {
        if (datas.montant >= MinElectron && datas.montant <= MaxElectron) {
            if (acc[datas.nomMonnaieElectronique]) {
                acc[datas.nomMonnaieElectronique] += datas.montant;
            } else {
                acc[datas.nomMonnaieElectronique] = datas.montant;
            }
        }
        return acc;
    }, {});

    const totalMontantToutesMonnaies = monnaieElectroniqueData.reduce((acc, data) => {
        if (data.montant >= MinElectron && data.montant <= MaxElectron) {
            acc += data.montant;
        }
        return acc;
    }, 0);

    const tableauSommeMontantsParNomMonnaieElectronique = Object.entries(
        sommeMontantsParNomMonnaieElectronique
    ).map(([nomMonnaieElectronique, montantTotal]) => {
        const pourcentage = (montantTotal / totalMontantToutesMonnaies) * 100;
        const filteredData = monnaieElectroniqueData.filter(
            (data) =>
                data.nomMonnaieElectronique === nomMonnaieElectronique &&
                data.montant >= MinElectron &&
                data.montant <= MaxElectron
        );

        return {
            nomMonnaieElectronique,
            montantTotal,
            pourcentage,
        };
    });

    //pour les etectron demande
    const sommeMontantsParNomMonnaieElectroniqueDemande = monnaieElectroniqueDataDemandant.reduce((acc, datas) => {
        if (datas.montant >= MinElectron && datas.montant <= MaxElectron) {
            if (acc[datas.nomMonnaieElectronique]) {
                acc[datas.nomMonnaieElectronique] += datas.montant;
            } else {
                acc[datas.nomMonnaieElectronique] = datas.montant;
            }
        }
        return acc;
    }, {});

    const totalMontantToutesMonnaiesDemande = monnaieElectroniqueDataDemandant.reduce((acc, data) => {
        if (data.montant >= MinElectron && data.montant <= MaxElectron) {
            acc += data.montant;
        }
        return acc;
    }, 0);

    const tableauSommeMontantsParNomMonnaieElectroniqueDemande = Object.entries(
        sommeMontantsParNomMonnaieElectroniqueDemande
    ).map(([nomMonnaieElectronique, montantTotal]) => {
        const pourcentage = (montantTotal / totalMontantToutesMonnaiesDemande) * 100;
        const filteredData = monnaieElectroniqueData.filter(
            (data) =>
                data.nomMonnaieElectronique === nomMonnaieElectronique &&
                data.montant >= MinElectron &&
                data.montant <= MaxElectron
        );

        return {
            nomMonnaieElectronique,
            montantTotal,
            pourcentage,
        };
    });

    tableauSommeMontantsParNomMonnaieElectroniqueDemande.sort((a, b) => b.pourcentage - a.pourcentage);

    const [avisData, setAvisData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalElements, setTotalElements] = useState(0)
    useEffect(() => {
        axios.get(`https://btrproject.burundientempsreel.com/avis?page=${currentPage}&pageSize=${pageSize}`).then((response) => {
            setAvisData(response.data.avis);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalAvis);
        }).catch((err) => {

            console.error('Erreur lors de la récupération des avis :', err.message);
        })
    }, [pageSize, currentPage])
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };





    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const getRange = () => {
        const from = (currentPage - 1) * pageSize + 1;
        const to = Math.min(currentPage * pageSize, totalElements);
        return `${from} à ${to} avis sur ${totalElements}`;
    };
    function getTimeDifference(createdAt) {
        const currentDate = new Date();
        const createdDate = new Date(createdAt);
        const timeDifference = currentDate.getTime() - createdDate.getTime();
        const secondsDifference = Math.floor(timeDifference / 1000);
        const minutesDifference = Math.floor(secondsDifference / 60);
        const hoursDifference = Math.floor(minutesDifference / 60);
        const daysDifference = Math.floor(hoursDifference / 24);
        const monthsDifference = Math.floor(daysDifference / 30);
        const yearsDifference = Math.floor(monthsDifference / 12);

        if (yearsDifference > 0) {
            return `Il y a ${yearsDifference} ${yearsDifference === 1 ? 'an' : 'ans'}`;
        } else if (monthsDifference > 0) {
            return `Il y a ${monthsDifference} ${monthsDifference === 1 ? 'mois' : 'mois'}`;
        } else if (daysDifference > 0) {
            return `Il y a ${daysDifference} ${daysDifference === 1 ? 'jour' : 'jours'}`;
        } else if (hoursDifference > 0) {
            return `Il y a ${hoursDifference} ${hoursDifference === 1 ? 'heure' : 'heures'}`;
        } else if (minutesDifference > 0) {
            return `Il y a ${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'}`;
        } else {
            return `A l'instants`;
        }

    }


    monnaieElectroniqueData.forEach(data => {
        const pourcentage = (((data.montant - MinElectron) / (MaxElectron - MinElectron)) * 100).toFixed(2)
        data.pourcentage = pourcentage;
    });

    monnaieElectroniqueData.sort((a, b) => b.pourcentage - a.pourcentage);



    monnaieElectroniqueDataDemandant.forEach(data => {
        const pourcentage = (((data.montant - MinElectron) / (MaxElectron - MinElectron)) * 100).toFixed(2)
        data.pourcentage = pourcentage;
    });
    monnaieElectroniqueDataDemandant.sort((a, b) => b.pourcentage - a.pourcentage);



    tableauSommeMontantsParNomMonnaieElectronique.sort((a, b) =>
        b.pourcentage - a.pourcentage
    )


    return (
        <>
            <div className={`w-full min-h-screen  flex flex-col items-center py-2 ${moblieSize5 ? 'px-[2px]' : moblieSize} ${moblieSize ? 'px-3' : 'px-10'} `}>
                <h2 className='text-blue-700 text-[20px] sm:text-[25px] md:text-[35px] font-serif w-full text-center'>  Bienvenue cher  agent </h2>
                <div className={`bg-[#0000ff09] flex  flex-col items-center mt-4 ${moblieSize1 ? 'w-[99.9900%] ' : 'w-[95%] '}`}>
                    <div className={`w-full text-center  border-b-2 border-white pb-5  font-thin ${moblieSize4 ? 'text-[1rem]' : 'text-2xl'}`}>
                        Monnaie électronique
                    </div>

                    <div className='w-full  flex justify-between mt-3'>
                        <div className={`w-[50%]  border-r border-[#0000ff6b] ${moblieSize5 ? 'p-[2px]' : 'p-4'}`}>
                            <div className={` text-blue-800 bg-[#0000ff38] mb-5 p-2 font-serif rounded-md text-center  ${moblieSize4 ? 'text-[0.75rem]' : 'text-xl'}`}>Monnaie électronique disponible</div>
                            <div>
                                {monnaieElectroniqueData.map((datas, index) => (
                                    datas.montant >= MinElectron && datas.montant <= MaxElectron && (
                                        <div key={index} className='bg-white shadow rounded justify-between p-[2px] sm:p-2 border border-blue-300 mt-2 flex items-center'>
                                            <div className='flex items-center'>
                                                <div className={`rounded-full border border-blue-700 overflow-hidden ${moblieSize7 ? 'h-4 w-4 mr-1' : moblieSize4}  ${moblieSize4 ? 'w-6 h-6 mr-1' : 'w-10 h-10  mr-2'} `}>
                                                    <img src={
                                                        datas.nomMonnaieElectronique == "Lumicash"
                                                            ? "image/lumitel.png"
                                                            : datas.nomMonnaieElectronique == "Bancobu enoti"
                                                                ? "image/BancobueNoti.jpg"
                                                                : datas.nomMonnaieElectronique == "CECM akaravyo"
                                                                    ? "image/New-logo-CECM-01.jpg"
                                                                    : datas.nomMonnaieElectronique == "Mobinetel"
                                                                        ? "image/mobinotel.png"
                                                                        : datas.nomMonnaieElectronique == "Telemax"
                                                                            ? "image/Telemax.jpg"
                                                                            : null
                                                    }
                                                        className='w-full h-full bg-white object-contain ' ></img>
                                                </div>
                                                <div className={`flex  ${moblieSize3 ? 'flex-col  items-start' : 'items-center'}`}>
                                                    <div>
                                                        <div className={`font-semibold  ${moblieSize7 ? 'text-[9.9999999999999999px]' : moblieSize5} ${moblieSize5 ? 'text-[0.60rem]' : moblieSize4} ${moblieSize4 ? 'text-[0.75rem]' : moblieSize2} ${moblieSize2 ? 'text-[1rem]' : 'text-2xl '}`}>{datas.nomMonnaieElectronique}</div>
                                                        <div className={`text-gray-400  ${moblieSize4 ? 'text-[9px]' : 'text-[13px]'}`}>{datas.nomPersonne}</div>
                                                    </div>
                                                    <div className={`flex ${moblieSize3 ? '' : 'ml-5 '}`}>
                                                        <div className={`flex  ${moblieSize7 && 'text-[9.9999999999999999px]'} text-fuchsia-700 ${moblieSize4 ? 'text-[0.60rem]' : moblieSize2} ${moblieSize2 ? 'text-[1rem]' : 'text-2xl '}`}>{datas.montant.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</div>
                                                        <div className={`text-blue-600 ${moblieSize7 && 'text-[9.9999999999999999px]'} ${moblieSize ? 'mx-[2px]' : moblieSize4}  ${moblieSize4 ? 'text-[0.65rem]' : moblieSize2} ${moblieSize2 ? 'text-[1rem] mx-2' : 'text-3xl mx-2'}`}>≈</div>
                                                        <div className={`text-green-600 ${moblieSize7 && 'text-[9.9999999999999999px]'} ${moblieSize4 ? 'text-[0.60rem]' : moblieSize2} ${moblieSize2 ? 'text-[1rem]' : 'text-2xl '}`}>{(((datas.montant - MinElectron) / (MaxElectron - MinElectron)) * 100).toFixed(2)}%</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`bg-[#0000ff36] p-[2px] sm:p-2 rounded-full`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right-circle-fill text-blue-800" viewBox="0 0 16 16">
                                                    <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z" />
                                                </svg>
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                        </div>
                        <div className={`w-[50%]   ${moblieSize5 ? 'p-[2px]' : 'p-4'}`}>
                            <div className={`  text-green-800 bg-[#0080001f]  mb-5 p-2 font-serif rounded-md text-center  ${moblieSize4 ? 'text-[0.75rem]' : 'text-xl'}`}>Monnaie électronique demandée</div>
                            <div>
                                {monnaieElectroniqueDataDemandant.map((datas, index) => (
                                    datas.montant >= MinElectron && datas.montant <= MaxElectron && (
                                        <div key={index} className='bg-white shadow rounded justify-between p-[2px] sm:p-2  border border-blue-300 mt-2 flex items-center'>
                                            <div className='flex items-center'>
                                                <div className={`rounded-full border border-blue-700 overflow-hidden mr-2 ${moblieSize4 ? 'w-6 h-6' : 'w-10 h-10 '} `}>
                                                    <img src={
                                                        datas.nomMonnaieElectronique == "Lumicash"
                                                            ? "image/lumitel.png"
                                                            : datas.nomMonnaieElectronique == "Bancobu enoti"
                                                                ? "image/BancobueNoti.jpg"
                                                                : datas.nomMonnaieElectronique == "CECM akaravyo"
                                                                    ? "image/New-logo-CECM-01.jpg"
                                                                    : datas.nomMonnaieElectronique == "Mobinetel"
                                                                        ? "image/mobinotel.png"
                                                                        : datas.nomMonnaieElectronique == "Telemax"
                                                                            ? "image/Telemax.jpg"
                                                                            : null
                                                    } className='w-full h-full bg-white object-contain ' ></img>
                                                </div>
                                                <div className={`flex  ${moblieSize3 ? 'flex-col  items-start' : 'items-center'}`}>
                                                    <div>
                                                        <div className={`font-semibold ${moblieSize7 ? 'text-[9.9999999999999999px]' : moblieSize5} ${moblieSize5 ? 'text-[0.60rem]' : moblieSize4} ${moblieSize4 ? 'text-[0.75rem]' : moblieSize2} ${moblieSize2 ? 'text-[1rem]' : 'text-2xl '}`}>{datas.nomMonnaieElectronique}</div>
                                                        <div className={`text-gray-400  ${moblieSize4 ? 'text-[9px]' : 'text-[13px]'}`}>{datas.nomPersonne}</div>
                                                    </div>
                                                    <div className={`flex ${moblieSize3 ? '' : 'ml-5 '}`}>
                                                        <div className={`flex  ${moblieSize7 && 'text-[9.9999999999999999px]'} text-fuchsia-700 ${moblieSize4 ? 'text-[0.60rem]' : moblieSize2} ${moblieSize2 ? 'text-[1rem]' : 'text-2xl '}`}>{datas.montant.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</div>
                                                        <div className={`text-blue-600 ${moblieSize7 && 'text-[9.9999999999999999px]'} ${moblieSize ? 'mx-[2px]' : moblieSize4}  ${moblieSize4 ? 'text-[0.65rem]' : moblieSize2} ${moblieSize2 ? 'text-[1rem] mx-2' : 'text-3xl mx-2'}`}>≈</div>
                                                        <div className={`text-green-600 ${moblieSize7 && 'text-[9.9999999999999999px]'}  ${moblieSize4 ? 'text-[0.60rem]' : moblieSize2} ${moblieSize2 ? 'text-[1rem]' : 'text-2xl '}`}>{(((datas.montant - MinElectron) / (MaxElectron - MinElectron)) * 100).toFixed(2)}%</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='bg-[#0080002d] p-[2px] sm:p-2 rounded-full'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-left-circle-fill text-green-800" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-5.904-2.803a.5.5 0 1 1 .707.707L6.707 10h2.768a.5.5 0 0 1 0 1H5.5a.5.5 0 0 1-.5-.5V6.525a.5.5 0 0 1 1 0v2.768l4.096-4.096z" />
                                                </svg>
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                        </div>

                    </div>

                    <div className='h-[1px] bg-gray-200 mt-4'></div>

                    <div className='w-full  flex justify-between mt-3'>
                        <div className={`w-[50%]  border-r border-[#0000ff6b] ${moblieSize5 ? 'p-[2px]' : 'p-4'}`}>
                            <div className={` text-blue-800 bg-[#0000ff38] mb-5 p-2 font-serif rounded-md text-center  ${moblieSize4 ? 'text-[0.75rem]' : 'text-xl'}`}>Monnaie électronique disponible</div>
                            <div>
                                {tableauSommeMontantsParNomMonnaieElectronique.map(({ nomMonnaieElectronique, montantTotal, pourcentage }, index) => (
                                    <div key={nomMonnaieElectronique} className='bg-white shadow rounded p-2 mt-2 flex items-center'>
                                        <div className={`rounded-full border border-blue-700 overflow-hidden mr-2 ${moblieSize4 ? 'w-6 h-6' : 'w-10 h-10 '} `}>
                                            <img src={
                                                nomMonnaieElectronique == "Lumicash"
                                                    ? "image/lumitel.png"
                                                    : nomMonnaieElectronique == "Bancobu enoti"
                                                        ? "image/BancobueNoti.jpg"
                                                        : nomMonnaieElectronique == "CECM akaravyo"
                                                            ? "image/New-logo-CECM-01.jpg"
                                                            : nomMonnaieElectronique == "Mobinetel"
                                                                ? "image/mobinotel.png"
                                                                : nomMonnaieElectronique == "Telemax"
                                                                    ? "image/Telemax.jpg"
                                                                    : null
                                            } className='w-full h-full bg-white object-contain '></img>
                                        </div>
                                        <div className={`flex  ${moblieSize6 ? 'flex-col items-start' : 'items-center'}`}>
                                            <div className={`font-thin ${moblieSize4 ? 'text-[0.75rem]' : ''}`}>{nomMonnaieElectronique}</div>
                                            <div className='flex items-center'>
                                                <div className={` flex items-center ${moblieSize6 ? 'w-[15vw]' : 'w-[15em]'} `}>
                                                    <div className={`h-2 bg-[#0000ff52] w-full relative overflow-hidden  ${moblieSize6 ? '' : 'ml-3'}  rounded-full`}>
                                                        <div className={`bg-[blue] ${moblieSize4 ? 'text-[0.75rem]' : ''} rounded-full absolute   h-full`} style={{ width: `${pourcentage}%` }}></div>
                                                    </div>
                                                </div>
                                                <div className={`text-fuchsia-600  font-bold ${moblieSize4 ? 'text-[0.60rem] ml-[2px]' : 'ml-3'} }`}>{pourcentage.toFixed(2)}%</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>




                        <div className={`w-[50%]   ${moblieSize5 ? 'p-[2px]' : 'p-4'}`}>
                            <div className={`  text-green-800 bg-[#0080001f]  mb-5 p-2 font-serif rounded-md text-center  ${moblieSize4 ? 'text-[0.75rem]' : 'text-xl'}`}>Monnaie électronique demandée</div>
                            <div>
                                {tableauSommeMontantsParNomMonnaieElectroniqueDemande.map(({ nomMonnaieElectronique, montantTotal, pourcentage }, index) => (
                                    <div key={nomMonnaieElectronique} className='bg-white shadow rounded p-2 mt-2 flex items-center'>
                                        <div className={`rounded-full border border-blue-700 overflow-hidden mr-2 ${moblieSize4 ? 'w-6 h-6' : 'w-10 h-10 '} `}>
                                            <img src={
                                                nomMonnaieElectronique == "Lumicash"
                                                    ? "image/lumitel.png"
                                                    : nomMonnaieElectronique == "Bancobu enoti"
                                                        ? "image/BancobueNoti.jpg"
                                                        : nomMonnaieElectronique == "CECM akaravyo"
                                                            ? "image/New-logo-CECM-01.jpg"
                                                            : nomMonnaieElectronique == "Mobinetel"
                                                                ? "image/mobinotel.png"
                                                                : nomMonnaieElectronique == "Telemax"
                                                                    ? "image/Telemax.jpg"
                                                                    : null
                                            } className='w-full h-full bg-white object-contain '></img>
                                        </div>
                                        <div className={`flex  ${moblieSize6 ? 'flex-col items-start' : 'items-center'}`}>
                                            <div className={`font-thin ${moblieSize4 ? 'text-[0.75rem]' : ''}`}>{nomMonnaieElectronique}</div>
                                            <div className='flex items-center'>
                                                <div className={` flex items-center ${moblieSize6 ? 'w-[15vw]' : 'w-[15em]'} `}>
                                                    <div className={`h-2 bg-[#0080002f] w-full relative overflow-hidden  ${moblieSize6 ? '' : 'ml-3'}  rounded-full`}>
                                                        <div className={`bg-[green] ${moblieSize4 ? 'text-[0.75rem]' : ''} rounded-full absolute   h-full`} style={{ width: `${pourcentage}%` }}></div>
                                                    </div>
                                                </div>
                                                <div className={`text-fuchsia-600  font-bold ${moblieSize4 ? 'text-[0.60rem] ml-[2px]' : 'ml-3'} }`}>{pourcentage.toFixed(2)}%</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>



                    {avisData.length > 0 && (
                        <div className='flex flex-col bg-gray-200 w-[95%] rounded-md p-2 mt-4'>
                            <div className='text-gray-600 text-xl max-sm:text-[0.8rem] max-sm:text-center'>Les avis des agents sur notre service très rapide</div>
                            <div class="w-full mx-auto flex flex-wrap justify-between p-[1px] sm:p-4">

                                {avisData.map((avis) => {
                                    return (
                                        <div class="bg-white shadow-md w-[30em] rounded-md p-3 sm:p-6 mb-4">
                                            <div class="flex items-center mb-4">
                                                <div className='sm:w-10 sm:h-10 w-7 h-7 border border-fuchsia-600 rounded-full mr-4 overflow-hidden'> {avis.fashanya && avis.fashanya.echangeur && <img class=" w-full h-full object-contain" src={`https://btrproject.burundientempsreel.com/uploads/photosechange/${avis.fashanya.echangeur.profil}`} />}</div>
                                                <div>
                                                    <p class={`font-bold text-gray-800 ${moblieSize7 && 'text-[10px]'}`}>{avis.fashanya && avis.fashanya.echangeur && `${avis.fashanya.echangeur.nom} ${avis.fashanya.echangeur.prenom}`}</p>
                                                    <p class={`text-gray-600  rounded-xl  ${moblieSize7 ? 'text-[10px]' : 'sm:text-sm'}`}>{avis.serviceQuality}</p>
                                                </div>
                                                <div class="flex items-center ml-auto text-blue-800 font-semibold">
                                                    <svg class="w-4 h-4 fill-current  mr-1" viewBox="0 0 20 20">
                                                        <path d="M10 18a8 8 0 110-16 8 8 0 010 16zm0-2a6 6 0 100-12 6 6 0 000 12zm0-4a1 1 0 110-2 1 1 0 010 2z" />
                                                    </svg>
                                                    <p class="text-sm max-sm:text-[9px]"> {getTimeDifference(avis.createdAt)}</p>
                                                </div>
                                            </div>
                                            <p class={`text-gray-700  ${moblieSize7 ? 'text-[9px]' : 'max-sm:text-sm'}`}>{avis.content}</p>
                                        </div>
                                    )
                                })}





                            </div>



                            <div className="mt-4 flex items-center justify-center">
                                <div className="flex items-center">
                                    <button
                                        disabled={currentPage === 1}
                                        onClick={handlePreviousPage}
                                        className={`mx-1 px-3 py-1 rounded bg-blue-200 font-semibold text-blue-700 ${currentPage === 1 && 'cursor-no-drop opacity-30'}`}
                                    >
                                        Précédent
                                    </button>
                                    {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                                        <button
                                            key={pageNumber}
                                            onClick={() => handlePageChange(pageNumber)}
                                            className={`mx-1 px-3 py-1 rounded ${currentPage === pageNumber ? 'bg-blue-700 text-white' : 'bg-gray-300 text-gray-700'}`}
                                        >
                                            {pageNumber}
                                        </button>
                                    ))}
                                    <button
                                        disabled={currentPage === totalPages}
                                        onClick={handleNextPage}
                                        className={`mx-1 px-3 py-1 rounded bg-blue-200 font-semibold text-blue-700 ${currentPage === totalPages && 'cursor-no-drop opacity-30'}`}>                                      Suivant
                                    </button>
                                </div>
                            </div>

                            <div className="mt-4 flex items-center justify-center">
                                <p className="text-gray-600">{getRange()}</p>
                            </div>


                        </div>
                    )}

                </div>
            </div>

        </>
    )
}

export default MotAcceuilUserEchange