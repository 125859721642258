// data.js
export const data = {
  "Benefice": [
    {
      "id": 1,
      "nomProduits": "Nokia",
      "PrixUnit": 250,
      "QuantiteProduits": 1020
    },
    {
      "id": 2,
      "nomProduits": "Techno",
      "PrixUnit": 1000,
      "QuantiteProduits": 101
    },
    {
      "id": 3,
      "nomProduits": "Samsung",
      "PrixUnit": 800,
      "QuantiteProduits": 500
    },
    {
      "id": 4,
      "nomProduits": "Apple",
      "PrixUnit": 1200,
      "QuantiteProduits": 300
    },
    {
      "id": 5,
      "nomProduits": "Sony",
      "PrixUnit": 300,
      "QuantiteProduits": 800
    },
    {
      "id": 6,
      "nomProduits": "LG",
      "PrixUnit": 800,
      "QuantiteProduits": 50
    },
    {
      "id": 7,
      "nomProduits": "Microsoft",
      "PrixUnit": 600,
      "QuantiteProduits": 150
    },
    {
      "id": 8,
      "nomProduits": "HP",
      "PrixUnit": 450,
      "QuantiteProduits": 200
    },
    {
      "id": 9,
      "nomProduits": "Dell",
      "PrixUnit": 700,
      "QuantiteProduits": 180
    },
    {
      "id": 10,
      "nomProduits": "Lenovo",
      "PrixUnit": 550,
      "QuantiteProduits": 520
    }
  ],


  "Perte": [
    {
      "id": 1,
      "nomProduits": "Sony",
      "PrixUnit": 300,
      "QuantiteProduits": 800
    },
    {
      "id": 2,
      "nomProduits": "LG",
      "PrixUnit": 800,
      "QuantiteProduits": 50
    },
    {
      "id": 3,
      "nomProduits": "Panasonic",
      "PrixUnit": 400,
      "QuantiteProduits": 200
    },
    {
      "id": 4,
      "nomProduits": "Toshiba",
      "PrixUnit": 250,
      "QuantiteProduits": 150
    },
    {
      "id": 5,
      "nomProduits": "Sharp",
      "PrixUnit": 600,
      "QuantiteProduits": 70
    },
    {
      "id": 6,
      "nomProduits": "Hitachi",
      "PrixUnit": 450,
      "QuantiteProduits": 10
    },
    {
      "id": 7,
      "nomProduits": "Sanyo",
      "PrixUnit": 350,
      "QuantiteProduits": 90
    },
    {
      "id": 8,
      "nomProduits": "Mitsubishi",
      "PrixUnit": 700,
      "QuantiteProduits": 300
    },
    {
      "id": 9,
      "nomProduits": "Philips",
      "PrixUnit": 200,
      "QuantiteProduits": 180
    },
    {
      "id": 10,
      "nomProduits": "JVC",
      "PrixUnit": 180,
      "QuantiteProduits": 100
    }
  ],

  "Dette": [
    {
      "id": 1,
      "nomProduits": "Apple",
      "PrixUnit": 1000,
      "QuantiteProduits": 500
    },
    {
      "id": 2,
      "nomProduits": "Samsung",
      "PrixUnit": 700,
      "QuantiteProduits": 150
    },
    {
      "id": 3,
      "nomProduits": "Microsoft",
      "PrixUnit": 800,
      "QuantiteProduits": 300
    },
    {
      "id": 4,
      "nomProduits": "Google",
      "PrixUnit": 1200,
      "QuantiteProduits": 200
    },
    {
      "id": 5,
      "nomProduits": "Amazon",
      "PrixUnit": 900,
      "QuantiteProduits": 250
    },
    {
      "id": 6,
      "nomProduits": "Facebook",
      "PrixUnit": 600,
      "QuantiteProduits": 300
    },
    {
      "id": 7,
      "nomProduits": "Tesla",
      "PrixUnit": 1500,
      "QuantiteProduits": 100
    },
    {
      "id": 8,
      "nomProduits": "Netflix",
      "PrixUnit": 1100,
      "QuantiteProduits": 400
    },
    {
      "id": 9,
      "nomProduits": "Uber",
      "PrixUnit": 800,
      "QuantiteProduits": 800
    },
    {
      "id": 10,
      "nomProduits": "Airbnb",
      "PrixUnit": 950,
      "QuantiteProduits": 10
    }
  ]
};
