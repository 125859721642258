import React from 'react'
import ClientAdminSuperEchange from './ClientAdminSuperEchange'
function ClientAdminSuperEchangeComponent() {
    return (
        <>
           
            <ClientAdminSuperEchange />  
        </>
    )
}

export default ClientAdminSuperEchangeComponent
