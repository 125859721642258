/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

function SpinnerDemarage() {
    const Logos = "../../image/btr.png";

    const [size, setSize] = useState(window.innerWidth < 354.903938488933);
    const [text, setText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showCursor, setShowCursor] = useState(false);
    const [started, setStarted] = useState(false);
    const content = "Merci de choisir le Burundi en Temps Réel 💕 !!";
    const typingSpeed = 15;
    const cursorBlinkSpeed = 500;
    useEffect(() => {
        if (started) {
            if (currentIndex < content.length) {
                setTimeout(() => {
                    setText(prevText => prevText + content.charAt(currentIndex));
                    setCurrentIndex(prevIndex => prevIndex + 1);
                }, typingSpeed);
            } else {
                setTimeout(() => {
                    setShowCursor(prevShowCursor => !prevShowCursor);
                }, cursorBlinkSpeed);
            }
        }
    }, [currentIndex, started]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setStarted(true);
        }, 0);
        const hundleRiesize = () => {
            setSize(window.innerWidth < 354.903938488933)

        }

        window.addEventListener('resize', hundleRiesize)
        return () => {
            clearTimeout(timeout);
            window.removeEventListener('resize', hundleRiesize)
        };
    }, []);


    return (
        <div className=' fixed w-full  flex-col h-full top-0 left-0 bg-blue-900 z-[10000000000000000000] flex justify-center items-center'>
            <div className="flex flex-col items-center">
                <div className="w-[150px] h-[150px]">
                    <img draggable='false' src={Logos} alt="" srcset="" />
                </div>
                <div className={`text-white ${size ? 'text-[10px]' : ''}  font-serif`}>
                    <p className="typing-text text-center">{text}<span className={`cursor ${showCursor ? 'visible' : 'hidden'}`}>|</span></p>  </div>
            </div>

            <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="text-orange-600 font-serif text-[13px]">Veuillez patienter...</div>
        </div>
    )
}

export default SpinnerDemarage;
