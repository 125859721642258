import React from 'react'

import MarketList from './MarketList'

function ManageMarketComponent() {
  return (
    <>

      <div class="p-4">
        <MarketList />
      </div>
    </>
  )
}

export default ManageMarketComponent
