/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import SpinnerDemarage from "../../../../SpinnerDemarage/SpinnerDemarage";
function AfficheCommandeUserEchange() {

  const [clickSlideHidden, setclickSlideHidden] = useState(false);
  const [loading, SetLoading] = useState(true);

  useEffect(() => {
    const windowClicked = () => {

      setclickSlideHidden(false);
    };

    window.addEventListener("click", windowClicked);

    return () => {
      window.removeEventListener("click", windowClicked);
    };
  }, []);

  const [data, setData] = useState([]);
  // État pour stocker le numéro de page actuel
  const [currentPage, setCurrentPage] = useState(1);
  // État pour stocker le nombre total de pages
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    const iduser = localStorage.getItem("idexch");
    axios
      .get(` https://btrproject.burundientempsreel.com/commande/byId/${iduser}?page=${currentPage}`)
      .then((response) => {
        // Extraire les données de la réponse
        const { Commandes, totalPages } = response.data;
        setData(Commandes);
        setTotalPages(totalPages);
        setTimeout(() => {
          SetLoading(false)
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);

  // Fonction pour passer à la page suivante
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Fonction pour passer à la page précédente
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Fonction pour changer de page lors du clic sur un numéro de page spécifique
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  // Générer la liste de numérotation des pages
  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={
            currentPage === i
              ? "text-sm font-medium leading-none cursor-pointer bg-blue-600 text-white w-[30px] h-[30px] pb-[20px] rounded-full dark:text-gray-200  dark:hover:text-yellow-400 border-t border-transparent hover:border-red-500 pt-3 mr-4 px-2"
              : "text-sm font-medium  leading-none cursor-pointer w-[30px] h-[30px] pb-[20px] bg-gray-400 transition-all  text-white hover:bg-gray-600  rounded-full dark:text-gray-200  dark:hover:text-yellow-400 border-t border-transparent hover:border-yellow-400 pt-3 mr-4 px-2"
          }
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };


  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);

  };


  const filterData = (data, searchValue) => {
    if (!searchValue.trim()) {
      return data;
    }

    return data.filter((item) => {
      if (item.montants.toString().toLowerCase().includes(searchValue.toLowerCase())) {
        return true;
      }


      if (item.Compte.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }

      if (item.Description.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }
      const formattedDate = new Date(item.createdAt).toLocaleDateString('en-US') + ' ' + new Date(item.createdAt).toLocaleTimeString('en-US');
      if (formattedDate.includes(searchValue)) {
        return true;
      }

      if (item.status.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }

      return false;
    });
  };

  const filteredData = filterData(data, searchValue);


  return (
    <>
      {loading && <SpinnerDemarage />}
      <div class="flex flex-col items-end min-h-[90vh]">
        <div className="pr-10  p-1 m-1 rounded search ">
          <p className="text-gray-500 font-serif">Rechercher par votre choix</p>
          <div className="search py-1 w-full search ">
            <form className="flex  w-[100%] ">
              <input
                value={searchValue}
                onChange={handleSearchChange}
                type="text"
                className="p-1 search border border-blue-700 rounded transition-all outline-none focus:border-2 focus:border-blue-500"
                id=""
                placeholder="Rechercher..."
              />
              <label
                htmlFor="search"
                className="transition-all bg-blue-400 text-white hover:bg-blue-600 p-2 ml-1 cursor-pointer rounded"
              >
                <i className="bi bi-search"></i>
              </label>
            </form>
          </div>
        </div>

        {filteredData.length > 0 ?
          (
            <div className="w-full overflow-hidden  mt-4">
              <div className="w-[98%]  overflow-x-auto  mr-4">
                <table className="w-full mx-2 widthTab" cellSpacing="0" border="0">
                  <thead className="h-11 select-none bg-blue-500 border-b-2 border-blue-400 ">
                    <tr>
                      <th className="border-r border-gray-300 text-white">
                        Montant
                      </th>
                      <th className="border-r border-gray-300 text-white">
                        Compte
                      </th>
                      <th className="border-r border-gray-300 text-white">
                        Date
                      </th>
                      <th className="border-r border-gray-300 text-white w-[25em]">
                        Description
                      </th>
                      <th className="border-r border-gray-300 text-white">
                        Statut
                      </th>
                      <th className="border-r border-gray-300 text-white">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-5000">
                    {filteredData.map((item, index) => {
                      return (
                        <tr
                          className="border-r border-b border-gray-300 h-[35px] text-gray-600"
                          key={index}
                        >
                          <td className="border-r border-gray-300">
                            {item.montants}
                          </td>
                          <td className="border-r border-gray-300">
                            {item.Compte}
                          </td>
                          <td className="border-r border-gray-300">
                            {new Date(item.createdAt).toLocaleDateString("fr-FR")}
                          </td>
                          <td className="border-r border-gray-300">
                            {item.Description.length > 40
                              ? item.Description.slice(0, 40) + "..."
                              : item.Description}
                          </td>
                          <td className="w-[7em] p-1 border-r border-gray-300">
                            <div className="border-r border-gray-300 bg-violet-500 text-white pl-2 h-full text-left text-[15px] w-[6.5em] select-none">
                              {item.status}
                            </div>
                          </td>
                          <td>
                            <Link
                              to={`/User/Echange/Commande/Detail/${item.id}`}
                              className="bg-blue-400 text-white w-full flex pl-2"
                            >
                              Détail
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4">
                <div className="lg:w-3/5 w-full flex items-center justify-between border-t border-gray-200 dark:border-gray-700">
                  <div className="flex items-center pt-3 mr-4 text-gray-600 dark:text-gray-200 hover:text-yellow-700 cursor-pointer">
                    <svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.1665 4H12.8332"
                        stroke="currentColor"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.1665 4L4.49984 7.33333"
                        stroke="currentColor"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.1665 4.00002L4.49984 0.666687"
                        stroke="currentColor"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <button
                      onClick={previousPage}
                      disabled={currentPage === 1}
                      className="text-sm ml-3 text-black  hover:text-yellow-700 cursor-pointer"
                    >
                      Previous
                    </button>
                  </div>
                  <div className="flex mt-3">{generatePageNumbers()}</div>
                  <div className="flex items-center pt-3 ml-4 text-black  hover:text-yellow-700 cursor-pointer">
                    <button
                      onClick={nextPage}
                      disabled={currentPage === totalPages}
                      className="text-sm font-medium leading-none mr-3"
                    >
                      Next
                    </button>
                    <svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.1665 4H12.8332"
                        stroke="currentColor"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 7.33333L12.8333 4"
                        stroke="currentColor"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 0.666687L12.8333 4.00002"
                        stroke="currentColor"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          ) :

          <div className=" p-4 w-[100%] h-[50vh]">
            <div class="flex h-full w-full  flex-col items-center rounded-2xl bg-gray-100 p-3 shadow-sm">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-database-fill-x w-1/2 h-1/2 text-gray-300" viewBox="0 0 16 16">
                <path d="M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1Z" />
                <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7Zm6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002Zm-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905Z" />
                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708Z" />
              </svg>
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default AfficheCommandeUserEchange;
