/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

function NavBarsUsersEchangee() {
    const history = useNavigate()
    const userId = localStorage.getItem('idexch');
    const [menu, setMenu] = useState(false);
    const [profil, setProfile] = useState({});
    const [imageLoading, SetImageLoading] = useState(true)
    const [imageLoading1, SetImageLoading1] = useState(true)

    const { pathname } = useLocation();
    const Commandes = /^\/User\/Echange\/Commande.*/
    const Echange = /^\/User\/Echange\/Echange.*/

    const AgentSuperAgents = localStorage.getItem("AgentSuperAgent");


    useEffect(() => {
        const windowClicked = () => {
            setMenu(false);
        };

        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        };
    }, []);

    useEffect(() => {
        axios.get(` https://btrproject.burundientempsreel.com/echangeur/profil/${userId}`).then((response) => {
            setProfile(response.data)
        }).catch((error) => {
            console.log(error.message)
        })
    }, [userId])

    function logout() {
        localStorage.removeItem("t")
        localStorage.removeItem("idexch")
        localStorage.removeItem("userRole")
        history('/')
        window.location.reload()
    }
    const returPageAdmin = () => {
        localStorage.removeItem("t")
        localStorage.removeItem("idexch")
        localStorage.removeItem("userRole")
        localStorage.removeItem('AgentSuperAgent')
        window.location.reload()
    }
    const [mobile, SetMobile] = useState(window.innerWidth < 560)
    const [mobile3, SetMobile3] = useState(window.innerWidth < 860)
    const [mobile1, SetMobile1] = useState(window.innerWidth < 473)
    const [mobile2, SetMobile2] = useState(window.innerWidth < 346)
    const [mobile4, SetMobile4] = useState(window.innerWidth < 303)


    useEffect(() => {
        const hunldeSize = () => {
            SetMobile(window.innerWidth < 560)
            SetMobile3(window.innerWidth < 860)
            SetMobile1(window.innerWidth < 473)
            SetMobile2(window.innerWidth < 346)
            SetMobile4(window.innerWidth < 303)
        }

        window.addEventListener('resize', hunldeSize)
        return () => {
            window.removeEventListener('resize', hunldeSize)
        }
    }, [])

    return (
        <div className=' border-b border-blue-500 overflow-hidden rounded-b-2xl'>
            {profil && (
                <div className='bg-gray-100 px-[20px] paddig w-full flex justify-between h-[10vh] min-h-[63px] items-end relative border-b border-gray-200'>
                    <Link to='/' className="flex justify-center items-center pr-1">
                        <div className="w-[50px] h-[50px] relative  rounded-full overflow-hidden bg-transparent  ">
                            {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                            <img draggable='false' onLoad={() => {
                                setTimeout(() => {
                                    SetImageLoading(false)
                                }, 1000)
                            }} src={` https://btrproject.burundientempsreel.com/uploads/logo/btr.png`} className='h-full w-full object-cover object-center' />
                        </div>
                        {mobile2 ? (<><div className="ml-1 text-blue-800 font-serif text-center ">BTR</div>
                        </>) : (<>
                            <div className="ml-1 text-blue-800 font-serif text-center ">Burundi en temps réel</div>

                        </>)}
                    </Link>
                    <div className="  flex items-center w-max  h-full pr-2   ">
                        <div className="flex justify-end  items-center h-full    mr-1 px-1 ">
                            <div className=" transition-all border w-[40px] h-[40px] relative overflow-hidden rounded-full  mr-2 ">
                                {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                <img draggable='false' onLoad={() => {
                                    setTimeout(() => {
                                        SetImageLoading1(false)
                                    }, 1000)
                                }} src={` https://btrproject.burundientempsreel.com/uploads/photosechange/${profil.profil}`} className='w-full h-full object-cover' />
                            </div>
                            <div className={`${mobile ? 'hidden' : ''}`}>
                                {profil.nom && (profil.nom + ' ' + profil.prenom)}
                            </div>
                        </div>
                        <div onClick={(e) => { setMenu(!menu); e.stopPropagation() }} className=" flex relative items-center">
                            <i class={`bi bi-gear-fill ${mobile1 ? 'text-[25px] rounded-l bg-gray-300 cursor-pointer w-[40px] h-[40px] flex justify-center items-center' : 'hidden'} `}></i>
                            <p className={` bg-gray-300 py-1 ${mobile1 ? 'hidden' : 'block'} rounded-l px-2 cursor-pointer`}>Parametre</p>
                            <div className={` transition-all shadow-2xl ${menu ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} absolute  font-serif z-50 bg-white top-[110%] rounded right-0  `}>
                                <div className=" m-1 px-1 flex cursor-pointer bg-gray-100 p-1 rounded">
                                    <i class="bi bi-person-vcard-fill"></i>
                                    <Link to='/User/Echange/Compte' className='ml-3'>Compte</Link>
                                </div>
                                {AgentSuperAgents == null && <div className=" flex m-1 px-1 cursor-pointer bg-gray-100 p-1 rounded">
                                    <i class="bi bi-box-arrow-in-left"></i>
                                    <p className='ml-2' onClick={logout}> Déconnecter</p>
                                </div>}
                            </div>
                        </div>

                        <Link to='/User/Echange/Aide' className=" flex relative ml-2  items-center">

                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class={` ${mobile1 ? 'text-[25px] cursor-pointer w-[40px] h-[40px] rounded-full overflow-hidden  hover:bg-white hover:text-blue-700 transition-all bg-gray-200 px-2' : 'hidden'}`} viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                            </svg>


                            <p className={` bg-gray-200 py-1 ${mobile1 ? 'hidden' : 'block'} w-[45px] h-[45px]   flex justify-center items-center rounded-full overflow-hidden hover:bg-white hover:text-blue-700 transition-all bg-gray-200 px-2 cursor-pointer`}>Aide</p>
                        </Link>


                        {AgentSuperAgents !== null && <div onClick={returPageAdmin} className='w-8 h-8 mx-3 cursor-pointer '>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-down w-7 h-7 text-fuchsia-700" viewBox="0 0 16 16">
                                <path d="M12.5 9a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7m.354 5.854 1.5-1.5a.5.5 0 0 0-.708-.708l-.646.647V10.5a.5.5 0 0 0-1 0v2.793l-.646-.647a.5.5 0 0 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                                <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                            </svg>
                        </div>
                        }
                    </div>

                </div>
            )
            }

            {mobile3 ? (
                <>
                    <div className={`bg-gray-100   ${SetMobile2 ? 'px-[10px]' : 'px-[20px]'}  w-full flex justify-between  h-[10vh] min-h-[63px] items-end relative`}>
                        <div className='w-max  pt-1'>
                            {pathname == "/" ? (
                                <Link
                                    to="/"
                                    className={`flex   ${mobile2 ? 'px-2  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max   ${mobile1 ? 'text-blue-700' : 'bg-blue-600 text-fuchsia-50'}     transition-all `} >
                                    <div className="flex  justify-center  flex-col  items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                                            <path d="M7.207 1a1 1 0 0 0-1.414 0L.146 6.646a.5.5 0 0 0 .708.708L1 7.207V12.5A1.5 1.5 0 0 0 2.5 14h.55a2.51 2.51 0 0 1-.05-.5V9.415a1.5 1.5 0 0 1-.56-2.475l5.353-5.354z" />
                                            <path d="M8.793 2a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708z" />
                                        </svg>
                                        {!mobile1 && <p className="text-[10px]">Acceuil</p>}
                                    </div>
                                </Link>
                            ) : (
                                <Link
                                    to="/"
                                    className={`flex   ${mobile2 ? 'px-2  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max   ${mobile1 ? 'hover:text-blue-400' : 'hover:bg-blue-400 hover:text-fuchsia-50'}     transition-all `} >


                                    <div className="flex  justify-center  flex-col  items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                                            <path d="M7.207 1a1 1 0 0 0-1.414 0L.146 6.646a.5.5 0 0 0 .708.708L1 7.207V12.5A1.5 1.5 0 0 0 2.5 14h.55a2.51 2.51 0 0 1-.05-.5V9.415a1.5 1.5 0 0 1-.56-2.475l5.353-5.354z" />
                                            <path d="M8.793 2a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708z" />
                                        </svg>
                                        {!mobile1 && <p className="text-[10px]">Acceuil</p>
                                        }
                                    </div>
                                </Link>
                            )}
                        </div>

                        <div className='w-max  pt-1'>
                            {Echange.test(pathname) ? (
                                <>
                                    <Link to='/User/Echange/Echange' className={`flex   ${mobile2 ? 'px-2  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max   ${mobile1 ? 'text-blue-700' : 'bg-blue-600 text-fuchsia-50'}     transition-all `} >

                                        <div className="flex  justify-center flex-col  items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                                                <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                                            </svg>
                                            {!mobile1 && <p className="text-[10px]">Echange</p>}
                                        </div>
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Link to='/User/Echange/Echange'
                                        className={`flex   ${mobile2 ? 'px-2  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max   ${mobile1 ? 'hover:text-blue-400' : 'hover:bg-blue-400 hover:text-fuchsia-50'}     transition-all `} >


                                        <div className="flex  justify-center flex-col items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                                                <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                                            </svg>
                                            {!mobile1 && <p className="text-[10px]">Echange</p>}
                                        </div>
                                    </Link>
                                </>
                            )}
                        </div>

                        <div className='w-max  pt-1'>
                            {pathname == '/User/Echange/Fraistransaction' ? (
                                <>
                                    <Link to='/User/Echange/Fraistransaction' className={`flex   ${mobile2 ? 'px-2  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max   ${mobile1 ? 'text-blue-700' : 'bg-blue-600 text-fuchsia-50'}     transition-all `} >

                                        <div className="flex  justify-center flex-col  items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                                            </svg>
                                            {!mobile1 && <p className="text-[10px]">Frais de transaction</p>}
                                        </div>
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Link to='/User/Echange/Fraistransaction'
                                        className={`flex   ${mobile2 ? 'px-2  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max   ${mobile1 ? 'hover:text-blue-400' : 'hover:bg-blue-400 hover:text-fuchsia-50'}     transition-all `} >



                                        <div className="flex  justify-center flex-col items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                                            </svg>
                                            {!mobile1 && <p className="text-[10px]">Frais de transaction</p>}
                                        </div>
                                    </Link>
                                </>
                            )}
                        </div>

                        <div className='w-max  pt-1'>
                            {Commandes.test(pathname) ? (
                                <>
                                    <Link to='/User/Echange/Commande' className={`flex   ${mobile2 ? 'px-2  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max   ${mobile1 ? 'text-blue-700' : 'bg-blue-600 text-fuchsia-50'}     transition-all `} >

                                        <div className="flex  justify-center flex-col  items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                                                <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                                            </svg>
                                            {!mobile1 && <p className="text-[10px]">Anciers demande</p>}
                                        </div>
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Link to='/User/Echange/Commande'
                                        className={`flex   ${mobile2 ? 'px-2  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max   ${mobile1 ? 'hover:text-blue-400' : 'hover:bg-blue-400 hover:text-fuchsia-50'}     transition-all `} >
                                        <div className="flex  justify-center flex-col items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                                                <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                                            </svg>
                                            {!mobile1 && <p className="text-[10px]">Anciers demande</p>}
                                        </div>
                                    </Link>
                                </>
                            )}
                        </div>
                        <div>
                            {pathname == '/User/Echange/NewCommande' ? (<>

                                <Link to="/User/Echange/NewCommande" className={`flex   ${mobile2 ? 'px-2  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max   ${mobile1 ? 'text-blue-700' : 'bg-blue-600 text-fuchsia-50'}     transition-all `} >

                                    <div className="flex  justify-center  flex-col  items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                                            <path d="M11 13a5 5 0 1 0-4.975-5.5H4A1.5 1.5 0 0 0 2.5 6h-1A1.5 1.5 0 0 0 0 7.5v1A1.5 1.5 0 0 0 1.5 10h1A1.5 1.5 0 0 0 4 8.5h2.025A5 5 0 0 0 11 13m.5-7.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2a.5.5 0 0 1 1 0" />
                                        </svg>
                                        {!mobile1 && <p className="text-[10px]">Nouveau demande</p>}
                                    </div>
                                </Link>
                            </>) : (<>

                                <Link to="/User/Echange/NewCommande" className={`flex   ${mobile2 ? 'px-2  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max   ${mobile1 ? 'hover:text-blue-400' : 'hover:bg-blue-400 hover:text-fuchsia-50'}     transition-all `} >
                                    <div className="flex  justify-center  flex-col items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                                            <path d="M11 13a5 5 0 1 0-4.975-5.5H4A1.5 1.5 0 0 0 2.5 6h-1A1.5 1.5 0 0 0 0 7.5v1A1.5 1.5 0 0 0 1.5 10h1A1.5 1.5 0 0 0 4 8.5h2.025A5 5 0 0 0 11 13m.5-7.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2a.5.5 0 0 1 1 0" />
                                        </svg>
                                        {!mobile1 && <p className="text-[10px]">Nouveau demande</p>}
                                    </div>
                                </Link>
                            </>)}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className='bg-gray-100 px-[20px]   w-full flex justify-between  h-[10vh] min-h-[63px] items-end relative '>
                        <div className='w-max  pt-1'>
                            {pathname == "/" ? (
                                <Link
                                    to="/"
                                    className="flex px-4 rounded-t-xl justify-start items-center  p-1 w-full h-12    pb-2 bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 "
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-houses-fill w-6 h-6 mr-2" viewBox="0 0 16 16">
                                        <path d="M7.207 1a1 1 0 0 0-1.414 0L.146 6.646a.5.5 0 0 0 .708.708L1 7.207V12.5A1.5 1.5 0 0 0 2.5 14h.55a2.51 2.51 0 0 1-.05-.5V9.415a1.5 1.5 0 0 1-.56-2.475l5.353-5.354z" />
                                        <path d="M8.793 2a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708z" />
                                    </svg>
                                    <p className="sizeText">Acceuil</p>
                                </Link>
                            ) : (
                                <Link
                                    to="/"
                                    className="flex text-gray-800 px-4 rounded-t-xl  justify-start items-center  p-1 w-max h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 "
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-houses-fill w-6 h-6 mr-2" viewBox="0 0 16 16">
                                        <path d="M7.207 1a1 1 0 0 0-1.414 0L.146 6.646a.5.5 0 0 0 .708.708L1 7.207V12.5A1.5 1.5 0 0 0 2.5 14h.55a2.51 2.51 0 0 1-.05-.5V9.415a1.5 1.5 0 0 1-.56-2.475l5.353-5.354z" />
                                        <path d="M8.793 2a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708z" />
                                    </svg>
                                    <p className="sizeText">Acceuil</p>
                                </Link>
                            )}
                        </div>
                        <div className='w-max  pt-1'>
                            {Echange.test(pathname) ? (
                                <>
                                    <Link to='/User/Echange/Echange' className="flex px-4 rounded-t-xl justify-start items-center  p-1 w-max h-12    pb-2 bg-blue-600 text-fuchsia-50  transition-all " >
                                        <div className="flex  justify-center  items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange mr-2 w-6 h-6" viewBox="0 0 16 16">
                                                <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                                            </svg>
                                            <p className='sizeText'>Echange</p>
                                        </div>
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Link to='/User/Echange/Echange' className="flex px-4 rounded-t-xl justify-start items-center  p-1 w-max h-12    pb-2 hover:bg-blue-400 text-gray-700  transition-all hover:text-fuchsia-50 " >
                                        <div className="flex  justify-center  items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange mr-2 w-6 h-6" viewBox="0 0 16 16">
                                                <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                                            </svg>
                                            <p className='sizeText'>Echange</p>
                                        </div>
                                    </Link>
                                </>
                            )}
                        </div>


                        <div className='w-max  pt-1'>
                            {pathname == '/User/Echange/Fraistransaction' ? (
                                <>
                                    <Link to='/User/Echange/Fraistransaction' className="flex px-4 rounded-t-xl justify-start items-center  p-1 w-max h-12    pb-2 bg-blue-600 text-fuchsia-50  transition-all " >
                                        <div className="flex  justify-center  items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                                            </svg>
                                            <p className='sizeText'>Frais de transaction</p>
                                        </div>
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Link to='/User/Echange/Fraistransaction' className="flex px-4 rounded-t-xl justify-start items-center  p-1 w-max h-12    pb-2 hover:bg-blue-400 text-gray-700  transition-all hover:text-fuchsia-50 " >
                                        <div className="flex  justify-center  items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                                            </svg>
                                            <p className='sizeText'>Frais de transaction</p>
                                        </div>
                                    </Link>
                                </>
                            )}
                        </div>

                        <div className='w-max  pt-1'>
                            {Commandes.test(pathname) ? (
                                <>
                                    <Link to='/User/Echange/Commande' className="flex px-4 rounded-t-xl justify-start items-center  p-1 w-max h-12    pb-2 bg-blue-600 text-fuchsia-50  transition-all " >
                                        <div className="flex  justify-center  items-center">
                                            <i class="bi bi-hourglass-split text-[20px] mr-1"></i>
                                            <p className='sizeText'>Anciers demande</p>
                                        </div>
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Link to='/User/Echange/Commande' className="flex px-4 rounded-t-xl justify-start items-center  p-1 w-max h-12    pb-2 hover:bg-blue-400 text-gray-700  transition-all hover:text-fuchsia-50 " >
                                        <div className="flex  justify-center  items-center">
                                            <i class="bi bi-hourglass-split text-[20px] mr-1"></i>
                                            <p className='sizeText'>Anciers demande</p>
                                        </div>
                                    </Link>
                                </>
                            )}
                        </div>



                        <div>
                            {pathname == '/User/Echange/NewCommande' ? (<>

                                <Link to="/User/Echange/NewCommande" className="flex px-4 w-max rounded-t-xl justify-start items-center  p-1  h-12    pb-2 bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 "
                                >
                                    <div className="flex  justify-center   items-center">
                                        <i class="bi bi-node-plus-fill text-[20px] mr-1"></i>
                                        <p className='sizeText'>Nouveau demande</p>
                                    </div>
                                </Link>
                            </>) : (<>

                                <Link to="/User/Echange/NewCommande" className="flex px-4 rounded-t-xl justify-start items-center  p-1 w-max h-12    pb-2 hover:bg-blue-400 text-gray-700  transition-all hover:text-fuchsia-50 " >
                                    <div className="flex  justify-center   items-center">
                                        <i class="bi bi-node-plus-fill text-[20px] mr-1"></i>
                                        <p className='sizeText'>Nouveau demande</p>
                                    </div>
                                </Link>
                            </>)}
                        </div>
                    </div>
                </>
            )}

        </div>

    )
}

export default NavBarsUsersEchangee
