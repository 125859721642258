/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import { FadeLoader } from 'react-spinners'



function SeconnectMArcheUser({
    LoginPopUp,
    SetLoginPopUp,
    SignPopUp,
    SetSignPopUp,
    SetisMarcheUser,
    SetInvestor,
    SetStudent,
    SetAdmin,
    SetisVisitor,
    SetisVisitorLogged,
    setAdminmarket
}) {

    const [boutLoading, setboutLoading] = useState(false);

    const history = useNavigate();
    localStorage.removeItem("userRole");
    const handleClose = () => {
        toast.dismiss();
    };

    const [spinnerButton, SetSpinnerButton] = useState(false)

    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const [emailValue, setEmailValue] = useState('')
    const [animationClassEmail, setAnimationClassEmail] = useState('');
    const elemetRefEmail = useRef(null)

    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
    const [passwordValue, setPasswordValue] = useState('')
    const [animationClassPassword, setAnimationClassPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const elemetRefPass = useRef(null)








    const handleSubmit = (e) => {
        e.preventDefault()

        if (emailValue.trim() == '') {
            toast.warning("L'address email est obligatoire !!", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-blue-500 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            elemetRefEmail.current.focus();
            return
        } else if (!emailValue.trim().match(emailRegex)) {
            toast.error("L'address email est incorrect", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            elemetRefEmail.current.focus();

            return
        } else if (passwordValue.trim() == "") {
            toast.warning("Le mot de passe est obligatoire !!", {
                autoClose: 2000
            });
            setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-blue-700 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassPassword(' ')
            }, 3000)
            elemetRefPass.current.focus()

            return
        } else if (!passwordValue.trim().match(PasswordRegex)) {

            toast.error(`Le mot de passe doit contenir au moins
                         une lettre minuscule,lettre majuscule,un chiffre et un caractère spécial
                       `, {
                autoClose: 5000,
                position: "top-center",
                className: 'my-toast',

            });
            setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassPassword(' ')
            }, 5000)
            elemetRefPass.current.focus()
            return
        } else {
            SetSpinnerButton(true)
            axios
                .post(" https://btrproject.burundientempsreel.com/user/login", {
                    email: emailValue,
                    password: passwordValue
                })
                .then((response) => {
                    toast.success("Welcome back! You are now logged in! ♨️", {
                        position: "top-center",
                        autoClose: 7000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        onClick: handleClose,
                    });

                    setboutLoading(true)

                    if (response.data.isAdmin === 1) {
                        SetAdmin(true);
                        SetisVisitor(false);
                        localStorage.setItem("userRole", "admin");
                        localStorage.setItem("userid", response.data.userId);
                        localStorage.setItem("t", response.data.token);
                        history("/Admin/Invest/Home");
                    } else if (response.data.isAdminmarket === 1) {
                        setAdminmarket(true);
                        SetisVisitor(false);
                        history("/Admin/Market");
                        localStorage.setItem("userRole", "Adminmarket");
                        localStorage.setItem("userid", response.data.userId);
                        localStorage.setItem("t", response.data.token);
                    } else if (response.data.isSeller === 1) {
                        SetisMarcheUser(true);
                        SetisVisitor(false);
                        history("/");
                        localStorage.setItem("userRole", "seller");
                        localStorage.setItem("userid", response.data.userId);
                        localStorage.setItem("t", response.data.token);
                    }
                    else {
                        SetisVisitor(true);
                        SetisVisitorLogged(true);
                        localStorage.setItem("userRole", "Visitor");
                        localStorage.setItem("userid", response.data.userId);
                        localStorage.setItem("t", response.data.token);
                        document.body.style.overflow = "auto";
                        SetLoginPopUp(false);
                        history("/");
                    }

                })
                .catch((error) => {
                    setboutLoading(true)
                    if (error.response) {

                        const errorMessage = error.response.data.error;
                        setTimeout(() => {
                            toast.error(errorMessage, {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                onClick: handleClose,
                            });
                        }, 500);

                        setTimeout(() => {
                            setboutLoading(false)
                        }, 2000);
                    } else if (error.request) {

                        console.log("Error from bac", error.request);
                    } else {

                        console.log("Error", error.message);
                    }
                }).finally(() => {
                    SetSpinnerButton(false)
                })

        }
    }
    const [imageLoading, SetImageLoading] = useState(true)
    return (
        <>
            {/* {loading && <SpinnerDemarage />} */}
            <div className='fixed w-full h-full z-[2000] top-0 left-0 flex justify-center overflow-hidden items-center bg-white'>

                <div className='w-full  h-full overflow-auto flex justify-center items-center p-4'>
                    <div className="bg-white overflow-hidden p-2 border rounded-2xl shadow-2xl border-blue-600 w-[25em] h-max">
                        <div className="flex justify-end px-4 items-center">
                            <div onClick={(e) => { SetLoginPopUp(false); e.stopPropagation() }} className="bg-blue-500 rounded-full cursor-pointer w-[30px] h-[30px] flex justify-center items-center text-[22px] font-serif text-white"><i class="bi bi-x-circle-fill"></i></div>
                        </div>
                        <div className="w-full flex justify-center flex-col items-center">
                            <div className="w-[6em] h-[6em] relative overflow-hidden">
                                {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover scale-[2]" /></div>}
                                <img draggable='false' onLoad={() => {
                                    setTimeout(() => {
                                        SetImageLoading(false)
                                    }, 1000)
                                }} src={` https://btrproject.burundientempsreel.com/uploads/logo/btr.png`} />
                            </div>
                            <p className="text-[14px] text-gray-500">Burundi en Temps Réel</p>
                        </div>
                        <h2 className=' p-2 text-center text-[18px]  font-serif text-gray-400 '>Se connecter</h2>
                        <p className='text-[15px] text-gray-500 pl-3 text-center'>Si vous êtes déjà membre, connectez-vous facilement maintenant.</p>

                        <div className="p-2 my-2 rounded w-full bg-transprent h-max">
                            <form onSubmit={handleSubmit}>
                                <div className="flex mb-6 flex-col ">
                                    <div className=" mt-[10px] transition-all  flex">
                                        <div class="relative h-11 w-full">
                                            <input value={emailValue} ref={elemetRefEmail}
                                                onInput={(e) => setEmailValue(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`} placeholder=" "
                                            />
                                            <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Email valide
                                            </label>
                                        </div>
                                    </div>

                                    <div className=" transition-all mt-5 flex  justify-between flexpass ">
                                        <div className={`flex items-center  w-full  relative gapps`}>
                                            <div class="relative h-11 w-full bloksInput mr-2 ">
                                                <input ref={elemetRefPass} onInput={(e) => { setPasswordValue(e.target.value) }} type={passwordVisible ? 'text' : 'password'} className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassword}`} placeholder=" " />
                                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                    Mot de passe
                                                </label>
                                            </div>
                                            <div onClick={() => { setPasswordVisible(!passwordVisible) }} className=" cursor-pointer w-[20px] h-[20px] absolute flex justify-center top-[20px] items-center right-2 ">
                                                <i class={`bi ${passwordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'} absolute`}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end items-center">




                                    {boutLoading ? (
                                        <>
                                            <div className="flex justify-end items-center  relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                                                <div className='absolute bg-transparent  pl-20 pt-5   w-full h-full flex justify-center items-center z-50'>
                                                    <FadeLoader
                                                        color="rgb(255, 255, 255)"
                                                        height={10}
                                                        margin={-9}
                                                        radius={100}
                                                        speedMultiplier={1}
                                                        width={1}
                                                    />
                                                </div>
                                                <input type="submit" id="send" value='Se connecter' className='cursor-pointer'></input>
                                                <i class="bi bi-send ml-2 "></i>
                                            </div>
                                        </>
                                    ) : (
                                        <>

                                            <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-blue-700   p-2 rounded  text-white">
                                                <input type="submit" id="send" value='Se connecter' className='cursor-pointer'></input>
                                                <i class="bi bi-send ml-2 "></i>
                                            </label>  </>
                                    )}




                                </div>
                            </form>
                        </div>
                        <div className="pl-3 text-gray-500">Vous n'avez pas un compte ? <div onClick={() => { SetSignPopUp(true); SetLoginPopUp(false) }} className='text-blue-600 cursor-pointer underline'> S'inscrire</div></div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default SeconnectMArcheUser;














