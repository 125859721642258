/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios";

function ModifierEchange() {
    const electroni = [
        { value: "", label: "Monnaie electronique" },
        { value: "Lumicash", label: "Lumicash" },
        { value: "Bancobu enoti", label: "Bancobu enoti" },
        { value: "CECM akaravyo", label: "CECM akaravyo" },
        { value: "Mobinetel", label: "Mobinetel" },
        { value: "Telemax", label: "Telemax" }
    ];
    const History = useNavigate()
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)


    const hiddenSelectroni = electroni.slice(1);
    const hiddenSelectroniDemade = electroni.slice(1);
    const [selectedElectronique, setselectedElectronique] = useState(electroni[0]);
    const [selectedElectroniqueDemade, setselectedElectroniqueDemade] = useState(electroni[0]);


    const selectedElectroniqueFonc = (option) => {
        setselectedElectronique(option)
    }
    const selectedElectroniqueDemandeFonc = (option) => {
        setselectedElectroniqueDemade(option)
    }


    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: mobile11 ? 30 : 50,
            backgroundColor: "white",

        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };



    const [transaction, Settransaction] = useState({})

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    const [animationClasselectronique, setanimationClasselectronique] = useState('');
    const elemetRefelectronique = useRef(null)

    const [Montant, SetMontant] = useState('')
    const [animationClassMontant, setAnimationClassMontant] = useState('');
    const elemetRefMontant = useRef(null)


    const [animationClasselectroniqueDemanda, setanimationClasselectroniqueDemanda] = useState('');
    const elemetRefelectroniqueDemanda = useRef(null)

    const [Code_agent, SetCode_agent] = useState('')
    const [animationClassCode_agent, setAnimationClassCode_agent] = useState('');
    const elemetRefCode_agent = useRef(null)

    const handleSubmit = (e) => {
        e.preventDefault()
        if (selectedElectronique.value == '') {
            toast.warning(' Nom de monnaie electronique que vous avez est obligatoire ', {
                autoClose: 2000
            });
            setanimationClasselectronique('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClasselectronique(' ')
            }, 3000)
            elemetRefelectronique.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;

        }
        else if (String(Montant).trim() == '') {
            toast.warning('Le montant est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassMontant('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassMontant(' ')
            }, 3000)
            elemetRefMontant.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefMontant.current.focus()
            return false
        } else if (Montant < 20000 || Montant > 5000000) {
            toast.warning('Le montant d\'un monnaie électronique   doit être compris entre 20 000 et 5 000 000.', {
                autoClose: 2000
            });
            setAnimationClassMontant('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassMontant(' ')
            }, 3000)
            elemetRefMontant.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefMontant.current.focus()
            return false
        } else if (selectedElectroniqueDemade.value == '') {
            toast.warning(' Nom de monnaie que vous voulez est obligatoire ', {
                autoClose: 2000
            });
            setanimationClasselectroniqueDemanda('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClasselectroniqueDemanda(' ')
            }, 3000)
            elemetRefelectroniqueDemanda.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false;

        } if (selectedElectronique.value === selectedElectroniqueDemade.value) {
            toast.warning(' Les monnaies que vous avez et que vous voulez , ne peuvent pas avoir le même nom ', {
                autoClose: 2000
            });
            setanimationClasselectronique('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClasselectronique(' ')
            }, 3000)
            elemetRefelectronique.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false;

        } else if (Code_agent.trim() == '') {
            toast.warning(<div>Code agent <strong>{selectedElectroniqueDemade.value}</strong> est obligatoire </div>, {
                autoClose: 2000
            });
            setAnimationClassCode_agent('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassCode_agent(' ')
            }, 3000)
            elemetRefCode_agent.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefCode_agent.current.focus()
            return false;

        }

        const data = {
            mfise: selectedElectronique.value,
            montant: Montant,
            nshaka: selectedElectroniqueDemade.value,
            codeAgent: Code_agent
        }


        axios.put(`https://btrproject.burundientempsreel.com/fashanya/${id}`, data).then((res) => {
            toast.success(res.data)
            History("/User/Echange/Echange")
        }).catch((err) => {
            if (err.response) {
                toast.warn(err.response.data[0])
                return false;
            }
            console.log(err.message)
        })
    }

    const { id } = useParams()
    useEffect(() => {
        axios.get(`https://btrproject.burundientempsreel.com/fashanya/ById/${id}`).then((response) => {
            Settransaction(response.data);
            SetCode_agent(response.data.codeAgent);
            SetMontant(response.data.montant);
            setselectedElectronique(electroni.find(option => option.value === response.data.mfise));
            setselectedElectroniqueDemade(electroni.find(option => option.value === response.data.nshaka));
        }).catch((err) => {
            console.error('Erreur lors de la récupération des fashanya :', err.message);
        })
    }, [id])

    return (

        <div className={`bg-white  w-full `}>
            <div class="my-5 w-full text-center text-[20px] sm:text-2xl font-bold ">
                Modification
            </div>
            <div class="w-full my-4 justify-center flex">
                <div class={`border border-blue-700  rounded-lg p-1 ${mobile11 ? 'w-[95%]' : 'w-[30em]'}`}>
                    <form onSubmit={handleSubmit}>
                        <div class="bg-white rounded p-1 sm:px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                            <div class="mb-6">
                                <div class="w-full px-3">
                                    <label
                                        class="block  tracking-wide text-gray-700 max-sm:text-center text-[15px] sm:text-lg font-bold mb-2"
                                    >
                                        Nom de monnaie electronique que vous avez
                                    </label>
                                    <div ref={elemetRefelectronique} className={` rounded  ${selectedElectronique.value == '' ? animationClasselectronique : 'focus:border-2 focus:border-blue-700'} `}
                                    >
                                        <Select
                                            value={selectedElectronique}
                                            options={hiddenSelectroni}
                                            styles={customStyles}
                                            onChange={selectedElectroniqueFonc}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class={`w-full mb-6`}>
                                <div class={`w-full px-1 sm:px-3`}>
                                    <label
                                        class="block  tracking-wide text-[15px] sm:text-lg font-bold mb-2"

                                    >
                                        Montant
                                    </label>
                                    <input
                                        ref={elemetRefMontant} value={Montant} onInput={(e) => SetMontant(e.target.value)}
                                        class={`appearance-none outline-none block w-full   border rounded py-1 px-2  sm:py-3 sm:px-4  ${Montant == '' ? animationClassMontant : 'focus:border-2 focus:border-blue-700'}`}
                                        id="grid-last-name"
                                        type="number"
                                        placeholder="De 20.000 à 5.000.000"
                                    />
                                </div>
                            </div>
                            <div class="mb-6">
                                <div class="w-full px-3">
                                    <label
                                        class="block  tracking-wide text-gray-700 max-sm:text-center text-[15px] sm:text-lg font-bold mb-2"
                                    >
                                        Nom de monnaie que vous voulez
                                    </label>
                                    <div ref={elemetRefelectroniqueDemanda} className={` rounded ${selectedElectronique.value === selectedElectroniqueDemade.value ? animationClasselectronique : ''}  ${selectedElectroniqueDemade.value == '' ? animationClasselectroniqueDemanda : 'focus:border-2 focus:border-blue-700'} `}
                                    >
                                        <Select
                                            value={selectedElectroniqueDemade}
                                            options={hiddenSelectroniDemade}
                                            styles={customStyles}
                                            onChange={selectedElectroniqueDemandeFonc}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div class={`w-full mb-6`}>
                                <div class={`w-full px-1 sm:px-3`}>
                                    <label
                                        class="block  tracking-wide text-[15px] sm:text-lg font-bold mb-2"

                                    >
                                        Code agent
                                    </label>
                                    <input
                                        ref={elemetRefCode_agent} value={Code_agent} onInput={(e) => SetCode_agent(e.target.value)}
                                        class={`appearance-none outline-none block w-full   border rounded py-1 px-2  sm:py-3 sm:px-4  ${Code_agent == '' ? animationClassCode_agent : 'focus:border-2 focus:border-blue-700'}`}
                                        placeholder="Code agent"
                                    />
                                </div>
                            </div>
                            <div class="mb-4 flex items-center justify-end">
                                <div class="">
                                    <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-2 sm:px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                        <p class="sm:text-lg text-[15px] font-medium leading-none text-white">
                                            Ajouter
                                        </p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
}


export default ModifierEchange;










