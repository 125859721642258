import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

function SlideNavBarsAdminSuperEchang() {
    const { pathname } = useLocation()
    const AdminCleint = /^\/Admin\/Client.*/
    const AdminAjoutCleint = /^\/Admin\/AjoutClient.*/

    const [click, setclick] = useState(false)

    return (
        <>
            <div className='w-[15em]  relative h-full bg-gray-300 transition-all border-r-2 border-blue-300 slideBars1'>
                <div className="w-full h-[10vh] flex justify-center items-end font-serif text-gray-600 border-b border-gray-200 ">
                    Tous navigation
                </div>
                <div className="w-full overflow-y-scroll overflow-x-hidden">
                    <div className="w-full h-[77vh] font-serif">
                        {pathname == "/" ? (
                            <Link to='/' className='flex  justify-start items-center relative p-1 w-full h-12    pb-2 bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Acceuil</p>
                            </Link>
                        ) : (
                            <Link to='/' className='flex text-gray-800  justify-start items-center relative p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Acceuil</p>
                            </Link>
                        )}

                        {AdminCleint.test(pathname) || AdminAjoutCleint.test(pathname) ? (
                            <Link to='/Admin/Client' className='flex    justify-start items-center relative p-1 w-full h-12   pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-book-half text-[20px] mr-4"></i>
                                <p className='sizeText'>Education</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>

                        ) : (
                            <Link to='/Admin/Client' className='flex text-gray-800  justify-start items-center relative p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-book-half text-[20px] mr-4"></i>
                                <p className='sizeText'>Education</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>
                        )}

                        {pathname == "/Super/Echange" || pathname == "/Super/AjoutAdminEchang" || pathname == "/Super/StandByAdminEchang" || pathname == "/Admin/History/Detail" ? (
                            <div className={` ${click ? 'h-[11em] transition-all ' : '  h-12  transition-all '} flex flex-col  h-12 items-start  p-1 w-full pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50`}>
                                <div onClick={(e) => { setclick(!click); e.stopPropagation() }} to='/Super/Echange' className='flex  cursor-pointer  justify-start items-center relative p-1 w-full h-12    pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-currency-exchange  text-[20px] mr-4"></i>
                                    <p className='sizeText'>Echange</p>
                                    <i class={` ${click ? 'rotate-[90deg]' : 'rotate-[0deg]'} transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>
                                </div>
                                <div className={` ${click ? 'h-[5em] opacity-100 pointer-events-auto transition-all ' : 'opacity-0 pointer-events-none hidden transition-all '} pl-[35px]  transition-all  w-full flex flex-col p-2 justify-between items-center`}>
                                    <Link to='/Super/Echange' className={` ${click ? 'h-[5em]' : 'h-[0em]'} w-full h-[0em] rounded-[2px]  hover:bg-blue-400 hover:text-fuchsia-50 `}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-person-check-fill text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>Listes</p>
                                        </div>
                                    </Link>
                                    <Link to="/Admin/NouvClient" className={` ${click ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50 `}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-person-fill-add text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>Nouveau </p>
                                        </div>
                                    </Link>

                                    <Link to="/Admin/StandBuy" className={` ${click ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50  relative`}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-person-exclamation text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>StandBuy </p>
                                            <div className="absolute -top-1 right-2 bg-blue-700 text-white w-[25px] h-[25px] rounded-full text-[10px] flex justify-center items-center">
                                                99+
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                        ) : (
                            <div className={` ${click ? 'h-[11em] transition-all ' : '  h-12  transition-all '} flex flex-col  h-12 items-start  p-1 w-full pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50`}>
                                <div onClick={(e) => { setclick(!click); e.stopPropagation() }} to='/Super/Echange' className='flex  cursor-pointer  justify-start items-center relative p-1 w-full h-12    pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-currency-exchange  text-[20px] mr-4"></i>
                                    <p className='sizeText'>Echange</p>
                                    <i class={` ${click ? 'rotate-[90deg]' : 'rotate-[0deg]'} transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>
                                </div>
                                <div className={` ${click ? 'h-[5em] opacity-100 pointer-events-auto transition-all ' : 'opacity-0 pointer-events-none hidden transition-all '} pl-[35px]  transition-all  w-full flex flex-col p-2 justify-between items-center`}>
                                    <Link to='/Super/Echange' className={` ${click ? 'h-[5em]' : 'h-[0em]'} w-full h-[0em] rounded-[2px]  hover:bg-blue-400 hover:text-fuchsia-50 `}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-person-check-fill text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>Listes Admin</p>
                                        </div>
                                    </Link>
                                    <Link to="/Admin/NouvClient" className={` ${click ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50 `}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-person-fill-add text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>Nouveau </p>
                                        </div>
                                    </Link>

                                    <Link to="/Admin/StandBuy" className={` ${click ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50  relative`}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-person-exclamation text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>StandBuy </p>
                                            <div className="absolute -top-1 right-2 bg-blue-700 text-white w-[25px] h-[25px] rounded-full text-[10px] flex justify-center items-center">
                                                99+
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )}

                        {pathname == "/Super/Echannge" || pathname == "/Super/AjoutAmi" || pathname == "/Super/StaByAdminEchang" || pathname == "/Admin/History/Detail" ? (
                            <Link to='/Super/Echannge' className='flex   justify-start items-center relative p-1 w-full h-12    pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-universal-access-circle  text-[20px] mr-4"></i>
                                <p className='sizeText'>Loisir</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>

                        ) : (
                            <Link to='/Super/Echannge' className='flex text-gray-800  justify-start items-center relative p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-universal-access-circle text-[20px] mr-4"></i>
                                <p className='sizeText'>Loisir</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>
                        )}


                        {pathname == "/Super/Echae" || pathname == "/Super/AjoutAdmi" || pathname == "/Super/StandByAinEchang" || pathname == "/Admin/History/Detail" ? (
                            <Link to='/Super/Echae' className='flex   justify-start items-center relative p-1 w-full h-12    pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-hospital text-[20px] mr-4"></i>
                                <p className='sizeText'>Sante</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>

                        ) : (
                            <Link to='/Super/Echae' className='flex text-gray-800  justify-start items-center relative p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-hospital text-[20px] mr-4"></i>
                                <p className='sizeText'>Sante</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>
                        )}


                        {pathname == "/Super/Echangee" || pathname == "/Super/AjoutAdminEcha" || pathname == "/Super/StandByAdEchang" || pathname == "/Admin/History/Detail" ? (
                            <Link to='/Super/Echangee' className='flex   justify-start items-center relative p-1 w-full h-12    pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-braces-asterisk text-[20px] mr-4"></i>
                                <p className='sizeText'>Programmation</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>

                        ) : (
                            <Link to='/Super/Echangee' className='flex text-gray-800  justify-start items-center relative p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-braces-asterisk text-[20px] mr-4"></i>
                                <p className='sizeText'>Programmation</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>
                        )}


                        {pathname == "/Admin/Comman" || pathname == "/Admin/History" || pathname == "/Admin/Command/Detail" || pathname == "/Admin/History/Detail" ? (
                            <Link to='/Admin/Comman' className='flex   justify-start items-center relative p-1 w-full h-12    pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-airplane-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Transport</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>

                        ) : (
                            <Link to='/Admin/Comman' className='flex text-gray-800  justify-start items-center relative p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-airplane-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Transport</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>
                        )}

                        {pathname == "/Admin/Comm" || pathname == "/Admin/History" || pathname == "/Admin/Command/Detail" || pathname == "/Admin/History/Detail" ? (
                            <Link to='/Admin/Comm' className='flex   justify-start items-center relative p-1 w-full h-12    pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-shop text-[20px] mr-4"></i>
                                <p className='sizeText'>Marché</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>

                        ) : (
                            <Link to='/Admin/Comm' className='flex text-gray-800  justify-start items-center relative p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-shop text-[20px] mr-4"></i>
                                <p className='sizeText'>Marché</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>
                        )}

                        {pathname == "/Admin/Comm2" || pathname == "/Admin/History" || pathname == "/Admin/Command/Detail" || pathname == "/Admin/History/Detail" ? (
                            <Link to='/Admin/Comm2' className='flex   justify-start items-center relative p-1 w-full h-12    pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-basket2-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Réservation</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>

                        ) : (
                            <Link to='/Admin/Comm2' className='flex text-gray-800  justify-start items-center relative p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-basket2-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Réservation</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>
                        )}


                        {pathname == "/Admin/Comm21" || pathname == "/Admin/History" || pathname == "/Admin/Command/Detail" || pathname == "/Admin/History/Detail" ? (
                            <Link to='/Admin/Comm21' className='flex   justify-start items-center relative p-1 w-full h-12    pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-people-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Mentor</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>

                        ) : (
                            <Link to='/Admin/Comm21' className='flex text-gray-800  justify-start items-center relative p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-people-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Mentor</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>
                        )}

                        {pathname == "/Admin/Comm210" || pathname == "/Admin/History" || pathname == "/Admin/Command/Detail" || pathname == "/Admin/History/Detail" ? (
                            <Link to='/Admin/Comm210' className='flex   justify-start items-center relative p-1 w-full h-12    pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-bug-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Agri&El</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>

                        ) : (
                            <Link to='/Admin/Comm210' className='flex text-gray-800  justify-start items-center relative p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-bug-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Agri&El</p>
                                <i class={` transition-all bi bi-chevron-right  text-[20px] absolute right-2 `}></i>

                            </Link>
                        )}
                    </div>
                </div>

            </div>
        </>
    )
}

export default SlideNavBarsAdminSuperEchang
