import React, { useEffect, useState } from 'react'
import { CLEAR_CART } from "../../../../../Redux/Action/Action";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useRef } from 'react';
import axios from 'axios';


function NewClientUserSeller() {
    const dispatch = useDispatch();
    const { pathname } = useLocation()
    const [clickSlideHidden, setclickSlideHidden] = useState(false)

    const [click, setclick] = useState(false)
    const [clickCommande, setclickCommande] = useState(false)

    const [nomClient, setnomClient] = useState('');
    const [animationClassnomClient, setAnimationClassnomClient] = useState('');
    const nomClientRef = useRef(null)

    const [emailClient, setemailClient] = useState('');
    const [animationClassemailClient, setAnimationClassemailClient] = useState('');
    const emailClientRef = useRef(null)

    const [telClient, settelClient] = useState('');
    const [animationClasstelClient, setAnimationClassetelClient] = useState('');
    const telClientRef = useRef(null)

    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const [addressClient, setaddressClient] = useState('');
    const [animationClassaddressClient, setAnimationClassaddressClient] = useState('');
    const addressClientRef = useRef(null)

    const [quantiteClient, setquantiteClient] = useState('');
    const [animationClassquantiteClient, setAnimationClassquantiteClient] = useState('');
    const quantiteClientRef = useRef(null)

    const [prixClient, SetprixClient] = useState('');
    const [animationClassprixClientt, setAnimationClassprixClient] = useState('');
    const prixClientRef = useRef(null)

    const [montantClient, setmontantClient] = useState('');
    const [animationClassaddmontantClient, setAnimationClassmontantClient] = useState('');
    const montantClientRef = useRef(null)

    const Client = /^\/Client.*/
    const Produits = /^\/Produits.*/

    useEffect(() => {
        const windowClicked = () => {
            setclick(false);
            setclickSlideHidden(false);
        };

        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        };
    }, [])

    const [price, setPrice] = useState(0);
    const getData = useSelector((state) => state.CartsReducer.Carts);
    const total = () => {
        let prices = 0;
        getData.map((ele, index) => {
            prices = Math.trunc(ele.prix) * ele.quantity + prices;
        });
        setPrice(Math.floor(prices).toLocaleString("fr-FR"));
    };
    useEffect(() => {
        total();
    }, [total]);

    let totalAmount = 0;
    const sellerId = getData[0]?.sellerId;
    const itemsInfo = [];

    getData.forEach(item => {
        const itemTotal = item.prix * item.quantity;
        totalAmount += itemTotal;

        itemsInfo.push({
            id: item.id,
            quantity: item.quantity
        });
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const regex = /^\d+(\.\d+)?$/;
        if (nomClient.trim() === '') {
            toast.warning('Le nom du client  est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassnomClient('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassnomClient('');
            }, 3000);
            nomClientRef.current.focus()
            return;
        }
        if (emailClient.trim() === '') {
            toast.warning("L'email est nécessaire", {
                autoClose: 2000
            });
            setAnimationClassemailClient('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassemailClient('');
            }, 3000);
            emailClientRef.current.focus()
            return;
        }
        if (!emailClient.trim().match(emailRegex)) {
            toast.error("L'address email est incorrect", {
                autoClose: 2000
            });
            setAnimationClassemailClient('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassemailClient(' ')
            }, 3000)
            emailClientRef.current.focus();
            e.preventDefault()
            return;
        }
        if (telClient.trim() === '') {
            toast.warning('Le telephone du client est nécessaire', {
                autoClose: 2000
            });
            setAnimationClassetelClient('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassetelClient('');
            }, 3000);
            telClientRef.current.focus()
            return;
        }
        if (addressClient.trim() === '') {
            toast.warning("L'address du client est nécessaire", {
                autoClose: 2000
            });
            setAnimationClassaddressClient('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassaddressClient('');
            }, 3000);
            addressClientRef.current.focus()
            return;
        } if (!regex.test(montantClient)) {
            toast.warning("Le Montant payé par client est nécessaire et doit être un nombre décimal", {
                autoClose: 2000
            });
            setAnimationClassmontantClient('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassmontantClient('');
            }, 3000);
            montantClientRef.current.focus()
            return;
        }


        const data = {
            nom: nomClient,
            email: emailClient,
            tel: telClient,
            montant: montantClient,
            address: addressClient,
            totalAmount: totalAmount,
            sellerId: sellerId,
            itemsInfo: itemsInfo
        }


        axios.post("https://btrproject.burundientempsreel.com/autovente", data).then((response) => {
            if (response.data.doit) {
                toast.warning(`On doit le payé ${response.data.doit} Fr`,
                    {
                        autoClose: 12000
                    });
            } else {
                toast.success(response.data,
                    {
                        autoClose: 7000
                    });
            }

            setnomClient("")
            settelClient("")
            setmontantClient("")
            setaddressClient("")
            dispatch(CLEAR_CART());
            setemailClient("")


        }).catch((error) => {
            console.log(error.message);
            if (error.response) {
                console.log(error.response.data)
            }
        });

    }


    return (
        <>

            <div className="w-full  flex justify-center pb-20">
                <div className={`border rounded border-blue-600 p-2 mt-3  bg-white shadow-2xl  w-[25em] responsive3Form1 `}>
                    <form onSubmit={handleSubmit}>
                        <div className="w-full ">
                            <div className="transition-all  w-[100%]">
                                <div className="flex justify-around flexVentes">
                                    <div className="  w-full p-2 border-r-2 border-white parts1">
                                        <div className="relative h-11 w-full InputsContact mt-5">
                                            <input ref={nomClientRef}

                                                value={nomClient}
                                                onInput={(e) => setnomClient(e.target.value)}
                                                className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassnomClient}`}
                                                placeholder=" "
                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Nom du Client
                                            </label>
                                        </div>


                                        <div className="relative h-11 w-full InputsContact mt-5">
                                            <input ref={emailClientRef}

                                                value={emailClient}
                                                onInput={(e) => setemailClient(e.target.value)}
                                                className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassemailClient}`}
                                                placeholder=" "
                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Email
                                            </label>
                                        </div>

                                        <div className="relative h-11 w-full InputsContact mt-5">
                                            <input ref={telClientRef}
                                                type='tel'
                                                value={telClient}
                                                onInput={(e) => settelClient(e.target.value)}
                                                className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClasstelClient}`}
                                                placeholder=" "
                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Telephone
                                            </label>
                                        </div>
                                        <div className="relative h-11 w-full InputsContact mt-5 ">
                                            <input ref={addressClientRef}
                                                value={addressClient}
                                                onInput={(e) => setaddressClient(e.target.value)}
                                                className={`peer pointer-events-auto h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassaddressClient}`}
                                                placeholder=" "

                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Address
                                            </label>
                                        </div>
                                        <div className="relative h-11 w-full InputsContact mt-5 ">
                                            <input ref={montantClientRef}
                                                value={montantClient}
                                                onInput={(e) => setmontantClient(e.target.value)}
                                                className={`peer pointer-events-auto h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassaddmontantClient}`}
                                                placeholder=" "

                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Montant payé
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end items-center mt-[20px]">
                                    <div className="flex bg-blue-600 rounded cursor-pointer pl-2 py-1 text-white">
                                        <input type="submit" id="send" hidden />
                                        <label htmlFor="send" className="w-max flex justify-end p-1 cursor-pointer mx-1 ">
                                            <span className='mr-2'>Ajouter</span>
                                            <div className="bi bi-send"></div>
                                        </label>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default NewClientUserSeller
