import React from 'react'
import MotAcceuilUserEchange from '../../../component/User/EchangeUser/MotAcceuilEchanges/MotAcceuilUserEchange'
function MotAcceuilUserEchangePage({ isScrollRemoveBarEcranPetit, SetisScrollRemoveBarEcranPetit, isdivScroll, SetdivScroll }) {
  return (
    <MotAcceuilUserEchange
      isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit}
      SetdivScroll={SetdivScroll}
      isdivScroll={isdivScroll}
      SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit}
    />
  )
}

export default MotAcceuilUserEchangePage
