import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useRef } from 'react';
import axios from 'axios';

function NewDepenesMarcheUser() {
    const { pathname } = useLocation()
    const [clickSlideHidden, setclickSlideHidden] = useState(false)


    const [click, setclick] = useState(false)
    const [clickCommande, setclickCommande] = useState(false)


    const [montant, setMontant] = useState('');
    const [animationClassnomProduits, setAnimationClassnomProduits] = useState('');
    const nomProduitsRef = useRef(null)

    const [textarea, setTextarea] = useState('');
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const textaresRef = useRef(null)

    const [autoScrollHeight, setAutoScrollHeight] = useState(50);
    const [valueTextarea, setValueTextarea] = useState('');

    const Client = /^\/Client.*/
    const Produits = /^\/Produits.*/
    const userId = localStorage.getItem('userid');
    useEffect(() => {
        const windowClicked = () => {
            setclick(false);
            setclickSlideHidden(false);
        };

        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        };
    }, [])


    const handleSubmit = (e) => {
        e.preventDefault();
        if (montant.trim() === '') {
            toast.warning('Entre le motant de la depense', {
                autoClose: 2000
            });
            setAnimationClassnomProduits('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassnomProduits('');
            }, 3000);
            nomProduitsRef.current.focus()
            return;
        }
        if (isNaN(parseFloat(montant))) {
            toast.warning('Le montant doit être un nombre décimal', {
                autoClose: 2000
            });
            setAnimationClassnomProduits('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassnomProduits('');
            }, 3000);
            nomProduitsRef.current.focus()
            return;
        }
        if (textarea.trim() === '') {
            toast.warning('La description de la depense est nécessaire', {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassTextarea('');
            }, 3000);
            textaresRef.current.focus()
            return;
        }
        const data = {
            userId: userId,
            montant: montant,
            description: textarea
        }
        axios.post('https://btrproject.burundientempsreel.com/depense/new', data).then((response) => {
            toast.success('Votre dépense a été enregistrée avec succès', {
                autoClose: 5000
            });
            setMontant("")
            setTextarea("")
        }).catch((err) => {
            console.log(err.message);
            if (err.response) {
                console.log(err.response.data);
            }
        })
    }


    return (
        <>


            <div className='bg-gray-200 sticky top-0 z-1 flex justify-between px-10 items-center border-blue-300 border-b paddings'>
                <div onClick={(e) => { setclickSlideHidden(!clickSlideHidden); e.stopPropagation(); }} className=" ml-2 menus bi bi-list cursor-pointer hover:bg-black hidden  hover:text-white transition-all h-max text-[20px] px-2 py-1"></div>
                <Link
                    to="/User/Depenses"
                    className=" left-0 flex bg-blue-500 text-white px-2 py-1 rounded cursor-pointer"
                >
                    <i class="bi bi-arrow-return-left mr-2"></i>
                    <p className='hiddess'>Retour</p>
                </Link>
                <div className="w-full overflow-hidden h-11 flex justify-center items-center mr-2">
                    <div className="text-gray-500 mot1">   Ajouter une nouvelle depences</div>
                </div>

            </div>




            <div className="w-full  flex justify-center h-[30em] pb-20">
                <div className={`border-2 rounded border-blue-600 p-2 mt-3 h-max  w-[25em] responsive3Form `}>
                    <form onSubmit={handleSubmit}>
                        <div className="mt-6 w-full ">
                            <div className="transition-all  w-[100%]">
                                <div className="relative h-11 w-full InputsContact mr-2">
                                    <input ref={nomProduitsRef}
                                        value={montant}
                                        onInput={(e) => setMontant(e.target.value)}
                                        className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassnomProduits}`}
                                        placeholder=" "
                                    />
                                    <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Le Montant
                                    </label>
                                </div>
                                <div className="relative h-full w-full mt-10">
                                    <textarea ref={textaresRef}
                                        value={textarea}
                                        onChange={(e) => {
                                            setTextarea(e.target.value);
                                            setAutoScrollHeight(e.target.scrollHeight);
                                            setValueTextarea(e.target.value.trim().length);
                                            if (!valueTextarea) {
                                                setAutoScrollHeight(50);
                                            }
                                        }}
                                        placeholder=" "
                                        style={{ height: `${autoScrollHeight}px` }}
                                        className={`peer personalTextarea leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassTextarea}`}
                                    />
                                    <label
                                        className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-blue-gray-500`}
                                    >
                                        Description de depanse
                                    </label>
                                </div>

                                <div className="flex justify-end items-center mt-[20px]">
                                    <div className="flex bg-blue-600 rounded cursor-pointer pl-2 py-1 text-white">
                                        <input type="submit" id="send" hidden />
                                        <label htmlFor="send" className="w-max flex justify-end p-1 cursor-pointer mx-1 ">
                                            <span className='mr-2'>Ajouter</span>
                                            <div className="bi bi-send"></div>
                                        </label>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default NewDepenesMarcheUser
