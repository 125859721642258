import React from "react";
import UpdateForm from "./UpdateForm";
function UpdateProdComponent() {
  return (
    <>


      <div class="flex flex-row justify-center container w-full pt-6 pb-8 px-2">
        <UpdateForm />
      </div>
    </>
  );
}

export default UpdateProdComponent;
