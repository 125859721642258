/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import Select from "react-select"
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FadeLoader } from 'react-spinners'

const AjoutertFraistransactionAdmi = () => {
    const [boutLoading, setboutLoading] = useState(false);


    const [mobile, SetMobile] = useState(window.innerWidth <= 640)
    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    const MedicOptions = [
        { value: "", label: "Sélectionner un médicament" },
        { value: "Lumicash", label: "Lumicash" },
        { value: "Bancobu enoti", label: "Bancobu enoti" },
        { value: "CECM akaravyo", label: "CECM akaravyo" },
        { value: "Mobinetel", label: "Mobinetel" },
        { value: "Telemax", label: "Telemax" }
    ]

    const hiddenSMedicOptions = MedicOptions.slice(1);
    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: mobile ? 25 : 50,
            backgroundColor: "white",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };

    const [blocs, setBlocs] = useState([
        { electronique: '', min: '', max: '', Enregistre: '', FraitRetrait: '', NonEnregistre: "" }
    ]);
    const addBloc = () => {
        setBlocs([...blocs, { electronique: '', min: '', max: '', Enregistre: '', NonEnregistre: '', FraitRetrait: '' }]);
    };
    const removeBloc = (index) => {
        const newBlocs = [...blocs];
        newBlocs.splice(index, 1);
        setBlocs(newBlocs);
    };
    const inputRefs = useRef([]);
    if (inputRefs.current.length !== blocs.length) {
        inputRefs.current = Array(blocs.length).fill({}).map((_, index) => ({
            electronique: React.createRef(null),
            min: React.createRef(null),
            max: React.createRef(null),
            Enregistre: React.createRef(null),
            NonEnregistre: React.createRef(null),
            FraitRetrait: React.createRef(null),
        }));
    }

    const handleInputChange = (index, field, value) => {
        const newBlocs = [...blocs];
        newBlocs[index][field] = value;
        setBlocs(newBlocs);
        if (value && inputRefs.current[index][field].current) {
            inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500');
        }
    };

    const validateBloc = (bloc, index) => {
        const requiredFields = ['electronique', 'min', 'max', 'Enregistre', 'NonEnregistre', 'FraitRetrait'];

        //Validation pour les champs vide
        for (const field of requiredFields) {
            if (!bloc[field]) {
                toast.warning(<div>Le <strong>{field == 'electronique' ? 'nom du électronique ' : field == 'min' ? 'montant minimum ' : field == 'max' ? 'montant maximum' : field == 'Enregistre' ? 'montant s\'il est enregistre' : field == 'NonEnregistre' ? 'montant s\'il n\'est pas enregistre' : field == 'FraitRetrait' ? 'frait de retrait' : null}</strong> est obligatoire dans le bloc {index + 1}.</div>);
                if (inputRefs.current[index][field].current) {
                    inputRefs.current[index][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    setTimeout(() => {
                        inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    }, 3000);
                    inputRefs.current[index][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    inputRefs.current[index][field].current.focus();
                    return false;
                }
            }
        }
        return true;
    };



    function toRoman(num) {
        const romanNumerals = [
            "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X",
            "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX",
            "XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", "XXIX", "XXX"
        ];
        return romanNumerals[num - 1] || num.toString();
    }

    const Datas = {
        rubriques: blocs.map((bloc) => ({
            type: bloc.electronique.value,
            min: bloc.min,
            max: bloc.max,
            yanditswe: bloc.Enregistre,
            atanditswe: bloc.NonEnregistre,
            kubikura: bloc.FraitRetrait
        }))
    };
    const History = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault();
        for (let index = 0; index < blocs.length; index++) {
            if (!validateBloc(blocs[index], index)) {
                return;
            }
        }
        setboutLoading(true)
        axios.post("https://btrproject.burundientempsreel.com/rubrique", Datas).then((res) => {
            toast.success(res.data)
            History("/Admin/fraistransaction")
        }).catch((err) => {
            if (err.response) {
                toast.warn(err.response.data[0])
                return false;
            }
            console.log(err.message)
        }).finally(() => {
            setboutLoading(false)
        })
    };


    return (
        <div className={`bg-white overflow-y-auto overflow-x-hidden w-full`}>
            <div className="sm:p-4 p-1 min-h-screen">
                <div>
                    <Link to='/Admin/fraistransaction' className='bg-blue-600 text-white px-2 py-1'>
                        Retour
                    </Link>
                </div>
                <div className="my-5 w-full">
                    <div className="w-full flex flex-row items_center justify-between">
                        <p className="text-navy-700 shrink w-full">
                            <p className="hover-text-navy-700 w-full text-center text-[18px] sm:text-xl font-bold first_letter:uppercase text-gray-500">
                                Ajoutez rubrique des frais de transaction
                            </p>
                        </p>
                    </div>
                </div>
                <div className="w-full my-4 grid grid-cols-1">
                    <div className="w-full my-4 grid grid-cols-1">
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <div className="rounded-lg p-2 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">
                                {blocs.map((bloc, index) => (
                                    <div key={index} className="justify_center rounded-xl w-full  p-1 sm:p-2 mb-10  border-[1px] flex flex-col">
                                        <div className="w-full">
                                            <div className="h-max w-full">
                                                <div className="px-3  w-full">
                                                    <div className="w-full flex items_center justify-between">
                                                        <label className="block  text-xs mt-5 font-serif text-gray-700 first_letter:uppercase tracking_wide text-grey-darker sm:text-lg  mb-1">
                                                            {toRoman(index + 1)}. Rubrique
                                                        </label>
                                                        {index > 0 && (
                                                            <button
                                                                min="button"
                                                                className="text-red-500 px-1 max-sm:text-xs py-2"
                                                                onClick={() => removeBloc(index)}
                                                            >
                                                                Supprimer
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-2">
                                            <div className="rounded-xl p-2 w-full">
                                                <div className="flex flex-col">
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                                Nom monnaie
                                                            </label>
                                                            <div ref={inputRefs.current[index].electronique} className={` rounded `}>
                                                                <Select
                                                                    value={bloc.electronique}
                                                                    options={hiddenSMedicOptions}
                                                                    styles={customStyles}
                                                                    onChange={(option) => handleInputChange(index, "electronique", option)} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="w-full mb-5">
                                                        <label class="block   tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">Minimum</label>
                                                        <input
                                                            type="number"
                                                            className={` outline-none block w-full bg-grey-lighter max-sm:text-xs  border rounded py-2 sm:py-3 px-4 mb-3  `}
                                                            placeholder="Minimum"
                                                            value={bloc.min}
                                                            onChange={(e) => handleInputChange(index, 'min', e.target.value)}
                                                            ref={inputRefs.current[index].min}
                                                        />
                                                    </div>

                                                    <div className="w-full mb-5">
                                                        <label class="block   tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">Maximum</label>
                                                        <input
                                                            type="number"
                                                            className={` outline-none block w-full bg-grey-lighter max-sm:text-xs  border rounded py-2 sm:py-3 px-4 mb-3  `}
                                                            placeholder="Maximum"
                                                            value={bloc.max}
                                                            onChange={(e) => handleInputChange(index, 'max', e.target.value)}
                                                            ref={inputRefs.current[index].max}
                                                        />
                                                    </div>
                                                    <div className="w-full mb-5">
                                                        <label class="block   tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">Enregistre</label>
                                                        <input
                                                            type="text"
                                                            className={` outline-none block w-full bg-grey-lighter max-sm:text-xs  border rounded py-2 sm:py-3 px-4 mb-3  `}
                                                            placeholder={`S'il est enregistre `}
                                                            value={bloc.Enregistre}
                                                            onChange={(e) => handleInputChange(index, 'Enregistre', e.target.value)}
                                                            ref={inputRefs.current[index].Enregistre}
                                                        />
                                                    </div>
                                                    <div className="w-full mb-5">
                                                        <label class="block   tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">Non enregistre </label>
                                                        <input
                                                            type="text"
                                                            className={` outline-none block w-full bg-grey-lighter max-sm:text-xs  border rounded py-2 sm:py-3 px-4 mb-3  `}
                                                            placeholder={`S'il n'est pas enregistre `}
                                                            value={bloc.NonEnregistre}
                                                            onChange={(e) => handleInputChange(index, 'NonEnregistre', e.target.value)}
                                                            ref={inputRefs.current[index].NonEnregistre}
                                                        />
                                                    </div>
                                                    <div className="w-full mb-5">
                                                        <label class="block   tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">Frait de retrait</label>
                                                        <input
                                                            type="text"
                                                            className={` outline-none block w-full bg-grey-lighter max-sm:text-xs  border rounded py-2 sm:py-3 px-4 mb-3  `}
                                                            placeholder={`Frait de retrait `}
                                                            value={bloc.FraitRetrait}
                                                            onChange={(e) => handleInputChange(index, 'FraitRetrait', e.target.value)}
                                                            ref={inputRefs.current[index].FraitRetrait}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="w-full flex justify-end">
                                    <button min="button" className="bg-blue-500 hover-bg-blue-700 text-white py-1 px-2  max-sm:text-xs sm:py-2 sm:px-4 rounded" onClick={addBloc}>
                                        Ajouter un  electron
                                    </button>
                                </div>

                            </div>
                            <div className=" flex justify-end  md:w-[40em] w-[95%]">
                                {boutLoading ? (
                                    <>
                                        <label disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-orange-950    p-2 rounded  text-gray-400">
                                            <input type="submit" id="send" value='Soumettre' className='pointer-events-none' />
                                            <i class="bi bi-send ml-2  pointer-events-none "></i>
                                            <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </label>
                                    </>
                                ) : (<>
                                    <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-orange-600   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Soumettre' className='cursor-pointer'></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>
                                </>)}

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AjoutertFraistransactionAdmi;






