// Import des dépendances
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
import Empty from "../Empty/Empty";
import PropTypes from "prop-types";

function Product_List({ isdivScroll }) {
  const history = useNavigate();
  // État pour stocker les données des produits
  const [data, setData] = useState([]);
  // État pour stocker le numéro de page actuel
  const [currentPage, setCurrentPage] = useState(1);
  // État pour stocker le nombre total de pages
  const [totalPages, setTotalPages] = useState(1);
  let [loading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(72);


  const [productSearch, setproductSearch] = useState([]);
  const [categoryMenu, setcategoryMenu] = useState(false)



  useEffect(() => {
    function hundleScrolls() {
      setcategoryMenu(false)
    }
    function hundleClick() {
      setcategoryMenu(false)
    }

    if (isdivScroll.current) {
      isdivScroll.current.addEventListener('scroll', hundleScrolls)
    }
    window.addEventListener('click', hundleClick)

    return () => {
      if (isdivScroll.current) {
        isdivScroll.current.removeEventListener('scroll', hundleScrolls)
        window.removeEventListener('click', hundleClick)
      }
    }
  }, [])

  const handleItemsPerPageChange = (membre) => {
    const selectedItemsPerPage = parseInt(membre.target.value);
    setItemsPerPage(selectedItemsPerPage);
  };
  const iduser = localStorage.getItem("userid");
  // Fonction pour passer à la page suivante
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };


  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Fonction pour changer de page lors du clic sur un numéro de page spécifique
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  // Générer la liste de numérotation des pages
  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={currentPage === i ? "text-sm font-medium leading-none cursor-pointer bg-blue-600 text-white w-[30px] h-[30px] pb-[20px] rounded-full dark:text-gray-200  dark:hover:text-blue-400 border-t border-transparent hover:border-red-500 pt-3 mr-4 px-2" : "text-sm font-medium  leading-none cursor-pointer w-[30px] h-[30px] pb-[20px] bg-gray-400 transition-all  text-white hover:bg-gray-600  rounded-full dark:text-gray-200  dark:hover:text-blue-400 border-t border-transparent hover:border-blue-400 pt-3 mr-4 px-2"}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };


  function handleDelete(id, index) {
    Swal.fire({
      title: 'Confirmation',
      text: 'Etes-vous sûr que vous voulez supprimer?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'SUPPRIMER',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'swal-confirm-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(` https://btrproject.burundientempsreel.com/market/seller/deleteproduct/${id}`)
          .then((response) => {
            if (response.status === 200) {
              toast.success('le produit a été supprimé avec succès');
              const alldata = [...data]
              alldata.splice(index, 1)
              setData(alldata)
            } else {
              toast.error("Une erreur s'est produite lors de la suppression du produit");
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error("Une erreur s'est produite lors de la suppression du produit verrifier si votre produit est command");
          });
      }
    });
  }
  const LazyImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };
    const lazyLoad = () => {
      setImgSrc(src);
    };

    const elementInViewPort = () => {
      // getBoundingClientRect => returns the size of the given element and the position of it in relation to the view port
      const clientRect = imageRef.current.getBoundingClientRect();

      return (
        clientRect.top >= 0 &&
        clientRect.left >= 0 &&
        clientRect.bottom - 100 <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        clientRect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LazyImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };






  const [titreseach, settitreseach] = useState("");
  const [searching, setSearching] = useState(false);
  // Fonction de recherche
  const search = (titre) => {
    if (titre) {
      setSearching(true);
      axios
        .get(" https://btrproject.burundientempsreel.com/market/seller/searchproductbuytitle", {
          params: { id: iduser, titre: titre },
        })
        .then((response) => {
          if (response.data === "Produit introuvable !") {
            toast.error("Produit est introuvable", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setproductSearch([]);
            setTimeout(() => {
              setLoading(false)
            }, 1000);
            // Mettre à jour avec un tableau vide
          } else {
            setproductSearch(response.data);
          }
          setSearching(false); // Indiquer que la recherche est terminée
        })
        .catch((err) => {
          console.error("Erreur lors de la recherche du produit", err);
          setproductSearch([]);
          setSearching(false);
        });
    } else {
      setproductSearch([]);
    }
  };
  const [category, setCategory] = useState("");
  const [datanotsearch, setdatanotsearch] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setproductSearch([])
      try {
        if (category) {
          const response = await axios.get(` https://btrproject.burundientempsreel.com/market/seller/productsbycategory/${iduser}/${category}?page=${currentPage}&size=${itemsPerPage}`);
          const { products, totalPages } = response.data;
          setData(products);
          setTotalPages(totalPages);
          setdatanotsearch(false)
        } else {
          const response = await axios.get(` https://btrproject.burundientempsreel.com/market/seller/getallproduct/${iduser}?page=${currentPage}&size=${itemsPerPage}`);
          const { products, totalPages } = response.data;
          setData(products);
          setTotalPages(totalPages);
          setdatanotsearch(true)
          const timer = setTimeout(() => {
            setLoading(false);
          }, 100);
          return () => clearTimeout(timer);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchData();
  }, [category, currentPage, itemsPerPage]);

  const handleCategoryClick = (category) => {
    setCategory(category);
    setcategoryMenu(false);
  };

  return (
    <>
      <div class="w-full relative">
        {loading && <SpinnerDemarage />}
        <div class="flex items-center z-10 sticky top-0 space-y-3 p-3 bg-gray-100 ">
          <div class="flex justify-between items-center gap-2  pb-1 w-full ml-auto">
            <div
              onClick={(e) => {
                setcategoryMenu(true)
                e.stopPropagation()
              }}
              class="bg-cream h-10           
              items-center bi bi-list-nested py-1 px-2 text-[30px] pb-11 bg-blue-400 text-white font-semibold rounded-lg hover:shadow-lg transition duration-3000 cursor-pointer"
            >

            </div>


            <>
              <div className={`sm:w-[20em] w-[100%] slideMarche ${categoryMenu ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}  top-0  pt-2 left-0 absolute z-[50] h-full bg-gray-300 transition-all border-r-2 border-blue-500`}>
                <div className="w-full relative h-12  pl-4  font-serif text-gray-500 border-b border-gray-200 ">
                  <button onClick={(e) => { setcategoryMenu(false); e.stopPropagation(); }} className='bi bi-chevron-double-left text-white hover:bg-blue-600  bg-blue-400   transition-all font-bold absolute top-0 right-2  px-[4px] py-[1px] rounded '></button>
                  <p>Catégories</p>
                </div>

                <div className={` w-full overflow-y-auto flex overflow-x-hidden bg-gray-300`}>
                  <div className="w-full h-[70vh] font-serif">

                    <div onClick={() => handleCategoryClick("")}

                      className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 ${category === "" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''}`}>

                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className='sizeText'>Tous les catégories</p>
                    </div>

                    <div onClick={() => handleCategoryClick("Appareils électronique")} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Appareils électronique" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>

                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className='sizeText'>Appareils électronique</p>
                    </div>
                    <div onClick={() => { handleCategoryClick("Appreils de construction"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Appreils de construction" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>

                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className='sizeText'>Appreils de construction</p>
                    </div>
                    <div onClick={() => { handleCategoryClick("Appreils maisons et cuisines"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Appreils maisons et cuisines" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>

                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className='sizeText'>Appreils maisons et cuisines</p>
                    </div>

                    <div onClick={() => { handleCategoryClick("Magazin"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Magazin" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>
                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className='sizeText'>Magazin</p>
                    </div>

                    <div onClick={() => { handleCategoryClick("Vehicule"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Vehicule" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>
                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className='sizeText'>Vehicule</p>
                    </div>
                    <div onClick={() => { handleCategoryClick("Immeubles"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Immeubles" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>
                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className='sizeText'>Immeubles</p>
                    </div>

                    <div onClick={() => { handleCategoryClick("Industriels"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Industriels" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>
                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className='sizeText'>Industriels</p>
                    </div>

                    <button onClick={() => { handleCategoryClick("Arts et Artisanat"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Arts et Artisanat" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>
                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className='sizeText'>Arts et Artisanat</p>
                    </button>


                    <div onClick={() => { handleCategoryClick("Jouets"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Jouets" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>
                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className='sizeText'>Jouets</p>
                    </div>

                  </div>
                </div>

              </div>
            </>


            <div className="flex">
              <div class="flex  h-12 bg-white p-2 sm:w-[20em]  space-x-4 inputwidth ">
                <i class="bi bi-search"></i>
                <input
                  value={titreseach}
                  onChange={(e) => {
                    settitreseach(e.target.value);
                    search(e.target.value);
                  }}
                  class="bg-gray-100 outline-none w-full pl-2"
                  type="text"
                  placeholder="Nom de l'article ou titre..."

                />
              </div>
              <select
                class="px-2 h-12 text-black border border-blue-700 font-serif rounded transition duration-3000 cursor-pointer"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option>5</option>
                <option>10</option>
                <option>12</option>
                <option>20</option>
                <option>24</option>
                <option>30</option>
                <option>36</option>
                <option>40</option>
                <option>48</option>
                <option>50</option>
                <option>60</option>
                <option>70</option>
                <option>72</option>
                {data.length > 72 && (
                  <option>{data.length}</option>
                )}
              </select>

            </div>
          </div>
        </div>



        {(data.length > 0 || productSearch.length > 0) ? (
          productSearch.length > 0 ? (
            <div className="col-span-3  m-3">
              <div className="grid  sm:grid-cols-3  md:grid-cols-3 lg:grid-cols-4  xl:grid-cols-5 grid-cols-2 clsgrid gap-6">
                {productSearch.map((item, index) => {
                  return (
                    <div className="rounded-2xl border border-gray-300 shadow overflow-hidden group">
                      <div className="relative h-30 overflow-hidden">
                        <div className="h-56">
                          <LazyImage
                            key={index}
                            alt=''
                            src={` https://btrproject.burundientempsreel.com/uploads/market/${item.profil}`}
                          />
                        </div>

                      </div>
                      <div className="pt-4 pb-3 px-4">
                        <Link to={`/User/Marche/PlusPhotos/Detail/${item.id}`}>
                          <h4 className="font-medium text-base mb-2 text-gray-800 hover:text-blue-500 transition">
                            {item.titre.length >= 40
                              ? item.titre.substring(0, 40) + "...."
                              : item.titre}
                            {item.titre.length < 20 ? <div className="py-3"></div> : null}
                          </h4>
                        </Link>
                        <div className="flex items-baseline mb-1 space-x-2">
                          <p className="text-base text-blue-500 font-semibold">
                            {item.prix} Fbu
                          </p>
                          <p className="text-sm text-gray-400 line-through">
                            {item.prix + (item.prix * 20 / 100)} Fbu
                          </p>
                        </div>
                        <div className="flex items-baseline mb-1 space-x-2">
                          <p className="text-xs text-gray-400 font-thin">
                            Disponible en stock :{item.quanite} articles
                          </p>
                          <p className="text-xs text-gray-400 flex font-thin">
                            Valide :   {(item.Active == 1) ? (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all  text-white bg-green-500 rounded-full" viewBox="0 0 16 16">
                              <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                              <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                            </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x  text-white bg-red-900 rounded-full" viewBox="0 0 16 16">
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>)}
                          </p>
                        </div>

                        <div className="flex items-center">
                          <div className="flex gap-1 text-sm text-blue-400">
                            <span>
                              <i className="fa-solid fa-star"></i>
                            </span>
                            <span>
                              <i className="fa-solid fa-star"></i>
                            </span>
                            <span>
                              <i className="fa-solid fa-star"></i>
                            </span>
                            <span>
                              <i className="fa-solid fa-star" />
                            </span>
                            <span>
                              <i className="fa-solid fa-star" />
                            </span>
                          </div>
                        </div>

                      </div>
                      <div className="w-full text-white bg-gray-200 rounded-2xl  p-1 flex justify-between items-center "  >
                        <Link to={`/User/Marche/Modif/${item.id}`} className=" bg-blue-500 p-2 rounded-2xl hover:bg-blue-600" >
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-down-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M9.636 2.5a.5.5 0 0 0-.5-.5H2.5A1.5 1.5 0 0 0 1 3.5v10A1.5 1.5 0 0 0 2.5 15h10a1.5 1.5 0 0 0 1.5-1.5V6.864a.5.5 0 0 0-1 0V13.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                            <path fill-rule="evenodd" d="M5 10.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1H6.707l8.147-8.146a.5.5 0 0 0-.708-.708L6 9.293V5.5a.5.5 0 0 0-1 0v5z" />
                          </svg>
                        </Link>

                        <Link to={`/User/Marche/PlusPhotos/${item.id}`} className=" bg-blue-500 p-2 rounded-2xl hover:bg-blue-600" >
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16">
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                          </svg>
                        </Link>
                        <button
                          onClick={() => handleDelete(item.id, index)}
                          className="bg-red-700 hover:bg-red-800 p-2 rounded-2xl cursor-pointer"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                          </svg>
                        </button>

                      </div>
                    </div>
                  );
                })}
              </div>
            </div >
          )
            : data.length > 0 && (
              <div className="col-span-3  m-3">
                <div className="grid  sm:grid-cols-3  md:grid-cols-3 lg:grid-cols-4  xl:grid-cols-5 grid-cols-2 clsgrid gap-6">
                  {data.map((item, index) => {
                    return (
                      <div className="rounded-2xl border border-gray-300 shadow overflow-hidden group">
                        <div className="relative h-30 overflow-hidden">
                          <div className="h-56">
                            <LazyImage
                              key={index}
                              alt=''
                              src={` https://btrproject.burundientempsreel.com/uploads/market/${item.profil}`}
                            />
                          </div>

                        </div>
                        <div className="pt-4 pb-3 px-4">
                          <Link to={`/User/Marche/PlusPhotos/Detail/${item.id}`}>
                            <h4 className="font-medium text-base mb-2 text-gray-800 hover:text-blue-500 transition">
                              {item.titre.length >= 40
                                ? item.titre.substring(0, 40) + "...."
                                : item.titre}
                              {item.titre.length < 20 ? <div className="py-3"></div> : null}
                            </h4>
                          </Link>
                          <div className="flex items-baseline mb-1 space-x-2">
                            <p className="text-base text-blue-500 font-semibold">
                              {item.prix} Fbu
                            </p>
                            <p className="text-sm text-gray-400 line-through">
                              {item.prix + (item.prix * 20 / 100)} Fbu
                            </p>
                          </div>
                          <div className="flex items-baseline mb-1 space-x-2">
                            <p className="text-xs text-gray-400 font-thin">
                              Disponible en stock :{item.quanite} articles
                            </p>
                            <p className="text-xs text-gray-400 flex font-thin">
                              Valide :   {(item.Active == 1) ? (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all  text-white bg-green-500 rounded-full" viewBox="0 0 16 16">
                                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                              </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x  text-white bg-red-900 rounded-full" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                              </svg>)}
                            </p>
                          </div>

                          <div className="flex items-center">
                            <div className="flex gap-1 text-sm text-blue-400">
                              <span>
                                <i className="fa-solid fa-star"></i>
                              </span>
                              <span>
                                <i className="fa-solid fa-star"></i>
                              </span>
                              <span>
                                <i className="fa-solid fa-star"></i>
                              </span>
                              <span>
                                <i className="fa-solid fa-star" />
                              </span>
                              <span>
                                <i className="fa-solid fa-star" />
                              </span>
                            </div>
                          </div>

                        </div>
                        <div className="w-full text-white bg-gray-200 rounded-2xl  p-1 flex justify-between items-center "  >
                          <Link to={`/User/Marche/Modif/${item.id}`} className=" bg-blue-500 p-2 rounded-2xl hover:bg-blue-600" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-down-left" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M9.636 2.5a.5.5 0 0 0-.5-.5H2.5A1.5 1.5 0 0 0 1 3.5v10A1.5 1.5 0 0 0 2.5 15h10a1.5 1.5 0 0 0 1.5-1.5V6.864a.5.5 0 0 0-1 0V13.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                              <path fill-rule="evenodd" d="M5 10.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1H6.707l8.147-8.146a.5.5 0 0 0-.708-.708L6 9.293V5.5a.5.5 0 0 0-1 0v5z" />
                            </svg>
                          </Link>

                          <Link to={`/User/Marche/PlusPhotos/${item.id}`} className=" bg-blue-500 p-2 rounded-2xl hover:bg-blue-600" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16">
                              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                          </Link>
                          <button
                            onClick={() => handleDelete(item.id, index)}
                            className="bg-red-700 hover:bg-red-800 p-2 rounded-2xl cursor-pointer"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                            </svg>
                          </button>

                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4">
                  <div className="lg:w-3/5 w-full flex items-center justify-between border-t border-gray-200 dark:border-gray-700">
                    <div className="flex items-center pt-3 mr-4 text-gray-600 dark:text-gray-200 hover:text-blue-700 cursor-pointer">
                      <svg
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.1665 4H12.8332"
                          stroke="currentColor"
                          stroke-width="1.25"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.1665 4L4.49984 7.33333"
                          stroke="currentColor"
                          stroke-width="1.25"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.1665 4.00002L4.49984 0.666687"
                          stroke="currentColor"
                          stroke-width="1.25"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <button onClick={previousPage} disabled={currentPage === 1} className="text-sm ml-3 text-black  hover:text-blue-700 cursor-pointer">Previous</button>
                    </div>
                    <div className="flex mt-3">
                      {generatePageNumbers()}

                    </div>
                    <div className="flex items-center pt-3 ml-4 text-black  hover:text-blue-700 cursor-pointer">
                      <button onClick={nextPage} disabled={currentPage === totalPages} className="text-sm font-medium leading-none mr-3">Next</button>
                      <svg
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.1665 4H12.8332"
                          stroke="currentColor"
                          stroke-width="1.25"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.5 7.33333L12.8333 4"
                          stroke="currentColor"
                          stroke-width="1.25"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.5 0.666687L12.8333 4.00002"
                          stroke="currentColor"
                          stroke-width="1.25"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div >
            )
        )

          :
          <Empty item="produit" />
        }
      </div>
    </>
  );
}

export default Product_List;


