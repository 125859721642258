import React from 'react'
import ConfirmPayment from './ConfirmPayment'
function ContainerContentPayment() {
  return (
    <>
      <ConfirmPayment/>
    </>
  )
}

export default ContainerContentPayment
