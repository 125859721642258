import React from "react";
import AllCarts from "./AllCarts";
function Content_Container() {
  return (
    <>
      {/* <!-- container --> */}
      <div class=" bg-transparent  px-4  text-gray-700 bg-gray-200 ">
        <div class=" h-full bg-transparent items-start">
          <AllCarts />
        </div>
      </div>
    </>
  );
}

export default Content_Container;
