/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import { Radar, Line, Bar, Bubble, Doughnut, Pie, PolarArea, Scatter } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

function ChartJsGraphis({ charData }) {
    const [Value, SetValue] = useState()

    const options = {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
            y: {
                type: 'linear',
                grid: {
                    display: true,
                    drawBorder: false,
                },
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Quantités', // Titre de l'axe Y
                    font: {
                        size: 12, // Ajustez la taille de la police du titre de l'axe Y
                    },
                },
                ticks: {
                    font: {
                        size: 10, // Ajustez la taille de la police des étiquettes de l'axe Y
                    },
                },
            },
            x: {
                type: 'category',
                grid: {
                    display: false,
                },
                position: 'bottom',
                title: {
                    display: true,
                    text: 'Nom du produit',
                    font: {
                        size: 12,
                    },
                },
                ticks: {
                    font: {
                        size: 10,
                    },
                },
            },
        },

        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        var value = context.dataset.data[context.dataIndex];
                        var sum = context.dataset.data.reduce((a, b) => a + b, 0);
                        var percentage = ((value / sum) * 100).toFixed(2);
                        return value + ' (' + percentage + '%)';
                    },
                },
            },
        },
    };

    // ... (Votre code pour créer le graphique)





    return (
        <div className='w-full h-[80vh] flex justify-center flex-col items-center'>
            <div className="w-full ">
                <select onChange={(e) => { SetValue(e.target.value) }} className='pl-2 bg-gray-100 border-2 rounded-lg border-blue-800 px-2 py-1 cursor-pointer outline-none'>
                    <option value="Bar">Bar</option>
                    <option value="Line">Line</option>
                    <option value="Radar">Radar</option>
                    <option value="Bubble">Bubble</option>
                    <option value="Doughnut">Doughnut</option>
                    <option value="Pie">Pie</option>
                    <option value="PolarArea">PolarArea</option>
                    <option value="Scatter">Scatter</option>
                </select>
            </div>
            {Value == 'Bar' ? <Bar data={charData} options={options} />
                : Value == 'Line' ? <Line data={charData} options={options} />
                    : Value == 'Radar' ? <Radar data={charData} options={options} />
                        : Value == 'Bubble' ? <Bubble data={charData} options={options} />
                            : Value == 'Doughnut' ? <Doughnut data={charData} options={options} />
                                : Value == 'Pie' ? <Pie data={charData} options={options} />
                                    : Value == 'PolarArea' ? <PolarArea data={charData} options={options} />
                                        : Value == 'Scatter' ? <Scatter data={charData} options={options} />
                                            : <Bar data={charData} options={options} />
            }
        </div>
    );
}

export default ChartJsGraphis
