import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import swal from 'sweetalert2'
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_CART } from "../../../../../Redux/Action/Action";
import { useNavigate } from 'react-router-dom';
function ClientBonus() {
    const history = useNavigate()
    const dispatch = useDispatch();
    const [price, setPrice] = useState(0);
    const getData = useSelector((state) => state.CartsReducer.Carts);
    const UserId = localStorage.getItem('userid');
    const total = () => {
        let prices = 0;
        getData.map((ele, index) => {
            prices = Math.trunc(ele.prix) * ele.quantity + prices;
        });
        setPrice(Math.floor(prices).toLocaleString("fr-FR"));
    };
    useEffect(() => {
        total();
    }, [total]);

    let totalAmount = 0;
    const sellerId = getData[0]?.sellerId;
    const itemsInfo = [];

    getData.forEach(item => {
        const itemTotal = item.prix * item.quantity;
        totalAmount += itemTotal;

        itemsInfo.push({
            id: item.id,
            quantity: item.quantity
        });
    });

    const valide = (id, nom) => {
        swal.fire({
            title: 'Validation',
            text: `Si vous êtes sûr de valider la bonus au client: ${nom}`,
            icon: 'warning',
            width: '300px',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Oui',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Non',
            customClass: {
                container: 'h-full w-full',
                title: 'text-[17px]',
                icon: 'h-[55px] w-[55px]',
                heightAuto: '100px',
            },
            heightAuto: false,
        }).then((result) => {
            if (result.isConfirmed) {
                const data = {
                    idclient: id,
                    sellerId: sellerId,
                    itemsInfo: itemsInfo
                }
                axios.post("https://btrproject.burundientempsreel.com/autovente/bonus", data).then((response) => {
                    toast.success('La bonus a bien effectuée',
                        {
                            autoClose: 7000
                        });

                    dispatch(CLEAR_CART());
                    history('/User/Bonus')
                }).catch((error) => {
                    console.log(error.message);
                    if (error.response) {
                        console.log(error.response.data)
                    }
                });

                /*
                toast.success("Vous avez un bonus à Mugisha ", {
                position: 'top-center',
                autoClose: 1000,
                });
                                */



            } else if (result.dismiss === swal.DismissReason.cancel) {
                toast.warning("Vous avez annulé la validation", {
                    position: 'top-center',
                    autoClose: 1000,
                });
            }

        });
    }

    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(36);
    const handleItemsPerPageChange = (membre) => {
        const selectedItemsPerPage = parseInt(membre.target.value);
        setItemsPerPage(selectedItemsPerPage);
    };
    useEffect(() => {
        axios.get(`https://btrproject.burundientempsreel.com/autovente/allClients?page=${currentPage}&size=${itemsPerPage}`).then((response) => {
            const { clients, totalPages } = response.data;
            setData(clients);
            setTotalPages(totalPages);
        }).catch((error) => {
            console.log(error.message)
            if (error.response) {
                console.log(error.response.data)
            }
        })
    }, [currentPage, itemsPerPage])
    // Fonction pour passer à la page suivante
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1)
        }
    }

    // Fonction pour passer à la page précédente
    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    // Fonction pour changer de page lors du clic sur un numéro de page spécifique
    const goToPage = (page) => {
        setCurrentPage(page)
    }
    // Générer la liste de numérotation des pages
    const generatePageNumbers = () => {
        const pageNumbers = []
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => goToPage(i)}
                    className={
                        currentPage === i
                            ? 'text-sm font-medium leading-none cursor-pointer bg-blue-600 text-white w-[30px] h-[30px] pb-[20px] rounded-lg dark:text-gray-200  dark:hover:text-blue-400 border-t border-transparent hover:border-red-500 pt-3 mr-4 px-2'
                            : 'text-sm font-medium  leading-none cursor-pointer w-[30px] h-[30px] pb-[20px] bg-gray-400 transition-all  text-white hover:bg-gray-600  rounded-2xl dark:text-gray-200  dark:hover:text-blue-400 border-t border-transparent hover:border-blue-400 pt-3 mr-4 px-2'
                    }
                >
                    {i}
                </button>,
            )
        }
        return pageNumbers
    }

    return (
        data.length > 0 && (
            <div class={`  flex flex-col items-end`}>
                <div>
                    <select
                        class="px-2  text-black border border-blue-700 font-serif rounded transition cursor-pointer"
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                    >
                        <option>5</option>
                        <option>10</option>
                        <option>12</option>
                        <option>20</option>
                        <option>24</option>
                        <option>30</option>
                        <option>36</option>
                        {data.length > 36 && (
                            <option>{data.length}</option>
                        )}
                    </select>
                </div>
                <div className="pr-10  p-1 m-1 rounded search ">
                    <p className='text-gray-500 font-serif'>Rechercher par votre choix</p>
                    <div className="search py-1 w-full search ">
                        <form className='flex  w-[100%] '>
                            <input type="text" className='p-1 search border border-blue-700 rounded transition-all outline-none focus:border-2 focus:border-blue-500' id="" placeholder='Rechercher...' />
                            <label htmlFor='search' className='transition-all bg-blue-400 text-white hover:bg-blue-600 p-2 ml-1 cursor-pointer rounded'><i className='bi bi-search'></i></label>
                        </form>
                    </div>
                </div>
                <div className="w-full overflow-auto mt-4">
                    <div className="w-[98%] overflow-hidden widthTabAdmiProdui mr-4">

                        <table className="w-full mx-2" cellspacing="0 " border="0 ">
                            <thead class=" h-[35px] select-none bg-blue-500 border-b-2 border-blue-400 ">
                                <tr>
                                    <th className='border-r border-gray-300 text-[white] w-[7em]'>Nom Complet</th><th className='border-r border-gray-300 text-[white]  w-[10em]'>Email</th><th className='border-r border-gray-300 text-[white] w-[3px]'>Téléphone</th><th className='border-r border-gray-300 text-[white] w-[3px]'>Address</th><th className='border-r border-gray-300 text-[white] w-[1em]'>Action</th>
                                </tr>
                            </thead>
                            <tbody class="text-gray-5000">
                                {data && data.map((client) => {
                                    return (
                                        <tr className='border-r border-b border-gray-300 h-[35px] text-gray-600 '>
                                            <td className=' border-r border-gray-300'>{client.nom}</td>
                                            <td className=' border-r border-gray-300 '>{client.email}</td>
                                            <td className=' border-r border-gray-300'>{client.tel}</td>
                                            <td className=' border-r border-gray-300'>{client.address}</td>
                                            <td onClick={() => valide(client.id, client.nom)} className='border-r flex items-center text-green-600 cursor-pointer border-gray-300'><div className="mr-4">Valide</div><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                                                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                                                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                                            </svg>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4">
                    <div className="lg:w-3/5 w-full flex items-center justify-between border-t border-gray-200 dark:border-gray-700">
                        <div className="flex items-center pt-3 mr-4 text-gray-600 dark:text-gray-200 hover:text-blue-700 cursor-pointer">
                            <svg
                                width="14"
                                height="8"
                                viewBox="0 0 14 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.1665 4H12.8332"
                                    stroke="currentColor"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M1.1665 4L4.49984 7.33333"
                                    stroke="currentColor"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M1.1665 4.00002L4.49984 0.666687"
                                    stroke="currentColor"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <button
                                onClick={previousPage}
                                disabled={currentPage === 1}
                                className="text-sm ml-3 text-black  hover:text-blue-700 cursor-pointer"
                            >
                                Prec
                            </button>
                        </div>
                        <div className="sm:flex mt-3">{generatePageNumbers()}</div>
                        <div className="flex items-center pt-3 ml-4 text-black  hover:text-blue-700 cursor-pointer">
                            <button
                                onClick={nextPage}
                                disabled={currentPage === totalPages}
                                className="text-sm font-medium leading-none mr-3"
                            >
                                Suiv
                            </button>
                            <svg
                                width="14"
                                height="8"
                                viewBox="0 0 14 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.1665 4H12.8332"
                                    stroke="currentColor"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M9.5 7.33333L12.8333 4"
                                    stroke="currentColor"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M9.5 0.666687L12.8333 4.00002"
                                    stroke="currentColor"
                                    stroke-width="1.25"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

export default ClientBonus
