import React from 'react'
import DetailPoductor from './DetailPoductor'

export default function DetailPoductorComponent() {
    return (
        <>

            <div class=" p-2">
                <DetailPoductor />
            </div>
        </>
    )
}
