/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import SpinnerDemarage from '../../../../SpinnerDemarage/SpinnerDemarage'
function StandByClientEchangeAdmin({ getStandByLong }) {
  const { pathname } = useLocation()
  const [click, setclick] = useState(false)
  const [loading, SetLoading] = useState(true)
  const [clickCommande, setclickCommande] = useState(false)
  const [clickSlideHidden, setclickSlideHidden] = useState(false)

  useEffect(() => {
    const windowClicked = () => {
      setclick(false)
      setclickSlideHidden(false)
    }
    window.addEventListener('click', windowClicked)
    return () => {
      window.removeEventListener('click', windowClicked)
    }
  }, [])

  const [data, setData] = useState([])
  // État pour stocker le numéro de page actuel
  const [currentPage, setCurrentPage] = useState(1)
  // État pour stocker le nombre total de pages
  const [totalPages, setTotalPages] = useState(1)

  const [itemsPerPage, setItemsPerPage] = useState(10)
  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value)
    setItemsPerPage(selectedItemsPerPage)
  }

  useEffect(() => {
    axios
      .get(
        ` https://btrproject.burundientempsreel.com/echangeur/standbuy?page=${currentPage}&size=${itemsPerPage}`,
      )
      .then((response) => {
        // Extraire les données de la réponse
        const { echangeurs, totalPages } = response.data
        setData(echangeurs)
        setTotalPages(totalPages)
        setTimeout(() => {
          SetLoading(false)
        }, 1000);
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response.data);
        }
      })
  }, [currentPage, itemsPerPage])

  // Fonction pour passer à la page suivante
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  // Fonction pour passer à la page précédente
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  // Fonction pour changer de page lors du clic sur un numéro de page spécifique
  const goToPage = (page) => {
    setCurrentPage(page)
  }

  // Générer la liste de numérotation des pages
  const generatePageNumbers = () => {
    const pageNumbers = []
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={
            currentPage === i
              ? 'text-sm font-medium leading-none cursor-pointer bg-blue-600 text-white w-[30px] h-[30px] pb-[20px] rounded-full dark:text-gray-200  dark:hover:text-blue-400 border-t border-transparent hover:border-red-500 pt-3 mr-4 px-2'
              : 'text-sm font-medium  leading-none cursor-pointer w-[30px] h-[30px] pb-[20px] bg-gray-400 transition-all  text-white hover:bg-gray-600  rounded-full dark:text-gray-200  dark:hover:text-blue-400 border-t border-transparent hover:border-blue-400 pt-3 mr-4 px-2'
          }
        >
          {i}
        </button>,
      )
    }
    return pageNumbers
  }


  const [count, setCount] = useState(0);
  useEffect(() => {
    axios.get(' https://btrproject.burundientempsreel.com/echangeur/countstandbuy').then((response) => {
      ;
      setCount(response.data)
    })
  })



  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);

  };


  const filterData = (data, searchValue) => {
    if (!searchValue.trim()) {
      return data;
    }

    return data.filter((value) => {


      if (value.nom.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }

      if (value.prenom.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }

      if (value.tel.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }

      if (value.email.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }
      if (value.banck.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }

      if (value.codeagent.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }
      return false;
    });
  };

  const filteredData = filterData(data, searchValue);

  getStandByLong(filteredData.length)

  return (
    <div className='min-h-[90vh]'>


      {loading && <SpinnerDemarage />}

      <div class="flex flex-col items-end">
        <div className="pr-1 items-center    p-1 m-1 flex rounded  ">
          <div className="flex  h-10 ">
            <div className="relative">
              <select
                className=" h-full w-[3em] pl-1 cursor-pointer rounded-l-2xl border block   bg-white border-gray-400    focus:outline-none focus:bg-white focus:border-gray-500"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option>5</option>
                <option>10</option>
                <option>20</option>
              </select>

            </div>
          </div>
          <div className=" py-1 w-full  ">
            <form className='flex  w-[100%] '>
              <input type="text"
                value={searchValue}
                onChange={handleSearchChange}
                className='p-1  border border-blue-700 rounded-e transition-all outline-none focus:border-2 focus:border-blue-500' id="" placeholder='Rechercher...' />
              <label htmlFor='search' className='transition-all bg-blue-400 text-white hover:bg-blue-600 p-2 ml-1 cursor-pointer rounded'><i className='bi bi-search'></i></label>
            </form>
          </div>
        </div>


        <div className="w-full overflow-hidden mt-4">
          <div className="w-[98%]  overflow-auto  mr-4">
            <table className="w-full mx-2 widthTabAdmi overflow-hidden" cellspacing="0 " border="0 ">
              <thead class=" h-11 select-none bg-blue-500 border-b-2 border-blue-400 ">
                <tr>
                  <th className='border-r border-gray-300 text-[white] w-[3em]'>Profil</th>
                  <th className='border-r border-gray-300 text-[white] w-[7em]'>Nom</th>
                  <th className='border-r border-gray-300 text-[white] w-[8em]'>Prénom</th>
                  <th className='border-r border-gray-300 text-[white] w-[8em]'>Téléphone</th>
                  <th className='border-r border-gray-300 text-[white] w-[21em]'>Email</th>
                  <th className='border-r border-gray-300 text-[white] w-[10em]'>Compte principal</th>
                  <th className='border-r border-gray-300 text-[white] w-[8em]'>Code agent</th>
                  <th className='border-r border-gray-300 text-[white] w-[4em]'>Action</th>

                </tr>
              </thead>
              <tbody class="text-gray-500 bg-white ">
                {filteredData.length > 0 ? (
                  <>
                    {
                      filteredData.map((data) => {
                        return (

                          <tr className='border-r by-1 px-2 border-b border-gray-300 h-[35px] text-gray-600 '>
                            <td className=' border-r border-gray-300'>
                              <div className="w-[35px] h-[35px] bg-transparent rounded-full overflow-hidden shadow1"><img draggable='false' src={` https://btrproject.burundientempsreel.com/uploads/photosechange/${data.profil}`} alt="" className='w-full h-full object-cover' />
                              </div>
                            </td>
                            <td className=' border-r border-gray-300'>
                              {data.nom}
                            </td>
                            <td className=' border-r border-gray-300'>
                              {data.prenom}
                            </td>
                            <td className=' border-r border-gray-300'>
                              {data.tel}
                            </td>
                            <td className=' border-r border-gray-300'>
                              {data.email}
                            </td>
                            <td class=" w-[7em] p-1  border-r border-gray-300">
                              <div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>{data.banck}</div>
                            </td>
                            <td className=' w-full flex pl-2 border-r border-gray-300'>{data.codeagent}</td><td>
                              <Link to={`/Admin/StandBuy/VoirPlus/${data.id}`} className='border-r border-gray-300 pl-2'>
                                Voir+
                              </Link>
                            </td>
                          </tr>

                        )
                      })
                    }

                  </>) : (<>
                    <tr >
                      <td colSpan="8"><div className='h-[75vh] text-gray-600 w-full flex justify-center items-center text-[22px]'>
                        Aucune correspondanct à <div className='ml-1 font-serif font-bold'> {searchValue}</div>
                      </div></td>
                    </tr>
                  </>)
                }
              </tbody>
            </table>
          </div>



          <div className="flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4">
            <div className="lg:w-3/5 w-full flex items-center justify-between border-t border-gray-200 dark:border-gray-700">
              <div className="flex items-center pt-3 mr-4 text-gray-600 dark:text-gray-200 hover:text-yellow-700 cursor-pointer">
                <i class="bi bi-arrow-left"></i>
                <button onClick={previousPage} disabled={currentPage === 1} className="text-sm ml-3 text-black  hover:text-yellow-700 cursor-pointer">Previous</button>
              </div>
              <div className="flex mt-3">
                {generatePageNumbers()}

              </div>
              <div className="flex items-center pt-3 ml-4 text-black  hover:text-yellow-700 cursor-pointer">
                <button onClick={nextPage} disabled={currentPage === totalPages} className="text-sm font-medium leading-none mr-3">Next</button>
                <i class="bi bi-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>







      </div>

    </div>
  )
}

export default StandByClientEchangeAdmin
