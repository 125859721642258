import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

function StandByAdminSuperEchange() {
  const { pathname } = useLocation()
  const [click, setclick] = useState(false)
  const [clickCommande, setclickCommande] = useState(false)
  const [clickSlideHidden, setclickSlideHidden] = useState(false)

  useEffect(() => {
    const windowClicked = () => {
      setclick(false);
      setclickSlideHidden(false);

      // navigate('/User/Echange/Commande')
    };

    window.addEventListener("click", windowClicked);

    return () => {
      window.removeEventListener("click", windowClicked);
    };
  }, []);
  return (
    <>


      {/* NavBars d'ecran de petit taill */}

      {/* NavBars d'ecran de petit taill */}


      <div className='bg-gray-200 w-full hidden  pt-2 justify-around sticky top-0 z-10 navEcrantTail'>
        <div onClick={(e) => { setclickSlideHidden(!clickSlideHidden); e.stopPropagation(); }} className=" menus bi bi-list cursor-pointer hover:bg-black hidden  hover:text-white transition-all h-max text-[20px] px-2 py-1"></div>
        <div className="ml-2 menus hidden text-center font-serif navEchange w-[80%]"><span className='mot1'>Burundi </span><span className='mot2'>en </span><span className='mot1'>temps</span> <span className='mot3'>réel</span></div>
      </div>
      <div className={`${clickSlideHidden ? 'slideBars20 ' : 'slideBars2'}  w-[11em] z-50  top-0 hidden absolute   h-full bg-red-300 transition-all border-r-2 border-blue-300 slideBars2`}>
        <div className="w-full relative h-12 flex justify-center items-end font-serif text-gray-600 border-b border-gray-200 ">
          <button onClick={(e) => { setclickSlideHidden(!clickSlideHidden); e.stopPropagation(); }} className='bi bi-chevron-double-left text-black hover:bg-black  hover:text-white transition-all font-bold absolute top-0 right-2  px-[4px] py-[1px] rounded '></button>
          <p>Tous navigation</p>
        </div>
        <div className="w-[11em]">
          <div className="w-full h-100% font-serif">
            {pathname == "/" ? (
              <Link to='/' className='flex  justify-start items-center  p-1 w-full h-12    bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                <p className='sizeText'>Acceuil</p>
              </Link>
            ) : (
              <Link to='/' className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 '>
                <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                <p className='sizeText'>Acceuil</p>
              </Link>
            )}

            {pathname == "/Admin/ListClien" || pathname == "/User/Echange/NewCommande" || pathname == "/User/Echange/Commande/Detail" ? (
              <div className='flex flex-col   items-start  p-1 w-full       pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                <div onClick={(e) => { setclick(!click); setclickCommande(false); e.stopPropagation() }} className="flex cursor-pointer items-center">
                  <i class="bi bi-person text-[20px] mr-1"></i>
                  <p className='sizeText'>Client</p>
                  <i class={`${click ? 'rotate-[90deg]' : 'rotate-[0deg]'} transition-all bi bi-chevron-right  text-[20px] ml-10`}></i>
                </div>
                <div className={` ${click ? 'h-[5em] opacity-100 pointer-events-auto transition-all ' : 'opacity-0 pointer-events-none hidden transition-all '} pl-[35px]  transition-all  w-full flex flex-col p-2 justify-between items-center`}>
                  <Link to='/Admin/Client' className={` ${click ? 'h-[5em]' : 'h-[0em]'} w-full h-[0em] rounded-[2px]  hover:bg-blue-400 hover:text-fuchsia-50 `}>
                    <div className="flex justify-start items-center">
                      <i class="bi bi-person-check-fill text-[20px] mr-2 p-[1px]"></i>
                      <p className='sizeText'>Listes</p>
                    </div>
                  </Link>
                  <Link to="/Admin/NouvClient" className={` ${click ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50 `}>
                    <div className="flex justify-start items-center">
                      <i class="bi bi-person-fill-add text-[20px] mr-2 p-[1px]"></i>
                      <p className='sizeText'>Nouveau </p>
                    </div>
                  </Link>

                  <Link to="/Admin/StandBuy" className={` ${click ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50  relative`}>
                    <div className="flex justify-start items-center">
                      <i class="bi bi-person-exclamation text-[20px] mr-2 p-[1px]"></i>
                      <p className='sizeText'>StandBuy </p>
                      <div className="absolute -top-1 right-2 bg-blue-700 text-white w-[25px] h-[25px] rounded-full text-[10px] flex justify-center items-center">
                        99+
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

            ) : (
              <div className={`${click ? 'mb-[1em]   ' : 'mb-[0em]   '} flex flex-col text-gray-800 justify-center  items-start py-2  p-1 w-full transition-all `}>
                <div onClick={(e) => { setclick(!click); setclickCommande(false); e.stopPropagation() }} className="flex cursor-pointer items-center">
                  <i class="bi bi-person text-[20px] mr-1"></i>
                  <p className='sizeText'>Client</p>
                  <i class={`${click ? 'rotate-[90deg]' : 'rotate-[0deg]'} transition-all bi bi-chevron-right  text-[20px] ml-10`}></i>
                </div>
                <div className={` ${click ? 'h-[5em] opacity-100 pointer-events-auto transition-all ' : 'opacity-0 pointer-events-none hidden transition-all '} pl-[35px]  transition-all  w-full flex flex-col p-2 justify-between items-center`}>
                  <Link to='/Admin/Client' className={` ${click ? 'h-[5em]' : 'h-[0em]'} w-full h-[0em] rounded-[2px]  hover:bg-blue-400 hover:text-fuchsia-50 `}>
                    <div className="flex justify-start items-center">
                      <i class="bi bi-person-check-fill text-[20px] mr-2 p-[1px]"></i>
                      <p className='sizeText'>Listes</p>
                    </div>
                  </Link>
                  <Link to="/Admin/NouvClient" className={` ${click ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50 `}>
                    <div className="flex justify-start items-center">
                      <i class="bi bi-person-fill-add text-[20px] mr-2 p-[1px]"></i>
                      <p className='sizeText'>Nouveau </p>
                    </div>
                  </Link>

                  <Link to="/Admin/StandBuy" className={` ${click ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50 relative`}>
                    <div className="flex justify-start items-center">
                      <i class="bi bi-person-exclamation text-[20px] mr-2 p-[1px]"></i>
                      <p className='sizeText'>StandBuy </p>
                      <div className="absolute -top-1 right-2 bg-blue-700 text-white w-[25px] h-[25px] rounded-full text-[10px] flex justify-center items-center">
                        99+
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}

            {pathname == "/Admin/Command" ? (


              <div className='flex flex-col   items-start  p-1 w-full       pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                <div onClick={(e) => { setclickCommande(!clickCommande); setclick(false); e.stopPropagation() }} className="flex cursor-pointer items-center">
                  <i class="bi bi-hourglass-split text-[20px] mr-1"></i>
                  <p className='sizeText'>Commande</p>
                  <i class={`${clickCommande ? 'rotate-[90deg]' : 'rotate-[0deg]'} transition-all bi bi-chevron-right  text-[20px] ml-10`}></i>
                </div>
                <div className={` ${clickCommande ? 'h-[5em] opacity-100 pointer-events-auto transition-all ' : 'opacity-0 pointer-events-none hidden transition-all '} pl-[35px]  transition-all  w-full flex flex-col p-2 justify-between items-center`}>
                  <Link to='/Admin/Command' className={` ${clickCommande ? 'h-[5em]' : 'h-[0em]'} w-full h-[0em] rounded-[2px]  hover:bg-blue-400 hover:text-fuchsia-50 `}>
                    <div className="flex justify-start items-center">
                      <i class="bi bi-list-nested text-[20px] mr-2 p-[1px]"></i>
                      <p className='sizeText'>Listes</p>
                    </div>
                  </Link>
                  <Link to="/Admin/History" className={` ${clickCommande ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50 `}>
                    <div className="flex justify-start items-center">
                      <i class="bi bi-hourglass-split text-[20px] mr-2 p-[1px]"></i>
                      <p className='sizeText'>Historique </p>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              <div className={`${clickCommande ? 'mb-[1em]   ' : 'mb-[0em]   '} flex flex-col text-gray-800 justify-center  items-start py-2  p-1 w-full transition-all `}>
                <div onClick={(e) => { setclickCommande(!clickCommande); setclick(false); e.stopPropagation() }} className="flex cursor-pointer items-center">
                  <i class="bi bi-hourglass-split  text-[20px] mr-1"></i>
                  <p className='sizeText'>Commande</p>
                  <i class={`${clickCommande ? 'rotate-[90deg]' : 'rotate-[0deg]'} transition-all bi bi-chevron-right  text-[20px] ml-10`}></i>
                </div>
                <div className={` ${clickCommande ? 'h-[5em] opacity-100 pointer-events-auto transition-all ' : 'opacity-0 pointer-events-none hidden transition-all '} pl-[35px]  transition-all  w-full flex flex-col p-2 justify-between items-center`}>
                  <Link to='/Admin/Command' className={` ${clickCommande ? 'h-[5em]' : 'h-[0em]'} w-full h-[0em] rounded-[2px]  hover:bg-blue-400 hover:text-fuchsia-50 `}>
                    <div className="flex justify-start items-center">
                      <i class="bi bi-list-nested text-[20px] mr-2 p-[1px]"></i>
                      <p className='sizeText'>Listes</p>
                    </div>
                  </Link>
                  <Link to="/Admin/History" className={` ${clickCommande ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50 `}>
                    <div className="flex justify-start items-center">
                      <i class="bi bi-hourglass-split text-[20px] mr-2 p-[1px]"></i>
                      <p className='sizeText'>Historique </p>
                    </div>
                  </Link>


                </div>
              </div>
            )}

          </div>
        </div>
      </div>




      <div class="flex flex-col items-end">
        <div className="pr-10  p-1 m-1 rounded search ">
          <p className='text-gray-500 font-serif'>Rechercher par votre choix</p>
          <div className="search py-1 w-full search ">
            <form className='flex  w-[100%] '>
              <input type="text" className='p-1 search border border-blue-700 rounded transition-all outline-none focus:border-2 focus:border-blue-500' id="" placeholder='Rechercher...' />
              <label htmlFor='search' className='transition-all bg-blue-400 text-white hover:bg-blue-600 p-2 ml-1 cursor-pointer rounded'><i className='bi bi-search'></i></label>
            </form>
          </div>
        </div>
        <div className="w-full overflow-auto mt-4">
          <div className="w-[98%] overflow-hidden widthTabAdmi mr-4">
            <table className="w-full mx-2" cellspacing="0 " border="0 ">
              <thead class=" h-11 select-none bg-blue-500 border-b-2 border-blue-400 ">
                <tr>
                  <th className='border-r border-gray-300 text-[white] w-[3em]'>Profil</th>
                  <th className='border-r border-gray-300 text-[white] w-[7em]'>Nom</th>
                  <th className='border-r border-gray-300 text-[white] w-[8em]'>Prénom</th>
                  <th className='border-r border-gray-300 text-[white] w-[8em]'>Téléphone</th>
                  <th className='border-r border-gray-300 text-[white] w-[20em]'>Email valide</th>
                  <th className='border-r border-gray-300 text-[white] w-[10em]'>Nom de la carte SIM</th>
                  <th className='border-r border-gray-300 text-[white] w-[8em]'>Code agent</th>
                  <th className='border-r border-gray-300 text-[white] w-[4em]'>Bank</th>
                  <th className='border-r border-gray-300 text-[white] w-[10em]'>Action</th>
                </tr>
              </thead>
              <tbody class="text-gray-5000">
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
                <tr className='border-r by-1 px-2 border-b border-gray-300 h-[40px] text-gray-600 '><td className=' border-r border-gray-300'><div className="w-[35px] h-[35px] bg-red-500 rounded-full overflow-hidden shadow1"><img draggable='false' src="../image/profile.jpg" alt="" className='w-full h-full object-cover' /></div></td><td className=' border-r border-gray-300'>NDAYIZEYE</td><td className=' border-r border-gray-300'>Telecom</td><td className=' border-r border-gray-300'>+257 89769054</td><td className=' border-r pl-2 border-gray-300'>telecomtelesphore@burundientemprreel.com</td><td class=" w-[7em] p-1  border-r border-gray-300"><div className=' border-r border-gray-300  pl-2  h-full text-left  text-[15px]  w-full select-none'>Lumitel</div></td><td className=' w-full flex pl-2 border-r border-gray-300'>1289</td><td className='border-r border-gray-300 pl-2'>Lumicash</td><td><div className="flex m-2 justify-around"><div className="py-[1px] px-[10px]  bg-green-400 text-white cursor-pointer hover:bg-green-600 transition-all rounded">Valider</div><div className="py-[1px] px-[10px]  bg-red-400 text-white cursor-pointer hover:bg-red-600 transition-all rounded">Annuler</div></div></td></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}

export default StandByAdminSuperEchange

