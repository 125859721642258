import React from 'react'
import ListClientEchangeAdmin from './ListClientEchangeAdmin'
function ListClientEchangeAdminComponent({ getListClient }) {
  return (
    <>
      <ListClientEchangeAdmin getListClient={getListClient} />
    </>
  )
}

export default ListClientEchangeAdminComponent
