/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { FadeLoader } from 'react-spinners'
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
function ModifierFraistransactionAdmi() {
    const [boutLoading, setboutLoading] = useState(false);
    const [loading, SetLoading] = useState(true)

    const electroni = [
        { value: "", label: "Monnaie electronique" },
        { value: "Lumicash", label: "Lumicash" },
        { value: "Bancobu enoti", label: "Bancobu enoti" },
        { value: "CECM akaravyo", label: "CECM akaravyo" },
        { value: "Mobinetel", label: "Mobinetel" },
        { value: "Telemax", label: "Telemax" }
    ];
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)


    const hiddenSelectroni = electroni.slice(1);
    const [selectedElectronique, setselectedElectronique] = useState(electroni[0]);



    const selectedElectroniqueFonc = (option) => {
        setselectedElectronique(option)
    }


    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: mobile11 ? 30 : 50,
            backgroundColor: "white",

        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };




    const { id } = useParams()
    const History = useNavigate()
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    const [animationClasselectronique, setanimationClasselectronique] = useState('');
    const elemetRefelectronique = useRef(null)

    const [Min, SetMin] = useState('')
    const [animationClassMin, setAnimationClassMin] = useState('');
    const elemetRefMin = useRef(null)

    const [Maximum, SetMaximum] = useState('')
    const [animationClassMaximum, setAnimationClassMaximum] = useState('');
    const elemetRefMaximum = useRef(null)

    const [Enregistrée, SetEnregistrée] = useState('')
    const [animationClassEnregistrée, setAnimationClassEnregistrée] = useState('');
    const elemetRefEnregistrée = useRef(null)



    const [Nonenregistrée, SetNonenregistrée] = useState('')
    const [animationClassNonenregistrée, setAnimationClassNonenregistrée] = useState('');
    const elemetRefNonenregistrée = useRef(null)

    const [Frait_de_retrait, SetFrait_de_retrait] = useState('')
    const [animationClassFrait_de_retrait, setAnimationClassFrait_de_retrait] = useState('');
    const elemetRefFrait_de_retraite = useRef(null)


    const handleSubmit = (e) => {
        e.preventDefault()
        if (selectedElectronique.value == '') {
            toast.warning(' Nom de monnaie electronique que vous avez est obligatoire ', {
                autoClose: 2000
            });
            setanimationClasselectronique('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClasselectronique(' ')
            }, 3000)
            elemetRefelectronique.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;

        }

        else if (String(Min).trim() == '') {
            toast.warning('Le minimum est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassMin('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassMin(' ')
            }, 3000)
            elemetRefMin.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefMin.current.focus()
            return false
        }
        else if (String(Maximum).trim() == '') {
            toast.warning('Le maximum est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassMaximum('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassMaximum(' ')
            }, 3000)
            elemetRefMaximum.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefMaximum.current.focus()
            return false
        }
        else if (Min > Maximum) {
            toast.warning('Le minimum ne doit pas être supérieur au maximun', {
                autoClose: 2000
            });
            setAnimationClassMaximum('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassMaximum(' ')
            }, 3000)
            elemetRefMaximum.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefMaximum.current.focus()
            return false
        } else if (Min == Maximum) {
            toast.warning('Le minimum ne doit pas être égal au maximun', {
                autoClose: 2000
            });
            setAnimationClassMaximum('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassMaximum(' ')
            }, 3000)
            elemetRefelectronique.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false;

        } else if (String(Enregistrée).trim() == '') {
            toast.warning('Le montant s\'il est enregistre est obligatoire ', {
                autoClose: 2000
            });
            setAnimationClassEnregistrée('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassEnregistrée(' ')
            }, 3000)
            elemetRefEnregistrée.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefEnregistrée.current.focus()
            return false;
        }
        else if (Nonenregistrée.trim() == '') {
            toast.warning('Le montant s\'il n\'est pas enregistre est obligatoire ', {
                autoClose: 2000
            });
            setAnimationClassNonenregistrée('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassNonenregistrée(' ')
            }, 3000)
            elemetRefNonenregistrée.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefNonenregistrée.current.focus()
            return false;
        }
        else if (Frait_de_retrait.trim() == '') {
            toast.warning('Le frait de retrait est obligatoire ', {
                autoClose: 2000
            });
            setAnimationClassFrait_de_retrait('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassFrait_de_retrait(' ')
            }, 3000)
            elemetRefFrait_de_retraite.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefFrait_de_retraite.current.focus()
            return false;
        }

        const data = {
            type: selectedElectronique.value,
            min: Min,
            max: Maximum,
            yanditswe: Enregistrée,
            atanditswe: Nonenregistrée,
            kubikura: Frait_de_retrait
        }


        setboutLoading(true)
        axios.put(`https://btrproject.burundientempsreel.com/rubrique/${id}`, data).then((res) => {
            toast.success(res.data)
            History("/Admin/fraistransaction")
        }).catch((err) => {
            if (err.response) {
                toast.warn(err.response.data[0])
                return false;
            }
            console.log(err.message)
        }).finally(() => {
            setboutLoading(false)
        })

    }


    useEffect(() => {
        axios.get(`https://btrproject.burundientempsreel.com/rubrique/ById/${id}`).then((response) => {
            //SetCode_agent(response.data.codeAgent);
            // SetMontant(response.data.montant);
            SetEnregistrée(response.data.yanditswe)
            SetNonenregistrée(response.data.atanditswe)
            SetMaximum(response.data.max)
            SetMin(response.data.min)
            SetFrait_de_retrait(response.data.kubikura)
            setselectedElectronique(electroni.find(option => option.value === response.data.type));
            setTimeout(() => {
                SetLoading(false)
            }, 1000);

        }).catch((err) => {
            console.error('Erreur lors de la récupération des rubrique :', err.message);
        })
    }, [id])



    return (

        <div className={`bg-white  w-full `}>
            {loading && <SpinnerDemarage />}
            <div class="my-5 w-full text-center text-[20px] sm:text-2xl font-bold ">
                Modification
            </div>
            <div class="w-full my-4 justify-center flex">
                <div class={`border border-blue-700  rounded-lg p-1 ${mobile11 ? 'w-[95%]' : 'w-[30em]'}`}>
                    <form onSubmit={handleSubmit}>
                        <div class="bg-white rounded p-1 sm:px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                            <div class="mb-6">
                                <div class="w-full sm:px-3">
                                    <label
                                        class=" tracking-wide text-gray-700  text-[15px] sm:text-lg font-bold mb-2"
                                    >
                                        Nom de monnaie
                                    </label>
                                    <div
                                        ref={elemetRefelectronique}
                                        className={`rounded ${selectedElectronique.value === ''
                                            ? animationClasselectronique
                                            : 'focus:border-2 focus:border-blue-700 pointer-events-none'
                                            }`}

                                    >
                                        <Select
                                            value={selectedElectronique}
                                            options={hiddenSelectroni}
                                            styles={customStyles}
                                            onChange={selectedElectroniqueFonc}
                                        />
                                    </div>

                                </div>
                            </div>

                            <div class={`w-full mb-6`}>
                                <div class={`w-full px-1 sm:px-3`}>
                                    <label
                                        class="block  tracking-wide text-[15px] sm:text-lg font-bold mb-2"

                                    >
                                        Minimum
                                    </label>
                                    <input
                                        ref={elemetRefMin} value={Min} onInput={(e) => SetMin(e.target.value)}
                                        class={`appearance-none outline-none block w-full   border rounded py-1 px-2  sm:py-3 sm:px-4  ${Min == '' ? animationClassMin : 'focus:border-2 focus:border-blue-700'}`}
                                        id="grid-last-name"
                                        type="number"
                                        placeholder="Minimum"
                                    />
                                </div>
                            </div>

                            <div class={`w-full mb-6`}>
                                <div class={`w-full px-1 sm:px-3`}>
                                    <label
                                        class="block  tracking-wide text-[15px] sm:text-lg font-bold mb-2"

                                    >
                                        Maximum
                                    </label>
                                    <input
                                        ref={elemetRefMaximum} value={Maximum} onInput={(e) => SetMaximum(e.target.value)}
                                        class={`appearance-none outline-none block w-full   border rounded py-1 px-2  sm:py-3 sm:px-4  ${Maximum == '' ? animationClassMaximum : 'focus:border-2 focus:border-blue-700'}`}
                                        id="grid-last-name"
                                        type="number"
                                        placeholder="Maximum"
                                    />
                                </div>
                            </div>

                            <div class={`w-full mb-6`}>
                                <div class={`w-full px-1 sm:px-3`}>
                                    <label
                                        class="block  tracking-wide text-[15px] sm:text-lg font-bold mb-2"
                                    >
                                        Enregistrée
                                    </label>
                                    <input
                                        ref={elemetRefEnregistrée} value={Enregistrée} onInput={(e) => SetEnregistrée(e.target.value)}
                                        class={`appearance-none outline-none block w-full   border rounded py-1 px-2  sm:py-3 sm:px-4  ${Enregistrée == '' ? animationClassEnregistrée : 'focus:border-2 focus:border-blue-700'}`}
                                        placeholder="Enregistrée"
                                    />
                                </div>
                            </div>
                            <div class={`w-full mb-6`}>
                                <div class={`w-full px-1 sm:px-3`}>
                                    <label
                                        class="block  tracking-wide text-[15px] sm:text-lg font-bold mb-2"
                                    >
                                        Non enregistrée
                                    </label>
                                    <input
                                        ref={elemetRefNonenregistrée} value={Nonenregistrée} onInput={(e) => SetNonenregistrée(e.target.value)}
                                        class={`appearance-none outline-none block w-full   border rounded py-1 px-2  sm:py-3 sm:px-4  ${Nonenregistrée == '' ? animationClassNonenregistrée : 'focus:border-2 focus:border-blue-700'}`}
                                        placeholder="Enregistrée"
                                    />
                                </div>
                            </div>
                            <div class={`w-full mb-6`}>
                                <div class={`w-full px-1 sm:px-3`}>
                                    <label
                                        class="block  tracking-wide text-[15px] sm:text-lg font-bold mb-2"
                                    >
                                        Frait de retrait
                                    </label>
                                    <input
                                        ref={elemetRefFrait_de_retraite} value={Frait_de_retrait} onInput={(e) => SetFrait_de_retrait(e.target.value)}
                                        class={`appearance-none outline-none block w-full   border rounded py-1 px-2  sm:py-3 sm:px-4  ${Frait_de_retrait == '' ? animationClassFrait_de_retrait : 'focus:border-2 focus:border-blue-700'}`}
                                        placeholder="Frait de retrait"
                                    />
                                </div>
                            </div>
                            <div class="mb-4 flex items-center justify-end">

                                {boutLoading ? (
                                    <>
                                        <label disabled className="w-max relative cursor-no-drop  flex justify-center  items-center   bg-blue-950 py-1 px-2 rounded  text-gray-400">
                                            <input type="submit" id="send" value='Modifier' className='pointer-events-none' />
                                            <i class="bi bi-send ml-2 "></i>
                                            <div className='absolute bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </label>
                                    </>
                                ) : (<>
                                    <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-blue-600   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Modifier' className='cursor-pointer'></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>
                                </>)}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
}


export default ModifierFraistransactionAdmi;










