import React from 'react'
import NouvCientEchangeAdmin from './NouvCientEchangeAdmin'
function NouvCientEchangeAdminComponent({ isdivScroll }) {
    return (
        <>
            <NouvCientEchangeAdmin isdivScroll={isdivScroll} />
        </>
    )
}

export default NouvCientEchangeAdminComponent
