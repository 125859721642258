// Import des dépendances
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

import Slider from 'react-slick'
import { toast, ToastContainer } from 'react-toastify'
import Empty from '../Empty/Empty'
import SpinnerDemarage from '../../../../SpinnerDemarage/SpinnerDemarage'
import { BarLoader } from 'react-spinners'

function Product_ListSeller({ isdivScroll }) {


  const [NotFound, SetNotFound] = useState(false)
  let [loading, setLoading] = useState(true)
  const [selectcategorie, setSelectcategorie] = useState('')
  const [titre, setTitre] = useState('')
  const [productSearch, setproductSearch] = useState([])
  const [searching, setSearching] = useState(false);





  useEffect(() => {

    const hundleScroll = () => {
      setcategoryMenu(false);
    }
    if (isdivScroll.current) {
      isdivScroll.current.addEventListener('scroll', hundleScroll)
    }
    return () => {
      isdivScroll.current.removeEventListener('scroll', hundleScroll)
    }
  }, [])

  const LoadingImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };
    const lazyLoad = () => {
      setImgSrc(src);
    };



    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LoadingImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };


  const history = useNavigate()
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [categoryMenu, setcategoryMenu] = useState(false)
  const [category, setCategory] = useState('')
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [datanotsearch, setdatanotsearch] = useState(false)
  const [itemsPerPage, setItemsPerPage] = useState(72);
  const [titreseach, settitreseach] = useState("");
  const handleItemsPerPageChange = (membre) => {
    const selectedItemsPerPage = parseInt(membre.target.value);
    setItemsPerPage(selectedItemsPerPage);
  };
  const iduser = localStorage.getItem("userid");
  useEffect(() => {
    const fetchData = async () => {
      setproductSearch([])
      try {
        if (category) {
          const response = await axios.get(` https://btrproject.burundientempsreel.com/market/seller/productsbycategory/${iduser}/${category}?page=${currentPage}&size=${itemsPerPage}`);
          const { products, totalPages } = response.data;
          setData(products);
          setTotalPages(totalPages);
          setdatanotsearch(false)
        } else {
          const response = await axios.get(` https://btrproject.burundientempsreel.com/market/seller/getallproduct/${iduser}?page=${currentPage}&size=${itemsPerPage}`);
          const { products, totalPages } = response.data;
          setData(products);
          setTotalPages(totalPages);
          setdatanotsearch(true)
          const timer = setTimeout(() => {
            setLoading(false);
          }, 100);
          return () => clearTimeout(timer);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchData();
  }, [category, currentPage, itemsPerPage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Fonction pour passer à la page suivante
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  // Fonction pour passer à la page précédente
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  // Fonction pour changer de page lors du clic sur un numéro de page spécifique
  const goToPage = (page) => {
    setCurrentPage(page)
  }

  // Générer la liste de numérotation des pages
  const generatePageNumbers = () => {
    const pageNumbers = []
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={
            currentPage === i
              ? 'text-sm font-medium leading-none cursor-pointer bg-blue-600 text-white w-[30px] h-[30px] pb-[20px] rounded-lg dark:text-gray-200  dark:hover:text-blue-400 border-t border-transparent hover:border-red-500 pt-3 mr-4 px-2'
              : 'text-sm font-medium  leading-none cursor-pointer w-[30px] h-[30px] pb-[20px] bg-gray-400 transition-all  text-white hover:bg-gray-600  rounded-2xl dark:text-gray-200  dark:hover:text-blue-400 border-t border-transparent hover:border-blue-400 pt-3 mr-4 px-2'
          }
        >
          {i}
        </button>,
      )
    }
    return pageNumbers
  }

  const search = (titre) => {
    if (titre) {
      setSearching(true);
      axios
        .get(" https://btrproject.burundientempsreel.com/market/user/searchproductbuytitle", {
          params: { titre: titre },
        })
        .then((response) => {
          if (response.data === "Produit introuvable !") {
            toast.error("Produit est introuvable", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setproductSearch([]); // Mettre à jour avec un tableau vide
          } else {
            setproductSearch(response.data);
          }
          setSearching(false); // Indiquer que la recherche est terminée
        })
        .catch((err) => {
          console.error("Erreur lors de la recherche du produit", err);
          setproductSearch([]);
          setSearching(false);
        });
    } else {
      setproductSearch([]);
    }
  };

  const [loadingImages, setLoadingImages] = useState([]);


  const handleImageLoad = (index) => {
    setLoadingImages((prevLoadingImages) => {
      const updatedLoadingImages = [...prevLoadingImages];
      setTimeout(() => {
        updatedLoadingImages[index] = false;
      }, 5000);
      return updatedLoadingImages;
    });
  };











  const [listoffInvestors, setListOffInvestors] = useState([]);
  const [element, setElement] = useState(0);

  const [currentPagep, setCurrentPagep] = useState(1);
  const [itemsPerPagep, setItemsPerPagep] = useState(10);
  const [totalPagesp, setTotalPagesp] = useState(0);

  const handlePageChange = (page) => {
    setCurrentPagep(page);
  };

  const handleItemsPerPageChangep = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPagep(selectedItemsPerPage);
  };






  const handleCategoryClick = (category) => {
    setCategory(category);
    setcategoryMenu(false);
  };


  return (
    <>
      {loading && <SpinnerDemarage />}
      <div class="w-full relative ">
        <div class="flex items-center sticky top-0 space-y-3 p-3 bg-gray-100 ">
          <div class="flex justify-between items-center gap-2  pb-1 w-full ml-auto">
            <div
              onClick={(e) => {
                setcategoryMenu(true)
                e.stopPropagation()
              }}
              class="bg-cream h-10           
              items-center bi bi-list-nested py-1 px-2 text-[30px] pb-11 bg-blue-400 text-white font-semibold rounded-lg hover:shadow-lg transition duration-3000 cursor-pointer"
            ></div>
            <div className="flex">
              <div class="flex  h-12 bg-white p-2 sm:w-[20em]  space-x-4 inputwidth ">
                <i class="bi bi-search"></i>
                <input
                  value={titreseach}
                  onChange={(e) => {
                    settitreseach(e.target.value);
                    search(e.target.value);
                  }}
                  class="bg-gray-100 outline-none w-full pl-2"
                  type="text"
                  placeholder="Nom de l'article ou titre..."

                />
              </div>
              <select
                class="px-2  text-black border border-blue-700 font-serif rounded transition cursor-pointer"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option>5</option>
                <option>10</option>
                <option>12</option>
                <option>20</option>
                <option>24</option>
                <option>30</option>
                <option>36</option>
                <option>40</option>
                <option>48</option>
                <option>50</option>
                <option>60</option>
                <option>70</option>
                <option>72</option>
                {data.length > 72 && (
                  <option>{data.length}</option>
                )}
              </select>

            </div>
          </div>
        </div>
        <hr />
        <>
          <div className={`sm:w-[20em] w-[100%] slideMarche ${categoryMenu ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}  top-0  pt-2 left-0 absolute z-[50] h-full bg-gray-300 transition-all border-r-2 border-blue-500`}>
            <div className="w-full relative h-12  pl-4  font-serif text-gray-500 border-b border-gray-200 ">
              <button onClick={(e) => { setcategoryMenu(false); e.stopPropagation(); }} className='bi bi-chevron-double-left text-white hover:bg-blue-600  bg-blue-400   transition-all font-bold absolute top-0 right-2  px-[4px] py-[1px] rounded '></button>
              <p>Catégories</p>
            </div>

            <div className={` w-full overflow-y-auto flex overflow-x-hidden bg-gray-300`}>
              <div className="w-full h-[70vh] font-serif">

                <div onClick={() => handleCategoryClick("")}

                  className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 ${category === "" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''}`}>

                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className='sizeText'>Tous les catégories</p>
                </div>

                <div onClick={() => handleCategoryClick("Appareils électronique")} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Appareils électronique" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>

                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className='sizeText'>Appareils électronique</p>
                </div>
                <div onClick={() => { handleCategoryClick("Appreils de construction"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Appreils de construction" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>

                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className='sizeText'>Appreils de construction</p>
                </div>
                <div onClick={() => { handleCategoryClick("Appreils maisons et cuisines"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Appreils maisons et cuisines" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>

                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className='sizeText'>Appreils maisons et cuisines</p>
                </div>

                <div onClick={() => { handleCategoryClick("Magazin"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Magazin" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>
                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className='sizeText'>Magazin</p>
                </div>

                <div onClick={() => { handleCategoryClick("Vehicule"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Vehicule" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>
                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className='sizeText'>Vehicule</p>
                </div>
                <div onClick={() => { handleCategoryClick("Immeubles"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Immeubles" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>
                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className='sizeText'>Immeubles</p>
                </div>

                <div onClick={() => { handleCategoryClick("Industriels"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Industriels" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>
                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className='sizeText'>Industriels</p>
                </div>

                <button onClick={() => { handleCategoryClick("Arts et Artisanat"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Arts et Artisanat" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>
                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className='sizeText'>Arts et Artisanat</p>
                </button>


                <div onClick={() => { handleCategoryClick("Jouets"); setcategoryMenu(false); }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50  ${category === "Jouets" ? 'bg-blue-600 text-white hover:bg-blue-600' : ''} `}>
                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className='sizeText'>Jouets</p>
                </div>

              </div>
            </div>

          </div>
        </>
        <div className="Container_Market md:p-5 py-5 px-1 md:pt-10 pb-32 ">
          <div className=" border-t-2 ">
            <h2 class="text-base md:text-2xl font-bold py-4 ml-3 ">
              {productSearch.length > 0 || !datanotsearch
                ? 'Résultats de la recherche'
                : 'Magasinez les dernières nouveautés'}
            </h2>

            {productSearch.length > 0 ? (
              <div class="grid md:grid-cols-4 grid-cols-3 md:px-2 md:gap-6 gap-3">
                {

                  productSearch.map((item, index) => {
                    return (
                      <div class="border rounded-2xl p-2 block  overflow-hidden group">
                        <Link to={`/User/Ventes/productDetail/${item.id}`}>
                          <div className="relative w-full  overflow-hidden">
                            <div className="h-24 md:h-56 ">
                              <LoadingImage
                                key={index}
                                alt=''
                                src={` https://btrproject.burundientempsreel.com/uploads/market/${item.profil}`}

                              />
                            </div>
                          </div>
                        </Link>
                        <div class="w-full ">
                          <div className="pt-3 md:pb-3 px-0 md:px-4">
                            {item.titre ? (
                              <>
                                <h4 className="font-medium text-xs md:text-base mb-2 text-gray-800 hover:text-blue-500 transition">
                                  {item.titre.length >= 40
                                    ? item.titre.substring(0, 40) + '....'
                                    : item.titre}
                                </h4>
                              </>
                            ) : (
                              <>
                                <BarLoader color="blue" width={30} height={2} />
                              </>
                            )}


                            <div className="md:flex flex flex-row md:flex-col  items-baseline mb-1 space-x-2">
                              <p className="text-[11px] md:text-base text-blue-500 font-semibold">

                                {item.prix ? (
                                  <>
                                    {item.prix} Fbu
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}

                              </p>
                              <p className="text-[7px] text-right md:text-sm text-red-400 line-through">
                                {item.prix ? (
                                  <>
                                    {item.prix + (item.prix * 20) / 100} Fbu
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}

                              </p>
                            </div>
                            <div className="md:flex hidden items-baseline mb-1 space-x-2">
                              <p className="text-xs text-gray-400 font-thin">
                                Disponible en stock :
                                { }


                                {item.quanite ? (
                                  <>
                                    {item.quanite} articles
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}

                              </p>
                            </div>
                            <div className="flex items-center">
                              <div className="flex gap-1 text-sm text-blue-400">
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star" />
                                </span>
                                <span>
                                  <i className="fa-solid fa-star" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="w-full md:flex items-end hidden justify-end">
                            <Link
                              to={`/User/Ventes/productDetail/${item.id}`}
                              class="block w-1/2  md:w-full py-1 text-center text-white bg-blue-500 border border-blue-500 rounded-2xl hover:bg-transparent hover:text-blue-500 transition"
                            >
                              Ajouter au panier
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            ) : data.length > 0 ? (
              <div class="grid md:grid-cols-4 grid-cols-3 md:px-2 md:gap-6 gap-3">

                {
                  data.map((item, index) => {
                    return (
                      <div class="border rounded-2xl p-2 block  overflow-hidden group">
                        <Link to={`/User/Ventes/productDetail/${item.id}`}>
                          <div className="relative w-full  overflow-hidden">
                            <div className="h-24 md:h-56 ">
                              <LoadingImage
                                key={index}
                                alt={`product ${index + 1}`}
                                src={` https://btrproject.burundientempsreel.com/uploads/market/${item.profil}`}

                              />
                            </div>
                          </div>
                        </Link>
                        <div class="w-full ">
                          <div className="pt-3 md:pb-3 px-0 md:px-4">
                            {item.titre ? (
                              <>
                                <h4 className="font-medium text-xs md:text-base mb-2 text-gray-800 hover:text-blue-500 transition">
                                  {item.titre.length >= 40
                                    ? item.titre.substring(0, 40) + '....'
                                    : item.titre}
                                </h4>
                              </>
                            ) : (
                              <>
                                <BarLoader color="blue" width={30} height={2} />
                              </>
                            )}

                            <div className="md:flex flex flex-row md:flex-col  items-baseline mb-1 space-x-2">
                              <p className="text-[11px] md:text-base text-blue-500 font-semibold">

                                {item.prix ? (
                                  <>
                                    {item.prix} Fbu
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}

                              </p>
                              <p className="text-[7px] text-right md:text-sm text-red-400 line-through">
                                {item.prix ? (
                                  <>
                                    {item.prix + (item.prix * 20) / 100} Fbu
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}

                              </p>
                            </div>
                            <div className="md:flex hidden items-baseline mb-1 space-x-2">
                              <p className="text-xs text-gray-400 font-thin">
                                Disponible en stock :


                                {item.quanite ? (
                                  <>
                                    {item.quanite} articles
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}
                              </p>
                            </div>
                            <div className="flex items-center">
                              <div className="flex gap-1 text-sm text-blue-400">
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star" />
                                </span>
                                <span>
                                  <i className="fa-solid fa-star" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="w-full md:flex items-end hidden justify-end">
                            <Link
                              to={`/User/Ventes/productDetail/${item.id}`}
                              class="block w-1/2  md:w-full py-1 text-center text-white bg-blue-500 border border-blue-500 rounded-2xl hover:bg-transparent hover:text-blue-500 transition"
                            >
                              Ajouter au panier
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            ) : (
              <div className='w-[100%] h-[70vh] flex justify-center items-center '>
                <Empty />
              </div>
            )}

          </div>
          {productSearch.length > 0 || (!datanotsearch && data.length === 0) ? (
            <></>
          ) : (
            <div className="flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4">
              <div className="lg:w-3/5 w-full flex items-center justify-between border-t border-gray-200 dark:border-gray-700">
                <div className="flex items-center pt-3 mr-4 text-gray-600 dark:text-gray-200 hover:text-blue-700 cursor-pointer">
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.1665 4H12.8332"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.1665 4L4.49984 7.33333"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.1665 4.00002L4.49984 0.666687"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <button
                    onClick={previousPage}
                    disabled={currentPage === 1}
                    className="text-sm ml-3 text-black  hover:text-blue-700 cursor-pointer"
                  >
                    Prec
                  </button>
                </div>
                <div className="sm:flex mt-3">{generatePageNumbers()}</div>
                <div className="flex items-center pt-3 ml-4 text-black  hover:text-blue-700 cursor-pointer">
                  <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                    className="text-sm font-medium leading-none mr-3"
                  >
                    Suiv
                  </button>
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.1665 4H12.8332"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.5 7.33333L12.8333 4"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.5 0.666687L12.8333 4.00002"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Product_ListSeller
