/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
function NavBarsEchangeAdmin({ GetHeight }) {

  const [imageLoading, SetImageLoading] = useState(true)
  const [imageLoading1, SetImageLoading1] = useState(true)
  const { pathname } = useLocation();
  const ListClient = /^\/Admin\/Client.*/
  const AjoutClient = /^\/Admin\/NouvClient.*/
  const StandBuyClient = /^\/Admin\/StandBuy.*/
  const Command = /^\/Admin\/Command.*/
  const Echange = /^\/Admin\/echange.*/
  const Avis = /^\/Admin\/avis.*/
  const Fraistransaction = /^\/Admin\/fraistransaction.*/



  const history = useNavigate();
  const userId = localStorage.getItem("idSuperagent");
  const [menu, setMenu] = useState(false);
  const [profil, setProfile] = useState({});
  useEffect(() => {
    const windowClicked = () => {
      setMenu(false);
    };

    window.addEventListener("click", windowClicked);

    return () => {
      window.removeEventListener("click", windowClicked);
    };
  }, []);

  useEffect(() => {
    axios
      .get(`https://btrproject.burundientempsreel.com/surperagent/profil/${userId}`)
      .then((response) => {
        setProfile(response.data);
        SetImageLoading1(false)
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [userId]);

  function logout() {
    localStorage.removeItem("t");
    localStorage.removeItem("idSuperagent");
    localStorage.removeItem("userRole");
    history("/");
    window.location.reload();
  }


  const [mobile, SetMobile] = useState(window.innerWidth < 560)
  const [mobile4, SetMobile4] = useState(window.innerWidth < 900)
  const [mobile3, SetMobile3] = useState(window.innerWidth < 1191)
  const [mobile5, SetMobile5] = useState(window.innerWidth < 496)
  const [mobile1, SetMobile1] = useState(window.innerWidth < 460)
  const [mobile2, SetMobile2] = useState(window.innerWidth < 346)





  useEffect(() => {
    const hunldeSize = () => {
      SetMobile(window.innerWidth < 560)
      SetMobile4(window.innerWidth < 900)
      SetMobile3(window.innerWidth < 1191)
      SetMobile5(window.innerWidth < 496)
      SetMobile1(window.innerWidth < 460)
      SetMobile2(window.innerWidth < 346)
    }

    window.addEventListener('resize', hunldeSize)
    return () => {
      window.removeEventListener('resize', hunldeSize)
    }
  }, [])

  GetHeight(mobile5)
  const [getCommandLong, SetgetCommandLong] = useState(0)
  const [getHostoryLong, SetgetHostoryLong] = useState(0)
  const [getStandByLongs, SetgetStandByLongs] = useState(0)
  const [getClientLongs, SetgetClientLongs] = useState(0)
  const [totalechange, setToatalechange] = useState(0)
  const [totalavis, setToatalAvis] = useState(0)
  const [totalanullation, setToatalAnullation] = useState(0)

  useEffect(() => {
    axios
      .get("https://btrproject.burundientempsreel.com/commande/countstandbuy")
      .then((response) => {
        SetgetCommandLong(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://btrproject.burundientempsreel.com/avis/countstandbuy")
      .then((response) => {
        setToatalAvis(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://btrproject.burundientempsreel.com/echangeur/countstandbuy")
      .then((response) => {
        SetgetStandByLongs(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://btrproject.burundientempsreel.com/commande/countstandbuyhistory")
      .then((response) => {
        SetgetHostoryLong(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  useEffect(() => {
    axios
      .get("https://btrproject.burundientempsreel.com/echangeur/counttousbuy")
      .then((response) => {
        SetgetClientLongs(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://btrproject.burundientempsreel.com/fashanya/countechanges")
      .then((response) => {
        setToatalechange(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);


  useEffect(() => {
    axios
      .get("https://btrproject.burundientempsreel.com/anl/counteencours")
      .then((response) => {
        setToatalAnullation(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  return (
    <div className=' border-b border-blue-500 overflow-hidden rounded-b-2xl'>
      {profil && (
        <div className="bg-gray-100 px-[20px] paddig w-full flex justify-between h-[10vh] min-h-[63px] items-center  relative border-b border-gray-300">
          <Link to='/' className="flex justify-center items-center pr-1">
            <div className="w-[60px] h-[60px] relative  rounded-full overflow-hidden bg-transparent  ">
              {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
              <img draggable='false' onLoad={() => {
                setTimeout(() => {
                  SetImageLoading(false)
                }, 1000)
              }} src={` https://btrproject.burundientempsreel.com/uploads/logo/btr.png`} className='h-full w-full object-contain object-center' />
            </div>
            {mobile1 ? (<><div className="ml-1 text-blue-800 font-serif text-center ">BTR</div>
            </>) : (<>
              <div className="ml-1 text-blue-800 font-serif text-center ">Burundi en temps réel</div>

            </>)}
          </Link>

          {mobile4 ? (

            <div className=' items-center justify-center flex'>
              <div className="flex justify-center items-center ">

                <div className='w-max  pt-1'>
                  {ListClient.test(pathname) ? (
                    <>
                      <Link to='/Admin/Client' className={`flex relative mx-1   ${mobile5 ? 'w-[25px] h-[25px]' : 'w-[40px] h-[40px] '}  justify-center items-center    text-blue-700     transition-all `} >
                        <div className="flex justify-center  items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill h-5  w-5" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                          <div className="absolute -top-2 right-2 bg-blue-700 text-white w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                            {getClientLongs}
                          </div>
                        </div>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to='/Admin/Client'
                        className={`flex relative  justify-center items-center  ${mobile5 ? 'w-[25px] h-[25px]' : 'w-[40px] h-[40px] '}      mx-1   transition-all `} >
                        <div className="flex justify-center  items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill h-5  w-5" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                          <div className="absolute -top-2 right-2 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                            {getClientLongs}
                          </div>
                        </div>
                      </Link>
                    </>
                  )}
                </div>
                <div>
                  {AjoutClient.test(pathname) ? (
                    <>
                      <Link to="/Admin/NouvClient" className={`flex   ${mobile5 ? 'w-[25px] h-[25px]' : 'w-[40px] h-[40px] '} mx-1  text-blue-700  justify-center items-center  p-1          transition-all `} >
                        <div className="flex justify-center flex-col items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill h-5  w-5" viewBox="0 0 16 16">
                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                            <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                          </svg>
                        </div>
                      </Link>
                    </>) : (<>

                      <Link to="/Admin/NouvClient" className={`flex     mx-1   ${mobile5 ? 'w-[25px] h-[25px]' : 'w-[40px] h-[40px] '}   justify-center items-center  p-1      transition-all `} >
                        <div className="flex justify-scenter flex-col items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill   h-5  w-5" viewBox="0 0 16 16">
                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                            <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                          </svg>
                        </div>
                      </Link>
                    </>)}
                </div>


                <div>
                  {Avis.test(pathname) ? (
                    <>
                      <Link to="/Admin/avis" className={`flex  relative  ${mobile5 ? 'w-[25px] h-[25px]' : 'w-[40px] h-[40px] '} mx-1   text-blue-700  justify-center items-center  p-1          transition-all `} >
                        <div className="flex justify-center flex-col items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell-fill  h-5  w-5 mt-1.5" viewBox="0 0 16 16">
                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                          </svg>
                          <div className="absolute -top-2 right-2 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                            {totalavis}
                          </div>
                        </div>
                      </Link>
                    </>) : (<>

                      <Link to="/Admin/avis" className={`flex  relative    mx-1   ${mobile5 ? 'w-[25px] h-[25px]' : 'w-[40px] h-[40px] '}   justify-center items-center  p-1      transition-all `} >
                        <div className="flex justify-scenter flex-col items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell-fill h-5  w-5 mt-1.5" viewBox="0 0 16 16">
                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                          </svg>
                          <div className="absolute -top-2 right-2 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                            {totalavis}
                          </div>
                        </div>
                      </Link>
                    </>)}
                </div>
              </div>
              {pathname == '/Admin/suppressionCompte' ? (
                <Link to='/Admin/suppressionCompte' className=" relative w-7 h-7 mx-1 sm:mx-2 rounded-full cursor-pointer flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-x text-blue-900 h-5 w-5" viewBox="0 0 16 16">
                    <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708" />
                  </svg>
                  <div className="absolute rounded-full bg-red-600 flex justify-center items-center text-white w-5 h-5 -top-[8px] -right-1 pointer-events-none">{totalanullation}</div>
                </Link>
              ) : (
                <Link to='/Admin/suppressionCompte' className=" relative w-7 h-7 mx-1 sm:mx-2 rounded-full cursor-pointer flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-x h-5 w-5" viewBox="0 0 16 16">
                    <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708" />
                  </svg>
                  <div className="absolute rounded-full bg-red-600 flex justify-center items-center text-white w-5 h-5 -top-[8px] -right-1 pointer-events-none">{totalanullation}</div>
                </Link>
              )}

              <div onClick={(e) => { setMenu(!menu); e.stopPropagation() }} className=" pr-4 cursor-pointer flex items-center w-max  h-full    ">
                <div className="flex justify-end  items-center h-full      ">
                  <div className=" transition-all border w-[55px] h-[55px] relative overflow-hidden rounded-full   ">
                    {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false' src={` https://btrproject.burundientempsreel.com/uploads/photosuperagent/${profil.photo}`} className='w-full h-full object-cover' />
                  </div>
                  <div className={`${mobile4 ? 'hidden' : ''}`}>
                    {profil.nom && (profil.nom + ' ' + profil.prenom)}
                  </div>
                </div>
                <div className=" flex relative top-4 shadow-2xl items-center">
                  <div className={` transition-all shadow-2xl ${menu ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} absolute  font-serif z-50 bg-white top-[110%] rounded right-0 paramare bloksCo`}>
                    <div className=" m-1 px-1 flex cursor-pointer bg-gray-100 p-1 rounded">
                      <i class="bi bi-person-vcard-fill"></i>
                      <Link to='/Admin/Compte' className='ml-3'>Compte</Link>
                    </div>
                    <div className=" flex m-1 px-1 cursor-pointer bg-gray-100 p-1 rounded">

                      <i class="bi bi-box-arrow-in-left"></i>
                      <p className='ml-2' onClick={logout}> Déconnecter</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="  flex items-center w-max  h-full pr-2   ">
                {pathname == '/Admin/suppressionCompte' ? (
                  <Link to='/Admin/suppressionCompte' className=" relative w-7 h-7 mx-1 sm:mx-2 rounded-full cursor-pointer flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-x  text-blue-900 h-6 w-6" viewBox="0 0 16 16">
                      <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708" />
                    </svg>
                    <div className="absolute rounded-full bg-red-600 flex justify-center items-center text-white w-5 h-5 -top-[8px] -right-1 pointer-events-none">{totalanullation}</div>
                  </Link>
                ) : (
                  <Link to='/Admin/suppressionCompte' className=" relative w-7 h-7 mx-1 sm:mx-2 rounded-full cursor-pointer flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-x  h-6 w-6" viewBox="0 0 16 16">
                      <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708" />
                    </svg>
                    <div className="absolute rounded-full bg-red-600 flex justify-center items-center text-white w-5 h-5 -top-[8px] -right-1 pointer-events-none">{totalanullation}</div>
                  </Link>
                )}

                <div className="flex justify-end  items-center h-full    mr-1 px-1 ">
                  <div className=" transition-all border w-[55px] h-[55px] relative overflow-hidden rounded-full  mr-2 ">
                    {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false' src={` https://btrproject.burundientempsreel.com/uploads/photosuperagent/${profil.photo}`} className='w-full h-full object-cover' />
                  </div>
                  <div className={`${mobile ? 'hidden' : ''}`}>
                    {profil.nom && (profil.nom + ' ' + profil.prenom)}
                  </div>
                </div>

                <div onClick={(e) => { setMenu(!menu); e.stopPropagation() }} className=" flex relative items-center">

                  <p className={` bg-[#0000ff44] text-blue-800 py-1 ${mobile4 ? 'hidden' : 'block'} rounded-l px-2 cursor-pointer`}>Parametre</p>
                  <div className={` transition-all shadow-2xl ${menu ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} absolute  font-serif z-50 bg-white top-[110%] rounded right-0 paramare bloksCo`}>

                    <div className=" m-1 px-1 flex cursor-pointer bg-gray-100 p-1 rounded">
                      <i class="bi bi-person-vcard-fill "></i>
                      <Link to='/Admin/Compte' className='ml-3'>Compte</Link>
                    </div>
                    <div className=" flex m-1 px-1 cursor-pointer bg-gray-100 p-1 rounded">

                      <i class="bi bi-box-arrow-in-left"></i>
                      <p className='ml-2' onClick={logout}> Déconnecter</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

        </div>
      )}





      {mobile3 ? (
        <>
          <div className={`bg-gray-100   ${SetMobile2 ? 'px-[10px]' : 'px-[20px]'}  w-full flex justify-between ${mobile5 ? 'h-[7vh]' : 'h-[10vh] min-h-[63px]'} items-end relative`}>
            <div className='w-max  pt-1'>
              {pathname == "/" ? (
                <Link
                  to="/"
                  className={`flex  ${mobile5 ? 'px-1 text-blue-700' : 'px-4  h-14 border-b-2   border-blue-600'}   pb-2 rounded-t-xl justify-center items-center  p-1 w-max     transition-all `} >
                  <div className="flex  justify-center   flex-col  items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-houses-fill  w-5 h-5" viewBox="0 0 16 16">
                      <path d="M7.207 1a1 1 0 0 0-1.414 0L.146 6.646a.5.5 0 0 0 .708.708L1 7.207V12.5A1.5 1.5 0 0 0 2.5 14h.55a2.51 2.51 0 0 1-.05-.5V9.415a1.5 1.5 0 0 1-.56-2.475l5.353-5.354z" />
                      <path d="M8.793 2a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708z" />
                    </svg>
                    <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Acceuil</p>
                  </div>
                </Link>
              ) : (
                <Link
                  to="/" className={`flex   ${mobile5 ? 'px-1' : 'px-4  h-14 hover:border-b-2  hover:border-blue-400'} pb-2 rounded-t-xl         justify-center items-center  p-1 w-max        transition-all `} >
                  <div className="flex  justify-center  flex-col  items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-houses-fill  w-5 h-5" viewBox="0 0 16 16">
                      <path d="M7.207 1a1 1 0 0 0-1.414 0L.146 6.646a.5.5 0 0 0 .708.708L1 7.207V12.5A1.5 1.5 0 0 0 2.5 14h.55a2.51 2.51 0 0 1-.05-.5V9.415a1.5 1.5 0 0 1-.56-2.475l5.353-5.354z" />
                      <path d="M8.793 2a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708z" />
                    </svg>
                    <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Acceuil</p>
                  </div>
                </Link>
              )}
            </div>

            {!mobile4 &&
              <>

                <div className='w-max  pt-1'>
                  {ListClient.test(pathname) ? (
                    <>
                      <Link to='/Admin/Client' className={`flex relative   ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max     border-b-2   border-blue-600   transition-all `} >
                        <div className="flex justify-center flex-col items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill h-5 w-5" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                          <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Listes clients</p>
                          <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                            {getClientLongs}
                          </div>
                        </div>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to='/Admin/Client'
                        className={`flex  relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl      hover:border-b-2  hover:border-blue-400   justify-center items-center  p-1 w-max        transition-all `} >
                        <div className="flex justify-center flex-col items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill h-5 w-5" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                          <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Listes clients</p>
                          <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                            {getClientLongs}
                          </div>
                        </div>
                      </Link>
                    </>
                  )}
                </div>

                <div>


                  {Echange.test(pathname) ? (
                    <>

                      <Link to="/Admin/echange" className={`flex  relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max     border-b-2   border-blue-600   transition-all `} >
                        <div className="flex justify-center flex-col items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange w-5 h-5" viewBox="0 0 16 16">
                            <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                          </svg>
                          <div className="absolute -top-2 right-2 bg-blue-700 text-white w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                            {totalechange}
                          </div>
                          <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Echange</p>
                        </div>
                      </Link>
                    </>
                  ) : (
                    <>

                      <Link to="/Admin/echange" className={`flex relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '}  rounded-t-xl  r    hover:border-b-2  hover:border-blue-400   justify-center items-center  p-1 w-max        transition-all `} >
                        <div className="flex justify-scenter flex-col items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange w-5 h-5" viewBox="0 0 16 16">
                            <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                          </svg>
                          <div className="absolute -top-2 right-2 bg-blue-700 text-white w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                            {totalechange}
                          </div>
                          <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Echange</p>
                        </div>
                      </Link>
                    </>
                  )}

                </div>

                <div>


                  {Avis.test(pathname) ? (<>

                    <Link to="/Admin/avis" className={`flex  relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max     border-b-2   border-blue-600   transition-all `} >
                      <div className="flex justify-center flex-col items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell-fill  h-5 w-5" viewBox="0 0 16 16">
                          <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                        </svg>
                        <div className="absolute -top-2 right-2 bg-blue-700 text-white w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                          {totalavis}
                        </div>
                        <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Avis</p>
                      </div>
                    </Link>
                  </>) : (<>

                    <Link to="/Admin/avis" className={`flex relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl  r    hover:border-b-2  hover:border-blue-400   justify-center items-center  p-1 w-max        transition-all `} >

                      <div className="flex justify-scenter flex-col items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell-fill  h-5 w-5" viewBox="0 0 16 16">
                          <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                        </svg>
                        <div className="absolute -top-2 right-2 bg-blue-700 text-white w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                          {totalavis}
                        </div>
                        <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Avis</p>
                      </div>
                    </Link>
                  </>)}

                </div>


                <div>


                  {AjoutClient.test(pathname) ? (<>

                    <Link to="/Admin/NouvClient" className={`flex   ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max     border-b-2   border-blue-600   transition-all `} >
                      <div className="flex justify-center flex-col items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                        </svg>
                        <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Frais de transation</p>
                      </div>
                    </Link>
                  </>) : (<>

                    <Link to="/Admin/NouvClient" className={`flex   ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl  r    hover:border-b-2  hover:border-blue-400   justify-center items-center  p-1 w-max        transition-all `} >

                      <div className="flex justify-scenter flex-col items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                        </svg>
                        <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Frais de transation</p>
                      </div>
                    </Link>
                  </>)}

                </div>

                <div>



                  {AjoutClient.test(pathname) ? (<>

                    <Link to="/Admin/NouvClient" className={`flex   ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max     border-b-2   border-blue-600   transition-all `} >
                      <div className="flex justify-center flex-col items-center">
                        <i class="bi bi-person-fill-add text-[25px] p-[1px]"></i>
                        <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Nouv.Client </p>
                      </div>
                    </Link>
                  </>) : (<>

                    <Link to="/Admin/NouvClient" className={`flex   ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl  r    hover:border-b-2  hover:border-blue-400   justify-center items-center  p-1 w-max        transition-all `} >

                      <div className="flex justify-scenter flex-col items-center">
                        <i class="bi bi-person-fill-add text-[25px] p-[1px]"></i>
                        <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Nouv.Client </p>
                      </div>
                    </Link>
                  </>)}
                </div>

              </>
            }
            <div>
              {Echange.test(pathname) ? (
                <>

                  <Link to="/Admin/echange" className={`flex relative   ${mobile5 ? 'px-1 text-blue-700' : 'px-4  h-14 border-b-2   border-blue-600  '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max transition-all `} >
                    <div className="flex justify-center flex-col items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange w-5 h-5" viewBox="0 0 16 16">
                        <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                      </svg>
                      <div className="absolute -top-2 right-2 bg-blue-700 text-white w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                        {totalechange}
                      </div>
                      <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Echange</p>
                    </div>
                  </Link>
                </>
              ) : (
                <>

                  <Link to="/Admin/echange" className={`flex relative  ${mobile5 ? 'px-1' : 'px-4  h-14 hover:border-b-2  hover:border-blue-400'} rounded-t-xl pb-2   justify-center items-center  p-1 w-max        transition-all `} >
                    <div className="flex justify-scenter flex-col items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange w-5 h-5" viewBox="0 0 16 16">
                        <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                      </svg>
                      <div className="absolute -top-2 right-2 bg-blue-700 text-white w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                        {totalechange}
                      </div>
                      <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Echange</p>
                    </div>
                  </Link>
                </>
              )}

            </div>

            <div>


              {Fraistransaction.test(pathname) ? (<>

                <Link to="/Admin/fraistransaction" className={`flex   ${mobile5 ? 'px-1 text-blue-700' : 'px-4  h-14 border-b-2   border-blue-600 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max       transition-all `} >
                  <div className="flex justify-center flex-col items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                    </svg>
                    <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Frais de transation</p>
                  </div>
                </Link>
              </>) : (<>

                <Link to="/Admin/fraistransaction" className={`flex  ${mobile5 ? 'px-1' : 'px-4  h-14 hover:border-b-2  hover:border-blue-400'} pb-2 rounded-t-xl        justify-center items-center  p-1 w-max        transition-all `} >

                  <div className="flex justify-scenter flex-col items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                    </svg>
                    <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Frais de transation</p>
                  </div>
                </Link>
              </>)}

            </div>

            <div>
              {StandBuyClient.test(pathname) ? (<>

                <Link to="/Admin/StandBuy" className={`flex relative  ${mobile5 ? 'px-1 text-blue-700' : 'px-4  h-14 border-b-2   border-blue-600 '} relative pb-2 rounded-t-xl justify-center items-center  p-1 w-max    transition-all `} >
                  <div className="flex justify-center  flex-col items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill-exclamation w-5 h-5" viewBox="0 0 16 16">
                      <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4" />
                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 1 0V11a.5.5 0 0 0-.5-.5m0 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                    </svg>
                    <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>StandBuy </p>
                    <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                      {getStandByLongs}
                    </div>
                  </div>
                </Link>
              </>) : (<>

                <Link to="/Admin/StandBuy" className={`flex relative  ${mobile5 ? 'px-1' : 'px-4  h-14 hover:border-b-2  hover:border-blue-400'} relative pb-2 rounded-t-xl       justify-center items-center  p-1 w-max        transition-all `} >

                  <div className="flex justify-center  flex-col items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill-exclamation  w-5 h-5" viewBox="0 0 16 16">
                      <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4" />
                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 1 0V11a.5.5 0 0 0-.5-.5m0 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                    </svg>
                    <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>StandBuy </p>
                    <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                      {getStandByLongs}
                    </div>
                  </div>
                </Link>
              </>)}
            </div>



            <div>
              {Command.test(pathname) ? (<>

                <Link to="/Admin/Command" className={`flex relative  ${mobile5 ? 'px-1 text-blue-700' : 'px-4  h-14 border-b-2   border-blue-600 '}  rounded-t-xl justify-center items-center  p-1 w-max        transition-all `} >
                  <div className="flex justify-center flex-col items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-nested  w-5 h-5" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5m-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5" />
                    </svg>
                    <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>commandes</p>
                    <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                      {getCommandLong}
                    </div>
                  </div>
                </Link>
              </>) : (<>

                <Link to="/Admin/Command" className={`flex relative  ${mobile5 ? 'px-1' : 'px-4  h-14 hover:border-b-2  hover:border-blue-400'}  rounded-t-xl        justify-center items-center  p-1 w-max        transition-all `} >

                  <div className="flex justify-center flex-col items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-nested  w-5 h-5" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5m-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5" />
                    </svg>
                    <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>commandes</p>
                    <div className="absolute -top-2 right-1 bg-blue-700 text-white w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                      {getCommandLong < 10 ? getCommandLong : '9+'}
                    </div>
                  </div>
                </Link>
              </>)}
            </div>

            <div>
              {pathname == '/Admin/History' ? (
                <>

                  <Link to="/Admin/History" className={`flex relative  ${mobile5 ? 'px-1 text-blue-700' : 'px-4  h-14 border-b-2   border-blue-600 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max        transition-all `} >
                    <div className="flex justify-center flex-col items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hourglass-split w-5 h-5" viewBox="0 0 16 16">
                        <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                      </svg>
                      <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Historique </p>
                      <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                        {getHostoryLong}
                      </div>
                    </div>
                  </Link>
                </>) : (<>

                  <Link to="/Admin/History" className={`flex relative  ${mobile5 ? 'px-1' : 'px-4  h-14 hover:border-b-2  hover:border-blue-400hover:border-b-2  hover:border-blue-400'} pb-2 rounded-t-xl  justify-center items-center  p-1 w-max        transition-all `} >
                    <div className="flex justify-center flex-col items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hourglass-split  w-5 h-5" viewBox="0 0 16 16">
                        <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                      </svg>
                      <p className={` ${mobile5 ? 'hidden' : ''} text-[10px]`}>Historique </p>
                      <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                        {getHostoryLong}
                      </div>
                    </div>
                  </Link>
                </>)}
            </div>
          </div>
        </>
      ) : (
        <>




          <div className={`bg-gray-100   ${SetMobile2 ? 'px-[10px]' : 'px-[20px]'}  w-full flex justify-between  h-[10vh] min-h-[63px] items-end relative`}>
            <div className='w-max  pt-1'>
              {pathname == "/" ? (
                <Link
                  to="/"
                  className={`flex  px-4  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max     border-b-2   border-blue-600   transition-all `} >
                  <div className="flex  justify-center    items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-houses-fill mr-2 w-5 h-5" viewBox="0 0 16 16">
                      <path d="M7.207 1a1 1 0 0 0-1.414 0L.146 6.646a.5.5 0 0 0 .708.708L1 7.207V12.5A1.5 1.5 0 0 0 2.5 14h.55a2.51 2.51 0 0 1-.05-.5V9.415a1.5 1.5 0 0 1-.56-2.475l5.353-5.354z" />
                      <path d="M8.793 2a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708z" />
                    </svg>
                    <p className="">Acceuil</p>
                  </div>
                </Link>
              ) : (

                <Link
                  to="/"
                  className={`flex  px-4  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max   hover:border-b-2   hover:border-blue-400 transition-all `} >
                  <div className="flex  justify-center    items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-houses-fill mr-2 w-5 h-5" viewBox="0 0 16 16">
                      <path d="M7.207 1a1 1 0 0 0-1.414 0L.146 6.646a.5.5 0 0 0 .708.708L1 7.207V12.5A1.5 1.5 0 0 0 2.5 14h.55a2.51 2.51 0 0 1-.05-.5V9.415a1.5 1.5 0 0 1-.56-2.475l5.353-5.354z" />
                      <path d="M8.793 2a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708z" />
                    </svg>
                    <p className="">Acceuil</p>
                  </div>
                </Link>
              )}
            </div>

            <div className='w-max  pt-1'>
              {ListClient.test(pathname) ? (
                <>
                  <Link to='/Admin/Client' className={`flex relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max     border-b-2   border-blue-600   transition-all `} >
                    <div className="flex justify-start items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill h-5 w-5 mr-2" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                        <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                      </svg>
                      <p className='sizeText'>Listes</p>
                      <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                        {getClientLongs}
                      </div>
                    </div>
                  </Link>
                </>
              ) : (
                <>
                  <Link to='/Admin/Client'
                    className={`flex  relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl  r    hover:border-b-2  hover:border-blue-400   justify-center items-center  p-1 w-max        transition-all `} >
                    <div className="flex justify-start items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill h-5 w-5 mr-2" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                        <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                      </svg>
                      <p className='sizeText'>Listes</p>
                      <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                        {getClientLongs}
                      </div>
                    </div>
                  </Link>
                </>
              )}
            </div>

            <div>
              {Echange.test(pathname) ? (<>

                <Link to="/Admin/echange" className={`flex relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max     border-b-2   border-blue-600   transition-all `} >
                  <div className="flex justify-start items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange w-5 h-5 mr-2" viewBox="0 0 16 16">
                      <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                    </svg>
                    <div className="absolute -top-2 right-2 bg-blue-700 text-white w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                      {totalechange}
                    </div>
                    <p className='sizeText'>Echange </p>
                  </div>
                </Link>
              </>) : (<>

                <Link to="/Admin/echange" className={`flex  relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl  r    hover:border-b-2  hover:border-blue-400   justify-center items-center  p-1 w-max        transition-all `} >

                  <div className="flex justify-start items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange w-5 h-5 mr-2" viewBox="0 0 16 16">
                      <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                    </svg>

                    <div className="absolute -top-2 right-2 bg-blue-700 text-white w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                      {totalechange}
                    </div>
                    <p className='sizeText'>Echange </p>
                  </div>
                </Link>
              </>)}
            </div>

            <div>
              {Avis.test(pathname) ? (<>

                <Link to="/Admin/avis" className={`flex relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max     border-b-2   border-blue-600   transition-all `} >
                  <div className="flex justify-start items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell-fill mr-2 h-5 w-5" viewBox="0 0 16 16">
                      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                    </svg>
                    <div className="absolute -top-2 right-2 bg-blue-700 text-white w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                      {totalavis}
                    </div>
                    <p className='sizeText'>Avis </p>
                  </div>
                </Link>
              </>) : (<>

                <Link to="/Admin/avis" className={`flex relative   ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl  r    hover:border-b-2  hover:border-blue-400   justify-center items-center  p-1 w-max        transition-all `} >

                  <div className="flex justify-start items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell-fill mr-2 h-5 w-5" viewBox="0 0 16 16">
                      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                    </svg>
                    <div className="absolute -top-2 right-2 bg-blue-700 text-white w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                      {totalavis}
                    </div>
                    <p className='sizeText'>Avis</p>
                  </div>
                </Link>
              </>)}
            </div>

            <div>
              {Fraistransaction.test(pathname) ? (<>

                <Link to="/Admin/fraistransaction" className={`flex   ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max     border-b-2   border-blue-600   transition-all `} >
                  <div className="flex justify-start items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                    </svg>
                    <p className='sizeText'>Frais de transaction </p>
                  </div>
                </Link>
              </>
              ) : (
                <>
                  <Link to="/Admin/fraistransaction" className={`flex   ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl  r    hover:border-b-2  hover:border-blue-400   justify-center items-center  p-1 w-max        transition-all `} >
                    <div className="flex justify-start items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`${mobile4 ? 'w-4 h-4' : ' mr-2 w-6 h-6'}`} viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                      </svg>
                      <p className='sizeText'>Frais de transaction</p>
                    </div>
                  </Link>
                </>
              )}
            </div>
            <div>



              {AjoutClient.test(pathname) ? (<>

                <Link to="/Admin/NouvClient" className={`flex   ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max     border-b-2   border-blue-600   transition-all `} >
                  <div className="flex justify-start items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill  mr-2 h-5 w-5" viewBox="0 0 16 16">
                      <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                      <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                    </svg>
                    <p className='sizeText'>Nouv.Client </p>
                  </div>
                </Link>
              </>) : (<>

                <Link to="/Admin/NouvClient" className={`flex   ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl  r    hover:border-b-2  hover:border-blue-400   justify-center items-center  p-1 w-max        transition-all `} >

                  <div className="flex justify-start items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill mr-2 h-5 w-5" viewBox="0 0 16 16">
                      <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                      <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                    </svg>
                    <p className='sizeText'>Nouv.Client </p>
                  </div>
                </Link>
              </>)}
            </div>




            <div>
              {StandBuyClient.test(pathname) ? (<>

                <Link to="/Admin/StandBuy" className={`flex relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max     border-b-2   border-blue-600   transition-all `} >
                  <div className="flex justify-start items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill-exclamation mr-2 w-5 h-5" viewBox="0 0 16 16">
                      <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4" />
                      <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 1 0V11a.5.5 0 0 0-.5-.5m0 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                    </svg>
                    <p className='sizeText'>StandBuy </p>
                    <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                      {getStandByLongs}
                    </div>
                  </div>
                </Link>
              </>) : (
                <>

                  <Link to="/Admin/StandBuy" className={`flex relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl  r    hover:border-b-2  hover:border-blue-400   justify-center items-center  p-1 w-max        transition-all `} >

                    <div className="flex justify-start items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill-exclamation mr-2 w-5 h-5" viewBox="0 0 16 16">
                        <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4" />
                        <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 1 0V11a.5.5 0 0 0-.5-.5m0 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                      </svg>
                      <p className='sizeText'>StandBuy </p>
                      <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                        {getStandByLongs}
                      </div>
                    </div>
                  </Link>
                </>)}
            </div>



            <div>
              {Command.test(pathname) ? (<>

                <Link to="/Admin/Command" className={`flex relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max     border-b-2   border-blue-600   transition-all `} >
                  <div className="flex justify-start items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-nested mr-2 w-5 h-5" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5m-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5" />
                    </svg>
                    <p className='sizeText'>List command</p>
                    <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                      {getCommandLong}
                    </div>
                  </div>
                </Link>
              </>) : (<>

                <Link to="/Admin/Command" className={`flex relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl  r    hover:border-b-2  hover:border-blue-400   justify-center items-center  p-1 w-max        transition-all `} >

                  <div className="flex justify-start items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-nested mr-2 w-5 h-5" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5m-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5" />
                    </svg>
                    <p className='sizeText'>List command</p>
                    <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                      {getCommandLong}
                    </div>
                  </div>
                </Link>
              </>)}
            </div>

            <div>
              {pathname == '/Admin/History' ? (
                <>

                  <Link to="/Admin/History" className={`flex relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl justify-center items-center  p-1 w-max     border-b-2   border-blue-600   transition-all `} >
                    <div className="flex justify-start items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hourglass-split mr-2 w-5 h-5" viewBox="0 0 16 16">
                        <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                      </svg>
                      <p className='sizeText'>Historique </p>
                      <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                        {getHostoryLong}
                      </div>
                    </div>
                  </Link>
                </>) : (<>

                  <Link to="/Admin/History" className={`flex  relative  ${mobile2 ? 'px-1  h-12' : 'px-4  h-14 '} pb-2 rounded-t-xl  r    hover:border-b-2  hover:border-blue-400   justify-center items-center  p-1 w-max        transition-all `} >
                    <div className="flex justify-start items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hourglass-split mr-2 w-5 h-5" viewBox="0 0 16 16">
                        <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                      </svg>
                      <p className='sizeText'>Historique </p>
                      <div className="absolute -top-2 right-1 bg-blue-700 text-white  w-[20px] h-[20px] rounded-full text-[10px] flex justify-center items-center">
                        {getHostoryLong}
                      </div>
                    </div>
                  </Link>
                </>)}
            </div>
          </div>
        </>)}

    </div>
  );
}

export default NavBarsEchangeAdmin;
