import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify';

function DetteEnregistr({ SetdettereEnregistr }) {
    const [prixUni, setprixUni] = useState('');
    const [animationClassprixUni, setAnimationClassprixUni] = useState('');
    const prixUniRef = useRef(null)


    const handleSubmit = (e) => {
        if (prixUni.trim() === '') {
            toast.warning('Entre la quantite du produits tel...', {
                autoClose: 2000
            });
            setAnimationClassprixUni('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassprixUni('');
            }, 3000);
            prixUniRef.current.focus()
            e.preventDefault();
            return;
        }


    }


    return (
        <div className='fixed w-full h-full bg-gray-100 top-0 left-0 flex justify-center items-center z-[1000]'>
            <div className="w-full  flex justify-center h-max">
                <div className="shadow1 p-2 rounded flex flex-col items-end ">
                    <div onClick={() => { SetdettereEnregistr(false) }} className="w-[30px] h-[30px] bg-[#ff0000ab] flex justify-center items-center text-white cursor-pointer rounded">X</div>
                    <div className={`border-2 rounded border-blue-600 p-2 mt-3  w-[20em] responsive3Form `}>
                        <form onSubmit={handleSubmit}>
                            <div className="mt-6 w-full ">
                                <div className="transition-all  w-[100%]">
                                    <div className="relative h-11 w-full InputsContact mt-10">
                                        <input ref={prixUniRef}
                                            type='number'
                                            value={prixUni}
                                            min='1'
                                            onInput={(e) => setprixUni(e.target.value)}
                                            className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassprixUni}`}
                                            placeholder=" "
                                        />
                                        <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                            Entre la somme de la dette
                                        </label>
                                    </div>



                                    <div className="flex justify-end items-center mt-[20px]">
                                        <div className="flex bg-blue-600 rounded cursor-pointer pl-2 py-1 text-white">
                                            <input type="submit" id="send" hidden />
                                            <label htmlFor="send" className="w-max flex justify-end p-1 cursor-pointer mx-1 ">
                                                <span className='mr-2'>Ajouter</span>
                                                <div className="bi bi-send"></div>
                                            </label>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetteEnregistr
