/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FadeLoader } from 'react-spinners'
import SpinnerDemarage from "../../../../SpinnerDemarage/SpinnerDemarage";
function VoirPlusClient() {
  const { id } = useParams();
  const history = useNavigate();
  const [loading, SetLoading] = useState(true)
  const [echangeur, setEchangeur] = useState({});
  const [imageLoading1, SetImageLoading1] = useState(true)

  const [boutLoading, setboutLoading] = useState(false);

  useEffect(() => {
    axios
      .get(` https://btrproject.burundientempsreel.com/echangeur/detail/${id}`)
      .then((response) => {
        setEchangeur(response.data);
        setTimeout(() => {
          SetLoading(false)
        }, 1000);
      })
      .catch((error) => {
        console.log(error.message);
        if (error.response) {
          console.error(error.response.data);
        }
      });
  }, [id]);

  const handleDesactivate = () => {
    axios.put(` https://btrproject.burundientempsreel.com/echangeur/changestatus/${id}`, {
      status: 0
    })
    setboutLoading(true)

      .then((response) => {
        toast.success("le compte de l'échangeur a été désactivé avec succès !");

        history('/Admin/Client');
      })
      .catch((error) => {
        toast.error("Une erreur s'est produite lors de la désactivation de l'échangeur.");
        if (error.response) {
          console.error(error.response.data);
        }
      }).finally(() => {
        setboutLoading(false)
      })
      ;
  };

  const [mobile, SetMobile] = useState(window.innerWidth < 552)
  const [mobile1, SetMobile1] = useState(window.innerWidth < 377)

  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth < 552)
      SetMobile1(window.innerWidth < 377)
    }

    window.addEventListener('resize', hundleSize)

    return () => {
      window.removeEventListener('resize', hundleSize)
    }
  }, [])

  const [imageLoading, SetImageLoading] = useState(true)
  return (
    <div className="flex  flex-col items-center   w-full min-h-[90vh]  ">
      <div className="w-full mt-2    relative left-2 rounded-l"><Link to='/Admin/Client' className="bg-blue-500 rouned-l text-white px-2 py-1">Retour</Link></div>

      {loading && <SpinnerDemarage />}

      {echangeur && (
        <>

          <div className={`flex-col border border-blue-700   ${mobile ? 'w-[95%]' : 'w-[30em]'} bg-white shadow-2xl rounded-2xl p-3 items-center m-2`}>
            <div className="w-[9em] h-[9em] border   m-2 rounded-full relative overflow-hidden">
              {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
              <img draggable='false' onLoad={() => {
                setTimeout(() => {
                  SetImageLoading(false)
                }, 1000)
              }}
                src={` https://btrproject.burundientempsreel.com/uploads/photosechange/${echangeur.profil}`}
                alt=""
                className="w-full h-full object-contain object-center "
              />
            </div>
            <div className="text-gray-500 leading-6">
              <div className={`${mobile1 ? 'flex-col' : ''} flex mb-5`}>
                {" "}
                <div className="font-bold mr-1 flex  w-[13em] justify-between">
                  Nom  <span>:</span>
                </div>
                <div>
                  {echangeur.nom}
                </div>
              </div>

              <div className={`${mobile1 ? 'flex-col' : ''} flex mb-5`}>
                {" "}
                <div className="font-bold mr-1 flex  w-[13em] justify-between">
                  Prénom <span>:</span>
                </div>
                <div>
                  {echangeur.prenom}
                </div>
              </div>







              <div className={`${mobile1 ? 'flex-col' : ''} flex mb-5`}>
                {" "}
                <div className="font-bold mr-1 flex  w-[13em] justify-between">
                  Telephone <span>:</span>
                </div>
                <div>{echangeur.tel}</div>
              </div>
              <div className={`flex  ${mobile ? 'flex-col leading-1' : ''} mb-5`}>
                {" "}
                <div className="font-bold mr-1 flex  w-[13em] justify-between">
                  Email valide <span>:</span>
                </div>
                <div>{echangeur.email}</div>
              </div>
              <div className={`${mobile1 ? 'flex-col' : ''} flex mb-5`}>
                {" "}
                <div className="font-bold mr-1 flex  w-[13em] justify-between">
                  Compte principal<span>:</span>
                </div>
                <div>{echangeur.banck}</div>
              </div>
              <div className={`${mobile1 ? 'flex-col' : ''} flex mb-5`}>
                {" "}
                <div className="font-bold mr-1 flex  w-[13em] justify-between">
                  Code  agent <span>:</span>
                </div>
                <div>{echangeur.codeagent}</div>
              </div>

              {echangeur.Compteechangeur && (

                <div className={`flex  ${mobile ? 'flex-col' : ''} mb-5 `}>
                  {" "}
                  <div className={`font-bold mr-1 flex  flex-nowrap w-[13em] justify-between`}>
                    Compte supplémentaires <span>:</span>
                  </div>
                  <div>{echangeur.Compteechangeur.compte}</div>
                </div>

              )}


              <div className=" mt-[10px] transition-all flex flex-wrap  justify-between ">
                <div class={`relative h-max mt-3 flex flex-col justify-center items-center bg-gray-100 p-2 text-gray-700  mr-2`}>
                  <label htmlFor="CNIAvant" class={`px-2  py-1 rounded sm:text-center `}>
                    Photo face avant du C.N.I
                  </label>
                  {echangeur.cnii && (
                    <div className="w-[12.5em] block h-[12.5em] opacity-100 transition-all   mt-1 rounded  bg-white border border-blue-500">
                      {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                      <img draggable='false' onLoad={() => {
                        setTimeout(() => {
                          SetImageLoading(false)
                        }, 1000)
                      }} src={`https://btrproject.burundientempsreel.com/uploads/photosechange/${echangeur.cnii}`} className="w-full h-full object-contain pointer-events-none" />
                    </div>
                  )}

                </div>
                <div class={`relative flex flex-col justify-center items-center h-max mt-3 bg-gray-100 text-gray-700 p-2  mr-2 `}>

                  <label htmlFor="CNIArrier" class={` px-2 t max-sm:text-center py-1 rounded  `}>
                    Photo face arrière du C.N.I
                  </label>
                  {echangeur.cnio && (
                    <div className="w-[12.5em] block h-[12.5em] opacity-100 transition-all mt-1 rounded  bg-white border border-blue-500">
                      {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                      <img draggable='false' onLoad={() => {
                        setTimeout(() => {
                          SetImageLoading1(false)
                        }, 1000)
                      }} src={`https://btrproject.burundientempsreel.com/uploads/photosechange/${echangeur.cnio}`} className="w-full h-full object-contain" />
                    </div>
                  )}
                </div>
              </div>

            </div>
            <div className=" flex justify-end mt-4 items-center -top-5 relative">

              {boutLoading ? (
                <>
                  <div disabled className="  cursor-no-drop mt-4 w-max relative  flex justify-center  items-center   bg-transparent ">
                    <button onClick={handleDesactivate} className=" m-2 bg-red-950 px-3  pointer-events-none transition-all rounded text-gray-400 py-1">
                      Desactiver
                    </button>
                    <div className='absolute bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                      <FadeLoader
                        color="rgb(255, 255, 255)"
                        height={10}
                        margin={-9}
                        radius={100}
                        speedMultiplier={1}
                        width={1}
                      />
                    </div>
                  </div>
                </>
              ) : (<>
                <button onClick={handleDesactivate} className=" m-2 bg-red-400 px-3 hover:bg-red-600 transition-all mt-4 rounded text-white py-1">
                  Desactiver
                </button>
              </>)}


            </div>
          </div>


        </>


      )
      }
    </div >
  );
}

export default VoirPlusClient;
