import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function EdicationConnaissVisitorPrimDetail() {
    return (
        <div
            style={{
                backgroundImage: "url('../image/bibli.jpg')",
                backgroundAttachment: "fixed",
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover'
            }}
            className='flex flex-col items-center'
        >
            <div className="flex flex-col w-[99%] items-center ">
                <div className="list bg-slate-100 w-[95%]  flex flex-col p-4 items-center">

                    <div className="flex flex-wrap justify-around ">
                        <div className="flex p-1 m-1 categori w-full">
                            <div className=" w-[100%] bg-gray-100 rounded overflow-hidden flex flexColDetail">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-left relative'>
                                    {/* <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>Titre: <span className='text-[10px]'>Non-fiction</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>Catégorie: <span className='text-[10px]'>Développement personnel</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>Description du livre: <span className='text-[10px]'>La richesse intellectuel est pour les jeunes</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>Auteur: <span className='text-[10px]'>Varia-Coeur</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>Editeur: <span className='text-[10px]'>Burundi en temp réel</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>Date de publication: <span className='text-[10px]'>Mardi,le 12/03/2020</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>Langue: <span className='text-[10px]'>Anglais</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>Lieu de publication: <span className='text-[10px]'>Burundi,Bujumbura  à KAMENGE</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>Exemplaire : <span className='text-[10px]'>5</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>Emplacement : <span className='text-[10px]'>Burundi,Bujumbura à KAMENGE étage 3</span> </h2>
                                    <h2 className='font-serif text-blue-800 p-1  rounded-lg w-max'>page : <span className='text-[10px]'>45</span> </h2>*/}
                                    <div className="pl-5 font-serif text-gray-500 relative mt-[30px] w-[95%]">
                                        Ce livre intitulé "Non-fiction" écrit par Varia-Coeur et publié par "Burundi en temps réel" le Mardi, le 12/03/2020 est classé dans la catégorie du "Développement personnel".<br /><br /> Ce livre, rédigé en Anglais, traite de la richesse intellectuelle et de son importance pour les jeunes. Nous disposons de 5 exemplaires de ce livre dans notre bibliothèque située à Bujumbura, Burundi, au quartier KAMENGE, à l'étage 3. Le livre contient 10 pages pour <span className='text-[22px] font-bold'>5$</span>.
                                    </div>
                                    <div className=" p-1 w-full  bottom-0 flex  ">
                                        <button className='p-2  bg-blue-400 rounded hover:bg-blue-600 text-white transition-all'>Ajouter au panier</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EdicationConnaissVisitorPrimDetail
