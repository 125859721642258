import React, { useEffect, useRef, useState } from "react";
import { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";

function SimilarProduct() {
  const [similarProducts, setSimilarProducts] = useState([]);
  const category = localStorage.getItem("categorysimulation");
  const id = localStorage.getItem("idsimulation");

  const LazyImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };

    const lazyLoad = () => {
      setImgSrc(src);
    };

    const elementInViewPort = () => {
      // getBoundingClientRect => returns the size of the given element and the position of it in relation to the view port
      const clientRect = imageRef.current.getBoundingClientRect();

      return (
        clientRect.top >= 0 &&
        clientRect.left >= 0 &&
        clientRect.bottom - 100 <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        clientRect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LazyImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };
  useEffect(() => {
    axios
      .get(
        ` https://btrproject.burundientempsreel.com/market/user/categorysimulation/${category}/${id}`
      )
      .then((response) => {
        setSimilarProducts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [category, id]);


  return (
    <>
      {similarProducts.length > 0 ? (
        <div className=" pb-16 pt-10">
          <h2 className="text-base md:text-2xl font-bold text-gray-400 uppercase mb-6">

            Produits similaires
          </h2>
          <div class="grid md:grid-cols-4 grid-cols-3 md:px-2 md:px-0  md:gap-6 gap-3">
            {similarProducts.map((item, index) => (
              <div class="border rounded-2xl p-2 block  overflow-hidden group">
                <Link
                  to={`/Visit/Marche/product/${item.id}`}
                >
                  <div className="relative w-full  overflow-hidden">
                    <div className="h-24 md:h-56 ">
                      <LazyImage
                        key={index}
                        alt={`product ${index + 1}`}
                        src={` https://btrproject.burundientempsreel.com/uploads/market/${item.profil}`}
                        loaderIcon="https://miro.medium.com/max/882/1*9EBHIOzhE1XfMYoKz1JcsQ.gif"
                      />
                    </div>
                  </div>
                </Link>
                <div class="w-full ">
                  <div className="pt-3 md:pb-3 px-0 md:px-4">
                    <h4 className="font-medium text-xs md:text-base mb-2 text-gray-800 hover:text-blue-500 transition">
                      {item.titre.length >= 40
                        ? item.titre.substring(0, 40) + "...."
                        : item.titre}
                    </h4>
                    <div className="md:flex flex flex-row md:flex-col  items-baseline mb-1 space-x-2">
                      <p className="text-[11px] md:text-base text-blue-500 font-semibold">
                        {item.prix} Fbu
                      </p>
                      <p className="text-[7px] text-right md:text-sm text-red-400 line-through">
                        {item.prix + (item.prix * 20) / 100} Fbu
                      </p>
                    </div>
                    <div className="md:flex hidden items-baseline mb-1 space-x-2">
                      <p className="text-xs text-gray-400 font-thin">
                        Disponible en stock :{item.quanite} articles
                      </p>
                    </div>
                    <div className="flex items-center">
                      <div className="flex gap-1 text-sm text-blue-400">
                        <span>
                          <i className="fa-solid fa-star"></i>
                        </span>
                        <span>
                          <i className="fa-solid fa-star"></i>
                        </span>
                        <span>
                          <i className="fa-solid fa-star"></i>
                        </span>
                        <span>
                          <i className="fa-solid fa-star" />
                        </span>
                        <span>
                          <i className="fa-solid fa-star" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:flex items-end hidden justify-end">
                    <Link
                      to={`/Visit/Marche/product/${item.id}`}
                      class="block w-1/2  md:w-full py-1 text-center text-white bg-blue-500 border border-blue-500 rounded-2xl hover:bg-transparent hover:text-blue-500 transition"
                    >
                      Commandez maintenant
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}

    </>
  );
}

export default SimilarProduct;
