/* eslint-disable jsx-a11y/alt-text */

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function LoisirVisitorHome() {
    const [imageLoading, SetImageLoading] = useState(true)
    const [imageLoading1, SetImageLoading1] = useState(true)
    const [imageLoading2, SetImageLoading2] = useState(true)
    const [imageLoading3, SetImageLoading3] = useState(true)


    const [sizeInfer, SetsizeInfer] = useState(window.innerWidth < 650)
    const [sizeInfer1, SetsizeInfer1] = useState(window.innerWidth < 650)
    const [sizeInfer2, SetsizeInfer2] = useState(window.innerWidth < 650)
    const [sizeInfer3, SetsizeInfer3] = useState(window.innerWidth < 650)



    const [titre, Settitre] = useState(window.innerWidth < 800.38383838282929)

    useEffect(() => {
        const hundleSize = () => {
            SetsizeInfer(window.innerWidth < 650)
            SetsizeInfer1(window.innerWidth < 650)
            SetsizeInfer2(window.innerWidth < 650)
            SetsizeInfer3(window.innerWidth < 650)
            Settitre(window.innerWidth < 800.38383838282929)


        }

        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    return (
        <>

            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}>  Bienvenue  sur notre site web dédié  transport</h2>

                    <div className={` ${imageLoading && sizeInfer ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading(false)
                            }, 1000)
                        }} src="../image/transport.png" alt="       " className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Bienvenue  sur notre site web dédié  transport</h2>
                        <p className="text-gray-500 p-1 text-[22px] mt-3 ">
                            <div className="reduitTailParagr text-gray-500">
                                Notre équipe dévouée est prête à répondre à toutes vos questions et à vous fournir les meilleurs conseils pour rendre vos déplacements plus faciles et plus agréables. Que vous préfériez les transports en commun, les taxis ou les services de location de véhicules, nous avons toutes les informations dont vous avez besoin.

                                <br />

                                Nous sommes ravis de vous accompagner dans tous vos déplacements et de vous offrir un service de transport de qualité. Merci de nous faire confiance et bon voyage !
                            </div>
                        </p>
                    </div>
                </div>
            </div>


            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}>Compagnie aérienne</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Compagnie aérienne </h2>

                        <p className="text-gray-500 p-1 text-[22px] ">
                            <div className="reduitTailParagr text-gray-500">
                                <div className="text-gray-500 mt-6]">
                                    Nous sommes ravis de vous accueillir et de vous offrir nos services de voyage exceptionnels. Que vous planifiez un voyage d'affaires ou des vacances bien méritées, notre compagnie aérienne s'engage à vous fournir des vols sécurisés, confortables et ponctuels.


                                    <br /><br />
                                    Explorez notre site pour découvrir nos destinations, les horaires de vol, les tarifs compétitifs et les services supplémentaires que nous proposons. Vous pouvez également effectuer des réservations en ligne en quelques clics seulement.

                                </div>
                                <div className=" w-full p-2   justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading1 && sizeInfer1 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading1(false)
                            }, 1000)
                        }} src="../image/airlines.png" alt="       " className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Reservations des taxi</h2>

                    <div className={` ${imageLoading2 && sizeInfer2 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading2 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading2(false)
                            }, 1000)
                        }} src="../image/TAXI.png" alt="       " className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Reservations des taxi</h2>
                        <p className="text-gray-500 p-1 text-[25px] reduitTailParagr ">

                            Notre service de réservation en ligne vous permet de sélectionner facilement votre lieu de prise en charge, votre destination et l'heure de votre trajet. Nous mettons à votre disposition une flotte de taxis professionnels, conduits par des chauffeurs expérimentés, pour vous assurer un trajet confortable et sécurisé.
                            <br /><br />

                            Réservez dès maintenant votre taxi et profitez d'un transport sûr et pratique pour vos déplacements. Nous sommes impatients de vous accueillir à bord de l'un de nos taxis et de vous offrir un service de qualité. Merci de choisir notre site de réservation de taxi !

                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className="  bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Locations des véhicules</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Locations des véhicules </h2>

                        <p className="text-gray-500 p-1 text-[22px] ">
                            <div className="reduitTailParagr text-gray-500">
                                <div className="text-gray-500 mt-6]">
                                    Vous êtes à la recherche d'un moyen de transport pratique et fiable pour vos déplacements ? Ne cherchez plus ! Notre agence de location de véhicules est là pour répondre à tous vos besoins.


                                    <br />
                                    <br />

                                    Faites confiance à notre service de location de véhicules pour une expérience agréable et sans souci. Réservez dès maintenant et profitez de votre prochaine aventure en toute tranquillité.
                                </div>
                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading3 && sizeInfer3 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading3 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading3(false)
                            }, 1000)
                        }} src="../image/location_des_vehicules.png" alt="       " className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>

        </>
    )
}

export default LoisirVisitorHome



