import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { openChat } from "../../../TawkTo";
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
import { GridLoader } from 'react-spinners'

function NavBarsTopAdmiSeller({ isdivScroll }) {
    const { pathname } = useLocation();
    const AminMarket = /^\/Admin\/Market.*/
    const ManagerOrder = /^\/Admin\/ManageOrderMarket.*/
    const RegisterSeller = /^\/Admin\/RegisterMarket.*/
    const standBy = /^\/Admin\/StandBuy.*/
    const historique = /^\/Admin\/market\/History.*/

    const [activeIndex, setActiveIndex] = useState(null);
    const [menuClicked, setMenuClicked] = useState(false)
    const [BouttonLogin, setBouttonLogin] = useState(false)
    const [isMobile, SetMobile] = useState(window.innerWidth < 345)
    const [tropTail, SettropTail] = useState(window.innerWidth < 414.97364448849393)


    const dropdownItems = [
        { text: 'Compte', link: '/Admin/Profil' },
        { text: 'Se déconnecter', link: '#' }
    ];


    const location = useLocation();
    const [number, setNumber] = useState(0);
    const [loadings, setloading] = useState(true);
    useEffect(() => {
        axios
            .get(" https://btrproject.burundientempsreel.com/market/admin/standbuynumberseller")
            .then((Response) => {
                setNumber(Response.data);
                setTimeout(() => {
                    setloading(false)
                }, 1000);
            })
            .catch((err) => {
                console.error(err.message);
            });
    });

    useEffect(() => {
        const hiddenOnget = () => {
            setMenuClicked(false)
            setBouttonLogin(false)
            SetUserDrop(false)
        };


        function hundleScrolls() {
            setMenuClicked(false)
            setBouttonLogin(false)
            SetUserDrop(false)
        }

        const hiddenresize = () => {
            SetMobile(window.innerWidth < 345)
            SettropTail(window.innerWidth < 414.97364448849393)
        }

        window.addEventListener('click', hiddenOnget);
        window.addEventListener('resize', hiddenresize);

        if (isdivScroll.current) {
            isdivScroll.current.addEventListener('scroll', hundleScrolls);

        }

        return () => {
            window.removeEventListener('click', hiddenOnget);
            window.removeEventListener('resize', hiddenresize);

            if (isdivScroll.current) {
                isdivScroll.current.removeEventListener('scroll', hundleScrolls);
            }
        };
    }, []);



    const history = useNavigate();

    const [UserDrop, SetUserDrop] = useState(false);
    const [AppsDrop, SetAppsDrop] = useState(false);

    function Deconnection(links) {
        if (links === '#') {
            localStorage.removeItem("t");
            localStorage.removeItem("userid");
            localStorage.removeItem("userRole");
            history("/");
            window.location.reload();
        }
    }

    const id = localStorage.getItem("userid");
    const [user, SetUser] = useState({});
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(` https://btrproject.burundientempsreel.com/user/Byid/${id}`)
            .then((response) => {
                SetUser(response.data);

                const timer = setTimeout(() => {
                    setLoading(false);
                }, 100);
                return () => clearTimeout(timer);

            }).catch((err) => {
                console.log(err.message);
            })
    }, []);

    const afficheChart = () => {
        openChat()
    }
    const [imageLoading, SetImageLoading] = useState(true)
    return (
        <>
            {loadings && <SpinnerDemarage />}
            <div className=' h-[20vh] bg-gray-300 sticky top-0 z-50 rounded-b-2xl border-b-2 border-blue-500'>
                <div className="flex  h-full w-full flex-col ">
                    <div className="h-1/2  flex border-b-2" >
                        <div className="flex items-center sm:w-[100%]  w-[70em]  ">
                            <div className="h-[4em] w-[4em] transition-all  relative overflow-hidden ml-3 rounded">
                                {imageLoading && <div className='w-full h-full flex bg-gray-200 justify-center items-center'>{tropTail ? (<GridLoader size='5' color='blue' />) : (<GridLoader size='10' color='blue' />)}</div>}
                                <img draggable='false' onLoad={() => {
                                    setTimeout(() => {
                                        SetImageLoading(false);
                                    }, 1000);
                                }}
                                    src={` https://btrproject.burundientempsreel.com/uploads/logo/btr.png`} alt="" className='h-full w-full object-contain object-center' />
                            </div>
                            <div className="ml-4">Burundi en temp reel</div>
                        </div>
                        <div className=" w-[100%] mr-2 h-full flex justify-end items-center">
                            <nav class=" rounded-t-xl md:rounded-none  flex  justify-between items-center md:items-center pb-1   scale-y-1">

                                <i onClick={afficheChart} class="bi bi-chat-dots-fill max-md:mr-6 cursor-pointer text-[1.5em] font-bold "></i>

                                <button onClick={(e) => { SetUserDrop(!UserDrop); e.stopPropagation() }}
                                    class="  md:w-auto mt-2 md:ml-4 md:flex items-center justify-center text-right"
                                >
                                    <div>
                                        <Link class=""                                        >
                                            <img draggable='false'
                                                className="inline-block h-8 w-8 md:h-10 md:w-10 border border-blue-500 rounded-full"
                                                src={` https://btrproject.burundientempsreel.com/uploads/photosuser/${user.photo}`}
                                                alt=""
                                            />
                                        </Link>
                                    </div>
                                    <div class="hidden md:flex  md:items-center  ml-4">
                                        <span class="text-gray-700 text-sm mr-2">{user.nom + " " + user.prenom}</span>
                                        <div>
                                            <i class="bi bi-caret-down-fill"></i>
                                        </div>
                                    </div>
                                </button>
                            </nav>
                            {UserDrop ? (
                                <div
                                    className="md:block z-[1000] absolute overflow-hidden p-2 right-5 top-16 mt-2 w-48 rounded bg-gray-100 border border-gray-300 shadow-xl"
                                >
                                    {dropdownItems.map((item, index) => (
                                        <Link
                                            key={index}
                                            to={item.link}
                                            className={`block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white w-full text-left ${index === activeIndex ? 'bg-blue-600 text-white' : ''}`}
                                            onClick={() => {
                                                setActiveIndex(index); Deconnection(item.link)
                                            }}
                                        >
                                            {item.text}
                                        </Link>
                                    ))}
                                </div>

                            ) : null}
                        </div>
                    </div>

                    <div className="w-full  h-1/2   flex  flex-col justify-center items-center  text-[12px] ">
                        <div className={`  w-[100%] h-full only:flex justify-around items-end  text-[15px]`}>
                            <div className="  flex px-4 w-[100%] justify-around 2xl:justify-start">
                                {AminMarket.test(pathname) ? (
                                    <Link
                                        to="/Admin/Market"
                                        class="no-underline text-white mr-1 bg-blue-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                                    >
                                        <i class="bi bi-window-split text-[22px] mr-2"></i>
                                        <span class="md:block hidden text-center">
                                            Gérer le vendeur</span>
                                    </Link>
                                ) : (
                                    <Link
                                        to="/Admin/Market"
                                        class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-blue-400  "

                                    >
                                        <i class="bi bi-window-split text-[22px] mr-2"></i>

                                        <span class="md:block hidden text-center">
                                            Gérer le vendeur</span>

                                    </Link>

                                )}

                                <div class="flex">
                                    {RegisterSeller.test(pathname) ? (
                                        <Link
                                            to="/Admin/RegisterMarket"
                                            class="no-underline text-white relative bg-blue-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                                        >
                                            <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-blue-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                99+
                                            </span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="h-6 w-6 fill-current mr-2"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                                                />
                                            </svg>
                                            <span class="md:block hidden text-center">Enregistrer le vendeur</span>
                                        </Link>
                                    ) : (
                                        <Link
                                            to="/Admin/RegisterMarket"
                                            class="no-underline mr-1 relative text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-blue-400  "
                                        >
                                            <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-blue-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                99+
                                            </span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="h-6 w-6 fill-current mr-2"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                                                />
                                            </svg>
                                            <span class="md:block hidden text-center">Enregistrer le vendeur</span>
                                        </Link>
                                    )}
                                </div>



                                <div class="flex ">
                                    {ManagerOrder.test(pathname) ? (
                                        <Link
                                            to="/Admin/ManageOrderMarket"
                                            class="no-underline text-white bg-blue-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="w-6 h-6 mr-2"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z"
                                                />
                                            </svg>

                                            <span class="md:block hidden text-center">Commandes du gestionnaire</span>
                                        </Link>
                                    ) : (
                                        <Link
                                            to="/Admin/ManageOrderMarket"
                                            class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-blue-400  "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="w-6 h-6 mr-2"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z"
                                                />
                                            </svg>

                                            <span class="md:block hidden text-center">Commandes du gestionnaire</span>
                                        </Link>
                                    )}
                                </div>


                                <div class="flex ">
                                    {standBy.test(pathname) ? (
                                        <Link
                                            to="/Admin/StandBuy"
                                            class="no-underline text-white relative  bg-blue-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                                        >
                                            <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-blue-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                {number > 99 ? "99+" : number}
                                            </span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="h-6 w-6 fill-current mr-2"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M9 8.5h2.793l.853.854A.5.5 0 0 0 13 9.5h1a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H9v1z" />
                                                <path d="M12 3H4a4 4 0 0 0-4 4v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7a4 4 0 0 0-4-4zM8 7a3.99 3.99 0 0 0-1.354-3H12a3 3 0 0 1 3 3v6H8V7zm-3.415.157C4.42 7.087 4.218 7 4 7c-.218 0-.42.086-.585.157C3.164 7.264 3 7.334 3 7a1 1 0 0 1 2 0c0 .334-.164.264-.415.157z" />
                                            </svg>
                                            <span class="md:block hidden text-center">StandBuy</span>
                                        </Link>
                                    ) : (
                                        <Link
                                            to="/Admin/StandBuy"
                                            class="no-underline mr-1 text-gray-500 relative rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-blue-400  "
                                        >
                                            <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-blue-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                {number > 99 ? "99+" : number}
                                            </span>

                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="h-6 w-6 fill-current mr-2"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M9 8.5h2.793l.853.854A.5.5 0 0 0 13 9.5h1a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H9v1z" />
                                                <path d="M12 3H4a4 4 0 0 0-4 4v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7a4 4 0 0 0-4-4zM8 7a3.99 3.99 0 0 0-1.354-3H12a3 3 0 0 1 3 3v6H8V7zm-3.415.157C4.42 7.087 4.218 7 4 7c-.218 0-.42.086-.585.157C3.164 7.264 3 7.334 3 7a1 1 0 0 1 2 0c0 .334-.164.264-.415.157z" />
                                            </svg>
                                            <span class="md:block hidden text-center">StandBuy</span>
                                        </Link>
                                    )}
                                </div>
                                <div class="flex ">
                                    {historique.test(pathname) ? (
                                        <Link
                                            to="/Admin/market/History"
                                            class="no-underline text-white bg-blue-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="h-6 w-6 fill-current mr-2"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                                            </svg>
                                            <span class="md:block hidden text-center">Histoire</span>
                                        </Link>
                                    ) : (
                                        <Link
                                            to="/Admin/market/History"
                                            class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-blue-400  "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="h-6 w-6 fill-current mr-2"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                                            </svg>
                                            <span class="md:block hidden text-center">Histoire</span>
                                        </Link>
                                    )}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NavBarsTopAdmiSeller;








