import React from 'react'
import { Link } from 'react-router-dom'
function SchoolLivresVisitorDetail() {

    return (
        <div
            style={{
                backgroundImage: "url('../image/bibli.jpg')",
                backgroundAttachment: "fixed",
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover'
            }}
            className='flex flex-col items-center'
        >

            <div className=" flex w-full sloger justify-around p-2 responsive1">
                <div className="w-[45%] rounded-2xl overflow-hidden  livreadmimg">
                    <img draggable='false' src="../../image/biblio.jpg" alt="" className='w-full h-full block object-cover object-center' />
                </div>
                <div className="bg-gray-200 w-[45%] flex justify-around  font-serif rounded p-2 flex-col livreadm">
                    <div className="text-[30px] font-serif text-center"><span className="mot1">Livres </span> <span className="mot3">des Sciences </span><span className='mot2'> et de technologie!</span></div>
                    <div className="text-[20px] ">Notre bibliothèque en ligne est conçue pour répondre à tous vos besoins
                        de lecture, en vous offrant une expérience immersive et enrichissante. Vous pouvez
                        parcourir notre catalogue de livres des Sciences et de technologie
                        explorer différentes catégories et genres, et découvrir de nouvelles œuvres captivantes</div>
                    <div className="text-[15px] text-gray-400 w-full text-right font-serif">Burundi en temp réel</div>
                </div>

            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50" className='hidden'>
                <ellipse cx="50" cy="0" rx="50" ry="10" fill="currentColor" />
            </svg>
            <div className="flex flex-col w-[99%] items-center mt-10">
                <div className="list bg-slate-100 w-[95%] mt-20 flex flex-col p-4 items-center">
                    <div className="w-full flex justify-end">
                        <div className="pr-10  p-1 m-1 rounded search ">
                            <p className='text-gray-500 font-serif'>Rechercher par votre choix</p>
                            <div className="search py-1 w-full search ">
                                <form className='flex  w-[100%] '>
                                    <input type="text" className='p-1 search border border-blue-700 rounded transition-all outline-none focus:border-2 focus:border-blue-500' id="" placeholder='Rechercher...' />
                                    <label htmlFor='search' className='transition-all bg-blue-400 text-white hover:bg-blue-600 p-2 ml-1 cursor-pointer rounded'><i className='bi bi-search'></i></label>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="text-[22px] mot2 py-2 bg-gray-200 pl-2 w-full">Tous les  catégories</div>
                    <div className="flex flex-wrap justify-around">
                        <div className="flex p-1 m-1 categori categori">
                            <Link to='/Visit/Livres/Detail' className=" w-[100%] flex flex-col bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px]  w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Mathematique</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        3 livres
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Physique</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        45 livres
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer ">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>chimie</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        3 livres
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Biologie</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        35 livres
                                    </div>
                                </div>
                            </Link>
                        </div> <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Sciences de la terre</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        3 livres
                                    </div>
                                </div>
                            </Link>
                        </div> <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Francais </h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        1 livres
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/religiio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Religieux</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        5 livres
                                    </div>
                                </div>
                            </Link>
                        </div>




                    </div>
                    <div className="w-max bg-gray-200 mt-14">
                        <div className="w-full flex justify-around items-center p-2">
                            <button
                                className="transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded"
                            >
                                <a href="#" className="mr-2">
                                    &laquo;
                                </a>
                                <p>Precedant</p>
                            </button>
                            <button className="bg-gray-400 w-[30px] h-[30px] text-[white] bg-blue-600  m-1 rounded-full">1</button>
                            <button className="bg-gray-400 w-[30px] h-[30px] text-[white]  m-1 rounded-full">2</button>
                            <button className="bg-gray-400 w-[30px] h-[30px] text-[white]  m-1 rounded-full">3</button>
                            <button

                                className="transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded"
                            >
                                <p>Suivant</p>
                                <a href="#" className="ml-2">
                                    &raquo;
                                </a>
                            </button>
                        </div>
                    </div>
                    <div className="text-gray-500 mt-3">1-20 sur 35 resultants</div>
                </div>
            </div>
        </div>
    )
}

export default SchoolLivresVisitorDetail
