import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage';

function DetailHistoryAdminMarket() {
    const [datailofcommande, setdatailofcommande] = useState({});
    const [loading, setloading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        axios.get(` https://btrproject.burundientempsreel.com/market/adminorderbyId/${id}`)
            .then((response) => {
                setdatailofcommande(response.data);
                setTimeout(() => {
                    setloading(false)
                }, 1000);
            }).catch((error) => {
                console.log(error.message);
            });
    }, [id]);
    return (

        <>
            {loading && <SpinnerDemarage />}
            <div class="px-8 py-8 bg-white border-t text-gray-700 border-b sm:border-l sm:border-r sm:rounded-xl shadow mb-6">
                <div class="w-full  flex flex-col items-center">
                    <div class="relative mb-4 flex w-full flex-col space-y-3 rounded-2xl  md:max-w-3xl md:flex-row md:space-x-5 md:space-y-0">
                        <div className='w-full text-left '>
                            <h2 class="text-2xl font-semibold  text-blue-500 leading-tight">

                                Informations sur l'acheteur
                            </h2>
                        </div>
                    </div>
                    <div class="relative flex max-w-xs flex-col  items-start space-y-3 rounded-2xl border border-gray-300 p-3 shadow-lg md:max-w-3xl md:space-x-5 md:space-y-0">
                        {datailofcommande && datailofcommande.user && (
                            <div class="flex-grow container pt-6 pb-8 px-2">
                                <div class="">
                                    <div class="">
                                        <div class="">
                                            <div class="md:flex no-wrap md:-mx-2">
                                                {/* <!-- Right Side --> */}
                                                <div class="w-full md:w-11/12 mx-2 ">
                                                    {/* <!-- Profile tab -->
                           <!-- About Section --> */}
                                                    <div class="bg-gray-100 rounded-2xl p-3 shadow-sm rounded-2xl">
                                                        <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                                                            <span class="text-blue-500">
                                                                <svg
                                                                    class="h-7"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                >
                                                                    <path
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-width="2"
                                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                                    ></path>
                                                                </svg>
                                                            </span>
                                                            <h2 class="text-xl text-blue-500 tracking-wide leading-8 my-1">

                                                                Informations sur l'acheteur
                                                            </h2>
                                                        </div>
                                                        <div class="image overflow-hidden">
                                                            <img draggable='false'
                                                                class="h-auto w-24 rounded-full mx-auto"
                                                                src={` https://btrproject.burundientempsreel.com/uploads/photosuser/${datailofcommande.user.photo}`}
                                                                alt="" photo
                                                            />
                                                        </div>
                                                        <div class="text-gray-700">
                                                            <div class="grid text-sm">
                                                                <div class="grid grid-cols-2">
                                                                    <div class="px-4 py-2 font-semibold">
                                                                        Nom
                                                                    </div>
                                                                    <div class="px-4 py-2">{datailofcommande.user.nom}</div>
                                                                </div>
                                                                <div class="grid grid-cols-2">
                                                                    <div class="px-4 py-2 font-semibold">
                                                                        Prenom
                                                                    </div>
                                                                    <div class="px-4 py-2">{datailofcommande.user.prenom}</div>
                                                                </div>
                                                                <div class="grid grid-cols-2">
                                                                    <div class="px-4 py-2 font-semibold">
                                                                        Contact No.
                                                                    </div><div class="px-4 py-2">{datailofcommande.user.tel}</div>
                                                                </div>
                                                                <div class="grid grid-cols-2">
                                                                    <div class="px-4 py-2 font-semibold">
                                                                        Address
                                                                    </div>
                                                                    <div class="px-4 py-2">
                                                                        {datailofcommande.user.address}
                                                                    </div>
                                                                </div>
                                                                <div class="grid grid-cols-2">
                                                                    <div class="px-4 py-2 font-semibold">
                                                                        Email.
                                                                    </div>
                                                                    <div class="px-4 py-2">
                                                                        <a
                                                                            class="text-blue-800"
                                                                            href="mailto:jane@example.com"
                                                                        >
                                                                            {datailofcommande.user.email}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- End of about section --> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {datailofcommande && (
                            <>
                                <div class="flex-grow container pt-6 pb-8 px-2">
                                    <div class="">
                                        <div class="">
                                            <div class="">
                                                <div class="md:flex no-wrap md:-mx-2">
                                                    {/* <!-- Right Side --> */}
                                                    <div class="w-full md:w-11/12 mx-2 ">
                                                        {/* <!-- Profile tab -->
                <!-- About Section --> */}
                                                        <div class="bg-gray-100 rounded-2xl p-3 shadow-sm rounded-2xl">
                                                            <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                                                                <h4 class="text-xl text-blue-500 tracking-wide leading-8 my-1">
                                                                    Adresse de livraison et coordonnées
                                                                </h4>
                                                            </div>
                                                            <div class="text-gray-700">
                                                                <div class="grid text-sm">
                                                                    <div class="grid grid-cols-2">
                                                                        <div class="px-4 py-2 font-semibold">
                                                                            Pays
                                                                        </div>
                                                                        <div class="px-4 py-2">{datailofcommande.pays}</div>
                                                                    </div>
                                                                    <div class="grid grid-cols-2">
                                                                        <div class="px-4 py-2 font-semibold">
                                                                            Province
                                                                        </div>
                                                                        <div class="px-4 py-2">{datailofcommande.province}</div>
                                                                    </div>

                                                                    <div class="grid grid-cols-2">
                                                                        <div class="px-4 py-2 font-semibold">
                                                                            Commune
                                                                        </div>
                                                                        <div class="px-4 py-2">{datailofcommande.commune}</div>
                                                                    </div>
                                                                    <div class="grid grid-cols-2">
                                                                        <div class="px-4 py-2 font-semibold">
                                                                            Zone
                                                                        </div>
                                                                        <div class="px-4 py-2">{datailofcommande.zone}</div>
                                                                    </div>
                                                                    <div class="grid grid-cols-2">
                                                                        <div class="px-4 py-2 font-semibold">
                                                                            Quartier
                                                                        </div>
                                                                        <div class="px-4 py-2">{datailofcommande.quartier}</div>
                                                                    </div>
                                                                    <div class="grid grid-cols-2">
                                                                        <div class="px-4 py-2 font-semibold">
                                                                            Avenue
                                                                        </div>
                                                                        <div class="px-4 py-2">{datailofcommande.avenue}</div>
                                                                    </div>
                                                                    <div class="grid grid-cols-2">
                                                                        <div class="px-4 py-2 font-semibold">
                                                                            House number
                                                                        </div>
                                                                        <div class="px-4 py-2">
                                                                            {datailofcommande.housernamber}
                                                                        </div>
                                                                    </div>
                                                                    <div class="grid grid-cols-2">
                                                                        <div class="px-4 py-2 font-semibold">
                                                                            Mobile
                                                                        </div>
                                                                        <div class="px-4 py-2">
                                                                            {datailofcommande.mobile}
                                                                        </div>
                                                                    </div>
                                                                    <div class="grid grid-cols-2">
                                                                        <div class="px-4 py-2 font-semibold">
                                                                            description
                                                                        </div>
                                                                        <div class="px-4 py-2">

                                                                            {datailofcommande.description}

                                                                        </div>
                                                                    </div>
                                                                    <div class="grid grid-cols-2">
                                                                        <div class="px-4 py-2 font-semibold">
                                                                            Date de commande
                                                                        </div>
                                                                        <div class="px-4 py-2">
                                                                            {new Date(datailofcommande.createdAt).toLocaleDateString('en-US') + ' ' + new Date(datailofcommande.createdAt).toLocaleTimeString('en-US')}
                                                                        </div>
                                                                    </div>
                                                                    <div class="grid grid-cols-2">
                                                                        <div class="px-4 py-2 font-semibold">
                                                                            Montant total payé
                                                                        </div>
                                                                        <div class="px-4 py-2">
                                                                            {datailofcommande.prixtotal} Fbu
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="relative mb-4 mt-8 flex max-w-xs flex-col space-y-3 rounded-2xl border border-gray-300 p-3 shadow-lg md:max-w-3xl md:flex-row md:space-x-5 md:space-y-0">
                                    <p class="font-bold text-lg text-blue-500">Proof Images</p>
                                </div>
                                <div class="grid w-full place-items-center ">
                                    <img draggable='false'
                                        src={` https://btrproject.burundientempsreel.com/uploads/market/${datailofcommande.proofimage}`}
                                        alt="tailwind logo"
                                        class="rounded-xl"
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <div class="relative mb-4 mt-8 flex max-w-xs flex-col space-y-3 rounded-2xl md:max-w-3xl md:flex-row md:space-x-5 md:space-y-0">
                        <div>
                            <h2 class="text-2xl font-semibold  text-blue-500 leading-tight">



                                Informations sur l'acheteur
                            </h2>
                        </div>
                    </div>
                    <div class="Ordered_Product flex flex-col space-y-5">

                        {datailofcommande && datailofcommande.commandemarketcontents && datailofcommande.commandemarketcontents.map((comande => {
                            return (

                                <div class="relative flex max-w-xs flex-col items-start space-y-3 rounded-2xl border border-gray-300 p-3 shadow-lg md:max-w-3xl md:space-x-5 md:space-y-0">
                                    <div class="flex-grow container pt-6 pb-8 px-2">
                                        <div class="">
                                            <div class="">
                                                <div class="">
                                                    <div class="md:flex no-wrap md:-mx-2">
                                                        {/* <!-- Left Side --> */}
                                                        <div class="w-full md:w-4/12 md:mx-2">
                                                            {/* <!-- Profile Card --> */}
                                                            <div class="bg-gray-100 rounded-2xl p-5">
                                                                <div class="image overflow-hidden">
                                                                    <img draggable='false'
                                                                        class="h-auto w-16 rounded-full mx-auto"
                                                                        src={` https://btrproject.burundientempsreel.com/uploads/photosuser/${comande.seller.user.photo}`}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <h1 class="text-blue-500 text-center font-bold text-lg leading-8 my-1">
                                                                    {comande.seller.user.firstname}   {comande.seller.user.lastname}
                                                                </h1>
                                                                <h3 class="text-gray-600 text-center font-lg text-semibold leading-6">
                                                                    Tel:   {comande.seller.user.tel}
                                                                </h3>
                                                                <h3 class="text-gray-600 text-center font-lg text-semibold leading-6">
                                                                    Email:<a mailto={comande.seller.user.email}>   {comande.seller.user.email}</a>
                                                                </h3>

                                                            </div>
                                                            {/* <!-- End of profile card --> */}
                                                            <div class="my-4"></div>
                                                        </div>
                                                        {/* <!-- Right Side --> */}
                                                        <div class="w-full md:w-8/12 mx-2 ">
                                                            {/* <!-- Profile tab -->
                          <!-- About Section --> */}
                                                            <div class=" shadow-sm rounded-2xl">
                                                                <div class="mb-6 justify-between rounded-lg  shadow-md sm:flex sm:justify-start">
                                                                    <img draggable='false'
                                                                        src={` https://btrproject.burundientempsreel.com/uploads/market/${comande.produit.profil}`}
                                                                        alt=" "
                                                                        class="w-full rounded-lg sm:w-40"
                                                                    />
                                                                    <div class="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                                                                        <div class="mt-5 w-48 sm:mt-0">
                                                                            <h2 class="text-lg font-bold text-gray-900">
                                                                                {comande.produit.titre}
                                                                                <p class="text-base text-gray-500">
                                                                                    Q:{comande.quantite}
                                                                                </p>
                                                                            </h2>
                                                                            <p class="mt-1 text-base text-green-500">{comande.produit.categorie}</p>
                                                                            <p class="mt-1 text-base text-gray-700">{comande.produit.description}</p>
                                                                        </div>
                                                                        <div class="mt-4 flex justify-between sm:mt-0 sm:block sm:space-x-6 sm:space-y-6">


                                                                            <div class="flex items-center space-x-4">
                                                                                <div class="font-roboto mb-1 mt-4 flex items-baseline space-x-2">
                                                                                    <p class="text-primary text-xl font-semibold">
                                                                                        {comande.produit.prix}
                                                                                    </p>
                                                                                    <p class="text-base text-gray-400 line-through">
                                                                                        {comande.produit.prix + (comande.produit.prix * 0.2)}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        }))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailHistoryAdminMarket