import React from "react";
import Myorders from "./Myorders";
function Content_Container() {
  return (
    <>
      {/* <!-- container --> */}
      <div class="-mb-40   text-gray-700 bg-gray-200 ">
        <div class=" pt-10 pb-32 items-start">
          <Myorders />
        </div>
      </div>
    </>
  );
}

export default Content_Container;
