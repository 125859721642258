import React from 'react'
import { Link} from 'react-router-dom'
export default function PageNotFind() {

  
  return (
    <div className='w-full h-full bg-gray-600 fixed pt-4 z-50 flex justify-center items-center left-0 top-0'>
      <div className="w-[40em] min-h-[20em] bg-gray-300 rounded Contactresponsive31 flex  flex-col items-center justify-start p-4 ">
        <div className="text-[8em] flex justify-center items-start h-[1.2em] font-serif">404</div>
        <div className=" text-[2em] my-2 w-full text-center">Page introuvable !!</div>
        <Link to='/' className="bg-blue-500 text-[white] rounded px-2 py-1 cursor-pointer">Retourne à l'acceuil</Link>
      </div>
    </div>
  )
}
