import React from "react";
import RegisterForm from "./RegisterForm";
function RegisterInvComponent() {
  return (
    <>
      <div class="flex flex-row justify-center container w-full pt-6 pb-8 px-2">
        <RegisterForm />
      </div>
    </>
  );
}

export default RegisterInvComponent;
