import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select';

const Options = [
    { value: '', label: 'Département' },
    { value: 'Tous Départemen', label: 'Tous les département' },
    { value: 'TIC', label: 'TIC' },
    { value: 'Genie civil', label: 'Genie civil' },
    { value: 'Urbanisme', label: 'Urbanisme' },
    { value: 'Electromecanique', label: 'Electromecanique' }
]

const defaultValue = Options[0];
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        border: '2px solid orange',
        borderRadius: '5px',
        backgroundColor: "transparent",
        width: '12em',
        borderBottom: state.isFocused ? ' ' : '',
        '&:hover': {
            borderBottom: state.isFocused ? '2px solid orange' : '2px solid orange',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'orange' : 'transparent',
        color: state.isSelected ? '#333' : '#666',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
        position: 'static',
        zIndex: '10000',
    }),
};



function EdicationLivreVisitorDetail() {

    const modifiedOptions = Options.slice(1)
    const [SelectValue, SetSelectValue] = useState([defaultValue])

    return (
        <div
            style={{
                backgroundImage: "url('../image/bibli.jpg')",
                backgroundAttachment: "fixed",
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover'
            }}
            className='flex flex-col items-center'
        >

            <div className=" flex w-full sloger justify-around p-2 responsive1">
                <div className="flex justify-around flex-1   font-serif rounded p-2 flex-col livreadm">
                    <div className="text-[30px] font-serif text-center"><span className="mot1">Bienvenue</span> <span className="mot3">bibliothèque </span><span className='mot2'>en ligne de l'édication</span></div>
                    <div className="text-[25px] text-center mot1 mt-10">
                        Faculté des Sciences de l'Ingénieur
                    </div>
                    <div className="text-[15px] text-gray-400 w-full text-right font-serif">Burundi en temp réel</div>
                </div>

            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50" className='hidden'>
                <ellipse cx="50" cy="0" rx="50" ry="10" fill="currentColor" />
            </svg>




            <div className="flex flex-col w-[99%] items-center ">
                <div className="list bg-slate-100 w-[95%]  flex flex-col p-4 items-center">
                    <div className="w-full flex justify-end">
                        <div className="pr-10  p-1 m-1 rounded search ">
                            <p className='text-gray-500 font-serif'>Rechercher par votre choix</p>
                            <div className="search py-1 w-full search ">
                                <form className='flex  w-[100%] '>
                                    <input type="text" className='p-1 search border border-blue-700 rounded transition-all outline-none focus:border-2 focus:border-blue-500' id="" placeholder='Rechercher...' />
                                    <label htmlFor='search' className='transition-all bg-blue-400 text-white hover:bg-blue-600 p-2 ml-1 cursor-pointer rounded'><i className='bi bi-search'></i></label>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="text-[22px] mot2 flex-wrap flex justify-between py-2 bg-gray-200 pl-2 w-full">
                        <p className='mb-5'>Tous les  catégories</p>
                        <div className="text-black ">
                            <Select isClearable={false} onChange={(e) => { SetSelectValue(e.value) }} closeMenuOnSelect={true} defaultValue={SelectValue} isSearchable={false} options={modifiedOptions} styles={customStyles} className='widthTab'>
                            </Select>
                        </div>
                    </div>

                    {/* Universiaire */}
                    <div className="mt-5 font-serif w-full text-left"><div className="bg-gray-400 border-2 border-blue-600 w-max py-1 px-2 rounded-full text-white mt-1">Niveau universitaires</div></div>
                    <div className="flex flex-wrap justify-around">
                        <div className="flex p-1 m-1 categori categori">
                            <Link to='/Visit/Livres/Educ/Detail' className=" w-[100%] flex flex-col bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px]  w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>TIC</h2>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>Algorthme. PartI</h2>

                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 ">
                                        <div className="">
                                            <p className='text-[10px]'>Lundi,12/02/2020</p>
                                            <p className='text-[10px]'>Par Dr Jeremy <span>à Campus KIRIRI</span> </p>
                                        </div>
                                        304 Pages
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori categori">
                            <Link to='/Visit/Livres/Educ/Detail' className=" w-[100%] flex flex-col bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px]  w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>TIC</h2>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>Algorthme. Exemen</h2>

                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 ">
                                        <div className="">
                                            <p className='text-[10px]'>Lundi,12/05/2022</p>
                                            <p className='text-[10px]'>Par Dr Jeremy <span>à Campus KIRIRI</span> </p>
                                        </div>
                                        5 Pages
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori categori">
                            <Link to='/Visit/Livres/Educ/Detail' className=" w-[100%] flex flex-col bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px]  w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>Electromecanique</h2>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>Assemblage. PartI</h2>

                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 ">
                                        <div className="">
                                            <p className='text-[10px]'>Lundi,12/02/2021</p>
                                            <p className='text-[10px]'>Par Dr Lemy <span>à Campus Rohero</span>  </p>
                                        </div>
                                        34 Pages
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori categori">
                            <Link to='/Visit/Livres/Educ/Detail' className=" w-[100%] flex flex-col bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px]  w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>Electromecanique</h2>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>Assemblage.TD</h2>

                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 ">
                                        <div className="">
                                            <p className='text-[10px]'>Lundi,12/02/2021</p>
                                            <p className='text-[10px]'>Par Dr Lemy <span>à Campus Rohero</span>  </p>
                                        </div>
                                        1 Pages
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori categori">
                            <Link to='/Visit/Livres/Educ/Detail' className=" w-[100%] flex flex-col bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px]  w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>Geni Civil</h2>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>Topographie. PartI</h2>

                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 ">
                                        <div className="">
                                            <p className='text-[10px]'>Lundi,12/02/2023</p>
                                            <p className='text-[10px]'>Par Dr Abram </p>
                                        </div>
                                        3 Pages
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="flex p-1 m-1 categori categori">
                            <Link to='/Visit/Livres/Educ/Detail' className=" w-[100%] flex flex-col bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px]  w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>Geni Civil</h2>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>Sol. PartI</h2>

                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 ">
                                        <div className="">
                                            <p className='text-[10px]'>Lundi,12/05/2023</p>
                                            <p className='text-[10px]'>Par Dr Abram <span>à Campus Kariri</span> </p>
                                        </div>
                                        13 Pages
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori categori">
                            <Link to='/Visit/Livres/Educ/Detail' className=" w-[100%] flex flex-col bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px]  w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>Electromecanique</h2>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>Moteur. PartI</h2>

                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 ">
                                        <div className="">
                                            <p className='text-[10px]'>Lundi,2/02/2023</p>
                                            <p className='text-[10px]'>Par Dr Mami <span>à Campus Buhumuza</span> </p>
                                        </div>
                                        3 Pages
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori categori">
                            <Link to='/Visit/Livres/Educ/Detail' className=" w-[100%] flex flex-col bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px]  w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>Urbanisme</h2>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>Route. PartI</h2>

                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 ">
                                        <div className="">
                                            <p className='text-[10px]'>jeudi,12/02/2023</p>
                                            <p className='text-[10px]'>Par Dr Kana <span>à Campus Rohero</span> </p>
                                        </div>
                                        3 Pages
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="w-max bg-gray-200 ">
                        <div className="w-full flex justify-around items-center p-2">
                            <button
                                className="transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded"
                            >
                                <a href="#" className="mr-2">
                                    &laquo;
                                </a>
                                <p>Precedant</p>
                            </button>
                            <button className=" w-[30px] h-[30px] text-[white] bg-blue-600  m-1 rounded-full">1</button>
                            <button className="bg-gray-400 w-[30px] h-[30px] text-[white]  m-1 rounded-full">2</button>
                            <button className="bg-gray-400 w-[30px] h-[30px] text-[white]  m-1 rounded-full">3</button>
                            <button

                                className="transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded"
                            >
                                <p>Suivant</p>
                                <a href="#" className="ml-2">
                                    &raquo;
                                </a>
                            </button>
                        </div>
                    </div>
                    <div className="text-gray-500 mt-3">1-20 sur 35 resultants</div>
                </div>
            </div>
        </div>
    )
}

export default EdicationLivreVisitorDetail
