import React from "react";
import OrderDetail from "./OrderDetail";
function Content_Container() {
  return (
    <>
      {/* <!-- container --> */}
      <div class="text-gray-700  ">
        <div class="  items-start">
          <OrderDetail />
        </div>
      </div>
    </>
  );
}

export default Content_Container;
