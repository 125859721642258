import React from 'react'
import StandByAdminSuperEchange from './StandByAdminSuperEchange'
function StandByAdminSuperEchangeComponent() {
    return (
        <>
            <StandByAdminSuperEchange />
        </>
    )
}

export default StandByAdminSuperEchangeComponent

