import React, { useEffect, useRef, useState } from "react";
import Select from 'react-select';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

const Options = [
  { value: '', label: 'Catégorie du produit' },
  { value: 'Appareils électronique', label: 'Appareils électronique' },
  { value: 'Appreils de construction', label: 'Appreils de construction' },
  { value: 'Appreils maisons et cuisines', label: 'Appreils maisons et cuisines' },
  { value: 'Magazin', label: 'Magazin' },
  { value: 'Vehicule', label: 'Vehicule' },
  { value: 'Immeubles', label: 'Immeubles' },
  { value: 'Industriels', label: 'Industriels' },
  { value: 'Arts et Artisanat', label: 'Arts et Artisanat' },
  { value: 'Jouets', label: 'Jouets' }
]


const defaultValue = Options[0];
const optionsWithoutFirst = Options.slice(1);


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderBottom: '2px solid gray',
    borderRadius: '0px',
    backgroundColor: "transparent",
    top: '7px',
    with: '100%',
    boxShadow: state.isFocused ? '0 0 0 2px #ddd' : 'none',
    borderBottom: state.isFocused ? '2px solid blue' : '2px solid gray',
    '&:hover': {
      borderBottom: state.isFocused ? '2px solid blue' : '2px solid orange',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'blue' : 'transparent',
    backgroundColor: state.isSelected ? 'blue' : 'transparent',
    color: state.isSelected ? 'white' : '#666',
    '&:hover': {
      color: state.isSelected ? 'white' : '#666',
      backgroundColor: state.isSelected ? 'blue' : 'transparent',
    },
    position: 'static',
    zIndex: '10000',
  }),
}


function RegisterForm() {
  const history = useNavigate();
  const useId = localStorage.getItem("userid");

  const [titre, Settitre] = useState('')
  const [animationClasstitre, setAnimationClasstitre] = useState('');
  const elemetReftitre = useRef(null)

  const [prix, Setprix] = useState()
  const [animationClassprix, setAnimationClassprix] = useState('');
  const elemetRefprix = useRef(null)

  const [quantit, Setquantit] = useState()
  const [animationClassquantit, setAnimationClassquantit] = useState('');
  const elemetRefquantit = useRef(null)


  const [isTextarea, setIsTextarea] = useState('');
  const [animationClassTextarea, setAnimationClassTextarea] = useState('');
  const textaresRef = useRef(null)

  const [autoScrollHeight, setAutoScrollHeight] = useState(50);
  const [valueTextarea, setValueTextarea] = useState('');

  const [SelectValue, SetSelectValue] = useState([defaultValue])
  const [animationClassSelecteValue, setanimationClassSelecteValue] = useState('');
  const elemetRefSelect = useRef(null)

  const [profil, SetProfil] = useState("")
  const [photo, SetPhoto] = useState(null)


  const handleSubmit = (e) => {
    e.preventDefault()
    if (titre.trim() === '') {
      toast.warning('Le titre de produit est  obligatoire', {
        autoClose: 2000
      });
      setAnimationClasstitre('animate__animated animate__shakeX placeholder-shown:border-red-500')
      setTimeout(() => {
        setAnimationClasstitre(' ')
      }, 3000)
      elemetReftitre.current.focus()
      e.preventDefault()
      return false
    } else if (prix.trim() === '') {
      toast.warning('Entre le prix du produit', {
        autoClose: 2000
      });
      setAnimationClassprix('animate__animated animate__shakeX placeholder-shown:border-red-500')
      setTimeout(() => {
        setAnimationClassprix(' ')
      }, 3000)
      elemetRefprix.current.focus()
      e.preventDefault()
      return false
    }
    else if (quantit.trim() == "") {
      toast.warning("Entre la qunatité du produits!!", {
        autoClose: 2000
      });
      setAnimationClassquantit('animate__animated animate__shakeX placeholder-shown:border-blue-700 text-blue-500 border-blue-700')
      setTimeout(() => {
        setAnimationClassquantit(' ')
      }, 3000)
      elemetRefquantit.current.focus()
      e.preventDefault()
      return false
    }
    else if (SelectValue.value == undefined) {
      toast.warning('Sélectionnez la catégorie de produit', {
        autoClose: 2000
      });
      setanimationClassSelecteValue('animate__animated animate__shakeX border-b-2 border-red-500')
      setTimeout(() => {
        setanimationClassSelecteValue(' ')
      }, 3000)
      return;
    } else if (isTextarea.trim() === '') {
      toast.warning('La description de vos produits', {
        autoClose: 2000
      });
      setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500');
      setTimeout(() => {
        setAnimationClassTextarea('');
      }, 3000);
      textaresRef.current.focus()
      return;
    } else if (profil == '') {
      toast.warning('Le photos de produits est obligatoire !!', {
        autoClose: 2000
      })
    } else {
      const formData = new FormData();
      formData.append("Title", titre);
      formData.append("Quantity", quantit);
      formData.append("Price", prix);
      formData.append("Category", SelectValue.value);
      formData.append("Description", isTextarea);
      formData.append("SellerId", useId);
      formData.append("File", photo);

      axios
        .post(' https://btrproject.burundientempsreel.com/market/seller/addproduct', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        })
        .then((response) => {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          })
          Settitre('')
          Setprix()
          Setquantit()
          SetSelectValue('')
          setIsTextarea('')
          SetProfil('')
          history('/');
        }).catch((error) => {
          console.error(error.message);
          if (error.response) {
            toast.error(error.response.data.error);
          }
          console.error(error.response.data);

        });

    }
  }


  return (
    <>
      <div class="rounded-2xl flex flex-col items-center border h-max  border-blue-500 w-[500px] p-2 shadow-sm">
        <div
          class="flex items-center space-x-1 font-semibold  text-gray-900"
        >
          <h2 class="my-1 text-lg leading-8 tracking-wide text-blue-500">
            Ajouter un nouveau produit
          </h2>
        </div>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className=" transition-all flex w-[100%] justify-between flexFormul conctat">
            <div class="relative h-11 w-full mb-6  flexFormulInput ">
              <input ref={elemetReftitre} value={titre} onInput={(e) => Settitre(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasstitre}`} placeholder=" " />
              <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Tritre du produits
              </label>
            </div>
          </div>

          <div className=" mt-[10px] transition-all flex w-full justify-between  flexFormul">
            <div class="relative h-11 w-1/2 flexFormulInput  mr-2 ">
              <input value={prix} type="number" ref={elemetRefprix} onInput={(e) => Setprix(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassprix}`} placeholder=" " />
              <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Prix du produit
              </label>
            </div>
            <div class="relative h-11 flexFormulInput w-1/2">
              <input value={quantit} type="number" ref={elemetRefquantit}
                onInput={(e) => Setquantit(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassquantit}`} placeholder=" "
              />
              <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Le quantite du produit
              </label>
            </div>
          </div>
          <div class="relative h-11  w-full   mt-[20px] ">
            <div className={`flex flex-col  h-11 w-full relative responsive31_temoignSelect ${animationClassSelecteValue}`}>
              <Select ref={elemetRefSelect} isClearable={false} onChange={SetSelectValue} defaultValue={SelectValue} options={optionsWithoutFirst} styles={customStyles} >
              </Select>
            </div>
          </div>

          <div className="relative h-full w-full mt-[2em]">
            <textarea ref={textaresRef}
              value={isTextarea}
              onChange={(e) => {
                setIsTextarea(e.target.value);
                setAutoScrollHeight(e.target.scrollHeight);
                setValueTextarea(e.target.value.trim().length);
                if (!valueTextarea) {
                  setAutoScrollHeight(50);
                }
              }}
              placeholder=" "
              style={{ height: `${autoScrollHeight}px` }}
              className={`peer personalTextarea leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassTextarea}`}
            />
            <label
              className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-blue-gray-500`}
            >
              Déscription du produit
            </label>
          </div>
          <div className="mt-10">
            <div className="text-gray-500">
              <label htmlFor="profiles" className='cursor-pointer bg-blue-500 px-1 py-2  ml-4 rounded text-white'>Photos de produits</label>
            </div>
            <input type="file" accept='image/*' hidden id='profiles' onChange={(e) => { const UrlFile = e.target.files[0]; SetPhoto(UrlFile); SetProfil(URL.createObjectURL(UrlFile)) }} />
            <div className={`${profil == "" ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'} w-[50%] h-[50%] bg-gray-200 overflow-hidden mt-[20px] rounded border-4 border-double border-blue-600`}>
              <img draggable='false' src={profil} alt="" className={`w-full h-full object-cover`} />
            </div>
          </div>


          <div className="flex justify-end items-center">
            <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-blue-600   p-2 rounded  text-white">
              <input type="submit" id="send" value='Ajouter' className='cursor-pointer '></input>
              <i class="bi bi-send ml-2 "></i>
            </label>

          </div>







        </form>


        <div
          class="container-mr mt-4 w-full flex items-center justify-end bg-gray-100 p-8 py-4 text-sm"
        >

        </div>

      </div>
    </>
  );
}

export default RegisterForm;
