import React from 'react'
import DetaillDemmandeHistoryEchangeAdmin from './DetaillDemmandeHistoryEchangeAdmin'
function DetaillDemmandeHistoryEchangeAdminComponent() {
  return (
    <>
      <DetaillDemmandeHistoryEchangeAdmin />
    </>
  )
}

export default DetaillDemmandeHistoryEchangeAdminComponent
