import React from 'react'
import HomeVisitorComponent from '../../component/Visitor/Home/HomeVisitorComponent'

function HomeVisitorPage({ isdivScroll }) {
  
  return (
    <HomeVisitorComponent isdivScroll={isdivScroll} />
  )
}

export default HomeVisitorPage
