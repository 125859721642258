/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import axios from "axios";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { FadeLoader } from 'react-spinners'
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
function CompteAdminEchange({ isdivScroll }) {

  const [spinnerButton, SetSpinnerButton] = useState(false)
  const [spinnerButton1, SetSpinnerButton1] = useState(false)
  const [imageLoading, SetImageLoading] = useState(true)
  const [imageLoading1, SetImageLoading1] = useState(true)
  const [loading, SetLoading] = useState(true)

  const [nom, Setnom] = useState("");
  const [animationClassNom, setAnimationClassNom] = useState("");
  const elemetRefNom = useRef(null);

  const [prenom, Setprenom] = useState("");
  const [animationClassPrenom, setAnimationClassPrenom] = useState("");
  const elemetRefPrenom = useRef(null);

  const [tel, SetTel] = useState("");
  const [animationClassTel, setAnimationClassTel] = useState("");
  const elemetRefTel = useRef(null);

  const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
  const [emailValue, setEmailValue] = useState("");
  const [animationClassEmail, setAnimationClassEmail] = useState("");
  const elemetRefEmail = useRef(null);

  const [nomSociete, SetnomSociete] = useState("");
  const [animationClassnomSociete, setanimationClassnomSociete] = useState("");
  const elemetRefnomSociete = useRef(null);

  const [codeSuperAgent, SetSuperAgent] = useState("");
  const [animationClassSuperAgent, setanimationClassSuperAgent] = useState("");
  const elemetRefSuperAgent = useRef(null);

  const [codeNIF, SetcodeNIF] = useState("");
  const [animationClasscodeNIF, setanimationClassNIF] = useState("");
  const elemetRefcodeNIF = useRef(null);

  const [codeIndentite, SetcodeIndentite] = useState("");
  const [animationClasscodeIndentite, setanimationClassIdentite] = useState("");
  const elemetRefcodeIndentite = useRef(null);

  const [codeAddress, SetcodeAddress] = useState("");
  const [animationClasscodeAddress, setanimationClasscodeAddress] =
    useState("");
  const elemetRefcodeAddress = useRef(null);

  //pour le mot de passe
  const [animationClassPassword, setAnimationClassPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const elemetRefPass = useRef(null);

  const [animationClassConfPass, setAnimationClassConfPass] = useState("");
  const [ConfpasswordVisible, setConfPasswordVisible] = useState(false);
  const elemetRefConfPass = useRef(null);

  const [profil, SetProfil] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [modifierBtn, SetmodifierBtn] = useState(false);
  const [EditorPass, SetEditorPass] = useState(false);

  const { pathname } = useLocation();
  const [click, setclick] = useState(false);
  const [clickSlideHidden, setclickSlideHidden] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (nom.trim() === "") {
      toast.warning("Le nom est obligatoire", {
        autoClose: 2000,
      });
      setAnimationClassNom(
        "animate__animated animate__shakeX placeholder-shown:border-red-500"
      );
      setTimeout(() => {
        setAnimationClassNom(" ");
      }, 3000);
      elemetRefNom.current.focus();
      return;
    } else if (prenom.trim() === "") {
      toast.warning("Le  prenom est obligatoire", {
        autoClose: 2000,
      });
      setAnimationClassPrenom(
        "animate__animated animate__shakeX placeholder-shown:border-red-500"
      );
      setTimeout(() => {
        setAnimationClassPrenom(" ");
      }, 3000);
      elemetRefPrenom.current.focus();
      return;
    } else if (tel.trim() == "") {
      toast.warning("Le numero de téléphone  est obligatoire !!", {
        autoClose: 2000,
      });
      setAnimationClassTel(
        "animate__animated animate__shakeX placeholder-shown:border-blue-700 text-blue-500 border-blue-700"
      );
      setTimeout(() => {
        setAnimationClassTel(" ");
      }, 3000);
      elemetRefTel.current.focus();
      return;
    } else if (emailValue.trim() == "") {
      toast.warning("L'address email est obligatoire !!", {
        autoClose: 2000,
      });
      setAnimationClassEmail(
        "animate__animated animate__shakeX placeholder-shown:border-blue-500 text-blue-500 border-blue-700"
      );
      setTimeout(() => {
        setAnimationClassEmail(" ");
      }, 3000);
      elemetRefEmail.current.focus();
      return;
    } else if (!emailValue.trim().match(emailRegex)) {
      toast.error("L'address email est incorrect", {
        autoClose: 2000,
      });
      setAnimationClassEmail(
        "animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500"
      );
      setTimeout(() => {
        setAnimationClassEmail(" ");
      }, 3000);
      elemetRefEmail.current.focus();
      return;
    } else if (nomSociete.trim() == "") {
      toast.warning("Le nom de la société est obligatoire", {
        autoClose: 2000,
      });
      setanimationClassnomSociete(
        "animate__animated animate__shakeX border-red-500"
      );
      setTimeout(() => {
        setanimationClassnomSociete(" ");
      }, 3000);
      elemetRefnomSociete.current.focus();
      return;
    } else if (codeSuperAgent.trim() == "") {
      toast.warning("Le code de super-agent est obligatoire", {
        autoClose: 2000,
      });
      setanimationClassSuperAgent(
        "animate__animated animate__shakeX border-red-500"
      );
      setTimeout(() => {
        setanimationClassSuperAgent(" ");
      }, 3000);
      elemetRefSuperAgent.current.focus();
      return;
    } else if (codeSuperAgent.trim() == "") {
      toast.warning("Le code de super-agent est obligatoire", {
        autoClose: 2000,
      });
      setanimationClassSuperAgent(
        "animate__animated animate__shakeX border-red-500"
      );
      setTimeout(() => {
        setanimationClassSuperAgent(" ");
      }, 3000);
      elemetRefSuperAgent.current.focus();
      return;
    } else if (codeNIF.trim() == "") {
      toast.warning("Le NIF est obligatoire", {
        autoClose: 2000,
      });
      setanimationClassNIF("animate__animated animate__shakeX border-red-500");
      setTimeout(() => {
        setanimationClassNIF(" ");
      }, 3000);
      elemetRefcodeNIF.current.focus();
      return;
    } else if (codeIndentite.trim() == "") {
      toast.warning("Le numéro d'identité est obligatoire", {
        autoClose: 2000,
      });
      setanimationClassIdentite(
        "animate__animated animate__shakeX border-red-500"
      );
      setTimeout(() => {
        setanimationClassIdentite(" ");
      }, 3000);
      elemetRefcodeIndentite.current.focus();
      return;
    } else if (codeAddress.trim() == "") {
      toast.warning("Le code Address est obligatoire", {
        autoClose: 2000,
      });
      setanimationClasscodeAddress(
        "animate__animated animate__shakeX border-red-500"
      );
      setTimeout(() => {
        setanimationClasscodeAddress(" ");
      }, 3000);
      elemetRefcodeAddress.current.focus();
      return;
    } else if (profil == "") {
      toast.warning(`Le photos de profil est nécessaire`, {
        autoClose: 5000,
        position: "top-center",
        className: "my-toast",
      });
      return;
    } else {
      SetSpinnerButton(true)
      SetSpinnerButton1(true)
      const formData = new FormData();
      formData.append("nom", nom);
      formData.append("prenom", prenom);
      formData.append("email", emailValue);
      formData.append("tel", tel);
      formData.append("NIF", codeNIF);
      formData.append("address", codeAddress);
      formData.append("CNI", codeIndentite);
      formData.append("codeSuperAgent", codeSuperAgent);
      formData.append("nomSociete", nomSociete);
      if (photo !== null) {
        formData.append("photo", photo);
      }


      axios.put(` https://btrproject.burundientempsreel.com/surperagent/modifyaccount/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        toast.success("Les identifiants ont été modifiés avec succès !");
        setPhoto(null);
        const executeAfter5Seconds = () => {
          window.location.reload();
        };
        setTimeout(executeAfter5Seconds, 5000);
      }).catch((error) => {
        console.log(error.message);
        if (error.response) {
          console.error(error.response.data);
        }
      }).finally(() => {
        SetSpinnerButton(false)
        SetSpinnerButton1(false)
      });
    }
  };
  const [data, setData] = useState({});
  const id = localStorage.getItem("idSuperagent");
  useEffect(() => {
    axios
      .get(` https://btrproject.burundientempsreel.com/surperagent/buyId/${id}`)
      .then((response) => {
        setData(response.data);
        Setnom(response.data.nom);
        Setprenom(response.data.prenom);
        SetTel(response.data.tel);
        SetnomSociete(response.data.nomSociete);
        SetSuperAgent(response.data.codeSuperAgent);
        SetcodeNIF(response.data.NIF);
        SetcodeIndentite(response.data.CNI);
        SetcodeAddress(response.data.address);
        setEmailValue(response.data.email);
        setTimeout(() => {
          SetLoading(false)
        }, 1000);
      })
      .catch((error) => {
        console.error(error.message);
        if (error.response) {
          console.error(error.response.data);
        }
      });
  }, [id]);

  const [password, setPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confumePassword, setConfirmPassword] = useState("");
  function validatePassword(password) {
    const PasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;

    return PasswordRegex.test(password);
  }
  const handleChangePassword = async () => {
    if (!validatePassword(password) || !validatePassword(newpassword)) {
      toast.warning(
        "Les mots de passe sont invalides. Assurez-vous qu'ils contiennent  une majuscule, une minuscule, un caractère spécial et un chiffre",
        {
          duration: 7000, // Durée de 7 secondes en millisecondes
        }
      );
      return;
    }
    if (newpassword !== confumePassword) {
      toast.warning(
        "Le nouveau mot de passe et la confirmation ne correspondent pas."
      );
      return;
    }
    try {
      const response = await axios.put(
        ` https://btrproject.burundientempsreel.com/surperagent/changepass`,
        {
          password: password,
          newPassword: newpassword,
          id: id,
        }
      );

      if (response.data.success) {
        toast.success("Le mot de passe a été modifié avec succès !");
        setPassword("");
        setNewPassword("");
        setConfirmPassword("");
        const executeAfter5Seconds = () => {
          window.location.reload();
        };
        setTimeout(executeAfter5Seconds, 5000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error.message);
      if (error.response) {
        toast.error(error.response.data);
      }


    } finally {
      SetSpinnerButton(false)
      SetSpinnerButton1(false)
    }
  };

  const ModiPassword = (e) => {
    SetEditorPass(!EditorPass);
    setTimeout(() => {
      if (isdivScroll.current) {
        isdivScroll.current.scrollTop = isdivScroll.current.scrollHeight;
      }
    }, 0);
    e.stopPropagation()
  };

  useEffect(() => {
    setTimeout(() => {
      isdivScroll.current.style.scrollBehavior = 'smooth';
    }, 0);
  }, []);


  const [mobile, SetMobile] = useState(window.innerWidth < 506)
  const [mobile2, SetMobile2] = useState(window.innerWidth < 613)
  const [mobile1, SetMobile1] = useState(window.innerWidth < 388)

  useEffect(() => {

    const hundleSize = () => {
      SetMobile(window.innerWidth < 506)
      SetMobile1(window.innerWidth < 388)
      SetMobile2(window.innerWidth < 613)
    }

    window.addEventListener('resize', hundleSize)

    return () => {
      window.removeEventListener('resize', hundleSize)
    }
  }, [])



  return (
    data && (
      <>
        {loading && <SpinnerDemarage />}
        <div className="flex justify-center min-h-[100vh] bg-white items-center p-2">
          <div className={` border border-blue-700 rounded-2xl p-2 shadow-2xl ${mobile2 ? 'w-[100%]' : ''} `}>
            <div
              className={`${modifierBtn ? "hidden" : "flex"
                }  flex-col items-start m-2`}
            >
              <div className="w-full flex justify-center items-center">
                <div className="w-[9em] h-[9em] border   m-2 rounded-full relative overflow-hidden">
                  {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                  <img draggable='false' onLoad={() => {
                    setTimeout(() => {
                      SetImageLoading(false)
                    }, 1000)
                  }}
                    src={` https://btrproject.burundientempsreel.com/uploads/photosuperagent/${data.photo}`}
                    alt=""
                    className="w-full h-full object-cover object-center "
                  />
                </div>
              </div>
              <div className={`text-gray-500 transition-all ${mobile ? 'leading-0' : 'leading-10'} `}>
                <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5' : ''} `}>

                  <div className="font-bold mr-1 flex  w-[13em] justify-between">
                    Nom<span>:</span>
                  </div>
                  <div>{nom}</div>
                </div>
                <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5' : ''} `}>

                  <div className="font-bold mr-1 flex  w-[13em] justify-between">
                    Prénom <span>:</span>
                  </div>
                  <div>{prenom}</div>
                </div>
                <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5' : ''} `}>


                  <div className="font-bold mr-1 flex  w-[13em] justify-between">
                    Telephone <span>:</span>
                  </div>
                  <div>{tel}</div>
                </div>
                <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5 flex-col' : ''} `}>


                  <div className="font-bold mr-1 flex  w-[13em] justify-between">
                    Email valide <span>:</span>
                  </div>
                  <div>{emailValue}</div>
                </div>
                <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5 flex-col' : ''} `}>


                  <div className="font-bold mr-1 flex  w-[13em] justify-between">
                    Nom de la société <span>:</span>
                  </div>
                  <div>{nomSociete}</div>
                </div>
                <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5' : ''} `}>


                  <div className="font-bold mr-1 flex  w-[13em] justify-between">
                    Code de super agent <span>:</span>
                  </div>
                  <div>{codeSuperAgent}</div>
                </div>
                <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5' : ''} `}>


                  <div className="font-bold mr-1 flex  w-[13em] justify-between">
                    NIF <span>:</span>
                  </div>
                  <div>{codeNIF}</div>
                </div>
                <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5 flex-col' : ''} `}>


                  <div className="font-bold mr-1 flex  w-[13em] justify-between">
                    Identite <span>:</span>
                  </div>
                  <div>{codeIndentite}</div>
                </div>
                <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5 flex-col' : ''} `}>


                  <div className="font-bold mr-1 flex  w-[13em] justify-between">
                    Addresse <span>:</span>
                  </div>
                  <div>{codeAddress}</div>
                </div>
                <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5' : ''} `}>


                  <div className="font-bold mr-1 flex  w-[13em] justify-between">
                    Mot de passe <span>:</span>
                  </div>
                  <div className="flex">
                    ***********
                    <div
                      title="Modifier le password "
                      onClick={ModiPassword}
                      className="bi cursor-pointer ml-4 bi-pencil-square"
                    ></div>
                  </div>
                </div>
              </div>
              <div className="w-full  flex justify-end">
                <div
                  onClick={() => {
                    SetmodifierBtn(true);
                  }}
                  className={` ${!modifierBtn ? "block" : "hidden"
                    }  px-2 py-1 bg-blue-400 text-white rounded cursor-pointer hover:bg-blue-600 transition-all`}
                >
                  Modifier
                </div>
              </div>
            </div>






            {/* autre champs */}
            <div className={`${modifierBtn ? "" : "hidden"}`}>
              <Link onClick={() => { SetmodifierBtn(false); }} className="bg-blue-700 px-2 rounded-full py-1">
                <i class="bi bi-arrow-left-circle text-white"></i>
              </Link>
              <h2 className=" p-2 text-center mb-[1em] text-[15px] font-serif text-gray-500 cursor-default">
                Modifier les informations
              </h2>
              <div className="bg-transparent  m-3 p-4 rounded w-[35em] addClient h-max inscriptvisitions">
                <form onSubmit={handleSubmit}>
                  <div className="flex mb-6 flex-col">
                    <div className=" transition-all flex w-[100%] justify-between flexFormul conctat">
                      <div class="relative h-11 w-1/2 flexFormulInput mr-2 ">
                        <input
                          ref={elemetRefNom}
                          value={nom}
                          onInput={(e) => Setnom(e.target.value)}
                          className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNom}`}
                          placeholder=" "
                        />
                        <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Nom
                        </label>
                      </div>

                      <div class="relative h-11 flexFormulInput w-1/2  mr-2 ">
                        <input
                          value={prenom}
                          ref={elemetRefPrenom}
                          onInput={(e) => Setprenom(e.target.value)}
                          className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPrenom}`}
                          placeholder=" "
                        />
                        <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Prenom
                        </label>
                      </div>
                    </div>
                    <div className=" mt-[10px] transition-all flex w-[100%] justify-between  flexFormul">
                      <div class="relative h-11 w-1/2 flexFormulInput  mr-2 ">
                        <input
                          value={tel}
                          ref={elemetRefTel}
                          onInput={(e) => SetTel(e.target.value)}
                          className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassTel}`}
                          placeholder=" "
                        />
                        <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Téléphone
                        </label>
                      </div>
                      <div class="relative h-11 flexFormulInput w-1/2">
                        <input
                          value={emailValue}
                          ref={elemetRefEmail}
                          onInput={(e) => setEmailValue(e.target.value)}
                          className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`}
                          placeholder=" "
                        />
                        <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Email valide
                        </label>
                      </div>
                    </div>
                    <div className=" mt-[10px] transition-all flex w-[100%] justify-between flexFormul">
                      <div class="relative h-11 flexFormulInput w-1/2  mr-2">
                        <input
                          value={nomSociete}
                          ref={elemetRefnomSociete}
                          onInput={(e) => SetnomSociete(e.target.value)}
                          className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassnomSociete}`}
                          placeholder=" "
                        />
                        <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Nom de la société
                        </label>
                      </div>

                      <div class="relative h-11 flexFormulInput w-1/2">
                        <input
                          value={codeSuperAgent}
                          ref={elemetRefSuperAgent}
                          onInput={(e) => SetSuperAgent(e.target.value)}
                          className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassSuperAgent}`}
                          placeholder=" "
                        />
                        <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Code de super-agent
                        </label>
                      </div>
                    </div>

                    <div className=" mt-[10px]  transition-all flex w-[100%] justify-between  flexFormul">
                      <div class="relative h-11 flexFormulInput w-1/2 mr-2">
                        <input
                          value={codeNIF}
                          ref={elemetRefcodeNIF}
                          onInput={(e) => SetcodeNIF(e.target.value)}
                          className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasscodeNIF}`}
                          placeholder=" "
                        />
                        <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          NIF
                        </label>
                      </div>

                      <div class="relative h-11 flexFormulInput w-1/2">
                        <input
                          value={codeIndentite}
                          ref={elemetRefcodeIndentite}
                          onInput={(e) => SetcodeIndentite(e.target.value)}
                          className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasscodeIndentite}`}
                          placeholder=" "
                        />
                        <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Identité
                        </label>
                      </div>
                    </div>
                    <div class="relative h-11 mt-[10px]  w-full contactInput  mr-2 ">
                      <div class="relative h-11 flexFormulInput w-full">
                        <input
                          value={codeAddress}
                          ref={elemetRefcodeAddress}
                          onInput={(e) => SetcodeAddress(e.target.value)}
                          className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasscodeAddress}`}
                          placeholder=" "
                        />
                        <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Addresse
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="ro">
                    <label htmlFor="profiles" className="la">
                      <span>Photos de profil</span>
                      <label
                        htmlFor="profiles"
                        className="bi bi-image-fill cursor-pointer bg-blue-500 px-1 ml-4 rounded text-white"
                      ></label>
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      id="profiles"
                      onChange={(e) => {
                        const UrlFile = e.target.files[0];
                        SetProfil(URL.createObjectURL(UrlFile));
                        setPhoto(UrlFile);
                      }}
                    />
                    <div
                      className={`${profil == ""
                        ? "opacity-0 pointer-events-none"
                        : "opacity-100 pointer-events-auto"
                        } w-[70%] h-[70%]  relative overflow-hidden bg-white mt-[20px] rounded-2xl border  border-blue-600`}
                    >
                      {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                      <img draggable='false' onLoad={() => {
                        setTimeout(() => {
                          SetImageLoading1(false)
                        }, 1000)
                      }}
                        src={
                          profil
                            ? profil
                            : ` https://btrproject.burundientempsreel.com/uploads/photosuperagent/${data.photo}`
                        }
                        className={`w-full h-full object-cover`}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end items-center mt-4">




                    {spinnerButton ? (
                      <>
                        <div className="flex justify-end items-center  relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                          <div className='absolute bg-transparent   pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                            <FadeLoader
                              color="rgb(255, 255, 255)"
                              height={10}
                              margin={-9}
                              radius={100}
                              speedMultiplier={1}
                              width={1}
                            /></div>

                          <input type="submit" id="send" value="Modifier" class=" transition-all "></input>
                          <i class="bi bi-send ml-2 "></i>
                        </div>
                      </>
                    ) : (
                      <>

                        <label
                          for="send"
                          className=" cursor-pointer w-max  flex justify-end  bg-blue-600   p-2 rounded  text-white"
                        >
                          <input
                            type="submit"
                            id="send"
                            value="Modifier"
                            className="cursor-pointer "
                          ></input>
                          <i class="bi bi-send ml-2 "></i>
                        </label>  </>
                    )}



                  </div>
                </form>
              </div>
            </div>

            {/* mot de pass */}
            <div
              className={`${EditorPass ? "block" : "hidden"}  ${modifierBtn ? "hidden" : ""
                }`}
            >
              <div className=" transition-all mt-10 flex w-[100%] flex-col">
                <h2 className="font-serif text-gray-400 text-[10px] cursor-default mb-5">
                  Modifier le password
                </h2>
                <div className={`relative  w-[100%] p-1 `}>
                  <div className={`relative mt-3 `}>
                    <div class="relative h-11 w-full bloksInput">
                      <input
                        ref={elemetRefPass}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        type={passwordVisible ? "text" : "password"}
                        className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassword}`}
                        placeholder=" "
                      />
                      <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Ancien mot de passe
                      </label>
                    </div>
                    <div
                      onClick={() => {
                        setPasswordVisible(!passwordVisible);
                      }}
                      className=" cursor-pointer w-[20px] h-[20px] absolute flex justify-center top-[20px] items-center right-2 "
                    >
                      <i
                        class={`bi ${passwordVisible ? "bi-eye-fill" : "bi-eye-slash-fill"
                          } absolute`}
                      ></i>
                    </div>
                  </div>

                  <div className={`relative mt-3 `}>
                    <div class="relative h-11 w-full bloksInput">
                      <input
                        ref={elemetRefPass}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                        }}
                        type={passwordVisible ? "text" : "password"}
                        className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassword}`}
                        placeholder=" "
                      />
                      <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Nouveau mot de passe
                      </label>
                    </div>
                    <div
                      onClick={() => {
                        setPasswordVisible(!passwordVisible);
                      }}
                      className=" cursor-pointer w-[20px] h-[20px] absolute flex justify-center top-[20px] items-center right-2 "
                    >
                      <i
                        class={`bi ${passwordVisible ? "bi-eye-fill" : "bi-eye-slash-fill"
                          } absolute`}
                      ></i>
                    </div>
                  </div>

                  <div className={`relative mt-3 `}>
                    <div class="relative h-11 w-full bloksInput ">
                      <input
                        ref={elemetRefConfPass}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                        type={ConfpasswordVisible ? "text" : "password"}
                        className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassConfPass}`}
                        placeholder=" "
                      />
                      <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Confirme le mot de passe
                      </label>
                    </div>
                    <div
                      onClick={() => {
                        setConfPasswordVisible(!ConfpasswordVisible);
                      }}
                      className=" cursor-pointer w-[20px] h-[20px] top-[20px] absolute flex justify-center items-center right-2 "
                    >
                      <i
                        class={`bi ${ConfpasswordVisible
                          ? "bi-eye-fill"
                          : "bi-eye-slash-fill"
                          } absolute`}
                      ></i>
                    </div>
                  </div>
                  <div className="flex justify-end items-center mt-3">



                    {spinnerButton1 ? (
                      <>
                        <div className="flex justify-end items-center  relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                          <div className='absolute bg-transparent   pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                            <FadeLoader
                              color="rgb(255, 255, 255)"
                              height={10}
                              margin={-9}
                              radius={100}
                              speedMultiplier={1}
                              width={1}
                            /></div>
                          <input type="submit" id="send" value="Modifier" class=" transition-all "></input>
                          <i class="bi bi-send ml-2 "></i>
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            handleChangePassword();
                          }}
                          className=" cursor-pointer w-max  flex justify-end  bg-blue-600   p-2 rounded  text-white"
                        >
                          Modifier <i class="bi bi-send ml-2 "></i>
                        </button>  </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default CompteAdminEchange;
