/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types'
function Qoiburuempell({ isdivScroll }) {

    const [moblie, SetMobile] = useState(window.innerWidth < 442.893948848448)

    const scrollQuestion = () => {
        if (isdivScroll.current) {
            isdivScroll.current.scrollTop = isdivScroll.current.scrollHeight - 2200

        }
        if (moblie) {
            isdivScroll.current.scrollTop = isdivScroll.current.scrollHeight - 3200

        }


    }

    useEffect(() => {
        const huldleResize = () => {
            SetMobile(window.innerWidth < 442.893948848448)
        }

        window.addEventListener('resize', huldleResize)
        setTimeout(() => {
            isdivScroll.current.style.scrollBehavior = 'smooth';
        }, 0);
        return () => {
            window.removeEventListener('resize', huldleResize)
        }
    }, []);

    const images = [
        { src: '/image/acceuilmarket.png', caption: 'Marche' },
        { src: '/image/Comptemoneyelectronic.png', caption: 'Echange' },
        { src: '/image/online_school.png', caption: 'Cours en ligne' },
        { src: '/image/Livres electroniques.png', caption: 'Bibliothèque' },
        { src: '/image/reservationenligne.png', caption: 'Hotel et Hebergement' },
        { src: '/image/maintenancedomicile.png', caption: 'Maintenance domicile' },
        { src: '/image/decoration.png', caption: 'Décoration' },
        { src: '/image/cameraman.png', caption: 'Cameraman' },
        { src: '/image/restaurant.png', caption: 'Restaurant' },
        { src: '/image/Studio.png', caption: 'Studio' },
        { src: '/image/Piscinne.png', caption: 'Piscine' },
        { src: '/image/Concert.png', caption: 'Concert' },
        { src: '/image/Massage.png', caption: 'Massage ou Sonner' },
        { src: '/image/Gymtonic.png', caption: 'Gymtonique' },
        { src: '/image/programming.png', caption: 'Programmation' },
        { src: '/image/admnistration_des_reseaux.png', caption: 'Administration des réseaux' },
        { src: '/image/admnistration_des_basesdonnees.png', caption: 'Administration des base de donnee' },
        { src: '/image/TAXI.png', caption: 'Reservations des taxi' },
        { src: '/image/agricultur_elevage.png', caption: 'Agri & Elev' },


    ]

    var setting = {
        dots: true,
        infinite: true,
        speed: 3000,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1294,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1075,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: false,
                    infinite: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
        ],
    }



    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };


    return (
        <>
            <div className=" w-[95%] py-10 flex items-center flex-col   sm:p-2 rounded my-[50px] sm:mx-2">
                <h1 className="flex  justify-center text-[30px]">
                    <h2 className='text-[35px] font-serif w-full textSizeTitre text-center text-blue-600'><span >C'est quoi la sociéte</span> <span >Burundi</span> <span >en temps </span><span >réel</span></h2>
                </h1>
                <div className="sm:p-2 w-[95%]  reduitTailParagr text-gray-500 text-[23px] text-center">
                    Burundi en temps réel est l'entreprise spécialisée dans le développement de solutions web personnalisées offre une gamme complète de services comprenant la création de sites web sur mesure avec des designs personnalisés, le développement d'applications web, mobiles et de bureau, ainsi que les services d'optimisation et de maintenance de sites existants
                </div>
                <div onClick={scrollQuestion} className='bg-blue-600 text-white border-2 border-dotted hover:text-blue-700 hover:bg-white transition-all cursor-pointer rounded-3xl hover:border-blue-600 py-2 px-4' >Autre question</div>
            </div>



            <div className='bg-gray-100 w-[95%]    '>
                <Slider {...setting}>
                    {images.map((image, index) => (

                        <div key={index} class=" overflow-hidden pl-4 py-2   rounded-2xl bg-inherit flex justify-center ">
                            <div className="md:w-[15em] md:h-[15em] w-full  border   border-blue-700  bg-gray-50 h-24 flex justify-center   p-2 rounded-2xl overflow-hidden relative"     >
                                <LoadingImage
                                    alt=''
                                    src={image.src}
                                    className='w-full h-full object-cover object-center  p-2  bg-gray-200' />
                                <span className='absolute top-[93%] w-max -translate-x-[50%]  -translate-y-[50%]  left-1/2 bg-blue-800 rounded-xl hover:bg-white hover:text-blue-700 border hover:border-blue-700 transition-all cursor-pointer text-white px-2 py-1 max-md:text-[10px] max-md:pb-3'>{image.caption}</span>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

        </>
    )
}

export default Qoiburuempell
