import React, { useEffect, useState } from 'react'
import axios from 'axios'
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage'
function Fraistransaction() {
    const [mobile, GetMobile] = useState(window.innerWidth < 566)
    const [selected, GetSelected] = useState('Lumicash')
    const [loading, SetLoading] = useState(true)
    useEffect(() => {
        const HundleSize = () => {
            GetMobile(window.innerWidth < 566)
        }
        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])
    const [rubrique, setRubrique] = useState([])
    useEffect(() => {
        axios.get(`https://btrproject.burundientempsreel.com/rubrique/all?selected=${selected}`).then((response) => {
            setRubrique(response.data);
            setTimeout(() => {
                SetLoading(false)
            }, 1000);
        }).catch((err) => {

            console.error('Erreur lors de la récupération des rurique :', err.message);
        })
    }, [selected])


    useEffect(() => {
        axios.get(`https://btrproject.burundientempsreel.com/fashanya/myOnly`).then((response) => {
            setRubrique(response.data);
            setTimeout(() => {
                SetLoading(false)
            }, 1000);
        }).catch((err) => {

            console.error('Erreur lors de la récupération des fashanya :', err.message);
        })
    }, [])

    return (
        <div className='w-full min-h-screen p-2 overflow-hidden'>
            {loading && <SpinnerDemarage />}
            <div className='bg-gray-200 p-2 rounded'>
                <div className='w-full flex justify-between px-4 items-center'>
                    <div className='border-2 border-blue-800 ml-4 rounded overflow-hidden'>
                        <select onChange={(e) => GetSelected(e.target.value)} className='px-2 py-1 cursor-pointer border-none  bg-white'>
                            <option value='Lumicash'>Lumicash</option>
                            <option value='Mobinetel'>Mobinetel</option>
                            <option value='Telemax'>Telemax</option>
                            <option value='Bancobu enoti'>Bancobu enoti</option>
                            <option value='CECM akaravyo'>CECM akaravyo</option>
                        </select>
                    </div>
                </div>


                <div className='mt-10 w-full bg-slate-50 flex flex-col rounded-md p-2'>
                    <div className='w-full text-xl text-center p-2 font-semibold'>Rubrique des frais de rubrique</div>
                    <div className='w-full text-xl flex justify-center p-2 font-semibold'>
                        <div className='w-max py-1 px-2  text-center rounded font-extrabold bg-blue-200 text-blue-700 '> {selected == 'Mobinetel' ? "Mobinetel" : selected == 'Telemax' ? "Telemax" : selected == 'Bancobu enoti' ? "Bancobu enoti" : selected == 'CECM akaravyo' ? "CECM akaravyo" : "Lumicash"}</div>
                    </div>
                    <div className='w-full p-4 overflow-hidden overflow-x-auto '>
                        <table className={`border border-gray-300 ${mobile ? 'w-[40em]' : 'w-full'}`}>
                            <thead className='w-full bg-blue-600 text-white overflow-hidden'>
                                <tr className='border-b border-gray-300'>
                                    <th rowSpan={2} className='p-1 border-r border-gray-300'>Minimum</th>
                                    <th rowSpan={2} className='p-1 border-r border-gray-300'>Maximum</th>
                                    <th colSpan={2} className='p-1 border-r border-gray-300'>Frais de transfert </th>
                                    <th rowSpan={2} className='p-1 border-r border-gray-300'>Frais de retrait</th>
                                </tr>
                                <tr className='border-b'>
                                    <th className='border-r border-gray-300 p-1'>Enregistrée</th>
                                    <th className='border-r border-gray-300 p-1'>Non enregistrée</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rubrique.length > 0 && rubrique.map((rub) => {
                                    return (
                                        < tr className='border-b border-gray-300' >
                                            <td className='border-r border-gray-300 pl-2'>{rub.min}</td>
                                            <td className='border-r border-gray-300 pl-2'>{rub.max}</td>
                                            <td className='border-r border-gray-300 pl-2'>{rub.yanditswe}</td>
                                            <td className='border-r border-gray-300 pl-2'>{rub.atanditswe}</td>
                                            <td className='border-r border-gray-300 pl-2'>{rub.kubikura}</td>

                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>


                </div>
            </div>




        </div >
    )
}

export default Fraistransaction
