import React from "react";
import VoirPlusClient from "./VoirPlusClient";
function VoirPlusClientComponent() {
  return (
    <>
     
      <VoirPlusClient />
    </>
  );
}

export default VoirPlusClientComponent;
