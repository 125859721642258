import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
import Empty from "../../../Visitor/Marche/Empty/Empty";
function NoSeller() {
    const NoSeller = 'de nouveaux vendeur'
    const [listoffUsers, setListUsers] = useState([]);
    const [element, setElement] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, Setloading] = useState(true);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (event) => {
        const selectedItemsPerPage = parseInt(event.target.value);
        setItemsPerPage(selectedItemsPerPage);
    };
    const firstEntry = (currentPage - 1) * itemsPerPage + 1;
    const lastEntry = Math.min(currentPage * itemsPerPage, element);

    useEffect(() => {
        axios.get(` https://btrproject.burundientempsreel.com/market/notseller/getall?page=${currentPage}&size=${itemsPerPage}`, {
            headers: {
                accessToken: localStorage.getItem("t"),
            }
        })
            .then((response) => {
                setElement(response.data.totalItems);
                setListUsers(response.data.users);
                setTotalPages(response.data.totalPages);
                setTimeout(() => {
                    Setloading(false)
                }, 1000);
            }).catch((error) => {
                console.log(error.message);
                if (error.response) {
                    console.error(error.response.data);
                }
            })
    }, [currentPage, itemsPerPage]);

    const [form, setfForm] = useState(false);


    const [userSearch, setUserSearch] = useState({});
    const [email, setEmail] = useState('');



    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);






    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };


    return (
        <>
            {loading && <SpinnerDemarage />}
            {listoffUsers.length > 0 ? (
                <div class="bg-white border-t text-gray-700 border-b sm:border-l sm:border-r sm:rounded-xl shadow mb-6">
                    <div class="container mx-auto px-4 sm:px-8">
                        <div class="py-8">
                            <div>
                                <h2 class="text-2xl font-semibold  text-blue-500 leading-tight">
                                    Enregister un vendeur
                                </h2>
                            </div>
                            <div class="my-2 flex sm:flex-row flex-col space-x-2">
                                <div class="flex flex-row mb-1 sm:mb-0">
                                    <div className="relative">
                                        <select
                                            className="appearance-none h-full rounded-2xl border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            value={itemsPerPage}
                                            onChange={handleItemsPerPageChange}
                                        >
                                            <option>5</option>
                                            <option>10</option>
                                            <option>20</option>
                                            {(listoffUsers.length > 20) && (<option>{listoffUsers.length}</option>)}
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg
                                                className="fill-current h-4 w-4"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div class="relative">
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg
                                                class="fill-current h-4 w-4"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                                <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                    <table class="min-w-full leading-normal">
                                        <thead>
                                            {isMobile ?
                                                <tr>
                                                    <th class="px-5 py-3 border-r-2 border-gray-200 bg-blue-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                        Email
                                                    </th>

                                                    <th class="px-5 py-3 border-r-2 border-gray-200 bg-blue-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                        Action
                                                    </th>
                                                </tr> :
                                                <tr>
                                                    <th class="px-5 py-3 border-r-2 border-gray-200 bg-blue-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                        Vendeur
                                                    </th>
                                                    <th class="px-5 py-3 border-r-2 border-gray-200 bg-blue-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                        CNI
                                                    </th>
                                                    <th class="px-5 py-3 border-r-2 border-gray-200 bg-blue-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                        E-mail
                                                    </th>
                                                    <th class="px-5 py-3 border-r-2 border-gray-200 bg-blue-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                        Tél
                                                    </th>
                                                    <th class="px-5 py-3 border-r-2 border-gray-200 bg-blue-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                        Adresse
                                                    </th>
                                                    <th class="px-5 py-3 border-r-2 border-gray-200 bg-blue-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                        Action
                                                    </th>
                                                </tr>}
                                        </thead>
                                        <tbody>

                                            {listoffUsers && (
                                                isMobile ?
                                                    listoffUsers.map((value, key) => {
                                                        return (
                                                            <tr>
                                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                    <p class="text-gray-900 whitespace-no-wrap">
                                                                        {value.user.email}</p>
                                                                </td>
                                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                    <span class="relative inline-block  py-1 font-semibold text-green-900 leading-tight">
                                                                        <Link to={`/Admin/RegisterMarket/detail/${value.user.id}`}

                                                                            class="px-5 py-2 border-olive-500 border text-olive-500 rounded-2xl transition duration-300 hover:bg-yellow-500hover:text-white focus:outline-none"
                                                                        >
                                                                            Details
                                                                        </Link>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) : listoffUsers.map((value, key) => {
                                                        return (
                                                            <tr>
                                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                    <div class="flex items-center">
                                                                        <div class="flex-shrink-0 w-10 h-10">
                                                                            <LoadingImage
                                                                                class="w-full h-full rounded-full"
                                                                                src={` https://btrproject.burundientempsreel.com/uploads/photosuser/${value.user.photo}`}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div class="ml-3">
                                                                            <p class="text-gray-900 whitespace-no-wrap">
                                                                                {value.user.nom + '  ' + value.user.prenom}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                    <p class="text-gray-900 whitespace-no-wrap">
                                                                        {value.CNI}</p>
                                                                </td>
                                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                    <p class="text-gray-900 whitespace-no-wrap">
                                                                        {value.user.email}</p>
                                                                </td>
                                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                    <p class="text-gray-900 whitespace-no-wrap">
                                                                        {value.user.tel}
                                                                    </p>
                                                                </td>
                                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                    <p class="text-gray-900 whitespace-no-wrap">
                                                                        {value.user.address}
                                                                    </p>
                                                                </td>
                                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                    <span class="relative inline-block  py-1 font-semibold text-green-900 leading-tight">
                                                                        <Link to={`/Admin/RegisterMarket/detail/${value.user.id}`}

                                                                            class="px-5 py-2 border-olive-500 border text-olive-500 rounded-2xl transition duration-300 hover:bg-yellow-500hover:text-white focus:outline-none"
                                                                        >
                                                                            Details
                                                                        </Link>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }))
                                            }

                                        </tbody>
                                    </table>
                                    <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                                        <span className="text-xs xs:text-sm text-gray-900">
                                            Affichage de {firstEntry} à {lastEntry} sur {element} entrées
                                        </span>

                                        <div className="inline-flex mt-2 xs:mt-0">
                                            <button
                                                className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
                                                disabled={currentPage === 1}
                                                onClick={() => handlePageChange(currentPage - 1)}
                                            >
                                                Prev
                                            </button>
                                            <button
                                                className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
                                                disabled={currentPage === totalPages}
                                                onClick={() => handlePageChange(currentPage + 1)}
                                            >
                                                Sui
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            ) : (

                <div className='flex border justify-center items-center h-[75vh]'>
                    <Empty items={NoSeller} />
                </div>
            )
            }
        </>
    );
}

export default NoSeller;
