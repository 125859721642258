import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
function DetailProducteUserMarket() {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [photo, setPhoto] = useState([]);

  let [loading, setLoading] = useState(true);
  const [imageLoading, SetImageLoading] = useState(true)

  useEffect(() => {
    axios
      .get(`https://btrproject.burundientempsreel.com/market/seller/getonproduct/${id}`)
      .then((response) => {
        setProduct(response.data);
        setPhoto(response.data.produitfiles);
        const timer = setTimeout(() => {
          setLoading(false);
        }, 100);
        return () => clearTimeout(timer);
      });
  }, [id]);

  function handleDelete(id, index) {
    Swal.fire({
      title: 'Confirmation',
      text: 'Etes-vous sûr que vous voulez supprimer?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'SUPPRIMER',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'swal-confirm-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(` https://btrproject.burundientempsreel.com/market/productfile/ById/${id}`)
          .then((response) => {
            if (response.status === 200) {
              toast.success('le fichier a été supprimé avec succès');
              const allPhotos = [...photo]
              allPhotos.splice(index, 1)
              setPhoto(allPhotos)
            } else {
              toast.error("Une erreur s'est produite lors de la suppression du fichier");
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error("Une erreur s'est produite lors de la suppression du fichier");
          });
      }
    });
  }

  return (
    <>
      <div class="rounded-2xl  m-4">
        {loading && <SpinnerDemarage />}
        <div className="flex mb-10  flexs">
          <div>
            <div class="w-[30em] h-[30em] relative overflow-hidden userPhotos">
              {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}

              <img draggable='false'
                onLoad={() => {
                  setTimeout(() => {
                    SetImageLoading(false)
                  }, 1000)
                }}
                src={` https://btrproject.burundientempsreel.com/uploads/market/${product.profil}`}
                alt="product 1"
                class="object-cover h-full w-full rounded-2xl"
              />
            </div>
          </div>

          <div className="ml-4">
            <h4 class="font-medium text-3xl mb-2 text-gray-600">
              {product.titre}
            </h4>

            <div class="space-y-2">
              <p class="text-gray-800 font-semibold space-x-2">
                <span>Disponibilité: </span>
                <span class="text-olive-500">
                  {product.quanite} articles en stock
                </span>
              </p>

              <p class="space-x-2">
                <span class="text-gray-800 font-semibold">Catégoried: </span>
                <span class="text-gray-600">{product.categorie}</span>
              </p>
            </div>
            <div class="flex items-baseline mb-1 space-x-2 font-roboto mt-4">
              <p class="text-xl text-primary font-semibold">
                {product.prix} Fbu
              </p>
              <p class="text-base text-gray-400 line-through">
                {product.prix + (product.prix * 20) / 100} Fbu
              </p>
            </div>

            <p class="mt-4 text-gray-600">{product.description}</p>
          </div>
        </div>
        {photo.length > 0 && (
          <>
            <span className="  ml-20 text-blue-600 font-serif text-center  textMargine">
              Les photos supplementaire de <b>{product.titre}</b>
            </span>
            <div class="w-full mt-1 flex flex-wrap  justify-center">
              {photo.map((photo, index) => {
                return (
                  <div
                    key={index}
                    className="rounded-2xl border-2 border-blue-500 p-2 m-2  overflow-hidden"
                  >
                    <div className="relative h-30 overflow-hidden z-10 w-[10em] h-[10em]">
                      <div className="absolute  text-white text-sm  ">
                        <button
                          onClick={() => handleDelete(photo.id, index)}
                          className="bg-red-400   hover:bg-red-700 px-4 py-1  rounded-2xl cursor-pointer"
                        >
                          supprimer
                        </button>
                      </div>
                      <div className="w-full h-full">
                        <img draggable='false' src={` https://btrproject.burundientempsreel.com/uploads/market/${photo.file}`} alt="" class="w-full h-full object-contain border-none rounded-md cursor-pointer border" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}

      </div>
    </>

  );
}

export default DetailProducteUserMarket;
