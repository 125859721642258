/* eslint-disable eqeqeq */
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { FadeLoader } from 'react-spinners'
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage';
function EchangeAdmin() {
    const [moblie2, SetMobile2] = useState(window.innerWidth <= 1111)
    const [moblie, SetMobile] = useState(window.innerWidth <= 509)
    const [moblie1, SetMobile1] = useState(window.innerWidth <= 400)
    const [moblie3, SetMobile3] = useState(window.innerWidth <= 829)
    const [transactions, Settransactions] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const [loading, SetLoading] = useState(true)


    const [RubriqueId, setRubriqueId] = useState(null);
    const [cancelongRubriqueId, setcancelongRubriqueId] = useState(null);


    const [totalElements, setTotalElements] = useState(0)
    useEffect(() => {
        const hundleSize = () => {
            SetMobile2(window.innerWidth <= 1111)
            SetMobile(window.innerWidth <= 509)
            SetMobile1(window.innerWidth <= 400)
            SetMobile3(window.innerWidth <= 826)
        }
        window.addEventListener('resize', hundleSize)
        return () => window.removeEventListener('resize', hundleSize)
    }, [])





    useEffect(() => {
        axios.get(`https://btrproject.burundientempsreel.com/fashanya/all?&page=${currentPage}&pageSize=${pageSize}`).then((response) => {
            Settransactions(response.data.fashanya);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalElements);
            setTimeout(() => {
                SetLoading(false)
            }, 1000);
        }).catch((err) => {

            console.error('Erreur lors de la récupération des fashanya :', err.message);
        })
    }, [pageSize, currentPage])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };





    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const formatDate = date => {
        return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
    };

    const getRange = () => {
        const from = (currentPage - 1) * pageSize + 1;
        const to = Math.min(currentPage * pageSize, totalElements);
        return `${from} à ${to} transactions sur ${totalElements}`;
    };


    const handleConfirmation = async (trasId) => {

        try {
            setcancelongRubriqueId(trasId);
            const result = await Swal.fire({
                title: "Confirmation",
                text: "Vous voulez annuler ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
            });

            if (result.isConfirmed) {
                // Si l'utilisateur a confirmé la suppression
                axios.delete(`https://btrproject.burundientempsreel.com/fashanya/${trasId}`)
                    .then((res) => {
                        toast.success('Vous avez annulé avec succès la transaction!');
                        // Supprimer l'élément correspondant de la liste
                        Settransactions(transactions.filter(transaction => transaction.id !== trasId));
                    })
                    .catch((err) => {
                        toast.warn('Erreur de l\'annulation de la transaction');
                        setcancelongRubriqueId(null);
                    }).finally(() => {
                        setcancelongRubriqueId(null);
                    });
            } else {
                console.log('Cancelled');
                setcancelongRubriqueId(null);
            }
        } catch (error) {
            console.log('Error:', error.message);
            setRubriqueId(null);
        }
    };

    const handleAccepter = async (trasId) => {
        try {
            setRubriqueId(trasId);
            const result = await Swal.fire({
                title: "Confirmation",
                text: "Vous Voulez Accepter ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
            });

            if (result.isConfirmed) {
                // Si l'utilisateur a confirmé la suppression
                axios.put(`https://btrproject.burundientempsreel.com/fashanya/accepter/${trasId}`)
                    .then((res) => {
                        toast.success('Vous avez Accepte la transaction !');
                        setPageSize(pageSize + 1);
                    })
                    .catch((err) => {
                        toast.warn('Erreur de l\'acceptation de la transaction');
                        setRubriqueId(null);
                    }).finally(() => {
                        setRubriqueId(null);
                    });
            } else {
                console.log('Cancelled');
                setRubriqueId(null);
            }

        } catch (error) {
            console.log('Error:', error.message);
            setRubriqueId(null);
        }
    };
    const handleRungika = async (trasId) => {
        try {
            setRubriqueId(trasId);
            const result = await Swal.fire({
                title: "Confirmation",
                text: "Vous Voulez Accepter ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
            });

            if (result.isConfirmed) {
                // Si l'utilisateur a confirmé la suppression
                axios.put(`https://btrproject.burundientempsreel.com/fashanya/Rungika/${trasId}`)
                    .then((res) => {
                        toast.success('Vous avez demandé qu’il t’envoie l’électronique avec succès !')
                        setPageSize(pageSize + 1);
                    })
                    .catch((err) => {
                        toast.warn('Erreur de l\'acceptation de la transaction');
                        setRubriqueId(null);
                    }).finally(() => {
                        setRubriqueId(null);
                    });
            } else {
                console.log('Cancelled');
                setRubriqueId(null);
            }

        } catch (error) {
            console.log('Error:', error.message);
            setRubriqueId(null);
        }
    };


    return (
        <div className='w-full  bg-white p-[2px] sm:p-5'>
            {loading && <SpinnerDemarage />}
            <div className='bg-gray-100 p-2 pb-10 rounded hidden'>
                <div className={`w-full  text-center font-semibold border-b border-white pb-5 ${moblie ? 'text-[15px]' : 'text-2xl'}`}>Echange de monaie electronique</div>
                <div className={`w-full flex justify-center mt-5`}>

                    <div className={`  p-[4px] sm:p-4 bg-[#0000ff10] rounded-md ${moblie1 ? 'w-full mb-5' : 'w-[35em]'}`}>
                        <div className={` font-bold p-[2px] sm:p-2 text-blue-800 text-xl`}>Chez agent</div>
                        <div className={`   tracking-wide`}>
                            Vous pouvez enregistrer votre monnaie électronique afin que ceux qui n'en ont pas puissent connaître directement les types de monnaies électroniques disponibles, avec des montants allant de 20.000 à 5.000.000<br /><br />
                            Vous pouvez dire aux autres que l'argent dont vous disposez est compris entre 20 000 et 5 000 000, vous envisagez de le créer, puis choisissez un autre type d'argent pour donner ce montant.
                        </div>
                        <div className='w-full  flex items-center flex-col p-[3px] sm:p-3 mt-[2px] sm:mt-2'>
                            <div className='bg-blue-400 p-[3px] sm:p-3 rounded-full'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right-circle-fill w-6 h-6 sm:w-8 sm:h-8  text-blue-600" viewBox="0 0 16 16">
                                    <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z" />
                                </svg>
                            </div>
                            <Link to='/User/Echange/Echange/EnregistrerMonnaie' className={`hover:bg-white bg-[#0000ff75] transition-all duration-75 text-blue-800 font-bold border border-blue-800 p-2 mt-3 rounded cursor-pointer `}>
                                Enregistre
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-gray-50 p-2 pb-10 rounded mt-5'>
                <div className={`w-full   text-center font-semibold border-b border-white pb-5 ${moblie ? 'text-[15px]' : 'text-2xl'}`}>Rendu propre</div>
                <div className='overflow-x-auto overflow-y-hidden'>
                    <div className={`mt-5 flex justify-center ${moblie3 ? 'w-[70em]' : 'w-full'}`}>
                        <table className={`border border-gray-300 p-2 rounded ${moblie2 ? 'w-full' : 'w-[90%] '} `}>
                            <thead className='bg-[#0000ff3a]'>
                                <tr className='border-b border-gray-300'>
                                    <th className='border-r p-2 text-left border-gray-300'>Echangeur </th>
                                    <th className='border-r p-2 text-left border-gray-300'>Possession </th>
                                    <th className='border-r p-2 text-left border-gray-300'>Montant </th>
                                    <th className='border-r p-2 text-left border-gray-300'>Désir</th>
                                    <th className='border-r p-2 text-left border-gray-300'>Code agent </th>
                                    <th className='border-r p-2 text-left border-gray-300'>Status </th>
                                    <th className='border-r p-2 text-left border-gray-300'>Date </th>
                                    <th className='border-r p-2 text-left border-gray-300 w-[10em]'>Action </th>
                                </tr>
                            </thead>
                            <tbody className='bg-white'>
                                {transactions.length > 0 ? transactions.map((datas, position) => (
                                    <tr key={position} className={`border-b border-gray-300 `}>
                                        <td className='border-r p-1 text-left border-gray-300'>{datas.echangeur && datas.echangeur.nom} {datas.echangeur && datas.echangeur.prenom}</td>
                                        <td className='border-r p-1 text-left border-gray-300'>{datas.mfise}</td>
                                        <td className='border-r p-1 text-left border-gray-300'>{(datas.montant).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</td>
                                        <td className='border-r p-1 text-left border-gray-300'>{datas.nshaka}</td>
                                        <td className='border-r p-1 text-left border-gray-300'>{datas.codeAgent}</td>
                                        <td className={`border-r p-1 w-[7em] relative border-gray-300 text-left first-letter:uppercase font-bold ${datas.status == 'en attente' ? 'bg-blue-100  text-blue-600' : datas.status == 'accepté' ? 'bg-yellow-100 text-yellow-600' : datas.status == 'annulé' ? 'bg-red-100 text-red-600' : datas.status == 'reçu' ? 'bg-green-100 text-green-600' : datas.status == 'noté' ? 'bg-violet-200 text-violet-800' : null}`}>
                                            {datas.status}
                                            {datas.status == 'reçu' && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all inline-flex absolute w-5 h-5 right-1 top-[25%]" viewBox="0 0 16 16"><path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" /></svg>}
                                            {datas.status == 'noté' && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill inline-flex absolute w-3 h-3 right-1 top-[30%]" viewBox="0 0 16 16">  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" /></svg>}
                                            {datas.status == 'annulé' && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x inline-flex absolute w-5 h-5 right-1 top-[25%]" viewBox="0 0 16 16">  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" /></svg>}
                                            {datas.status == 'accepté' && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check inline-flex absolute w-5 h-5 right-1 top-[25%]" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg>}
                                            {datas.status == 'en attente' && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-alarm-fill inline-flex absolute w-3 h-3 right-1 top-[40%]" viewBox="0 0 16 16"><path d="M6 .5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H9v1.07a7.001 7.001 0 0 1 3.274 12.474l.601.602a.5.5 0 0 1-.707.708l-.746-.746A6.97 6.97 0 0 1 8 16a6.97 6.97 0 0 1-3.422-.892l-.746.746a.5.5 0 0 1-.707-.708l.602-.602A7.001 7.001 0 0 1 7 2.07V1h-.5A.5.5 0 0 1 6 .5m2.5 5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9zM.86 5.387A2.5 2.5 0 1 1 4.387 1.86 8.035 8.035 0 0 0 .86 5.387zM11.613 1.86a2.5 2.5 0 1 1 3.527 3.527 8.035 8.035 0 0 0-3.527-3.527" /></svg>}
                                        </td>
                                        <td className='border-r p-1 text-left '>{formatDate(datas.createdAt)}</td>
                                        {datas.status == 'en attente' ? (
                                            <td className='border-r  text-left flex'>
                                                {RubriqueId === datas.id ? (
                                                    <div disabled className={`relative cursor-no-drop  flex justify-center items-center rounded text-white`}>
                                                        <div className='absolute bg-transparent pointer-events-none top-[20%] left-[15%]  w-full h-full flex justify-center items-center z-50'>
                                                            <FadeLoader
                                                                color="rgb(255, 255, 255)"
                                                                height={10}
                                                                margin={-9}
                                                                radius={100}
                                                                speedMultiplier={1}
                                                                width={1}
                                                            />
                                                        </div>
                                                        <button disabled onClick={() => handleRungika(datas.id)} className='bg-blue-950 pointer-events-none text-gray-300 cursor-pointer rounded m-1 py-1 px-3'>Envoyer</button>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <button onClick={() => handleRungika(datas.id)} className='bg-blue-500 text-white cursor-pointer rounded m-1 py-1 px-3'>Envoyer</button>
                                                    </>
                                                )}
                                                {cancelongRubriqueId === datas.id ? (
                                                    <div disabled className={`relative cursor-no-drop  flex justify-center items-center rounded text-white`}>
                                                        <div className='absolute bg-transparent pointer-events-none top-[20%] left-[15%]  w-full h-full flex justify-center items-center z-50'>
                                                            <FadeLoader
                                                                color="rgb(255, 255, 255)"
                                                                height={10}
                                                                margin={-9}
                                                                radius={100}
                                                                speedMultiplier={1}
                                                                width={1}
                                                            />
                                                        </div>
                                                        <button disabled onClick={() => handleConfirmation(datas.id)} className='bg-red-950 text-gray-400 pointer-events-none rounded m-1 py-1 px-3'>Annuler</button>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <button onClick={() => handleConfirmation(datas.id)} className='bg-red-500 text-white cursor-pointer rounded m-1 py-1 px-3'>Annuler</button>
                                                    </>
                                                )}

                                            </td>
                                        ) : datas.status == 'Rungika' ? (
                                            <td className='border-r  text-left  flex'>

                                                {RubriqueId === datas.id ? (
                                                    <div disabled className={`relative cursor-no-drop  flex justify-center items-center rounded text-white`}>
                                                        <div className='absolute bg-transparent pointer-events-none top-[20%] left-[15%]  w-full h-full flex justify-center items-center z-50'>
                                                            <FadeLoader
                                                                color="rgb(255, 255, 255)"
                                                                height={10}
                                                                margin={-9}
                                                                radius={100}
                                                                speedMultiplier={1}
                                                                width={1}
                                                            />
                                                        </div>
                                                        <button disabled onClick={() => handleAccepter(datas.id)} className='bg-blue-950 text-gray-400 pointer-events-none rounded m-1 py-1 px-3'>Accepter</button>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <button onClick={() => handleAccepter(datas.id)} className='bg-blue-500 text-white cursor-pointer rounded m-1 py-1 px-3'>Accepter</button>
                                                    </>
                                                )}


                                            </td>
                                        ) : (
                                            <td className='border-r first-letter:uppercase  p-1 text-left bg-gray-100 text-gray-500 flex'>
                                                Aucun accès
                                            </td>
                                        )}

                                    </tr>
                                )) : null}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='flex justify-center w-full items-center flex-col'>
                    {transactions.length > 0 && (
                        <>
                            <div className="mt-4 flex items-center w-full  p-2 justify-center">
                                <div className="flex items-center">
                                    <button
                                        disabled={currentPage === 1}
                                        onClick={handlePreviousPage}
                                        className={`mx-1 px-3 py-1 rounded bg-blue-200 font-semibold text-blue-700 ${currentPage === 1 && 'cursor-no-drop opacity-30'}`}
                                    >
                                        Précédent
                                    </button>
                                    {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                                        <button
                                            key={pageNumber}
                                            onClick={() => handlePageChange(pageNumber)}
                                            disabled={currentPage === pageNumber}
                                            className={`mx-1 px-3 py-1 rounded ${currentPage === pageNumber ? 'bg-blue-700 text-white' : 'bg-gray-300 text-gray-700'}`}
                                        >
                                            {pageNumber}
                                        </button>
                                    ))}
                                    <button
                                        onClick={handleNextPage}
                                        className={`mx-1 px-3 py-1 rounded bg-blue-200 font-semibold text-blue-700 ${currentPage === totalPages && 'cursor-no-drop opacity-30'}`}>                                      Suivant
                                    </button>
                                </div>
                            </div>

                            <div className="mt-4 flex items-center justify-center">
                                <p className="text-gray-600">{getRange()}</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default EchangeAdmin
