/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { FadeLoader } from 'react-spinners';
import axios from 'axios';

function Help() {
    const [formula, SetFormul] = useState(false)
    const [spinnerButton, SetSpinnerButton] = useState(false)
    const [isEmail, setIsEmail] = useState('');
    const [animationClassEmail, setAnimationClassEmail] = useState('');
    const elemetEmail = useRef(null)
    const [isMotPasse, setIsMotPasse] = useState('');
    const [animationClassMotPasse, setAnimationClassMotPasse] = useState('');
    const elemenetPassWords = useRef(null)

    const [isTextarea, setIsTextarea] = useState('');
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const elemenetMotif = useRef(null)
    const id = localStorage.getItem("idexch")


    function validateEmail(email) {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (isEmail.trim() === '') {
            toast.warning("L'adresse email est obligatoire", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassEmail('');
            }, 3000);
            elemetEmail.current.focus()
            return;
        }
        if (!validateEmail(isEmail)) {
            toast.warning("L'adresse email doit être valide", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassEmail('');
            }, 3000);
            elemetEmail.current.focus()
            return;
        }
        if (isMotPasse.trim() === '') {
            toast.warning('Le mot de passe est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassMotPasse('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassMotPasse('');
            }, 3000);
            elemenetPassWords.current.focus()
            return;
        }
        if (isTextarea.trim() === '') {
            toast.warning('Votre commentaire est nécessaire', {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassTextarea('');
            }, 3000);
            elemenetMotif.current.focus()
            return;
        }
        const data = {
            motif: isTextarea,
            password: isMotPasse,
            email: isEmail,
            usersId: id
        }

        axios.post("https://btrproject.burundientempsreel.com/anl/demandeannulation", data).then((res) => {
            toast.success('Demande enregistrée avec succès');

            setIsEmail("")
            setIsMotPasse("")
            setIsTextarea("")
            SetFormul(false)
        }).catch((err) => {
            if (err.response) {
                toast.warn(err.response.data[0])
                return false;
            }
            console.log(err.message)
        }).finally(() => {
            // setboutLoading(false)
        })
    };
    const sendRef = useRef(null)

    const [mobile, SetMobile] = useState(window.innerWidth < 318)
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile(window.innerWidth < 318)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }

    }, [])

    return (
        <div className='min-h-[90vh]'>
            <div class={`bg-white  ${mobile ? 'py-2' : 'py-10'}`}>
                <div class="container mx-auto px-4">
                    <h2 class={` font-bold text-gray-800 ${mobile ? 'text-base ' : 'text-2xl mb-10 '}  w-full text-left font-serif`}>Contactez-nous</h2>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 ">
                        <div>
                            <h3 class={`font-semibold text-gray-700 mb-2 ${mobile ? 'text-sm' : 'text-xl '}`}>Coordonnées</h3>
                            <ul class="list-disc pl-6">
                                <li className={`${mobile ? 'text-sm' : ''}`}>
                                    <span class={`text-gray-600 ${mobile ? 'text-sm' : ''}`}>Téléphone :</span> <a href='tel: +25768706358' className='text-blue-600 underline'> +257 68706358</a>
                                    / <a href='tel:+25779928694' className='text-blue-600 underline'>+257 79928694</a></li>
                                <li><span class={`text-gray-600 ${mobile ? 'text-sm' : ''}`}>E-mail :</span> <a href='mailto:variacoeur@gmail.com' className='text-blue-600 underline'>variacoeur@gmail.com</a>                             </li>
                            </ul>
                        </div>
                    </div>
                    <div className='mt-10 '>
                        <div onClick={() => SetFormul(!formula)} className={`text-red-700 cursor-pointer ${mobile ? 'text-center' : ''}`}>
                            Demande de supprime votre compte
                        </div>
                        {formula &&
                            <div className='flex  p-1 sm:p-4'>
                                <div className={` border rounded-2xl border-fuchsia-700 h-max flex justify-center p-2 sm:p-4 flex-col items-center ${mobile ? 'w-full' : 'sm:w-[35em]'} `}>
                                    <h2 className="sm:p-2 text-center max-sm:text-base mb-[1em] text-base sm:text-[20px] font-bold text-blue-700 border-b border-gray-400 pb-4">Formulaire de demande la suppression de compte</h2>
                                    <div className="m-3 sm:p-2 rounded w-full h-max ">
                                        <form onSubmit={handleSubmit}>
                                            <div className="flex mb-6 ">
                                                <div className="transition-all flex flex-col w-[100%] justify-between ">
                                                    <div className="relative  flex flex-col  w-full mt-2">
                                                        <label className='mb-2 font-semibold'>
                                                            Votre email
                                                        </label>
                                                        <input
                                                            value={isEmail}
                                                            ref={elemetEmail}
                                                            onInput={(e) => setIsEmail(e.target.value)}
                                                            placeholder="Votre email... "
                                                            className={`p-3 border border-fuchsia-400 focus:border-2 focus:border-fuchsia-700 rounded-md outline-none ${animationClassEmail} `}
                                                        />
                                                    </div>
                                                    <div className="relative  flex flex-col   w-full mt-2">
                                                        <label className='mb-2 font-semibold'>
                                                            Votre mot de passe
                                                        </label>
                                                        <input
                                                            value={isMotPasse}
                                                            ref={elemenetPassWords}
                                                            onInput={(e) => setIsMotPasse(e.target.value)}
                                                            className={`p-3 border border-fuchsia-400 focus:border-2 focus:border-fuchsia-700 rounded-md outline-none ${animationClassMotPasse} `}
                                                            placeholder="Votre mot de passe"
                                                        />

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="relative  flex flex-col   w-full mt-2">
                                                <label className='mb-2 font-semibold'>
                                                    Motif
                                                </label>
                                                <textarea
                                                    value={isTextarea}
                                                    onChange={(e) => {
                                                        setIsTextarea(e.target.value);
                                                    }}
                                                    ref={elemenetMotif}
                                                    placeholder="Motif... "
                                                    className={`p-3 min-h-[10em] border border-fuchsia-400 focus:border-2 focus:border-fuchsia-700 resize-none rounded-md outline-none ${animationClassTextarea} `}
                                                />
                                            </div>
                                            <div className="flex justify-end items-center mt-4">
                                                {spinnerButton ? (
                                                    <>
                                                        <label htmlFor="send" ref={sendRef} className="w-max relative pointer-events-none   flex justify-center items-center p-1 bg-gray-900 rounded cursor-pointer px-5 py-1 text-white ">
                                                            <div className='absolute bg-transparent pt-5  pl-5 w-full h-full flex justify-center items-center z-50'>
                                                                <FadeLoader
                                                                    color="rgba(255, 255, 255)"
                                                                    height={10}
                                                                    margin={-9}
                                                                    radius={100}
                                                                    speedMultiplier={1}
                                                                    width={1}
                                                                /></div>
                                                            <span>Demander</span>
                                                            <i title="Demander" className="bi bi-send ml-3 "></i>
                                                        </label>
                                                    </>
                                                ) : (
                                                    <>
                                                        <label htmlFor="send" ref={sendRef} className="w-max  flex justify-end p-1 bg-blue-600 rounded cursor-pointer px-5 py-1 text-white ">
                                                            <span>Demander</span>
                                                            <i title="Demander" className="bi bi-send ml-3 "></i>
                                                        </label>
                                                    </>
                                                )}
                                                <input type="submit" id="send" hidden className="w-max hidden justify-end p-1 bg-blue-600 rounded cursor-pointer px-5 py-1 text-white" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Help
