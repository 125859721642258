import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';



function UploadPDF({ ShowPages }) {
  const pdfURL = '../../../pdf/ExemenGrille.pdf';
  const [numPage, setNumPage] = useState(0);
  const [ZoomPage, setZoomPage] = useState(1.1);

  const DocumentLoadSuccess = ({ numPages }) => {
    setNumPage(numPages);
  };

  const renderPages = () => {
    const pages = [];
    for (let pageNumber = 1; pageNumber <= numPage; pageNumber++) {
      pages.push(<Page key={pageNumber} pageNumber={pageNumber} scale={ZoomPage} className='pdf-page-wrapper' />);
    }
    return pages;
  };

  ShowPages(numPage)

  return (
    <div>
      <div className="sticky top-0 bg-gray-300 z-10 flex justify-center items-center">
        <div onClick={() => { setZoomPage(ZoomPage - 0.1) }} className={`${ZoomPage < 0.9 ? 'pointer-events-none opacity-50' : 'pointer-events-auto opacity-100'} bg-blue-400 select-none text-white font-bold m-1 w-[30px] h-[30px] rounded-full flex justify-center pb-1 cursor-pointer hover:bg-blue-600 transition-all items-center text-[25px]`}>-</div>
        <div onClick={() => { setZoomPage(ZoomPage + 0.1) }} className={`${ZoomPage > 1.9 ? 'pointer-events-none opacity-50' : 'pointer-events-auto opacity-100'} bg-blue-400 select-none text-white font-bold m-1 w-[30px] h-[30px] rounded-full flex justify-center pb-1 cursor-pointer hover:bg-blue-600 transition-all items-center text-[25px]`}>+</div>
      </div>
      <Document file={pdfURL} onLoadSuccess={DocumentLoadSuccess} className="pdf-document flex">
        {renderPages()}
      </Document>
    </div>
  );
}

export default UploadPDF;
