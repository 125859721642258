import React from 'react'
import DetailProducteUserMarket from './DetailProducteUserMarket'
function DetailProductorComponent() {
    return (
        <div className=' h-max w-full flex justify-start'>
            <DetailProducteUserMarket />
        </div>
    )
}

export default DetailProductorComponent