import { useEffect } from 'react';
import { toast } from 'react-toastify';
export const openChat = () => {
    if (window.Tawk_API) {
        window.Tawk_API.toggle();
    } else {
        toast.warning("veiller contacter BTR")
    }
};

const TawkTo = () => {
    useEffect(() => {
        const tawkToScript = document.createElement('script');
        tawkToScript.src = 'https://embed.tawk.to/{YOUR_TAWK_TO_WIDGET_ID}';
        tawkToScript.async = true;
        document.body.appendChild(tawkToScript);
        return () => {
            document.body.removeChild(tawkToScript);
        };
    }, []);
};

export default TawkTo;