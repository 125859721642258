import React from 'react'
import AjoutAdminSuperEchange from './AjoutAdminSuperEchange'
function AjoutAdminSuperEchangeComponent() {
    return (
        <>
            <AjoutAdminSuperEchange />
        </>
    )
}

export default AjoutAdminSuperEchangeComponent
