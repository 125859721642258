/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function ReservationVisitor() {
    const [imageLoading, SetImageLoading] = useState(true)
    const [imageLoading1, SetImageLoading1] = useState(true)
    const [imageLoading2, SetImageLoading2] = useState(true)
    const [imageLoading3, SetImageLoading3] = useState(true)
    const [imageLoading4, SetImageLoading4] = useState(true)
    const [imageLoading5, SetImageLoading5] = useState(true)
    const [imageLoading6, SetImageLoading6] = useState(true)
    const [imageLoading7, SetImageLoading7] = useState(true)
    const [imageLoading8, SetImageLoading8] = useState(true)
    const [imageLoading9, SetImageLoading9] = useState(true)

    const [sizeInfer, SetsizeInfer] = useState(window.innerWidth < 650)
    const [sizeInfer1, SetsizeInfer1] = useState(window.innerWidth < 650)
    const [sizeInfer2, SetsizeInfer2] = useState(window.innerWidth < 650)
    const [sizeInfer3, SetsizeInfer3] = useState(window.innerWidth < 650)
    const [sizeInfer4, SetsizeInfer4] = useState(window.innerWidth < 650)
    const [sizeInfer5, SetsizeInfer5] = useState(window.innerWidth < 650)
    const [sizeInfer6, SetsizeInfer6] = useState(window.innerWidth < 650)
    const [sizeInfer7, SetsizeInfer7] = useState(window.innerWidth < 650)
    const [sizeInfer8, SetsizeInfer8] = useState(window.innerWidth < 650)
    const [sizeInfer9, SetsizeInfer9] = useState(window.innerWidth < 650)

    const [titre, Settitre] = useState(window.innerWidth < 800.38383838282929)

    useEffect(() => {
        const hundleSize = () => {
            SetsizeInfer(window.innerWidth < 650)
            SetsizeInfer1(window.innerWidth < 650)
            SetsizeInfer2(window.innerWidth < 650)
            SetsizeInfer3(window.innerWidth < 650)
            SetsizeInfer4(window.innerWidth < 650)
            SetsizeInfer5(window.innerWidth < 650)
            SetsizeInfer6(window.innerWidth < 650)
            SetsizeInfer7(window.innerWidth < 650)
            SetsizeInfer8(window.innerWidth < 650)
            SetsizeInfer9(window.innerWidth < 650)

            Settitre(window.innerWidth < 800.38383838282929)


        }

        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    return (
        <>

            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}>  Bienvenue sur notre site web dédié  réservation en ligne</h2>

                    <div className={` ${imageLoading && sizeInfer ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading(false)
                            }, 1000)
                        }} src="../image/reservationenligne.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Bienvenue sur notre site web dédié  réservation en ligne</h2>
                        <p className="text-gray-500 p-1 text-[22px] mt-6 ">
                            <div className="reduitTailParagr text-gray-500">
                                Nous sommes déterminés à fournir une expérience d'apprentissage enrichissante et accessible à tous les utilisateurs, car nous croyons que chaque individu mérite la possibilité de se développer, indépendamment de ses origines ou de son niveau de compétence.
                            </div>
                        </p>
                    </div>
                </div>
            </div>


            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}>    Hotel et  Hebergement </h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Hotel et  Hebergement </h2>

                        <p className="text-gray-500 p-1 text-[22px] ">
                            <div className="reduitTailParagr text-gray-500">
                                <div className="text-gray-500 mt-6]">
                                    Que vous planifiez un voyage d'affaires, des vacances en famille ou une escapade romantique, vous êtes au bon endroit. Notre site vous offre un large choix d'hôtels dans différentes catégories, des hôtels de luxe aux options plus abordables, afin de répondre à tous les budgets et préférences.
                                    Naviguez à travers notre sélection d'hôtels et explorez les différentes fonctionnalités pour trouver l'option qui correspond le mieux à vos besoins. Nous avons veillé à vous offrir des descriptions détaillées, des photos et des avis d'autres voyageurs pour vous aider à prendre une décision éclairée.

                                </div>
                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading1 && sizeInfer1 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading1(false)
                            }, 1000)
                        }} src="../image/hotel_et_hebergement.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Rendez-vous médicaux</h2>

                    <div className={` ${imageLoading2 && sizeInfer2 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading2 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading2(false)
                            }, 1000)
                        }} src="../image/cabinet_professionel.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Rendez-vous médicaux</h2>
                        <p className="reduitTailParagr text-gray-500">
                            Si vous avez besoin de consulter un médecin,
                            vous êtes au bon endroit.
                            Notre plateforme vous permet
                            de prendre facilement rendez-vous avec
                            des professionnels de la santé dans différents
                            domaines. Que vous ayez besoin d'une consultation générale,
                            d'une visite chez un spécialiste ou d'un suivi médical régulier,
                            nous vous offrons un large choix de médecins qualifiés.
                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}>    La  beauté et au bien-être</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}>La  beauté et au bien-être </h2>

                        <p className="text-gray-500 p-1 text-[22px] ">
                            <div className="reduitTailParagr text-gray-500">
                                <div className="text-gray-500 mt-6]">
                                    Que vous cherchiez des conseils en soins de la peau, des astuces pour un maquillage parfait, des techniques de relaxation ou des recommandations pour votre bien-être général, vous êtes au bon endroit.
                                    Notre équipe d'experts est là pour vous guider, vous conseiller et vous offrir des services de qualité.
                                    Prenez le temps de découvrir nos différents services et produits qui vous aideront à vous sentir bien dans votre corps et votre esprit.

                                </div>
                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading3 && sizeInfer3 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading3 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading3(false)
                            }, 1000)
                        }} src="../image/BeautySalon.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>




            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Maintenance domicile</h2>

                    <div className={` ${imageLoading4 && sizeInfer4 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading4 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading4(false)
                            }, 1000)
                        }} src="../image/maintenancedomicile.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Maintenance domicile</h2>
                        <p className="reduitTailParagr text-gray-500">
                            Quel que soit votre besoin dans le domaine de la maintenance, nous sommes là pour vous aider. Que ce soit pour des travaux de plomberie, d'électricité, de menuiserie, de jardinage ou tout autre service lié à l'entretien de votre domicile, notre équipe de professionnels qualifiés est prête à intervenir chez vous.

                            Nous comprenons que votre temps est précieux, c'est pourquoi nous mettons tout en œuvre pour vous offrir un service de qualité, rapide et efficace.

                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>




            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}>   Salle   de conference et événement</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}>Salle   de conference et événement </h2>

                        <p className="text-gray-500 p-1 text-[22px] ">
                            <div className="reduitTailParagr text-gray-500">
                                <div className="text-gray-500 mt-6]">
                                    Que vous soyez un professionnel à la recherche d'un lieu pour organiser une conférence, une réunion d'affaires ou un séminaire, ou bien un particulier souhaitant célébrer un événement spécial tel qu'un mariage, un anniversaire ou une fête, vous êtes au bon endroit.

                                    Notre plateforme vous offre un large choix de salles de conférence et d'événements dans différents domaines, adaptés à toutes vos exigences. Que vous ayez besoin d'une salle spacieuse, équipée des dernières technologies, ou d'un lieu plus intime et chaleureux, nous avons ce qu'il vous faut

                                </div>
                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading5 && sizeInfer5 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading5 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading5(false)
                            }, 1000)
                        }} src="../image/salle_de_conference.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>




            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Sonolisation et club danse </h2>

                    <div className={` ${imageLoading6 && sizeInfer6 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading6 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading6(false)
                            }, 1000)
                        }} src="../image/sonorisationetclubdedance.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Sonolisation et club danse </h2>
                        <p className="reduitTailParagr text-gray-500">
                            Que vous soyez passionné de musique, amateur de danse ou simplement curieux de découvrir de nouvelles expériences, vous êtes au bon endroit. Notre club vous offre une atmosphère dynamique et festive où vous pourrez exprimer votre créativité et votre passion pour la musique et la danse.

                            Que vous soyez un DJ en herbe cherchant à perfectionner vos compétences de mixage, un danseur talentueux souhaitant explorer de nouveaux styles de danse, ou simplement quelqu'un qui aime profiter de l'ambiance envoûtante de la musique, notre club est l'endroit idéal pour vous.

                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>




            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}>  Décoration</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}>Décoration</h2>

                        <p className="text-gray-500 p-1 text-[22px] ">
                            <div className="reduitTailParagr text-gray-500">
                                <div className="text-gray-500 mt-6]">
                                    Que vous recherchiez des idées pour embellir votre maison, votre bureau, votre jardin ou tout autre espace, vous êtes au bon endroit. Notre équipe passionnée de décoration a rassemblé une sélection inspirante d'idées, de conseils et de tendances pour vous aider à créer des environnements magnifiques et harmonieux.
                                    Nous espérons que notre site vous inspirera et vous aidera à créer des espaces uniques qui reflètent votre personnalité et votre style. Que vous soyez un amateur de décoration expérimenté ou que vous débutiez dans ce domaine, notre objectif est de vous guider et de vous inspirer tout au long de votre parcours de décoration.
                                    Profitez de votre visite et laissez libre cours à votre créativité pour donner une nouvelle vie à vos espaces grâce à la décoration !
                                    Bien cordialement
                                </div>
                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading8 && sizeInfer8 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading8 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading8(false)
                            }, 1000)
                        }} src="../image/decoration.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Cameraman</h2>

                    <div className={` ${imageLoading7 && sizeInfer7 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading7 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading7(false)
                            }, 1000)
                        }} src="../image/cameraman.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Cameraman </h2>
                        <p className="reduitTailParagr text-gray-500">
                            Que vous ayez besoin d'un caméraman pour un mariage, un événement d'entreprise, une séance photo ou tout autre projet, vous êtes au bon endroit. Nous sommes fiers de vous proposer une équipe talentueuse de caméramans expérimentés, prêts à capturer vos moments les plus précieux avec créativité et professionnalisme.
                            Que vous recherchiez des prises de vue cinématographiques, des vidéos promotionnelles percutantes ou des images exceptionnelles, nos caméramans sont là pour répondre à vos besoins. Ils sont équipés des dernières technologies et possèdent une solide expertise dans le domaine de la capture vidéo.
                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}>  Restaurant</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}>Restaurant</h2>

                        <p className="text-gray-500 p-1 text-[22px] ">
                            <div className="reduitTailParagr text-gray-500">
                                <div className="text-gray-500 mt-6]">
                                    Que vous soyez amateur de cuisine italienne, fan de cuisine asiatique ou passionné de gastronomie française, vous trouverez ici une sélection diversifiée de restaurants pour satisfaire toutes vos envies. Découvrez nos établissements partenaires qui proposent une large gamme de plats délicieux, des ambiances chaleureuses et un service de qualité.
                                </div>
                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading9 && sizeInfer9 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading9 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading9(false)
                            }, 1000)
                        }} src="../image/restaurant.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>
        </>
    )
}

export default ReservationVisitor

