/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import NavBarsVisitorMobile from './NavBarsVisitorMobile'

function NavBarsVisitor({ isdivScroll, LoginPopUp, SetLoginPopUp, SetSignPopUp, getSrollowerSize, getMobille1 }) {
    const { pathname } = useLocation()
    const [menuClicked1, setMenuClicked1] = useState(false)
    const [BouttonLogin, setBouttonLogin] = useState(false)
    const [isMobile, SetMobile] = useState(window.innerWidth < 570.97364448849393)
    const [isMobileTros, SetMobileTros] = useState(window.innerWidth < 292.97364448849393)
    const [tropTail, SettropTail] = useState(window.innerWidth < 436.97364448849393)
    const [mobilleMenu, SetMobileMenu] = useState(window.innerWidth < 978.97364448849393)
    const [mobilleName, SetMobileName] = useState(window.innerWidth < 618.97364448849393)


    const [imageLoading, SetImageLoading] = useState(true)


    const Education = /^\/Visit\/Education.*/
    const Marche = /^\/Visit\/Marche.*/

    getMobille1(isMobile)


    useEffect(() => {
        const hiddenOnget = () => {
            setMenuClicked1(false)
            setBouttonLogin(false)

        };

        function hundleScrolls(e) {
            setMenuClicked1(false)
            setBouttonLogin(false)
            getSrollowerSize(e.target.scrollTop)

        }
        const hiddenresize = () => {
            SetMobileMenu(window.innerWidth < 978.97364448849393)
            SetMobile(window.innerWidth < 570.97364448849393)
            SettropTail(window.innerWidth < 436.97364448849393)
            SetMobileName(window.innerWidth < 618.97364448849393)
            SetMobileTros(window.innerWidth < 292.97364448849393)
        }

        window.addEventListener('resize', hiddenresize);
        window.addEventListener('click', hiddenOnget);
        if (isdivScroll.current) {
            isdivScroll.current.addEventListener('scroll', hundleScrolls);

        }

        return () => {
            if (isdivScroll.current) {
                isdivScroll.current.removeEventListener('scroll', hundleScrolls);
            }
            window.removeEventListener('click', hiddenOnget);
            window.removeEventListener('resize', hiddenresize);


        }

    }, [])

    function VersHauts() {
        isdivScroll.current.scrollTop = 0
    }

    return (
        <>
            {/* equipement grande taille */}
            <div className={`bg-gray-100 ${mobilleMenu ? 'h-[13vh] min-h-[70px]' : ' h-[20vh]  min-h-[127px]'} relative rounded-b-2xl overflow-hidden border-b-2 border-blue-500`}>
                <div className={` ${mobilleMenu ? 'h-full ' : 'h-[10vh] min-h-[62px] '} flex justify-between px-4 w-full bg-transparent border-b border-gray-200`}>
                    <Link to='/' className="flex items-center w-max ">
                        <div className={`h-[4em] w-[4em] transition-all ${tropTail ? 'ml-0' : 'ml-3'}  relative overflow-hidden  rounded `}>
                            {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                            <img draggable='false' onLoad={() => {
                                setTimeout(() => {
                                    SetImageLoading(false)
                                }, 1000)
                            }} src={` https://btrproject.burundientempsreel.com/uploads/logo/btr.png`} className='h-full w-full object-cover object-center' />
                        </div>
                        <div className=" font-serif text-[20px] ml-2  transition-all ">
                            <span style={{ fontFamily: 'Monotype Corsiva' }} className={`text-[30px] ${mobilleName ? '' : 'hidden'} `}>
                                <span className='text-green-600 '>B</span><span><sup className='text-red-600'>T</sup></span><span className='text-white'>R</span></span>
                            <span className={`${mobilleName ? 'hidden' : ''}`}>
                                <span style={{ fontFamily: 'Monotype Corsiva' }} className='text-[25px] text-green-700'>B</span>urundi en <span style={{ fontFamily: 'Monotype Corsiva' }} className='text-[25px] text-red-700'>T</span>emps <span style={{ fontFamily: 'Monotype Corsiva' }} className='text-[25px] text-white'>R</span>éel</span></div>
                    </Link>


                    <div className='flex items-center'>
                        {tropTail ? (
                            <div onClick={(e) => { setBouttonLogin(!BouttonLogin); e.stopPropagation() }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-fill-add mr-2 cursor-pointer  w-[30px] h-[30px]" viewBox="0 0 16 16">
                                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                                </svg>
                                <div className={`absolute bg-white ${BouttonLogin ? 'opacity-100 pointer-events-auto' : 'pointer-events-none opacity-0'} duration-500 transition-all flex py-1 px-2 top-1/4 h-max z-[100] rounded shadow-2xl w-max right-1    flex-col justify-center items-center  text-[12px]`}>
                                    <div className=" w-[100%] h-full flex justify-end items-center">
                                        <div onClick={(e) => { SetLoginPopUp(true); e.stopPropagation() }} className="text-[15px] mx-1 px-2 py-1 rounded cursor-pointer bg-gray-200  transition-all">Se connecter</div>
                                        <div onClick={(e) => { SetSignPopUp(true); e.stopPropagation() }} className="text-[15px] mx-1 bg-blue-600 text-white px-2 py-1 rounded cursor-pointer hover:bg-blue-600 transition-all">S'inscrire</div>
                                    </div>
                                </div>
                            </div>
                        ) : (<>
                            <div className="h-full   flex  flex-col justify-center items-center  text-[12px] ">
                                <div className=" w-[100%] h-full flex justify-end items-center">
                                    <div onClick={(e) => { SetLoginPopUp(true); e.stopPropagation() }} className="text-[15px] mx-1 px-2 py-1 rounded cursor-pointer  transition-all">Se connecter</div>
                                    <div onClick={(e) => { SetSignPopUp(true); e.stopPropagation() }} className="text-[15px] mx-1 bg-blue-600 text-white px-2 py-1 rounded cursor-pointer hover:bg-blue-600 transition-all">S'inscrire</div>
                                </div>
                            </div>
                        </>
                        )}



                        {mobilleMenu && <div onClick={(e) => { setMenuClicked1(!menuClicked1); setBouttonLogin(false); e.stopPropagation() }} className={`z-[60]  cursor-pointer ${tropTail ? 'mx-0' : 'mx-4'}     rounded-tl-lg `}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="hover:bg-blue-700 transition-all hover:text-white  rounded-md" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                            </svg>
                        </div>}
                    </div>
                </div>


                {mobilleMenu ? (
                    <>
                        {/* equipement petite taille */}
                        <NavBarsVisitorMobile menuClicked1={menuClicked1} tropTail={tropTail} />

                    </>
                ) : (
                    <>
                        <div className='h-[10vh] min-h-[62px] w-full bg-transparent'>
                            <div className={`w-[100%]  h-full flex justify-around items-end  text-[12px]`}>
                                <div className="  flex  w-[100%] ">
                                    {pathname === "/Home" || pathname === "/" ? (
                                        <Link to="/" className='  separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="120" fill="currentColor" class="bi bi-house-door-fill" viewBox="0 0 16 16">
                                                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                                            </svg>
                                            <p className='sizeText'>Acceuil</p>
                                        </Link>) : (
                                        <Link to="/" onClick={VersHauts} className='  separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-house-door-fill" viewBox="0 0 16 16">
                                                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                                            </svg>
                                            <p className='sizeText'>Acceuil</p>
                                        </Link>)
                                    }
                                    {pathname === "/Visit/Exchange" || pathname === "/Visit/connect" || pathname === "/Visit/s'inscrire" || pathname === "/Visit/Exchange/connected" ? (
                                        <Link to="/Visit/Exchange" className='  separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
                                                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                            </svg>
                                            <p className='sizeText'>Échange</p>
                                        </Link>
                                    ) : (
                                        <Link to="/Visit/Exchange" onClick={VersHauts} className='separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
                                                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                            </svg>
                                            <p className='sizeText'>Échange</p>
                                        </Link>
                                    )}
                                    {Marche.test(pathname) ? (
                                        <a href='https://onex-s.com/' target='_blank' className='  separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-shop" viewBox="0 0 16 16">
                                                <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
                                            </svg>
                                            <p className='sizeText flex flex-nowrap ml-3'>Marché</p>
                                        </a>

                                    ) : (
                                        <a href='https://onex-s.com/' onClick={VersHauts} target='_blank' className='separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-shop" viewBox="0 0 16 16">
                                                <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
                                            </svg>
                                            <p className='sizeText flex flex-nowrap ml-3'>Marché</p>
                                        </a>
                                    )}

                                    {Education.test(pathname) ? (

                                        <Link to="/Visit/Education" className='  separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-book-half" viewBox="0 0 16 16">
                                                <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                                            </svg>
                                            <p className='sizeText'>Éducation</p>
                                        </Link>
                                    ) : (

                                        <Link onClick={VersHauts} to="/Visit/Education" className='  separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-book-half" viewBox="0 0 16 16">
                                                <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                                            </svg>
                                            <p className='sizeText'>Éducation</p>
                                        </Link>
                                    )

                                    }

                                    {pathname === "/Visit/Reservation" ? (
                                        <Link to="/Visit/Reservation" className='  separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-basket2-fill" viewBox="0 0 16 16">
                                                <path d="M5.929 1.757a.5.5 0 1 0-.858-.514L2.217 6H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h.623l1.844 6.456A.75.75 0 0 0 3.69 15h8.622a.75.75 0 0 0 .722-.544L14.877 8h.623a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1.717L10.93 1.243a.5.5 0 1 0-.858.514L12.617 6H3.383L5.93 1.757zM4 10a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm3 0a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm4-1a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1z" />
                                            </svg>
                                            <p className='sizeText flex flex-nowrap ml-3'>Reservation</p>
                                        </Link>
                                    ) : (
                                        <Link onClick={VersHauts} to="/Visit/Reservation" className='separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-basket2-fill" viewBox="0 0 16 16">
                                                <path d="M5.929 1.757a.5.5 0 1 0-.858-.514L2.217 6H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h.623l1.844 6.456A.75.75 0 0 0 3.69 15h8.622a.75.75 0 0 0 .722-.544L14.877 8h.623a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1.717L10.93 1.243a.5.5 0 1 0-.858.514L12.617 6H3.383L5.93 1.757zM4 10a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm3 0a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm4-1a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1z" />
                                            </svg>
                                            <p className='sizeText flex flex-nowrap ml-3'>Reservation</p>
                                        </Link>
                                    )}
                                    {pathname === "/Visit/Loisir" ? (
                                        <Link to="/Visit/Loisir" className='  separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-universal-access-circle" viewBox="0 0 16 16">
                                                <path d="M8 4.143A1.071 1.071 0 1 0 8 2a1.071 1.071 0 0 0 0 2.143Zm-4.668 1.47 3.24.316v2.5l-.323 4.585A.383.383 0 0 0 7 13.14l.826-4.017c.045-.18.301-.18.346 0L9 13.139a.383.383 0 0 0 .752-.125L9.43 8.43v-2.5l3.239-.316a.38.38 0 0 0-.047-.756H3.379a.38.38 0 0 0-.047.756Z" />
                                                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Z" />
                                            </svg>
                                            <p className='sizeText'>Loisir</p>
                                        </Link>
                                    ) : (
                                        <Link to="/Visit/Loisir" onClick={VersHauts} className='separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-universal-access-circle" viewBox="0 0 16 16">
                                                <path d="M8 4.143A1.071 1.071 0 1 0 8 2a1.071 1.071 0 0 0 0 2.143Zm-4.668 1.47 3.24.316v2.5l-.323 4.585A.383.383 0 0 0 7 13.14l.826-4.017c.045-.18.301-.18.346 0L9 13.139a.383.383 0 0 0 .752-.125L9.43 8.43v-2.5l3.239-.316a.38.38 0 0 0-.047-.756H3.379a.38.38 0 0 0-.047.756Z" />
                                                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Z" />
                                            </svg>
                                            <p className='sizeText'>Loisir</p>
                                        </Link>
                                    )}
                                    {pathname === "/Visit/Sante" ? (
                                        <Link to="/Visit/Sante" className='  separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hospital-fill" viewBox="0 0 16 16">
                                                <path d="M6 0a1 1 0 0 0-1 1v1a1 1 0 0 0-1 1v4H1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h6v-2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V16h6a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-3V3a1 1 0 0 0-1-1V1a1 1 0 0 0-1-1H6Zm2.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1h1ZM2.25 9h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 2 9.75v-.5A.25.25 0 0 1 2.25 9Zm0 2h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5a.25.25 0 0 1 .25-.25ZM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5ZM13.25 9h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5a.25.25 0 0 1 .25-.25ZM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Zm.25 1.75h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5a.25.25 0 0 1 .25-.25Z" />
                                            </svg>
                                            <p className='sizeText'>Sante</p>
                                        </Link>
                                    ) : (
                                        <Link to="/Visit/Sante" onClick={VersHauts} className='separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hospital-fill" viewBox="0 0 16 16">
                                                <path d="M6 0a1 1 0 0 0-1 1v1a1 1 0 0 0-1 1v4H1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h6v-2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5V16h6a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-3V3a1 1 0 0 0-1-1V1a1 1 0 0 0-1-1H6Zm2.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1h1ZM2.25 9h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 2 9.75v-.5A.25.25 0 0 1 2.25 9Zm0 2h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5a.25.25 0 0 1 .25-.25ZM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5ZM13.25 9h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5a.25.25 0 0 1 .25-.25ZM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Zm.25 1.75h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5a.25.25 0 0 1 .25-.25Z" />
                                            </svg>
                                            <p className='sizeText'>Sante</p>
                                        </Link>
                                    )}
                                    {pathname === "/Visit/Programmation" ? (
                                        <Link to="/Visit/Programmation" className='  separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-braces-asterisk" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1.114 8.063V7.9c1.005-.102 1.497-.615 1.497-1.6V4.503c0-1.094.39-1.538 1.354-1.538h.273V2h-.376C2.25 2 1.49 2.759 1.49 4.352v1.524c0 1.094-.376 1.456-1.49 1.456v1.299c1.114 0 1.49.362 1.49 1.456v1.524c0 1.593.759 2.352 2.372 2.352h.376v-.964h-.273c-.964 0-1.354-.444-1.354-1.538V9.663c0-.984-.492-1.497-1.497-1.6ZM14.886 7.9v.164c-1.005.103-1.497.616-1.497 1.6v1.798c0 1.094-.39 1.538-1.354 1.538h-.273v.964h.376c1.613 0 2.372-.759 2.372-2.352v-1.524c0-1.094.376-1.456 1.49-1.456v-1.3c-1.114 0-1.49-.362-1.49-1.456V4.352C14.51 2.759 13.75 2 12.138 2h-.376v.964h.273c.964 0 1.354.444 1.354 1.538V6.3c0 .984.492 1.497 1.497 1.6ZM7.5 11.5V9.207l-1.621 1.621-.707-.707L6.792 8.5H4.5v-1h2.293L5.172 5.879l.707-.707L7.5 6.792V4.5h1v2.293l1.621-1.621.707.707L9.208 7.5H11.5v1H9.207l1.621 1.621-.707.707L8.5 9.208V11.5h-1Z" />
                                            </svg>
                                            <p className='sizeText'>Programmation</p>
                                        </Link>
                                    ) : (
                                        <Link to="/Visit/Programmation" onClick={VersHauts} className='separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-braces-asterisk" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1.114 8.063V7.9c1.005-.102 1.497-.615 1.497-1.6V4.503c0-1.094.39-1.538 1.354-1.538h.273V2h-.376C2.25 2 1.49 2.759 1.49 4.352v1.524c0 1.094-.376 1.456-1.49 1.456v1.299c1.114 0 1.49.362 1.49 1.456v1.524c0 1.593.759 2.352 2.372 2.352h.376v-.964h-.273c-.964 0-1.354-.444-1.354-1.538V9.663c0-.984-.492-1.497-1.497-1.6ZM14.886 7.9v.164c-1.005.103-1.497.616-1.497 1.6v1.798c0 1.094-.39 1.538-1.354 1.538h-.273v.964h.376c1.613 0 2.372-.759 2.372-2.352v-1.524c0-1.094.376-1.456 1.49-1.456v-1.3c-1.114 0-1.49-.362-1.49-1.456V4.352C14.51 2.759 13.75 2 12.138 2h-.376v.964h.273c.964 0 1.354.444 1.354 1.538V6.3c0 .984.492 1.497 1.497 1.6ZM7.5 11.5V9.207l-1.621 1.621-.707-.707L6.792 8.5H4.5v-1h2.293L5.172 5.879l.707-.707L7.5 6.792V4.5h1v2.293l1.621-1.621.707.707L9.208 7.5H11.5v1H9.207l1.621 1.621-.707.707L8.5 9.208V11.5h-1Z" />
                                            </svg>
                                            <p className='sizeText'>Programmation</p>
                                        </Link>
                                    )}

                                    {pathname === "/Visit/Transport" ? (
                                        <Link to="/Visit/Transport" className='  separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-airplane-engines rotate-45" viewBox="0 0 16 16">
                                                <path d="M8 0c-.787 0-1.292.592-1.572 1.151A4.347 4.347 0 0 0 6 3v3.691l-2 1V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.191l-1.17.585A1.5 1.5 0 0 0 0 10.618V12a.5.5 0 0 0 .582.493l1.631-.272.313.937a.5.5 0 0 0 .948 0l.405-1.214 2.21-.369.375 2.253-1.318 1.318A.5.5 0 0 0 5.5 16h5a.5.5 0 0 0 .354-.854l-1.318-1.318.375-2.253 2.21.369.405 1.214a.5.5 0 0 0 .948 0l.313-.937 1.63.272A.5.5 0 0 0 16 12v-1.382a1.5 1.5 0 0 0-.83-1.342L14 8.691V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.191l-2-1V3c0-.568-.14-1.271-.428-1.849C9.292.591 8.787 0 8 0ZM7 3c0-.432.11-.979.322-1.401C7.542 1.159 7.787 1 8 1c.213 0 .458.158.678.599C8.889 2.02 9 2.569 9 3v4a.5.5 0 0 0 .276.447l5.448 2.724a.5.5 0 0 1 .276.447v.792l-5.418-.903a.5.5 0 0 0-.575.41l-.5 3a.5.5 0 0 0 .14.437l.646.646H6.707l.647-.646a.5.5 0 0 0 .14-.436l-.5-3a.5.5 0 0 0-.576-.411L1 11.41v-.792a.5.5 0 0 1 .276-.447l5.448-2.724A.5.5 0 0 0 7 7V3Z" />
                                            </svg>
                                            <p className='sizeText'>Transport</p>
                                        </Link>
                                    ) : (
                                        <Link to="/Visit/Transport" onClick={VersHauts} className='separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-airplane-engines rotate-45" viewBox="0 0 16 16">
                                                <path d="M8 0c-.787 0-1.292.592-1.572 1.151A4.347 4.347 0 0 0 6 3v3.691l-2 1V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.191l-1.17.585A1.5 1.5 0 0 0 0 10.618V12a.5.5 0 0 0 .582.493l1.631-.272.313.937a.5.5 0 0 0 .948 0l.405-1.214 2.21-.369.375 2.253-1.318 1.318A.5.5 0 0 0 5.5 16h5a.5.5 0 0 0 .354-.854l-1.318-1.318.375-2.253 2.21.369.405 1.214a.5.5 0 0 0 .948 0l.313-.937 1.63.272A.5.5 0 0 0 16 12v-1.382a1.5 1.5 0 0 0-.83-1.342L14 8.691V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.191l-2-1V3c0-.568-.14-1.271-.428-1.849C9.292.591 8.787 0 8 0ZM7 3c0-.432.11-.979.322-1.401C7.542 1.159 7.787 1 8 1c.213 0 .458.158.678.599C8.889 2.02 9 2.569 9 3v4a.5.5 0 0 0 .276.447l5.448 2.724a.5.5 0 0 1 .276.447v.792l-5.418-.903a.5.5 0 0 0-.575.41l-.5 3a.5.5 0 0 0 .14.437l.646.646H6.707l.647-.646a.5.5 0 0 0 .14-.436l-.5-3a.5.5 0 0 0-.576-.411L1 11.41v-.792a.5.5 0 0 1 .276-.447l5.448-2.724A.5.5 0 0 0 7 7V3Z" />
                                            </svg>
                                            <p className='sizeText'>Transport</p>
                                        </Link>
                                    )}

                                    {pathname === "/Visit/Mentors" ? (
                                        <Link to="/Visit/Mentors" className='  separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                                            </svg>
                                            <p className='sizeText'>Mentors</p>
                                        </Link>
                                    ) : (
                                        <Link to="/Visit/Mentors" onClick={VersHauts} className='separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                                            </svg>
                                            <p className='sizeText'>Mentors</p>
                                        </Link>
                                    )}
                                    {pathname === "/Visit/Agri_Ele" ? (
                                        <Link to="/Visit/Agri_Ele" title='Agriculture et elevage' className='  separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-bug-fill" viewBox="0 0 16 16">
                                                <path d="M4.978.855a.5.5 0 1 0-.956.29l.41 1.352A4.985 4.985 0 0 0 3 6h10a4.985 4.985 0 0 0-1.432-3.503l.41-1.352a.5.5 0 1 0-.956-.29l-.291.956A4.978 4.978 0 0 0 8 1a4.979 4.979 0 0 0-2.731.811l-.29-.956z" />
                                                <path d="M13 6v1H8.5v8.975A5 5 0 0 0 13 11h.5a.5.5 0 0 1 .5.5v.5a.5.5 0 1 0 1 0v-.5a1.5 1.5 0 0 0-1.5-1.5H13V9h1.5a.5.5 0 0 0 0-1H13V7h.5A1.5 1.5 0 0 0 15 5.5V5a.5.5 0 0 0-1 0v.5a.5.5 0 0 1-.5.5H13zm-5.5 9.975V7H3V6h-.5a.5.5 0 0 1-.5-.5V5a.5.5 0 0 0-1 0v.5A1.5 1.5 0 0 0 2.5 7H3v1H1.5a.5.5 0 0 0 0 1H3v1h-.5A1.5 1.5 0 0 0 1 11.5v.5a.5.5 0 1 0 1 0v-.5a.5.5 0 0 1 .5-.5H3a5 5 0 0 0 4.5 4.975z" />
                                            </svg>
                                            <p className='sizeText'> Agri & Elev</p>
                                        </Link>
                                    ) : (
                                        <Link onClick={VersHauts} to="/Visit/Agri_Ele" title='Agriculture et elevage' className='separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-bug-fill" viewBox="0 0 16 16">
                                                <path d="M4.978.855a.5.5 0 1 0-.956.29l.41 1.352A4.985 4.985 0 0 0 3 6h10a4.985 4.985 0 0 0-1.432-3.503l.41-1.352a.5.5 0 1 0-.956-.29l-.291.956A4.978 4.978 0 0 0 8 1a4.979 4.979 0 0 0-2.731.811l-.29-.956z" />
                                                <path d="M13 6v1H8.5v8.975A5 5 0 0 0 13 11h.5a.5.5 0 0 1 .5.5v.5a.5.5 0 1 0 1 0v-.5a1.5 1.5 0 0 0-1.5-1.5H13V9h1.5a.5.5 0 0 0 0-1H13V7h.5A1.5 1.5 0 0 0 15 5.5V5a.5.5 0 0 0-1 0v.5a.5.5 0 0 1-.5.5H13zm-5.5 9.975V7H3V6h-.5a.5.5 0 0 1-.5-.5V5a.5.5 0 0 0-1 0v.5A1.5 1.5 0 0 0 2.5 7H3v1H1.5a.5.5 0 0 0 0 1H3v1h-.5A1.5 1.5 0 0 0 1 11.5v.5a.5.5 0 1 0 1 0v-.5a.5.5 0 0 1 .5-.5H3a5 5 0 0 0 4.5 4.975z" />
                                            </svg>
                                            <p className='sizeText'> Agri & Elev</p>
                                        </Link>
                                    )}

                                    {pathname === "/Visit/Contact" ? (
                                        <Link to="/Visit/Contact" title='Agriculture et elevage' className='  separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-rolodex" viewBox="0 0 16 16">
                                                <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                                                <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
                                            </svg>
                                            <p className='sizeText'> Contact</p>
                                        </Link>
                                    ) : (
                                        <Link to="/Visit/Contact" onClick={VersHauts} title='Agriculture et elevage' className='separeIcons mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-rolodex" viewBox="0 0 16 16">
                                                <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                                                <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
                                            </svg>
                                            <p className='sizeText'> Contact</p>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>)}
            </div >



        </>
    )
}

export default NavBarsVisitor
