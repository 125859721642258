/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import { pdfjs } from 'react-pdf';
import { ToastContainer } from 'react-toastify';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css/animate.min.css';

import ChampCofirmation from './component/Visitor/Auteur/ChampCofirmation';
import NavBarsVisitor from './component/Visitor/NavBarsVisitors/NavBarsVisitor';
import NavbarVisitorLogged from './component/Visitor/NavBarsVisitors/NavbarVisitorLogged';
import FooterVisitor from './component/Visitor/FooterVisitor/FooterVisitor';
import CopyRight from './component/Visitor/FooterVisitor/CopyRight';
import Seconnecter from './component/Visitor/Connecter/Seconnecter';
import Sinscrire from './component/Visitor/Sinscrire/Sinscrire';
import PageNotFind from './component/PageNotFind/PageNotFind';
import SinscrireMarcheUser from './component/Visitor/Auteur/SinscrireMarcheUser';
import SeconnectMArcheUser from './component/Visitor/Auteur/SeconnectMArcheUser';
import ProfilePageVisitor from './page/VisitorPage/MarcheVisitorPage/ProfilePageVisitor';
import SeconnectAdminiEchange from './component/Visitor/SeconnectAdminiEchange/SeconnectAdminiEchange';

// pour visiteur Cites
import HomeVisitorPage from './page/VisitorPage/HomeVisitorPage';


// pour visiteur echange
import VisitorExhangeHomePage from './page/VisitorPage/VisitorExchange/VisitorExhangeHomePage';


// pour visiteur school
import HomeVisitorSchoolPage from './page/VisitorPage/SchoolVisitorPage/HomeVisitorSchoolPage';
import SchoolLivresVisitorDetail from './component/Visitor/School/SchoolLivresVisitor/SchoolLivresVisitorDetail';
import NavBarsSchoolLivresVisitor from './component/Visitor/School/SchoolLivresVisitor/NavBarsSchoolLivresVisitor';
import AcceuilSchoolLivresVisitorPage from './page/VisitorPage/SchoolVisitorPage/AcceuilSchoolLivresVisitorPage';
import EdicationLivreVisitorPage from './page/VisitorPage/SchoolVisitorPage/EdicationLivreVisitorPage';
import EdicationLivreVisitorDetailPage from './page/VisitorPage/SchoolVisitorPage/EdicationLivreVisitorDetailPage';
import EdicationConnaissVisitorPape from './page/VisitorPage/SchoolVisitorPage/EdicationConnaissVisitorPape';
import EdicationConnaissVisitorDetailPage from './page/VisitorPage/SchoolVisitorPage/EdicationConnaissVisitorDetailPage';
import EdicationConnaissVisitorPrimDetailPage from './page/VisitorPage/SchoolVisitorPage/EdicationConnaissVisitorPrimDetailPage';
import LirePdfLivreVisitorSchoolPage from './page/VisitorPage/SchoolVisitorPage/LirePdfLivreVisitorSchoolPage';
import SchoolCoursNavBars from './component/Visitor/School/SchoolCoursVisitor/SchoolCoursNavBars';
import SchoolCoursVisitorPage from './page/VisitorPage/SchoolVisitorPage/SchoolCoursVisitorPage';

//pour le visiteur Loisir
import LosirVisitorPage from './page/VisitorPage/LosirVisitorPage/LosirVisitorPage';


//pour la sante
import SanteHomeVisitorPage from './page/VisitorPage/SanteVisitorPage/SanteHomeVisitorPage';


//pour la programmation
import ProgrammationVisitorPage from './page/VisitorPage/ProgrammationVisitorPage/ProgrammationVisitorPage';



//pour le transport
import TransoporVisitorPage from './page/VisitorPage/TransoporVisitorPage/TransoporVisitorPage';













//pour la marche visitor
import MarcheVisitorPage from './page/VisitorPage/MarcheVisitorPage/MarcheVisitorPage';
import NavBarsMarcheVisitor from './component/Visitor/Marche/NavBarsMarcheVisitor/NavBarsMarcheVisitor';
import ProductDetailMarcheVisitorPage from './page/VisitorPage/MarcheVisitorPage/ProductDetailMarcheVisitorPage';
import MyordersPage from './page/VisitorPage/MarcheVisitorPage/MyordersPage';
import OrderDetailPage from './page/VisitorPage/MarcheVisitorPage/OrderDetailPage';
import AllCartsPage from './page/VisitorPage/MarcheVisitorPage/AllCartsPage';
import PaymentMarcheVisitorPage from './page/VisitorPage/MarcheVisitorPage/PaymentMarcheVisitorPage';
import InscriptionSellerMarche from './component/Visitor/InscriptionSellerMarche/InscriptionSellerMarche';


//pour la reservation
import ReservationVisitorPage from './page/VisitorPage/ReservationVisitorPage/ReservationVisitorPage';

import LogImage from './component/LogImage/LogImage';
//pour les mentor
import MentorsVisitorsPage from './page/VisitorPage/MentorsVisitorsPage/MentorsVisitorsPage';

//pour agri et ele
import Agri_ElevVisitorHomePage from './page/VisitorPage/Agri_ElevVisitorPage/Agri_ElevVisitorHomePage';



import ContactVisitorPage from './page/VisitorPage/ContactVisitorPage/ContactVisitorPage';


// pour utilisateur echange

import NavBarsUsersEchangee from './component/User/EchangeUser/NavBarsUsersEchangee/NavBarsUsersEchangee';
import MotAcceuilUserEchangePage from './page/UserPage/EchangeUserPage/MotAcceuilUserEchangePage';
import AfficheCommandeUserEchangePage from './page/UserPage/EchangeUserPage/AfficheCommandeUserEchangePage';
import NewCommandeUserEchangePAge from './page/UserPage/EchangeUserPage/NewCommandeUserEchangePAge';
import DetailCommandeUserEchangePage from './page/UserPage/EchangeUserPage/DetailCommandeUserEchangePage';
import CompteUserPage from './page/UserPage/EchangeUserPage/CompteUserPage';
import HelpPage from './page/UserPage/EchangeUserPage/HelpPage';
import EnregistrerMonnaiePage from './page/UserPage/EchangeUserPage/EnregistrerMonnaiePage';
import FraistransactionPage from './page/UserPage/EchangeUserPage/FraistransactionPage';


// pour utilisateur Marche
import NavBarMarketUsers from './component/User/UserMarket/UserNavBar/NavBarMarketUsers';
import AcceuilMarcheUserPage from './page/UserPage/MarcheUserPage/AcceuilMarcheUserPage';
import DetteEnregistr from './component/User/UserMarket/DetteMarcheUser/DetteEnregistr'
import ModifierVenteMarcheUserPage from './page/UserPage/MarcheUserPage/ModifierVenteMarcheUserPage';
import DetteMarcheUserPage from './page/UserPage/MarcheUserPage/DetteMarcheUserPage';
import DetteMarcheUserDetaillPage from './page/UserPage/MarcheUserPage/DetteMarcheUserDetaillPage';
import DepenseMarcheUserPage from './page/UserPage/MarcheUserPage/DepenseMarcheUserPage';
import DepenseMarcheUserDetaillPage from './page/UserPage/MarcheUserPage/DepenseMarcheUserDetaillPage';
import NewDepenesMarcheUserPage from './page/UserPage/MarcheUserPage/NewDepenesMarcheUserPage';
import ModifDepenesMarcheUserPage from './page/UserPage/MarcheUserPage/ModifDepenesMarcheUserPage';
import MarketBonusPage from './page/UserPage/MarcheUserPage/MarketBonusPage';
import ProductBonusDetaillPage from './page/UserPage/MarcheUserPage/ProductBonusDetaillPage';
import NewBonusMarcheUserPage from './page/UserPage/MarcheUserPage/NewBonusMarcheUserPage';
import ModifBonusMarcheUserPage from './page/UserPage/MarcheUserPage/ModifBonusMarcheUserPage';
import ActionJoursPage from './page/UserPage/MarcheUserPage/ActionJoursPage';
import ChoixEnreg from './component/User/UserMarket/Marche/PaymentSeller/ChoixEnreg';
import ListClientUserSellerPage from './page/UserPage/MarcheUserPage/ListClientUserSellerPage';
import CartsBonusPage from './page/UserPage/MarcheUserPage/CartsBonusPage';
import DashBoardUserMarchePage from './page/UserPage/MarcheUserPage/DashBoardUserMarchePage';
import RegisterProductMarcheUserPage from './page/UserPage/MarcheUserPage/RegisterProductMarcheUserPage';
import ManageOrderMarketPage from './page/UserPage/MarcheUserPage/ManageOrderMarketPage';
import UpdateProdMacheUserPage from './page/UserPage/MarcheUserPage/UpdateProdMacheUserPage';
import UpdatePhotoMarcheUserPage from './page/UserPage/MarcheUserPage/UpdatePhotoMarcheUserPage';
import DetailProductorMarcheUserPage from './page/UserPage/MarcheUserPage/DetailProductorMarcheUserPage';
import CompteMarcheUsePage from './page/UserPage/MarcheUserPage/CompteMarcheUsePage';
import OrderDetailUserMarcheurPage from './page/UserPage/MarcheUserPage/OrderDetailUserMarcheurPage';
import ClientBonusPage from './page/UserPage/MarcheUserPage/ClientBonusPage';
import NewClientUserSellerPage from './page/UserPage/MarcheUserPage/NewClientUserSellerPage';
import VenteSellerPage from './page/UserPage/MarcheUserPage/VenteSellerPage';
import ProductSellerDetaillPage from './page/UserPage/MarcheUserPage/ProductSellerDetaillPage';
import AllCartsSellerPage from './page/UserPage/MarcheUserPage/AllCartsSellerPage';
import PaymentSellerPage from './page/UserPage/MarcheUserPage/PaymentSellerPage';
import ModifierEchangePage from './page/UserPage/EchangeUserPage/ModifierEchangePage';
import AjoutertFraistransactionAdmiPage from './page/AdminPage/EchangeAdminPage/AjoutertFraistransactionAdmiPage';
import ModifierFraistransactionAdmiPage from './page/AdminPage/EchangeAdminPage/ModifierFraistransactionAdmiPage';

// pour admin echange
import SinscrireAdmin from './component/Visitor/SinscrireAdmin/SinscrireAdmin';
import NavBarsEchangeAdmin from './component/Admin/EchangeAdmin/NavBarsEchangeAdmin/NavBarsEchangeAdmin';
import MotAceuilEchangeAdminPage from './page/AdminPage/EchangeAdminPage/MotAceuilEchangeAdminPage';
import ListClientEchangeAdminPage from './page/AdminPage/EchangeAdminPage/ListClientEchangeAdminPage';
import NouvCientEchangeAdminPage from './page/AdminPage/EchangeAdminPage/NouvCientEchangeAdminPage';
import StandByClientEchangeAdminPage from './page/AdminPage/EchangeAdminPage/StandByClientEchangeAdminPage';
import DemmandeCilentEchangeAdminPage from './page/AdminPage/EchangeAdminPage/DemmandeCilentEchangeAdminPage';
import DemmandeHistoryEchangeAdminPage from './page/AdminPage/EchangeAdminPage/DemmandeHistoryEchangeAdminPage';
import DetaillDemmandeEchangeAdminPage from './page/AdminPage/EchangeAdminPage/DetaillDemmandeEchangeAdminPage';
import DetaillDemmandeHistoryEchangeAdminPage from './page/AdminPage/EchangeAdminPage/DetaillDemmandeHistoryEchangeAdminPage';
import CompteAdminEchangePage from './page/AdminPage/EchangeAdminPage/CompteAdminEchangePage';
import StandByVoirPlusPage from './page/AdminPage/EchangeAdminPage/StandByVoirPlusPage';
import VoirPlusClientPage from './page/AdminPage/EchangeAdminPage/VoirPlusClientPage';
import EchangePage from './page/UserPage/EchangeUserPage/EchangePage';
import EchangeAdminPage from './page/AdminPage/EchangeAdminPage/EchangeAdminPage';
import AvisAdminPage from './page/AdminPage/EchangeAdminPage/AvisAdminPage';
import FraistransactionAdmiPage from './page/AdminPage/EchangeAdminPage/FraistransactionAdmiPage';
import SuppressionCompletAdmin from './component/Admin/EchangeAdmin/SuppressionComplet/SuppressionCompletAdmin';


//pour admin market
import NavBarsTopAdmiSeller from './component/Admin/AdminMarket/NavBarsTopAdmiSeller/NavBarsTopAdmiSeller';
import ManageMarketAdminPage from './page/AdminPage/AdminMarket/ManageMarketAdminPage';
import DetailManageMarketAdminPage from './page/AdminPage/AdminMarket/DetailManageMarketAdminPage';
import RegisterMarketAdminPage from './page/AdminPage/AdminMarket/RegisterMarketAdminPage';
import ManageOrderMarketAdminPage from './page/AdminPage/AdminMarket/ManageOrderMarketAdminPage';
import DetailleAdminMarketManageOrderPage from './page/AdminPage/AdminMarket/DetailleAdminMarketManageOrderPage';
import StandByAdminMArketPage from './page/AdminPage/AdminMarket/StandByAdminMArketPage';
import DetaillenewsellerPage from './page/AdminPage/AdminMarket/DetaillenewsellerPage';

import DetailPoductorAdminMarketPage from './page/AdminPage/AdminMarket/DetailPoductorAdminMarketPage';
import HistoryAdminMarketPage from './page/AdminPage/AdminMarket/HistoryAdminMarketPage';
import AdminMarketProfil from './component/Admin/AdminMarket/AdminMarketProfil/AdminMarketProfil';
import DetailHistoryAdminMarketPage from './page/AdminPage/AdminMarket/DetailHistoryAdminMarketPage';

// superAdmin
import NavBarsAdminSuperEchange from './component/AdminSuper/NavBarsAdminSuper/NavBarsAdminSuper';
import SlideNavBarsAdminSuperEchang from './component/AdminSuper/SlideNavBarsAdminSuperEchang/SlideNavBarsAdminSuperEchang';
import AcceuilAdminPageSuperEchangePage from './page/AdminPageSuper/AdminPageSuperEchange/AcceuilAdminPageSuperEchangePage';
import ClientAdminSuperEchangePage from './page/AdminPageSuper/AdminPageSuperEchange/ClientAdminSuperEchangePage';
import AjoutAdminSuperEchangePage from './page/AdminPageSuper/AdminPageSuperEchange/AjoutAdminSuperEchangePage';
import StandByAdminSuperEchangePage from './page/AdminPageSuper/AdminPageSuperEchange/StandByAdminSuperEchangePage';
import LoginWithGoogle from './LoginWithGoogle';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function App() {
  const [isVisitor, SetisVisitor] = useState(false)

  const [isEchangeurUser, SetisEchangeurUser] = useState(false)
  const [isEchangeurUserAdmin, SetisEchangeurUserAdmin] = useState(false)
  const [isMarcheUser, SetisMarcheUser] = useState(false)

  const [isSchoolUser, SetisSchoolUser] = useState(false)

  const [isEchangeurAdmin, SetisEchangeurAdmin] = useState(false)
  const [isMarcheAdmin, SetisMarcheAdmin] = useState(false)
  const [isSchoolAdmin, SetisSchoolAdmin] = useState(false)

  const [isAdminSuper, SetisAdminSuper] = useState(false)
  const [isVisitorLogged, SetisVisitorLogged] = useState(false);

  // const [isMarcheUser, SetisMarcheUser] = useState(false);
  const [isInvestor, SetInvestor] = useState(false);
  const [isStudent, SetStudent] = useState(false);
  const [isAdmin, SetAdmin] = useState(false);
  const [isAdminmarket, setAdminmarket] = useState(false);

  const [LoginPopUp, SetLoginPopUp] = useState(false);
  const [SignPopUp, SetSignPopUp] = useState(false);
  const [SignPopUpSeller, SetSignPopUpSeller] = useState(false);

  const [questionUser, SetQuestionUser] = useState(false);
  const [retireProduit, SetsetireProduit] = useState(false);
  const [ajoutNombreQuantisProduit, SetajoutNombreQuantisProduit] = useState(false);
  const [dettereEnregistr, SetdettereEnregistr] = useState(false);


  const LocationActuel = useLocation()
  const regexLivres = /^\/Visit\/Livres.*/
  const regexCours = /^\/Visit\/Cours.*/
  const regexVisitorLogin = /^\/Visit\/login.*/
  const regexVisitorHomeLogged = /^\/Visit\/HomeLogged.*/

  const isLivresPath = regexLivres.test(LocationActuel.pathname);
  const isCourssPath = regexCours.test(LocationActuel.pathname);
  const isVisitorLoginsPath = regexVisitorLogin.test(LocationActuel.pathname);
  const isVisitorHomeLogged = regexVisitorHomeLogged.test(LocationActuel.pathname);



  const divScroll = useRef(null)
  const [isScroll, SetisScroll] = useState(false)
  const [isScrollRemoveBarEcranPetit, SetisScrollRemoveBarEcranPetit] = useState(false)
  const [isdivScroll, SetdivScroll] = useState(divScroll)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  const [getWidth, SetgetWidth] = useState(0)
  useEffect(() => {

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    const hundleScroll = (e) => {
      const value = e.target.scrollTop
      if (value > 100) {
        SetisScroll(true)
      } else {
        SetisScroll(false)
      }
      SetgetWidth(value / ((e.target.scrollHeight) - (e.target.clientHeight)))
    }

    if (divScroll.current) {
      divScroll.current.addEventListener('scroll', hundleScroll);

    }
    window.addEventListener("resize", handleResize);
    return () => {
      if (divScroll.current) {
        divScroll.current.removeEventListener('scroll', hundleScroll);
      }
      window.removeEventListener("resize", handleResize);
    }
  }, [divScroll.current])

  useEffect(() => {
    setTimeout(() => {
      isdivScroll.current.style.scrollBehavior = 'smooth';
    }, 0);
  }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);




  const [displayPage, setDisplayPage] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const numberPage = (page) => {
    setDisplayPage(page);

  };
  const handleDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf.numPages);
  };




  const userRole = localStorage.getItem("userRole");
  const idexch = localStorage.getItem("idexch");
  const idSuperagent = localStorage.getItem("idSuperagent");

  useEffect(() => {
    if (userRole === "admin") {
      SetAdmin(true);
      SetisVisitor(false);
    } else if (userRole === "Adminmarket") {
      SetisVisitor(false);
      setAdminmarket(true);
    }
    else if (userRole === "seller") {
      SetisMarcheUser(true);
      SetisVisitor(false);
    } else if (userRole === "investor") {
      SetInvestor(true);
      SetisVisitor(false);
    } else if (userRole === "student") {
      SetStudent(true);
      SetisVisitor(false);
    }
    else if (userRole === "Visitor") {
      SetisVisitor(true);
      SetisVisitorLogged(true);
    }
    else if (idSuperagent) {
      SetisEchangeurAdmin(true);
    } else if (idexch) {
      SetisEchangeurUser(true);
    } else {
      SetisVisitor(true);
    }
  });
  const [getSize, setdetSize] = useState()
  function SizeMobile(size) {
    setdetSize(size);
  }
  function getMobille1(size) {
    setdetSize(size);
  }

  function getSrollowerSize(valuers) {
    if (valuers > 100) {
      SetisScroll(true);

    } else {
      SetisScroll(false)
    }
  }




  const [getCommandLong, SetgetCommandLong] = useState(0)
  const [getHostoryLong, SetgetHostoryLong] = useState(0)
  const [getStandByLongs, SetgetStandByLongs] = useState(0)
  const [getClientLongs, SetgetClientLongs] = useState(0)
  const [mobile5, SetMobile5] = useState()

  const getHistoryLong = HistoryLength => {
    SetgetHostoryLong(HistoryLength)
  }
  const getListCommand = CommandeLength => {
    SetgetCommandLong(CommandeLength)
  }
  const getStandByLong = StandByLength => {
    SetgetStandByLongs(StandByLength)
  }
  const getListClient = ClientLenght => {
    SetgetClientLongs(ClientLenght)
  }
  const GetHeight = value => {
    SetMobile5(value)
  }



  return isVisitor ? (
    <>
      {
        LoginPopUp ? (
          <>
            <SeconnectMArcheUser
              LoginPopUp={LoginPopUp}
              SetLoginPopUp={SetLoginPopUp}
              SignPopUp={SignPopUp}
              SetSignPopUp={SetSignPopUp}
              SetisMarcheUser={SetisMarcheUser}
              SetInvestor={SetInvestor}
              SetStudent={SetStudent}
              SetAdmin={SetAdmin}
              setAdminmarket={setAdminmarket}
              SetisVisitor={SetisVisitor}
              SetisVisitorLogged={SetisVisitorLogged}
            />
          </>
        ) : SignPopUp ? (
          <>
            <SinscrireMarcheUser LoginPopUp={LoginPopUp} SetLoginPopUp={SetLoginPopUp} SetSignPopUp={SetSignPopUp} SignPopUp={SignPopUp} />
          </>
        ) : SignPopUpSeller ? (
          <>
            <InscriptionSellerMarche SetSignPopUpSeller={SetSignPopUpSeller} SetLoginPopUp={SetLoginPopUp} isVisitorLogged={isVisitorLogged} />
          </>
        ) : null
      }


      <div className='w-[100%] h-[100vh]  overflow-hidden'>
        <div
          className='h-[3px] fixed  bg-blue-700 top-0   left-0 z-[1000]'
          style={{
            width: `${getWidth * 100}%`,
          }}

        ></div>
        {isCourssPath ?
          (
            <SchoolCoursNavBars />
          ) : isLivresPath ? (
            <NavBarsSchoolLivresVisitor />
          ) :
            isVisitorLogged ? (
              <NavbarVisitorLogged
                LoginPopUp={LoginPopUp}
                SetLoginPopUp={SetLoginPopUp}
                SignPopUp={SignPopUp}
                SetSignPopUp={SetSignPopUp}
                isdivScroll={isdivScroll}
                getMobille1={getMobille1}
                getSrollowerSize={getSrollowerSize}
              />
            ) : (
              <NavBarsVisitor
                isdivScroll={isdivScroll}
                LoginPopUp={LoginPopUp}
                SetLoginPopUp={SetLoginPopUp}
                SignPopUp={SignPopUp}
                SetSignPopUp={SetSignPopUp}
                getMobille1={getMobille1}
                getSrollowerSize={getSrollowerSize}
              />
            )}

        <div className=" relative w-full h-full flex flex-col  justify-between overflow-hidden">

          <div ref={divScroll} className={`${isMobile ? 'h-[87vh]' : 'h-[80vh]'}  bg-gray-50 overflow-x-hidden overflow-y-auto`}>
            <div onClick={() => {
              divScroll.current.scrollTop = 3
              divScroll.current.style.scrollBehavior = 'smooth'
            }}
              className={`${isScroll ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} transition-all fixed w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer right-6 bottom-2 bg-blue-400 hover:bg-blue-600  duration-75 text-white z-[30]`}>
              <div className="bi bi-capslock-fill"></div>
            </div>

            <Routes>
              <Route path="/" element={<HomeVisitorPage isdivScroll={isdivScroll} />} />
              <Route path="*" element={<PageNotFind />} />
              <Route path="/LoginAdmin" element={<SeconnectAdminiEchange isdivScroll={isdivScroll} />} />
              <Route path="/Visit/connect" element={<Seconnecter isdivScroll={isdivScroll} />} />
              <Route path="/Visit/s'inscrire" element={<Sinscrire isdivScroll={isdivScroll} />} />
              <Route path="/Visit/s'inscrireAdmi" element={<SinscrireAdmin isdivScroll={isdivScroll} />} />
              <Route path="/Visit/Profile" element={<ProfilePageVisitor />} />
              <Route path="/Visit/Orders" element={<MyordersPage />} />
              <Route path="/Visit/Orders/:id" element={<OrderDetailPage />} />
              <Route path="/Visit/Marche/Orders" element={<AllCartsPage />} />
              <Route path="/Visit/Marche/Paiement" element={<PaymentMarcheVisitorPage isVisitorLogged={isVisitorLogged} />} />
              <Route path="/image" Component={LogImage} />
              <Route path="/Visit/Education" Component={HomeVisitorSchoolPage} />
              <Route path="/Visit/Livres" Component={AcceuilSchoolLivresVisitorPage} />
              <Route path="/Visit/Livres" Component={SchoolLivresVisitorDetail} />
              <Route path="/Visit/Livres/Educ" Component={EdicationLivreVisitorPage} />
              <Route path="/Visit/Livres/Educ/Detail" Component={EdicationLivreVisitorDetailPage} />
              <Route path="/Visit/Livres/Connaiss" element={<EdicationConnaissVisitorPape displayPage={displayPage} />} />
              <Route path="/Visit/Livres/Connaiss/Lire" element={<LirePdfLivreVisitorSchoolPage ShowPages={numberPage} onDocumentLoadSuccess={handleDocumentLoadSuccess} />} />
              <Route path="/Visit/Livres/Connaiss/Detail" Component={EdicationConnaissVisitorDetailPage} />
              <Route path="/Visit/Livres/Connaiss/PremDetail" Component={EdicationConnaissVisitorPrimDetailPage} />
              <Route path="/Visit/Cours" Component={SchoolCoursVisitorPage} />
              <Route path="/Visit/login" Component={SchoolCoursVisitorPage} />
              <Route path="/Visit/Exchange" element={<VisitorExhangeHomePage isdivScroll={isdivScroll} getSrollowerSize={getSrollowerSize} />} />
              <Route path="/Visit/Loisir" Component={LosirVisitorPage} />
              <Route path="/Visit/Sante" Component={SanteHomeVisitorPage} />
              <Route path="/Visit/Programmation" Component={ProgrammationVisitorPage} />
              <Route path="/Visit/Transport" Component={TransoporVisitorPage} />
              <Route path="/Visit/Marche" element={<MarcheVisitorPage isVisitorLogged={isVisitorLogged} isdivScroll={isdivScroll} SetSignPopUpSeller={SetSignPopUpSeller} SetLoginPopUp={SetLoginPopUp} />} />
              <Route path="/Visit/Marche/product/:id" element={<ProductDetailMarcheVisitorPage isVisitorLogged={isVisitorLogged} SetLoginPopUp={SetLoginPopUp} />} />
              <Route path="/Visit/Reservation" Component={ReservationVisitorPage} />
              <Route path="/Visit/Mentors" Component={MentorsVisitorsPage} />
              <Route path="/Visit/Agri_Ele" Component={Agri_ElevVisitorHomePage} />
              <Route path="/Visit/Contact" Component={ContactVisitorPage} />
            </Routes>
            <FooterVisitor />
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  ) : isEchangeurUser ? (

    <>
      <div className="w-[100%] h-[100vh] overflow-hidden">
        <NavBarsUsersEchangee />
        <div
          className='h-[3px] fixed  bg-blue-700 top-0   left-0 z-[1000]'
          style={{
            width: `${getWidth * 100}%`,
          }}
        ></div>
        <div className=" relative w-full h-full flex justify-between overflow-hidden">

          <div ref={divScroll} className="h-[80vh] bg-white w-full  overflow-x-hidden overflow-y-auto">
            <Routes>
              <Route path="/" element={<MotAcceuilUserEchangePage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} />} />
              <Route path="*" element={<PageNotFind />} />
              <Route path="/User/Echange/Commande" element={<AfficheCommandeUserEchangePage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} />} />
              <Route path="/User/Echange/Echange" element={<EchangePage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} />} />
              <Route path="/User/Echange/Fraistransaction" element={<FraistransactionPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} />} />
              <Route path="/User/Echange/Echange/EnregistrerMonnaie" element={<EnregistrerMonnaiePage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} />} />
              <Route path="/User/Echange/Echange/ModifierEchange/:id" element={<ModifierEchangePage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} />} />
              <Route path="/User/Echange/NewCommande" element={<NewCommandeUserEchangePAge isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} />} />
              <Route path="/User/Echange/Commande/Detail/:id" element={<DetailCommandeUserEchangePage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} />} />
              <Route path="/User/Echange/Compte" element={<CompteUserPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} />} />
              <Route path="/User/Echange/Aide" element={<HelpPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} />} />
            </Routes>
            <CopyRight />
          </div>
        </div>
      </div><ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  ) : isMarcheUser ? (
    <>
      {dettereEnregistr ? (
        <>
          <DetteEnregistr SetdettereEnregistr={SetdettereEnregistr} />
        </>
      ) : questionUser ? (
        <>
          <ChoixEnreg SetQuestionUser={SetQuestionUser} />
        </>
      ) : null}

      <div className='w-[100%] h-[100vh] overflow-hidden'>
        <div
          className='h-[3px] fixed  bg-blue-700 top-0  transition-all left-0 z-[1000]'
          style={{
            width: `${getWidth * 100}%`,
          }}

        ></div>
        <NavBarMarketUsers isdivScroll={isdivScroll} SizeMobile={SizeMobile} getSrollowerSize={getSrollowerSize} getMobille1={getMobille1} />
        <div ref={divScroll} className={`${getSize ? 'h-[90vh]' : 'h-[80vh]'}  bg-gray-100 w-full  overflow-x-hidden overflow-y-auto`}>
          <Routes>
            <Route path="/" element={<AcceuilMarcheUserPage isdivScroll={isdivScroll} />} />
            <Route path="/User/Ventes" element={<VenteSellerPage isdivScroll={isdivScroll} />} />
            <Route path="/User/Ventes/productDetail/:id" element={<ProductSellerDetaillPage isdivScroll={isdivScroll} />} />
            <Route path="/User/Ventes/Carts" element={<AllCartsSellerPage SetQuestionUser={SetQuestionUser} isdivScroll={isdivScroll} />} />
            <Route path="/User/Ventes/Payment" element={<PaymentSellerPage isdivScroll={isdivScroll} />} />
            <Route path='/User/Ventes/AjoutClient' element={<NewClientUserSellerPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} />} />
            <Route path='/User/Ventes/ModifierVente' element={<ModifierVenteMarcheUserPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetajoutNombreQuantisProduit={SetajoutNombreQuantisProduit} />} />
            <Route path='/User/Ventes/ClientExistant' element={<ListClientUserSellerPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetajoutNombreQuantisProduit={SetajoutNombreQuantisProduit} />} />

            <Route path='/User/Dettes' element={<DetteMarcheUserPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetajoutNombreQuantisProduit={SetajoutNombreQuantisProduit} SetdettereEnregistr={SetdettereEnregistr} />} />
            <Route path='/User/Dettes/Details/:id' element={<DetteMarcheUserDetaillPage isdivScroll={isdivScroll} />} />
            <Route path='/User/Bonus' element={<MarketBonusPage isdivScroll={isdivScroll} />} />
            <Route path='/User/Bonus/Detail/:id' element={<ProductBonusDetaillPage isdivScroll={isdivScroll} />} />

            <Route path='/User/Bonus/AjoutBonus' element={<NewBonusMarcheUserPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetajoutNombreQuantisProduit={SetajoutNombreQuantisProduit} SetdettereEnregistr={SetdettereEnregistr} />} />
            <Route path='/User/Bonus/AjoutBonus' element={<ModifBonusMarcheUserPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetajoutNombreQuantisProduit={SetajoutNombreQuantisProduit} SetdettereEnregistr={SetdettereEnregistr} />} />
            <Route path='/User/Bonus/Carts' element={<CartsBonusPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetajoutNombreQuantisProduit={SetajoutNombreQuantisProduit} />} />
            <Route path='/User/Bonus/Client' element={<ClientBonusPage isdivScroll={isdivScroll} />} />
            <Route path='/User/Jours' element={<ActionJoursPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetajoutNombreQuantisProduit={SetajoutNombreQuantisProduit} SetdettereEnregistr={SetdettereEnregistr} />} />


            <Route path='/User/Depenses' element={<DepenseMarcheUserPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetajoutNombreQuantisProduit={SetajoutNombreQuantisProduit} SetdettereEnregistr={SetdettereEnregistr} />} />
            <Route path='/User/Depenses/AjoutDepense' element={<NewDepenesMarcheUserPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetajoutNombreQuantisProduit={SetajoutNombreQuantisProduit} SetdettereEnregistr={SetdettereEnregistr} />} />
            <Route path='/User/Depenses/Dettail/:id' element={<DepenseMarcheUserDetaillPage isdivScroll={isdivScroll} />} />
            <Route path='/User/Depenses/ModifDepense' element={<ModifDepenesMarcheUserPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetajoutNombreQuantisProduit={SetajoutNombreQuantisProduit} SetdettereEnregistr={SetdettereEnregistr} />} />

            <Route path="/User/Marche" element={<DashBoardUserMarchePage isdivScroll={isdivScroll} />} />
            <Route path="/User/Profile" element={<CompteMarcheUsePage isdivScroll={isdivScroll} />} />
            <Route path="/User/Marche/Modif/:id" Component={UpdateProdMacheUserPage} />
            <Route path="/User/Marche/PlusPhotos/:id" Component={UpdatePhotoMarcheUserPage} />
            <Route path="/User/Marche/PlusPhotos/Detail/:id" Component={DetailProductorMarcheUserPage} />
            <Route path="/User/Orders/Detail/:ida/:idb" Component={OrderDetailUserMarcheurPage} />
            <Route path="/User/AjouProduct" Component={RegisterProductMarcheUserPage} />
            <Route path="/User/Orders" Component={ManageOrderMarketPage} />


          </Routes>
          <CopyRight />
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>


  ) :
    isEchangeurAdmin ? (

      <>
        <div className='w-[100%] h-[100vh] overflow-hidden'>
          <div
            className='h-[10px] fixed  bg-blue-700 top-0   left-0 z-5'
            style={{
              width: `${getWidth * 100}%`,
            }}

          ></div>
          <NavBarsEchangeAdmin GetHeight={GetHeight} getClientLongs={getClientLongs} getHostoryLong={getHostoryLong} getCommandLong={getCommandLong} getStandByLongs={getStandByLongs} />
          <div className="bg-green-500  relative w-full h-full flex  justify-between overflow-hidden">
            <div ref={divScroll} className={` ${mobile5 ? 'h-[83vh]' : 'h-[80vh]'} bg-white w-full  overflow-x-hidden overflow-y-auto`}>
              <Routes>
                <Route path='/' element={<MotAceuilEchangeAdminPage isdivScroll={isdivScroll} />} />
                <Route path='/Admin/Client' element={<ListClientEchangeAdminPage getListClient={getListClient} />} />
                <Route path='/Admin/NouvClient' element={<NouvCientEchangeAdminPage isdivScroll={isdivScroll} />} />
                <Route path='/Admin/StandBuy' element={<StandByClientEchangeAdminPage getStandByLong={getStandByLong} />} />
                <Route path='/Admin/Command' element={<DemmandeCilentEchangeAdminPage getListCommand={getListCommand} />} />
                <Route path='/Admin/History' element={<DemmandeHistoryEchangeAdminPage getHistoryLong={getHistoryLong} />} />
                <Route path='/Admin/Command/Detail/:id' element={<DetaillDemmandeEchangeAdminPage isdivScroll={isdivScroll} />} />
                <Route path='/Admin/History/Detail/:id' Component={DetaillDemmandeHistoryEchangeAdminPage} />
                <Route path='/Admin/StandBuy/VoirPlus/:id' Component={StandByVoirPlusPage} />
                <Route path='/Admin/Client/VoirPlus/:id' Component={VoirPlusClientPage} />
                <Route path='/Admin/Compte' element={<CompteAdminEchangePage isdivScroll={isdivScroll} />} />
                <Route path='/Admin/echange' element={<EchangeAdminPage isdivScroll={isdivScroll} />} />
                <Route path='/Admin/avis' element={<AvisAdminPage isdivScroll={isdivScroll} />} />
                <Route path='/Admin/fraistransaction' element={<FraistransactionAdmiPage isdivScroll={isdivScroll} />} />
                <Route path='/Admin/fraistransaction/Ajouter' element={<AjoutertFraistransactionAdmiPage isdivScroll={isdivScroll} />} />
                <Route path='/Admin/fraistransaction/Modifier/:id' element={<ModifierFraistransactionAdmiPage isdivScroll={isdivScroll} />} />
                <Route path='/Admin/suppressionCompte' element={<SuppressionCompletAdmin isdivScroll={isdivScroll} SetisEchangeurUser={SetisEchangeurUser} />} />
              </Routes>
              <CopyRight />
            </div>
          </div>
        </div><ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </>

    ) : isAdminmarket ? (
      <>
        <div className='w-[100%] h-[100vh] overflow-hidden'>
          <div
            className='h-[3px] fixed  bg-blue-700 top-0   left-0 z-[1000]'
            style={{
              width: `${getWidth * 100}%`,
            }}

          ></div>
          <NavBarsTopAdmiSeller isdivScroll={isdivScroll} />
          <div className="bg-green-500 relative w-full h-full flex justify-between overflow-hidden">
            <div ref={divScroll} className="h-[80vh] bg-gray-100 w-full  overflow-x-hidden overflow-y-auto">
              <Routes>
                <Route path='/Admin/Profil' element={<AdminMarketProfil />} />
                <Route path='/Admin/Market' element={<ManageMarketAdminPage />} />
                <Route path='/Admin/Market/Detail/:id' element={<DetailManageMarketAdminPage />} />
                <Route path='/Admin/RegisterMarket' element={<RegisterMarketAdminPage />} />
                <Route path='/Admin/RegisterMarket/detail/:id' element={<DetaillenewsellerPage />} />
                <Route path='/Admin/ManageOrderMarket' element={<ManageOrderMarketAdminPage />} />
                <Route path='/Admin/ManageOrderMarket/Detail/:id' element={<DetailleAdminMarketManageOrderPage />} />
                <Route path='/Admin/StandBuy' element={<StandByAdminMArketPage />} />
                <Route path='/Admin/StandBuy/Detail/:id' element={<DetailPoductorAdminMarketPage />} />
                <Route path='/Admin/market/History' element={<HistoryAdminMarketPage />} />
                <Route path='/Admin/market/History/Detail/:id' element={<DetailHistoryAdminMarketPage />} />
              </Routes>
              <CopyRight />
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </>
    )
      : isAdminSuper ? (
        <>
          <div className='w-[100%] h-[100vh] overflow-hidden'>
            <NavBarsAdminSuperEchange />
            <div
              className='h-[3px] fixed  bg-blue-700 top-0   left-0 z-[1000]'
              style={{
                width: `${getWidth * 100}%`,
              }}

            ></div>
            <div className="bg-green-500 relative w-full h-full flex justify-between overflow-hidden">
              <SlideNavBarsAdminSuperEchang />
              <div ref={divScroll} className="h-[87%] bg-gray-100 w-full  overflow-x-hidden overflow-y-auto">
                <Routes>
                  <Route path='/' Component={AcceuilAdminPageSuperEchangePage} />
                  <Route path='/Super/Echange' Component={ClientAdminSuperEchangePage} />
                  <Route path='/Super/AjoutAdminEchang' Component={AjoutAdminSuperEchangePage} />
                  <Route path='/Super/StandByAdminEchang' Component={StandByAdminSuperEchangePage} />
                </Routes>
              </div>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
        </>
      ) : null;
}

export default App;






