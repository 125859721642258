import React from "react";
import UpdateForm from "./UpdateForm";
function UpdatePhotoComponent() {
  return (
    <>
      <div className="w-full  flex  h-max justify-center ">
        <UpdateForm />
      </div>
    </>
  );
}

export default UpdatePhotoComponent;
