import React from 'react'
import DemmandeCilentEchangeAdminComponent from '../../../component/Admin/EchangeAdmin/DemmandeCilentAdmin/DemmandeEchangeAdmin/DemmandeCilentEchangeAdminComponent'
function DemmandeCilentEchangeAdminPage({ getListCommand }) {
  return (
    <DemmandeCilentEchangeAdminComponent getListCommand={getListCommand} />


  )
}

export default DemmandeCilentEchangeAdminPage
