import React, { useEffect, useState } from "react";
import Content_ContainerCartSeller from "./Content_ContainerCartSeller";

function AllCartsComponentSeller({SetQuestionUser}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div className={isMobile ? "  " : " "}>
        <Content_ContainerCartSeller SetQuestionUser={SetQuestionUser} />
      </div>
    </>
  );
}

export default AllCartsComponentSeller;
