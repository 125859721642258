import React from 'react'
import DetailleAdminManagent from './DetailleAdminManagent'

export default function DetailleAdminMarketManageOrderComponent() {
    return (
        <>

            <div class=" p-2">
                <DetailleAdminManagent />
            </div>
        </>
    )
}
