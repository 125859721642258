import React, { useEffect, useState } from 'react';
import ChartJsGraphis from './ChartJsGraphis';
import axios from 'axios';
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage';

function AcceuilMarcheUser() {

    const [loading, setLoading] = useState(true);
    const [dataSource, setdataSource] = useState([]);
    const id = localStorage.getItem('userid');

    useEffect(() => {
        axios.get(` https://btrproject.burundientempsreel.com/market/seller/getallproducttochart/${id}`)
            .then((response) => {
                setdataSource(response.data);
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            })
            .catch((error) => {
                console.error('Error fetching products:', error.message);
            });
    }, [id]);


    const generateColors = (count) => {
        const colors = [
            'rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)',
            'rgb(75, 192, 192)', 'rgb(153, 102, 255)', 'rgb(255, 159, 64)',
            'rgb(255, 0, 0)', 'rgb(0, 128, 0)', 'rgb(0, 0, 255)',
            'rgb(128, 0, 0)', 'rgb(0, 128, 128)', 'rgb(128, 0, 128)',
            'rgb(0, 0, 128)', 'rgb(0, 255, 0)', 'rgb(255, 0, 255)',
            'rgb(192, 192, 192)', 'rgb(128, 128, 0)', 'rgb(128, 0, 64)'
        ];
        const generatedColors = [];

        for (let i = 0; i < count; i++) {
            generatedColors.push(colors[i % colors.length]);
        }

        return generatedColors;
    };

    const useData = {
        labels: dataSource.map((data) => data.titre),
        datasets: [{
            label: 'Vos produits',
            data: dataSource.map((data) => data.quanite),
            fill: 'start',
            backgroundColor: generateColors(dataSource.length),
        }]
    };

    return (
        <div className={`w-full flex  flex-col items-center py-2 px-10 rounded textBod bg-white`}>

            {loading && <SpinnerDemarage />}
            <div className="w-full bg-gray-200 flex justify-center items-center h-[100vh]">
                <div className='w-[90%] h-[90vh] p-2 rounded-lg flex justify-center items-center'>
                    <ChartJsGraphis charData={useData} className="w-[100%] h-[100%]" />
                </div>
            </div>
        </div>
    );
}

export default AcceuilMarcheUser;
