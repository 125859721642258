import React, { useEffect, useState } from "react";
import ContainerContentPaymentSeller from "./ContainerContentPaymentSeller";
function PaymentComponentSeller({ isVisitorLogged }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div className={isMobile && isVisitorLogged ? " mt-[91px] md:mt-[125px] " : " mt-[53px] md:mt-[125px]  "}>
        <ContainerContentPaymentSeller />
      </div>
    </>
  );
}

export default PaymentComponentSeller;




