import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify'
function SuppressionCompletAdmin() {
    const [moblie, GetMobile] = useState(window.innerWidth < 852)
    const [mobile, Setmobile] = useState(window.innerWidth < 380)
    const [mobile1, Setmobile1] = useState(window.innerWidth < 1192)
    const [mobile2, Setmobile2] = useState(window.innerWidth < 949)
    const [mobile3, Setmobile3] = useState(window.innerWidth < 435)
    useEffect(() => {
        const HundleSize = () => {
            GetMobile(window.innerWidth < 852)
            Setmobile(window.innerWidth < 380)
            Setmobile1(window.innerWidth < 1192)
            Setmobile2(window.innerWidth < 949)
            Setmobile3(window.innerWidth < 435)
        }
        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])
    const [demande, setDemande] = useState([])
    useEffect(() => {
        axios.get('https://btrproject.burundientempsreel.com/anl/demandeannulation').then((response) => {
            setDemande(response.data)
        }).catch((error) => {
            console.log(error.message)
        })
    }, [])
    function getTimeDifference(createdAt) {
        const currentDate = new Date();
        const createdDate = new Date(createdAt);
        const timeDifference = currentDate.getTime() - createdDate.getTime();
        const secondsDifference = Math.floor(timeDifference / 1000);
        const minutesDifference = Math.floor(secondsDifference / 60);
        const hoursDifference = Math.floor(minutesDifference / 60);
        const daysDifference = Math.floor(hoursDifference / 24);
        const monthsDifference = Math.floor(daysDifference / 30);
        const yearsDifference = Math.floor(monthsDifference / 12);

        if (yearsDifference > 0) {
            return `Il y a ${yearsDifference} ${yearsDifference === 1 ? 'an' : 'ans'}`;
        } else if (monthsDifference > 0) {
            return `Il y a ${monthsDifference} ${monthsDifference === 1 ? 'mois' : 'mois'}`;
        } else if (daysDifference > 0) {
            return `Il y a ${daysDifference} ${daysDifference === 1 ? 'jour' : 'jours'}`;
        } else if (hoursDifference > 0) {
            return `Il y a ${hoursDifference} ${hoursDifference === 1 ? 'heure' : 'heures'}`;
        } else if (minutesDifference > 0) {
            return `Il y a ${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'}`;
        } else {
            return `Il y a quelques instants`;
        }
    }

    const handleSuppression = async (echangeurId) => {
        try {
            //  setcancelongRubriqueId(demandeId)
            const result = await Swal.fire({
                title: "Confirmation",
                text: "Vous voulez supprimer le compte échangeur ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
            });

            if (result.isConfirmed) {
                // Si l'utilisateur a confirmé la suppression
                axios.delete(`https://btrproject.burundientempsreel.com/anl/suppressionCompte/${echangeurId}`)
                    .then((res) => {
                        toast.success('Vous avez supprimé avec succès le compte échangeur');
                        // Supprimer l'élément correspondant de la liste
                        setDemande(demande.filter(dem => dem.echangeurId !== echangeurId));
                    })
                    .catch((err) => {
                        toast.warn('Erreur de suppression de compte échangeur');

                    }).finally(() => {
                        //setcancelongRubriqueId(null)
                    });
            } else {
                console.log('Cancelled');
                // setcancelongRubriqueId(null)
            }
        } catch (error) {
            console.error("Erreur lors de la gestion de la confirmation", error.message);
            //  setRubriqueId(null)
        }
    };

    const handleIgnorer = async (demandeId) => {

        try {
            //  setRubriqueId(demandeId)
            const result = await Swal.fire({
                title: "Confirmation",
                text: "Vous voulez ignorer la demande de suppression de compte ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
            });

            if (result.isConfirmed) {
                axios.put(`https://btrproject.burundientempsreel.com/anl/demandeannulation/${demandeId}`)
                    .then((res) => {
                        setDemande(demande.filter(dem => dem.id !== demandeId));
                        toast.success('Vous avez ignoré la demande de suppression de compte avec succès !');
                        //setPageSize(pageSize + 1);
                    })
                    .catch((err) => {
                        toast.warn('Erreur d\'ignoration de la demande de suppression de compte !');
                        // setRubriqueId(null)
                        console.log(err.message)
                    }).finally(() => {
                        // setRubriqueId(null)
                    });
            } else {
                console.log('Cancelled');
                //setRubriqueId(null)
            }

        } catch (error) {
            console.log('Error:', error.message);
            //setRubriqueId(null)
        }
    };
    return (
        <div className='w-full h-max flex justify-center '>
            <div className='flex flex-col bg-gray-200 w-[95%] rounded-md p-2 mt-4'>
                <div className='text-gray-600  max-sm:text-[1.3rem] text-2xl sm:py-4 text-center border-b border-white '>Demande de la suppression des comptes</div>
                <div class={`w-full mx-auto flex justify-between p-[1px] sm:p-4 ${mobile2 ? 'flex-col' : 'flex-wrap '}`}>
                    {demande.length > 0 && demande.map((data, index) => (
                        <div key={index} class={`bg-white shadow-md ${mobile2 ? 'w-full' : moblie}  rounded-md p-3 sm:p-6 mb-4 ${moblie ? 'w-full h-max' : 'w-[48%]'}`}>
                            <div class={`flex  mb-4 ${mobile3 ? 'flex-col items-start' : 'items-center'}`}>
                                <div className='w-[5em] h-[5em]  border border-fuchsia-600 rounded-xl mr-2 sm:mr-4 overflow-hidden'>
                                    <img class=" w-full h-full object-contain" src={data.echangeur && `https://btrproject.burundientempsreel.com/uploads/photosechange/${data.echangeur.profil}`} />
                                </div>
                                {mobile3 &&
                                    <div class="flex items-center  ml-auto text-blue-800 font-semibold">
                                        <svg class="w-4 h-4 fill-current  mr-1" viewBox="0 0 20 20">
                                            <path d="M10 18a8 8 0 110-16 8 8 0 010 16zm0-2a6 6 0 100-12 6 6 0 000 12zm0-4a1 1 0 110-2 1 1 0 010 2z" />
                                        </svg>
                                        <p class=""> {data.createdAt && getTimeDifference(data.createdAt)}</p>
                                    </div>
                                }
                                <div className={`flex  w-[85%] ${mobile1 ? 'flex-col justify-start  ' : ''}`}>
                                    <div className={`${mobile3 ? 'h-max' : 'h-[5em]'}`}>
                                        <p class="font-semibold text-gray-800 text-[25px]">{data.echangeur && data.echangeur.nom} {data.echangeur && data.echangeur.prenom} ({data.echangeur && data.echangeur.email})</p>
                                        <p class="font-semibold text-gray-500 text-[17px]">Email: <span className='font-normal'>{data.email} </span></p>
                                        <p class="font-semibold text-gray-500 text-[17px]">Mot de passe: <span className='font-normal'>{data.password} </span> </p>
                                    </div>
                                    {!mobile3 &&
                                        <div class="flex items-center  ml-auto text-blue-800 font-semibold">
                                            <svg class="w-4 h-4 fill-current  mr-1" viewBox="0 0 20 20">
                                                <path d="M10 18a8 8 0 110-16 8 8 0 010 16zm0-2a6 6 0 100-12 6 6 0 000 12zm0-4a1 1 0 110-2 1 1 0 010 2z" />
                                            </svg>
                                            <p class=""> {data.createdAt && getTimeDifference(data.createdAt)}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <p class={`text-gray-700 text-[18px] relative mt-12`}>{data.motif}</p>
                            <div className='flex justify-end'>
                                <div className='flex justify-end mt-2 relative'>
                                    <bouton onClick={() => handleIgnorer(data.id)} className={`bg-fuchsia-700 m-1 ${mobile ? 'py-[2px] px-[4px] h-max ' : 'py-1 px-2 '} cursor-pointer rounded text-white`}>Ignorer</bouton>
                                    <bouton onClick={() => handleSuppression(data.echangeurId)} className={`bg-red-700 m-1 ${mobile ? 'py-[2px] px-[4px] h-max ' : 'py-1 px-2 '} cursor-pointer rounded text-white`}>Supprimer</bouton>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div >
    )
}

export default SuppressionCompletAdmin
