import axios from 'axios'
import { chiffrement, dechiffrement } from '../../../../module'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment';
function DetteMarcheUser() {
    const { pathname } = useLocation()

    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(36);
    const handleItemsPerPageChange = (membre) => {
        const selectedItemsPerPage = parseInt(membre.target.value);
        setItemsPerPage(selectedItemsPerPage);
    };
    useEffect(() => {
        axios.get(`https://btrproject.burundientempsreel.com/autovente/alldettes?page=${currentPage}&size=${itemsPerPage}`).then((response) => {
            const { dettes, totalPages } = response.data;
            setData(dettes);
            setTotalPages(totalPages);
        }).catch((error) => {
            console.log(error.message)
            if (error.response) {
                console.log(error.response.data)
            }
        })
    }, [currentPage, itemsPerPage])
    // Fonction pour passer à la page suivante
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1)
        }
    }

    // Fonction pour passer à la page précédente
    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    // Fonction pour changer de page lors du clic sur un numéro de page spécifique
    const goToPage = (page) => {
        setCurrentPage(page)
    }
    // Générer la liste de numérotation des pages
    const generatePageNumbers = () => {
        const pageNumbers = []
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => goToPage(i)}
                    className={
                        currentPage === i
                            ? 'text-sm font-medium leading-none cursor-pointer bg-blue-600 text-white w-[30px] h-[30px] pb-[20px] rounded-lg dark:text-gray-200  dark:hover:text-blue-400 border-t border-transparent hover:border-red-500 pt-3 mr-4 px-2'
                            : 'text-sm font-medium  leading-none cursor-pointer w-[30px] h-[30px] pb-[20px] bg-gray-400 transition-all  text-white hover:bg-gray-600  rounded-2xl dark:text-gray-200  dark:hover:text-blue-400 border-t border-transparent hover:border-blue-400 pt-3 mr-4 px-2'
                    }
                >
                    {i}
                </button>,
            )
        }
        return pageNumbers
    }
    return (

        data.length > 0 && (
            <>
                <div className='bg-gray-200 sticky top-0 flex justify-between px-10 items-center border-blue-300 border-b paddings'>
                    <div className="w-full overflow-hidden h-11 flex justify-center items-center mr-2">
                        <div className="text-gray-500 mot1">Affichage des dettes</div>
                    </div>
                </div>
                <div class={` flex flex-col items-end`}>
                    <div>
                        <select
                            class="px-2  text-black border border-blue-700 font-serif rounded transition cursor-pointer"
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                        >
                            <option>5</option>
                            <option>10</option>
                            <option>12</option>
                            <option>20</option>
                            <option>24</option>
                            <option>30</option>
                            <option>36</option>
                            {data.length > 36 && (
                                <option>{data.length}</option>
                            )}
                        </select>
                    </div>
                    <div className="pr-10  p-1 m-1 rounded search ">
                        <p className='text-gray-500 font-serif'>Rechercher par votre choix</p>
                        <div className="search py-1 w-full search ">
                            <form className='flex  w-[100%] '>
                                <input type="text" className='p-1 search border border-blue-700 rounded transition-all outline-none focus:border-2 focus:border-blue-500' id="" placeholder='Rechercher...' />
                                <label htmlFor='search' className='transition-all bg-blue-400 text-white hover:bg-blue-600 p-2 ml-1 cursor-pointer rounded'><i className='bi bi-search'></i></label>
                            </form>
                        </div>
                    </div>
                    <div className="w-full overflow-auto mt-4">
                        <div className="w-[98%] overflow-hidden widthTabAdmiProdui mr-4">

                            <table className="w-full mx-2" cellspacing="0 " border="0 ">
                                <thead class=" h-11 select-none bg-blue-500 border-b-2 border-blue-400 ">
                                    <tr>
                                        <th className='border-r border-gray-300 text-[white] w-[7em]'>Nom  du client</th>
                                        <th className='border-r border-gray-300 text-[white] w-[3px]'>Tel</th>
                                        <th className='border-r border-gray-300 text-[white] w-[3em]'>Email</th>
                                        <th className='border-r border-gray-300 text-[white] w-[3em]'>Adresse</th>
                                        <th className='border-r border-gray-300 text-[white] w-[3em]'>Date</th>
                                        <th className='border-r border-gray-300 text-[white] w-[3em]'>Dette</th>
                                        <th className='border-r border-gray-300 text-[white] w-[1em]'>Action</th>
                                    </tr>
                                </thead>
                                <tbody class="text-gray-5000">
                                    {data && data.map((data) => {
                                        return (
                                            <tr className='border-r  border-b border-gray-300 h-[35px] text-gray-600 '>
                                                <td className=' border-r border-gray-300'>{data.vente.client.nom}</td>
                                                <td className=' border-r border-gray-300'>{data.vente.client.email}</td>
                                                <td className=' border-r border-gray-300 w-[3px]'>{data.vente.client.tel}</td>
                                                <td className=' border-r border-gray-300'>{data.vente.client.address}</td>
                                                <td className=' border-r border-gray-300'>Le {moment(data.vente.createdAt).locale('fr').format('DD/MM/YYYY HH:mm:ss')}</td>
                                                <td className='border-r border-gray-300'>{data.montant} Fbu</td>
                                                <td className='flex justify-center items-center h-10'>
                                                    <div class="flex justify-center items-center h-full w-[70px] px-4 border rounded-lg border-blue-400 pt-2 ">
                                                        <Link to={`/User/Dettes/Details/${chiffrement(data.vente.id)}`} class=" w-full  h-full flex justify-around">
                                                            Détaill
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className="flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4">
                        <div className="lg:w-3/5 w-full flex items-center justify-between border-t border-gray-200 dark:border-gray-700">
                            <div className="flex items-center pt-3 mr-4 text-gray-600 dark:text-gray-200 hover:text-blue-700 cursor-pointer">
                                <svg
                                    width="14"
                                    height="8"
                                    viewBox="0 0 14 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.1665 4H12.8332"
                                        stroke="currentColor"
                                        stroke-width="1.25"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M1.1665 4L4.49984 7.33333"
                                        stroke="currentColor"
                                        stroke-width="1.25"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M1.1665 4.00002L4.49984 0.666687"
                                        stroke="currentColor"
                                        stroke-width="1.25"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                <button
                                    onClick={previousPage}
                                    disabled={currentPage === 1}
                                    className="text-sm ml-3 text-black  hover:text-blue-700 cursor-pointer"
                                >
                                    Prec
                                </button>
                            </div>
                            <div className="sm:flex mt-3">{generatePageNumbers()}</div>
                            <div className="flex items-center pt-3 ml-4 text-black  hover:text-blue-700 cursor-pointer">
                                <button
                                    onClick={nextPage}
                                    disabled={currentPage === totalPages}
                                    className="text-sm font-medium leading-none mr-3"
                                >
                                    Suiv
                                </button>
                                <svg
                                    width="14"
                                    height="8"
                                    viewBox="0 0 14 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.1665 4H12.8332"
                                        stroke="currentColor"
                                        stroke-width="1.25"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M9.5 7.33333L12.8333 4"
                                        stroke="currentColor"
                                        stroke-width="1.25"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M9.5 0.666687L12.8333 4.00002"
                                        stroke="currentColor"
                                        stroke-width="1.25"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    )
}

export default DetteMarcheUser
