import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useRef } from 'react';

function ModifBonusMarcheUser() {
    const { pathname } = useLocation()
    const [clickSlideHidden, setclickSlideHidden] = useState(false)


    const [click, setclick] = useState(false)
    const [clickCommande, setclickCommande] = useState(false)


    const [nomProduits, setnomProduits] = useState('');
    const [animationClassnomProduits, setAnimationClassnomProduits] = useState('');
    const nomProduitsRef = useRef(null)

    const [nomClient, setnomClient] = useState('');
    const [animationClassnomClient, setAnimationClassnomClient] = useState('');
    const nomClientRef = useRef(null)



    const [telClient, settelClient] = useState('');
    const [animationClasstelClient, setAnimationClassetelClient] = useState('');
    const telClientRef = useRef(null)

    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const [addressClient, setaddressClient] = useState('');
    const [animationClassaddressClient, setAnimationClassaddressClient] = useState('');
    const addressClientRef = useRef(null)

    const [quantiteClient, setquantiteClient] = useState('');
    const [animationClassquantiteClient, setAnimationClassquantiteClient] = useState('');
    const quantiteClientRef = useRef(null)

    const [textarea, setTextarea] = useState('');
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const textaresRef = useRef(null)

    const [autoScrollHeight, setAutoScrollHeight] = useState(50);
    const [valueTextarea, setValueTextarea] = useState('');


    const Client = /^\/Client.*/
    const Produits = /^\/Produits.*/

    useEffect(() => {
        const windowClicked = () => {
            setclick(false);
            setclickSlideHidden(false);
        };

        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        };
    }, [])


    const handleSubmit = (e) => {


        if (nomProduits.trim() === '') {
            toast.warning('Le nom du produit est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassnomProduits('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassnomProduits('');
            }, 3000);
            nomProduitsRef.current.focus()
            e.preventDefault();
            return;
        }

        if (nomClient.trim() === '') {
            toast.warning('Le nom du client  est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassnomClient('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassnomClient('');
            }, 3000);
            nomClientRef.current.focus()
            e.preventDefault();
            return;
        }





        if (telClient.trim() === '') {
            toast.warning('Le telephone du client est nécessaire', {
                autoClose: 2000
            });
            setAnimationClassetelClient('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassetelClient('');
            }, 3000);
            telClientRef.current.focus()
            e.preventDefault();
            return;
        }

        if (addressClient.trim() === '') {
            toast.warning("L'address du client est nécessaire", {
                autoClose: 2000
            });
            setAnimationClassaddressClient('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassaddressClient('');
            }, 3000);
            addressClientRef.current.focus()
            e.preventDefault();
            return;
        }




        if (quantiteClient.trim() === '') {
            toast.warning("La quantite des produits du client est nécessaire", {
                autoClose: 2000
            });
            setAnimationClassquantiteClient('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassquantiteClient('');
            }, 3000);
            quantiteClientRef.current.focus()
            e.preventDefault();
            return;
        }

        if (textarea.trim() === '') {
            toast.warning('Le motif de la bonus est nécessaire', {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassTextarea('');
            }, 3000);
            textaresRef.current.focus()
            e.preventDefault();
            return;
        }

    }


    return (
        <>

            <div className='bg-gray-200 sticky top-0 z-10 flex justify-between px-10 items-center border-blue-300 border-b paddings'>
                <div onClick={(e) => { setclickSlideHidden(!clickSlideHidden); e.stopPropagation(); }} className=" ml-2 menus bi bi-list cursor-pointer hover:bg-black hidden  hover:text-white transition-all h-max text-[20px] px-2 py-1"></div>
                <Link
                    to="/User/Bonus"
                    className=" left-0 flex bg-blue-500 text-white px-2 py-1 rounded cursor-pointer"
                >
                    <i class="bi bi-arrow-return-left mr-2"></i>
                    <p className='hiddess'>Retour</p>
                </Link>
                <div className="w-full overflow-hidden h-11 flex justify-center items-center mr-2">
                    <div className="text-gray-500 mot1"> Modifier le bonus</div>
                </div>

            </div>




            <div className="w-full  flex justify-center pb-20 h-[35em]">
                <div className={`border-2 rounded border-blue-600 p-2 mt-3   w-[40em] h-max responsive3Form1 `}>
                    <form onSubmit={handleSubmit}>
                        <div className="mt-6 w-full ">
                            <div className="transition-all  w-[100%]">
                                <div className="flex justify-around flexVentes">
                                    <div className="  w-[50%] p-2 border-r-2 border-white parts1">
                                        <div className="relative h-11 w-full InputsContact mr-2">
                                            <input ref={nomProduitsRef}
                                                value={nomProduits}
                                                onInput={(e) => setnomProduits(e.target.value)}
                                                className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassnomProduits}`}
                                                placeholder=" "
                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Nom du produit
                                            </label>
                                        </div>

                                        <div className="relative h-11 w-full InputsContact mt-5">
                                            <input ref={nomClientRef}

                                                value={nomClient}
                                                onInput={(e) => setnomClient(e.target.value)}
                                                className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassnomClient}`}
                                                placeholder=" "
                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Nom du Client
                                            </label>
                                        </div>




                                        <div className="relative h-11 w-full InputsContact mt-5">
                                            <input ref={telClientRef}
type='tel'
                                                value={telClient}
                                                onInput={(e) => settelClient(e.target.value)}
                                                className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClasstelClient}`}
                                                placeholder=" "
                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Telephone
                                            </label>
                                        </div>
                                    </div>

                                    <div className=" w-[50%] p-2 parts1">
                                        <div className="relative h-11 w-full InputsContact ">
                                            <input ref={addressClientRef}
                                                value={addressClient}
                                                onInput={(e) => setaddressClient(e.target.value)}
                                                className={`peer pointer-events-auto h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassaddressClient}`}
                                                placeholder=" "

                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Address
                                            </label>
                                        </div>

                                        <div className="relative h-11 w-full InputsContact mt-5">
                                            <input ref={quantiteClientRef}
                                                type='number'
                                                value={quantiteClient}
                                                onInput={(e) => setquantiteClient(e.target.value)}
                                                className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassquantiteClient}`}
                                                placeholder=" "
                                                min='1'
                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Quantite
                                            </label>
                                        </div>

                                        <div className="relative h-full w-full mt-10">
                                            <textarea ref={textaresRef}
                                                value={textarea}
                                                onChange={(e) => {
                                                    setTextarea(e.target.value);
                                                    setAutoScrollHeight(e.target.scrollHeight);
                                                    setValueTextarea(e.target.value.trim().length);
                                                    if (!valueTextarea) {
                                                        setAutoScrollHeight(50);
                                                    }
                                                }}
                                                placeholder=" "
                                                style={{ height: `${autoScrollHeight}px` }}
                                                className={`peer personalTextarea leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassTextarea}`}
                                            />
                                            <label
                                                className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-blue-gray-500`}
                                            >
                                                Motif
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                <div className="flex justify-end items-center mt-[20px]">
                                    <div className="flex bg-blue-600 rounded cursor-pointer pl-2 py-1 text-white">
                                        <input type="submit" id="send" hidden />
                                        <label htmlFor="send" className="w-max flex justify-end p-1 cursor-pointer mx-1 ">
                                            <span className='mr-2'>Modifier</span>
                                            <div className="bi bi-send"></div>
                                        </label>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ModifBonusMarcheUser
