import React from 'react'

import Detaillenewseller from './Detaillenewseller'

export default function DetailleNewSellerComponent() {
    return (
        <div className=''>

            <Detaillenewseller />
        </div>
    )
}
