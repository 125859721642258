import React from "react";

function Description() {
  return (
    <>
      <div class="py-5 my-5 px-5 rounded-2xl bg-gray-50">
        <div class="border-b border-gray-300  flex flex-row space-x-1">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
              />
            </svg>
          </div>
          <h3 class=" text-gray-700 text-sm  md:text-base  font-bold pb-3 ">
            Détails d'expédition et de livraison
          </h3>
        </div>

        <div class="w-11/12 pt-2 px-2 md:px-5">
          <div class="text-gray-600  text-xs md:text-base">
            <ul class="flex flex-col space-y-2 list-disc">
              <li>
                <p class="font-medium mb-1">
                  Informations sur la livraison:</p>
                <p className="text-gray-700 font-bold pb-3 ">
                  Découvrez notre marché en ligne  de Burundi en temps reel ! Pour vos besoins en produits, contactez-nous au <a href="tel:62681084" className="text-blue-500">62681084</a> . Nous sommes là pour vous servir
                </p>
              </li>
              <li>
                <p class="font-medium mb-1">Informations de livraison:</p>
                <p class="flex flex-col space-x-4">
                  <h3 class=" text-gray-700 flex flex-row items-center  pb-3 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5 mr-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    Temps de livraison estimé :
                  </h3>
                  <h3 class=" text-gray-700  font-bold pb-1 ">
                    24h à 10 jours</h3>
                </p>
                <p class="flex flex-col space-x-4 mt-3">
                  <h3 class=" text-gray-700  flex flex-row  pb-1 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5 mr-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                      />
                    </svg>
                    Adresse de livraison :
                  </h3>
                  <div class="flex md:flex-col flex-col">
                    <h3 class=" text-gray-700 font-bold pb-3 ">
                      1. Campus kiriri
                    </h3>
                    <h3 class=" text-gray-700 font-bold pb-3 ">
                      2. Kamenge kuri zone
                    </h3>
                  </div>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Description;
