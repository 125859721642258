import React from 'react'

import MarketList from './MarketList'

function ManageOrderMarketComponent() {
  return (
    <>

      <div class="w-full flex justify-center">
        <MarketList />
      </div>
    </>
  )
}

export default ManageOrderMarketComponent
