import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

function AcceuilAdminSuperEchange() {
    const { pathname } = useLocation()
    const [click, setclick] = useState(false)
    const [clickCommande, setclickCommande] = useState(false)
    const [clickSlideHidden, setclickSlideHidden] = useState(false)

    const AdminCleint = /^\/Admin\/Client.*/

    useEffect(() => {
        const windowClicked = () => {
            setclick(false);
            setclickSlideHidden(false);

            // navigate('/User/Echange/Commande')
        };

        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        };
    }, []);

    return (
        <>
            {/* NavBars d'ecran de petit taill */}


            <div className='bg-gray-200 w-full hidden  pt-2 justify-around sticky top-0 z-10 navEcrantTail'>
                <div onClick={(e) => { setclickSlideHidden(!clickSlideHidden); e.stopPropagation(); }} className=" menus bi bi-list cursor-pointer hover:bg-black hidden  hover:text-white transition-all h-max text-[20px] px-2 py-1"></div>
                <div className="ml-2 menus hidden text-center font-serif navEchange w-[80%]"><span className='mot1'>Burundi </span><span className='mot2'>en </span><span className='mot1'>temps</span> <span className='mot3'>réel</span></div>
            </div>
            <div className={`${clickSlideHidden ? 'slideBars20 ' : 'slideBars2'}  w-[11em] z-50  top-0 hidden absolute   h-full bg-red-300 transition-all border-r-2 border-blue-300 slideBars2`}>
                <div className="w-full relative h-12 flex justify-center items-end font-serif text-gray-600 border-b border-gray-200 ">
                    <button onClick={(e) => { setclickSlideHidden(!clickSlideHidden); e.stopPropagation(); }} className='bi bi-chevron-double-left text-black hover:bg-black  hover:text-white transition-all font-bold absolute top-0 right-2  px-[4px] py-[1px] rounded '></button>
                    <p>Tous navigation</p>
                </div>
                <div className="w-[11em]">
                    <div className="w-full h-100% font-serif">
                        {pathname == "/" ? (
                            <Link to='/' className='flex  justify-start items-center  p-1 w-full h-12    bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Acceuil</p>
                            </Link>
                        ) : (
                            <Link to='/' className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-blue-400  transition-all hover:text-fuchsia-50 '>
                                <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                                <p className='sizeText'>Acceuil</p>
                            </Link>
                        )}

                        {AdminCleint.test(pathname) ? (
                            <div className='flex flex-col   items-start  p-1 w-full       pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <div onClick={(e) => { setclick(!click); setclickCommande(false); e.stopPropagation() }} className="flex cursor-pointer items-center">
                                    <i class="bi bi-person text-[20px] mr-1"></i>
                                    <p className='sizeText'>Client</p>
                                    <i class={`${click ? 'rotate-[90deg]' : 'rotate-[0deg]'} hidden transition-all bi bi-chevron-right  text-[20px] ml-10 `}></i>
                                </div>
                                <div className={` ${click ? 'h-[5em] opacity-100 pointer-events-auto transition-all ' : 'opacity-0 pointer-events-none hidden transition-all '} pl-[35px]  transition-all  w-full flex flex-col p-2 justify-between items-center`}>
                                    <Link to='/Admin/Client' className={` ${click ? 'h-[5em]' : 'h-[0em]'} w-full h-[0em] rounded-[2px]  hover:bg-blue-400 hover:text-fuchsia-50 `}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-person-check-fill text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>Listes</p>
                                        </div>
                                    </Link>
                                    <Link to="/Admin/NouvClient" className={` ${click ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50 `}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-person-fill-add text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>Nouveau </p>
                                        </div>
                                    </Link>

                                    <Link to="/Admin/StandBuy" className={` ${click ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50  relative`}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-person-exclamation text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>StandBuy </p>
                                            <div className="absolute -top-1 right-2 bg-blue-700 text-white w-[25px] h-[25px] rounded-full text-[10px] flex justify-center items-center">
                                                99+
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                        ) : (
                            <div className={`${click ? 'mb-[1em]   ' : 'mb-[0em]   '} flex flex-col text-gray-800 justify-center  items-start py-2  p-1 w-full transition-all `}>
                                <div onClick={(e) => { setclick(!click); setclickCommande(false); e.stopPropagation() }} className="flex relative w-full cursor-pointer items-center">
                                    <i class="bi bi-person text-[20px] mr-1 pointer-events-none"></i>
                                    <p className='sizeText pointer-events-none'>Client</p>
                                    <i class={`${click ? 'rotate-[90deg]' : 'rotate-[0deg]'} transition-all bi bi-chevron-right  text-[20px] absolute right-2 pointer-events-none`}></i>
                                </div>
                                <div className={` ${click ? 'h-[5em] opacity-100 pointer-events-auto transition-all ' : 'opacity-0 pointer-events-none hidden transition-all '} pl-[35px]  transition-all  w-full flex flex-col p-2 justify-between items-center`}>
                                    <Link to='/Admin/Client' className={` ${click ? 'h-[5em]' : 'h-[0em]'} w-full h-[0em] rounded-[2px]  hover:bg-blue-400 hover:text-fuchsia-50 `}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-person-check-fill text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>Listes</p>
                                        </div>
                                    </Link>
                                    <Link to="/Admin/NouvClient" className={` ${click ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50 `}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-person-fill-add text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>Nouveau </p>
                                        </div>
                                    </Link>

                                    <Link to="/Admin/StandBuy" className={` ${click ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50 relative`}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-person-exclamation text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>StandBuy </p>
                                            <div className="absolute -top-1 right-2 bg-blue-700 text-white w-[25px] h-[25px] rounded-full text-[10px] flex justify-center items-center">
                                                99+
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )}

                        {pathname == "/Admin/Command" ? (
                            <div className='flex flex-col   items-start  p-1 w-full  pb-2  bg-blue-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                <div onClick={(e) => { setclickCommande(!clickCommande); setclick(false); e.stopPropagation() }} className="flex w-full relative cursor-pointer items-center">
                                    <i class="bi bi-hourglass-split text-[20px] mr-1"></i>
                                    <p className='sizeText'>Commande</p>
                                    <i class={`${clickCommande ? 'rotate-[90deg]' : 'rotate-[0deg]'} transition-all bi bi-chevron-right  text-[20px] absolute right-2`}></i>
                                </div>
                                <div className={` ${clickCommande ? 'h-[5em] opacity-100 pointer-events-auto transition-all ' : 'opacity-0 pointer-events-none hidden transition-all '} pl-[35px]  transition-all  w-full flex flex-col p-2 justify-between items-center`}>
                                    <Link to='/Admin/Command' className={` ${clickCommande ? 'h-[5em]' : 'h-[0em]'} w-full h-[0em] rounded-[2px]  hover:bg-blue-400 hover:text-fuchsia-50 `}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-list-nested text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>Listes</p>
                                        </div>
                                    </Link>
                                    <Link to="/Admin/History" className={` ${clickCommande ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50 `}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-hourglass-split text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>Historique </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div className={`${clickCommande ? 'mb-[1em]   ' : 'mb-[0em]   '} flex flex-col text-gray-800 justify-center  items-start py-2  p-1 w-full transition-all `}>
                                <div onClick={(e) => { setclickCommande(!clickCommande); setclick(false); e.stopPropagation() }} className="flex cursor-pointer items-center">
                                    <i class="bi bi-hourglass-split  text-[20px] mr-1"></i>
                                    <p className='sizeText'>Commande</p>
                                    <i class={`${clickCommande ? 'rotate-[90deg]' : 'rotate-[0deg]'} transition-all bi bi-chevron-right  text-[20px] ml-10`}></i>
                                </div>
                                <div className={` ${clickCommande ? 'h-[5em] opacity-100 pointer-events-auto transition-all ' : 'opacity-0 pointer-events-none hidden transition-all '} pl-[35px]  transition-all  w-full flex flex-col p-2 justify-between items-center`}>
                                    <Link to='/Admin/Command' className={` ${clickCommande ? 'h-[5em]' : 'h-[0em]'} w-full h-[0em] rounded-[2px]  hover:bg-blue-400 hover:text-fuchsia-50 `}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-list-nested text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>Listes</p>
                                        </div>
                                    </Link>
                                    <Link to="/Admin/History" className={` ${clickCommande ? 'h-[5em]' : 'h-[0em]'} rounded-[2px]  mt-1 w-full h-[0em] hover:bg-blue-400 hover:text-fuchsia-50 `}>
                                        <div className="flex justify-start items-center">
                                            <i class="bi bi-hourglass-split text-[20px] mr-2 p-[1px]"></i>
                                            <p className='sizeText'>Historique </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>



            <div className='w-full  flex flex-col items-center py-2 px-10 textBod bg-white'>
                <h2 className='text-[22px] font-serif w-full text-center'> <span className="mot2">Bienvenue de</span> <span className="mot3">nouveau sur votre</span> <span className="mot1">cote comme admin d'échange de</span> <span className="mot2">monnaie électronique !</span>
                </h2>
                <p className='p-3 text-gray-500'> Nous sommes ravis de vous revoir parmi nous. Vous avez déjà créé un compte et nous vous remercions de votre confiance continue.<br />
                    Connectez-vous simplement à votre compte en utilisant vos identifiants précédemment enregistrés, c'est-à-dire votre adresse e-mail et votre mot de passe. <br /><br />
                    Une fois connecté, vous pourrez profiter de tous nos services d'échange de monnaie électronique en toute simplicité.
                    Nous sommes là pour vous accompagner à chaque étape de vos transactions.<br />

                    Grâce à notre plateforme, vous pouvez effectuer des échanges rapides et fiables entre différentes monnaies électroniques telles que Lumicash, Ecocash, et bien d'autres. Vous avez déjà pris la bonne
                    décision en choisissant notre service, et nous sommes là pour vous accompagner à chaque étape.<br /><br />

                    Encore une autre fois, nous vous remercions de votre confiance continue envers Burundi en temp réel. Nous sommes ravis de vous accompagner dans vos
                    échanges de monnaie électronique et de vous offrir un service de qualité supérieure.

                    Notre équipe d'assistance est disponible pour vous aider 24 heures sur 24, 7 jours sur 7.<br /><br />
                    N'hésitez pas à nous contacter si vous avez des questions, des suggestions ou des préoccupations.
                    Merci de faire partie de notre communauté d'échange de monnaie électronique. Nous sommes impatients de vous offrir une expérience exceptionnelle à chaque connexion. Bonnes transactions !
                </p>
            </div>

        </>
    )
}

export default AcceuilAdminSuperEchange
