import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function AvisAgent({ SetshowAvis, transt }) {

    const [textarea, setTextarea] = useState('');
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const textaresRef = useRef(null);
    const History = useNavigate()
    const [autoScrollHeight, setAutoScrollHeight] = useState(50);
    const [valueTextarea, setValueTextarea] = useState('');
    const [serviceQuality, setServiceQuality] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!serviceQuality) {
            toast.warning("Veuillez sélectionner le degré de service", {
                autoClose: 2000,
            });
            return;
        } else if (textarea.trim() === '') {
            toast.warning('Le motif est nécessaire', {
                autoClose: 2000,
            });
            setAnimationClassTextarea(
                'animate_animated animate_shakeX placeholder-shown:border-red-500'
            );
            setTimeout(() => {
                setAnimationClassTextarea('');
            }, 3000);
            textaresRef.current.focus();
            return;
        }
        const data = {
            content: textarea,
            fashanyaId: transt,
            serviceQuality
        }

        axios.post("https://btrproject.burundientempsreel.com/avis", data).then((res) => {
            toast.success(res.data)
            SetshowAvis(false)
        }).catch((err) => {
            if (err.response) {
                toast.warn(err.response.data[0])
                return false;
            }
            console.log(err.message)
        })
    };

    return (
        <div className='w-full h-full fixed bg-[#0000006b] flex justify-center z-[1000] top-0 left-0'>
            <div className='bg-white w-[30em] h-max relative rounded-xl p-2 mt-10 flex flex-col items-center'>
                <div className='absolute flex justify-end w-full p-3'>
                    <label onClick={() => SetshowAvis(false)} className='font-semibold bg-[#ff00002d] p-1 rounded-full cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi text-red-800 bi-x-octagon-fill w-6 h-6" viewBox="0 0 16 16">
                            <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                        </svg>
                    </label>
                </div>
                <div className='text-xl text-gray-700 font-semibold w-full text-center my-4'>Votre avis est nécessaire</div>
                <div className='w-[90%]'>
                    <form className='w-full' onSubmit={handleSubmit}>
                        <div className="w-full" >
                            <label className='font-semibold mb-3'>Degré de service</label>
                            <select
                                value={serviceQuality}
                                onChange={(e) => setServiceQuality(e.target.value)}
                                className='w-full p-3 cursor-pointer overflow-hidden border border-fuchsia-700 rounded-md bg-white'
                            >
                                <option hidden value=''>Degré de service</option>
                                <option value='Très satisfait'>Très satisfait</option>
                                <option value='Service rapide et efficace'>Service rapide et efficace</option>
                                <option value='Moyenne'>Moyenne</option>
                                <option value='Service lent'>Service lent</option>
                            </select>
                        </div>
                        <div className='relative h-full w-full mt-[5em]'>
                            <textarea
                                ref={textaresRef}
                                value={textarea}
                                onChange={(e) => {
                                    setTextarea(e.target.value);
                                    setAutoScrollHeight(e.target.scrollHeight);
                                    setValueTextarea(e.target.value.trim().length);
                                    if (!valueTextarea) {
                                        setAutoScrollHeight(50);
                                    }
                                }}
                                style={{ height: `${autoScrollHeight}px` }}
                                className={`peer personalTextarea leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassTextarea}`}
                            />
                            <label
                                className={`pointer-events-none absolute left-1 -top-[25px] peer-focus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5  w-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-blue-gray-500`}
                            >
                                Commentaire
                            </label>
                        </div>
                        <div className='mt-5 flex justify-end'>
                            <div className='flex bg-blue-600 w-max rounded cursor-pointer pl-2 py-1 text-white'>
                                <input type='submit' id='send' value='Envoyer' />
                                <label className='w-max flex justify-end p-1 cursor-pointer mx-1'>
                                    <i title='Envoyer' className='bi bi-send ml-2 rounded text-white'></i>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AvisAgent;
