/* eslint-disable eqeqeq */
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FadeLoader } from 'react-spinners'

function SeconnectAdminiEchange({ SetisEchangeurAdmin, isdivScroll }) {
  const History = useNavigate()
  const [spinnerButton, SetSpinnerButton] = useState(false)

  const [passwordVisible, setPasswordVisible] = useState();
  const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
  const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
  const [emailValue, setEmailValue] = useState('')
  const [animationClassEmail, setAnimationClassEmail] = useState('');

  const [passwordValue, setPasswordValue] = useState('')
  const [animationClassPassword, setAnimationClassPassword] = useState('');

  const elemetRefEmail = useRef(null)
  const elemetRefPass = useRef(null)

  function sendsValue(e) {
    e.preventDefault()

    if (emailValue.trim() == '') {
      toast.warning("L'address email est obligatoire !!", {
        autoClose: 2000
      });
      setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-yellow-500 text-yellow-500 border-yellow-700')
      setTimeout(() => {
        setAnimationClassEmail(' ')
      }, 3000)
      elemetRefEmail.current.focus();
      return;
    } if (!emailValue.trim().match(emailRegex)) {
      toast.error("L'address email est incorrect", {
        autoClose: 2000
      });
      setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
      setTimeout(() => {
        setAnimationClassEmail(' ')
      }, 3000)
      elemetRefEmail.current.focus();
      return;
    } else if (passwordValue.trim() == "") {
      toast.warning("Le mot de passe est obligatoire !!", {
        autoClose: 2000
      });
      setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-yellow-700 text-yellow-500 border-yellow-700')
      setTimeout(() => {
        setAnimationClassPassword(' ')
      }, 3000)
      elemetRefPass.current.focus()
      return;
    } else if (!passwordValue.trim().match(PasswordRegex)) {

      toast.error(`Le mot de passe est incorrect ,
            Veuillez choisir un mot de passe qui respecte les critères suivants:
                         -Doit contenir au moins une lettre minuscule (a-z)
                         -Doit contenir au moins une lettre majuscule(A - Z)
                         - Doit contenir au moins un chiffre(0 - 9)
                         -Doit contenir au moins un caractère spécial parmi les suivants : !@#$ %^&* (),.?":{}|<>!!`, {
        autoClose: 5000,
        position: "top-center",
        className: 'my-toast',

      });
      setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
      setTimeout(() => {
        setAnimationClassPassword(' ')
      }, 5000)
      elemetRefPass.current.focus()
      return;
    }
    const data = {
      email: emailValue,
      password: passwordValue
    }
    SetSpinnerButton(true)
    axios.post("https://btrproject.burundientempsreel.com/surperagent/login", data).then((response) => {
      if (response.data.error) {
        toast.error(response.data.error);
      } else {
        localStorage.setItem('t', response.data.token)
        localStorage.setItem('idSuperagent', response.data.userId)
        History("/")
        window.location.reload();
        SetisEchangeurAdmin(true);
      }
    }).catch(error => {
      if (error.message === 'Network Error') {
        toast.error("Votre connexion Internet est instable.", {
          autoClose: 3000
        });
      } else if (error.response) {
        const response = error.response;
        if (response.status === 404) {
          toast.error("Vous n'etes pas enregistrer dans notre systeme.", {
            autoClose: 3000
          });
        } else if (response.status === 500) {
          toast.error("Une erreur interne du serveur s'est produite.", {
            autoClose: 3000
          });
        } else {

          toast.error(`Erreur ${response.status}: ${response.statusText}`, {
            autoClose: 3000
          });
        }
      } else {
        toast.error("Une erreur inconnue s'est produite.", {
          autoClose: 3000
        });
      }
    }).finally(() => {
      SetSpinnerButton(false)
    });

  }


  return (
    <>
      <div className='w-full h-full select-none bg-white flex justify-center items-center '>
        <div className="w-full h-full flex justify-center  items-center pt-1 ">
          <div className=" transition-all flex colLogin overflow-hidden border border-blue-800 rounded-2xl">
            <div className="bg-[#000000] w-[20em] margi  overflow-hidden relative border border-gray-500 shadow1  rounded p-2 flex flex-col items-center bloskFlex">
              <img draggable='false' src="../image/exchangepremier.png" alt="" className='animeTop w-full h-full object-contain z-10' />
              <div className="boule">
                <span className="namie1 z-50 bg-blue-500 w-[30px] h-[30px] rounded-full animate-spin m-1 absolute top-2 left-4"></span>
                <span className="namie2 z-50 bg-blue-500 w-[30px] h-[30px] rounded-full animate-spin m-1 absolute bottom-20"></span>
                <span className="namie4 z-50 bg-blue-500 w-[30px] h-[30px] rounded-full animate-spin m-1 absolute bottom-10 right-36"></span>
                <span className="namie4 z-50 bg-blue-500 w-[30px] h-[30px] rounded-full animate-spin m-1 absolute top-10 rigth-3"></span>
                <span className="namie1 z-50 bg-blue-500 w-[30px] h-[30px] rounded-full animate-spin m-1 absolute right-9 bottom-10"></span>
                <span className="namie5 z-50 bg-blue-500 w-[30px] h-[30px] rounded-full animate-spin m-1 absolute right-20 bottom-3"></span>
                <span className="namie4 z-50 bg-blue-500 w-[30px] h-[30px] rounded-full animate-spin m-1 absolute top-20 left-2"></span>
                <span className="namie5 z-50 bg-blue-500 w-[30px] h-[30px] rounded-full animate-spin m-1 absolute bottom-20"></span>
                <span className="namie1 z-50 bg-blue-500 w-[30px] h-[30px] rounded-full animate-spin m-1 absolute bottom-5 left-2"></span>
                <span className="namie2 z-50 bg-blue-500 w-[30px] h-[30px] rounded-full animate-spin m-1 absolute top-3 left-36"></span>
              </div>
            </div>
            <div className="bg-white w-[20em] shadow1 h-max rounded relative p-2 flex flex-col items-center bloskFlex">
              <h2 className='py-2 text-gray-600 text-[20px] mb-[10px] text-center'>Nous sommes ravis de vous accueillir parmi nous.<br />
                <span className='text-blue-700 font-serif'>
                  Super Agent</span></h2>
              <form action="" onSubmit={sendsValue} className='w-full  h-full'>
                <div className="flex w-full justify-between flex-col">
                  <div className={`flex items-center`}>
                    <i className='bi bi-envelope-fill mr-1'></i>
                    <div class="relative h-11 w-full mb-6 bloksInput mr-2 ">
                      <input type="email" id="email" name="email" ref={elemetRefEmail} onInput={(e) => { setEmailValue(e.target.value) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`} placeholder=" " />
                      <label htmlFor='email' class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Email
                      </label>
                    </div>
                  </div>
                  <div className={`flex items-center relative`}>
                    <i className='bi bi-key-fill mr-1'></i>
                    <div class="relative h-11 w-full mb-6 bloksInput mr-2 ">
                      <input ref={elemetRefPass} onInput={(e) => { setPasswordValue(e.target.value) }} type={passwordVisible ? 'text' : 'password'} className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassword}`} placeholder=" " />
                      <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Mot de passe
                      </label>
                    </div>
                    <div onClick={() => { setPasswordVisible(!passwordVisible) }} className=" cursor-pointer w-[20px] h-[20px] pb-2 absolute flex justify-center items-center right-2 ">
                      <i class={`bi ${passwordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'} absolute`}></i>
                    </div>
                  </div>
                </div>

                <div className="w-[50px] h-[50px] bottom-10 absolute animate-pulse">
                  <img draggable='false' src="../image/admnistration_des_reseaux.png" alt="" />
                </div>
                <div className="flex justify-end items-center mt-4">



                  {spinnerButton ? (
                    <>
                      <div className="flex justify-end items-center  relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                        <div className='absolute bg-transparent   pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                          <FadeLoader
                            color="rgb(255, 255, 255)"
                            height={10}
                            margin={-9}
                            radius={100}
                            speedMultiplier={1}
                            width={1}
                          /></div>
                        <input type="submit" id="send" value="Se connecter" class=" transition-all "></input>
                      </div>
                    </>
                  ) : (
                    <>
                      <input type="submit" id="send" value="Se connecter" class=" transition-all bg-blue-400 rounded hover:bg-blue-600 cursor-pointer px-5 py-1 text-white"></input>
                    </>
                  )}

                </div>

                <p class="text-sm font-light text-gray-900 dark:text-gray-400 p-2">
                  Vous n'avez pas encore de compte ? <Link
                    onClick={() => {
                      isdivScroll.current.scrollTop = 0
                    }}
                    to="/Visit/s'inscrireAdmi" class="font-serif  hover:underline dark:text-primary-500 text-blue-500 ml-2 ">S'inscrire</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default SeconnectAdminiEchange
