import React from "react";
import AllCartsBonus from "./AllCartsBonus";
function Content_ContainerCartBonus({ SetQuestionUser }) {
  return (
    <>
      {/* <!-- container --> */}
      <div class=" bg-transparent  px-4  text-gray-700 bg-gray-200 ">
        <div class=" h-full bg-transparent items-start">
          <AllCartsBonus SetQuestionUser={SetQuestionUser} />
        </div>
      </div>
    </>
  );
}

export default Content_ContainerCartBonus;
