import React from 'react'
import DetailCommandeUserEchange from './DetailCommandeUserEchange'
function DetailCommandeUserEchangeComponent() {
  return (
    <>
     
      <DetailCommandeUserEchange />
    </>
  )
}

export default DetailCommandeUserEchangeComponent
