/* eslint-disable no-dupe-keys */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { FadeLoader } from 'react-spinners'
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
const Options = [
  { value: "Lumicash", label: "Lumicash" },
  { value: "Ecocash", label: "Ecocash" },
  { value: "Bancobu inoti", label: "Bancobu inoti" },
  { value: "CCM akaravyo", label: "CCM akaravyo" },
  { value: "Mobinetel", label: "Mobinetel" },
];

const defaultValue = Options[0];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderBottom: "2px solid gray",
    borderRadius: "0px",
    backgroundColor: "transparent",
    top: "7px",
    with: "100%",
    boxShadow: state.isFocused ? "0 0 0 2px #ddd" : "none",
    borderBottom: state.isFocused ? "2px solid blue" : "2px solid gray",
    "&:hover": {
      borderBottom: state.isFocused ? "2px solid blue" : "2px solid orange",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "orange" : "transparent",
    color: state.isSelected ? "#333" : "#666",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
    position: "static",
    zIndex: "10000",
  }),
};

const Options2 = [
  { value: "Lumicash", label: "Lumicash" },
  { value: "Ecocash", label: "Ecocash" },
  { value: "Bancobu inoti", label: "Bancobu inoti" },
  { value: "CCM akaravyo", label: "CCM akaravyo" },
  { value: "Mobinetel", label: "Mobinetel" },
];
const defaultValue2 = Options2[0];

function CompteEchangeurUser({ isdivScroll }) {
  const [spinnerButton, SetSpinnerButton] = useState(false)
  const [spinnerButton1, SetSpinnerButton1] = useState(false)
  const [imageLoading, SetImageLoading] = useState(true)
  const [imageLoading1, SetImageLoading1] = useState(true)
  const [loading, SetLoading] = useState(true)
  // pour autre champs
  const [profil, SetProfil] = useState(null);
  const [cnii, setcnii] = useState(null);
  const [cnio, setcnio] = useState(null);

  const [nom, Setnom] = useState("");
  const [animationClassNom, setAnimationClassNom] = useState("");
  const elemetRefNom = useRef(null);

  const [prenom, Setprenom] = useState("");
  const [animationClassPrenom, setAnimationClassPrenom] = useState("");
  const elemetRefPrenom = useRef(null);

  const [tel, SetTel] = useState("");
  const [animationClassTel, setAnimationClassTel] = useState("");
  const elemetRefTel = useRef(null);

  const [SelectValue, SetSelectValue] = useState([]);
  const [animationClassSelecteValue, setanimationClassSelecteValue] =
    useState("");
  const elemetRefSelect = useRef(null);

  const [codeAgent, SetcodeAgent] = useState("");
  const [animationClasscodeAgent, setanimationClasscodeAgent] = useState("");
  const elemetRefcodeAgent = useRef(null);

  const [SelectValue2, SetSelectValue2] = useState("");
  const [animationClassSelecteValue2, setanimationClassSelecteValue2] =
    useState("");
  const elemetRefSelect2 = useRef(null);
  const modifiedOptions2 = Options2.slice(1);

  const [nomCartSIM, SetnomCartSIM] = useState("");
  const [animationClassnomCartSIM, setanimationClassnomCartSIM] = useState("");
  const elemetRefnomCartSIM = useRef(null);

  //pour le mot de passe
  const [animationClassPassword, setAnimationClassPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const elemetRefPass = useRef(null);

  const [animationClassConfPass, setAnimationClassConfPass] = useState("");
  const [ConfpasswordVisible, setConfPasswordVisible] = useState(false);
  const elemetRefConfPass = useRef(null);

  const [modifierBtn, SetmodifierBtn] = useState(false);
  const [EditorPass, SetEditorPass] = useState(false);

  const [photo, SetPhoto] = useState(null);



  const { pathname } = useLocation();
  const [click, setclick] = useState(false);
  const [clickSlideHidden, setclickSlideHidden] = useState(false);

  useEffect(() => {
    const windowClicked = () => {
      setclick(false);
      setclickSlideHidden(false);


    };

    window.addEventListener("click", windowClicked);

    return () => {
      window.removeEventListener("click", windowClicked);
    };
  }, []);
  useEffect(() => {
    function hundleScrolls() {
      setclick(false);
      setclickSlideHidden(false);
    }
    if (isdivScroll.current) {
      isdivScroll.current.addEventListener("scroll", hundleScrolls);
    }
    return () => {
      if (isdivScroll.current) {
        isdivScroll.current.removeEventListener("scroll", hundleScrolls);
      }
    };
  }, []);

  const id = localStorage.getItem("idexch");
  const [data, setData] = useState({});
  useEffect(() => {
    axios
      .get(` https://btrproject.burundientempsreel.com/echangeur/buyId/${id}`)
      .then((response) => {
        setData(response.data);
        Setnom(response.data.nom);
        Setprenom(response.data.prenom);
        SetTel(response.data.tel);
        SetPhotoAvantCNI(`https://btrproject.burundientempsreel.com/uploads/photosechange/${response.data.cnii}`);
        SetPhotoAprestCNI(`https://btrproject.burundientempsreel.com/uploads/photosechange/${response.data.cnio}`);
        SetcodeAgent(response.data.codeagent);
        setTimeout(() => {
          SetLoading(false)
        }, 1000);
      })
      .catch((error) => {
        console.error(error.message);
        if (error.response) {
          console.log(error.response.data);
        }
      });
  }, [id]);

  const [password, setPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confumePassword, setConfirmPassword] = useState("");
  function validatePassword(password) {
    const PasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;

    return PasswordRegex.test(password);
  }
  const handleChangePassword = async () => {
    if (!validatePassword(password) || !validatePassword(newpassword)) {
      toast.warning(
        "Les mots de passe sont invalides. Assurez-vous qu'ils contiennent  une majuscule, une minuscule, un caractère spécial et un chiffre",
        {
          duration: 7000, // Durée de 7 secondes en millisecondes
        }
      );
      return;
    }
    if (newpassword !== confumePassword) {
      toast.warning(
        "Le nouveau mot de passe et la confirmation ne correspondent pas."
      );
      return;
    }

    try {
      const response = await axios.put(
        ` https://btrproject.burundientempsreel.com/echangeur/changepass`,
        {
          password: password,
          newPassword: newpassword,
          id: id,
        }
      );

      if (response.data.success) {
        toast.success("Le mot de passe a été modifié avec succès !");
        setPassword("");
        setNewPassword("");
        setConfirmPassword("");
        const executeAfter5Seconds = () => {
          window.location.reload();
        };
        setTimeout(executeAfter5Seconds, 5000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error.message);
      if (error.response) {
        toast.error(error.response.data);
      }

    }
  };
  const [autreCompte, SetautreCompte] = useState([]);
  useEffect(() => {
    const newResultats = [];
    SelectValue.forEach(select => {
      const result = select.value.split('  ');
      newResultats.push(...result);
    });
    SetautreCompte(newResultats);
  }, [SelectValue]);
  const [PhotoAvantCNI, SetPhotoAvantCNI] = useState("")
  const [animationClassPhotoAvantCNI, setAnimationClassPhotoAvantCNI] = useState('');
  const elementPhotosAvant = useRef(null)

  const [PhotoAprestCNI, SetPhotoAprestCNI] = useState("")
  const [animationClassPhotoAprestCNI, setAnimationClassPhotoAprestCNI] = useState('');
  const elementPhotosApres = useRef(null)
  const handleSubmitAutreChamp = (e) => {
    e.preventDefault();
    if (nom.trim() === "") {
      toast.warning("Le nom est obligatoire", {
        autoClose: 2000,
      });
      setAnimationClassNom(
        "animate__animated animate__shakeX placeholder-shown:border-red-500"
      );
      setTimeout(() => {
        setAnimationClassNom(" ");
      }, 3000);
      elemetRefNom.current.focus();
      return;
    } else if (prenom.trim() === "") {
      toast.warning("Le  prenom est obligatoire", {
        autoClose: 2000,
      });
      setAnimationClassPrenom(
        "animate__animated animate__shakeX placeholder-shown:border-red-500"
      );
      setTimeout(() => {
        setAnimationClassPrenom(" ");
      }, 3000);
      elemetRefPrenom.current.focus();
      return;
    } else if (tel.trim() == "") {
      toast.warning("Le numero de téléphone  est obligatoire !!", {
        autoClose: 2000,
      });
      setAnimationClassTel(
        "animate__animated animate__shakeX placeholder-shown:border-yellow-700 text-yellow-500 border-yellow-700"
      );
      setTimeout(() => {
        setAnimationClassTel(" ");
      }, 3000);
      elemetRefTel.current.focus();
      return;
    } else if (codeAgent.trim() == "") {
      toast.warning("Le code agent est obligatoire", {
        autoClose: 2000,
      });
      setanimationClasscodeAgent(
        "animate__animated animate__shakeX border-red-500"
      );
      setTimeout(() => {
        setanimationClasscodeAgent(" ");
      }, 3000);
      elemetRefcodeAgent.current.focus();
      return;
    }
    SetSpinnerButton(true)
    SetSpinnerButton1(true)
    const formData = new FormData();
    formData.append('nom', nom);
    formData.append('prenom', prenom);
    formData.append('tel', tel);
    formData.append('codeagent', codeAgent);

    if (photo !== null) {
      formData.append('photo', photo);
    }
    if (cnii !== null) {
      formData.append('cnii', cnii);
    }
    if (cnio !== null) {
      formData.append('cnio', cnio);
    }
    formData.append('banck', SelectValue?.value || data.banck);
    formData.append('compte', autreCompte.length > 0 ? autreCompte : data.Compteechangeur.compte);

    axios.put(` https://btrproject.burundientempsreel.com/echangeur/modifyaccount/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      toast.success("Les identifiants ont été modifiés avec succès !");
      SetautreCompte("");
      SetSelectValue([]);
      SetSelectValue2("");
      SetPhoto(null);

      const executeAfter5Seconds = () => {
        window.location.reload();
      };
      setTimeout(executeAfter5Seconds, 5000);
    }).catch((error) => {
      console.log(error.message);
    }).finally(() => {
      SetSpinnerButton(false)
      SetSpinnerButton1(false)
    });
  };

  const ModiPassword = (e) => {
    SetEditorPass(!EditorPass);
    setTimeout(() => {
      if (isdivScroll.current) {
        isdivScroll.current.scrollTop = isdivScroll.current.scrollHeight;
      }
    }, 0);
    e.stopPropagation()
  };

  useEffect(() => {
    setTimeout(() => {
      isdivScroll.current.style.scrollBehavior = 'smooth';
    }, 0);
  }, []);



  const [mobile, SetMobile] = useState(window.innerWidth < 506)
  const [mobile1, SetMobile1] = useState(window.innerWidth < 364)

  useEffect(() => {

    const hundleSize = () => {
      SetMobile(window.innerWidth < 506)
      SetMobile1(window.innerWidth < 364)
    }

    window.addEventListener('resize', hundleSize)

    return () => {
      window.removeEventListener('resize', hundleSize)
    }
  }, [])



  return (
    <>
      {loading && <SpinnerDemarage />}
      <div className="flex justify-center min-h-[100vh] bg-white items-center p-2">
        <div className=" border border-blue-700 rounded-2xl p-2 shadow-2xl widtg">
          <div
            className={`${modifierBtn ? "hidden" : "flex"
              }  flex-col items-center m-2`}
          >
            <div className="w-[9em] h-[9em] border   m-2 rounded-full relative overflow-hidden">
              {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
              <img draggable='false' onLoad={() => {
                setTimeout(() => {
                  SetImageLoading(false)
                }, 1000)
              }}
                src={` https://btrproject.burundientempsreel.com/uploads/photosechange/${data.profil}`}
                alt=""
                className="w-full h-full object-cover object-center "
              />
            </div>
            <div className={`text-gray-500 transition-all ${mobile ? 'leading-0' : 'leading-10'} `}>
              <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5' : ''} `}>

                <div className="font-bold mr-1 flex  w-[13em] justify-between">
                  Nom  <span>:</span>
                </div>
                <div>
                  {data.nom}
                </div>
              </div>

              <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5' : ''} `}>

                <div className="font-bold mr-1 flex  w-[13em] justify-between">
                  Prénom <span>:</span>
                </div>
                <div>
                  {data.prenom}
                </div>
              </div>


              <div className={`flex  ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5' : ''} `}>

                <div className="font-bold mr-1 flex  w-[13em] justify-between">
                  Telephone <span>:</span>
                </div>
                <div>{data.tel}</div>
              </div>
              <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5 flex-col' : ''} `}>

                <div className="font-bold mr-1 flex  w-[13em] justify-between">
                  Email <span>:</span>
                </div>
                <div>{data.email}</div>
              </div>
              <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5' : ''} `}>

                <div className="font-bold mr-1 flex  w-[13em] justify-between">
                  Compte principal <span>:</span>
                </div>
                <div>{data.banck}</div>
              </div>
              <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5' : ''} `}>

                <div className="font-bold mr-1 flex  w-[13em] justify-between">
                  Code agent <span>:</span>
                </div>
                <div>{data.codeagent}</div>
              </div>
              {data.Compteechangeur && (
                <div className={`flex ${mobile ? 'mb-5 flex-col' : ''} `}>

                  <div className="font-bold mr-1 flex  w-[13em] justify-between">
                    Compte supplémentaires <span>:</span>
                  </div>
                  <div>{data.Compteechangeur.compte}</div>
                </div>
              )}





              <div className=" mt-[10px] transition-all flex flex-wrap  justify-between ">
                <div class={`relative h-max mt-3 flex flex-col justify-center items-center bg-gray-100 p-2 text-gray-700  mr-2`}>
                  <label htmlFor="CNIAvant" class={`px-2  py-1 rounded sm:text-center `}>
                    Photo face avant du C.N.I
                  </label>
                  {data.cnii && (
                    <div className={` ${!PhotoAvantCNI ? 'w-[0em] h-[0em] opacity-0' : 'w-[12.5em] block h-[12.5em] opacity-100'} transition-all   mt-1 rounded  bg-white  border border-blue-500`}>
                      {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                      <img draggable='false' onLoad={() => {
                        setTimeout(() => {
                          SetImageLoading(false)
                        }, 1000)
                      }} src={PhotoAvantCNI} className="w-full h-full object-contain pointer-events-none" />
                    </div>
                  )}

                </div>
                <div class={`relative flex flex-col justify-center items-center h-max mt-3 bg-gray-100 text-gray-700 p-2  mr-2 `}>

                  <label htmlFor="CNIArrier" class={` px-2 t max-sm:text-center py-1 rounded  `}>
                    Photo face arrière du C.N.I
                  </label>
                  {data.cnio && (
                    <div className={` ${!PhotoAprestCNI ? 'w-[0em] h-[0em] opacity-0' : 'w-[12.5em] block h-[12.5em] opacity-100'} transition-all   mt-1 rounded  bg-white border border-blue-500`}>
                      {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                      <img draggable='false' onLoad={() => {
                        setTimeout(() => {
                          SetImageLoading1(false)
                        }, 1000)
                      }} src={PhotoAprestCNI} className="w-full h-full object-contain" />
                    </div>
                  )}
                </div>
              </div>






              <div className={`flex ${mobile1 ? ' flex-col' : ''} ${mobile ? 'mb-5' : ''} `}>

                <div className="font-bold mr-1 flex  w-[13em] justify-between">
                  Mot de passe <span>:</span>
                </div>
                <div className="flex">
                  *********{" "}
                  <div
                    title="Modifier le password "
                    onClick={ModiPassword}
                    className="bi cursor-pointer ml-4 bi-pencil-square"
                  ></div>
                </div>
              </div>
            </div>
            <div className="w-full  flex justify-end">
              <div
                onClick={() => {
                  SetmodifierBtn(true);
                }}
                className={` ${!modifierBtn ? "block" : "hidden"
                  }  px-2 py-1 bg-blue-400 text-white rounded cursor-pointer hover:bg-blue-600 transition-all`}
              >
                Modifier
              </div>
            </div>
          </div>

          {/* autre champs */}
          <div className={`${modifierBtn ? "" : "hidden"}`}>
            <Link onClick={() => { SetmodifierBtn(false); }} className="bg-blue-700 px-2 rounded-full py-1">
              <i class="bi bi-arrow-left-circle text-white"></i>
            </Link>
            <h2 className=" p-2 text-center mb-[1em] text-[15px] font-serif text-gray-500 cursor-default">
              Modifier les informations
            </h2>
            <div className="bg-white  m-3 p-2 rounded w-[35em] addClient h-max inscriptvisitions">
              <form onSubmit={handleSubmitAutreChamp}>
                <div className="flex mb-6 flex-col">
                  <div className="mb-10">
                    <div className="la">
                      <label htmlFor="profiles">Photos de profil</label>
                      <label
                        for="profiles"
                        className="bi bi-image-fill cursor-pointer bg-blue-500 px-1 ml-4 rounded text-white"
                      ></label>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      id="profiles"
                      onChange={(e) => {
                        const UrlFile = e.target.files[0];
                        SetPhoto(UrlFile)
                        SetProfil(URL.createObjectURL(UrlFile));
                      }}
                    />
                    <div
                      className={`${profil == ""
                        ? "opacity-0 pointer-events-none"
                        : "opacity-100 pointer-events-auto"
                        } w-[50%] h-[50%]  overflow-hidden mt-[20px] relative  border rounded-2xl border-blue-600`}
                    >
                      {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                      <img draggable='false' onLoad={() => {
                        setTimeout(() => {
                          SetImageLoading1(false)
                        }, 1000)
                      }}
                        src={
                          profil
                            ? profil
                            : ` https://btrproject.burundientempsreel.com/uploads/photosechange/${data.profil}`
                        }
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </div>

                  <div className=" transition-all flex w-[100%] justify-between flexFormul conctat">
                    <div class="relative h-11 w-1/2 flexFormulInput mr-2 ">
                      <input
                        ref={elemetRefNom}
                        value={nom}
                        onInput={(e) => Setnom(e.target.value)}
                        className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNom}`}
                        placeholder=" "
                      />
                      <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Nom
                      </label>
                    </div>

                    <div class="relative h-11 flexFormulInput w-1/2  mr-2 ">
                      <input
                        value={prenom}
                        ref={elemetRefPrenom}
                        onInput={(e) => Setprenom(e.target.value)}
                        className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPrenom}`}
                        placeholder=" "
                      />
                      <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Prenom
                      </label>
                    </div>
                  </div>
                  <div className=" mt-[10px] transition-all flex w-[100%] justify-between  flexFormul">
                    <div class="relative h-11 w-1/2 flexFormulInput  mr-2 ">
                      <input
                        value={tel}
                        ref={elemetRefTel}
                        onInput={(e) => SetTel(e.target.value)}
                        className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassTel}`}
                        placeholder=" "
                      />
                      <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Téléphone
                      </label>
                    </div>
                    <div class="relative h-11 flexFormulInput w-1/2">
                      <input
                        value={data.email}
                        readOnly
                        className="peer  pointer-events-none h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500"
                        placeholder=" "
                      />
                      <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Email valide
                      </label>
                    </div>
                  </div>

                  <div className=" mt-[10px] transition-all flex w-[100%] justify-between flexFormul">
                    <div class="relative h-11 flexFormulInput w-1/2">
                      <input
                        value={codeAgent}
                        ref={elemetRefcodeAgent}
                        onInput={(e) => SetcodeAgent(e.target.value)}
                        className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasscodeAgent}`}
                        placeholder=" "
                      />
                      <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Code Agent
                      </label>
                    </div>
                  </div>

                  <div className=" mt-[30px] transition-all  w-[100%] ">

                    {SelectValue2.value === undefined ? (
                      <div>{data.banck}</div>
                    ) : (
                      <div>{SelectValue2.value}</div>
                    )}
                    <div class="relative h-11 contactInput flex flex-wrap w-full mr-2 ">
                      <div
                        className={`flex flex-col  h-11 w-full relative  responsive31_temoignSelect ${animationClassSelecteValue2}`}
                      >
                        <Select
                          ref={elemetRefSelect2}
                          isClearable={false}
                          onChange={SetSelectValue2}
                          closeMenuOnSelect={true}
                          options={modifiedOptions2}
                          styles={customStyles}
                        ></Select>
                      </div>
                    </div>{" "}
                    {autreCompte.length === 0 ? (
                      <div>{data.Compteechangeur && data.Compteechangeur.compte}</div>
                    ) : (
                      <div>{autreCompte.join(", ")}</div>
                    )}
                    <div class="relative h-11  w-full contactInput  mr-2 ">
                      <div
                        className={`flex flex-col  h-11 w-full relative responsive31_temoignSelect ${animationClassSelecteValue}`}
                      ><Select ref={elemetRefSelect2} onChange={(element) => { SetSelectValue(element); }} defaultValue={SelectValue} isMulti closeMenuOnSelect={true} options={Options2} styles={customStyles} >
                        </Select>
                      </div>
                    </div>
                  </div>

                  <div className=" mt-[10px] transition-all flex flex-wrap  justify-between ">
                    <div ref={elementPhotosAvant} class={`relative h-max mt-3 flex flex-col justify-center items-center bg-gray-100 p-2 text-gray-700  mr-2 ${animationClassPhotoAvantCNI}`}>
                      <input accept='image/*' type='file' id="CNIAvant" hidden onChange={(e) => { const PhotosAvantCNI = e.target.files[0]; setcnii(PhotosAvantCNI); SetPhotoAvantCNI(URL.createObjectURL(PhotosAvantCNI)) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPhotoAvantCNI}`} />
                      <label htmlFor="CNIAvant" class={`px-2  py-1 rounded cursor-pointer sm:text-center `}>
                        Photo face avant du C.N.I
                      </label>
                      <div className={` ${!PhotoAvantCNI ? 'w-[0em] h-[0em] opacity-0' : 'w-[12.5em] block h-[12.5em] opacity-100'} transition-all   mt-1 rounded  bg-white  border border-blue-500`}>
                        {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                          setTimeout(() => {
                            SetImageLoading(false)
                          }, 1000)
                        }} src={PhotoAvantCNI} className="w-full h-full object-contain pointer-events-none" />
                      </div>
                    </div>
                    <div ref={elementPhotosApres} class={`relative flex flex-col justify-center items-center h-max mt-3 bg-gray-100 text-gray-700 p-2  mr-2 ${animationClassPhotoAprestCNI}`}>
                      <input accept='image/*' type='file' id="CNIArrier" hidden onChange={(e) => { const PhotosApprestCNI = e.target.files[0]; setcnio(PhotosApprestCNI); SetPhotoAprestCNI(URL.createObjectURL(PhotosApprestCNI)) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500  ${animationClassPhotoAprestCNI}`} placeholder=" " />
                      <label htmlFor="CNIArrier" class={` px-2 t max-sm:text-center py-1 rounded cursor-pointer `}>
                        Photo face arrière du C.N.I
                      </label>
                      <div className={` ${!PhotoAprestCNI ? 'w-[0em] h-[0em] opacity-0' : 'w-[12.5em] block h-[12.5em] opacity-100'} transition-all   mt-1 rounded  bg-white border border-blue-500`}>
                        {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                          setTimeout(() => {
                            SetImageLoading1(false)
                          }, 1000)
                        }} src={PhotoAprestCNI} className="w-full h-full object-contain" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end items-center">


                  {spinnerButton1 ? (
                    <>
                      <div className="flex justify-end items-center  relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                        <div className='absolute bg-transparent   pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                          <FadeLoader
                            color="rgb(255, 255, 255)"
                            height={10}
                            margin={-9}
                            radius={100}
                            speedMultiplier={1}
                            width={1}
                          /></div>
                        <input type="submit" id="send" value="Modifier" class=" transition-all "></input>
                      </div>
                    </>
                  ) : (
                    <>
                      <label
                        for="send"
                        className=" cursor-pointer w-max  flex justify-end  bg-blue-600   p-2 rounded  text-white"
                      >
                        <input
                          type="submit"
                          id="send"
                          value="Modifier"
                          className="cursor-pointer "
                        ></input>
                      </label>  </>
                  )}

                </div>
              </form>
            </div>
          </div>

          {/* mot de pass */}
          <div
            className={`${EditorPass ? "block" : "hidden"}  ${modifierBtn ? "hidden" : ""
              }`}
          >
            <div className=" transition-all mt-10 flex w-[100%] flex-col">
              <h2 className="font-serif text-gray-400 text-[10px] cursor-default mb-5">
                Modifier le password
              </h2>
              <div className={`relative  w-[100%] p-1 `}>
                <div className={`relative mt-3 `}>
                  <div class="relative h-11 w-full bloksInput">
                    <input
                      ref={elemetRefPass}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      type={passwordVisible ? "text" : "password"}
                      className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassword}`}
                      placeholder=" "
                    />
                    <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Ancien mot de passe
                    </label>
                  </div>
                  <div
                    onClick={() => {
                      setPasswordVisible(!passwordVisible);
                    }}
                    className=" cursor-pointer w-[20px] h-[20px] absolute flex justify-center top-[20px] items-center right-2 "
                  >
                    <i
                      class={`bi ${passwordVisible ? "bi-eye-fill" : "bi-eye-slash-fill"
                        } absolute`}
                    ></i>
                  </div>
                </div>

                <div className={`relative mt-3 `}>
                  <div class="relative h-11 w-full bloksInput">
                    <input
                      ref={elemetRefPass}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                      type={passwordVisible ? "text" : "password"}
                      className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassword}`}
                      placeholder=" "
                    />
                    <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Nouveau mot de passe
                    </label>
                  </div>
                  <div
                    onClick={() => {
                      setPasswordVisible(!passwordVisible);
                    }}
                    className=" cursor-pointer w-[20px] h-[20px] absolute flex justify-center top-[20px] items-center right-2 "
                  >
                    <i
                      class={`bi ${passwordVisible ? "bi-eye-fill" : "bi-eye-slash-fill"
                        } absolute`}
                    ></i>
                  </div>
                </div>

                <div className={`relative mt-3 `}>
                  <div class="relative h-11 w-full bloksInput ">
                    <input
                      ref={elemetRefConfPass}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      type={ConfpasswordVisible ? "text" : "password"}
                      className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassConfPass}`}
                      placeholder=" "
                    />
                    <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Confirme le mot de passe
                    </label>
                  </div>
                  <div
                    onClick={() => {
                      setConfPasswordVisible(!ConfpasswordVisible);
                    }}
                    className=" cursor-pointer w-[20px] h-[20px] top-[20px] absolute flex justify-center items-center right-2 "
                  >
                    <i
                      class={`bi ${ConfpasswordVisible
                        ? "bi-eye-fill"
                        : "bi-eye-slash-fill"
                        } absolute`}
                    ></i>
                  </div>
                </div>
                <div className="flex justify-end items-center mt-3">


                  {spinnerButton ? (
                    <>
                      <div className="flex justify-end items-center  relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                        <div className='absolute bg-transparent   pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                          <FadeLoader
                            color="rgb(255, 255, 255)"
                            height={10}
                            margin={-9}
                            radius={100}
                            speedMultiplier={1}
                            width={1}
                          /></div>
                        <input type="submit" id="send" value="Modifier" class=" transition-all "></input>
                      </div>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          handleChangePassword();
                        }}
                        className=" cursor-pointer w-max  flex justify-end  bg-blue-600   p-2 rounded  text-white"
                      >
                        Modifier <i class="bi bi-send ml-2 "></i>
                      </button>   </>
                  )}



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompteEchangeurUser;
