/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import swal from 'sweetalert2'
import PropTypes from 'prop-types'
import { FadeLoader } from 'react-spinners'

function NewCommandeUserEchange() {
    const [spinnerButton, SetSpinnerButton] = useState(false)

    const [montant, SetMontant] = useState('')
    const [animationClassMontant, setAnimationClassMontant] = useState('');
    const montantRef = useRef(null)

    const [compte, SetCompte] = useState('')
    const [animationClassCompte, setAnimationClassCompte] = useState('');
    const compteRef = useRef(null)


    const [isTextarea, setIsTextarea] = useState('');
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const textareaRef = useRef(null)

    const [autoScrollHeight, setAutoScrollHeight] = useState(50);
    const [valueTextarea, setValueTextarea] = useState('');

    const [animationClassPhotos, setAnimationClassPhotos] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);


    const handleSubmit = (e) => {
        e.preventDefault();
        if (montant.trim() == '') {
            toast.warning('Le montant est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassMontant('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassMontant(' ')
            }, 3000)
            montantRef.current.focus()
            return false
        } else if (compte.trim() == '') {
            toast.warning('Le compte est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassCompte('animate__animated animate__shakeX placeholder-shown:border-yellow-500')
            setTimeout(() => {
                setAnimationClassCompte(' ')
            }, 3000)
            compteRef.current.focus()
            return false
        } else if (isTextarea.trim() === '') {
            toast.warning('Votre déscription est nécessaire', {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassTextarea('');
            }, 3000);
            textareaRef.current.focus()
            return false
        }

        const formData = new FormData();
        selectedFiles.forEach((photo) => {
            formData.append('File', photo);

        });
        SetSpinnerButton(true)
        const idexch = localStorage.getItem('idexch');
        formData.append('echangeurId', idexch)
        formData.append('montants', montant)
        formData.append('Compte', compte)
        formData.append('Description', isTextarea)



        axios.post(' https://btrproject.burundientempsreel.com/commande', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            toast.success('Votre commande a bien été enregistré.', {
                autoClose: 3000
            });

            setSelectedFiles(null);
            setIsTextarea('')
            SetCompte('')
            SetMontant('')
            setTimeout(() => {
                window.location.reload()
            }, 3000);

        }).catch((err) => {
            console.log(err.message);
        }).finally(() => {
            SetSpinnerButton(false)
        });
    }



    const handleImageUpload = (event) => {
        const files = event.target.files;
        setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, ...files]);
        const imageArray = [...selectedImages];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            let newImageName = file.name;
            let duplicateCount = 1;

            while (imageArray.some((image) => image.name === newImageName)) {
                newImageName = `${file.name} (${duplicateCount})`;
                duplicateCount++;
            }

            const existingImageIndex = imageArray.findIndex(
                (image) => image.name === file.name
            );

            if (existingImageIndex !== -1) {
                const replaceImage = window.confirm(
                    `Le fichier ${file.name} existe déjà. Voulez-vous le remplacer ?`
                );

                if (replaceImage) {
                    const imageData = {
                        name: file.name,
                        url: URL.createObjectURL(file),
                    };
                    imageArray.splice(existingImageIndex, 1, imageData);
                    toast.success(`Le fichier ${file.name} a été remplacé.`, {
                        autoClose: 3000
                    });
                } else {
                    const duplicateImage = {
                        name: newImageName,
                        url: URL.createObjectURL(file),
                    };
                    imageArray.push(duplicateImage);
                    toast.success(`Le doublement de fichier ${file.name} a été ajouté en tant que ${newImageName}.`, {
                        autoClose: 3000
                    });
                }
            } else {
                const imageData = {
                    name: file.name,
                    url: URL.createObjectURL(file),
                };
                imageArray.push(imageData);
            }
        }

        setSelectedImages(imageArray);
    };

    const handleImageDelete = (index) => {
        const imageArray = [...selectedImages];
        const fileToDelete = imageArray[index];

        swal.fire({
            title: 'Vous êtes sûr de supprimer le produit',
            text: `Si vous êtes sûr de pouvoir le supprimer définitivement le fichier ${fileToDelete.name}!!`,
            icon: 'warning',
            width: '300px',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Oui',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            cancelButtonText: 'Non',
            cancelButtonTextSize: 'text-[10px]',
            customClass: {
                container: 'h-full w-full',
                title: 'text-[15px]',
                text: 'text-[10px]',
                icon: 'h-[55px] w-[55px]',
            },
            heightAuto: false,
        }).then((result) => {
            if (result.isConfirmed) {
                imageArray.splice(index, 1);
                setSelectedImages(imageArray);

                const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
                setSelectedFiles(newSelectedFiles);

                toast.success(`vous avez supprimé définitivement le fichier ${fileToDelete.name}`, {
                    autoClose: 3000,
                });
            } else if (result.dismiss === swal.DismissReason.cancel) {
                toast.warning(`L'annulation de la suppression du fichier ${fileToDelete.name}`, {
                    autoClose: 3000,
                });
            }
        });
    };


    const [loadingImages, setLoadingImages] = useState([]);


    const handleImageLoad = (index) => {
        setLoadingImages((prevLoadingImages) => {
            const updatedLoadingImages = [...prevLoadingImages];
            setTimeout(() => {
                updatedLoadingImages[index] = false;
            }, 5000);
            return updatedLoadingImages;
        });
    };

    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };

    const [mouseMove, SetMouseMove] = useState(false)

    return (
        <>
            <div className=' bg-white min-h-[90vh]  flex justify-center items-center'>
                <div className="m-3 p-2 shadow-2xl w-[25em]   border border-blue-600  rounded-2xl ">
                    <h2 className=' p-2 text-center  text-[30px] text-blue-500'>Nouveau demande</h2>
                    <form onSubmit={handleSubmit}>
                        <div className=" transition-all flex flex-col mb-6  p-1">
                            <div className="flex w-full justify-between flex-col my-3">
                                <div class="relative h-11 w-full bloksInput mr-2 ">
                                    <p className='absolute right-0 annCoros animate-spin'>★</p>
                                    <input ref={montantRef} type='number' min="1" value={montant} onInput={(e) => SetMontant(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassMontant}`} placeholder=" " />
                                    <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Montant
                                    </label>
                                </div>

                                <div class="relative h-11 w-full bloksInput mr-2 mt-[30px] ">
                                    <p className='absolute right-0 annCoros animate-spin'>★</p>
                                    <input ref={compteRef} value={compte} onInput={(e) => SetCompte(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassCompte}`} placeholder=" " />
                                    <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Compte
                                    </label>
                                </div>
                                <div className="relative h-full w-full mt-[2em]">
                                    <textarea
                                        value={isTextarea}
                                        onChange={(e) => {
                                            setIsTextarea(e.target.value);
                                            setAutoScrollHeight(e.target.scrollHeight);
                                            setValueTextarea(e.target.value.trim().length);
                                            if (!valueTextarea) {
                                                setAutoScrollHeight(50);
                                            }
                                        }}
                                        ref={textareaRef}
                                        placeholder=" "
                                        style={{ height: `${autoScrollHeight}px` }}
                                        className={`peer personalTextarea leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassTextarea}`}
                                    />
                                    <label
                                        className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-blue-gray-500`}
                                    >
                                        Votre description
                                    </label>
                                </div>
                                <div className='w-full mt-[20px] relative  overflow-y-auto flex flex-col'>

                                    <label htmlFor='files' className={`w-full inline-flex ${animationClassPhotos}`}>
                                        <div class="inline mr-3 ">Photos:</div>
                                        <label htmlFor="files" className=' rounded cursor-pointer bi bi-images text-white p-2 w-max bg-blue-600 flex justify-center' />
                                        <input type="file" id='files' accept='image/*' multiple onChange={handleImageUpload} hidden />
                                    </label>
                                    {selectedImages.length > 0 && (
                                        <div className="p-2 flex flex-wrap justify-left w-full">
                                            {selectedImages.map((image, index) => (
                                                <div
                                                    onMouseEnter={() => SetMouseMove(index)}
                                                    onMouseLeave={() => SetMouseMove(null)}

                                                    className="w-max rounded select-none h-[max-content] relative m-2 p-1"
                                                >
                                                    <div className="peer w-[8em] h-[8em] bg-transparent border border-blue-700 text-white rounded-2xl overflow-hidden">
                                                        {loadingImages[index] ? (
                                                            <div className="absolute w-full h-full">
                                                                <img
                                                                    src="https://www.eliananunes.com/images/lazy_loader.gif"
                                                                    className="w-full h-full object-cover"
                                                                    alt="Loading..."
                                                                />
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <img
                                                                    onLoad={() => handleImageLoad(index)}

                                                                    src={image.url}
                                                                    key={index}
                                                                    alt={image.name}
                                                                    className="w-full h-full border-none object-contain pointer-events-none"
                                                                />
                                                            </>
                                                        )}

                                                    </div>
                                                    {index === mouseMove && (
                                                        <span
                                                            onClick={() => handleImageDelete(index)}
                                                            className="flex absolute top-2 right-2 rounded-full cursor-pointer text-white bg-red-800 w-[30px] h-[30px] justify-center items-center"
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                            </svg>
                                                        </span>
                                                    )}
                                                    <div className="desc">
                                                        {index + 1} : {image.name.substring(0, 3) + '..' + image.name.substring(image.name.length - 7)}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div className="flex justify-end items-center">


                                {spinnerButton ? (
                                    <>
                                        <div className="flex justify-end items-center  relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                                            <div className='absolute bg-transparent   pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                /></div>

                                            <input type="submit" id="send" value="Commander" class=" transition-all "></input>
                                            <i class="bi bi-send ml-2 "></i>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-blue-600   p-1 rounded  text-white">
                                            <input type="submit" id="send" value='Commander' className='cursor-pointer '></input>
                                            <i class="bi bi-send ml-2 "></i>
                                        </label>       </>
                                )}





                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default NewCommandeUserEchange
