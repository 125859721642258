import React from "react";
import SchoolCoursVisitor from "./SchoolCoursVisitor";
function SchoolCoursVisitorComponent() {
  return (
    <>
      <SchoolCoursVisitor />
    </>
  );
}

export default SchoolCoursVisitorComponent;
