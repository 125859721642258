import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
function ChoixEnreg({ SetQuestionUser }) {
  const [monbile, Setmobile] = useState(window.innerWidth < 525.9383737378292)

  useEffect(() => {
    window.addEventListener('resize', () => {
      Setmobile(window.innerWidth < 525.9383737378292)
    })
  }, [])


  return (
    <div className='w-full z-[1000000000000000000] py-2 h-full fixed bg-[#00000056] top-0 left-0 flex justify-center items-center'>
      <div className={`${monbile ? 'w-[95%]' : 'w-[30em]'} bg-white  shadow-2xl rounded-lg relative p-4 border border-blue-700 h-max`}>
        <div className='bg-white absolute rounded-full p-2 text-gray-400  border border-blue-700 -top-[35%]  left-1/2 -translate-x-1/2 translate-y-1/2'>
          <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-patch-question-fill" viewBox="0 0 16 16">
            <path d="M5.933.87a2.89 2.89 0 0 1 4.134 0l.622.638.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636zM7.002 11a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm1.602-2.027c.04-.534.198-.815.846-1.26.674-.475 1.05-1.09 1.05-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.71 1.71 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745.336 0 .504-.24.554-.627z" />
          </svg>
        </div>
        <div>
          <div className='flex justify-end items-center'>
            <p className='bg-gray-300  w-[25px] rounded-lg flex justify-center items-center font-bold text-[20px] cursor-pointer hover:text-red-600 transition-all h-[25px]' onClick={() => SetQuestionUser(false)}> X</p>
          </div>
        </div>
        <div className="mt-10 text-[20px]">
          <p>Posez-vous votre client:</p>
          <p className='font-serif text-[18px] text-gray-400 text-center'>Est-ce que vous avez déjà fait des achats chez nous ? </p>
        </div>
        <div className='flex p-4 justify-start'>
          <Link to='/User/Ventes/ClientExistant'
            onClick={() => {
              SetQuestionUser(false)
            }}
            className='bg-blue-500 px-4 rounded transition-all mx-3 py-1 hover:bg-blue-700 text-white cursor-pointer'>
            Oui
          </Link>
          <Link onClick={() => {
            SetQuestionUser(false)
          }}
            to='/User/Ventes/AjoutClient' className=' px-4 mx-3 py-1   cursor-pointer'>
            Non
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ChoixEnreg
