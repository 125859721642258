import React, { useEffect, useState } from "react";
import Content_Container from "./Content_Container";
function ProfileComponent() {

  return (
    <>
      <div className=" mt-[91px] md:mt-[125px]">
        <Content_Container />
      </div>
    </>
  );
}

export default ProfileComponent;
