/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function VisitorProgrammation() {
    const [imageLoading, SetImageLoading] = useState(true)
    const [imageLoading1, SetImageLoading1] = useState(true)
    const [imageLoading2, SetImageLoading2] = useState(true)
    const [imageLoading3, SetImageLoading3] = useState(true)
    const [imageLoading4, SetImageLoading4] = useState(true)
    const [imageLoading5, SetImageLoading5] = useState(true)
    const [imageLoading6, SetImageLoading6] = useState(true)
    const [imageLoading7, SetImageLoading7] = useState(true)
    const [imageLoading8, SetImageLoading8] = useState(true)
    const [imageLoading9, SetImageLoading9] = useState(true)
    const [imageLoading10, SetImageLoading10] = useState(true)

    const [sizeInfer, SetsizeInfer] = useState(window.innerWidth < 650)
    const [sizeInfer1, SetsizeInfer1] = useState(window.innerWidth < 650)
    const [sizeInfer2, SetsizeInfer2] = useState(window.innerWidth < 650)
    const [sizeInfer3, SetsizeInfer3] = useState(window.innerWidth < 650)
    const [sizeInfer4, SetsizeInfer4] = useState(window.innerWidth < 650)
    const [sizeInfer5, SetsizeInfer5] = useState(window.innerWidth < 650)
    const [sizeInfer6, SetsizeInfer6] = useState(window.innerWidth < 650)
    const [sizeInfer7, SetsizeInfer7] = useState(window.innerWidth < 650)
    const [sizeInfer8, SetsizeInfer8] = useState(window.innerWidth < 650)
    const [sizeInfer9, SetsizeInfer9] = useState(window.innerWidth < 650)
    const [sizeInfer10, SetsizeInfer10] = useState(window.innerWidth < 650)

    const [titre, Settitre] = useState(window.innerWidth < 800.38383838282929)

    useEffect(() => {
        const hundleSize = () => {
            SetsizeInfer(window.innerWidth < 650)
            SetsizeInfer1(window.innerWidth < 650)
            SetsizeInfer2(window.innerWidth < 650)
            SetsizeInfer3(window.innerWidth < 650)
            SetsizeInfer4(window.innerWidth < 650)
            SetsizeInfer5(window.innerWidth < 650)
            SetsizeInfer6(window.innerWidth < 650)
            SetsizeInfer7(window.innerWidth < 650)
            SetsizeInfer8(window.innerWidth < 650)
            SetsizeInfer9(window.innerWidth < 650)
            SetsizeInfer10(window.innerWidth < 650)

            Settitre(window.innerWidth < 800.38383838282929)


        }

        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    return (
        <>

            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}>  Bienvenue sur notre site web dédié à la programmation</h2>

                    <div className={` ${imageLoading && sizeInfer ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading(false)
                            }, 1000)
                        }} src="../image/programming.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Bienvenue sur notre site web dédié à la programmation </h2>
                        <p className=" text-gray-500 p-1 text-[22px] mt-6 ">
                            <div className="reduitTailParagr text-gray-500">
                                Que vous soyez intéressé par le développement web, l'intelligence artificielle, l'Internet des objets, le développement d'applications mobiles ou tout autre aspect de la programmation, notre site est là pour vous guider et vous inspirer.

                                N'hésitez pas à parcourir notre contenu, poser des questions et interagir avec notre communauté de développeurs passionnés.<br /><br /> Nous sommes ravis de vous accompagner dans votre aventure de programmation et de vous aider à atteindre vos objectifs.        </div>

                        </p>
                    </div>
                </div>
            </div>


            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}>Dévellopement des logiciels</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Dévellopement des logiciels </h2>

                        <p className="reduitTailParagr text-gray-500 p-1 text-[22px] ">
                            <div className="reduitTailParagr text-gray-500">
                                <div className="reduitTailParagr text-gray-500 mt-6]">
                                    Explorez nos ressources, tutoriels et articles pour découvrir les différents langages de programmation, les meilleures pratiques de développement, les frameworks populaires et bien plus encore. Que vous souhaitiez créer des applications web, des applications mobiles ou des logiciels de bureau, nous avons ce qu'il vous faut.<br /><br />

                                    N'hésitez pas à parcourir nos rubriques et à poser des questions si vous en avez.<br /><br /> Notre équipe est là pour vous aider à chaque étape de votre parcours de développement de logiciels.

                                </div>
                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading1 && sizeInfer1 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading1(false)
                            }, 1000)
                        }} src="../image/DevLogicielles.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}>  Dévellopement de sites web</h2>

                    <div className={` ${imageLoading2 && sizeInfer2 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading2 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading2(false)
                            }, 1000)
                        }} src="../image/Website.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}>  Dévellopement de sites web</h2>
                        <p className="reduitTailParagr text-gray-500 p-1 text-[25px] ">
                            Sur notre site, vous trouverez une multitude
                            de ressources, telles que des tutoriels, des articles,
                            des exemples de code, des conseils et des astuces pour
                            vous aider à maîtriser les différentes technologies et
                            langages utilisés dans le développement web.<br /><br /> Que vous
                            souhaitiez apprendre les bases du HTML, du CSS et du JavaScript,
                            ou explorer des concepts plus avancés tels que les frameworks et
                            les bibliothèques populaires, nous avons ce qu'il vous faut.
                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}>Dévellopement des applications  web</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}>Dévellopement des applications  web </h2>

                        <p className="reduitTailParagr text-gray-500 p-1 text-[22px] ">
                            <div className="reduitTailParagr text-gray-500">
                                <div className="reduitTailParagr text-gray-500 mt-6]">
                                    Ici, vous trouverez tout ce dont vous avez besoin pour créer des applications web performantes, intuitives et attrayantes.
                                    Explorez nos ressources, tutoriels et exemples de code pour vous familiariser avec les langages de programmation web tels que HTML, CSS et JavaScript. Découvrez les frameworks populaires tels que React, Angular et Vue.js pour simplifier le développement et améliorer l'expérience utilisateur. <br /><br />
                                    Que vous souhaitiez créer des sites web interactifs, des applications web dynamiques ou des plateformes de commerce électronique, nous avons tout ce qu'il vous faut pour réaliser vos projets. Restez à jour avec les dernières tendances et les bonnes pratiques du développement web, et laissez votre créativité s'exprimer.

                                </div>
                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading3 && sizeInfer3 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading3 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading3(false)
                            }, 1000)
                        }} src="../image/APP_WEB.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>




            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Dévellopement des applications mobiles</h2>

                    <div className={` ${imageLoading4 && sizeInfer4 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading4 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading4(false)
                            }, 1000)
                        }} src="../image/APP_MOBILE.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Dévellopement des applications mobiles</h2>
                        <p className="reduitTailParagr text-gray-500 p-1 text-[25px] ">

                            Découvrez les technologies les plus populaires telles que React Native, Flutter, Xamarin et bien d'autres. Explorez les bonnes pratiques de conception, les techniques de développement et les astuces de débogage pour créer des applications performantes et conviviales.

                            <br /><br /> Préparez-vous à donner vie à vos idées créatives et à créer des applications mobiles qui auront un impact réel.
                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>




            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Dévellopement des applications  desktop</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}>Dévellopement des applications  desktop </h2>

                        <p className="reduitTailParagr text-gray-500 p-1 text-[22px] ">
                            <div className="reduitTailParagr text-gray-500">
                                <div className="reduitTailParagr text-gray-500 mt-6]">
                                    Vous êtes passionné par les piscines et vous recherchez des
                                    informations sur les meilleures piscines du Burundi ? Vous êtes au
                                    bon endroit ! Notre site regorge de conseils, d'avis et de
                                    recommandations pour vous aider à trouver la piscine idéale pour
                                    vous détendre, vous amuser et profiter des plaisirs de l'eau.
                                    <br />
                                    <br />
                                    Profitez de votre expérience piscine au Burundi et passez
                                    des moments inoubliables dans ces oasis aquatiques. Bienvenue dans
                                    l'univers des piscines au Burundi !
                                </div>
                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading5 && sizeInfer5 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading5 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading5(false)
                            }, 1000)
                        }} src="../image/app_bureau.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>




            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}>Internet des objet </h2>

                    <div className={` ${imageLoading6 && sizeInfer6 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading6 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading6(false)
                            }, 1000)
                        }} src="../image/internet_des_objets.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Internet des objet </h2>
                        <p className="reduitTailParagr text-gray-500 p-1 text-[25px] ">
                            Notre site regorge d'informations, de ressources et d'inspiration pour vous aider à explorer les possibilités infinies offertes par l'Internet des objets.
                            <br /><br />
                            Parcourez nos articles, tutoriels et études de cas pour découvrir comment l'IoT transforme nos vies, des maisons intelligentes aux villes connectées en passant par l'industrie 4.0. Trouvez des conseils pratiques, des guides d'achat et des recommandations pour choisir les meilleurs dispositifs IoT adaptés à vos besoins.

                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>




            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}>  Intelligence artificielle</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}>Intelligence artificielle</h2>

                        <p className="reduitTailParagr text-gray-500 p-1 text-[22px] ">
                            <div className="reduitTailParagr text-gray-500">
                                <div className="reduitTailParagr text-gray-500 mt-6]">

                                    Ici, vous découvrirez un monde fascinant où la science rencontre la technologie pour créer des machines intelligentes capables d'apprendre, de raisonner et de résoudre des problèmes complexes.


                                    <br /><br />

                                    Préparez-vous à plonger dans l'univers de l'intelligence artificielle et à repousser les frontières de la connaissance. Bienvenue dans le monde de l'IA !         </div>


                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading7 && sizeInfer7 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading7 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading7(false)
                            }, 1000)
                        }} src="../image/intelligenceartificielle.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Apprentissange automatique</h2>

                    <div className={` ${imageLoading8 && sizeInfer8 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading8 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading8(false)
                            }, 1000)
                        }} src="../image/MachineLearning.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Apprentissange automatique </h2>
                        <p className="reduitTailParagr text-gray-500 p-1 text-[25px] ">
                            Que vous soyez un débutant curieux ou un expert chevronné, notre plateforme est conçue pour répondre à vos besoins en matière d'apprentissage automatique.
                            Explorez nos ressources, tutoriels et guides détaillés pour acquérir des connaissances approfondies sur les principes fondamentaux de l'apprentissage automatique. Découvrez les différentes techniques et algorithmes utilisés dans ce domaine, et apprenez à les appliquer pour résoudre des problèmes réels.
                            <br /><br />
                            Vous trouverez également des études de cas, des exemples de projets, et des astuces pratiques pour vous aider à améliorer vos compétences en matière d'apprentissage automatique.
                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>




            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Administration des  réseaux</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}>Administration des  réseaux</h2>

                        <p className="reduitTailParagr text-gray-500 p-1 text-[22px] ">
                            <div className="reduitTailParagr text-gray-500">
                                <div className="reduitTailParagr text-gray-500 mt-6]">
                                    Ici, vous trouverez tous les outils et les fonctionnalités nécessaires pour gérer et superviser les réseaux de manière efficace. Que vous soyez un administrateur réseau expérimenté ou que vous découvriez le domaine, notre plateforme est conçue pour répondre à vos besoins.

                                    Grâce à notre interface conviviale, vous pouvez surveiller l'état de votre réseau, gérer les équipements, configurer les paramètres de sécurité, effectuer des diagnostics, et bien plus encore. Notre objectif est de simplifier la gestion des réseaux tout en vous offrant des fonctionnalités avancées pour optimiser les performances et assurer la sécurité de votre infrastructure.
                                    <br /><br />
                                    N'hésitez pas à explorer les différentes sections de notre plateforme et à profiter de toutes les ressources mises à votre disposition. Nous sommes là pour vous accompagner à chaque étape et vous aider à atteindre vos objectifs en matière d'administration des réseaux.

                                </div>


                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading9 && sizeInfer9 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading9 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading9(false)
                            }, 1000)
                        }} src="../image/admnistration_des_reseaux.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>


            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Administration des de donnée</h2>

                    <div className={` ${imageLoading10 && sizeInfer10 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading10 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading10(false)
                            }, 1000)
                        }} src="../image/admnistration_des_basesdonnees.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} textSizeTitre text-[30px] text-blue-600 font-serif w-full text-center`}> Administration des de donnée</h2>
                        <p className="reduitTailParagr text-gray-500 p-1 text-[25px] ">
                            Ici, vous trouverez une mine d'informations sur le domaine passionnant de l'apprentissage automatique. Que vous soyez un débutant curieux ou un expert chevronné, notre plateforme est conçue pour répondre à vos besoins en matière d'apprentissage automatique.
                            <br /><br />
                            Vous trouverez également des études de cas, des exemples de projets, et des astuces pratiques pour vous aider à améliorer vos compétences en matière d'apprentissage automatique.

                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VisitorProgrammation


