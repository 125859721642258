/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { useEffect } from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FadeLoader } from 'react-spinners'
const Options2 = [
  { value: 'Lumicash', label: 'Lumicash' },
  { value: 'Ecocash', label: 'Ecocash' },
  { value: 'Bancobu inoti', label: 'Bancobu inoti' },
  { value: 'CCM akaravyo', label: 'CCM akaravyo' },
  { value: 'Mobinetel', label: 'Mobinetel' }
]
const defaultValue2 = Options2[0];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderBottom: '2px solid gray',
    borderRadius: '0px',
    backgroundColor: "transparent",
    top: '7px',
    with: '100%',
    boxShadow: state.isFocused ? '0 0 0 2px #ddd' : 'none',
    borderBottom: state.isFocused ? '2px solid blue' : '2px solid gray',
    '&:hover': {
      borderBottom: state.isFocused ? '2px solid blue' : '2px solid orange',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'orange' : 'transparent',
    color: state.isSelected ? '#333' : '#666',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    position: 'static',
    zIndex: '10000',
  }),
};



const Options = [
  { value: 'Sélectionner la banque', label: 'Sélectionner la banque', isDisabled: true },
  { value: 'Lumicash', label: 'Lumicash' },
  { value: 'Ecocash', label: 'Ecocash' },
  { value: 'Bancobu inoti', label: 'Bancobu inoti' },
  { value: 'CCM akaravyo', label: 'CCM akaravyo' },
  { value: 'Mobinetel', label: 'Mobinetel' }
]
const defaultValue = Options[0];


function NouvCientEchangeAdmin({ isdivScroll }) {

  const [imageLoading, SetImageLoading] = useState(true)
  const [spinnerButton, SetSpinnerButton] = useState(false)

  const [nom, Setnom] = useState('')
  const [animationClassNom, setAnimationClassNom] = useState('');
  const elemetRefNom = useRef(null)

  const [prenom, Setprenom] = useState('')
  const [animationClassPrenom, setAnimationClassPrenom] = useState('');
  const elemetRefPrenom = useRef(null)

  const [tel, SetTel] = useState('')
  const [animationClassTel, setAnimationClassTel] = useState('');
  const elemetRefTel = useRef(null)

  const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
  const [emailValue, setEmailValue] = useState('')
  const [animationClassEmail, setAnimationClassEmail] = useState('');
  const elemetRefEmail = useRef(null)



  const [PhotoAvantCNI, SetPhotoAvantCNI] = useState("")
  const [animationClassPhotoAvantCNI, setAnimationClassPhotoAvantCNI] = useState('');
  const elementPhotosAvant = useRef(null)

  const [PhotoAprestCNI, SetPhotoAprestCNI] = useState("")
  const [animationClassPhotoAprestCNI, setAnimationClassPhotoAprestCNI] = useState('');
  const elementPhotosApres = useRef(null)



  const [SelectValue, SetSelectValue] = useState([defaultValue])
  const [animationClassSelecteValue, setanimationClassSelecteValue] = useState('');
  const elemetRefSelect = useRef(null)
  const modifiedOptions = Options.slice(1)

  const history = useNavigate()


  const [codeAgent, SetcodeAgent] = useState('')
  const [animationClasscodeAgent, setanimationClasscodeAgent] = useState('');
  const elemetRefcodeAgent = useRef(null)

  const [SelectValue2, SetSelectValue2] = useState([defaultValue2])
  const [animationClassSelecteValue2, setanimationClassSelecteValue2] = useState('');
  const elemetRefSelect2 = useRef(null)




  const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
  const [passwordValue, setPasswordValue] = useState('')
  const [animationClassPassword, setAnimationClassPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const elemetRefPass = useRef(null)

  const [confPass, SetconfPass] = useState('')
  const [animationClassConfPass, setAnimationClassConfPass] = useState('');
  const [ConfpasswordVisible, setConfPasswordVisible] = useState(false);
  const elemetRefConfPass = useRef(null)

  const [profil, SetProfil] = useState(null);
  const [profilAnimationClass, setprofilAnimationClass] = useState('');

  const [photo, SetPhoto] = useState(null);

  const [autreCompte, SetautreCompte] = useState([]);
  const [boutLoading, SetboutLoading] = useState(false);


  const [cnii, setcnii] = useState(null);
  const [cnio, setcnio] = useState(null);


  const [imageLoading1, SetImageLoading1] = useState(true)
  const [imageLoading3, SetImageLoading3] = useState(true)


  useEffect(() => {
    const newResultats = [];
    SelectValue2.forEach(select => {
      const result = select.value.split('  ');
      newResultats.push(...result);
    });
    SetautreCompte(newResultats);
  }, [SelectValue2]);


  const handleSubmit = (e) => {
    e.preventDefault();

    if (nom.trim() === '') {
      toast.warning('Le nom est obligatoire', {
        autoClose: 2000
      });
      setAnimationClassNom('animate__animated animate__shakeX placeholder-shown:border-red-500');
      setTimeout(() => {
        setAnimationClassNom(' ');
      }, 3000);

      elemetRefNom.current.focus();
      return;
    } else if (prenom.trim() === '') {
      toast.warning('Le prénom est obligatoire', {
        autoClose: 2000
      });
      setAnimationClassPrenom('animate__animated animate__shakeX placeholder-shown:border-red-500');
      setTimeout(() => {
        setAnimationClassPrenom(' ');
      }, 3000);
      elemetRefPrenom.current.focus();
      return;
    }
    else if (tel.trim() === '') {
      toast.warning("Le numéro de téléphone est obligatoire !!", {
        autoClose: 2000
      });
      setAnimationClassTel('animate__animated animate__shakeX placeholder-shown:border-yellow-700 text-yellow-500 border-yellow-700');
      setTimeout(() => {
        setAnimationClassTel(' ');
      }, 3000);

      elemetRefTel.current.focus();
      return;
    }
    else if (emailValue.trim() === '') {
      toast.warning("L'adresse email est obligatoire !!", {
        autoClose: 2000
      });
      setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-yellow-500 text-yellow-500 border-yellow-700');
      setTimeout(() => {
        setAnimationClassEmail(' ');
      }, 3000);

      elemetRefEmail.current.focus();
      return;
    }
    else if (!emailValue.trim().match(emailRegex)) {
      toast.error("L'adresse email est incorrecte", {
        autoClose: 2000
      });
      setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500');
      setTimeout(() => {
        setAnimationClassEmail(' ');
      }, 3000);

      elemetRefEmail.current.focus();
      return;
    }

    else if (PhotoAvantCNI == "") {
      toast.warning(`Photo face avant du C.N.I est obligatoire`, {
        autoClose: 5000,
        position: "top-center",

      })
      setAnimationClassPhotoAvantCNI('animate__animated animate__shakeX  text-white border-b-2 bg-red-500 border-orange-500')
      setTimeout(() => {
        setAnimationClassPhotoAvantCNI(' ')
      }, 5000)

      return
    }
    else if (PhotoAprestCNI == "") {
      toast.warning(`Photo face arrière du C.N.I est obligatoire`, {
        autoClose: 5000,
        position: "top-center",

      })
      setAnimationClassPhotoAprestCNI('animate__animated animate__shakeX  text-white border-b-2 bg-red-500 border-orange-500')
      setTimeout(() => {
        setAnimationClassPhotoAprestCNI(' ')
      }, 5000)

      return
    }
    else if (SelectValue.value == undefined) {
      toast.warning('Sélectionnez votre compte principal', {
        autoClose: 2000
      });
      setanimationClassSelecteValue('animate__animated animate__shakeX border-red-500');
      setTimeout(() => {
        setanimationClassSelecteValue(' ');
      }, 3000);

      return;
    } else if (codeAgent.trim() === '') {
      toast.warning('Le code agent est obligatoire', {
        autoClose: 2000
      });
      setanimationClasscodeAgent('animate__animated animate__shakeX border-red-500');
      setTimeout(() => {
        setanimationClasscodeAgent(' ');
      }, 3000);

      elemetRefcodeAgent.current.focus();
      return;
    } else if (SelectValue2.length == 0) {
      toast.warning('Sélectionnez votre compte supplémentaire', {
        autoClose: 2000
      });
      setanimationClassSelecteValue2('animate__animated animate__shakeX border-red-500');
      setTimeout(() => {
        setanimationClassSelecteValue2(' ');
      }, 3000);

      return;
    } else if (passwordValue.trim() === "") {
      toast.warning("Le mot de passe est obligatoire !!", {
        autoClose: 2000
      });
      setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-yellow-700 text-yellow-500 border-yellow-700');
      setTimeout(() => {
        setAnimationClassPassword(' ');
      }, 3000);

      elemetRefPass.current.focus();
      return;
    } else if (!passwordValue.trim().match(PasswordRegex)) {
      toast.error(`Le mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial`, {
        autoClose: 5000,
        position: "top-center",
        className: 'my-toast'
      });
      setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500');
      setTimeout(() => {
        setAnimationClassPassword(' ');
      }, 5000);

      elemetRefPass.current.focus();
      return;
    } else if (confPass.trim() === '') {
      toast.warning(`Confirmez le mot de passe saisi`, {
        autoClose: 5000,
        position: "top-center",
        className: 'my-toast'
      });
      setAnimationClassConfPass('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500');
      setTimeout(() => {
        setAnimationClassConfPass(' ');
      }, 5000);

      elemetRefConfPass.current.focus();
      return;
    } else if (confPass !== passwordValue) {
      toast.error(`La confirmation du mot de passe ne correspond pas au mot de passe saisi. Veuillez vérifier et réessayer`, {
        autoClose: 5000,
        position: "top-center",
        className: 'my-toast'
      });
      setAnimationClassConfPass('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500');
      setTimeout(() => {
        setAnimationClassConfPass(' ');
      }, 5000);
      elemetRefPass.current.focus();
      return;
    } else if (profil == null) {
      toast.error(`Votre profil est obligatoire`, {
        autoClose: 3000,
        position: "top-center",
        className: 'my-toast'
      });
      setprofilAnimationClass('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500');
      setTimeout(() => {
        setprofilAnimationClass(' ');
      }, 3000);

      return;
    }
    else {
      SetSpinnerButton(true)
      const formData = new FormData();
      formData.append('nom', nom);
      formData.append('prenom', prenom);
      formData.append('email', emailValue);
      formData.append('tel', tel);
      formData.append('codeagent', codeAgent);
      formData.append('banck', SelectValue.value);
      formData.append('compte', autreCompte);
      formData.append('password', passwordValue);
      formData.append('profil', photo);
      formData.append('cnio', cnio);
      formData.append('cnii', cnii);
      axios.post(" https://btrproject.burundientempsreel.com/echangeur/supperadd", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          if (response.data.error) {
            toast.error(response.data.error);
          } else {
            toast.success(response.data, {
              autoClose: 10000
            });
            setEmailValue('')
            setPasswordValue('')
            SetPhoto(null)
            Setprenom('')
            Setnom('')
            SetProfil(null)
            SetautreCompte('')
            SetcodeAgent('')
            SetSelectValue('')
            SetSelectValue2([])
            SetconfPass('')
            SetTel('')
            history("/Admin/Client")
          }

        })
        .catch(error => {
          console.error(error.message);
        }).finally(() => {
          SetSpinnerButton(false)
        });

    }
  }

  const [mobile, SetMobile] = useState(window.innerWidth < 882)
  const [mobile1, SetMobile1] = useState(window.innerWidth < 711)
  const [mobile2, SetMobile2] = useState(window.innerWidth < 507)
  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth < 882)
      SetMobile1(window.innerWidth < 711)
      SetMobile2(window.innerWidth < 507)
    }
    window.addEventListener('resize', hundleSize)
    return () => window.removeEventListener('resize', hundleSize)
  }, [])


  return (
    <div className=" h-max flex justify-center p-1 sm:p-2 flex-col items-center">
      <div className={`p-2 bg-white text-gray-500 mt-4 rounded shadow max-sm:w-full ${mobile ? 'w-[95%]' : 'w-[80%]'}`}>
        <div className='p-2'>
          <h1 className='md:text-[35px] sm:text-[25px] text-center text-[15px] text-blue-700'>Bienvenue sur notre site web d'échange  de monnaie électronique</h1>
          <div className="sm:text-[20px] text-[12px]">
            Nous sommes ravis
            que vous ayez choisi de vous inscrire.
            Pour commencer, veuillez remplir les
            informations demandées
            dans le formulaire ci-dessous. Nous aurons besoin des détails suivants :
            <br />
            <div className="sm:pl-[20px] pl-2">
              -Nom: Veuillez saisir votre nom de famille .<br />
              -Prénom : Veuillez saisir votre prénom de famille .<br />
              -Adresse e-mail valide : Assurez-vous de fournir une adresse e-mail correcte que nous pourrons utiliser pour vous contacter.<br />
              - Numéro de téléphone : Veuillez entrer votre numéro de téléphone actif.<br />
              -Choix de l'opérateur : Sélectionnez l'opérateur que vous utilisez pour votre monnaie électronique, que ce soit Lumicash, Ecocash ou autre.<br />
              -Code agent : Si vous disposez d'un code agent spécifique, veuillez le mentionner ici.<br />
              -Nom de la carte SIM : Veuillez indiquer le nom associé à votre carte SIM.<br />
              -Mot de passe : Choisissez un mot de passe sécurisé pour votre compte.
            </div>
            <br />
            <br />
            Assurez-vous de remplir tous les champs requis
            avec des informations précises et à jour. Une fois que
            vous avez soumis le formulaire, nous traiterons votre demande  en envoyant un message sur votre email dans les plus brefs délais.
          </div>
        </div>



        <div className='flex items-center flex-col'>
          <h2 className="sm:p-2 p-1 text-center mb-[1em] text-blue-600 sm:text-[35px] text-[15px] "> S'inscrire</h2>
          <div className={`bg-white border rounded-2xl border-blue-600 shadow-2xl p-1 m-3 sm:p-4   ${mobile ? 'w-full' : 'w-[40em]'} h-max`}>
            <div className={` sm:m-3 sm:p-2 p-1 m-1  rounded  h-max ${mobile ? 'w-full' : 'w-[35em]'} `}>
              <form onSubmit={handleSubmit}>
                <div className="flex mb-6 flex-col">
                  <div className={`transition-all flex w-[100%]  ${mobile1 ? 'flex-col' : 'justify-between mt-5 '}`}>
                    <div class={`relative h-11    ${mobile1 ? 'w-full mb-3' : 'w-1/2 mr-2'} `}>
                      <input ref={elemetRefNom} value={nom} onInput={(e) => Setnom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNom}`} placeholder=" " />
                      <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Nom
                      </label>
                    </div>
                    <div class={`relative h-11    ${mobile1 ? 'w-full mb-3' : 'w-1/2 mr-2'} `}>
                      <input value={prenom} ref={elemetRefPrenom}
                        onInput={(e) => Setprenom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPrenom}`} placeholder=" "
                      />
                      <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Prenom
                      </label>
                    </div>
                  </div>
                  <div className={`transition-all flex w-[100%]  ${mobile1 ? 'flex-col' : 'justify-between mt-5 '}`}>
                    <div class={`relative h-11    ${mobile1 ? 'w-full mb-3' : 'w-1/2 mr-2'} `}>
                      <input type='tel' value={tel} ref={elemetRefTel} onInput={(e) => SetTel(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassTel}`} placeholder=" " />
                      <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Téléphone
                      </label>
                    </div>
                    <div class={`relative h-11    ${mobile1 ? 'w-full mb-3' : 'w-1/2 mr-2'} `}>
                      <input value={emailValue} ref={elemetRefEmail}

                        onInput={(e) => setEmailValue(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`} placeholder=" "
                      />
                      <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Email valide
                      </label>
                    </div>
                  </div>

                  <div className={`mt-[10px] transition-all flex  ${mobile2 ? 'flex-col' : ''} justify-between`}>
                    <div ref={elementPhotosAvant} class={`relative ${mobile2 ? 'w-full' : ''} h-max mt-3 flex flex-col justify-center items-center bg-gray-100 p-2 text-gray-700  mr-2 ${animationClassPhotoAvantCNI}`}>
                      <input accept='image/*' type='file' id="CNIAvant" hidden onChange={(e) => { const PhotosAvantCNI = e.target.files[0]; setcnii(PhotosAvantCNI); SetPhotoAvantCNI(URL.createObjectURL(PhotosAvantCNI)) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPhotoAvantCNI}`} />
                      <label htmlFor="CNIAvant" class={`px-2  py-1 rounded cursor-pointer sm:text-center `}>
                        Photo face avant du C.N.I
                      </label>
                      <div className={` ${!PhotoAvantCNI ? 'w-[0em] h-[0em] opacity-0' : 'w-[12.5em] block h-[12.5em] opacity-100'} transition-all   mt-1 rounded  bg-white  border border-blue-500`}>
                        {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                          setTimeout(() => {
                            SetImageLoading(false)
                          }, 1000)
                        }} src={PhotoAvantCNI} className="w-full h-full object-contain pointer-events-none" />
                      </div>
                    </div>
                    <div ref={elementPhotosApres} class={`relative flex flex-col justify-center items-center h-max mt-3 bg-gray-100 text-gray-700 p-2  mr-2 ${animationClassPhotoAprestCNI}`}>
                      <input accept='image/*' type='file' id="CNIArrier" hidden onChange={(e) => { const PhotosApprestCNI = e.target.files[0]; setcnio(PhotosApprestCNI); SetPhotoAprestCNI(URL.createObjectURL(PhotosApprestCNI)) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500  ${animationClassPhotoAprestCNI}`} placeholder=" " />
                      <label htmlFor="CNIArrier" class={` px-2 t max-sm:text-center py-1 rounded cursor-pointer `}>
                        Photo face arrière du C.N.I
                      </label>
                      <div className={` ${!PhotoAprestCNI ? 'w-[0em] h-[0em] opacity-0' : 'w-[12.5em] block h-[12.5em] opacity-100'} transition-all   mt-1 rounded  bg-white border border-blue-500`}>
                        {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                          setTimeout(() => {
                            SetImageLoading1(false)
                          }, 1000)
                        }} src={PhotoAprestCNI} className="w-full h-full object-contain" />
                      </div>
                    </div>
                  </div>

                  <div className={`transition-all flex w-[100%]  ${mobile1 ? 'flex-col mt-5' : 'justify-between mt-5 '}`}>
                    <div class={`relative h-11    ${mobile1 ? 'w-full mb-3' : 'w-1/2 mr-2'} `}>
                      <div className={`flex flex-col  h-11 w-full relative  ${animationClassSelecteValue}`}>
                        <Select ref={elemetRefSelect} onChange={SetSelectValue} defaultValue={SelectValue} options={modifiedOptions} styles={customStyles} >
                        </Select>
                      </div>
                    </div>

                    <div class={`relative h-11    ${mobile1 ? 'w-full mb-3' : 'w-1/2 mr-2'} `}>
                      <input value={codeAgent} ref={elemetRefcodeAgent} onInput={(e) => SetcodeAgent(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasscodeAgent}`} placeholder=" "
                      />
                      <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Code Agent
                      </label>
                    </div>
                  </div>
                  <div className={`mt-[30px] transition-all  w-[100%] ${mobile1 ? 'mb-5' : ''} `}>

                    <div class="relative h-11  w-full ">
                      <div className={`flex flex-col  h-11 w-full relative   ${animationClassSelecteValue2}`}>
                        <Select ref={elemetRefSelect2} onChange={(element) => { SetSelectValue2(element); }} defaultValue={SelectValue2} isMulti closeMenuOnSelect={true} options={Options2} styles={customStyles} >
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className={`transition-all flex w-[100%]  ${mobile1 ? 'flex-col' : 'justify-between mt-5 '}`}>
                    <div class={`relative h-11    ${mobile1 ? 'w-full mb-3' : 'w-1/2 mr-2'} `}>
                      <div class="relative h-11 w-full  mr-2 ">
                        <input ref={elemetRefPass} onInput={(e) => { setPasswordValue(e.target.value) }} type={passwordVisible ? 'text' : 'password'} className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassword}`} placeholder=" " />
                        <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Mot de passe
                        </label>
                      </div>
                      <div onClick={() => { setPasswordVisible(!passwordVisible) }} className=" cursor-pointer w-[20px] h-[20px]   absolute flex justify-center items-center top-5 right-2 ">
                        <i class={`bi ${passwordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'} absolute`}></i>
                      </div>
                    </div>

                    <div class={`relative h-11    ${mobile1 ? 'w-full mb-3' : 'w-1/2 mr-2'} `}>
                      <div class="relative h-11 w-full  mr-2 ">
                        <input ref={elemetRefConfPass} value={confPass} onInput={(e) => { SetconfPass(e.target.value) }} type={ConfpasswordVisible ? 'text' : 'password'} className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassConfPass}`} placeholder=" " />
                        <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Confirme le mot de passe
                        </label>
                      </div>
                      <div onClick={() => { setConfPasswordVisible(!ConfpasswordVisible) }} className=" cursor-pointer w-[20px] h-[20px]   absolute flex justify-center items-center top-5 right-2 ">
                        <i class={`bi ${ConfpasswordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'} absolute`}></i>
                      </div>
                    </div>

                  </div>
                </div>
                <div className={`${profilAnimationClass}`}>
                  <label htmlFor='profiles' className="w-max">
                    <span>Photos de profil</span>
                    <label for="profiles" className='bi bi-image-fill cursor-pointer bg-blue-500 px-1 ml-4 rounded text-white'></label>
                  </label>
                  <input type="file" name='profil' accept='image/*' hidden id='profiles' onChange={(e) => { const UrlFile = e.target.files[0]; SetPhoto(UrlFile); SetProfil(URL.createObjectURL(UrlFile)) }} />
                  {profil && <div className={`${profil == "" ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'} w-[50%] h-[50%] bg-white  mt-[20px] rounded-2xl border  relative overflow-hidden border-blue-600`}>
                    {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false' onLoad={() => {
                      setTimeout(() => {
                        SetImageLoading(false)
                      }, 1000)
                    }} src={profil} alt="" className={`w-full h-full object-cover`} />
                  </div>}
                </div>

                <div className="flex justify-end items-center">


                  {spinnerButton ? (
                    <>
                      <div className="flex justify-end items-center mt-4 relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                        <div className='absolute bg-transparent   pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                          <FadeLoader
                            color="rgb(255, 255, 255)"
                            height={10}
                            margin={-9}
                            radius={100}
                            speedMultiplier={1}
                            width={1}
                          /></div>
                        <input type="submit" id="send" value="Créer" class=" transition-all "></input>
                        <i class="bi bi-send ml-2 "></i>
                      </div>
                    </>
                  ) : (
                    <>
                      <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-blue-600   p-2 rounded  text-white">
                        <input type="submit" id="send" value='Créer' className='cursor-pointer '></input>
                        <i class="bi bi-send ml-2 "></i>
                      </label>

                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="text-gray-500 w-[90%] mt-[30px] text-center">
            Si vous avez des questions supplémentaires, n'hésitez pas à nous contacter. Merci de votre confiance
            et bienvenue dans notre communauté d'échange de monnaie électronique !
          </div>
        </div>
      </div>
    </div>
  )
}

export default NouvCientEchangeAdmin

