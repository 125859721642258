
import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
function SchoolCoursNavBars() {
    const { pathname } = useLocation()

    const [menuClicked, setMenuClicked] = useState(false)

    useEffect(() => {
        const hiddenOnget = () => {
            setMenuClicked(false)
        };

        window.addEventListener('click', hiddenOnget);

        return () => {
            window.removeEventListener('click', hiddenOnget);
        };
    }, []);


    return (
        <>
            {/* pour le grand ecran */}
            <div className=' HiddenNAvGrand h-[13%] bg-gray-300 sticky top-0 z-50  border-b-2 border-blue-500'>
                <div className="flex justify-between h-full w-full items-center ">
                    <div className="flex items-center logosNameee">
                        <div className="h-[3.5em] w-[3.5em] logoss transition-all  relative overflow-hidden ml-3 rounded ">
                            <img draggable='false' src={` https://btrproject.burundientempsreel.com/uploads/logo/btr.png`} alt="" className='h-full w-full object-cover object-center' />
                        </div>
                        <div className="marquee namesss font-serif text-[20px] ml-3   transition-all "><span style={{ fontFamily: 'Monotype Corsiva' }} className='text-[30px] names '><span className='text-green-600 nameBTR'>B</span><span><sup className='text-red-600 nameBTR'>T</sup></span><span className='text-white nameBTR'>R</span></span> <span className='ml-5'><span style={{ fontFamily: 'Monotype Corsiva' }} className='text-[25px] text-green-700'>B</span>urundi en <span style={{ fontFamily: 'Monotype Corsiva' }} className='text-[25px] text-red-700'>T</span>emps <span style={{ fontFamily: 'Monotype Corsiva' }} className='text-[25px] text-white'>R</span>éel</span></div>
                    </div>

                    <div className=" h-full w-[60%]  flex justify-around items-end  text-[12px] ">
                        <div className={`  h-full w-[100%] flex justify-around items-end  text-[12px]`}>
                            <div className="  flex  w-[100%] ">
                                {pathname === "/Visit/Cours" ? (
                                    <Link to="/Visit/Cours" className='   mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>
                                        <i class="bi bi-houses-fill text-[20px]"></i>
                                        <p className='sizeText'>Acceuil</p>
                                    </Link>) : (
                                    <Link to="/Visit/Cours" className='   mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                        <i class="bi bi-houses-fill text-[20px]"></i>
                                        <p className='sizeText'>Acceuil</p>
                                    </Link>)
                                }

                                {pathname === "/Visit/Cours/Educ" || pathname === '/Visit/Cours/Educ/Detail' ? (

                                    <Link to="/Visit/Cours/Educ" className='   mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>
                                        <i class="bi bi-play-btn-fill text-[20px]"></i>
                                        <p className='sizeText'> Tutoriels</p>
                                    </Link>
                                ) : (

                                    <Link to="/Visit/Cours/Educ" className='   mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                        <i class="bi bi-play-btn-fill text-[20px]"></i>
                                        <p className='sizeText'>Tutoriels</p>
                                    </Link>
                                )

                                }
                                {pathname === "/Visit/Cours/Connaiss" || pathname === "/Visit/Cours/Connaiss/Detail" || pathname === "/Visit/Cours/Connaiss/PremDetail" || pathname === "/Visit/Exchange/connected" ? (
                                    <Link to="/Visit/Cours/Connaiss" className='   mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>
                                        <i class="bi bi-info-circle text-[20px]"></i>

                                        <p className='sizeText'>Formations</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Cours/Connaiss" className=' mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>

                                        <i class="bi bi-info-circle text-[20px]"></i>
                                        <p className='sizeText'>Formations</p>
                                    </Link>
                                )}

                                {pathname === "/Visit/Cours/Connaiss" || pathname === "/Visit/Cours/Connaiss/Detail" || pathname === "/Visit/Cours/Connaiss/PremDetail" || pathname === "/Visit/Exchange/connected" ? (
                                    <Link to="/Visit/Cours/Connaiss" className='   mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>
                                        <i class="text-[20px]">✦</i>
                                        <p className='sizeText'>Premium</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Cours/Connaiss" className=' mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>

                                        <i class="text-[20px]">✦</i>
                                        <p className='sizeText'>Premium</p>
                                    </Link>
                                )}


                                {pathname === "/Visit/Cours/Connaiss" || pathname === "/Visit/Cours/Connaiss/Detail" || pathname === "/Visit/Cours/Connaiss/PremDetail" || pathname === "/Visit/Exchange/connected" ? (
                                    <Link to="/Visit/Cours/Connaiss" className='   mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>
                                        <i class="bi bi-blockquote-right text-[20px]"></i>

                                        <p className='sizeText'>Blog</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Cours/Connaiss" className=' mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                        <i class="bi bi-blockquote-right text-[20px]"></i>
                                        <p className='sizeText'>Blog</p>
                                    </Link>
                                )}
                                {pathname === "/Visit/Cours/Connaiss" || pathname === "/Visit/Cours/Connaiss/Detail" || pathname === "/Visit/Cours/Connaiss/PremDetail" || pathname === "/Visit/Exchange/connected" ? (
                                    <Link to="/Visit/Cours/Connaiss" className='   mx-1 w-[10em] h-[4.2em] rounded-se-lg mr[2px]  rounded-tl-lg pb-2 bg-blue-600 text-white  flex flex-col justify-center items-center'>
                                        <i class="bi bi-chat-left-text-fill text-[20px]"></i>

                                        <p className='sizeText'>Forum</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Cours/Connaiss" className=' mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>

                                        <i class="bi bi-chat-left-text-fill  text-[20px]"></i>
                                        <p className='sizeText'>Forum</p>
                                    </Link>
                                )}

                                <Link to="/" className=' mx-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-blue-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                    <i class="bi bi-arrow-return-left text-[20px]"></i>
                                    <p className='sizeText'>Aller au cite</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* pour les petit ecrand */}
            <div className='HiddenNAvPetit h-[13%] bg-gray-300 sticky top-0 z-50 border-b-2  border-blue-500'>
                <div className="flex justify-between h-full overflow-visible w-full items-center">
                    <div className="flex items-center  logosName">
                        <div className="h-[3.5em] w-[3.5em] transition-all  relative overflow-hidden ml-3 rounded logo">
                            <img draggable='false' src={` https://btrproject.burundientempsreel.com/uploads/logo/btr.png`} alt="" className='h-full w-full object-cover object-center' />
                        </div>
                        <div className="hadName font-serif text-[20px] ml-3  marquee0 transition-all name"><span style={{ fontFamily: 'Monotype Corsiva' }} className='text-[30px] names '><span className='text-green-600'>B</span><span><sup className='text-red-600'>T</sup></span><span className='text-white'>R</span></span> <span className='ml-5'><span style={{ fontFamily: 'Monotype Corsiva' }} className='text-[25px] text-green-700'>B</span>urundi en <span style={{ fontFamily: 'Monotype Corsiva' }} className='text-[25px] text-red-700'>T</span>emps <span style={{ fontFamily: 'Monotype Corsiva' }} className='text-[25px] text-white'>R</span>éel</span></div>
                    </div>
                    <div onClick={(e) => { setMenuClicked(!menuClicked); e.stopPropagation() }} className='z-[60]  cursor-pointer  mr-2   rounded-tl-lg pb-2 '>
                        <i class="bi bi-list text-[20px] p-2 hover:bg-black transition-all hove:rounded hover:text-fuchsia-50"></i>
                    </div>

                    <div className={`bg-[#0000005f]  transition-all  ${!menuClicked ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'}  w-full h-[90vh]  font-serif overflow-hidden mt-[1.5px] top-full left-0 absolute z-[55] flex justify-end`}>
                        <div className={`w-[10em] transition-all navFullScreen  ${!menuClicked ? 'translateTransform' : ''}  bg-gray-300 h-full   overflow-hidden`}>
                            <div className={`h-full`}>

                                {pathname === "Visit/Cours" ? (
                                    <Link to="Visit/Cours" className='w-full h-[3em] pl-2 flex justify-start  items-center bg-blue-600 text-white '>
                                        <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                                        <p >Acceuil</p>
                                    </Link>) : (
                                    <Link to="Visit/Cours" className='w-full h-[3em] pl-2 border-b flex justify-start  items-center hover:bg-blue-400 hover:text-white transition-all  '>
                                        <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                                        <p >Acceuil</p>
                                    </Link>
                                )
                                }

                                {pathname === "/Visit/Cours/Educ" || pathname === '/Visit/Cours/Educ/Detail' ? (
                                    <Link to="/Visit/Cours/Educ" className='w-full h-[3em] pl-2 flex justify-start border-b items-center bg-blue-600 text-white '>
                                        <i class="bi bi-play-btn-fill text-[20px] mr-4"></i>
                                        <p className='sizeText'> Tutoriels</p>
                                    </Link>) : (
                                    <Link to="/Visit/Cours/Educ" className='w-full h-[3em] border-b pl-2 flex justify-start items-center hover:bg-blue-400 hover:text-white transition-all  '>
                                        <i class="bi bi-play-btn-fill text-[20px] mr-4"></i>
                                        <p className='sizeText'> Tutoriels</p>
                                    </Link>
                                )
                                }
                                {pathname === "/Visit/Cours/Connaiss" || pathname === "/Visit/Cours/Connaiss/Detail" || pathname === "/Visit/Cours/Connaiss/PremDetail" || pathname === "/Visit/Exchange/connected" ? (
                                    <Link to="/Visit/Cours/Connaiss" className='w-full h-[3em] pl-2 flex justify-start items-center bg-blue-600 text-white '>
                                        <i class="bi bi-info-circle text-[20px] mr-4"></i>

                                        <p className='sizeText'>Formations</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Cours/Connaiss" className='w-full h-[3em] pl-2 border-b flex justify-start  items-center hover:bg-blue-400 hover:text-white transition-all  '>
                                        <i class="bi bi-info-circle text-[20px] mr-4"></i>
                                        <p className='sizeText'>Formations</p>
                                    </Link>
                                )}

                                {pathname === "/Visit/Cours/Connaiss" || pathname === "/Visit/Cours/Connaiss/Detail" || pathname === "/Visit/Cours/Connaiss/PremDetail" || pathname === "/Visit/Exchange/connected" ? (
                                    <Link to="/Visit/Cours/Connaiss" className='w-full h-[3em] pl-2 flex justify-start items-center bg-blue-600 text-white '>
                                        <i class="text-[20px] mr-4">✦</i>
                                        <p className='sizeText'>Premium</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Cours/Connaiss" className='w-full h-[3em] pl-2 border-b flex justify-start  items-center hover:bg-blue-400 hover:text-white transition-all  '>
                                        <i class="text-[20px] mr-4">✦</i>
                                        <p className='sizeText'>Premium</p>
                                    </Link>
                                )}


                                {pathname === "/Visit/Cours/Connaiss" || pathname === "/Visit/Cours/Connaiss/Detail" || pathname === "/Visit/Cours/Connaiss/PremDetail" || pathname === "/Visit/Exchange/connected" ? (
                                    <Link to="/Visit/Cours/Connaiss" className='w-full h-[3em] pl-2 flex justify-start items-center bg-blue-600 text-white '>
                                        <i class="bi bi-blockquote-right text-[20px] mr-4"></i>
                                        <p className='sizeText'>Blog</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Cours/Connaiss" className='w-full h-[3em] pl-2 border-b flex justify-start  items-center hover:bg-blue-400 hover:text-white transition-all '>
                                        <i class="bi bi-blockquote-right text-[20px] mr-4"></i>
                                        <p className='sizeText'>Blog</p>
                                    </Link>
                                )}



                                {pathname === "/Visit/Cours/Connaiss" || pathname === "/Visit/Cours/Connaiss/Detail" || pathname === "/Visit/Cours/Connaiss/PremDetail" || pathname === "/Visit/Exchange/connected" ? (
                                    <Link to="/Visit/Cours/Connaiss" className='w-full h-[3em] pl-2 flex justify-start items-center bg-blue-600 text-white '>
                                        <i class="bi bi-chat-left-text-fill text-[20px] mr-4"></i>
                                        <p className='sizeText'>Forum</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Cours/Connaiss" className='w-full h-[3em] pl-2 border-b flex justify-start  items-center hover:bg-blue-400 hover:text-white transition-all  '>
                                        <i class="bi bi-chat-left-text-fill text-[20px] mr-4"></i>
                                        <p className='sizeText'>Forum</p>
                                    </Link>
                                )}

                                <Link to="/" className='w-full h-[3em]  pl-2 flex justify-start items-center hover:bg-blue-400 hover:text-white transition-all  '>
                                    <i class="bi bi-arrow-return-left text-[20px] mr-4"></i>
                                    <p className='sizeText'>Aller au cite</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SchoolCoursNavBars
