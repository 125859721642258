/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useRef } from "react";
import axios from "axios";
import SpinnerDemarage from "../../../../SpinnerDemarage/SpinnerDemarage";
import PropTypes from 'prop-types'
import { FadeLoader } from 'react-spinners'
function DetaillDemmandeEchangeAdmin({ isdivScroll }) {

  const [boutLoading, setboutLoading] = useState(false);
  const [formboutMotifLoading, setforMotivemboutLoading] = useState(false);



  const { id } = useParams();
  const history = useNavigate();

  const [loading, SetLoading] = useState(true);

  const [clickSlideHidden, setclickSlideHidden] = useState(false);

  const [titre, setTitre] = useState("");
  const [animationClasstitre, setAnimationClasstitre] = useState("");
  const titreRef = useRef(null);

  const [textarea, setTextarea] = useState("");
  const [animationClassTextarea, setAnimationClassTextarea] = useState("");
  const textaresRef = useRef(null);

  const [autoScrollHeight, setAutoScrollHeight] = useState(50);
  const [valueTextarea, setValueTextarea] = useState("");

  const [addMotif, setaddMotif] = useState(false);
  useEffect(() => {
    const windowClicked = () => {

      setclickSlideHidden(false);
      // navigate('/User/Echange/Commande')
    };

    window.addEventListener("click", windowClicked);

    return () => {
      window.removeEventListener("click", windowClicked);
    };
  }, []);

  const [comande, setCommade] = useState({});
  useEffect(() => {
    axios
      .get(` https://btrproject.burundientempsreel.com/Commande/details/${id}`)
      .then((response) => {
        setCommade(response.data);
        setTimeout(() => {
          SetLoading(false)
        }, 1000);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data);
        }
      });
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (titre.trim() === "") {
      toast.warning("Le titre est obligatoire", {
        autoClose: 2000,
      });
      setAnimationClasstitre(
        "animate__animated animate__shakeX placeholder-shown:border-red-500"
      );
      setTimeout(() => {
        setAnimationClasstitre("");
      }, 3000);
      titreRef.current.focus();
      return;
    }
    if (textarea.trim() === "") {
      toast.warning("Le motif est nécessaire", {
        autoClose: 2000,
      });
      setAnimationClassTextarea(
        "animate__animated animate__shakeX placeholder-shown:border-red-500"
      );
      setTimeout(() => {
        setAnimationClassTextarea("");
      }, 3000);
      textaresRef.current.focus();
      return;
    }

    setforMotivemboutLoading(true)
    axios
      .post(` https://btrproject.burundientempsreel.com/Commande/annuler/${id}`, {
        motif: textarea,
        titre: titre,
      })
      .then((response) => {
        toast.success('Vous avez anuller la commande avec success');
        history("/Admin/Command");
      })
      .catch((error) => {
        console.error(error.message);
        if (error.response) {
          toast.error(error.response.data);
        }
        setforMotivemboutLoading(false)
      }).finally(() => {
        setforMotivemboutLoading(false)
      });
  };

  const handleValide = () => {
    setboutLoading(true)

    axios
      .put(` https://btrproject.burundientempsreel.com/commande/valider/${id}`, {
        status: "success",
      })
      .then((response) => {
        toast.success("Successfully Valided");

        history("/Admin/Command");
      })
      .catch((error) => {
        toast.error("An error occurred while activeting seller.");
        console.log(error.response);
      }).finally(() => {
        setboutLoading(false)

      });
  };

  const [count, setCount] = useState(0);
  useEffect(() => {
    axios.get(' https://btrproject.burundientempsreel.com/commande/countstandbuy').then((response) => {
      ;
      setCount(response.data)
    })
  })




  const [loadingImages, setLoadingImages] = useState([]);


  const handleImageLoad = (index) => {
    setLoadingImages((prevLoadingImages) => {
      const updatedLoadingImages = [...prevLoadingImages];
      setTimeout(() => {
        updatedLoadingImages[index] = false;
      }, 5000);
      return updatedLoadingImages;
    });
  };

  const LoadingImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };
    const lazyLoad = () => {
      setImgSrc(src);
    };



    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LoadingImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };

  const [mobile, Setmobile] = useState(window.innerWidth < 549.8494040505)
  const [mobile1, Setmobile1] = useState(window.innerWidth < 361.8494040505)
  useEffect(() => {
    const hundleSize = () => {
      Setmobile(window.innerWidth < 549.8494040505)
      Setmobile1(window.innerWidth < 361.8494040505)
    }
    window.addEventListener('resize', hundleSize)
    return () => {
      window.removeEventListener('resize', hundleSize)
    }
  }, [])

  const Annulation = (e) => {
    setaddMotif(!addMotif);
    setTimeout(() => {
      if (isdivScroll.current) {
        isdivScroll.current.scrollTop = isdivScroll.current.scrollHeight;
      }
    }, 0);
    e.stopPropagation()
  };

  useEffect(() => {
    setTimeout(() => {
      isdivScroll.current.style.scrollBehavior = 'smooth';
    }, 0);
  }, []);

  return (
    <div className="min-h-screen">
      {loading && <SpinnerDemarage />}
      <div className="bg-white w-full hidden   pt-2 justify-around sticky top-0 z-10 navEcrantTail">
        <div
          onClick={(e) => {
            setclickSlideHidden(!clickSlideHidden);
            e.stopPropagation();
          }}
          className=" menus bi bi-list cursor-pointer hover:bg-black hidden  hover:text-white transition-all h-max text-[20px] px-2 py-1"
        >

        </div>

        <div className="ml-2 menus hidden text-center font-serif text-blue-700 navEchange w-[80%]"> Burundi temps  réel </div>

      </div>


      <div className="bg-white flex justify-center relative left-2 top-2 rounded-2xl border border-blue-700 shadow-2xl w-[95%] p-2">
        <div className="py-2  pl-1 sm:pl-10  w-full ">
          <h2 className="text-[25px] text-blue-700">
            Detail
            de la
            commande
          </h2>

          {comande.echangeurId && (
            <div className="w-full bg-white flex justify-center  p-2">
              <div className="p-2  w-full">
                <div className="text-gray-500">
                  <div className="">
                    <span>Montant</span> : {comande.montants}
                  </div>
                  <div className="">
                    <span>Compte</span> : {comande.Compte}
                  </div>
                  <div className="">
                    <span>Date</span> :Le{" "}
                    {new Date(comande.updatedAt).toLocaleDateString("fr-FR")}
                  </div>
                  <div className="">
                    <span>Status</span> : {comande.status}
                  </div>
                </div>
                <div className="mt-4">
                  <h3 className="text-[25px]  underline text-blue-800">Votre demande</h3>
                  <p style={{ whiteSpace: "pre-wrap" }} className="text-gray-500">{comande.Description}</p>
                </div>
                {comande.CommandesFiles &&
                  comande.CommandesFiles.length > 0 && (
                    <div className="mt-4">
                      <h3 className="text-[25px]  underline text-blue-700">
                        Capture d'écran
                      </h3>
                      <div className="text-gray-500 flex flex-wrap">
                        {comande.CommandesFiles.map((command, index) => {
                          return (
                            <div key={index} className={`border ${mobile ? 'flex-auto w-full h-max' : 'w-[14em] h-[14em]'}  border-blue-800 rounded-2xl relative overflow-hidden  m-2`}>
                              {loadingImages[index] ? (
                                <div className="absolute w-full h-full bg-red-600 "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                              ) : (
                                <img
                                  onLoad={() => handleImageLoad(index)}
                                  src={`https://btrproject.burundientempsreel.com/uploads/photoscommande/${command.Files}`}
                                  alt=""
                                  className="w-full h-full object-cover"
                                />)}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}



          <div className="mt-4 w-full">
            <div
              className={` ${!addMotif
                ? " transition-all h-0 hidden              opacity-0 pointer-events-none"
                : " transition-all opacity-100 pointer-events-auto"
                }  border-2 rounded-2xl border-dotted border-blue-600 p-2 mt-3   w-[30em] responsive31 `}
            >
              <form onSubmit={handleSubmit}>
                <div className="mb-6 w-full ">
                  <div className="transition-all  w-[100%]">
                    <div className="relative h-11 w-full InputsContact mr-2">
                      <input
                        ref={titreRef}
                        value={titre}
                        onInput={(e) => setTitre(e.target.value)}
                        className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClasstitre}`}
                        placeholder=" "
                      />
                      <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Titre
                      </label>
                    </div>

                    <div className="relative h-full w-full mt-[2em]">
                      <textarea
                        ref={textaresRef}
                        value={textarea}
                        onChange={(e) => {
                          setTextarea(e.target.value);
                          setAutoScrollHeight(e.target.scrollHeight);
                          setValueTextarea(e.target.value.trim().length);
                          if (!valueTextarea) {
                            setAutoScrollHeight(50);
                          }
                        }}
                        placeholder=" "
                        style={{ height: `${autoScrollHeight}px` }}
                        className={`peer personalTextarea leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassTextarea}`}
                      />
                      <label
                        className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-blue-gray-500`}
                      >
                        Motif d'annulation du commande
                      </label>
                    </div>

                    <div className="flex justify-end items-center mt-[20px]">


                      {formboutMotifLoading ? (
                        <>
                          <div className="flex justify-end items-center  relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                            <div className='absolute bg-transparent   pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                              <FadeLoader
                                color="rgb(255, 255, 255)"
                                height={10}
                                margin={-9}
                                radius={100}
                                speedMultiplier={1}
                                width={1}
                              /></div>

                            <input type="submit" id="send" value="Envoyer" class=" transition-all "></input>
                            <i class="bi bi-send ml-2 "></i>
                          </div>
                        </>
                      ) : (
                        <>

                          <div className="flex bg-blue-600 rounded cursor-pointer pl-2 py-1 text-white">
                            <input type="submit" id="send" value="Envoyer" />
                            <label
                              htmlFor="send"
                              className="w-max flex justify-end p-1 mx-1"
                            >
                              <i
                                title="Envoyer"
                                className="bi bi-send bg-blue-600 rounded cursor-pointer text-white"
                              >

                              </i>
                            </label>
                          </div>
                        </>
                      )}





                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className={`flex justify-end ${mobile1 ? 'flex-col' : ''}`}>
              <Link
                to="/Admin/Command"
                className="m-2 bg-green-600 px-2 text-center py-1 rounded text-white"
              >
                Retourner
              </Link>
              <button
                onClick={Annulation}
                className="m-2 bg-red-700 px-2 py-1 rounded text-white"
              >
                Annuler
              </button>



              {boutLoading ? (
                <>
                  <div disabled className="flex justify-end items-center cursor-no-drop  relative  opacity-80 rounded  ">
                    <div className='absolute  pointer-events-none    pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                      <FadeLoader
                        color="rgb(255, 255, 255)"
                        height={10}
                        margin={-9}
                        radius={100}
                        speedMultiplier={1}
                        width={1}
                      /></div>
                    <button onClick={handleValide} className="m-2 bg-gray-950 pointer-events-none bg-gray-0 px-2 py-1 rounded text-gray-400">Deja donnee</button>
                  </div>
                </>
              ) : (
                <button
                  onClick={handleValide}
                  className="m-2 bg-gray-600 px-2 py-1 rounded text-white"
                >
                  Deja donnee
                </button>
              )}







            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetaillDemmandeEchangeAdmin;
