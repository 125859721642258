/* eslint-disable react/jsx-pascal-case */
import React from "react";
import SideBarNav from "./SideBarNav";
import Product_List from "./Product_List";
function Content_Container({ isdivScroll }) {
  return (
    <>
      {/* <!-- container --> */}
      <div class="bg-white border-t text-gray-700 border-b sm:border-l sm:border-r sm:rounded-xl shadow">
        <Product_List isdivScroll={isdivScroll} />
      </div>

    </>
  );
}

export default Content_Container;
