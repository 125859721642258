import React from "react";
import HeadProductSeller from "./HeadProductSeller";
import ProductDetailSeller from "./ProductDetailSeller";
import SimilarProductSeller from "./SimilarProductSeller";

function Content_ContainerSellerDetaill({ isVisitorLogged, SetLoginPopUp }) {
  return (
    <>
      <div class="bg-white pb-32 mx-auto px-5  text-gray-700 overflow-x-hidden">
        <HeadProductSeller />
        <ProductDetailSeller isVisitorLogged={isVisitorLogged} SetLoginPopUp={SetLoginPopUp} />
        <SimilarProductSeller />
      </div>
    </>
  );
}

export default Content_ContainerSellerDetaill;
