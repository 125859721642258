import React, { useEffect, useState } from "react";

function OurPaymentMethod() {

  const [mobile, Setmobile] = useState(window.innerWidth < 327.9292828290293)
  const [LoadingPagePhotos1, SetLoadingPagePhotos1] = useState(true)
  const [LoadingPagePhotos2, SetLoadingPagePhotos2] = useState(true)
  const [LoadingPagePhotos3, SetLoadingPagePhotos3] = useState(true)
  const [LoadingPagePhotos4, SetLoadingPagePhotos4] = useState(true)
  const [LoadingPagePhotos5, SetLoadingPagePhotos5] = useState(true)
  const [LoadingPagePhotos6, SetLoadingPagePhotos6] = useState(true)

  useEffect(() => {
    const hundleResize = () => {
      Setmobile(window.innerWidth < 327.9292828290293)
    }

    window.addEventListener('resize', hundleResize)

    return () => {
      window.removeEventListener('resize', hundleResize)
    }
  }, [])

  return (
    <>
      <div className="bg-cream">
        <div className=" px-1 mx-auto flex flex-col lg:flex-col items-center">
          {/* <!-- Payment methods & Code --> */}
          <div className="text-center">
            <h1

              className="sm:text-5xl text-[20px] font-bold leading-tight "
            >
              EXPÉDITION ET MÉTHODE DE PAIEMENT
            </h1>
            <p className="text-black my-5">

              Envoyez le montant requis pour rejoindre notre plateforme.
              <br />
              Dès que notre vérification sera terminée, vous serez ajouté.
              <br />

              Notre paiement est sûr, fiable
            </p>
          </div>
          <div

            className="flex w-[90%] justify-center items-center "
          >
            <div className="w-full grid grid-cols-2   h-max  lg:grid-cols-3 mt-2 p-2 gap-2">

              <div className="flex  flex-col  shadow-2xl border bg-white p-2 sm:w-[18em] rounded-2xl">
                <div className="flex items-center justify-start   p-2">
                  <div className="relative sm:h-[7em] flex items-center  justify-center border border-dashed p-1 border-gray-400 rounded-2xl">
                    {LoadingPagePhotos1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false'
                      className="rounded-xl object-fill w-full h-full"
                      onLoad={() => {
                        SetLoadingPagePhotos1(false)
                      }}
                      src="../../image/ecocashe.jpg" />
                  </div>
                </div>
                <div className="pt-2">
                  <div className="flex flex-row items-start">
                    <h1 className="text-gray-800 text-sm font-semibold">
                      Nom :
                    </h1>
                    <p className="text-gray-800 text-sm">Ecocash</p>
                  </div>
                  <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                  <div className="flex flex-col items-start">
                    <p className="text-blue-800 font-semibold max-sm:text-[10px] text-sm">
                      <p className="w-full h-full text-center">
                        Bientôt l'intégration !!
                      </p>
                    </p>
                  </div>
                  <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                </div>
              </div>




              <div className="flex  flex-col  shadow-2xl border bg-white p-2 sm:w-[18em] rounded-2xl">
                <div className="flex items-center justify-start   p-2">
                  <div className="relative sm:h-[7em] flex items-center  justify-center border border-dashed p-1 border-gray-400 rounded-2xl">
                    {LoadingPagePhotos2 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false'
                      className="rounded-xl object-cover w-full h-full"
                      onLoad={() => {
                        SetLoadingPagePhotos2(false)
                      }} src="../../../image/lumitel.png" />
                  </div>
                </div>
                <div className="pt-2">
                  <div className="flex flex-row items-start">
                    <h1 className="text-gray-800 text-sm font-semibold">
                      Nom :
                    </h1>
                    <p className="text-gray-800 text-sm">Lumicash</p>
                  </div>
                  <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                  <div className="flex flex-col items-start">
                    <p className="text-blue-800 font-semibold max-sm:text-[10px] text-sm">
                      <p className="w-full h-full text-center">
                        Bientôt l'intégration !!
                      </p>
                    </p>
                  </div>
                  <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                </div>
              </div>



              <div className="flex  flex-col  shadow-2xl border bg-white p-2 sm:w-[18em] rounded-2xl">
                <div className="flex items-center justify-start   p-2">
                  <div className="relative sm:h-[7em] flex items-center  justify-center border border-dashed p-1 border-gray-400 rounded-2xl">
                    {LoadingPagePhotos3 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false'
                      className="rounded-xl object-fill w-full h-full"
                      onLoad={() => {
                        SetLoadingPagePhotos3(false)
                      }}
                      src="../../../image/mobinotel.png"
                    />
                  </div>
                </div>
                <div className="pt-2">
                  <div className="flex flex-row items-start">
                    <h1 className="text-gray-800 text-sm font-semibold">
                      Nom :
                    </h1>
                    <p className="text-gray-800 text-sm">Mobinotel</p>
                  </div>
                  <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                  <div className="flex flex-col items-start">
                    <p className="text-blue-800 font-semibold max-sm:text-[10px] text-sm">
                      <p className="w-full h-full text-center">
                        Bientôt l'intégration !!
                      </p>
                    </p>
                  </div>
                  <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                </div>


              </div>
              <div className="flex  flex-col  shadow-2xl border bg-white p-2 sm:w-[18em] rounded-2xl">
                <div className="flex items-center justify-start   p-2">
                  <div className="relative sm:h-[7em] flex items-center  justify-center border border-dashed p-1 border-gray-400 rounded-2xl">
                    {LoadingPagePhotos4 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false'
                      className="rounded-xl object-fill w-full h-full"
                      onLoad={() => {
                        SetLoadingPagePhotos4(false)
                      }}
                      src="../../../image/feza.png"
                    />
                  </div>
                </div>
                <div className="pt-2">
                  <div className="flex flex-row items-start">
                    <h1 className="text-gray-800 text-sm font-semibold">
                      Nom :
                    </h1>
                    <p className="text-gray-800 text-sm">Fezza</p>
                  </div>
                  <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                  <div className="flex flex-col items-start">
                    {/* <h1 className="text-gray-800 text-sm font-semibold">
                      Code:
                    </h1> */}
                    <p className="text-blue-800 font-semibold max-sm:text-[10px] text-sm">
                      <p className="w-full h-full text-center">
                        Bientôt l'intégration !!
                      </p>
                    </p>



                  </div>
                  <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                </div>
              </div>
              <div className="flex  flex-col  shadow-2xl border bg-white p-2 sm:w-[18em] rounded-2xl">
                <div className="flex items-center justify-start   p-2">
                  <div className="relative sm:h-[7em] flex items-center  justify-center border border-dashed p-1 border-gray-400 rounded-2xl">
                    {LoadingPagePhotos5 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false'
                      className="rounded-xl object-contain "
                      onLoad={() => {
                        SetLoadingPagePhotos5(false)
                      }} src="../../../image/New-logo-CECM-01.jpg" />
                  </div>
                </div>
                <div className="pt-2">
                  <div className="flex flex-row items-start">
                    <h1 className="text-gray-800 text-sm font-semibold">
                      Nom :
                    </h1>
                    <p className="text-gray-800 text-sm">Akaravyo</p>
                  </div>
                  <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                  <div className="flex flex-col items-start">
                    <p className="text-blue-800 font-semibold max-sm:text-[10px] text-sm">
                      <p className="w-full h-full text-center">
                        Bientôt l'intégration !!
                      </p>
                    </p>
                  </div>
                  <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                </div>

              </div>
              <div className="flex  flex-col  shadow-2xl border bg-white p-2 sm:w-[18em] rounded-2xl">
                <div className="flex items-center justify-start   p-2">
                  <div className="relative sm:h-[7em] flex items-center  justify-center border border-dashed p-1 border-gray-400 rounded-2xl">
                    {LoadingPagePhotos6 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false'
                      className="rounded-xl object-fill w-full h-full"
                      onLoad={() => {
                        SetLoadingPagePhotos6(false)
                      }} src="../../../image/BancobueNoti.jpg" />
                  </div>
                </div>
                <div className="pt-2">
                  <div className="flex flex-row items-start">
                    <h1 className="text-gray-800 text-sm font-semibold">
                      Nom :
                    </h1>

                    <p className="text-gray-800 text-sm">Bancobu inoti</p>
                  </div>
                  <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                  <div className="flex flex-col items-start">
                    {/* <h1 className="text-gray-800 text-sm font-semibold">
                      Code:
                    </h1> */}
                    <p className="text-blue-800 font-semibold max-sm:text-[10px] text-sm">
                      <p className="w-full h-full text-center">
                        Bientôt l'intégration !!
                      </p>
                    </p>
                  </div>
                  <hr className="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700   " />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-white -mt-2 sm:-mt-2 lg:-mt-2 z-40 relative">
          <svg
            className="xl:h-20 xl:w-full"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
              fill="currentColor"
            ></path>
          </svg>
          <div className="bg-white w-full h-10 -mt-px"></div>
        </div>
      </div>
    </>
  );
}

export default OurPaymentMethod;
