import React from "react";
import Profile from "./Profile";
function Content_Container() {
  return (
    <>
      {/* <!-- container --> */}
      <div class=" text-gray-700 bg-transparent ">
        <div class=" items-start">
          <Profile/>
        </div>
      </div>
    </>
  );
}

export default Content_Container;
