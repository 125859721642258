import React, { useEffect, useState } from "react";
import Content_ContainerSellerDetaill from "./Content_ContainerSellerDetaill";
function ProductComponentSellerDetaill({ isVisitorLogged, SetLoginPopUp }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div className={isMobile ? "  " : "  "}>
        <Content_ContainerSellerDetaill
          isVisitorLogged={isVisitorLogged}
          SetLoginPopUp={SetLoginPopUp}
        />
      </div>
    </>
  );
}

export default ProductComponentSellerDetaill;
