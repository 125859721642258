
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function MentorsVisitorHome() {
    const [imageLoading, SetImageLoading] = useState(true)
    const [imageLoading1, SetImageLoading1] = useState(true)
    const [imageLoading2, SetImageLoading2] = useState(true)
    const [imageLoading3, SetImageLoading3] = useState(true)



    const [sizeInfer, SetsizeInfer] = useState(window.innerWidth < 650)
    const [sizeInfer1, SetsizeInfer1] = useState(window.innerWidth < 650)
    const [sizeInfer2, SetsizeInfer2] = useState(window.innerWidth < 650)
    const [sizeInfer3, SetsizeInfer3] = useState(window.innerWidth < 650)


    const [titre, Settitre] = useState(window.innerWidth < 800.38383838282929)

    useEffect(() => {
        const hundleSize = () => {
            SetsizeInfer(window.innerWidth < 650)
            SetsizeInfer1(window.innerWidth < 650)
            SetsizeInfer2(window.innerWidth < 650)
            SetsizeInfer3(window.innerWidth < 650)


            Settitre(window.innerWidth < 800.38383838282929)


        }

        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    return (
        <>

            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}>  Bienvenue  sur notre site web dédié   Mentor</h2>

                    <div className={` ${imageLoading && sizeInfer ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading(false)
                            }, 1000)
                        }} src="../image/collaboration.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Bienvenue  sur notre site web dédié   Mentor</h2>
                        <p className="text-gray-500 p-1 text-[22px] mt-6 ">
                            <div className="text-gray-500">
                                Vous êtes sur le point de bénéficier d'un accompagnement personnalisé et de l'expertise de nos mentors qualifiés. Que vous soyez débutant ou expérimenté, nos mentors sont là pour vous guider dans votre parcours et répondre à vos questions.
                                Profitez de cette occasion unique pour développer vos compétences, acquérir de nouvelles connaissances et atteindre vos objectifs. Notre équipe est dévouée à vous fournir une expérience d'apprentissage enrichissante et motivante.
                            </div>
                        </p>
                    </div>
                </div>
            </div>


            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}>Financement  participatif</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Financement  participatif </h2>

                        <p className="text-gray-500 p-1 text-[22px] ">
                            <div className="text-gray-500">
                                <div className="reduitTailParagr  text-gray-500 mt-6]">
                                    Nous sommes ravis de vous accueillir en tant que visiteur intéressé par l'option du financement participatif. Ici, vous découvrirez une communauté dynamique de contributeurs et de porteurs de projets passionnants.


                                    <br /><br />
                                    Explorez nos différentes catégories de projets, découvrez des histoires inspirantes et choisissez celui qui vous parle le plus. Chaque projet est unique, et votre soutien peut contribuer à sa réussite.


                                </div>
                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading1 && sizeInfer1 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading1(false)
                            }, 1000)
                        }} src="../image/Finance.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Reclutement en ligne</h2>

                    <div className={` ${imageLoading2 && sizeInfer2 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading2 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading2(false)
                            }, 1000)
                        }} src="../image/recrutement_en_ligne.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Reclutement en ligne</h2>
                        <p className="reduitTailParagr  text-gray-500 mt-6]">

                            Vous avez choisi l'option de recrutement en ligne, et nous sommes ravis de vous accueillir. Ici, vous pouvez découvrir nos opportunités d'emploi passionnantes et postuler directement en ligne.


                            <br />
                            <br />
                            Que vous soyez à la recherche de votre premier emploi, d'une nouvelle opportunité ou d'un changement de carrière, nous avons une variété de postes dans différents domaines qui pourraient correspondre à vos compétences et à vos aspirations professionnelles.


                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Mentors en developpement personnel</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Mentors en developpement personnel </h2>

                        <p className="text-gray-500 p-1 text-[22px] ">
                            <div className="text-gray-500">
                                <div className="reduitTailParagr  text-gray-500 mt-6]">
                                    Ici, vous trouverez une opportunité unique de vous connecter avec des mentors inspirants et compétents dans différents domaines du développement personnel. Que vous souhaitiez améliorer votre confiance en vous, développer vos compétences en communication, ou trouver des stratégies pour atteindre vos objectifs, nos mentors sont là pour vous guider.
                                    Explorez notre sélection de mentors et découvrez leurs profils détaillés, leurs parcours et leurs domaines d'expertise. Vous pourrez choisir le mentor qui correspond le mieux à vos besoins et à vos objectifs personnels.


                                    Rejoignez-nous dès maintenant et commencez votre aventure de développement personnel avec nos mentors passionnés !
                                </div>
                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading3 && sizeInfer3 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading3 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading3(false)
                            }, 1000)
                        }} src="../image/educationperso.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Mentors en developpement entrepreneuriat</h2>

                    <div className={` ${imageLoading2 && sizeInfer2 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading2 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading2(false)
                            }, 1000)
                        }} src="../image/conseils.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}> Mentors en developpement entrepreneuriat</h2>
                        <p className="reduitTailParagr  text-gray-500 mt-6]">
                            Que vous soyez un étudiant aspirant entrepreneur ou un professionnel en reconversion, notre option Mentors en Développement Entrepreneurial vous permettra de bénéficier d'un accompagnement personnalisé pour concrétiser vos idées, développer vos compétences entrepreneuriales et atteindre vos objectifs.
                            <br /><br />
                            Nous vous souhaitons une excellente visite et beaucoup de succès dans vos projets entrepreneuriaux !
                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>

        </>
    )
}

export default MentorsVisitorHome



