/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import axios from "axios";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { FadeLoader } from 'react-spinners'
import { Link } from "react-router-dom";
function SinscrireAdmin() {

  const [imageLoading, SetImageLoading] = useState(true)
  const [spinnerButton, SetSpinnerButton] = useState(false)

  const [nom, Setnom] = useState("");
  const [animationClassNom, setAnimationClassNom] = useState("");
  const elemetRefNom = useRef(null);

  const [prenom, Setprenom] = useState("");
  const [animationClassPrenom, setAnimationClassPrenom] = useState("");
  const elemetRefPrenom = useRef(null);

  const [tel, SetTel] = useState("");
  const [animationClassTel, setAnimationClassTel] = useState("");
  const elemetRefTel = useRef(null);

  const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
  const [emailValue, setEmailValue] = useState("");
  const [animationClassEmail, setAnimationClassEmail] = useState("");
  const elemetRefEmail = useRef(null);

  const [nomSociete, SetnomSociete] = useState("");
  const [animationClassnomSociete, setanimationClassnomSociete] = useState("");
  const elemetRefnomSociete = useRef(null);

  const [codeSuperAgent, SetSuperAgent] = useState("");
  const [animationClassSuperAgent, setanimationClassSuperAgent] = useState("");
  const elemetRefSuperAgent = useRef(null);

  const [codeNIF, SetcodeNIF] = useState("");
  const [animationClasscodeNIF, setanimationClassNIF] = useState("");
  const elemetRefcodeNIF = useRef(null);

  const [codeIndentite, SetcodeIndentite] = useState("");
  const [animationClasscodeIndentite, setanimationClassIdentite] = useState("");
  const elemetRefcodeIndentite = useRef(null);
  const [cnii, setcnii] = useState(null);
  const [cnio, setcnio] = useState(null);


  const [PhotoAvantCNI, SetPhotoAvantCNI] = useState("")
  const [animationClassPhotoAvantCNI, setAnimationClassPhotoAvantCNI] = useState('');
  const elementPhotosAvant = useRef(null)

  const [PhotoAprestCNI, SetPhotoAprestCNI] = useState("")
  const [animationClassPhotoAprestCNI, setAnimationClassPhotoAprestCNI] = useState('');
  const elementPhotosApres = useRef(null)

  const [imageLoading1, SetImageLoading1] = useState(true)

  const [codeAddress, SetcodeAddress] = useState("");
  const [animationClasscodeAddress, setanimationClasscodeAddress] = useState("");
  const elemetRefcodeAddress = useRef(null);

  const PasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
  const [passwordValue, setPasswordValue] = useState("");
  const [animationClassPassword, setAnimationClassPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const elemetRefPass = useRef(null);

  const [confPass, SetconfPass] = useState("");
  const [animationClassConfPass, setAnimationClassConfPass] = useState("");
  const [ConfpasswordVisible, setConfPasswordVisible] = useState(false);
  const elemetRefConfPass = useRef(null);

  const [profil, SetProfil] = useState("");
  const [profilAnimationClass, setprofilAnimationClass] = useState('');
  const [photo, setPhoto] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nom.trim() === "") {
      toast.warning("Le nom est obligatoire", {
        autoClose: 2000,
      });
      setAnimationClassNom(
        "animate__animated animate__shakeX placeholder-shown:border-red-500"
      );
      setTimeout(() => {
        setAnimationClassNom(" ");
      }, 3000);
      elemetRefNom.current.focus();
      return;
    } else if (prenom.trim() === "") {
      toast.warning("Le  prenom est obligatoire", {
        autoClose: 2000,
      });
      setAnimationClassPrenom(
        "animate__animated animate__shakeX placeholder-shown:border-red-500"
      );
      setTimeout(() => {
        setAnimationClassPrenom(" ");
      }, 3000);
      elemetRefPrenom.current.focus();
      return;
    } else if (tel.trim() == "") {
      toast.warning("Le numero de téléphone  est obligatoire !!", {
        autoClose: 2000,
      });
      setAnimationClassTel(
        "animate__animated animate__shakeX placeholder-shown:border-yellow-700 text-yellow-500 border-yellow-700"
      );
      setTimeout(() => {
        setAnimationClassTel(" ");
      }, 3000);
      elemetRefTel.current.focus();
      return;
    } else if (emailValue.trim() == "") {
      toast.warning("L'address email est obligatoire !!", {
        autoClose: 2000,
      });
      setAnimationClassEmail(
        "animate__animated animate__shakeX placeholder-shown:border-yellow-500 text-yellow-500 border-yellow-700"
      );
      setTimeout(() => {
        setAnimationClassEmail(" ");
      }, 3000);
      elemetRefEmail.current.focus();
      return;
    } else if (!emailValue.trim().match(emailRegex)) {
      toast.error("L'address email est incorrect", {
        autoClose: 2000,
      });
      setAnimationClassEmail(
        "animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500"
      );
      setTimeout(() => {
        setAnimationClassEmail(" ");
      }, 3000);
      elemetRefEmail.current.focus();
      return;
    } else if (nomSociete.trim() == "") {
      toast.warning("Le nom de la société est obligatoire", {
        autoClose: 2000,
      });
      setanimationClassnomSociete(
        "animate__animated animate__shakeX border-red-500"
      );
      setTimeout(() => {
        setanimationClassnomSociete(" ");
      }, 3000);
      elemetRefnomSociete.current.focus();
      return;
    } else if (codeSuperAgent.trim() == "") {
      toast.warning("Le code de super-agent est obligatoire", {
        autoClose: 2000,
      });
      setanimationClassSuperAgent(
        "animate__animated animate__shakeX border-red-500"
      );
      setTimeout(() => {
        setanimationClassSuperAgent(" ");
      }, 3000);
      elemetRefSuperAgent.current.focus();
      return;
    } else if (codeSuperAgent.trim() == "") {
      toast.warning("Le code de super-agent est obligatoire", {
        autoClose: 2000,
      });
      setanimationClassSuperAgent(
        "animate__animated animate__shakeX border-red-500"
      );
      setTimeout(() => {
        setanimationClassSuperAgent(" ");
      }, 3000);
      elemetRefSuperAgent.current.focus();
      return;
    } else if (codeNIF.trim() == "") {
      toast.warning("Le NIF est obligatoire", {
        autoClose: 2000,
      });
      setanimationClassNIF("animate__animated animate__shakeX border-red-500");
      setTimeout(() => {
        setanimationClassNIF(" ");
      }, 3000);
      elemetRefcodeNIF.current.focus();
      return;
    } else if (codeIndentite.trim() == "") {
      toast.warning("Le numéro d'identité est obligatoire", {
        autoClose: 2000,
      });
      setanimationClassIdentite(
        "animate__animated animate__shakeX border-red-500"
      );
      setTimeout(() => {
        setanimationClassIdentite(" ");
      }, 3000);
      elemetRefcodeIndentite.current.focus();
      return;
    } else if (PhotoAvantCNI == "") {
      toast.warning(`Photo face avant du C.N.I est obligatoire`, {
        autoClose: 5000,
        position: "top-center",

      })
      setAnimationClassPhotoAvantCNI('animate__animated animate__shakeX  text-white border-b-2 bg-red-500 border-orange-500')
      setTimeout(() => {
        setAnimationClassPhotoAvantCNI(' ')
      }, 5000)

      return
    }
    else if (PhotoAprestCNI == "") {
      toast.warning(`Photo face arrière du C.N.I est obligatoire`, {
        autoClose: 5000,
        position: "top-center",

      })
      setAnimationClassPhotoAprestCNI('animate__animated animate__shakeX  text-white border-b-2 bg-red-500 border-orange-500')
      setTimeout(() => {
        setAnimationClassPhotoAprestCNI(' ')
      }, 5000)

      return
    } else if (codeAddress.trim() == "") {
      toast.warning("Le code Address est obligatoire", {
        autoClose: 2000,
      });
      setanimationClasscodeAddress(
        "animate__animated animate__shakeX border-red-500"
      );
      setTimeout(() => {
        setanimationClasscodeAddress(" ");
      }, 3000);
      elemetRefcodeAddress.current.focus();
      return;
    } else if (passwordValue.trim() == "") {
      toast.warning("Le mot de passe est obligatoire !!", {
        autoClose: 2000,
      });
      setAnimationClassPassword(
        "animate__animated animate__shakeX placeholder-shown:border-yellow-700 text-yellow-500 border-yellow-700"
      );
      setTimeout(() => {
        setAnimationClassPassword(" ");
      }, 3000);
      elemetRefPass.current.focus();
      return;
    } else if (!passwordValue.trim().match(PasswordRegex)) {
      toast.error(
        `Le mot de passe doit contenir au moins
                          une lettre minuscule,lettre majuscule,un chiffre et un caractère spécial
                        `,
        {
          autoClose: 5000,
          position: "top-center",
          className: "my-toast",
        }
      );
      setAnimationClassPassword(
        "animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500"
      );
      setTimeout(() => {
        setAnimationClassPassword(" ");
      }, 5000);
      elemetRefPass.current.focus();
      return;
    } else if (!passwordValue.length > 9) {
      toast.error(`Le mot de passe doit etre inférieure à 9`, {
        autoClose: 5000,
        position: "top-center",
        className: "my-toast",
      });
      setAnimationClassPassword(
        "animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500"
      );
      setTimeout(() => {
        setAnimationClassPassword(" ");
      }, 5000);
      elemetRefPass.current.focus();
      return;
    } else if (confPass.trim() == "") {
      toast.warning(`confirme le mot de passe saisi`, {
        autoClose: 5000,
        position: "top-center",
        className: "my-toast",
      });
      setAnimationClassConfPass(
        "animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500"
      );
      setTimeout(() => {
        setAnimationClassConfPass(" ");
      }, 5000);
      elemetRefConfPass.current.focus();
      return;
    } else if (confPass != passwordValue) {
      toast.error(
        `La confirmation du mot de passe ne correspond pas au mot de passe saisi. Veuillez vérifier et réessayer`,
        {
          autoClose: 5000,
          position: "top-center",
          className: "my-toast",
        }
      );
      setAnimationClassConfPass(
        "animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500"
      );
      setTimeout(() => {
        setAnimationClassConfPass(" ");
      }, 5000);
      elemetRefPass.current.focus();
      return;
    } else if (profil == "") {
      toast.warning(`Le photos de profil est nécessaire`, {
        autoClose: 5000,
        position: "top-center",
        className: "my-toast",
      });
      setprofilAnimationClass('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500');
      setTimeout(() => {
        setprofilAnimationClass(' ');
      }, 3000);
      return;
    } else {
      SetSpinnerButton(true)
      const formData = new FormData();
      formData.append("nom", nom);
      formData.append("prenom", prenom);
      formData.append("email", emailValue);
      formData.append("password", passwordValue);
      formData.append("tel", tel);
      formData.append("NIF", codeNIF);
      formData.append("address", codeAddress);
      formData.append("CNI", codeIndentite);
      formData.append("codeSuperAgent", codeSuperAgent);
      formData.append("nomSociete", nomSociete);
      formData.append("photo", photo);
      axios
        .post(" https://btrproject.burundientempsreel.com/surperagent/register", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.error) {
            toast.error(response.data.error);
          } else {
            toast.success(
              "Inscription réussie ! Bienvenue en tant que superagent. Explorez nos services et contactez-nous en cas de besoin. Bonne chance !",
              {
                autoClose: 15000,
              }
            );
            Setnom("");
            Setprenom("");
            setEmailValue("");
            SetcodeNIF("");
            SetcodeIndentite("");
            SetProfil(null);
            setPhoto(null);
            setPasswordValue("");
            SetconfPass("");
            SetTel("");
            SetnomSociete("");
            SetcodeAddress("");
            SetSuperAgent("");
            const executeAfter15Seconds = () => {
              window.location.reload();
            };
            setTimeout(executeAfter15Seconds, 15000);
          }
        })
        .catch((error) => {
          console.error(error.message.data);
          if (error.response) {
            console.log(error.response.data);
            toast.error(error.response.data.error);
          }
        }).finally(() => {
          SetSpinnerButton(false)
        });
    }
  };

  return (
    <>
      <div className=" bg-white  h-max flex justify-center p-2 flex-col items-center">
        <div className="log">
          <h2 className=" p-2 text-center mb-[1em] ms:text-[25px] text-[20px] md:text-[30px] font-serif mot2 ">
            <p className="text-blue-600 p-4">Créez votre compte gratuit</p>
          </h2>
          <div className="bg-white border shadow-2xl rounded-2xl border-blue-500 m-3 p-4  w-[35em] addClient h-max inscriptvisitions">
            <form onSubmit={handleSubmit}>
              <div className="flex mb-6 flex-col">
                <div className=" transition-all flex w-[100%] justify-between flexFormul conctat">
                  <div class="relative h-11 w-1/2 flexFormulInput mr-2 ">
                    <input
                      ref={elemetRefNom}
                      value={nom}
                      onInput={(e) => Setnom(e.target.value)}
                      className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNom}`}
                      placeholder=" "
                    />
                    <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Nom
                    </label>
                  </div>

                  <div class="relative h-11 flexFormulInput w-1/2  mr-2 ">
                    <input
                      value={prenom}
                      ref={elemetRefPrenom}
                      onInput={(e) => Setprenom(e.target.value)}
                      className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPrenom}`}
                      placeholder=" "
                    />
                    <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Prenom
                    </label>
                  </div>
                </div>
                <div className=" mt-[10px] transition-all flex w-[100%] justify-between  flexFormul">
                  <div class="relative h-11 w-1/2 flexFormulInput  mr-2 ">
                    <input
                      value={tel}
                      ref={elemetRefTel}
                      onInput={(e) => SetTel(e.target.value)}
                      className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassTel}`}
                      placeholder=" "
                    />
                    <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Téléphone
                    </label>
                  </div>
                  <div class="relative h-11 flexFormulInput w-1/2">
                    <input
                      value={emailValue}
                      ref={elemetRefEmail}

                      onInput={(e) => setEmailValue(e.target.value)}
                      className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`}
                      placeholder=" "
                    />
                    <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Email valide
                    </label>
                  </div>
                </div>

                <div className=" mt-[10px] transition-all flex w-[100%] justify-between flexFormul">
                  <div class="relative h-11 flexFormulInput w-1/2  mr-2">
                    <input
                      value={nomSociete}
                      ref={elemetRefnomSociete}
                      onInput={(e) => SetnomSociete(e.target.value)}
                      className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassnomSociete}`}
                      placeholder=" "
                    />
                    <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Nom de la société
                    </label>
                  </div>

                  <div class="relative h-11 flexFormulInput w-1/2">
                    <input
                      value={codeSuperAgent}
                      ref={elemetRefSuperAgent}
                      onInput={(e) => SetSuperAgent(e.target.value)}
                      className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassSuperAgent}`}
                      placeholder=" "
                    />
                    <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Code de super-agent
                    </label>
                  </div>
                </div>
                <div className=" mt-[10px]  transition-all flex w-[100%] justify-between  flexFormul">
                  <div class="relative h-11 flexFormulInput w-1/2 mr-2">
                    <input
                      value={codeNIF}
                      ref={elemetRefcodeNIF}
                      onInput={(e) => SetcodeNIF(e.target.value)}
                      className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasscodeNIF}`}
                      placeholder=" "
                    />
                    <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      NIF
                    </label>
                  </div>

                  <div class="relative h-11 flexFormulInput w-1/2">
                    <input
                      value={codeIndentite}
                      ref={elemetRefcodeIndentite}
                      onInput={(e) => SetcodeIndentite(e.target.value)}
                      className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasscodeIndentite}`}
                      placeholder=" "
                    />
                    <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Identité
                    </label>
                  </div>
                </div>

                <div className=" mt-[10px] transition-all flex flex-wrap  justify-between ">
                  <div ref={elementPhotosAvant} class={`relative h-max mt-3 flex flex-col justify-center items-center bg-gray-100 p-2 text-gray-700  mr-2 ${animationClassPhotoAvantCNI}`}>
                    <input accept='image/*' type='file' id="CNIAvant" hidden onChange={(e) => { const PhotosAvantCNI = e.target.files[0]; setcnii(PhotosAvantCNI); SetPhotoAvantCNI(URL.createObjectURL(PhotosAvantCNI)) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPhotoAvantCNI}`} />
                    <label htmlFor="CNIAvant" class={`px-2  py-1 rounded cursor-pointer sm:text-center `}>
                      Photo face avant du C.N.I
                    </label>
                    <div className={` ${!PhotoAvantCNI ? 'w-[0em] h-[0em] opacity-0' : 'w-[12.5em] block h-[12.5em] opacity-100'} transition-all   mt-1 rounded  bg-white  border border-blue-500`}>
                      {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                      <img draggable='false' onLoad={() => {
                        setTimeout(() => {
                          SetImageLoading(false)
                        }, 1000)
                      }} src={PhotoAvantCNI} className="w-full h-full object-contain pointer-events-none" />
                    </div>
                  </div>
                  <div ref={elementPhotosApres} class={`relative flex flex-col justify-center items-center h-max mt-3 bg-gray-100 text-gray-700 p-2  mr-2 ${animationClassPhotoAprestCNI}`}>
                    <input accept='image/*' type='file' id="CNIArrier" hidden onChange={(e) => { const PhotosApprestCNI = e.target.files[0]; setcnio(PhotosApprestCNI); SetPhotoAprestCNI(URL.createObjectURL(PhotosApprestCNI)) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500  ${animationClassPhotoAprestCNI}`} placeholder=" " />
                    <label htmlFor="CNIArrier" class={` px-2 t max-sm:text-center py-1 rounded cursor-pointer `}>
                      Photo face arrière du C.N.I
                    </label>
                    <div className={` ${!PhotoAprestCNI ? 'w-[0em] h-[0em] opacity-0' : 'w-[12.5em] block h-[12.5em] opacity-100'} transition-all   mt-1 rounded  bg-white border border-blue-500`}>
                      {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                      <img draggable='false' onLoad={() => {
                        setTimeout(() => {
                          SetImageLoading1(false)
                        }, 1000)
                      }} src={PhotoAprestCNI} className="w-full h-full object-contain" />
                    </div>
                  </div>
                </div>

                <div class="relative h-11 mt-[10px]  w-full contactInput  mr-2 ">
                  <div class="relative h-11 flexFormulInput w-full">
                    <input
                      value={codeAddress}
                      ref={elemetRefcodeAddress}
                      onInput={(e) => SetcodeAddress(e.target.value)}
                      className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasscodeAddress}`}
                      placeholder=" "
                    />
                    <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Addresse
                    </label>
                  </div>
                </div>

                <div className=" transition-all mt-[10px] flex w-[100%] justify-between flexFormul">
                  <div
                    className={`flex items-center w-1/2 -400 relative flexFormulInput`}
                  >
                    <div class="relative h-11 w-full bloksInput mr-2 ">
                      <input
                        ref={elemetRefPass}
                        onInput={(e) => {
                          setPasswordValue(e.target.value);
                        }}
                        type={passwordVisible ? "text" : "password"}
                        className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassword}`}
                        placeholder=" "
                      />
                      <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Mot de passe
                      </label>
                    </div>
                    <div
                      onClick={() => {
                        setPasswordVisible(!passwordVisible);
                      }}
                      className=" cursor-pointer w-[20px] h-[20px] absolute flex justify-center top-[20px] items-center right-2 "
                    >
                      <i
                        class={`bi ${passwordVisible ? "bi-eye-fill" : "bi-eye-slash-fill"
                          } absolute`}
                      ></i>
                    </div>
                  </div>

                  <div
                    className={`flex items-center w-1/2 relative flexFormulInput`}
                  >
                    <div class="relative h-11 w-full bloksInput mr-2 ">
                      <input
                        ref={elemetRefConfPass}
                        value={confPass}
                        onInput={(e) => {
                          SetconfPass(e.target.value);
                        }}
                        type={ConfpasswordVisible ? "text" : "password"}
                        className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassConfPass}`}
                        placeholder=" "
                      />
                      <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Confirme le mot de passe
                      </label>
                    </div>
                    <div
                      onClick={() => {
                        setConfPasswordVisible(!ConfpasswordVisible);
                      }}
                      className=" cursor-pointer w-[20px] h-[20px] top-[20px] absolute flex justify-center items-center right-2 "
                    >
                      <i
                        class={`bi ${ConfpasswordVisible
                          ? "bi-eye-fill"
                          : "bi-eye-slash-fill"
                          } absolute`}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${profilAnimationClass}`}>
                <label htmlFor="profiles" className="la">
                  <span>Photos de profil</span>
                  <label
                    for="profiles"
                    className="bi bi-image-fill cursor-pointer bg-blue-500 px-1 ml-4 rounded text-white"
                  ></label>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  id="profiles"
                  onChange={(e) => {
                    const UrlFile = e.target.files[0];
                    setPhoto(UrlFile);
                    SetProfil(URL.createObjectURL(UrlFile));
                  }}
                />
                <div
                  className={`${profil == ""
                    ? "opacity-0 pointer-events-none"
                    : "opacity-100 pointer-events-auto"
                    } w-[70%] h-[70%] bg-transparent  mt-[20px]  border rounded-2xl relative overflow-hidden border-blue-600`}
                >
                  {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                  <img draggable='false' onLoad={() => {
                    setTimeout(() => {
                      SetImageLoading(false)
                    }, 1000)
                  }}
                    src={profil}

                    className={`w-full h-full object-cover`}
                  />
                </div>
              </div>
              <div className="flex justify-end items-center mt-4">


                {spinnerButton ? (
                  <>
                    <div className="flex justify-end items-center mt-4 relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                      <div className='absolute bg-transparent   pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                        <FadeLoader
                          color="rgb(255, 255, 255)"
                          height={10}
                          margin={-9}
                          radius={100}
                          speedMultiplier={1}
                          width={1}
                        /></div>
                      <input type="submit" id="send" value="créer un compte" class=" transition-all "></input>
                      <i class="bi bi-send ml-2 "></i>
                    </div>
                  </>
                ) : (
                  <>
                    <label
                      for="send"
                      className=" cursor-pointer w-max  flex justify-end  bg-blue-600   p-2 rounded  text-white"
                    >
                      <input
                        type="submit"
                        id="send"
                        value="créer un compte"
                        className="cursor-pointer "
                      ></input>
                      <i class="bi bi-send ml-2 "></i>
                    </label>

                  </>
                )}




              </div>
              <p class="text-sm font-light text-gray-700 dark:text-gray-400 p-2">
                Vous avez déjà un compte?{" "}
                <Link to='/LoginAdmin'
                  onClick={() => {
                    isdivScroll.current.scrollTop = 0
                  }}
                  class="font-serif  hover:underline dark:text-primary-500 text-blue-500 "
                >
                  Connectez-vous ici
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default SinscrireAdmin;
