/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function LoisirVisitorHome() {
  const [imageLoading, SetImageLoading] = useState(true)
  const [imageLoading1, SetImageLoading1] = useState(true)
  const [imageLoading2, SetImageLoading2] = useState(true)
  const [imageLoading3, SetImageLoading3] = useState(true)
  const [imageLoading4, SetImageLoading4] = useState(true)
  const [imageLoading5, SetImageLoading5] = useState(true)
  const [imageLoading6, SetImageLoading6] = useState(true)
  const [imageLoading7, SetImageLoading7] = useState(true)
  const [imageLoading8, SetImageLoading8] = useState(true)


  const [sizeInfer, SetsizeInfer] = useState(window.innerWidth < 650)
  const [sizeInfer1, SetsizeInfer1] = useState(window.innerWidth < 650)
  const [sizeInfer2, SetsizeInfer2] = useState(window.innerWidth < 650)
  const [sizeInfer3, SetsizeInfer3] = useState(window.innerWidth < 650)
  const [sizeInfer4, SetsizeInfer4] = useState(window.innerWidth < 650)
  const [sizeInfer5, SetsizeInfer5] = useState(window.innerWidth < 650)
  const [sizeInfer6, SetsizeInfer6] = useState(window.innerWidth < 650)
  const [sizeInfer7, SetsizeInfer7] = useState(window.innerWidth < 650)
  const [sizeInfer8, SetsizeInfer8] = useState(window.innerWidth < 650)


  const [titre, Settitre] = useState(window.innerWidth < 800.38383838282929)

  useEffect(() => {
    const hundleSize = () => {
      SetsizeInfer(window.innerWidth < 650)
      SetsizeInfer1(window.innerWidth < 650)
      SetsizeInfer2(window.innerWidth < 650)
      SetsizeInfer3(window.innerWidth < 650)
      SetsizeInfer4(window.innerWidth < 650)
      SetsizeInfer5(window.innerWidth < 650)
      SetsizeInfer6(window.innerWidth < 650)
      SetsizeInfer7(window.innerWidth < 650)
      SetsizeInfer8(window.innerWidth < 650)
      Settitre(window.innerWidth < 800.38383838282929)
    }

    window.addEventListener('resize', hundleSize)

    return () => {
      window.removeEventListener('resize', hundleSize)
    }
  }, [])

  return (
    <>

      <div className="p-2 h-max my-1 w-full flex justify-center ">
        <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
          <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center`}>  Bienvenue sur notre site web dédié  réservation en ligne</h2>

          <div className={` ${imageLoading && sizeInfer ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  bg-gray-200   rounded-[20px]  overflow-hidden responsive1`}>
            {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false' onLoad={() => {
              setTimeout(() => {
                SetImageLoading(false)
              }, 1000)
            }} src="../image/LOISIR.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
          </div>

          <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
            <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center`}> Bienvenue sur notre site web dédié  réservation en ligne</h2>
            <p className="text-gray-500 p-1 text-[22px] mt-6 ">
              <div className="reduitTailParagr text-gray-500 ">
                Vous êtes passionné de découvertes, de divertissements et
                d'activités enrichissantes ? Vous êtes au bon endroit !
                <br /> Notre site regorge d'informations sur les nombreux loisirs et
                attractions que le Burundi a à offrir.

                <br />
                Que votre séjour au Burundi soit synonyme de joie, de découverte et
                de moments mémorables ! L'équipe du site des loisirs au Burundi
              </div>
            </p>
          </div>
        </div>
      </div>


      <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
        <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center`}>Gymtonique</h2>

        <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

          <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
            <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center`}> Gymtonique </h2>

            <p className="text-gray-500 p-1 text-[22px] ">
              <div className="reduitTailParagr text-gray-500 ">
                <div className="reduitTailParagr text-gray-500 ">
                  Vous êtes passionné par le fitness, la remise en forme et vous
                  souhaitez découvrir de nouvelles méthodes d'entraînement pour
                  sculpter votre corps et améliorer votre bien-être ? Vous êtes au bon
                  endroit !
                  <br />
                  <br />
                  Notre site regorge d'informations sur la Gymtonique, une discipline
                  qui combine des exercices de gymnastique, de renforcement musculaire
                  et de stretching pour vous aider à atteindre vos objectifs de forme
                  physique et à maintenir une bonne santé. Que vous soyez débutant ou
                  expérimenté, notre équipe d'experts est là pour vous guider et vous
                  accompagner dans votre parcours de remise en forme.

                </div>
                <div className=" w-full p-2 flex  justify-end hidden ">
                  <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                    Commencer
                  </Link>
                </div>
              </div>

            </p>
          </div>
          <div className={` ${imageLoading1 && sizeInfer1 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  bg-gray-200   rounded-[20px]  overflow-hidden responsive1`}>
            {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false' onLoad={() => {
              setTimeout(() => {
                SetImageLoading1(false)
              }, 1000)
            }} src="../image/Gymtonic.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
          </div>

        </div>
      </div>



      <div className="p-2 h-max my-1 w-full flex justify-center ">
        <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
          <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center`}> Massage ou Sonner</h2>

          <div className={` ${imageLoading2 && sizeInfer2 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
            {imageLoading2 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false' onLoad={() => {
              setTimeout(() => {
                SetImageLoading2(false)
              }, 1000)
            }} src="../image/Massage.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
          </div>

          <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
            <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center`}> Massage ou Sonner</h2>
            <p className="reduitTailParagr text-gray-500 p-1 text-[25px] ">
              Nos thérapeutes expérimentés vous offriront une expérience
              personnalisée, en utilisant des techniques de massage variées pour
              répondre à vos besoins spécifiques.<br /><br /> Que vous souhaitiez soulager le
              stress, détendre vos muscles tendus ou simplement vous offrir un
              moment de pure indulgence, nos massages sont conçus pour vous offrir
              une expérience sensorielle inoubliable.
              <div className=" w-full p-2 flex justify-end ">

                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                  <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                  S'inscrire
                </Link>
              </div>            </p>
          </div>
        </div>
      </div>



      <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
        <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center`}>Touristique</h2>

        <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

          <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
            <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center`}>Touristique </h2>

            <p className="text-gray-500 p-1 text-[22px] ">
              <div className="reduitTailParagr text-gray-500 ">
                <div className="reduitTailParagr text-gray-500]">
                  Vous êtes passionné par les voyages et vous cherchez une destination
                  unique et authentique ? Ne cherchez plus, le Burundi est l'endroit
                  idéal pour vivre une expérience inoubliable.<br /><br /> Découvrez les
                  merveilles de ce petit pays d'Afrique de l'Est, niché au cœur de la
                  région des Grands Lacs. Avec ses paysages époustouflants, sa
                  biodiversité exceptionnelle et sa riche culture, le Burundi a tant à
                  offrir aux voyageurs avides de nouvelles découvertes.
                </div>
                <div className=" w-full p-2 flex  justify-end hidden ">
                  <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                    Commencer
                  </Link>
                </div>
              </div>

            </p>
          </div>
          <div className={` ${imageLoading3 && sizeInfer3 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  bg-gray-200   rounded-[20px]  overflow-hidden responsive1`}>
            {imageLoading3 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false' onLoad={() => {
              setTimeout(() => {
                SetImageLoading3(false)
              }, 1000)
            }} src="../image/tourism.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
          </div>

        </div>
      </div>




      <div className="p-2 h-max my-1 w-full flex justify-center ">
        <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
          <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center`}> Concert</h2>

          <div className={` ${imageLoading4 && sizeInfer4 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  bg-gray-200   rounded-[20px]  overflow-hidden responsive1`}>
            {imageLoading4 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false' onLoad={() => {
              setTimeout(() => {
                SetImageLoading4(false)
              }, 1000)
            }} src="../image/Concert.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
          </div>

          <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
            <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center`}> Concert</h2>
            <p className="reduitTailParagr text-gray-500 p-1 text-[25px] ">
              Que vous souhaitiez assister à un concert  en famille ou
              entre amis, notre site vous aidera à trouver les meilleures options
              de divertissement musical au Burundi. Profitez de l'ambiance
              envoûtante, des performances énergiques et de l'expérience unique
              qu'offre un concert en direct. Nous espérons que notre site vous
              permettra de vivre des moments musicaux mémorables et de découvrir
              toute la richesse de la scène musicale au Burundi. Restez à l'écoute
              et préparez-vous à vivre des expériences musicales exceptionnelles !
              <div className=" w-full p-2 flex justify-end ">

                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                  <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                  S'inscrire
                </Link>
              </div>            </p>
          </div>
        </div>
      </div>




      <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
        <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center`}> Piscine</h2>

        <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

          <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
            <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center`}>Piscine </h2>

            <p className="text-gray-500 p-1 text-[22px] ">
              <div className="reduitTailParagr text-gray-500 ">
                <div className="reduitTailParagr text-gray-500]">
                  Vous êtes passionné par les piscines et vous recherchez des
                  informations sur les meilleures piscines du Burundi ? Vous êtes au
                  bon endroit ! Notre site regorge de conseils, d'avis et de
                  recommandations pour vous aider à trouver la piscine idéale pour
                  vous détendre, vous amuser et profiter des plaisirs de l'eau.
                  <br />
                  <br />
                  Profitez de votre expérience piscine au Burundi et passez
                  des moments inoubliables dans ces oasis aquatiques. Bienvenue dans
                  l'univers des piscines au Burundi !
                </div>
                <div className=" w-full p-2 flex  justify-end hidden ">
                  <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                    Commencer
                  </Link>
                </div>
              </div>

            </p>
          </div>
          <div className={` ${imageLoading5 && sizeInfer5 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  bg-gray-200   rounded-[20px]  overflow-hidden responsive1`}>
            {imageLoading5 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false' onLoad={() => {
              setTimeout(() => {
                SetImageLoading5(false)
              }, 1000)
            }} src="../image/Piscinne.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
          </div>

        </div>
      </div>




      <div className="p-2 h-max my-1 w-full flex justify-center ">
        <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
          <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center`}>Design </h2>

          <div className={` ${imageLoading6 && sizeInfer6 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  bg-gray-200   rounded-[20px]  overflow-hidden responsive1`}>
            {imageLoading6 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false' onLoad={() => {
              setTimeout(() => {
                SetImageLoading6(false)
              }, 1000)
            }} src="../image/Design.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
          </div>

          <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
            <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center`}> Design </h2>
            <p className="reduitTailParagr text-gray-500 p-1 text-[25px] ">

              Nous nous engageons à vous offrir des solutions visuelles uniques et
              sur mesure pour votre projet. Que ce soit pour le design graphique,
              le design d'interface ou le design d'expérience utilisateur, nous
              sommes là pour vous. Contactez-nous dès maintenant pour collaborer
              avec des experts en design et façonner une image remarquable pour
              votre entreprise.
              <div className=" w-full p-2 flex justify-end ">

                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                  <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                  S'inscrire
                </Link>
              </div>            </p>
          </div>
        </div>
      </div>




      <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
        <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center`}>  Studio</h2>

        <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

          <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
            <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center`}>Studio</h2>

            <p className="text-gray-500 p-1 text-[22px] ">
              <div className="reduitTailParagr text-gray-500 ">
                <div className="reduitTailParagr text-gray-500]">

                  Laissez-vous inspirer par notre ambiance artistique, partagez vos
                  idées avec d'autres créatifs et explorez de nouvelles formes
                  d'expression. Que vous soyez débutant ou expérimenté, notre studio
                  est ouvert à tous ceux qui ont une passion pour les arts. N'hésitez
                  pas à réserver votre créneau horaire et à venir vivre une expérience
                  artistique unique dans notre studio. Venez laisser votre empreinte
                  créative et découvrir tout le potentiel artistique qui sommeille en
                  vous
                </div>
                <div className=" w-full p-2 flex  justify-end hidden ">
                  <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                    Commencer
                  </Link>
                </div>
              </div>

            </p>
          </div>
          <div className={` ${imageLoading8 && sizeInfer8 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  bg-gray-200   rounded-[20px]  overflow-hidden responsive1`}>
            {imageLoading8 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false' onLoad={() => {
              setTimeout(() => {
                SetImageLoading8(false)
              }, 1000)
            }} src="../image/Studio.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
          </div>

        </div>
      </div>



      <div className="p-2 h-max my-1 w-full flex justify-center ">
        <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
          <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center`}> Montage Video et Film</h2>

          <div className={` ${imageLoading7 && sizeInfer7 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  bg-gray-200   rounded-[20px]  overflow-hidden responsive1`}>
            {imageLoading7 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false' onLoad={() => {
              setTimeout(() => {
                SetImageLoading7(false)
              }, 1000)
            }} src="../image/MontageVideoFilm.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
          </div>

          <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
            <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 font-serif w-full text-center`}> Montage Video et Film </h2>
            <p className="reduitTailParagr text-gray-500 p-1 text-[25px] ">
              Découvrez l'art fascinant du montage vidéo et du film avec notre
              équipe passionnée. Transformez vos idées en des créations visuelles
              époustouflantes qui racontent des histoires uniques.
              <div className=" w-full p-2 flex justify-end ">

                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                  <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                  S'inscrire
                </Link>
              </div>            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoisirVisitorHome

