import React from 'react'

function Partenariat() {
    return (
        <div className=" hidden bg-white rounded  h-max my-10 flex justify-center flex-col  items-center">
            <h2 className="my-4 text-[30px]  text-green-700">Partenariat</h2>
            <div className="flex flex-wrap" >


                <div className="bg-white rounded-[50px] h-max my-1 flex justify-center w-full  items-center">
                    <div className="  w-full h-max flex p-2 justify-left items-center responsive22">

                        <div className="shadow1 p-2 h-max w-[15em]   m-2 relative flex flex-col items-center   rounded overflow-hidden">
                            <div className="w-[12em] h-[12em] bg-red-900 rounded-[50%]  object-cover overflow-hidden">
                                <img draggable='false' src="../../image/logbtr.jpg" className="w-full h-full" alt="" srcset="" />
                            </div>
                            <div className="">
                                <h2 className="flex  p-1 justify-start my-1 font-bold">Dr Ir Jeremy</h2>

                            </div>
                        </div>

                        <div className=" w-[70%] py-[20px]  text-[20px] p-1 rounded  px-[10px] responsive21">
                            <p className="font-bold m-2 text-blue-400 text-[25px]">Dr_Ir Jeremy</p>
                            <p className="text-gray-600 p-1 text-[17px]">
                                Ls cours en ligne:
                                <ul>
                                    <li>- Algorithme</li>
                                    <li>- Ingenierie</li>
                                    <li>- Structure de base de donnée</li>
                                </ul>
                            </p>
                            <button className="flex bg-blue-400 transition-all rounded text-white px-4 py-1 justify-center my-1  hover:bg-blue-600">Voir plus</button>

                        </div>

                    </div>
                </div>

                <div className="bg-white rounded-[50px] h-max my-1 flex justify-center w-full  items-center">
                    <div className="  w-full h-max flex p-2 justify-left items-center responsive2">



                        <div className=" w-[70%] py-[20px]  text-[20px] p-1 rounded  px-[10px] responsive21">
                            <p className="font-bold m-2 text-blue-400 text-[25px]">Dr_Ir Jeremy</p>
                            <p className="text-gray-600 p-1 text-[17px]">
                                Ls cours en ligne:
                                <ul>
                                    <li>- Algorithme</li>
                                    <li>- Ingenierie</li>
                                    <li>- Structure de base de donnée</li>
                                </ul>
                            </p>
                            <button className="flex bg-blue-400 transition-all rounded text-white px-4 py-1 justify-center my-1  hover:bg-blue-600">Voir plus</button>

                        </div>
                        <div className="shadow1 p-2 h-max w-[15em]   m-2 relative flex flex-col items-center   rounded overflow-hidden">
                            <div className="w-[12em] h-[12em] bg-red-900 rounded-[50%]  object-cover overflow-hidden">
                                <img draggable='false' src="../../image/logbtr.jpg" className="w-full h-full" alt="" srcset="" />
                            </div>
                            <div className="">
                                <h2 className="flex  p-1 justify-start my-1 font-bold">Dr Ir Jeremy</h2>
                                <p className="text-[15px]">

                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="bg-white rounded-[50px] h-max my-1 flex justify-center w-full  items-center">
                    < div className="bg-white rounded-[50px] h-max my-1 flex justify-center w-full  items-center">
                        <div className="  w-full h-max flex p-2 justify-left items-center responsive22">

                            <div className="shadow1 p-2 h-max w-[15em]  m-2 relative flex flex-col items-center   rounded overflow-hidden">
                                <div className="w-[12em] h-[12em] bg-red-900 rounded-[50%]  object-cover overflow-hidden">
                                    <img draggable='false' src="../../image/logbtr.jpg" className="w-full h-full" alt="" srcset="" />
                                </div>
                                <div className="">
                                    <h2 className="flex  p-1 justify-start my-1 font-bold">IRERA Eliedo</h2>
                                    <p className="text-[15px]">

                                    </p>
                                </div>
                            </div>

                            <div className=" w-[70%] py-[20px]  text-[20px] p-1 rounded  px-[10px] responsive21">
                                <p className="font-bold m-2 text-blue-400 text-[25px]">IRERA Eliedo</p>
                                <p className="text-gray-600 p-1 text-[17px]">
                                    Il est capable de faire:
                                    <ul>
                                        <li>- Dessin anime</li>
                                        <li>- Design des photos</li>
                                    </ul>
                                </p>
                                <button className="flex bg-blue-400 transition-all rounded text-white px-4 py-1 justify-center my-1  hover:bg-blue-600">Voir plus</button>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Partenariat
