import React, { useState, useEffect } from 'react'


function NavBarsAdminSuperEchange() {
    const [menu, setMenu] = useState(false);


    useEffect(() => {
        const windowClicked = () => {
            setMenu(false);

            // navigate('/User/Echange/Commande')
        };

        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        };
    }, []);

    return (
        <div className='bg-gray-300 px-[20px] paddig w-full flex justify-between h-[13vh] items-center  relative border-b-2 border-blue-500'>
            <div className="flex justify-center items-center  navEchange1 parent">
                <div className="w-[60px] h-[60px] rounded-full overflow-hidden bg-transparent imgage1 ">
                    <img draggable='false' src={` https://btrproject.burundientempsreel.com/uploads/logo/btr.png`} alt="" className='w-full h-full' />
                </div>
                <div className="ml-2 font-serif navEchange marquee"><span className='mot1'>Burundi </span><span className='mot2'>en </span><span className='mot1'>temps</span> <span className='mot3'>réel</span></div>
            </div>
            <div className=" flex items-end  h-full widss">
                <div className="flex justify-end items-center h-full iconPros   mr-10 px-3 navEchange">
                    <div className="logos transition-all w-[50px] h-[50px] rounded-full overflow-hidden mr-3 imgage">
                        <img draggable='false' src="image/boy_and_girl_fashion.png" alt="" className='w-full h-full object-cover' />
                    </div>
                    <div className="font-serif nameSmall hidenameAndPara">
                        NIYOMUKIZA Varia-Coeur
                    </div>
                </div>
                <div onClick={(e) => { setMenu(!menu); e.stopPropagation() }} className="mr-8 icosn flex flex-col relative items-center transition-all px-4  rounded-t hover:bg-blue-400 hover:text-white cursor-pointer">
                    <i class="bi bi-gear-fill"></i>
                    <p className=' hidenameAndPara'>Parametre</p>

                    <div className={` transition-all ${menu ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} absolute hover:text-black  font-serif z-50 bg-gray-200 top-[110%] rounded right-0 paramare bloksCo`}>
                        <div className="bg-blue-500 m-1 px-1 flex cursor-pointer ">
                            <i class="bi bi-person-bounding-box"></i>
                            <p className='ml-2'> Profil</p>
                        </div>
                        <div className="bg-blue-500 m-1 px-1 flex cursor-pointer ">
                            <i class="bi bi-person-vcard-fill"></i>
                            <p className='ml-3'>Compte</p>
                        </div>
                        <div className="bg-blue-500 flex m-1 px-1 cursor-pointer ">

                            <i class="bi bi-box-arrow-in-left"></i>
                            <p className='ml-2'> Déconnecter</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBarsAdminSuperEchange
