import React from 'react'
import LogImage from '../../LogImage/LogImage'
function CopyRight() {
    return (
        <div className="copy p-4 bg-[rgba(10,1,67,0.93)] inline-flex  w-full text-center justify-center items-center  text-white">
            CopyRight ,developpée par la sociéte <div className="w-[40px]  p-1 h-[40px] mx-2  border-4  border-double border-blue-600 rounded-full overflow-hidden"><LogImage /></div> Burundi en Temps Réel
        </div>
    )
}

export default CopyRight
