import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage';
import { toast } from 'react-toastify';
import swal from 'sweetalert2';

function DepenseMarcheUserDetaill({ isdivScroll }) {
    const { id } = useParams();
    const History = useNavigate()
    const [Modifier, SetModifier] = useState(false)

    const [montant, setmontant] = useState('');
    const [animationClassmontant, setAnimationClassmontant] = useState('');
    const montantRef = useRef(null)

    const [description, setdescription] = useState('');
    const [animationClassdescription, setAnimationClassdescription] = useState('');
    const textaresRef = useRef(null)

    const [autoScrollHeight, setAutoScrollHeight] = useState(50);
    const [valuedescription, setValuedescription] = useState('');

    const [depense, setDepense] = useState({});

    const formatDate = (dateString) => {
        const optionsDate = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const optionsTime = { hour: '2-digit', minute: '2-digit' };

        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('fr-FR', optionsDate);
        const formattedTime = date.toLocaleTimeString('fr-FR', optionsTime);

        return `Le ${formattedDate} à ${formattedTime}`;
    };
    const formatAmount = (amount) => {
        // Convertir le montant en nombre à virgule flottante
        const numberAmount = parseFloat(amount);

        // Vérifier si le montant est un nombre valide
        if (isNaN(numberAmount)) {
            return "Montant invalide";
        }

        // Formater le montant en utilisant les séparateurs de milliers et la virgule décimale
        const formattedAmount = numberAmount.toLocaleString('fr-FR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return formattedAmount;
    };
    useEffect(() => {
        axios.get(`https://btrproject.burundientempsreel.com/depense/ById/${id}`).then((response) => {
            setDepense(response.data);
            setdescription(response.data.motif);
            setmontant(response.data.montant);

        }).catch((error) => {
            console.log(error.message);
            if (error.response) {
                console.log(error.response.data)
            }
        });
    }, [id])


    const Deletedepense = (abc) => {
        swal.fire({
            title: 'Suppression de  la depense',
            text: 'Si vous êtes sûr de supprimer définitivement votre depense !!',
            icon: 'warning',
            width: '300px',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Oui',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Non',
            customClass: {
                container: 'h-full w-full',
                title: 'text-[17px]',
                icon: 'h-[55px] w-[55px]',
                heightAuto: '100px',
            },
            heightAuto: false,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`https://btrproject.burundientempsreel.com/depense/delete/${abc}`).then((response) => {
                    toast.success("Vous l'avez définitivement supprimé", {
                        position: 'top-center',
                        autoClose: 5000,
                    });
                    History('/User/Depenses')
                }).catch((error) => {
                    console.log(error.message);
                    if (error.response) {
                        console.log(error.response.data);
                    }
                });
            } else if (result.dismiss === swal.DismissReason.cancel) {
                toast.warning("Vous avez annulé la suppression du depense", {
                    position: 'top-center',
                    autoClose: 1000,
                });
            }

        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const regex = /^\d+(\.\d+)?$/;
        if (!regex.test(montant)) {
            toast.warning("Le montant doit être un nombre décimal", {
                autoClose: 2000
            });
            setAnimationClassmontant('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassmontant('');
            }, 3000);
            montantRef.current.focus()
            return;
        }

        if (description.trim() === '') {
            toast.warning('Le description de la depense est nécessaire', {
                autoClose: 2000
            });
            setAnimationClassdescription('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassdescription('');
            }, 3000);
            textaresRef.current.focus()
            return;
        }
        const data = {
            montant: montant,
            motif: description
        }
        axios.put(`https://btrproject.burundientempsreel.com/depense/modify/${id}`, data).then((response) => {
            if (response.data.message === "Mise à jour réussie") {
                toast.success("Mise à jour réussie", {
                    autoClose: 5000
                })
                setmontant("")
                setdescription("")
                setTimeout(() => {
                    window.location.reload();
                }, 5000);

            }
        }).catch((error) => {
            console.log(error.message);
            if (error.response) {
                console.log(error.response.data);
            }
        });

    }


    const ModiDepenses = (e) => {
        SetModifier(!Modifier)
        setTimeout(() => {
            if (isdivScroll.current) {
                isdivScroll.current.scrollTop = isdivScroll.current.scrollHeight;
            }
        }, 0);
        e.stopPropagation()
    };

    useEffect(() => {
        const hundleclick = () => {
            SetModifier(false);
        }
        window.addEventListener('click', hundleclick)
        setTimeout(() => {
            isdivScroll.current.style.scrollBehavior = 'smooth';
        }, 0);

        return () => {
            window.removeEventListener('click', hundleclick)
        }
    }, []);


    return (
        <div className='min-h-[30em]'>
            <div className='mt-3'>
                <div className='ml-5 rounded px-2 py-1 bg-blue-600 w-max items-center text-white flex justify-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                    </svg>
                    <Link to='/User/Depenses' className='ml-1 px-2 py-1 bg-blue-600 text-white'>Retour</Link>
                </div>
            </div>

            <div className="my-8">

                <section className="flex  justify-center">
                    <div className="bg-white  w-full  border border-blue-700 shadow-2xl rounded-2xl flex flex-col max-w-7xl sm:p-5 items-start">
                        <div className="py-2 flex flex-col items-start px-2 w-full">
                            <div>
                                <div className='overflow-hidden leading-6'>

                                    <p><b>Montant:</b> {formatAmount(depense.montant)} Fb</p>
                                    <p><b>Date du depense:</b> {depense && formatDate(depense.createdAt)}</p>
                                    <p className='flex'><b>description:</b> <div>{depense.motif}</div> </p>
                                </div>
                            </div>

                            <div className="h-full mt-10 ">
                                <div className="mx-auto  justify-center md:flex  xl:px-0">
                                    <div className="justify-between sm:ml-6 py-3 px-4  sm:w-[20em] flex-col w-full bg-inherit   h-max sm:flex sm:justify-start">
                                        <div className='w-full h-max flex justify-end my-5 items-center'>
                                            <div onClick={ModiDepenses} className=' text-white bg-blue-400 hover:bg-blue-800 transition-all rounded cursor-pointer py-2 px-4 m-2'>Modifier</div>
                                            <div onClick={() => Deletedepense(depense.id)} className=' text-white bg-red-400 hover:bg-red-800 transition-all rounded cursor-pointer py-2 px-4 m-2'>Supprimer</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>



                        <div onClick={(e) => e.stopPropagation()} className={` ${Modifier ? 'block' : 'hidden'} mt-2 shadow-2xl border border-violet-800 w-[95%]  sm:w-[25em] bg-white py-4 px-4`}>
                            <div className={`   `}>
                                <form onSubmit={handleSubmit}>
                                    <div className="mt-6 w-full ">
                                        <div className="transition-all  w-[100%]">
                                            <div className="relative h-11 w-full InputsContact mr-2">
                                                <input ref={montantRef}
                                                    value={montant}
                                                    onInput={(e) => setmontant(e.target.value)}
                                                    className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassmontant}`}
                                                    placeholder=" "
                                                />
                                                <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                    Le Montant
                                                </label>
                                            </div>
                                            <div className="relative h-full w-full mt-10">
                                                <textarea ref={textaresRef}
                                                    value={description}
                                                    onChange={(e) => {
                                                        setdescription(e.target.value);
                                                        setAutoScrollHeight(e.target.scrollHeight);
                                                        setValuedescription(e.target.value.trim().length);
                                                        if (!valuedescription) {
                                                            setAutoScrollHeight(50);
                                                        }
                                                    }}
                                                    placeholder=" "
                                                    style={{ height: `${autoScrollHeight}px` }}
                                                    className={`peer personaldescription leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassdescription}`}
                                                />
                                                <label
                                                    className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-blue-gray-500`}
                                                >
                                                    description
                                                </label>
                                            </div>

                                            <div className="flex justify-end items-center mt-[20px]">
                                                <div className="flex bg-blue-600 rounded cursor-pointer pl-2 py-1 text-white">
                                                    <input type="submit" id="send" hidden />
                                                    <label htmlFor="send" className="w-max flex justify-end p-1 cursor-pointer mx-1 ">
                                                        <span className='mr-2'>Modifier</span>
                                                        <div className="bi bi-send"></div>
                                                    </label>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </section>
            </div >


        </div >
    )
}

export default DepenseMarcheUserDetaill
