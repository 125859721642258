import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
import { GridLoader } from 'react-spinners'
function DetailleAdminManagent() {
  const history = useNavigate()
  const [Payment, setPayment] = useState(false);
  const [Shipping, setShipping] = useState(false);
  const [Received, setReceived] = useState(false);
  const [Commande, setCommande] = useState(false);
  const [loading, setLoading] = useState(true);
  const [datailofcommande, setdatailofcommande] = useState({});
  const [status, setstatus] = useState('');
  const { id } = useParams();

  const handleClose = () => {
    toast.dismiss();
  };

  useEffect(() => {
    axios.get(` https://btrproject.burundientempsreel.com/market/adminorderbyId/${id}`)
      .then((response) => {
        setdatailofcommande(response.data);
        setstatus(response.data.status);
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      }).catch((error) => {
        console.log(error.message);
      });

    // Déplacez la condition à l'intérieur de useEffect pour éviter la boucle infinie
    if (status === "pending") {
      setCommande(true);
    } else if (status === "payed") {
      setPayment(true)
    } else if (status == "shipping") {
      setShipping(true)
    }
  }, [id, status]);


  const handleRefuse = async (id) => {
    try {
      await axios.put(` https://btrproject.burundientempsreel.com/market/admin/refusecommande/${id}`);
      history('/Admin/ManageOrderMarket');
      toast.success('refuser la commande a réussi');
    } catch (error) {
      toast.error("Une erreur s'est produite lors de l'annulation du commande");
    }
  };
  const handlePayed = async (id) => {
    try {
      await axios.put(` https://btrproject.burundientempsreel.com/market/admin/payedcommande/${id}`);
      history('/Admin/ManageOrderMarket');
      toast.success('confimer le payement du commande a réussi');
    } catch (error) {
      toast.error("Une erreur s'est produite lors de confirmation de payement  du commande");
    }
  };
  const handleShipping = async (id) => {
    try {
      await axios.put(` https://btrproject.burundientempsreel.com/market/admin/shippingcommande/${id}`);
      history('/Admin/ManageOrderMarket');
      toast.success("la confirmation que tous les produit de la commande sont c'est vous  a réussi");
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la confirmation que tous les produit de la commande sont c'est vous");
    }
  };
  return (
    <>
      {loading && <SpinnerDemarage />}
      <div class=" bg-white border-t text-gray-700 border-b sm:border-l sm:border-r sm:rounded-xl shadow mb-6">
        <div class="w-full flex flex-col items-center">
          <div class="relative mb-4 flex  flex-col space-y-3 rounded-2xl  md:max-w-3xl md:flex-row md:space-x-5 md:space-y-0">
            <div>
              <h2 class="text-2xl font-semibold text-left text-blue-500 leading-tight">
                Informations sur l'acheteur
              </h2>
            </div>
          </div>
          <div class="relative flex w-full  flex-col items-start space-y-3 rounded-2xl border border-gray-300 p-3 shadow-lg md:max-w-3xl md:space-x-5 md:space-y-0">
            {datailofcommande && datailofcommande.user && (
              <div class="flex-grow  pt-6 pb-8 px-2">
                <div class="">
                  <div class="">
                    <div class="">
                      <div class="flex no-wrap md:-mx-2">
                        {/* <!-- Right Side --> */}
                        <div class="w-full  mx-2 ">
                          {/* <!-- Profile tab -->
                         <!-- About Section --> */}
                          <div class="bg-gray-100 w-full p-3 shadow-sm rounded-2xl">
                            <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                              <span class="text-blue-500">
                                <svg
                                  class="h-7"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                  ></path>
                                </svg>
                              </span>
                              <h2 class="text-xl text-blue-500 tracking-wide leading-8 my-1">
                                Informations sur l'acheteur
                              </h2>
                            </div>
                            <div class="image overflow-hidden">
                              <img draggable='false'
                                class="h-auto w-24 rounded-full mx-auto"
                                src={` https://btrproject.burundientempsreel.com/uploads/photosuser/${datailofcommande.user.photo}`}
                                alt="" photo
                              />
                            </div>
                            <div class="text-gray-700">
                              <div class="grid text-sm">
                                <div class="grid grid-cols-2">
                                  <div class="px-4 py-2 font-semibold">
                                    Nom
                                  </div>
                                  <div class="px-4 py-2">{datailofcommande.user.nom}</div>
                                </div>
                                <div class="grid grid-cols-2">
                                  <div class="px-4 py-2 font-semibold">
                                    Prenom
                                  </div>
                                  <div class="px-4 py-2">{datailofcommande.user.prenom}</div>
                                </div>
                                <div class="grid grid-cols-2">
                                  <div class="px-4 py-2 font-semibold">
                                    Contact No.
                                  </div><div class="px-4 py-2">{datailofcommande.user.tel}</div>
                                </div>
                                <div class="grid grid-cols-2">
                                  <div class="px-4 py-2 font-semibold">
                                    Address
                                  </div>
                                  <div class="px-4 py-2">
                                    {datailofcommande.user.address}
                                  </div>
                                </div>
                                <div class="grid grid-cols-2">
                                  <div class="px-4 py-2 font-semibold">
                                    Email.
                                  </div>
                                  <div class="px-4 py-2">
                                    <a
                                      class="text-blue-800"
                                      href="mailto:jane@example.com"
                                    >
                                      {datailofcommande.user.email}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- End of about section --> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {datailofcommande && (
              <>
                <div class="flex-grow container pt-6 pb-8 px-2">
                  <div class="">
                    <div class="">
                      <div class="">
                        <div class="md:flex no-wrap md:-mx-2">
                          {/* <!-- Right Side --> */}
                          <div class="w-full md:w-11/12 mx-2 ">
                            {/* <!-- Profile tab -->
              <!-- About Section --> */}
                            <div class="bg-gray-100 p-3 shadow-sm rounded-2xl">
                              <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                                <h4 class="text-xl text-blue-500 tracking-wide leading-8 my-1">
                                  Adresse de livraison et coordonnées
                                </h4>
                              </div>
                              <div class="text-gray-700">
                                <div class="grid text-sm">
                                  <div class="grid grid-cols-2">
                                    <div class="px-4 py-2 font-semibold">
                                      Pays
                                    </div>
                                    <div class="px-4 py-2">{datailofcommande.pays}</div>
                                  </div>
                                  <div class="grid grid-cols-2">
                                    <div class="px-4 py-2 font-semibold">
                                      Province
                                    </div>
                                    <div class="px-4 py-2">{datailofcommande.province}</div>
                                  </div>

                                  <div class="grid grid-cols-2">
                                    <div class="px-4 py-2 font-semibold">
                                      Commune
                                    </div>
                                    <div class="px-4 py-2">{datailofcommande.commune}</div>
                                  </div>
                                  <div class="grid grid-cols-2">
                                    <div class="px-4 py-2 font-semibold">
                                      Zone
                                    </div>
                                    <div class="px-4 py-2">{datailofcommande.zone}</div>
                                  </div>
                                  <div class="grid grid-cols-2">
                                    <div class="px-4 py-2 font-semibold">
                                      Quartier
                                    </div>
                                    <div class="px-4 py-2">{datailofcommande.quartier}</div>
                                  </div>
                                  <div class="grid grid-cols-2">
                                    <div class="px-4 py-2 font-semibold">
                                      Avenue
                                    </div>
                                    <div class="px-4 py-2">{datailofcommande.avenue}</div>
                                  </div>
                                  <div class="grid grid-cols-2">
                                    <div class="px-4 py-2 font-semibold">
                                      House number
                                    </div>
                                    <div class="px-4 py-2">
                                      {datailofcommande.housernamber}
                                    </div>
                                  </div>
                                  <div class="grid grid-cols-2">
                                    <div class="px-4 py-2 font-semibold">
                                      Mobile
                                    </div>
                                    <div class="px-4 py-2">
                                      {datailofcommande.mobile}
                                    </div>
                                  </div>
                                  <div class="grid grid-cols-2">
                                    <div class="px-4 py-2 font-semibold">
                                      description
                                    </div>
                                    <div class="px-4 py-2">

                                      {datailofcommande.description}

                                    </div>
                                  </div>
                                  <div class="grid grid-cols-2">
                                    <div class="px-4 py-2 font-semibold">
                                      Date de commande
                                    </div>
                                    <div class="px-4 py-2">
                                      {new Date(datailofcommande.createdAt).toLocaleDateString('en-US') + ' ' + new Date(datailofcommande.createdAt).toLocaleTimeString('en-US')}
                                    </div>
                                  </div>
                                  <div class="grid grid-cols-2">
                                    <div class="px-4 py-2 font-semibold">
                                      Montant total payé
                                    </div>
                                    <div class="px-4 py-2">
                                      {datailofcommande.prixtotal} Fbu
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="relative mb-4 mt-8 flex max-w-xs flex-col space-y-3 rounded-2xl border border-gray-300 p-3 shadow-lg md:max-w-3xl md:flex-row md:space-x-5 md:space-y-0">
                  <p class="font-bold text-lg text-blue-500">Images de preuve</p>
                </div>
                <div class="grid w-full place-items-center ">
                  <img draggable='false'
                    src={` https://btrproject.burundientempsreel.com/uploads/market/${datailofcommande.proofimage}`}
                    alt="tailwind logo"
                    class="rounded-xl"
                  />
                </div>
              </>
            )}
          </div>

          <div class="relative mb-4 mt-8 flex w-full flex-col space-y-3 rounded-2xl md:max-w-3xl md:flex-row md:space-x-5 md:space-y-0">
            <div>
              <h2 class="text-2xl font-semibold  text-blue-500 leading-tight">

                Informations sur le vendeur
              </h2>
            </div>
          </div>
          <div class="Ordered_Product flex flex-col space-y-5">

            {datailofcommande && datailofcommande.commandemarketcontents && datailofcommande.commandemarketcontents.map((comande => {
              return (

                <div class="relative flex w-full  flex-col items-start space-y-3 rounded-2xl border border-gray-300 p-3 shadow-lg md:max-w-3xl md:space-x-5 md:space-y-0">
                  <div class="flex-grow container  px-2">
                    <div >
                      <div >
                        <div class="p-4">
                          <div class="md:flex no-wrap md:-mx-2">
                            {/* <!-- Left Side --> */}
                            <div class="w-full md:w-4/12 md:mx-2">
                              {/* <!-- Profile Card --> */}
                              <div class="bg-gray-100 rounded-2xl p-5">
                                <div class="image overflow-hidden">
                                  <img draggable='false'
                                    class="h-auto w-16 rounded-full mx-auto"
                                    src={` https://btrproject.burundientempsreel.com/uploads/photosuser/${comande.seller.user.photo}`}
                                    alt=""
                                  />
                                </div>
                                <h1 class="text-blue-500 text-center font-bold text-lg leading-8 my-1">
                                  {comande.seller.user.firstname}   {comande.seller.user.lastname}
                                </h1>
                                <h3 class="text-gray-600 text-center font-lg text-semibold leading-6">
                                  Tel:   {comande.seller.user.tel}
                                </h3>
                                <h3 class="text-gray-600 text-center font-lg text-semibold leading-6">
                                  Email:<a mailto={comande.seller.user.email}>   {comande.seller.user.email}</a>
                                </h3>

                              </div>
                              {/* <!-- End of profile card --> */}
                              <div class="my-4"></div>
                            </div>
                            {/* <!-- Right Side --> */}
                            <div class="w-full md:w-8/12 mx-2 ">
                              {/* <!-- Profile tab -->
                        <!-- About Section --> */}
                              <div class=" shadow-sm rounded-2xl">
                                <div class="mb-6 justify-between rounded-lg  shadow-md sm:flex sm:justify-start">
                                  <img draggable='false'
                                    src={` https://btrproject.burundientempsreel.com/uploads/market/${comande.produit.profil}`}
                                    alt=" "
                                    class="w-full rounded-lg sm:w-40"
                                  />
                                  <div class="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                                    <div class="mt-5 w-48 sm:mt-0">
                                      <h2 class="text-lg font-bold text-gray-900">
                                        {comande.produit.titre}
                                        <p class="text-base text-gray-500">
                                          Q:{comande.quantite}
                                        </p>
                                      </h2>
                                      <p class="mt-1 text-base text-green-500">{comande.produit.categorie}</p>
                                      <p class="mt-1 text-base text-gray-700">{comande.produit.description}</p>
                                    </div>
                                    <div class="mt-4 flex justify-between sm:mt-0 sm:block sm:space-x-6 sm:space-y-6">


                                      <div class="flex items-center space-x-4">
                                        <div class="font-roboto mb-1 mt-4 flex items-baseline space-x-2">
                                          <p class="text-primary text-xl font-semibold">
                                            {comande.produit.prix}
                                          </p>
                                          <p class="text-base text-gray-400 line-through">
                                            {comande.produit.prix + (comande.produit.prix * 0.2)}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              )
            }))}




          </div>

          <div class="relative mb-4 mt-8 flex max-w-xs flex-col space-y-3 rounded-2xl border border-gray-300 p-3 shadow-lg md:max-w-3xl md:flex-row md:space-x-5 md:space-y-0">
            <p class="font-bold text-gray-400"> Suivi du processus de livraison </p>
          </div>
          <div class="relative flex full flex-col w-full items-start space-y-3 rounded-2xl border border-gray-300 p-3 shadow-lg md:max-w-3xl md:space-x-5 md:space-y-0">
            <div class="relative flex full w-full flex-col items-start space-y-3 rounded-2xl p-3 md:max-w-3xl md:flex-row md:space-x-5 md:space-y-0">
              {/* <!-- component --> */}
              <div class="w-full py-6">
                <div class="flex">
                  <div class="w-1/4">
                    <div class="relative mb-2">
                      <div
                        class={`mx-auto flex h-10 w-10 items-center rounded-full text-lg text-white ${Commande
                          ? " bg-blue-500"
                          : " border-2 border-gray-200 bg-white text-gray-600 "
                          }`}
                      >
                        <span class="w-full text-center text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="3"
                            stroke="currentColor"
                            class="w-full p-2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div class="text-center text-xs md:text-base">Commande</div>
                  </div>

                  <div class="w-1/4">
                    <div class="relative mb-2">
                      <div
                        class="align-center absolute flex content-center items-center align-middle"
                        style={{
                          width: " calc(100% - 2.5rem - 1rem)",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <div class="align-center w-full flex-1 items-center rounded bg-gray-200 align-middle">
                          <div
                            class={` rounded bg-blue-300 py-1 ${Commande ? "w-[100%]" : "w-[0%]"
                              }`}
                          ></div>
                        </div>
                      </div>

                      <div
                        class={`mx-auto flex h-10 w-10 items-center rounded-full text-lg text-white ${Payment
                          ? " bg-blue-500"
                          : " border-2 border-gray-200 bg-white "
                          }`}
                      >
                        {Payment ? (
                          <span class="w-full text-center   text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="3"
                              stroke="currentColor"
                              class="w-full p-2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </span>
                        ) : (
                          <span class="w-full text-center  text-gray-600">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="p-2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                              />
                            </svg>
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="text-center text-xs md:text-base">Paiment</div>
                  </div>

                  <div class="w-1/4">
                    <div class="relative mb-2">
                      <div
                        class="align-center absolute flex content-center items-center align-middle"
                        style={{
                          width: " calc(100% - 2.5rem - 1rem)",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <div class="align-center w-full flex-1 items-center rounded bg-gray-200 align-middle">
                          <div
                            class={` rounded bg-blue-300 py-1 ${Payment ? "w-[100%]" : "w-[0%]"
                              }`}
                          ></div>
                        </div>
                      </div>

                      <div
                        class={`mx-auto flex h-10 w-10 items-center rounded-full text-lg text-white ${Shipping
                          ? " bg-blue-500"
                          : " border-2 border-gray-200 bg-white "
                          }`}
                      >
                        {Shipping ? (
                          <span class="w-full text-center   text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="3"
                              stroke="currentColor"
                              class="w-full p-2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </span>
                        ) : (
                          <span class="w-full text-center  text-gray-600">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="p-2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                              />
                            </svg>
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="text-center text-xs md:text-base">Expédition</div>
                  </div>

                  <div class="w-1/4">
                    <div class="relative mb-2">
                      <div
                        class="align-center absolute flex content-center items-center align-middle"
                        style={{
                          width: "calc(100% - 2.5rem - 1rem)",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <div class="align-center w-full flex-1 items-center rounded bg-gray-200 align-middle">
                          <div
                            class={` rounded bg-blue-300 py-1 ${Shipping ? "w-[100%]" : "w-[0%]"
                              }`}
                          ></div>
                        </div>
                      </div>

                      <div
                        class={`mx-auto flex h-10 w-10 items-center rounded-full text-lg text-white ${Received
                          ? " bg-blue-500"
                          : " border-2 border-gray-200 bg-white text-gray-600 "
                          }`}
                      >
                        {Received ? (
                          <span class="w-full text-center   text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="3"
                              stroke="currentColor"
                              class="w-full p-2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </span>
                        ) : (
                          <span class="w-full text-center  text-gray-600">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="p-2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                              />
                            </svg>
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="text-center text-xs md:text-base">
                      Reçue</div>
                  </div>
                </div>
              </div>
            </div>

            <p class="text-base text-black md:text-lg">
              Vous êtes autorisé à confirmer le Payé et Expédition
            </p>
            <p class="text-base text-black font-medium md:text-lg">
              Cliquez sur l'étape pour activer
            </p>
            <div class="flex py-5 justify-between w-full space-x-5 px-5">

              {Commande ? (
                <>
                  <button
                    onClick={() => handleRefuse(datailofcommande.id)}
                    class="block w-full py-2  text-center text-white bg-red-700 border border-red-500 rounded-2xl hover:bg-transparent hover:text-red-500 transition"
                  >

                    Refuser la commande
                  </button>

                  <button
                    onClick={() => handlePayed(datailofcommande.id)}
                    class="block w-full py-2  text-center text-white bg-blue-500 border border-blue-500 rounded-2xl hover:bg-transparent hover:text-blue-500 transition"
                  >

                    Confirmer le paiement
                  </button>
                </>
              ) : (
                <button
                  onClick={() => handleShipping(datailofcommande.id)}
                  class="block w-full py-2  text-center text-white bg-blue-500 border border-blue-500 rounded-2xl hover:bg-transparent hover:text-blue-500 transition"
                >
                  Confirmer l'expédition
                </button>
              )}





            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailleAdminManagent;
