/* eslint-disable eqeqeq */
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import { FadeLoader } from 'react-spinners'
import { toast } from 'react-toastify';
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage';
function FraisrubriqueAdmi() {

    const [deletingRubriqueId, setDeletingRubriqueId] = useState(null);
    const [mobile, GetMobile] = useState(window.innerWidth < 566)
    const [selected, GetSelected] = useState('Lumicash')
    const [loading, SetLoading] = useState(true)
    useEffect(() => {
        const HundleSize = () => {
            GetMobile(window.innerWidth < 566)
        }
        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)
    }, [])
    const [rubrique, setRubrique] = useState([])
    useEffect(() => {
        axios.get(`https://btrproject.burundientempsreel.com/rubrique/all?selected=${selected}`).then((response) => {
            setRubrique(response.data);
            setTimeout(() => {
                SetLoading(false)
            }, 1000);
        }).catch((err) => {

            console.error('Erreur lors de la récupération des rurique :', err.message);
        })
    }, [selected])

    const handleConfirmation = async (trasId) => {
        try {
            setDeletingRubriqueId(trasId);

            const result = await Swal.fire({
                title: "Confirmation",
                text: "Vous Voulez Supprimer ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
            });

            if (result.isConfirmed) {
                // Si l'utilisateur a confirmé la suppression
                axios.delete(`https://btrproject.burundientempsreel.com/rubrique/${trasId}`)
                    .then((res) => {
                        toast.success('Vous avez supprimé avec succès la rubrique!');
                        // Supprimer l'élément correspondant de la liste
                        setRubrique(rubrique.filter(rubrique => rubrique.id !== trasId));
                    })
                    .catch((err) => {
                        toast.warn('Erreur de la suppression du rubrique');
                    }).finally(() => {
                        setDeletingRubriqueId(null);
                    });
            } else {
                console.log('Cancelled');
                setDeletingRubriqueId(null);
            }
        } catch (error) {
            console.log('Error:', error.message);
            setDeletingRubriqueId(null);
        }
    };
    return (
        <div className='w-full min-h-screen p-2 overflow-hidden'>
            {loading && <SpinnerDemarage />}
            <div className='bg-gray-200 p-2 rounded'>
                <div className='w-full flex justify-between px-4 items-center'>
                    <div className='border-2 border-blue-800 ml-4 rounded overflow-hidden'>
                        <select onChange={(e) => GetSelected(e.target.value)} className='px-2 py-1 cursor-pointer border-none  bg-white'>
                            <option value='Lumicash'>Lumicash</option>
                            <option value='Mobinetel'>Mobinetel</option>
                            <option value='Telemax'>Telemax</option>
                            <option value='Bancobu enoti'>Bancobu enoti</option>
                            <option value='CECM akaravyo'>CECM akaravyo</option>
                        </select>
                    </div>
                    <Link to='/Admin/fraistransaction/Ajouter' className='bg-blue-400 p-2 rounded-full cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-fill h-6 w-6 text-blue-700" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                        </svg>
                    </Link>
                </div>
                <div className='mt-10 w-full bg-slate-50 flex flex-col rounded-md p-2'>
                    <div className='w-full text-xl text-center p-2 font-semibold'>Rubrique des frais de rubrique</div>
                    <div className='w-full text-xl flex justify-center p-2 font-semibold'>
                        <div className='w-max py-1 px-2  text-center rounded font-extrabold bg-blue-200 text-blue-700 '> {selected == 'Mobinetel' ? "Mobinetel" : selected == 'Telemax' ? "Telemax" : selected == 'Bancobu enoti' ? "Bancobu enoti" : selected == 'CECM akaravyo' ? "CECM akaravyo" : "Lumicash"}</div>
                    </div>
                    <div className='w-full p-4 overflow-hidden overflow-x-auto '>
                        <table className={`border border-gray-300 ${mobile ? 'w-[40em]' : 'w-full'}`}>
                            <thead className='w-full bg-blue-600 text-white overflow-hidden'>
                                <tr className='border-b border-gray-300'>
                                    <th rowSpan={2} className='p-1 border-r border-gray-300'>Minimum</th>
                                    <th rowSpan={2} className='p-1 border-r border-gray-300'>Maximum</th>
                                    <th colSpan={2} className='p-1 border-r border-gray-300'>Frais de transfert </th>
                                    <th rowSpan={2} className='p-1 border-r border-gray-300'>Frais de retrait</th>
                                    <th rowSpan={2} className='p-1 border-r border-gray-300'>Action</th>
                                </tr>
                                <tr className='border-b'>
                                    <th className='border-r border-gray-300 p-1'>Enregistrée</th>
                                    <th className='border-r border-gray-300 p-1'>Non enregistrée</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rubrique.length > 0 && rubrique.map((rub) => {
                                    return (
                                        <tr className='border-b border-gray-300' >
                                            <td className='border-r border-gray-300 pl-2'>{rub.min}</td>
                                            <td className='border-r border-gray-300 pl-2'>{rub.max}</td>
                                            <td className='border-r border-gray-300 pl-2'>{rub.yanditswe}</td>
                                            <td className='border-r border-gray-300 pl-2'>{rub.atanditswe}</td>
                                            <td className='border-r border-gray-300 pl-2'>{rub.kubikura}</td>
                                            <td className='border-r  text-center border-gray-300 flex'>
                                                <Link to={`/Admin/fraistransaction/Modifier/${rub.id}`} className='bg-blue-600 text-white cursor-pointer rounded m-1 px-2 py-1'>
                                                    Modifier
                                                </Link>
                                                {deletingRubriqueId === rub.id ? (
                                                    <>
                                                        <div aria-disabled className="cursor-no-drop w-max relative  flex justify-center  items-center   bg-transparent  rounded  text-gray-400">
                                                            <button onClick={() => handleConfirmation(rub.id)} className='bg-red-950  pointer-events-none rounded px-2 py-1'>supprimer</button>
                                                            <div className='absolute pointer-events-none bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                                <FadeLoader
                                                                    color="rgb(255, 255, 255)"
                                                                    height={10}
                                                                    margin={-9}
                                                                    radius={100}
                                                                    speedMultiplier={1}
                                                                    width={1}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (<>
                                                    <button onClick={() => handleConfirmation(rub.id)} className='bg-red-500 text-white cursor-pointer rounded m-1 px-2 py-1'>supprimer</button>
                                                </>)}

                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>


                </div>
            </div>




        </div >
    )
}

export default FraisrubriqueAdmi
