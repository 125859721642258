/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Agri_ElevVisitorHome() {
    const [imageLoading, SetImageLoading] = useState(true)
    const [imageLoading1, SetImageLoading1] = useState(true)
    const [imageLoading2, SetImageLoading2] = useState(true)
    const [imageLoading3, SetImageLoading3] = useState(true)
    const [imageLoading4, SetImageLoading4] = useState(true)

    const [sizeInfer, SetsizeInfer] = useState(window.innerWidth < 650)
    const [sizeInfer1, SetsizeInfer1] = useState(window.innerWidth < 650)
    const [sizeInfer2, SetsizeInfer2] = useState(window.innerWidth < 650)
    const [sizeInfer3, SetsizeInfer3] = useState(window.innerWidth < 650)
    const [sizeInfer4, SetsizeInfer4] = useState(window.innerWidth < 650)

    const [titre, Settitre] = useState(window.innerWidth < 800.38383838282929)

    useEffect(() => {
        const hundleSize = () => {
            SetsizeInfer(window.innerWidth < 650)
            SetsizeInfer1(window.innerWidth < 650)
            SetsizeInfer2(window.innerWidth < 650)
            SetsizeInfer3(window.innerWidth < 650)
            SetsizeInfer4(window.innerWidth < 650)

            Settitre(window.innerWidth < 800.38383838282929)


        }

        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    return (
        <>

            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}>  Bienvenue  sur notre site web dédié  à l'agriculture  et à l'élevage</h2>

                    <div className={` ${imageLoading && sizeInfer ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading(false)
                            }, 1000)
                        }} src="../image/agricultur_elevage.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}>  Bienvenue  sur notre site web dédié  à l'agriculture  et à l'élevage</h2>
                        <p className="text-gray-500 p-1 text-[20px]  reduitTailParagr ">
                            <div className="text-gray-500">
                                Que vous soyez un agriculteur chevronné, un éleveur passionné ou simplement intéressé par le domaine de l'agriculture et de l'élevage, vous êtes au bon endroit.
                                <br /><br />
                                Explorez nos différentes rubriques pour découvrir des articles, des guides pratiques, des conseils d'experts, des témoignages d'agriculteurs et d'éleveurs à succès, ainsi que des actualités pertinentes pour le secteur.


                            </div>

                        </p>
                    </div>
                </div>
            </div>


            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}>   Semance sélectionné</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">



                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}>Semance sélectionné</h2>
                        <p className="text-gray-500 p-1 text-[20px]  reduitTailParagr ">
                            <div className="text-gray-500">
                                Que vous soyez un jardinier passionné, un agriculteur ou simplement quelqu'un qui souhaite cultiver ses propres plantes, notre large sélection de semences de qualité répondra à vos besoins.

                                <br /><br />
                                Une fois que vous aurez fait votre sélection, il vous suffira de passer à l'étape de commande sécurisée. Nous nous occupons de l'expédition rapide et fiable de vos semences, afin que vous puissiez commencer votre saison de plantation sans attendre.

                            </div>
                        </p>
                    </div>
                    <div className={` ${imageLoading1 && sizeInfer1 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading1(false)
                            }, 1000)
                        }} src="../image/semencesselectionnees.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}> Information IGBU</h2>

                    <div className={` ${imageLoading2 && sizeInfer2 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading2 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading2(false)
                            }, 1000)
                        }} src="../image/IGEBU.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}> Information IGBU</h2>
                        <p className="text-gray-500 p-1 text-[20px]  reduitTailParagr ">

                            Nous sommes ravis de vous accueillir en tant que visiteur intéressé par ces domaines. Ici, vous trouverez une mine d'informations sur l'agriculture, les techniques de culture, l'élevage des animaux et bien plus encore.

                            Explorez nos différentes sections pour découvrir des articles, des guides pratiques, des conseils d'experts et des ressources utiles.<br /><br /> Que vous soyez un agriculteur passionné, un éleveur expérimenté ou simplement curieux d'en savoir plus, notre site est conçu pour répondre à vos besoins.

                            Bonne visite et profitez pleinement de notre site dédié à l'agriculture et à l'élevage !
                        </p>
                    </div>
                </div>
            </div>





            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}> Marche des produits agricole</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">



                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}>Marche des produits agricole</h2>
                        <p className="text-gray-500 p-1 text-[20px]  reduitTailParagr ">

                            Si vous êtes intéressé par les prix des produits agricoles, vous êtes au bon endroit. Nous vous fournissons les informations les plus récentes sur les prix des différentes cultures, des produits laitiers, de la viande et bien plus encore.

                            Notre équipe s'efforce de collecter et de mettre à jour régulièrement les données sur les prix afin de vous offrir des informations fiables et précises. Que vous soyez un agriculteur, un professionnel de l'industrie agroalimentaire ou simplement curieux des tendances actuelles, vous trouverez ici les informations dont vous avez besoin.


                            <br /><br />

                            Nous vous souhaitons une expérience agréable et enrichissante sur notre site !

                        </p>
                    </div>
                    <div className={` ${imageLoading3 && sizeInfer3 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading3 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading3(false)
                            }, 1000)
                        }} src="../image/produitagricoles.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>

            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}> Marche des produits et des élevages</h2>

                    <div className={` ${imageLoading4 && sizeInfer4 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                        {imageLoading4 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading4(false)
                            }, 1000)
                        }} src="../image/Marchedesproduitd'elevages.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] text-blue-600 textSizeTitre font-serif w-full text-center`}> Marche des produits et des élevages</h2>
                        <p className="text-gray-500 p-1 text-[20px]  reduitTailParagr ">

                            Vous avez choisi l'option de consultation des prix liés à l'élevage. Nous sommes ravis de vous fournir toutes les informations nécessaires pour vous aider dans vos projets d'élevage.

                            <br /><br />

                            Que vous soyez un éleveur expérimenté ou simplement curieux de découvrir le monde de l'élevage, nous avons rassemblé une gamme complète de prix pour répondre à vos besoins. Explorez nos différentes catégories, des équipements aux fournitures en passant par les animaux, et trouvez les prix compétitifs qui correspondent à vos attentes. N'hésitez pas à nous contacter si vous avez des questions ou besoin d'assistance. Bonne découverte !
                        </p>
                    </div>
                </div>
            </div>
        </>


    )
}

export default Agri_ElevVisitorHome

