import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import axios from 'axios';
import { FadeLoader } from 'react-spinners'

function Contact() {
    const [isNom, setIsNom] = useState('');
    const [animationClassNom, setAnimationClassNom] = useState('');
    const nomRef = useRef(null)

    const [isPrenom, setIsPrenom] = useState('');
    const [animationClassPrenom, setAnimationClassPrenom] = useState('');
    const prenomRef = useRef(null)

    const [isEmail, setIsEmail] = useState('');
    const [animationClassEmail, setAnimationClassEmail] = useState('');
    const emailRef = useRef(null)

    const [titre, settitre] = useState('');
    const [animationClasstitre, setAnimationClasstitre] = useState('');
    const titreRef = useRef(null)

    const [isTextarea, setIsTextarea] = useState('');
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const textaresRef = useRef(null)

    const [autoScrollHeight, setAutoScrollHeight] = useState(50);
    const [valueTextarea, setValueTextarea] = useState('');
    function validateEmail(email) {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
    }
    const [spinnerButton, SetSpinnerButton] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isNom.trim() === '') {
            toast.warning('Le nom est obligatoire', {
                className: 'custom-toast',
                autoClose: 2000
            });
            setAnimationClassNom('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassNom('');
            }, 3000);
            nomRef.current.focus()
            return;
        }

        if (isPrenom.trim() === '') {
            toast.warning('Le prénom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassPrenom('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassPrenom('');
            }, 3000);
            prenomRef.current.focus()
            return;
        }

        if (isEmail.trim() === '') {
            toast.warning("L'adresse email est obligatoire", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassEmail('');
            }, 3000);
            emailRef.current.focus()
            return;
        }
        if (!validateEmail(isEmail)) {
            toast.error("L'adresse email doit incorrect", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX border-red-500');
            setTimeout(() => {
                setAnimationClassEmail('');
            }, 3000);
            emailRef.current.focus()
            return;
        }
        if (titre.trim() === '') {
            toast.warning("Le titre de la suggestion est obligatoire", {
                autoClose: 2000
            });
            setAnimationClasstitre('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClasstitre('');
            }, 3000);
            titreRef.current.focus()
            return;
        }
        if (isTextarea.trim() === '') {
            toast.warning('Votre suggestion est nécessaire', {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassTextarea('');
            }, 3000);
            textaresRef.current.focus()
            return;
        }

        const data = {
            nom: isNom,
            prenom: isPrenom,
            email: isEmail,
            titre: titre,
            suggestion: isTextarea
        }

        SetSpinnerButton(true)
        axios.post(" https://btrproject.burundientempsreel.com/contact", data).then((response) => {
            toast.success(response.data)
            setIsPrenom("")
            setIsNom("")
            setIsEmail("")
            settitre("")
            setIsTextarea("")
        }).catch((error) => {
            console.log(error.message);
        }).finally(() => {
            SetSpinnerButton(false)
        });
    };

    return (
        <div className=" h-max flex justify-center  p-2 flex-col items-center">
            <div className="p-2 bg-white text-gray-500 rounded shadow w-[85%]">
                <div className='p-2'>
                    <h2 className='text-[35px] text-blue-700 textSizeTitre'>Remerciement</h2>
                    <div className="text-[25px] reduitTailParagr">
                        Nous apprécions votre visite et votre intérêt pour nos services.
                        Si vous rencontrez des problèmes ou des difficultés lors de votre
                        utilisation du site, nous sommes là pour vous aider.

                        Votre expérience est importante pour nous, et nous sommes engagés
                        à fournir un service de qualité.
                        <br />
                        <br />
                        Si vous avez des suggestions d'amélioration ou si vous rencontrez des problèmes techniques,
                        n'hésitez pas à nous en informer. Votre retour nous permettra d'identifier les problèmes
                        et de les résoudre rapidement pour rendre votre expérience sur notre site encore meilleure.


                        Nous vous remercions de votre soutien et de votre
                        collaboration. Nous sommes impatients de vous offrir
                        une expérience agréable et satisfaisante sur notre site.
                        <br />
                        <br />
                        N'hésitez pas à nous contacter en completant le formulaire ci-dessous si vous avez des questions
                        ou des préoccupations.
                    </div>
                </div>
            </div>
            <div>
                <h2 className="p-2 text-center mb-[1em] text-blue-600 text-[35px]"> Formulaire de contact</h2>
                <div className="bg-white border border-blue-600 shadow-2xl m-3 p-4 rounded w-[40em] h-max Contactresponsive31">
                    <form onSubmit={handleSubmit}>
                        <div className="flex mb-6 ">
                            <div className="transition-all flex w-[100%] justify-between conctat InputContactresponsive31">
                                <div className="relative h-11 w-1/2 InputsContact mr-2">
                                    <input ref={nomRef}
                                        value={isNom}
                                        onInput={(e) => setIsNom(e.target.value)}
                                        className={`peer h-full w-full border-b-2 border-green-700 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassNom}`}
                                        placeholder=" "
                                    />
                                    <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Nom
                                    </label>
                                </div>

                                <div className="relative h-11 InputsContact w-1/2 mr-2">
                                    <input ref={prenomRef}
                                        value={isPrenom}
                                        onInput={(e) => setIsPrenom(e.target.value)}
                                        className={`peer h-full w-full border-b-2 border-green-700 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassPrenom}`}
                                        placeholder=" "
                                    />
                                    <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Prénom
                                    </label>
                                </div>

                                <div className="relative h-11 InputsContact w-1/2">
                                    <input ref={emailRef}
                                        value={isEmail}
                                        onInput={(e) => setIsEmail(e.target.value)}
                                        className={`peer h-full w-full border-b-2 border-green-700 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassEmail}`}
                                        placeholder=" "
                                    />
                                    <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Email
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="relative h-full w-full mt-[2em]">
                            <input ref={titreRef}
                                value={titre}
                                onInput={(e) => settitre(e.target.value)}
                                className={`peer h-full w-full border-b-2 border-green-700 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClasstitre}`}
                                placeholder=" "
                            />
                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Titre de la suggestion
                            </label>
                        </div>
                        <div className="relative h-full w-full mt-[2em]">
                            <textarea ref={textaresRef}
                                value={isTextarea}
                                onChange={(e) => {
                                    setIsTextarea(e.target.value);
                                    setAutoScrollHeight(e.target.scrollHeight);
                                    setValueTextarea(e.target.value.trim().length);
                                    if (!valueTextarea) {
                                        setAutoScrollHeight(50);
                                    }
                                }}
                                placeholder=" "
                                style={{ height: `${autoScrollHeight}px` }}
                                className={`peer personalTextarea leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassTextarea}`}
                            />
                            <label
                                className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-blue-gray-500`}
                            >
                                Suggestion
                            </label>
                        </div>
                        <div className="flex justify-end items-center mt-[20px]">



                            {spinnerButton ? (
                                <>
                                    <div className="flex justify-end items-center  relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                                        <div className='absolute bg-transparent   pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                                            <FadeLoader
                                                color="rgb(255, 255, 255)"
                                                height={10}
                                                margin={-9}
                                                radius={100}
                                                speedMultiplier={1}
                                                width={1}
                                            /></div>
                                        <label htmlFor="send" className="w-max flex justify-end p-1 mx-1">
                                            <i itle="Envoyer" className="bi bi-send bg-blue-600 rounded cursor-pointer text-white"> </i>
                                        </label>
                                    </div>
                                </>
                            ) : (
                                <>

                                    <div className="flex bg-blue-600 rounded cursor-pointer pl-2 py-1 text-white">
                                        <input type="submit" id="send" value="Envoyer" />
                                        <label htmlFor="send" className="w-max flex justify-end p-1 mx-1">
                                            <i itle="Envoyer" className="bi bi-send bg-blue-600 rounded cursor-pointer text-white"> </i>
                                        </label>
                                    </div>
                                </>
                            )}



                        </div>
                    </form>
                </div>
            </div>

            <div className="w-[80%] text-center reduitTailParagr text-gray-500 text-[25px]">
                Nous vous souhaitons une excellente
                journée et nous espérons que vous
                apprécierez votre expérience sur
                notre site !
            </div>
        </div>
    );
}

export default Contact;
