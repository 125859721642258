import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useRef } from 'react';

function ModifierVenteMarcheUser() {
    const { pathname } = useLocation()
    const [clickSlideHidden, setclickSlideHidden] = useState(false)


    const [click, setclick] = useState(false)
    const [clickCommande, setclickCommande] = useState(false)


    const [nomProduits, setnomProduits] = useState('');
    const [animationClassnomProduits, setAnimationClassnomProduits] = useState('');
    const nomProduitsRef = useRef(null)

    const [prixUni, setprixUni] = useState('');
    const [animationClassprixUni, setAnimationClassprixUni] = useState('');
    const prixUniRef = useRef(null)

    const [textarea, setTextarea] = useState('');
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const textaresRef = useRef(null)

    const [autoScrollHeight, setAutoScrollHeight] = useState(50);
    const [valueTextarea, setValueTextarea] = useState('');

    const Client = /^\/Client.*/
    const Produits = /^\/Produits.*/

    useEffect(() => {
        const windowClicked = () => {
            setclick(false);
            setclickSlideHidden(false);
        };

        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        };
    }, [])


    const handleSubmit = (e) => {


        if (nomProduits.trim() === '') {
            toast.warning('Le nom du produit est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassnomProduits('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassnomProduits('');
            }, 3000);
            nomProduitsRef.current.focus()
            e.preventDefault();
            return;
        }

        if (prixUni.trim() === '') {
            toast.warning('Le prix unitaire est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassprixUni('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassprixUni('');
            }, 3000);
            prixUniRef.current.focus()
            e.preventDefault();
            return;
        }

        if (textarea.trim() === '') {
            toast.warning('Le description du prpduit est nécessaire', {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassTextarea('');
            }, 3000);
            textaresRef.current.focus()
            e.preventDefault();
            return;
        }
    }


    return (
        <>


            <div className='bg-gray-200 sticky top-0 z-10 flex justify-between px-10 items-center border-blue-300 border-b paddings'>

                <Link
                    to="/User/Ventes"
                    className="relative left-2 flex bg-blue-500 text-white px-2 py-1 rounded cursor-pointer"
                >
                    <i class="bi bi-arrow-return-left mr-2"></i>
                    <p className='hiddess'>Retour</p>
                </Link>
                <div className="w-full overflow-hidden h-11 flex justify-center items-center mr-2">
                    <div className="text-gray-500 mot1"> Modifier le vente</div>
                </div>

            </div>




            <div className="w-full  flex justify-center pb-20">
                <div className={`border-2 rounded border-blue-600 p-2 mt-3   w-[40em] responsive3Form1 `}>
                    <form onSubmit={handleSubmit}>
                        <div className="mt-6 w-full ">
                            <div className="transition-all  w-[100%]">
                                <div className="flex justify-around flexVentes">
                                    <div desable onClick={(e) => { alert('Iyi partie azoba ari readonly'); e.stopPropagation() }} className=" pointer-events-none w-[50%] p-2 border-r-2 border-white parts1">
                                        <div className="relative h-11 w-full InputsContact mr-2">
                                            <input ref={nomProduitsRef}
                                                value={nomProduits}
                                                onInput={(e) => setnomProduits(e.target.value)}
                                                className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassnomProduits}`}
                                                placeholder=" "
                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Nom du produit
                                            </label>
                                        </div>

                                        <div className="relative h-11 w-full InputsContact mt-5">
                                            <input ref={prixUniRef}

                                                value={prixUni}
                                                onInput={(e) => setprixUni(e.target.value)}
                                                className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassprixUni}`}
                                                placeholder=" "
                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Nom du Client
                                            </label>
                                        </div>


                                        <div className="relative h-11 w-full InputsContact mt-5">
                                            <input ref={prixUniRef}

                                                value={prixUni}
                                                onInput={(e) => setprixUni(e.target.value)}
                                                className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassprixUni}`}
                                                placeholder=" "
                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Email
                                            </label>
                                        </div>

                                        <div className="relative h-11 w-full InputsContact mt-5">
                                            <input ref={prixUniRef}

                                                value={prixUni}
                                                onInput={(e) => setprixUni(e.target.value)}
                                                className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassprixUni}`}
                                                placeholder=" "
                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Telephone
                                            </label>
                                        </div>
                                    </div>

                                    <div className=" w-[50%] p-2 parts1">
                                        <div className="relative h-11 w-full InputsContact ">
                                            <input ref={prixUniRef}
                                                value={prixUni}
                                                onInput={(e) => setprixUni(e.target.value)}
                                                className={`peer pointer-events-none h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassprixUni}`}
                                                placeholder=" "

                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Address
                                            </label>
                                        </div>

                                        <div className="relative h-11 w-full InputsContact mt-5">
                                            <input ref={prixUniRef}

                                                value={prixUni}
                                                onInput={(e) => setprixUni(e.target.value)}
                                                className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassprixUni}`}
                                                placeholder=" "
                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Quantite
                                            </label>
                                        </div>
                                        <div className="relative h-11 w-full InputsContact mt-5">
                                            <input ref={prixUniRef}

                                                value={prixUni}
                                                onInput={(e) => setprixUni(e.target.value)}
                                                className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassprixUni}`}
                                                placeholder=" "
                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Prix payé
                                            </label>
                                        </div>
                                        <div className="relative h-11 w-full InputsContact mt-5">
                                            <input ref={prixUniRef}

                                                value={prixUni}
                                                onInput={(e) => setprixUni(e.target.value)}
                                                className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassprixUni}`}
                                                placeholder=" "
                                            />
                                            <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Prix total
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                <div className="flex justify-end items-center mt-[20px]">
                                    <div className="flex bg-blue-600 rounded cursor-pointer pl-2 py-1 text-white">
                                        <input type="submit" id="send" hidden />
                                        <label htmlFor="send" className="w-max flex justify-end p-1 cursor-pointer mx-1 ">
                                            <span className='mr-2'>Modifier</span>
                                            <div className="bi bi-send"></div>
                                        </label>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ModifierVenteMarcheUser
