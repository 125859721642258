import React from 'react'

import NoSeller from './NoSeller'
function RegisterMarketComponent() {
  return (
    <>

      <div class="p-4 ">
        <NoSeller />
      </div>
    </>
  )
}

export default RegisterMarketComponent

