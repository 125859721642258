import React from "react";
import HeadProduct from "./HeadProduct";
import ProductDetail from "./ProductDetail";
import SimilarProduct from "./SimilarProduct";
import Description from "./Description";

function Content_Container({ isVisitorLogged, SetLoginPopUp }) {
  return (
    <>
      <div class="-mb-40 bg-white pb-32 mx-auto px-5  text-gray-700 overflow-x-hidden">
        <HeadProduct />
        <ProductDetail isVisitorLogged={isVisitorLogged} SetLoginPopUp={SetLoginPopUp} />
        <Description />
        <SimilarProduct />
      </div>
    </>
  );
}

export default Content_Container;
