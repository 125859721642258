/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Content_ContainerCartBonus from "./Content_ContainerCartBonus";

function CartsComponentBonus({ SetQuestionUser }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div className={isMobile ? "  " : " "}>
        <Content_ContainerCartBonus SetQuestionUser={SetQuestionUser} />
      </div>
    </>
  );
}

export default CartsComponentBonus;
