
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { dechiffrement } from '../../../../module';
import { toast } from 'react-toastify';
import swal from 'sweetalert2';

function DetteMarcheUserDetaill({ isdivScroll }) {
    const history = useNavigate()
    const { id } = useParams();
    const venteId = dechiffrement(id);

    const [Modifier, SetModifier] = useState(false)

    const [dettesTotal, SetDettesTotal] = useState(0)

    const [dettePaye, SetDettePaye] = useState()
    const [animationClassdetteprix, setAnimationClassDetteprix] = useState('');
    const elemetRefDetteprix = useRef(null)

    const [detteReste, SetdetteRest] = useState(dettesTotal)

    useEffect(() => {
        if (dettePaye > dettesTotal) {
            toast.warning('Vous avez depasse la dette total', {
                autoClose: 2000
            });
            setAnimationClassDetteprix('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassDetteprix(' ')
            }, 3000)
            elemetRefDetteprix.current.focus()
        }
        SetdetteRest(dettesTotal - dettePaye)


        const hundleKey = (e) => {
            const codeKey = e.charCode


        }

        window.addEventListener('keypress', hundleKey)

        return () => {
            window.removeEventListener('keypress', hundleKey)
        }
    }, [dettePaye])

    useEffect(() => {
        if (detteReste < 0) {
            SetdetteRest(0)
        }
    }, [detteReste])

    const DeleteDette = () => {
        swal.fire({
            title: 'Suppression de dette',
            text: 'Si vous êtes sûr de supprimer définitivement la dette de Venuste!!',
            icon: 'warning',
            width: '300px',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Oui',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Non',
            customClass: {
                container: 'h-full w-full',
                title: 'text-[17px]',
                icon: 'h-[55px] w-[55px]',
                heightAuto: '100px',
            },
            heightAuto: false,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`https://btrproject.burundientempsreel.com/autovente/payedette/${idvent}`, {
                    dettePaye: dettesTotal,
                    detteReste: 0
                }).then((response) => {
                    toast.success("Vous l'avez définitivement supprimé", {
                        position: 'top-center',
                        autoClose: 5000,
                    });
                    history('/User/Dettes')
                }).catch((error) => {
                    console.error(error.message);
                    if (error.response) {
                        console.error(error.response.data);
                    }
                });

            } else if (result.dismiss === swal.DismissReason.cancel) {
                toast.warning("Vous avez annulé la suppression du dette", {
                    position: 'top-center',
                    autoClose: 1000,
                });
            }

        });
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (isNaN(parseFloat(dettePaye))) {
            toast.warning('Entre le prix de dette Payé', {
                autoClose: 2000
            });
            setAnimationClassDetteprix('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassDetteprix(' ')
            }, 3000)
            elemetRefDetteprix.current.focus()
            return;
        }
        axios.put(`https://btrproject.burundientempsreel.com/autovente/payedette/${idvent}`, {
            dettePaye: dettePaye,
            detteReste: detteReste
        }).then((response) => {
            toast.success("La partie de la dette est payée avec succès", {
                position: 'top-center',
                autoClose: 5000,
            });
            history('/User/Dettes')
        }).catch((error) => {
            console.error(error.message);
            if (error.response) {
                console.error(error.response.data);
            }
        });

    }

    const ModiDettes = (e) => {
        SetModifier(!Modifier);
        setTimeout(() => {
            if (isdivScroll.current) {
                isdivScroll.current.scrollTop = isdivScroll.current.scrollHeight;
            }
        }, 0);
        e.stopPropagation()
    };

    useEffect(() => {
        const hundleclick = () => {
            SetModifier(false);
        }
        window.addEventListener('click', hundleclick)
        setTimeout(() => {
            isdivScroll.current.style.scrollBehavior = 'smooth';
        }, 0);

        return () => {
            window.removeEventListener('click', hundleclick)
        }
    }, []);

    const [vent, setvente] = useState({});
    const [idvent, setIdVent] = useState('');
    useEffect(() => {
        axios.get(`https://btrproject.burundientempsreel.com/autovente/getonvente/${venteId}`).then((response) => {
            setvente(response.data)
            SetDettesTotal(response.data.dette.montant);
            setIdVent(response.data.id);
        }).catch((err) => {
            console.error(err.message)
            if (err.response) {
                console.log(err.response.data);
            }
        });
    }, [venteId])


    return (
        <div>
            <div className='mt-3'>
                <div className='ml-5 rounded px-2 py-1 bg-blue-600 w-max items-center text-white flex justify-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                    </svg>
                    <Link to='/User/Dettes' className='ml-1 px-2 py-1 bg-blue-600 text-white'>Retour</Link>
                </div>
            </div>

            <div className="my-8">

                <section className="flex flex-col md:flex-row  justify-center">
                    <div className="bg-white  w-full  border border-blue-700 shadow-2xl rounded-2xl flex flex-col max-w-7xl sm:p-5 items-center">
                        <div className="py-8 px-8 w-full">
                            <div>
                                <div className='overflow-hidden'>
                                    {vent && vent.client && (
                                        <>
                                            < p > <b>Nom:</b> {vent.client.nom}</p>
                                            <p><b>Tel:</b> {vent.client.tel}</p>
                                            <p><b>Email:</b> {vent.client.email}</p>
                                            <p><b>Addresse:</b> {vent.client.address}</p>
                                            <p><b>Date du dette:</b> Le {moment(vent.createdAt).locale('fr').format('DD/MM/YYYY HH:mm:ss')}</p>
                                            <p><b>Montant qui doit Payé:</b>  {vent.prixtotal} Fb</p>
                                            <p><b>Montant déjà Payé:</b>  {vent.montantdejapaye} Fb</p>
                                        </>
                                    )}
                                </div>
                            </div>



                            <div className="h-full mt-10 ">
                                <h1 className="text-left text-2xl hidden font-bold">
                                    Tous les articles du panier
                                </h1>
                                <div className="">
                                    <h2 className="font-bold text-2xl text-blue-500">

                                        Produits commandés
                                    </h2>

                                </div>
                                <hr class="h-px my-2 bg-gray-300 border-0 dark:bg-gray-700" />
                                <div className="mx-auto  justify-center md:flex  xl:px-0">
                                    <div className="rounded-lg w-full">


                                        {vent && vent.ventecontents && vent.ventecontents.map((p) => {
                                            return (
                                                <div className="justify-between mb-6 p-4 w-full rounded-lg border border-gray-200 bg-gray-100  shadow-md sm:flex sm:justify-start">
                                                    <div>
                                                        <img draggable='false'
                                                            src={`https://btrproject.burundientempsreel.com/uploads/market/${p.produit.profil}`}
                                                            alt="product-image"
                                                            className="w-full rounded-lg sm:w-40"
                                                        />
                                                    </div>
                                                    <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                                                        <div className="mt-5  sm:mt-0 ">

                                                            <div className="flex flex-row justify-between ">
                                                                <h2 className="text-lg font-bold text-gray-900">
                                                                    {p.produit.titre}
                                                                </h2>
                                                            </div>
                                                            <p className="mt-1 text-base text-gray-700">
                                                                Quantite: {p.quantite}
                                                            </p>
                                                            <p className="mt-1 text-base text-gray-700">
                                                                Prix unitaire: {p.produit.prix}
                                                            </p>
                                                            <p className="mt-1 text-base text-gray-700">
                                                                Categories:   {p.produit.categorie}
                                                            </p>
                                                            <p className="mt-1 text-base text-gray-700">
                                                                Déscription: {p.produit.description}
                                                            </p>

                                                        </div>
                                                        <div className="mt-4  flex  md:flex-row flex-row-reverse justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">

                                                            <div className="flex items-center space-x-4">
                                                                <div class="flex items-baseline mb-1 space-x-2 font-roboto mt-4">
                                                                    <p class="text-xl text-primary font-semibold">
                                                                        {p.produit.prix * p.quantite}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)
                                        })}







                                    </div>
                                    <div className="justify-between sm:ml-6 py-3 px-4  sm:w-[20em] flex-col w-full rounded-lg border border-gray-200 bg-gray-100 h-max shadow-md sm:flex sm:justify-start">
                                        <div>
                                            <p className='text-xl'><b>Dette Total:</b>{vent.dette && vent.dette.montant} F </p>
                                        </div>
                                        <div className='w-full h-max flex justify-end my-5 items-center'>
                                            <div onClick={ModiDettes} className=' text-white bg-blue-400 hover:bg-blue-800 transition-all rounded cursor-pointer py-2 px-4 m-2'>Modifier</div>
                                            <div onClick={DeleteDette} className=' text-white bg-red-400 hover:bg-red-800 transition-all rounded cursor-pointer py-2 px-4 m-2'>Supprimer</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()} className={` ${Modifier ? 'block' : 'hidden'} mt-2 shadow-2xl border border-violet-800 w-[95%]  sm:w-[20em] bg-white py-4 px-4`}>
                            <div className=" transition-all flex w-[100%] justify-between  ">
                                <div class="relative h-11 w-full mb-6 flex ">
                                    <input value={dettesTotal.toLocaleString('fr-FR')} onInput={(e) => { SetDettesTotal(e.target.value); }} className={`peer  h-full w-full border-b-2 border-green-700 pointer-events-none bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 `} placeholder=" " />
                                    <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Dette total
                                    </label>
                                </div>
                            </div>

                            <div className="transition-all flex w-full justify-between  flex-col">
                                <div class="relative h-11 w-full  ">
                                    <input type='number' value={dettePaye} ref={elemetRefDetteprix} onInput={(e) => { SetDettePaye(e.target.value); }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassdetteprix}`} placeholder=" " />
                                    <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Dettes payés
                                    </label>
                                </div>
                                <div class="relative h-11 mt-2  w-full">
                                    <input value={detteReste} type='number'
                                        readOnly className={`peer pointer-events-none  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 }`} placeholder=" "
                                    />
                                    <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Dette restés
                                    </label>
                                </div>
                            </div>
                            <div className='w-full flex justify-end items-center '>
                                <input type='submit' hidden id='Modifier' />
                                <label htmlFor='Modifier' className='bg-blue-500 cursor-pointer px-2 py-1 rounded m-2 text-white'>Modifier</label>
                            </div>
                        </form>
                    </div>
                </section>
            </div >


        </div >
    )
}

export default DetteMarcheUserDetaill
