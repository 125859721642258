/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SpinnerDemarage from "../../SpinnerDemarage/SpinnerDemarage";
import { FadeLoader } from 'react-spinners'
function SinscrireMarcheUser({ LoginPopUp, SetLoginPopUp, SetSignPopUp, SignPopUp }) {
    const [boutLoading, setboutLoading] = useState(false);

    const [nom, Setnom] = useState('')
    const [animationClassNom, setAnimationClassNom] = useState('');
    const elemetRefNom = useRef(null)

    const [prenom, Setprenom] = useState('')
    const [animationClassPrenom, setAnimationClassPrenom] = useState('');
    const elemetRefPrenom = useRef(null)

    const [tel, SetTel] = useState('')
    const [animationClassTel, setAnimationClassTel] = useState('');
    const elemetRefTel = useRef(null)

    const [adrress, Setadrress] = useState('')
    const [animationClassadrress, setAnimationClassadrress] = useState('');
    const elemetRefadrress = useRef(null)
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
    const [passwordValue, setPasswordValue] = useState('')
    const [animationClassPassword, setAnimationClassPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const elemetRefPass = useRef(null)

    const [confPass, SetconfPass] = useState('')
    const [animationClassConfPass, setAnimationClassConfPass] = useState('');
    const [ConfpasswordVisible, setConfPasswordVisible] = useState(false);
    const elemetRefConfPass = useRef(null)

    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const [emailValue, setEmailValue] = useState('')
    const [animationClassEmail, setAnimationClassEmail] = useState('');
    const elemetRefEmail = useRef(null)

    const [profil, SetProfil] = useState(null)
    const [photo, SetPhoto] = useState(null)




    let [loading, setLoading] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault()
        if (nom.trim() === '') {
            toast.warning('Le nom est obligatoire', {
                autoClose: 2000,
                position: "top-center",
            });
            setAnimationClassNom('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassNom(' ')
            }, 3000)
            elemetRefNom.current.focus()
            return
        } else if (prenom.trim() === '') {
            toast.warning('Le  prenom est obligatoire', {
                autoClose: 2000,
                position: "top-center",
            });
            setAnimationClassPrenom('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassPrenom(' ')
            }, 3000)
            elemetRefPrenom.current.focus()

            return
        }
        else if (tel.trim() == "") {
            toast.warning("Le numero de téléphone  est obligatoire !!", {
                autoClose: 2000, position: "top-center",
            });
            setAnimationClassTel('animate__animated animate__shakeX placeholder-shown:border-blue-700 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassTel(' ')
            }, 3000)
            elemetRefTel.current.focus()

            return
        }
        else if (adrress.trim() == '') {
            toast.warning("L'addresse est obligatoire !!", {
                autoClose: 2000,
                position: "top-center",
            });
            setAnimationClassadrress('animate__animated animate__shakeX placeholder-shown:border-blue-500 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassadrress(' ')
            }, 3000)
            elemetRefadrress.current.focus();

            return
        } else if (passwordValue.trim() == "") {
            toast.warning("Le mot de passe est obligatoire !!", {
                autoClose: 2000,
                position: "top-center"
            });
            setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-blue-700 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassPassword(' ')
            }, 3000)
            elemetRefPass.current.focus()

            return
        } else if (!passwordValue.trim().match(PasswordRegex)) {

            toast.error(`Le mot de passe doit contenir au moins
                          une lettre minuscule,lettre majuscule,un chiffre et un caractère spécial
                        `, {
                autoClose: 5000,
                position: "top-center",


            });
            setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassPassword(' ')
            }, 5000)
            elemetRefPass.current.focus()

            return
        }
        else if (!passwordValue.length > 9) {

            toast.error(`Le mot de passe doit etre inférieure à 9`, {
                autoClose: 5000,
                position: "top-center",


            });
            setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassPassword(' ')
            }, 5000)
            elemetRefPass.current.focus()

            return
        }
        else if (confPass.trim() == '') {
            toast.warning(`confirme le mot de passe saisi`, {
                autoClose: 5000,
                position: "top-center",

            });
            setAnimationClassConfPass('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassConfPass(' ')
            }, 5000)
            elemetRefConfPass.current.focus()

            return
        }
        else if (confPass != passwordValue) {
            toast.error(`La confirmation du mot de passe ne correspond pas au mot de passe saisi. Veuillez vérifier et réessayer`, {
                autoClose: 5000,
                position: "top-center",

            });
            setAnimationClassConfPass('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassConfPass(' ')
            }, 5000)
            elemetRefPass.current.focus()

            return
        } else if (emailValue.trim() == '') {
            toast.warning("L'address email est obligatoire !!", {
                autoClose: 2000,
                position: "top-center",
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-blue-500 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            elemetRefEmail.current.focus();

            return
        } else if (!emailValue.trim().match(emailRegex)) {
            toast.error("L'address email est incorrect", {
                autoClose: 2000,
                position: "top-center"
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            elemetRefEmail.current.focus();
            return
        } else if (profil == null) {
            toast.warning(`Votre photos de profil est obligatoire`, {
                autoClose: 5000,
                position: "top-center",
            });

            return
        } else {
            setboutLoading(true)
            const formData = new FormData();
            formData.append("nom", nom);
            formData.append("prenom", prenom);
            formData.append("tel", tel);
            formData.append("address", adrress);
            formData.append("password", passwordValue);
            formData.append("email", emailValue);
            formData.append("photo", photo);

            axios
                .post(" https://btrproject.burundientempsreel.com/user/register", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    if (response.data.error) {
                        setTimeout(() => {
                            toast.error(response.data.error)
                        }, 500);

                    } else {
                        toast.success('Inscription réussi;vous etes le bon visiteur de nos sites 🎉🎉',
                            {
                                position: "top-center",
                                autoClose: 7000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        Setnom("");
                        Setprenom("");
                        SetTel("");
                        Setadrress("");
                        setEmailValue("");
                        setPasswordValue("");
                        SetProfil("");

                        const timer = setTimeout(() => {
                            setLoading(false);

                        }, 1500);
                        SetSignPopUp(false);
                        return () => clearTimeout(timer);
                    }
                })
                .catch((error) => {

                    if (error.response) {
                        const errorMessage = error.response.data.error;
                        toast.error(errorMessage);
                    }

                })
            setTimeout(() => {
                setboutLoading(false)
            }, 3000);
        }

    }
    const [imageLoading, SetImageLoading] = useState(true)
    const [imageLoading1, SetImageLoading1] = useState(true)

    return (
        <>
            {loading && <SpinnerDemarage />}
            <div className='fixed w-full h-full z-[2000] top-0 left-0 flex justify-center overflow-hidden items-center bg-white'>
                <div className='w-full py-4 h-full overflow-auto flex justify-center  p-4'>
                    <div className="bg-white shadow-2xl p-2 rounded-2xl  border border-blue-600 w-[30em] h-max">
                        <div className="flex justify-end px-4 items-center">
                            <div onClick={() => { SetSignPopUp(false) }} className="bg-blue-500 rounded-full cursor-pointer w-[30px] h-[30px] flex justify-center items-center text-[22px] font-serif text-white"><i class="bi bi-x-circle-fill"></i></div>

                        </div>
                        <div className="w-full flex justify-center flex-col items-center">
                            <div className="w-[6em] h-[6em] relative overflow-hidden">
                                {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover scale-[2]" /></div>}
                                <img draggable='false' onLoad={() => {
                                    setTimeout(() => {
                                        SetImageLoading(false)
                                    }, 1000)
                                }} src={` https://btrproject.burundientempsreel.com/uploads/logo/btr.png`} />
                            </div>
                            <p className="text-[14px] text-gray-500">Burundi en Temps Réel</p>
                        </div>
                        <h2 className=' p-2 text-center  font-serif text-gray-400 '>S'inscrire</h2>

                        <p className='text-[15px] text-gray-500 pl-3'>Entrez vos informations d'inscription.</p>
                        <div className="p-2 my-2 rounded w-full bg-transparent h-max ">
                            <form onSubmit={handleSubmit}>
                                <div className="flex mb-6 flex-col">
                                    <div className=" transition-all flex  justify-between  ">
                                        <div class="relative h-11 w-1/2    mr-2 ">
                                            <input ref={elemetRefNom} value={nom} onInput={(e) => Setnom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNom}`} placeholder=" " />
                                            <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Nom
                                            </label>
                                        </div>

                                        <div class="relative h-11 w-1/2    mr-2 ">
                                            <input value={prenom} ref={elemetRefPrenom}
                                                onInput={(e) => Setprenom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPrenom}`} placeholder=" "
                                            />
                                            <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Prenom
                                            </label>
                                        </div>
                                    </div>
                                    <div className=" mt-[10px] transition-all flex  justify-between  ">
                                        <div class="relative h-11  w-1/2    mr-2 ">
                                            <input value={tel} ref={elemetRefTel} type='tel' onInput={(e) => SetTel(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassTel}`} placeholder=" " />
                                            <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Téléphone
                                            </label>
                                        </div>
                                        <div class="relative h-11 w-1/2    mr-2 ">
                                            <input value={adrress} ref={elemetRefadrress} type="text" onInput={(e) => Setadrress(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassadrress}`} placeholder=" " />
                                            <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Addresse
                                            </label>
                                        </div>
                                    </div>

                                    <div className=" transition-all mt-5 flex  justify-between flexpass ">
                                        <div className={`flex items-center w-1/2  relative gapps`}>
                                            <div class="relative h-11 w-full bloksInput mr-2 ">
                                                <input ref={elemetRefPass} onInput={(e) => { setPasswordValue(e.target.value) }} type={passwordVisible ? 'text' : 'password'} className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassword}`} placeholder=" " />
                                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                    Mot de passe
                                                </label>
                                            </div>
                                            <div onClick={() => { setPasswordVisible(!passwordVisible) }} className="  cursor-pointer w-[20px] h-[20px] absolute flex justify-center top-[20px] items-center right-2 ">
                                                <i class={`bi ${passwordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'} absolute`}></i>
                                            </div>
                                        </div>

                                        <div className={`flex items-center w-1/2 relative  gapps`}>
                                            <div class="relative h-11 w-full bloksInput mr-2 ">
                                                <input ref={elemetRefConfPass} value={confPass} onInput={(e) => { SetconfPass(e.target.value) }} type={ConfpasswordVisible ? 'text' : 'password'} className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassConfPass}`} placeholder=" " />
                                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                    Confirme le mot de passe
                                                </label>
                                            </div>
                                            <div onClick={() => { setConfPasswordVisible(!ConfpasswordVisible) }} className=" cursor-pointer w-[20px] h-[20px] top-[20px] absolute flex justify-center items-center right-2 ">
                                                <i class={`bi ${ConfpasswordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'} absolute`}></i>
                                            </div>
                                        </div>

                                    </div>
                                    <div className=" transition-all mt-5 flex  justify-between ">
                                        <div className={`flex items-center  relative w-full`}>
                                            <input value={emailValue} ref={elemetRefEmail} type='email'
                                                onInput={(e) => setEmailValue(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`} placeholder=" "
                                            />
                                            <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Email valide
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="ro">
                                    <label htmlFor="profiles" className="la">
                                        <span>Photos de profil</span>
                                        <label htmlFor="profiles" className='bi bi-image-fill cursor-pointer bg-blue-500 px-1 ml-4 rounded text-white'></label>
                                    </label>
                                    <input type="file" accept='image/*' hidden id='profiles' onChange={(e) => { const UrlFile = e.target.files[0]; SetPhoto(UrlFile); SetProfil(URL.createObjectURL(UrlFile)) }} />
                                    {
                                        profil ? (
                                            <div className={`${profil == "" ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'} w-[50%] h-[50%] bg-gray-200  mt-[20px]  border rounded-2xl  relative overflow-hidden border-blue-600`}>
                                                {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover scale-[2]" /></div>}
                                                <img draggable='false' onLoad={() => {
                                                    setTimeout(() => {
                                                        SetImageLoading1(false)
                                                    }, 1000)
                                                }} src={profil} alt="" className={`w-full h-full object-cover`} />
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }


                                </div>
                                <div className="flex justify-end items-center">




                                    {boutLoading ? (
                                        <>
                                            <div className="flex justify-end items-center  relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                                                <div className='absolute bg-transparent  pl-20 pt-5   w-full h-full flex justify-center items-center z-50'>
                                                    <FadeLoader
                                                        color="rgb(255, 255, 255)"
                                                        height={10}
                                                        margin={-9}
                                                        radius={100}
                                                        speedMultiplier={1}
                                                        width={1}
                                                    />
                                                </div>
                                                <input type="submit" id="send" value='Créer' className='cursor-pointer'></input>
                                                <i class="bi bi-send ml-2 "></i>
                                            </div>
                                        </>
                                    ) : (
                                        <>

                                            <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-blue-600   p-2 rounded  text-white">
                                                <input type="submit" id="send" value='Créer' className='cursor-pointer'></input>
                                                <i class="bi bi-send ml-2 "></i>
                                            </label>  </>
                                    )}



                                </div>
                            </form>
                        </div>
                        <div className="pl-3 text-gray-500">Vous avez  un compte ? <div onClick={() => { SetSignPopUp(false); SetLoginPopUp(true) }} className='text-blue-600 underline cursor-pointer'> Se_connecter</div></div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default SinscrireMarcheUser;















