import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DELETE, ADD, REMOVE } from "../../../../../Redux/Action/Action";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";

function AllCartsSeller({ SetQuestionUser }) {
  const [price, setPrice] = useState(0);
  const history = useNavigate();
  const getData = useSelector((state) => state.CartsReducer.Carts);
  const [isClicked, setIsClicked] = useState(false);
  const [quntite, SetQuntite] = useState(true);
  function test() {
    setIsClicked(!isClicked);
  }

  const dispatch = useDispatch();

  const dlt = (id) => {
    dispatch(DELETE(id));
  };

  //supprime un à un dans une commande

  function remove(e) {
    dispatch(REMOVE(e));
  }

  function send(e) {
    dispatch(ADD(e, 1));
  }

  const total = () => {
    let prices = 0;
    getData.map((ele, index) => {
      prices = Math.trunc(ele.prix) * ele.quantity + prices;
    });

    setPrice(Math.floor(prices).toLocaleString("fr-FR"));
  };
  useEffect(() => {
    total();
  }, [total]);

  if (getData.length < 1) {
    history("/User/Ventes");
  }

  function handleDelete(e) {
    if (getData.length > 1) {
      dlt(e);
    } else {
      dlt(e);
      history("/User/Ventes");
    }
  }

  const handleClose = () => {
    toast.dismiss();
  };

  const [mobile1, Setmobile1] = useState(window.innerWidth < 377.9292828290293);
  const [mobile2, Setmobile2] = useState(window.innerWidth < 552.9292828290293);
  const [mobile, Setmobile] = useState(window.innerWidth < 961.9292828290293);
  const [LoadingPagePhotos, SetLoadingPagePhotos] = useState(true);
  const [LoadingPagePhotos1, SetLoadingPagePhotos1] = useState(true);
  const [LoadingPagePhotos2, SetLoadingPagePhotos2] = useState(true);
  const [LoadingPagePhotos3, SetLoadingPagePhotos3] = useState(true);
  const [LoadingPagePhotos4, SetLoadingPagePhotos4] = useState(true);
  const [LoadingPagePhotos5, SetLoadingPagePhotos5] = useState(true);
  const [LoadingPagePhotos6, SetLoadingPagePhotos6] = useState(true);

  useEffect(() => {
    const hundleResize = () => {
      Setmobile1(window.innerWidth < 377.9292828290293);
      Setmobile(window.innerWidth < 961.9292828290293);
      Setmobile2(window.innerWidth < 552.9292828290293);
    };

    window.addEventListener("resize", hundleResize);

    return () => {
      window.removeEventListener("resize", hundleResize);
    };
  }, []);

  const LazyImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };

    const lazyLoad = () => {
      setImgSrc(src);
    };

    const elementInViewPort = () => {
      // getBoundingClientRect => returns the size of the given element and the position of it in relation to the view port
      const clientRect = imageRef.current.getBoundingClientRect();

      return (
        clientRect.top >= 0 &&
        clientRect.left >= 0 &&
        clientRect.bottom - 100 <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        clientRect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LazyImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };

  return (
    <>
      {getData.length ? (
        <div className="h-full p-2 ">
          <div class=" py-2 border border-gray-300  px-4  rounded-b-2xl justify-between flex items-center gap-3">
            <Link
              to="/User/Ventes"
              className="flex py-1 px-2 rounded bg-blue-500 text-white"
            >
              <i class="bi bi-arrow-return-left mr-3"></i>
              <p class=" font-bold">Retour</p>
            </Link>
            <p class="text-gray-400 font-serif text-center max-sm:text-xs">
              Burundi en temps reel marche en ligne
            </p>
          </div>
          <h1 className="mb-10 text-blue-500 font-serif text-left text-2xl max-sm:text-center max-sm:text-xl font-bold">
            Tous les articles du panier
          </h1>

          <div
            className={` ${mobile ? "flex-col" : ""
              } mx-auto justify-center flex    xl:flex md:space-x-6 xl:px-0`}
          >
            <div
              className={` ${mobile ? "w-full" : "xl:w-2/3"
                } rounded-lg   parentGrandss`}
            >
              {getData.map((e) => {
                return (
                  <>
                    <div
                      className={`flex bg-gray-50 shadow-2xl m-2 rounded-xl justify-between parentwidths p-2 w-[50em]`}
                    >
                      <div
                        class={` ${mobile2 ? "flex" : "hidden"}   justify-end`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="h-5 w-5 cursor-pointer duration-150  hover:text-red-500"
                          onClick={() => handleDelete(e.id)}
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                      <div className="relative p-2 overflow-hidden w-[30em] h-[30em] widths">
                        <LazyImage
                          src={` https://btrproject.burundientempsreel.com/uploads/market/${e.profil}`}
                          alt="product-image"
                          className="w-full rounded-lg sm:w-40"
                        />
                      </div>
                      <div className=" w-[18em] mt-5 widsss">
                        <div
                          class={` ${mobile2 ? "hidden" : " flex"
                            }   justify-end`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="h-5 w-5 cursor-pointer duration-150  hover:text-red-500"
                            onClick={() => handleDelete(e.id)}
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </div>

                        <div className="mt-5 sm:mt-0 md:w-48">
                          <div className="flex flex-row justify-between ">
                            <h2 className="text-lg font-bold text-gray-900">
                              {e.titre.length >= 40
                                ? e.titre.substring(0, 40) + "...."
                                : e.titre}
                            </h2>
                          </div>
                          <p className="mt-1 text-base text-gray-700">
                            Quantite: {e.quanite}
                          </p>
                        </div>
                        <div className="mt-4  flex  md:flex-row flex-row-reverse justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                          <div className="flex items-center border-gray-500">
                            <span
                              onClick={() => {
                                if (e.quantity < 2) {
                                  dlt(e.id);
                                  SetQuntite(e.quantity);
                                } else {
                                  remove(e);
                                }
                              }}
                              className="cursor-pointer rounded-l bg-gray-200 py-1 px-3.5 duration-500 hover:bg-blue-500 hover:text-blue-50"
                            >
                              {" "}
                              -{" "}
                            </span>
                            <input
                              className="h-8 w-8 border bg-white text-center text-xs outline-none"
                              type="text"
                              value={e.quantity}
                              min="1"
                            />
                            <span
                              onClick={() => {
                                if (e.quantity < e.quanite) {
                                  send(e);
                                } else {
                                  toast.error(
                                    "Vous avez depense la limite des quantites ",
                                    {
                                      position: "top-center",
                                      autoClose: 1000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                      theme: "light",
                                      onClick: handleClose, // Utilisation de la référence à la fonction handleClose
                                    }
                                  );
                                }
                              }}
                              className="cursor-pointer rounded-r bg-gray-200 py-1 px-3 duration-500 hover:bg-blue-500 hover:text-blue-50"
                            >
                              {" "}
                              +{" "}
                            </span>
                          </div>
                          <div className="flex items-center space-x-4">
                            <div class="flex items-baseline mb-1 space-x-2 font-roboto mt-4">
                              <p class="text-xl text-primary font-semibold">
                                {Math.floor(e.prix).toLocaleString("fr-FR")} Fbu
                              </p>
                              <p class="text-base text-red-400 line-through">
                                {Math.floor(e.prix) +
                                  (Math.floor(e.prix) * 20) / 100}{" "}
                                Fbu
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            {/* <!-- Sub total --> */}

            <div className="mt-6 bg-white h-full p-8 rounded-lg border shadow-2xl   lg:w-[40em]">
              <div className="flex justify-between">
                <p className="text-gray-700">Expédition</p>
                <p className="text-gray-700">0 Fbu</p>
              </div>
              <hr className="my-4" />
              <div className="flex justify-between">
                <p className="text-lg font-bold">Total</p>
                <div className="">
                  <p className="mb-1 text-lg font-bold"> {price} Fbu</p>
                </div>
              </div>

              <div class="block w-full mt-6 py-2  cursor-pointer text-center text-white bg-blue-500 border border-blue-500 rounded-2xl hover:bg-transparent hover:text-blue-500 transition"
                onClick={() => { SetQuestionUser(true) }}>
                Acheter
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default AllCartsSeller;
