import React from 'react'
import StandByClientEchangeAdmin from './StandByClientEchangeAdmin'
function StandByClientEchangeAdminComponent({ getStandByLong }) {
  return (
    <>

      <StandByClientEchangeAdmin getStandByLong={getStandByLong} />
    </>
  )
}

export default StandByClientEchangeAdminComponent
