import React from 'react'
import { Link } from 'react-router-dom'
function EdicationLivreVisitor() {

    return (
        <div
            style={{
                backgroundImage: "url('../image/bibli.jpg')",
                backgroundAttachment: "fixed",
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover'
            }}
            className='flex flex-col items-center'
        >

            <div className=" flex w-full sloger justify-around p-2 responsive1">
                <div className="w-[45%] rounded-2xl overflow-hidden  livreadmimg">
                    <img draggable='false' src="../../image/biblio.jpg" alt="" className='w-full h-full block object-cover object-center' />
                </div>
                <div className="bg-gray-200 w-[45%] flex justify-around  font-serif rounded p-2 flex-col livreadm">
                    <div className="text-[30px] font-serif text-center"><span className="mot1">Bienvenue</span> <span className="mot3">bibliothèque </span><span className='mot2'>en ligne de l'édication</span></div>
                    <div className="text-[16px] ">
                        Explorez notre riche bibliothèque de cours explicatifs couvrant différents niveaux d'éducation, de l'enseignement secondaire général aux programmes techniques universitaires. Nous sommes fiers de vous offrir une ressource complète pour vous aider à enrichir vos connaissances et à poursuivre votre parcours académique avec succès.<br /><br />
                        Que vous soyez un étudiant, un enseignant ou simplement quelqu'un qui cherche à se former, nous espérons que notre collection de cours vous aidera à atteindre vos objectifs éducatifs. Naviguez à travers nos sujets et découvrez un monde de connaissances passionnantes qui vous attend.
                    </div>
                    <div className="text-[15px] text-gray-400 w-full text-right font-serif">Burundi en temp réel</div>
                </div>

            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50" className='hidden'>
                <ellipse cx="50" cy="0" rx="50" ry="10" fill="currentColor" />
            </svg>




            <div className="flex flex-col w-[99%] items-center ">
                <div className="list bg-slate-100 w-[95%]  flex flex-col p-4 items-center">
                    <div className="w-full flex justify-end">
                        <div className="pr-10  p-1 m-1 rounded search ">
                            <p className='text-gray-500 font-serif'>Rechercher par votre choix</p>
                            <div className="search py-1 w-full search ">
                                <form className='flex  w-[100%] '>
                                    <input type="text" className='p-1 search border border-blue-700 rounded transition-all outline-none focus:border-2 focus:border-blue-500' id="" placeholder='Rechercher...' />
                                    <label htmlFor='search' className='transition-all bg-blue-400 text-white hover:bg-blue-600 p-2 ml-1 cursor-pointer rounded'><i className='bi bi-search'></i></label>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="text-[22px] mot2 py-2 bg-gray-200 pl-2 w-full">Tous les  catégories</div>

                    {/* Universiaire */}
                    <div className="mt-5 font-serif w-full text-left"><div className="bg-gray-400 border-2 border-blue-600 w-max py-1 px-2 rounded-full text-white mt-1">Niveau universitaires</div></div>
                    <div className="flex flex-wrap justify-around">
                        <div className="flex p-1 m-1 categori categori">
                            <Link to='/Visit/Livres/Educ/Detail' className=" w-[100%] flex flex-col bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px]  w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1  w-max'>FSI: <span className='text-[10px]'>Faculté des Sciences de l'Ingénieur </span> </h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        5 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>FABI: <span className='text-[10px]'>Faculté d'Agronomie et de Bio-Ingenierie</span> </h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        30 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer ">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Médecine</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        305 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Statistique</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        500 PDF
                                    </div>
                                </div>
                            </Link>
                        </div> <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>IPA: <span className='text-[10px]'>Institut de pédagogie appliquée</span></h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        305 PDF
                                    </div>
                                </div>
                            </Link>
                        </div> <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Vértenaire</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        300 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>


                    <div className="w-max bg-gray-200 ">
                        <div className="w-full flex justify-around items-center p-2">
                            <button
                                className="transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded"
                            >
                                <a href="#" className="mr-2">
                                    &laquo;
                                </a>
                                <p>Precedant</p>
                            </button>
                            <button className=" w-[30px] h-[30px] text-[white] bg-blue-600  m-1 rounded-full">1</button>
                            <button className="bg-gray-400 w-[30px] h-[30px] text-[white]  m-1 rounded-full">2</button>
                            <button className="bg-gray-400 w-[30px] h-[30px] text-[white]  m-1 rounded-full">3</button>
                            <button

                                className="transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded"
                            >
                                <p>Suivant</p>
                                <a href="#" className="ml-2">
                                    &raquo;
                                </a>
                            </button>
                        </div>
                    </div>
                    <div className="text-gray-500 mt-3">1-20 sur 35 resultants</div>





                    {/* pour ecole secondaire enseignement general */}

                    <div className="mt-10 font-serif w-full text-left"><div className="bg-gray-400 border-2 border-blue-600 w-max py-1 px-2 rounded-full text-white mt-1">Niveau secondaire enseignant général</div></div>
                    <div className="flex flex-wrap justify-around">
                        <div className="flex p-1 m-1 categori categori">
                            <Link to='/Visit/Livres/Detail' className=" w-[100%] flex flex-col bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px]  w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>MPT: <span className='text-[10px]'>Maths Physique</span> </h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        5 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>BCST: <span className="text-[10px]">Bio-Chimie et Sciences de la terre</span> </h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        30 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer ">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Langue</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        305 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>SSH : <span className='text-[10px]'>Sciencs Social et Humaine</span></h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        500 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Economie</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        500 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Pédagogie</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        305 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Vértenaire</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        300 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="w-max bg-gray-200 ">
                        <div className="w-full flex justify-around items-center p-2">
                            <button
                                className="transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded"
                            >
                                <a href="#" className="mr-2">
                                    &laquo;
                                </a>
                                <p>Precedant</p>
                            </button>
                            <button className=" w-[30px] h-[30px] text-[white] bg-blue-600  m-1 rounded-full">1</button>
                            <button className="bg-gray-400 w-[30px] h-[30px] text-[white]  m-1 rounded-full">2</button>
                            <button className="bg-gray-400 w-[30px] h-[30px] text-[white]  m-1 rounded-full">3</button>
                            <button

                                className="transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded"
                            >
                                <p>Suivant</p>
                                <a href="#" className="ml-2">
                                    &raquo;
                                </a>
                            </button>
                        </div>
                    </div>
                    <div className="text-gray-500 mt-3">1-20 sur 35 resultants</div>


                    {/* pour ecole secondaire enseignement technique*/}

                    <div className="mt-10 font-serif w-full text-left"><div className="bg-gray-400 border-2 border-blue-600 w-max py-1 px-2 rounded-full text-white mt-1">Niveau secondaire enseignant technique</div></div>
                    <div className="flex flex-wrap justify-around">
                        <div className="flex p-1 m-1 categori categori">
                            <Link to='/Visit/Livres/Detail' className=" w-[100%] flex flex-col bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px]  w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>IT: <span className='text-[10px]'>Infrmatique de télécommunication</span></h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        5 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>IM <span className='text-[10px]'>Infrmatique de maintenance</span></h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        30 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer ">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Electricité</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        305 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>IG <span className='text-[10px]'>Infrmatique de gestion</span></h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        500 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Electronique</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        305 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>Hotelerie</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        300 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>


                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>BA <span className='text-[10px]'>Banque et Assurence</span></h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        300 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>EI: <span className='text-[10px]'>Electricite Indistrielle </span></h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        300 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>EM:<span className='text-[10px]'>Electromecanique</span></h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        300 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>CT :<span className='text-[10px]'>Condicteur de travaux</span></h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        300 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>HT:<span className='text-[10px]'>Hotelerie et Tourisme</span></h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        300 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>TH: <span className='text-[10px]'>Texture et Habillement</span></h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        300 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>EF:<span className='text-[10px]'>Eau et Foret</span></h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        300 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="flex p-1 m-1 categori ">
                            <Link className=" w-[100%] bg-gray-300 rounded overflow-hidden cursor-pointer">
                                <div className="w-[100%] rounded overflow-hidden ">
                                    <img draggable='false' src="../../image/biblio.jpg" className='w-full h-full object-cover object-center' alt="" srcset="" />
                                </div>
                                <div className='text[25px] w-full mb-2 flex flex-col items-center '>
                                    <h2 className='font-serif text-blue-800 px-2 py-1  rounded-lg bg-gray-200 m-1 w-max'>ITB</h2>
                                    <div className="w-[95%] flex justify-between px-2 bg-gray-200 mot2 text-[22px]">
                                        <i class="bi bi-list-nested"></i>
                                        300 PDF
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="w-max bg-gray-200 ">
                        <div className="w-full flex justify-around items-center p-2">
                            <button
                                className="transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded"
                            >
                                <a href="#" className="mr-2">
                                    &laquo;
                                </a>
                                <p>Precedant</p>
                            </button>
                            <button className=" w-[30px] h-[30px] text-[white] bg-blue-600  m-1 rounded-full">1</button>
                            <button className="bg-gray-400 w-[30px] h-[30px] text-[white]  m-1 rounded-full">2</button>
                            <button className="bg-gray-400 w-[30px] h-[30px] text-[white]  m-1 rounded-full">3</button>
                            <button

                                className="transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded"
                            >
                                <p>Suivant</p>
                                <a href="#" className="ml-2">
                                    &raquo;
                                </a>
                            </button>
                        </div>
                    </div>
                    <div className="text-gray-500 mt-3">1-20 sur 35 resultants</div>
                </div>
            </div>
        </div>
    )
}

export default EdicationLivreVisitor
