/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SpinnerDemarage from "../../../../SpinnerDemarage/SpinnerDemarage";
import PropTypes from 'prop-types'

function DetailCommandeUserEchange() {
  const { id } = useParams();
  const [loading, SetLoading] = useState(true);
  const [clickSlideHidden, setclickSlideHidden] = useState(false);


  useEffect(() => {
    const windowClicked = () => {

      setclickSlideHidden(false);
    };

    window.addEventListener("click", windowClicked);

    return () => {
      window.removeEventListener("click", windowClicked);
    };
  }, []);
  const [comande, setCommade] = useState({});
  useEffect(() => {
    axios
      .get(` https://btrproject.burundientempsreel.com/Commande/details/${id}`)
      .then((response) => {
        setCommade(response.data);
        ;
        setTimeout(() => {
          SetLoading(false)
        }, 1000);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, [id]);



  const [loadingImages, setLoadingImages] = useState([]);


  const handleImageLoad = (index) => {
    setLoadingImages((prevLoadingImages) => {
      const updatedLoadingImages = [...prevLoadingImages];
      setTimeout(() => {
        updatedLoadingImages[index] = false;
      }, 5000);
      return updatedLoadingImages;
    });
  };

  const LoadingImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };
    const lazyLoad = () => {
      setImgSrc(src);
    };



    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LoadingImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };

  const [mobile, Setmobile] = useState(window.innerWidth < 562.8494040505)
  const [mobile1, Setmobile1] = useState(window.innerWidth < 350.8494040505)
  useEffect(() => {
    const hundleSize = () => {
      Setmobile(window.innerWidth < 562.8494040505)
      Setmobile1(window.innerWidth < 350.8494040505)
    }
    window.addEventListener('resize', hundleSize)
    return () => {
      window.removeEventListener('resize', hundleSize)
    }
  }, [])

  return (
    <div className="min-h-[90vh] pb-10">
      <div className="">
        {loading && <SpinnerDemarage />}
        <div className="bg-white flex justify-center relative left-2 top-2 rounded-2xl border border-blue-700 shadow-2xl w-[95%] p-2">
          <div className="py-2  pl-1 sm:pl-10  w-full ">
            <h2 className={` ${mobile1 ? 'text-[18px]' : 'text-[25px]'} text-blue-700`}>
              Detail
              de la
              commande
            </h2>

            {comande.echangeurId && (
              <div className="w-full bg-white flex justify-center  p-2">
                <div className="w-full">
                  <div className={`text-gray-500 ${mobile1 ? 'text-[15px]' : ''}`}>
                    <div className="">
                      <span>Montant</span> : {comande.montants}
                    </div>
                    <div className="">
                      <span>Compte</span> : {comande.Compte}
                    </div>
                    <div className="">
                      <span>Date</span> :Le{" "}
                      {new Date(comande.updatedAt).toLocaleDateString("fr-FR")}
                    </div>
                    <div className="">
                      <span>Status</span> : {comande.status}
                    </div>
                  </div>
                  <div className="mt-4">
                    <h3 className={` ${mobile1 ? 'text-[18px]' : 'text-[25px]'}  underline text-blue-500`}>Votre demande</h3>
                    <p className={`text-gray-500 ${mobile1 ? 'text-[15px]' : ''}`}>{comande.Description}</p>
                  </div>

                  {comande.CommandesFiles && comande.CommandesFiles.length > 0 && (
                    <div className="mt-4">
                      <h3 className={`  underline text-blue-500  ${mobile1 ? 'text-[18px]' : 'text-[25px]'} `}>Capture d'écran</h3>
                      <div className={`text-gray-500 flex  ${mobile1 ? 'text-[15px]' : ''} flex-wrap`}>
                        {comande.CommandesFiles.map((command, index) => {
                          return (
                            <div key={index} className={`border ${mobile ? 'flex-auto w-full h-max' : 'w-[14em] h-[14em]'}  border-blue-800 rounded-2xl relative overflow-hidden  m-2`}>
                              {loadingImages[index] ? (
                                <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                              ) : (
                                <LoadingImage
                                  onLoad={() => handleImageLoad(index)}
                                  src={` https://btrproject.burundientempsreel.com/uploads/photoscommande/${command.Files}`}
                                  alt=""
                                  className="w-full h-full object-contain"
                                />)}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="mt-4 w-full">
              {comande.Comandeannuler && (
                <>
                  <h2 className="mot2 border-b-2 border-double border-blue-500 italic text-[25px] w-max">
                    {comande.Comandeannuler.titre}
                  </h2>
                  <div className="text-gray-500 mt-2">
                    {comande.Comandeannuler.motif}
                  </div>
                </>
              )}

              <div className="flex justify-end ">
                <Link
                  to="/User/Echange/Commande"
                  className="m-2 bg-blue-600 px-2 py-1 rounded text-white"
                >
                  Retour
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  );
}

export default DetailCommandeUserEchange;
