import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Empty from "../Empty/Empty";
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
function Myorders() {


  const [listoffOrders, setListOffOrders] = useState([]);
  const [element, setElement] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [LoadingPagePhotos, SetLoadingPagePhotos] = useState(true);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
  };
  const firstEntry = (currentPage - 1) * itemsPerPage + 1;
  const lastEntry = Math.min(currentPage * itemsPerPage, element);
  const id = localStorage.getItem('userid');
  useEffect(() => {
    axios
      .get(` https://btrproject.burundientempsreel.com/market/user/myorders/${id}?page=${currentPage}&size=${itemsPerPage}`, {
        headers: {
          accessToken: localStorage.getItem("t"),
        }
      })
      .then((response) => {
        setElement(response.data.totalItems);
        setListOffOrders(response.data.orders);
        setTotalPages(response.data.totalPages);
        SetLoadingPagePhotos(false)
      }).catch((error) => {
        console.log(error.message);
        console.log(error.response);
      })
  }, [currentPage, itemsPerPage]);
  console.log(listoffOrders)
  return (


    <>
      {LoadingPagePhotos && <SpinnerDemarage />}
      <div class="bg-white border-t text-gray-700 border-b sm:border-l sm:border-r sm:rounded-xl shadow mb-6">
        <div class=" mx-auto px-4 flex justify-center items-center sm:px-8">

          {listoffOrders.length < 0 ? (
            <div class="py-8">
              <div>
                <h2 class="text-2xl font-serif  text-blue-500 leading-tight">
                  Mes commandes
                </h2>
              </div>
              <div class="my-2 flex flex-row ">
                <div class="flex flex-row mb-1 sm:mb-0">
                  <div className="relative">
                    <select className="h-full rounded-l-2xl border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                    >
                      <option>5</option>
                      <option>10</option>
                      <option>20</option>
                    </select>

                  </div>
                </div>
              </div>
              <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                <div class="inline-block min-w-full shadow rounded-lg overflow-hidden w-[60em]">
                  <table class="min-w-full leading-normal">
                    <thead>
                      <tr className=" bg-blue-600 text-white">
                        <th class="px-5 py-3 border-r-2 border-white  text-left  font-serif">
                          Numéro de commande
                        </th>
                        <th class="px-5 py-3 border-r-2 border-white  text-left  font-serif">
                          Montant payé
                        </th>
                        <th class="px-5 py-3 border-r-2 border-white  text-left  font-serif">
                          Statut
                        </th>
                        <th class="px-5 py-3 border-r-2 border-white  text-left  font-serif">
                          Date
                        </th>

                        <th class="px-5 py-3 border-r-2 border-gray-200  text-left text-xs font-serif">
                          Action
                        </th>
                      </tr>
                    </thead>



                    <tbody>
                      {listoffOrders && (listoffOrders.map((value, key) => {
                        return (
                          <tr>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {value.id}
                              </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {value.prixtotal}</p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {value.status === "pending" && (
                                  <span class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-5 py-2  dark:bg-gray-700 dark:text-blue-300 rounded-2xl border border-blue-300">En attente</span>
                                )}
                                {value.status === "payed" && (
                                  <span class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-5 py-2  dark:bg-gray-700 dark:text-blue-300 rounded-2xl border border-blue-300">Payé</span>
                                )}
                                {value.status === "shipping" && (
                                  <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-5 py-2  dark:bg-gray-400 dark:text-green-800 rounded-2xl border border-green-400">
                                    Expédition</span>
                                )}
                                {value.status === "completed" && (
                                  <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-5 py-2  dark:bg-gray-700 dark:text-green-400 rounded-2xl border border-green-400">
                                    Complété</span>
                                )}
                                {value.status === "refused" && (
                                  <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-5 py-2  dark:bg-gray-700 rounded-2xl dark:text-red-400 border border-red-400">refused</span>
                                )}



                              </p>
                            </td>

                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">

                                <tr >
                                  Le  {new Date(value.createdAt).toLocaleDateString('fr-FR') + ' ' + new Date(value.createdAt).toLocaleTimeString('fr-FR')}
                                </tr>

                              </p>
                            </td>


                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <span class="relative inline-block  py-1 font-serif text-green-900 leading-tight">
                                <span
                                  aria-hidden
                                  class="absolute inset-0  opacity-50 rounded-full"
                                ><Link to={`/Visit/Orders/${value.id}`} class="px-5 py-2 border-olive-500 border text-olive-500 rounded-2xl transition duration-300 hover:bg-blue-500 hover:text-white focus:outline-none">
                                    Details
                                  </Link>
                                </span>
                              </span>
                            </td>
                          </tr>
                        )
                      }))}
                    </tbody>
                  </table>
                  <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                    <span className="text-xs xs:text-sm text-gray-900">
                      Affichage de {firstEntry} à {lastEntry} sur {element} commandes
                    </span>

                    <div className="inline-flex mt-2 xs:mt-0">
                      <button
                        className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-serif py-2 px-4 rounded-l"
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        Prev
                      </button>
                      <button
                        className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-serif py-2 px-4 rounded-r"
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        Next
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          ) : (
            <div className="w-[20em] h-[20em]">
              <Empty />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Myorders
