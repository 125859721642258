import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import SpinnerDemarage from '../../../../SpinnerDemarage/SpinnerDemarage'
function DetaillDemmandeHistoryEchangeAdmin() {
  const { id } = useParams()
  const [clickSlideHidden, setclickSlideHidden] = useState(false)
  const [loading, SetLaoding] = useState(true)
  useEffect(() => {
    const windowClicked = () => {

      setclickSlideHidden(false);
    };

    window.addEventListener("click", windowClicked);

    return () => {
      window.removeEventListener("click", windowClicked);
    };
  }, []);
  const [comande, setCommade] = useState({});
  useEffect(() => {
    axios.get(` https://btrproject.burundientempsreel.com/Commande/history/${id}`).then((response) => {
      setCommade(response.data);
      ;
      setTimeout(() => {
        SetLaoding(false)
      }, 1000);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response.data);
      }

    });
  }, [id]);

  const [count, setCount] = useState(0);
  useEffect(() => {
    axios.get(' https://btrproject.burundientempsreel.com/commande/countstandbuy').then((response) => {
      ;
      setCount(response.data)
    })
  })
  return (
    <div className='min-h-[90vh]'>
      {loading && <SpinnerDemarage />}
      {comande.montants && (
        <div className='w-[90%] relative top-2 left-2 bg-white rounded-2xl  border border-blue-700 flex justify-center  p-2'>
          <div className="p-2">
            <h2 className='text-[25px] text-blue-600'>
              Detail de la  commande </h2>
            <div className="text-gray-500">
              <div className=""><span>Montant</span> : {comande.montants}</div>
              <div className=""><span>Compte</span> : {comande.Compte}</div>
              <div className=""><span>Date</span> : Le {new Date(comande.updatedAt).toLocaleDateString('fr-FR')}</div>
              <div className=""><span>Status</span> : {comande.status}</div>
            </div>
            <div className="mt-4">
              <h3 className='text-[25px]  underline text-blue-500'>La demande du client</h3>
              <p className='text-gray-500'>
                {comande.Description}
              </p>
            </div>


            {comande.CommandesFiles && comande.CommandesFiles.length > 0 && (
              <div className="mt-4">
                <h3 className='text-[25px]  underline text-blue'>Capture d'écran</h3>
                <div className='text-gray-500  flex flex-wrap'>
                  {comande.CommandesFiles.map((command) => {
                    return (
                      <div className="rounded overflow-hidden w-[14em] h-[14em] m-2">
                        <img draggable='false' src={`https://btrproject.burundientempsreel.com/uploads/photoscommande/${command.Files}`} alt="" className='w-full h-full object-cover' />
                      </div>
                    )
                  })}
                </div>
              </div>
            )}

            {comande.echangeur && (
              <>
                <h2 className='text-[25px]'>Client</h2>
                <div className="text-gray-500">
                  <div className=""><span>NOm</span> : {comande.echangeur.nom}</div>
                  <div className=""><span>Prenom</span> : {comande.echangeur.prenom}</div>
                  <div className=""><span>Tel</span> : {comande.echangeur.tel}</div>
                  <div className=""><span>Email</span> : {comande.echangeur.email}</div>
                  <div className=""><span>Code agent</span> : {comande.echangeur.codeagent}</div>
                  <div className=""><span>compte principal</span> : {comande.echangeur.banck}</div>
                </div>
              </>
            )}



            <div className="mt-4 w-full">
              {comande.Comandeannuler && (
                <>
                  <h2 className='text-gray-700 border-b-2 border-double border-blue-500 italic text-[25px] w-max'>{comande.Comandeannuler.titre}</h2>
                  <div className="text-gray-500 mt-2">
                    {comande.Comandeannuler.motif}
                  </div>
                </>
              )}




              <div className="flex justify-end ">
                <Link to="/Admin/History" className='m-2 bg-green-600 px-2 py-1 rounded text-white'>Retourner</Link>
              </div>
            </div>

          </div>
        </div>
      )}




    </div>
  )
}

export default DetaillDemmandeHistoryEchangeAdmin
