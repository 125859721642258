import React from 'react'
import DemmandeHistoryEchangeAdmin from './DemmandeHistoryEchangeAdmin'
function DemmandeHistoryEchangeAdminComponent({ getHistoryLong }) {
  return (
    <>

      <DemmandeHistoryEchangeAdmin getHistoryLong={getHistoryLong} />
    </>
  )
}

export default DemmandeHistoryEchangeAdminComponent
