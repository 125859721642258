import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
function DetailPoductor() {
    const { id } = useParams();
    const [product, setProduct] = useState({});
    const [photo, setPhoto] = useState([]);
    const history = useNavigate();

    useEffect(() => {
        axios.get(` https://btrproject.burundientempsreel.com/market/seller/getonproduct/${id}`).then((response) => {

            setProduct(response.data);
            setPhoto(response.data.produitfiles);
        }, []).catch((error) => {
            console.error(error.message);
            if (error.response) {
                console.error(error.response.data);
            }
        });
    }, [id]);
    const handleDelete = async (id) => {
        try {
            await axios.delete(` https://btrproject.burundientempsreel.com/market/seller/deleteproduct/${id}`);
            history('/Admin/StandBuy');
            toast.success("l'annulation du produit est faite avec succes");
        } catch (error) {
            toast.error("Une erreur s'est produite lors de l'annulation du produit");

        }
    };
    const handleValidate = async (id) => {
        try {
            await axios.put(` https://btrproject.burundientempsreel.com/market/admin/validateproduct/${id}`);
            history('/Admin/StandBuy');
            toast.success('la validation du produit est faite avec succes');
        } catch (error) {
            toast.error("Une erreur s'est produite lors de la validation du produit");
        }
    };
    return (
        <>
            <div

                class="container grid grid-cols-2 gap-6 mt-4 pb-10 pt-10 px-5 rounded-2xl bg-gray-100"
            >
                <div>
                    <div class="">
                        <img draggable='false'
                            src={` https://btrproject.burundientempsreel.com/uploads/market/${product.profil}`}
                            alt="product 1"
                            class="object-cover h-full w-full rounded-2xl"
                        />
                    </div>
                    <div class="grid grid-cols-5 gap-4 mt-4">
                        {photo.map((photo) => {
                            return (
                                <img draggable='false'
                                    src={` https://btrproject.burundientempsreel.com/uploads/market/${photo.file}`}
                                    alt=""
                                    class="w-full rounded-md cursor-pointer border"
                                />
                            )
                        })}

                    </div>
                </div>

                <div>
                    <h4 class="font-medium text-3xl mb-2">{product.titre}</h4>

                    <div class="espace-y-2">
                        <p class="text-gray-800 font-semibold space-x-2">
                            <span>Disponibilité : </span>
                            <span class="text-olive-500">
                                {product.Quantite} articles en stock
                            </span>
                        </p>

                        <p class="espace-x-2">
                            <span class="text-gray-800 font-semibold">Catégorie : </span>
                            <span class="text-gray-600">{product.categorie}</span>
                        </p>
                    </div>
                    <div class="flex items-baseline mb-1 space-x-2 font-roboto mt-4">
                        <p class="text-xl text-primary font-semibold">
                            {product.prix} Fbu
                        </p>
                        <p class="text-base text-gray-400 line-through">
                            {product.prix + (product.prix * 20 / 100)} Fbu
                        </p>
                    </div>

                    <p class="mt-4 text-gray-600">{product.description}</p>
                </div>


            </div>
            <div className="mt-2 flex w-full justify-end">
                <button onClick={() => handleValidate(id)} className="transition-all mr-4  bg-blue-400 hover:bg-blue-700 text-white px-7 py-1 rounded">Valid</button>
                <button onClick={() => handleDelete(id)} className="transition-all  bg-red-500 hover:bg-red-700 text-white px-7 py-1 rounded">Cancel</button>
            </div>

        </>
    );
}

export default DetailPoductor;

