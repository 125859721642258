/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react'
import { data } from './data';
function ActionJours() {
    const [isList, SetiseList] = useState(true)
    const [isGraphy, SetGraphy] = useState(true)
    const [active, SetActive] = useState('')
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');

            chartInstanceRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: data.Benefice.map(item => item.nomProduits),
                    datasets: [
                        {
                            label: 'Benefice',
                            data: data.Benefice.map(item => item.QuantiteProduits),
                            borderColor: 'green',
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: 'Perte',
                            data: data.Perte.map(item => item.QuantiteProduits),
                            borderColor: 'red',
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: 'Dette',
                            data: data.Dette.map(item => item.QuantiteProduits),
                            borderColor: 'blue',
                            borderWidth: 1,
                            fill: false,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },

                },
            });
        }

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, []);
    return (
        <>

            <div className='bg-gray-200 sticky top-0 flex justify-between px-10 items-center border-blue-300 border-b paddings'>
                <select className='cursor-pointer border border-blue-500 px-4 py-2 rounded-2xl'>
                    <option>Aujourd'hui</option>
                    <option>Hier</option>
                    <option>Semaine</option>
                    <option>Mois</option>
                    <option>Annéé</option>
                </select>
                <div className="w-max overflow-hidden"><div className=" text-blue-600  marquee2 text-center">   Affichage de vos produits journalier du 12/02/2024</div></div>
                <div className="flex justify-around  items-center  w-max px-2 py-1 m-1">
                    <button onClick={() => { SetiseList(true); SetGraphy(true); SetActive('') }} className={` ${isList ? 'bg-blue-600 hover:bg-blue-600' : ''} ${active == '' ? 'bg-blue-700 text-white' : ''} hover:bg-blue-400 transition  px-4 py-1 m-[1px] rounded`}>Listes</button>
                    <button onClick={() => { SetiseList(false); SetGraphy(false); SetActive('Graphique') }} className={`${isGraphy ? '' : 'bg-blue-600 hover:bg-blue-600'} ${active == 'Graphique' ? 'bg-blue-700 text-white' : ''}  hover:bg-blue-400 transition  px-4 py-1 m-[1px] rounded`}>Graphique</button>
                </div>
            </div>
            <div class={` ${isList ? 'flex' : 'hidden'} flex flex-col items-end`}>
                <div className="pr-10  p-1 m-1 rounded search ">
                    <p className='text-gray-500 font-serif'>Rechercher par votre choix</p>
                    <div className="search py-1 w-full search ">
                        <form className='flex  w-[100%] '>
                            <input type="text" className='p-1 search border border-blue-700 rounded transition-all outline-none focus:border-2 focus:border-blue-500' id="" placeholder='Rechercher...' />
                            <label htmlFor='search' className='transition-all bg-blue-400 text-white hover:bg-blue-600 p-2 ml-1 cursor-pointer rounded'><i className='bi bi-search'></i></label>
                        </form>
                    </div>
                </div>
                <div className="w-full overflow-auto mt-4">
                    <div className="w-full overflow-auto mt-4">
                        <div className="w-[98%] overflow-hidden widthTabAdmiVentes mr-4">

                            <table className="w-full mx-2" cellspacing="0 " border="0 ">
                                <thead class="h-11 z-1 select-none bg-blue-500 border-b-2 border-blue-400 ">
                                    <tr className=''>
                                        <th colspan="8" className='border-b-2 border-blue-400 text-[white] w-full h-12'>Ventes</th>

                                    </tr>
                                    <tr>
                                        <th className='border-r border-gray-300 text-[white] h-10 w-[7em]'>Nom produit</th>
                                        <th className='border-r border-gray-300 text-[white] h-10 w-[3px]'>Quantites</th>
                                        <th className='border-r border-gray-300 text-[white] h-10 w-[3px]'>Date & Heure</th>
                                    </tr>
                                </thead>
                                <tbody class="text-gray-5000">
                                    <tr className='border-r  border-b border-gray-300 h-[35px] text-gray-600 '><td className=' border-r border-gray-300'>Scie</td><td className=' border-r border-gray-300 w-[3px]'>200 200</td><td className=' border-r border-gray-300'>20h00</td></tr>

                                </tbody>
                            </table>

                        </div>

                        <div className="w-[98%] overflow-hidden widthTabAdmiVentes mt-20 mr-4">

                            <table className="w-full mx-2" cellspacing="0 " border="0 ">
                                <thead class="h-11 z-1 select-none bg-blue-500 border-b-2 border-blue-400 ">
                                    <tr className=''>
                                        <th colspan="5" className='border-b-2 border-blue-400 text-[white] w-full h-12'>Depenses</th>

                                    </tr>
                                    <tr>
                                        <th className='border-r border-gray-300 text-[white] h-10 w-[10em]'>Montant</th>
                                        <th className='border-r border-gray-300 text-[white] h-10 w-[10em]'>Date & Heure</th>
                                        <th className='border-r border-gray-300 text-[white] h-10'>Motif</th>
                                    </tr>
                                </thead>
                                <tbody class="text-gray-5000">
                                    <tr className='border-r  border-b border-gray-300 h-[35px] text-gray-600 '><td className=' border-r border-gray-300 p-2'>39 992</td><td className=' border-r border-gray-300 p-2'>3h00</td><td className=' border-r border-gray-300 p-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Id quidem quas itaque unde vero nesciunt neque fugit, odio architecto? Dolor dicta nobis corporis architecto omnis, nostrum facere odit voluptas voluptatum?</td></tr>
                                    <tr className='border-r  border-b border-gray-300 h-[35px] text-gray-600 '><td className=' border-r border-gray-300 p-2'>39 992</td><td className=' border-r border-gray-300 p-2'>7h00</td><td className=' border-r border-gray-300 p-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Id quidem quas itaque unde vero nesciunt neque fugit, odio architecto? Dolor dicta nobis corporis architecto omnis, nostrum facere odit voluptas voluptatum?</td></tr>
                                    <tr className='border-r  border-b border-gray-300 h-[35px] text-gray-600 '><td className=' border-r border-gray-300 p-2'>39 992</td><td className=' border-r border-gray-300 p-2'>10h30</td><td className=' border-r border-gray-300 p-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Id quidem quas itaque unde vero nesciunt neque fugit, odio architecto? Dolor dicta nobis corporis architecto omnis, nostrum facere odit voluptas voluptatum?</td></tr>
                                    <tr className='border-r  border-b border-gray-300 h-[35px] text-gray-600 '><td className=' border-r border-gray-300 p-2'>39 992</td><td className=' border-r border-gray-300 p-2'>11h45</td><td className=' border-r border-gray-300 p-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Id quidem quas itaque unde vero nesciunt neque fugit, odio architecto? Dolor dicta nobis corporis architecto omnis, nostrum facere odit voluptas voluptatum?</td></tr>
                                    <tr className='border-r  border-b border-gray-300 h-[35px] text-gray-600 '><td className=' border-r border-gray-300 p-2'>39 992</td><td className=' border-r border-gray-300 p-2'>05h55</td><td className=' border-r border-gray-300 p-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Id quidem quas itaque unde vero nesciunt neque fugit, odio architecto? Dolor dicta nobis corporis architecto omnis, nostrum facere odit voluptas voluptatum?</td></tr>
                                    <tr className='border-r  border-b border-gray-300 h-[35px] text-gray-600 '><td className=' border-r border-gray-300 p-2'>39 992</td><td className=' border-r border-gray-300 p-2'>318h05</td><td className=' border-r border-gray-300 p-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Id quidem quas itaque unde vero nesciunt neque fugit, odio architecto? Dolor dicta nobis corporis architecto omnis, nostrum facere odit voluptas voluptatum?</td></tr>
                                </tbody>
                            </table>

                        </div>


                        <div className="w-[98%] overflow-hidden widthTabAdmiVentes mt-20 mr-4">

                            <table className="w-full mx-2" cellspacing="0 " border="0 ">
                                <thead class="h-11 z-1 select-none bg-blue-500 border-b-2 border-blue-400 ">
                                    <tr className=''>
                                        <th colspan="7" className='border-b-2 border-blue-400 text-[white] w-full h-12'>Dettes payés</th>
                                    </tr>
                                    <tr>
                                        <th className='border-r border-gray-300 text-[white] h-10 w-[7em]'>Nom  du client</th>
                                        <th className='border-r border-gray-300 text-[white] h-10 w-[3px]'>Tel</th>
                                        <th className='border-r border-gray-300 text-[white] h-10 w-[3px]'>Email</th>
                                        <th className='border-r border-gray-300 text-[white] h-10 w-[3em]'>Dette</th>
                                        <th className='border-r border-gray-300 text-[white] h-10 w-[3em]'>Pix payés</th>
                                        <th className='border-r border-gray-300 text-[white] h-10 w-[3em]'>Pix Resté</th>
                                    </tr>
                                </thead>
                                <tbody class="text-gray-5000">
                                    <tr className='border-r  border-b border-gray-300 h-[35px] text-gray-600 '><td className=' border-r border-gray-300'>Muma Bebe</td> <td className=' border-r border-gray-300'>68108490</td><td className=' border-r border-gray-300 w-[3px]'>telecomtelesphore@gmail.com</td><td className=' border-r border-gray-300'>78 383</td><td className=' border-r border-gray-300'>39 992</td><td className=' border-r border-gray-300'>39 992</td></tr>
                                </tbody>
                            </table>

                        </div>

                        <div className="w-full flex justify-center items-center my-10">
                            <div className="bg-white shadow-2xl p-2 rounded" >
                                <h2 className='w-full bg-blue-400 p-1 text-white text-center font-bold'> Synthèse</h2>
                                <div className=" m-2 p-2 rounded">
                                    <p>Montant vendus: <b>893 3939</b> </p>
                                    <p>Montant dettes: <b>39 200</b> </p>
                                    <p>Montant payes: <b>682 293</b></p>
                                    <p>Montant depense: <b>19 000</b></p>
                                    <p>Montant bonus: <b className='text-green-600'>19 000</b></p>
                                    <p>Montant perte: <b className='text-red-600'>00</b></p>
                                    <p>Montant en caisse: <b>38393 39392</b> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* sous forme graphique */}

            <div className={`w-full ${isGraphy ? 'hidden' : 'flex'}   flex-col items-center py-2 px-10 textBod bg-white`}>
                <div className="w-full bg-gray-200 flex justify-center  items-center h-[100vh]">
                    <div className='w-[90%] h-[90vh] p-2 rounded-lg flex justify-center items-center'>
                        <canvas ref={chartRef} />
                    </div>
                </div>
            </div>

        </>
    )
}

export default ActionJours



