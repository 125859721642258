import React, { useState } from 'react'
import Home from './motaceui'
import Qoiburuempell from './Qoiburuempell'
import Service from './Service'
import Partenariat from './Partenariat'
import Equipe from './Equipe'
import QuestionPropose from './QuestionPropose'

function HomeVisitorComponent({ isdivScroll }) {
  const [recupValues, SetrecupValues] = useState()

  const scrollQuestion = (ValuesScrill) => {
    SetrecupValues(ValuesScrill)
  }
  return (
    <div className='bg-white flex items-center flex-col  w-full'>
      <Home />
      <Qoiburuempell isdivScroll={isdivScroll} scrollQuestion={scrollQuestion} />
      <Service />
      <Partenariat />
      <Equipe />
      <QuestionPropose isdivScroll={isdivScroll} recupValues={recupValues} SetrecupValues={SetrecupValues} />

    </div>
  )
}

export default HomeVisitorComponent
