import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-toastify';
import { GridLoader } from 'react-spinners'

import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage';

function Detaillenewseller() {
  const [sellerData, setSellerData] = useState({});
  const history = useNavigate()
  const { id } = useParams();
  const [tropTail, SettropTail] = useState(window.innerWidth < 414.97364448849393)
  const [imageLoading, SetImageLoading] = useState(true)
  const [loading, setLoading] = useState(true)



  useEffect(() => {

    const hiddenresize = () => {

      SettropTail(window.innerWidth < 414.97364448849393)

    }

    window.addEventListener('resize', hiddenresize);



    return () => {

      window.removeEventListener('resize', hiddenresize);


    }

  }, [])


  useEffect(() => {
    // Fonction pour récupérer les données du vendeur en utilisant Axios
    const fetchSellerData = async () => {
      try {
        const response = await axios.get(` https://btrproject.burundientempsreel.com/market/admin/getoneseller/${id}`);
        setSellerData(response.data);
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      } catch (error) {
        if (error.response) {
          console.error(error.response.data);
        }
      }
    };

    fetchSellerData();
  }, [id]);
  const handleActivate = () => {
    axios.put(` https://btrproject.burundientempsreel.com/market/admin/activateseller/${id}`, {
      status: 1
    })
      .then((response) => {
        toast.success("Le compte vendeur a été activé avec succès !");

        history('/Admin/Market');
      })
      .catch((error) => {
        toast.error("Une erreur s'est produite lors de l'activation du vendeur.");
        if (error.response) {
          console.error(error.response.data);
        }
      });
  };
  const handleCancel = () => {
    axios.delete(` https://btrproject.burundientempsreel.com/market/seller/cancel/${id}`)
      .then((response) => {
        toast.success("Le compte vendeur a été annulé avec succès !");
        history('/Admin/Market');
      })
      .catch((error) => {
        toast.error("Une erreur s'est produite lors de l'annulation du vendeur.");
        if (error.response) {
          console.error(error.response.data);
        }
      });
  };
  return (
    <div className="p-2 bg-white">
      {loading && <SpinnerDemarage />}
      <div className="">
        <div className="">
          <div className="">
            <div className="md:flex no-wrap md:-mx-2">
              { /* Left Side */}

              {sellerData.address && (
                <>
                  <div className="w-full md:w-4/12 md:mx-2">
                    {sellerData.user && (
                      <div className="bg-gray-100 rounded-2xl p-5">
                        <div className="h-[5em] w-[5em] transition-all  relative overflow-hidden ml-3 rounded logo">
                          {imageLoading && <div className='w-full h-full flex bg-gray-200 justify-center items-center'><GridLoader size='15' color='blue' /></div>}
                          <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                              SetImageLoading(false)
                            }, 1000)
                          }}
                            src={`http://localhos:8080/uploads/photosuser/${sellerData.user.photo}`} alt="dfrtete" />
                        </div>
                        <h1 className="text-blue-500 text-center font-bold text-xl leading-8 my-1">
                          {sellerData.user.nom}{" "} {sellerData.user.prenom}
                        </h1>
                        <h3 className="text-gray-600 text-center font-lg text-semibold leading-6">
                          {sellerData.user.address}
                        </h3>

                        <ul className="bg-gray-200  text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                          <li className="flex items-center py-3">
                            <span>Status</span>
                            <span className="ml-auto"><span className="bg-blue-500 py-1 px-2 rounded text-white text-sm">Pending</span></span>
                          </li>
                          <li className="flex items-center py-3">
                            <span>
                              Demande à être vendeur depuis
                            </span>
                            <span className="ml-auto">{new Date(sellerData.createdAt).toLocaleDateString('en-US') + ' ' + new Date(sellerData.createdAt).toLocaleTimeString('en-US')}</span>
                          </li>
                        </ul>
                      </div>
                    )}


                    <div className="my-4" />
                  </div>

                  <div className="w-full md:w-8/12 mx-2 ">
                    {sellerData && (
                      <div className="bg-gray-100 p-3 shadow-sm rounded-2xl">
                        <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                          <span className="text-blue-500">
                            <svg className="h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>
                          </span>
                          <h2 className="text-xl text-blue-500 tracking-wide leading-8 my-1">
                            À propos du vendeur
                          </h2>
                        </div>
                        <div className="text-gray-700">
                          <div className="grid text-sm">
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">
                                Nom
                              </div>
                              <div className="px-4 py-2">{sellerData.user.nom}</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">
                                Prénom
                              </div>
                              <div className="px-4 py-2"> {sellerData.user.prenom}</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">
                                Contact No.
                              </div>
                              <div className="px-4 py-2">{sellerData.user.tel}</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">
                                Addresswe Actuel
                              </div>
                              <div className="px-4 py-2">
                                {sellerData.user.address}
                              </div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">
                                Email.
                              </div>
                              <div className="px-4 py-2">
                                <a className="text-blue-800 hover:underline" href={`mailto:${sellerData.user.email}`}>
                                  {sellerData.user.email}
                                </a>

                              </div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">
                                Mode de paiement
                              </div>
                              <div className="px-4 py-2">{sellerData.paymentmethod}</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">

                                N° carte nationale d'identité
                              </div>
                              <div className="px-4 py-2">{sellerData.CNI}</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">

                                Adresse Shop
                              </div>
                              <div className="px-4 py-2">
                                {sellerData.address}
                              </div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">

                                Nom de Shop
                              </div>
                              <div className="px-4 py-2">{sellerData.nomshop}</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">

                                NIF
                              </div>
                              <div className="px-4 py-2">{sellerData.NIF}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}


                  </div></>
              )}

            </div>
            <div className='w-full md:w-auto  mx-2  no-wrap 
            bg-gray-100 rounded-2xl p-5 my-5'>
              <h1 class="text-blue-500 text-left font-bold text-xl leading-8 my-1">Cnio & Cnii Pictures</h1>
              {sellerData && (
                <>
                  <div className='mt-5 flex justify-center flex-wrap  w-full '>

                    <div className="h-[25em]  w-[25em] p-2  border-2 border-blue-500  rounded-2xl">
                      <img draggable='false' className="object-contain m-1  h-full w-full rounded-2xl" src={` https://btrproject.burundientempsreel.com/uploads/market/${sellerData.cnii}`} alt="" />

                    </div>




                    <div className="h-[25em] m-1 p-2 w-[25em] border-2 border-blue-500 rounded-2xl">
                      <img draggable='false' className="object-contain  h-full w-full rounded-2xl" src={` https://btrproject.burundientempsreel.com/uploads/market/${sellerData.cnio}`} alt="" />
                    </div>




                    <div className="h-[25em] m-1 p-2 w-[25em] border-2 border-blue-500 rounded-2xl">
                      <img draggable='false' className="object-contain  h-full w-full rounded-2xl" src={` https://btrproject.burundientempsreel.com/uploads/market/${sellerData.logo}`} alt="" />
                    </div>

                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end w-full">
        <div className="px-4 py-2 font-semibold">
          <button onClick={handleActivate} class="bg-blue-500 text-white px-5 py-2 rounded-xl hover:scale-105 duration-300 hover:bg-[#206ab1] font-medium" type="submit">Activate</button>
        </div>
        <div className="px-4 py-2 font-semibold">
          <button onClick={handleCancel} class="bg-red-500  px-5 py-2 text-white  rounded-xl hover:scale-15 duration-300 hover:bg-[#105af1] font-medium" type="submit">cancel</button>
        </div>
      </div>
    </div>

  )
}

export default Detaillenewseller
