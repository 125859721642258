import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import SpinnerDemarage from '../../../../SpinnerDemarage/SpinnerDemarage'

function DemmandeHistoryEchangeAdmin({ getHistoryLong }) {
  const { pathname } = useLocation()
  const [loading, SetLoadinh] = useState(true)

  const [clickSlideHidden, setclickSlideHidden] = useState(false)


  useEffect(() => {
    const windowClicked = () => {

      setclickSlideHidden(false)
    }

    window.addEventListener('click', windowClicked)

    return () => {
      window.removeEventListener('click', windowClicked)
    }
  }, [])

  const [data, setData] = useState([])
  // État pour stocker le numéro de page actuel
  const [currentPage, setCurrentPage] = useState(1)
  // État pour stocker le nombre total de pages
  const [totalPages, setTotalPages] = useState(1)

  const [itemsPerPage, setItemsPerPage] = useState(10)
  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value)
    setItemsPerPage(selectedItemsPerPage)
  }

  useEffect(() => {
    axios
      .get(
        ` https://btrproject.burundientempsreel.com/commande/history?page=${currentPage}&size=${itemsPerPage}`,
      )
      .then((response) => {
        // Extraire les données de la réponse
        const { Commandes, totalPages } = response.data
        setData(Commandes)
        console.log(Commandes)
        setTotalPages(totalPages)
        setTimeout(() => {
          SetLoadinh(false)
        }, 1000);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      })
  }, [currentPage, itemsPerPage])

  // Fonction pour passer à la page suivante
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  // Fonction pour passer à la page précédente
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  // Fonction pour changer de page lors du clic sur un numéro de page spécifique
  const goToPage = (page) => {
    setCurrentPage(page)
  }

  // Générer la liste de numérotation des pages
  const generatePageNumbers = () => {
    const pageNumbers = []
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={
            currentPage === i
              ? 'text-sm font-medium leading-none cursor-pointer bg-blue-600 text-white w-[30px] h-[30px] pb-[20px] rounded-full dark:text-gray-200  dark:hover:text-yellow-400 border-t border-transparent hover:border-red-500 pt-3 mr-4 px-2'
              : 'text-sm font-medium  leading-none cursor-pointer w-[30px] h-[30px] pb-[20px] bg-gray-400 transition-all  text-white hover:bg-gray-600  rounded-full dark:text-gray-200  dark:hover:text-yellow-400 border-t border-transparent hover:border-yellow-400 pt-3 mr-4 px-2'
          }
        >
          {i}
        </button>,
      )
    }
    return pageNumbers
  }


  const [count, setCount] = useState(0);
  useEffect(() => {
    axios.get(' https://btrproject.burundientempsreel.com/commande/countstandbuy').then((response) => {
      ;
      setCount(response.data)
    })
  })



  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);

  };


  const filterData = (data, searchValue) => {
    if (!searchValue.trim()) {
      return data;
    }

    return data.filter((item) => {
      if (item.montants.toString().toLowerCase().includes(searchValue.toLowerCase())) {
        return true;
      }


      if (item.Compte.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }

      if (item.Description.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }
      const formattedDate = new Date(item.createdAt).toLocaleDateString('en-US') + ' ' + new Date(item.createdAt).toLocaleTimeString('en-US');
      if (formattedDate.toString().includes(searchValue)) {
        return true;
      }

      console.log(formattedDate)

      if (item.status.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }

      return false;
    });
  };

  const filteredData = filterData(data, searchValue)

  getHistoryLong(filteredData.length)

  return (
    <div className='min-h-[90vh]'>


      {/* NavBars d'ecran de petit taill */}

      {loading && <SpinnerDemarage />}

      <div class="flex flex-col items-end">
        <div className="pr-1 items-center    justify-center h-10   p-1 m-1 flex rounded  ">
          <div className="flex  h-10 ">
            <div className="relative">
              <select
                className=" h-full w-[3em] pl-1 cursor-pointer rounded-l-2xl border block   bg-white border-gray-400    focus:outline-none focus:bg-white focus:border-gray-500"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option>5</option>
                <option>10</option>
                <option>20</option>
              </select>

            </div>
          </div>
          <div className=" py-1 w-full  ">
            <form className='flex  w-[100%] '>
              <input type="text"
                value={searchValue}
                onChange={handleSearchChange}
                className='p-1  border border-blue-700 rounded-e transition-all outline-none focus:border-2 focus:border-blue-500' id="" placeholder='Rechercher...' />
              <label htmlFor='search' className='transition-all bg-blue-400 text-white hover:bg-blue-600 p-2 ml-1 cursor-pointer rounded'><i className='bi bi-search'></i></label>
            </form>
          </div>
        </div>


        <div className="w-full overflow-auto mt-4">
          <div className="w-[99%] overflow-hidden widthTab mr-4">
            <table className="w-full rounded-t-2xl  overflow-hidden" cellspacing="0 " border="0 ">
              <thead class="h-11 select-none bg-blue-500 border-b-2  border-blue-400 sticky top-0">
                <tr>
                  <th className='border-r border-gray-300 text-[white]'>Montant</th>
                  <th className='border-r border-gray-300 text-[white]'>Compte</th>
                  <th className='border-r border-gray-300 text-[white]'>Date</th>
                  <th className='border-r border-gray-300 text-[white] w-[25em]'>Déscription</th>
                  <th className='border-r border-gray-300 text-[white]'>Status</th>
                  <th className='border-r border-gray-300 text-[white]'>Action</th>
                </tr>
              </thead>
              <tbody class="text-gray-5000">
                {filteredData.length > 0 ? (
                  <>
                    {
                      filteredData.map((item, index) => {
                        return (
                          <tr className="border-r border-b border-gray-300 h-[35px] text-gray-600" key={index}>
                            <td className="border-r pl-3 border-gray-300">{item.montants}</td>
                            <td className="border-r pl-3 border-gray-300">{item.Compte}</td>
                            <td className="border-r pl-3 border-gray-300">{new Date(item.createdAt).toLocaleDateString('fr-FR')}</td>
                            <td className="border-r pl-3 border-gray-300">{item.Description.length > 40 ? item.Description.slice(0, 40) + '...' : item.Description}</td>
                            <td className="w-[7em] p-1 border-r border-gray-300">
                              <div className="border-r border-gray-300 bg-violet-500 text-white pl-2 h-full text-left text-[15px] w-[6.5em] select-none">{item.status}</div>
                            </td>
                            <td>
                              <Link to={`/Admin/History/Detail/${item.id}`} className="bg-blue-400 text-white w-full flex pl-2">Détail</Link></td>
                          </tr>
                        );
                      })
                    }
                  </>
                ) : (
                  <>
                    <tr >
                      <td colSpan="8"><div className='h-[75vh] text-gray-600 w-full flex justify-center items-center text-[22px]'>
                        Aucune correspondanct à <div className='ml-1 font-serif font-bold'> {searchValue}</div>
                      </div></td>
                    </tr>
                  </>)}
              </tbody>
            </table>
          </div>
        </div>





        <div className="flex items-center  w-full justify-center py-10 lg:px-0 sm:px-6 px-4">
          <div className="lg:w-3/5 w-full flex items-center justify-between border-t border-gray-200 dark:border-gray-700">
            <div className="flex items-center pt-3 mr-4 text-gray-600 dark:text-gray-200 hover:text-yellow-700 cursor-pointer">
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.1665 4H12.8332"
                  stroke="currentColor"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.1665 4L4.49984 7.33333"
                  stroke="currentColor"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.1665 4.00002L4.49984 0.666687"
                  stroke="currentColor"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <button onClick={previousPage} disabled={currentPage === 1} className="text-sm ml-3 text-black  hover:text-yellow-700 cursor-pointer">Previous</button>
            </div>
            <div className="flex mt-3">
              {generatePageNumbers()}

            </div>
            <div className="flex items-center pt-3 ml-4 text-black  hover:text-yellow-700 cursor-pointer">
              <button onClick={nextPage} disabled={currentPage === totalPages} className="text-sm font-medium leading-none mr-3">Next</button>
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.1665 4H12.8332"
                  stroke="currentColor"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.5 7.33333L12.8333 4"
                  stroke="currentColor"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.5 0.666687L12.8333 4.00002"
                  stroke="currentColor"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default DemmandeHistoryEchangeAdmin
