import React from 'react'
import SanteHomeVisitor from './SanteHomeVisitor'
function SanteHomeVisitorComponent() {
  return (
    <SanteHomeVisitor/>
      
  
  )
}

export default SanteHomeVisitorComponent
