import React from 'react'
import DetailleAdminManagent from './DetailleAdminManagent'

export default function DetailleAdminMarketComponent() {
    return (
        <div className='bg-gray-50 fex justify-center'>

            <DetailleAdminManagent />
        </div>
    )
}
