import React, { useEffect } from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';



function CompteMarcheUse({ isdivScroll }) {
    // pour autre champs
    const [profil, SetProfil] = useState("../../image/btr.png")

    const [nom, Setnom] = useState('')
    const [animationClassNom, setAnimationClassNom] = useState('');
    const elemetRefNom = useRef(null)

    const [prenom, Setprenom] = useState('')
    const [animationClassPrenom, setAnimationClassPrenom] = useState('');
    const elemetRefPrenom = useRef(null)

    const [tel, SetTel] = useState('')
    const [animationClassTel, setAnimationClassTel] = useState('');
    const elemetRefTel = useRef(null)

    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const [emailValue, setEmailValue] = useState('telecomtelesphore@gmail.com')
    const [animationClassEmail, setAnimationClassEmail] = useState('');
    const elemetRefEmail = useRef(null)

    const [textarea, setTextarea] = useState('');
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const textaresRef = useRef(null)

    const [autoScrollHeight, setAutoScrollHeight] = useState(50);
    const [valueTextarea, setValueTextarea] = useState('');

    const [adrress, Setadrress] = useState('')
    const [animationClassadrress, setAnimationClassadrress] = useState('');
    const elemetRefadrress = useRef(null)

    const [CNI, setCNI] = useState('')
    const [animationClassCNI, setAnimationClassCNI] = useState('');
    const elemetRefCNI = useRef(null)

    const [PhotoAvantCNI, SetPhotoAvantCNI] = useState("")
    const [animationClassPhotoAvantCNI, setAnimationClassPhotoAvantCNI] = useState('');
    const elemetRefPhotoAvantCNI = useRef(null)

    const [PhotoAprestCNI, SetPhotoAprestCNI] = useState("")
    const [animationClassPhotoAprestCNI, setAnimationClassPhotoAprestCNI] = useState('');
    const elemetRefPhotoAprestCNI = useRef(null)

    const [MethodePaiemen, setMethodePaiemen] = useState('')
    const [animationClassMethodePaiemen, setAnimationClassMethodePaiemen] = useState('');
    const elemetRefMethodePaiemen = useRef(null)


    const [PhotoMagazin, SetPPhotoMagazin] = useState("")
    const [animationClassPhotoMagazin, setAnimationClassPhotoMagazin] = useState('');
    const elemetRefPhotoMagazin = useRef(null)

    const [NomMagazin, setNomMagazin] = useState('')
    const [animationClassNomMagazin, setAnimationClassNomMagazin] = useState('');
    const elemetRefNomMagazin = useRef(null)

    const [NIF, setNIF] = useState('')
    const [animationClassNIF, setAnimationClassNIF] = useState('');
    const elemetRefNIF = useRef(null)

    const [cnii, setcnii] = useState(null);
    const [cnio, setcnio] = useState(null);
    const [logo, setLogo] = useState(null);





    //pour le mot de passe
    const PasswordRegexPassAncien = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
    const [PassValueAncien, SetPassValueAncien] = useState('')
    const [animationClassPassAncien, setAnimationClassPassAncien] = useState('');
    const elemetRefPassAncien = useRef(null)



    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
    const [passwordValue, setPasswordValue] = useState('')
    const [animationClassPassword, setAnimationClassPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const elemetRefPass = useRef(null)

    const [confPass, SetconfPass] = useState('')
    const [animationClassConfPass, setAnimationClassConfPass] = useState('');
    const [ConfpasswordVisible, setConfPasswordVisible] = useState(false);
    const elemetRefConfPass = useRef(null)



    const [modifierBtn, SetmodifierBtn] = useState(false)
    const [EditorPass, SetEditorPass] = useState(false)


    const handleSubmitAutreChamp = (e) => {

        if (nom.trim() === '') {
            toast.warning('Le nom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassNom('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassNom(' ')
            }, 3000)
            elemetRefNom.current.focus()
            e.preventDefault()
            return false
        } else if (prenom.trim() === '') {
            toast.warning('Le  prenom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassPrenom('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassPrenom(' ')
            }, 3000)
            elemetRefPrenom.current.focus()
            e.preventDefault()
            return false
        }
        else if (tel.trim() == "") {
            toast.warning("Le numero de téléphone  est obligatoire !!", {
                autoClose: 2000
            });
            setAnimationClassTel('animate__animated animate__shakeX placeholder-shown:border-blue-700 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassTel(' ')
            }, 3000)
            elemetRefTel.current.focus()
            e.preventDefault()
            return false
        }
        else if (emailValue.trim() == '') {
            toast.warning("L'address email est obligatoire !!", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-blue-500 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            elemetRefEmail.current.focus();
            e.preventDefault()
            return false
        } else if (!emailValue.trim().match(emailRegex)) {
            toast.error("L'address email est incorrect", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            elemetRefEmail.current.focus();
            e.preventDefault()
            return false
        }
        e.preventDefault()
        if (textarea.trim() === '') {
            toast.warning("L'address  est obligatoire !!", {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassTextarea('');
            }, 3000);
            textaresRef.current.focus()
            return;
        }
        else if (CNI.trim() == '') {
            toast.warning("Le numéro national  d'identité est obligatoire !! ", {
                autoClose: 2000,
                position: "top-center"
            });
            setAnimationClassCNI('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassCNI(' ')
            }, 3000)
            elemetRefCNI.current.focus();
            return
        }

        else if (PhotoAvantCNI == "") {
            toast.warning(`Photo face avant du C.N.I est obligatoire`, {
                autoClose: 5000,
                position: "top-center",

            })
            setAnimationClassPhotoAvantCNI('animate__animated animate__shakeX  text-white border-b-2 bg-red-500 border-blue-500')
            setTimeout(() => {
                setAnimationClassPhotoAvantCNI(' ')
            }, 5000)
            return
        }
        else if (PhotoAprestCNI == "") {
            toast.warning(`Photo face arrière du C.N.I est obligatoire`, {
                autoClose: 5000,
                position: "top-center",

            })
            setAnimationClassPhotoAprestCNI('animate__animated animate__shakeX  text-white border-b-2 bg-red-500 border-blue-500')
            setTimeout(() => {
                setAnimationClassPhotoAprestCNI(' ')
            }, 5000)
            return
        }
        else if (MethodePaiemen.trim() == "") {
            toast.warning("Votre methode de paiement est obligatoire !!", {
                autoClose: 2000,
                position: "top-center"
            });
            setAnimationClassMethodePaiemen('animate__animated animate__shakeX placeholder-shown:border-blue-700 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassMethodePaiemen(' ')
            }, 3000)
            elemetRefMethodePaiemen.current.focus()

            return
        }
        else if (PhotoMagazin == "") {
            toast.warning(`Photo du magazin ou logo est obligatoire`, {
                autoClose: 5000,
                position: "top-center",

            })
            setAnimationClassPhotoMagazin('animate__animated animate__shakeX  text-white border-b-2 bg-red-500 border-blue-500')
            setTimeout(() => {
                setAnimationClassPhotoMagazin(' ')
            }, 5000)
            return
        }

        else if (NomMagazin.trim() == "") {
            toast.warning("Le nom du magazin est obligatoire !!", {
                autoClose: 2000,
                position: "top-center"
            });
            setAnimationClassNomMagazin('animate__animated animate__shakeX placeholder-shown:border-blue-700 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassNomMagazin(' ')
            }, 3000)
            elemetRefNomMagazin.current.focus()

            return
        }
        else if (NIF.trim() == "") {
            toast.warning("Le NIF est obligatoire !!", {
                autoClose: 2000,
                position: "top-center"
            });
            setAnimationClassNIF('animate__animated animate__shakeX placeholder-shown:border-blue-700 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassNIF(' ')
            }, 3000)
            elemetRefNIF.current.focus()
            return
        }
    }







    // pour le password
    const handleSubmitPassword = (e) => {
        e.preventDefault()
        if (PassValueAncien.trim() == "") {
            toast.warning("Entre le mot de passe ancien!!", {
                autoClose: 2000
            });
            setAnimationClassPassAncien('animate__animated animate__shakeX placeholder-shown:border-blue-700 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassPassAncien(' ')
            }, 3000)
            elemetRefPassAncien.current.focus()

            return
        } else if (!PassValueAncien.trim().match(PasswordRegexPassAncien)) {

            toast.error(`Le mot de passe doit contenir au moins
                          une lettre minuscule,lettre majuscule,un chiffre et un caractère spécial
                        `, {
                autoClose: 5000,
                position: "top-center",
                className: 'my-toast',

            });
            setAnimationClassPassAncien('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassPassAncien(' ')
            }, 5000)
            elemetRefPassAncien.current.focus()

            return
        }
        else if (!PassValueAncien.length > 9) {

            toast.error(`Le mot de passe doit etre inférieure à 9`, {
                autoClose: 5000,
                position: "top-center",
                className: 'my-toast',

            });
            setAnimationClassPassAncien('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassPassAncien(' ')
            }, 5000)

            elemetRefPassAncien.current.focus()
            e.preventDefault()
            return false
        }



        if (passwordValue.trim() == "") {
            toast.warning("Entre le nouveaux mot de passe", {
                autoClose: 2000
            });
            setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-blue-700 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassPassword(' ')
            }, 3000)
            elemetRefPass.current.focus()
            e.preventDefault()
            return false
        } else if (!passwordValue.trim().match(PasswordRegex)) {

            toast.error(`Le mot de passe doit contenir au moins
                          une lettre minuscule,lettre majuscule,un chiffre et un caractère spécial
                        `, {
                autoClose: 5000,
                position: "top-center",
                className: 'my-toast',

            });
            setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassPassword(' ')
            }, 5000)
            elemetRefPass.current.focus()
            e.preventDefault()
            return false
        }
        else if (!passwordValue.length > 9) {

            toast.error(`Le mot de passe doit etre inférieure à 9`, {
                autoClose: 5000,
                position: "top-center",
                className: 'my-toast',

            });
            setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassPassword(' ')
            }, 5000)
            elemetRefPass.current.focus()
            e.preventDefault()
            return false
        }
        else if (confPass.trim() == '') {
            toast.warning(`confirme le mot de passe saisi`, {
                autoClose: 5000,
                position: "top-center",
                className: 'my-toast',
            });
            setAnimationClassConfPass('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassConfPass(' ')
            }, 5000)
            elemetRefConfPass.current.focus()
            e.preventDefault()
            return false
        }
        else if (confPass != passwordValue) {
            toast.error(`La confirmation du mot de passe ne correspond pas au mot de passe saisi. Veuillez vérifier et réessayer`, {
                autoClose: 5000,
                position: "top-center",
                className: 'my-toast',
            });
            setAnimationClassConfPass('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassConfPass(' ')
            }, 5000)
            elemetRefPass.current.focus()
            e.preventDefault()
            return false
        }
    }

    const { pathname } = useLocation()
    const [click, setclick] = useState(false)
    const [clickSlideHidden, setclickSlideHidden] = useState(false)





    useEffect(() => {

        const windowClicked = () => {
            setclick(false);
            setclickSlideHidden(false);

            // navigate('/User/Echange/Commande')
        };

        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        };
    }, []);


    return (
        <>
            <div className='flex justify-center items-center p-2'>
                <div className="bg-gray-50 border-2 border-blue-800 rounded-3xl max-sm:p-2  shadow-lg sm:px-10  widtg">

                    <div className={`${modifierBtn ? 'hidden' : 'flex'}  flex-col items-center m-2`}>
                        <div className="w-[9em] h-[9em]  m-2 rounded-full relative overflow-hidden">
                            <img draggable='false' src={profil} alt="" className='w-full h-full object-cover object-center ' />
                        </div>
                        <div className="text-gray-500 containera">
                            <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">Nom complet <span>:</span></div><div>MANIRAKIZA Jean de Dieu</div></div>
                            <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">Telephone <span>:</span></div><div>+257 7978690</div></div>
                            <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">Email valide  <span>:</span></div><div>Telecomtelesphore@gmail.com</div></div>
                            <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">Addresse <span>:</span></div><div>Kinindo,Kamenge</div></div>
                            <div className="flex leading-[2em] flexDetail flex-col"> <div className="font-bold mr-1 flex  w-[13em] justify-between">Addresse physique<span>:</span></div><div>hshshshs dhdhhd shahha shshhsnah ahah shshs ahahs</div></div>

                            <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">CNI <span>:</span></div><div>20/292992.2292</div></div>
                            <div className="flex leading-[2em] mt-5 flexDetail">
                                <div className="font-bold mr-1   w-[13em] justify-between">
                                    <p>Photo face avant du C.N.I</p>
                                    <div className='border border-blue-900 p-3 rounded-2xl'>
                                        <img draggable='false' src={profil} />
                                    </div>
                                </div>
                                <div className="font-bold mr-1 f w-[13em] justify-between">
                                    <p>Photo face arrière du C.N.I</p>
                                    <div className='border border-blue-900 p-3 rounded-2xl'>
                                        <img draggable='false' src={profil} />
                                    </div>
                                </div>
                            </div>
                            <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">Méthode de paiement <span>:</span></div><div>Lumicash:6161688;Ecocash:71928192</div></div>



                            <div className="flex leading-[2em] mt-5 flexDetail">
                                <div className="font-bold mr-1   w-[15em] justify-between">
                                    <p>Photo du magazin ou logo</p>
                                    <div className='border border-blue-900 p-3 rounded-2xl'>
                                        <img draggable='false' src={profil} />
                                    </div>
                                </div>
                            </div>
                            <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">Nom du magazin <span>:</span></div><div>MUZINGA shop</div></div>
                            <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">NIF <span>:</span></div><div>23/9202.71928192</div></div>

                            <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">Mot de passe  <span>:</span></div><div className="flex">********* <div title='Modifier le password ' onClick={() => {
                                SetEditorPass(!EditorPass);
                                setTimeout(() => {
                                    if (isdivScroll.current) {
                                        isdivScroll.current.scrollTop = isdivScroll.current.scrollHeight;
                                    }
                                }, 0);
                            }} className="bi cursor-pointer ml-4 bi-pencil-square"></div></div></div>
                        </div>

                        <div className="w-full  flex justify-end"><div onClick={() => { SetmodifierBtn(true) }} className={` ${!modifierBtn ? 'block' : 'hidden'}  px-2 py-1 bg-blue-400 text-white rounded cursor-pointer hover:bg-blue-600 transition-all`}>Modifier</div></div>
                    </div>















                    {/* autre champs */}
                    <div className={`${modifierBtn ? '' : 'hidden'}`}>
                        <h2 className=' p-2 text-center mb-[1em] text-[15px] font-serif text-gray-500 cursor-default'>Modifier les informations</h2>
                        <div className="bg-inherit  m-3 p-2 rounded w-[35em] addClient h-max inscriptvisitions">
                            <form onSubmit={handleSubmitAutreChamp}>
                                <div className="flex mb-6 flex-col">
                                    <div className="mb-10">
                                        <div className="la">
                                            <span>Photos de profil</span>
                                            <label for="profiles" className='bi bi-image-fill cursor-pointer bg-blue-500 px-1 ml-4 rounded text-white'></label>
                                        </div>
                                        <input type="file" accept='image/*' hidden id='profiles' onChange={(e) => { const UrlFile = e.target.files[0]; SetProfil(URL.createObjectURL(UrlFile)) }} />
                                        <div className={`${profil == "" ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'} w-[50%] h-[50%] bg-gray-300 overflow-hidden mt-[20px] rounded-2xl`}>
                                            <img draggable='false' src={profil} alt="" className={`w-full h-full object-cover`} />
                                        </div>
                                    </div>

                                    <div className=" transition-all flex w-[100%] justify-between flexFormul conctat">
                                        <div class="relative h-11 w-1/2 flexFormulInput mr-2 ">
                                            <input ref={elemetRefNom} value={nom} onInput={(e) => Setnom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNom}`} placeholder=" " />
                                            <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Nom
                                            </label>
                                        </div>

                                        <div class="relative h-11 flexFormulInput w-1/2  mr-2 ">
                                            <input value={prenom} ref={elemetRefPrenom}
                                                onInput={(e) => Setprenom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPrenom}`} placeholder=" "
                                            />
                                            <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Prenom
                                            </label>
                                        </div>
                                    </div>
                                    <div className=" mt-[10px] transition-all flex w-[100%] justify-between  flexFormul">
                                        <div class="relative h-11 w-1/2 flexFormulInput  mr-2 ">
                                            <input value={tel} ref={elemetRefTel} onInput={(e) => SetTel(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassTel}`} placeholder=" " />
                                            <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Téléphone
                                            </label>
                                        </div>
                                        <div class="relative h-11 flexFormulInput w-1/2">
                                            <input value={emailValue} ref={elemetRefEmail}
                                                onInput={(e) => setEmailValue(e.target.value)} readOnly className={`peer  pointer-events-none h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`} placeholder=" "
                                            />
                                            <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Email valide
                                            </label>
                                        </div>
                                    </div>

                                    <div className="flex mb-6 flex-col">

                                        <div className="relative h-full w-full mt-[2em]">
                                            <textarea ref={textaresRef}
                                                value={textarea}
                                                onChange={(e) => {
                                                    setTextarea(e.target.value);
                                                    setAutoScrollHeight(e.target.scrollHeight);
                                                    setValueTextarea(e.target.value.trim().length);
                                                    if (!valueTextarea) {
                                                        setAutoScrollHeight(50);
                                                    }
                                                }}
                                                placeholder=" "
                                                style={{ height: `${autoScrollHeight}px` }}
                                                className={`peer personalTextarea leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassTextarea}`}
                                            />
                                            <label
                                                className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-blue-gray-500`}
                                            >
                                                L'address physique
                                            </label>
                                        </div>

                                        <div className=" transition-all mt-5 flex  justify-between ">
                                            <div className={`flex items-center  relative w-full`}>
                                                <input value={CNI} ref={elemetRefCNI}
                                                    onInput={(e) => setCNI(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassCNI}`} placeholder=" "
                                                />
                                                <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                    Carte National d'identité
                                                </label>
                                            </div>
                                        </div>

                                        <div className=" mt-[10px] transition-all flex max-sm:flex-col max-sm:items-center justify-between flexPhotoCNI ">
                                            <div class={`relative h-max mt-3 max-sm:w-full bg-gray-300 text-gray-700  mr-2 ${animationClassPhotoAvantCNI}`}>
                                                <input type='file' id="CNIAvant" hidden onChange={(e) => { const PhotosAvantCNI = e.target.files[0]; setcnii(PhotosAvantCNI); SetPhotoAvantCNI(URL.createObjectURL(PhotosAvantCNI)) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPhotoAvantCNI}`} />
                                                <label htmlFor="CNIAvant" class={`px-2 py-1 rounded cursor-pointer `}>
                                                    Photo face avant du C.N.I
                                                </label>
                                                <div className={` ${!PhotoAvantCNI ? 'w-[0em] h-[0em] opacity-0' : 'w-[12.5em] block h-[12.5em] max-sm:w-full max-sm:h-max opacity-100'} transition-all   mt-1 rounded  bg-gray-300 border-2 border-blue-500`}>
                                                    <img draggable='false' src={PhotoAvantCNI} className="w-full h-full object-contain pointer-events-none" />
                                                </div>
                                            </div>
                                            <div class={`relative max-sm:w-full h-max mt-3 bg-gray-300 text-gray-700  mr-2 ${animationClassPhotoAprestCNI}`}>
                                                <input type='file' id="CNIArrier" hidden onChange={(e) => { const PhotosApprestCNI = e.target.files[0]; setcnio(PhotosApprestCNI); SetPhotoAprestCNI(URL.createObjectURL(PhotosApprestCNI)) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500  ${animationClassPhotoAprestCNI}`} placeholder=" " />
                                                <label htmlFor="CNIArrier" class={` px-2 py-1 rounded cursor-pointer `}>
                                                    Photo face arrière du C.N.I
                                                </label>
                                                <div className={` ${!PhotoAprestCNI ? 'w-[0em] h-[0em] opacity-0' : 'w-[12.5em] block h-[12.5em] max-sm:w-full max-sm:h-max opacity-100'} transition-all   mt-1 rounded  bg-gray-300 border-2 border-blue-500`}>
                                                    <img draggable='false' src={PhotoAprestCNI} className="w-full h-full object-contain" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" transition-all mt-5 flex  mt-[20px] justify-between ">
                                            <div className={`flex items-center  relative w-full`}>
                                                <input value={MethodePaiemen} ref={elemetRefMethodePaiemen}
                                                    onInput={(e) => setMethodePaiemen(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassMethodePaiemen}`} placeholder=" "
                                                />
                                                <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                    Méthode de paiement
                                                </label>
                                            </div>
                                        </div>


                                        <div class={`relative h-max w-max bg-gray-300 text-gray-700   mt-[20px] ${animationClassPhotoMagazin}`}>
                                            <input type='file' id="logoShop" hidden onChange={(e) => { const PhotosSphop = e.target.files[0]; setLogo(PhotosSphop); SetPPhotoMagazin(URL.createObjectURL(PhotosSphop)) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500`} placeholder=" " />
                                            <label htmlFor="logoShop" class={` px-2 py-1 rounded cursor-pointer`}>Photo du magazin ou logo</label>
                                            <div className={` ${!PhotoMagazin ? 'w-[0em] h-[0em] opacity-0' : 'w-[20em] block max-sm:w-full max-sm:h-max h-[20em] opacity-100'} transition-all   mt-1 rounded  bg-gray-300 border-2 border-blue-500 settWidthShp`}>
                                                <img draggable='false' src={PhotoMagazin} className="w-full h-full object-contain" />
                                            </div>
                                        </div>

                                        <div className=" transition-all flex mt-3  justify-between  ">
                                            <div class="relative h-11 w-1/2  mr-2 ">
                                                <input ref={elemetRefNomMagazin} value={NomMagazin} onInput={(e) => setNomMagazin(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNomMagazin}`} placeholder=" " />
                                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                    Nom du magazin
                                                </label>
                                            </div>

                                            <div class="relative h-11 w-1/2   mr-2 ">
                                                <input value={NIF} ref={elemetRefNIF}
                                                    onInput={(e) => setNIF(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNIF}`} placeholder=" "
                                                />
                                                <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                    NIF
                                                </label>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div className="flex justify-end items-center">
                                    <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-blue-600   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Modifier' className='cursor-pointer '></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>

                                </div>
                            </form>
                        </div>
                    </div>





















                    {/* mot de pass */}
                    <div className={`${EditorPass ? 'block' : 'hidden'}  ${modifierBtn ? 'hidden' : ''}`}>
                        <form onSubmit={handleSubmitPassword}>
                            <div className=" transition-all mt-10 flex w-[100%] flex-col">
                                <h2 className='font-serif text-gray-400 text-[10px] cursor-default mb-5'>Modifier le password</h2>
                                <div className={`relative  w-[100%] p-1 `}>

                                    <div className={`relative mt-3 `}>
                                        <div class="relative h-11 w-full bloksInput">
                                            <input ref={elemetRefPassAncien} onInput={(e) => { SetPassValueAncien(e.target.value) }} type={passwordVisible ? 'text' : 'password'} className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassAncien}`} placeholder=" " />
                                            <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Ancien mot de passe
                                            </label>
                                        </div>
                                        <div onClick={() => { setPasswordVisible(!passwordVisible) }} className=" cursor-pointer w-[20px] h-[20px] absolute flex justify-center top-[20px] items-center right-2 ">
                                            <i class={`bi ${passwordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'} absolute`}></i>
                                        </div>
                                    </div>

                                    <div className={`relative mt-3 `}>
                                        <div class="relative h-11 w-full bloksInput">
                                            <input ref={elemetRefPass} onInput={(e) => { setPasswordValue(e.target.value) }} type={passwordVisible ? 'text' : 'password'} className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassword}`} placeholder=" " />
                                            <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Nouveau mot de passe
                                            </label>
                                        </div>
                                        <div onClick={() => { setPasswordVisible(!passwordVisible) }} className=" cursor-pointer w-[20px] h-[20px] absolute flex justify-center top-[20px] items-center right-2 ">
                                            <i class={`bi ${passwordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'} absolute`}></i>
                                        </div>
                                    </div>

                                    <div className={`relative mt-3 `}>
                                        <div class="relative h-11 w-full bloksInput ">
                                            <input ref={elemetRefConfPass} value={confPass} onInput={(e) => { SetconfPass(e.target.value) }} type={ConfpasswordVisible ? 'text' : 'password'} className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassConfPass}`} placeholder=" " />
                                            <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Confirme le mot de passe
                                            </label>
                                        </div>
                                        <div onClick={() => { setConfPasswordVisible(!ConfpasswordVisible) }} className=" cursor-pointer w-[20px] h-[20px] top-[20px] absolute flex justify-center items-center right-2 ">
                                            <i class={`bi ${ConfpasswordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'} absolute`}></i>
                                        </div>
                                    </div>
                                    <div className="flex justify-end items-center mt-3">
                                        <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-blue-600   p-2 rounded  text-white">
                                            <input type="submit" id="send" value='Modifier' className='cursor-pointer '></input>
                                            <i class="bi bi-send ml-2 "></i>
                                        </label>

                                    </div>
                                </div>


                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompteMarcheUse
