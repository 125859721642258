import React from 'react'
import DemmandeCilentEchangeAdmin from './DemmandeCilentEchangeAdmin'
function DemmandeCilentEchangeAdminComponent({ getListCommand }) {
  return (
    <>

      <DemmandeCilentEchangeAdmin getListCommand={getListCommand} />
    </>
  )
}

export default DemmandeCilentEchangeAdminComponent
