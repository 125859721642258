/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"
import ReactPlayer from 'react-player';

function Home() {
  const [imageLoading, SetImageLoading] = useState(true)
  const [sizeInfer, SetsizeInfer] = useState(window.innerWidth < 650)

  const [titre, Settitre] = useState(window.innerWidth < 800.38383838282929)

  useEffect(() => {
    const hundleSize = () => {
      SetsizeInfer(window.innerWidth < 650)
      Settitre(window.innerWidth < 800.38383838282929)
    }

    window.addEventListener('resize', hundleSize)

    return () => {
      window.removeEventListener('resize', hundleSize)
    }
  }, [])
  return (
    <div className="h-max  w-full flex justify-center ">

      <div className="w-full h-max flex py-2 bg-slate-50 pb-40 rounded-b-[30%]  sm:px-4 justify-around responsi ">

        <h2 className={` ${titre ? 'block ' : 'hidden'} text-[30px] text-blue-600 font-serif w-full text-center textSizeTitre`}>   Bienvenue à notre  site web  Burundi  en temps réel</h2>

        <div className={` flex  overflow-hidden  relative   w-[30em] h-[30em] bg-transparent PhotosSizeVideo  responsive1`}>

          {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
          <ReactPlayer width='100%' height='100%' url='https://www.youtube.com/watch?v=3jGGXF4_YiE' controls draggable='false' onReady={() => { setTimeout(() => { SetImageLoading(false) }, 1) }} />
        </div>

        <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
          <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] textSizeTitre text-blue-600 font-serif w-full text-center textSizeTitre`}>   Bienvenue à notre  site web  Burundi  en temps réel</h2>

          <p className="text-gray-500 p-1 text-[25px]  text-center reduitTailParagr ">
            Découvrez une plateforme tout-en-un qui
            couvre l'éducation, les échanges, les réservations en ligne,
            les loisirs, la santé, la programmation, le transport, les mentors,
            l'agriculture et l'élevage. Apprenez sans limites, explorez de nouvelles
            cultures et trouvez des solutions adaptées à vos besoins. Réservez facilement
            des activités de loisirs, bénéficiez de conseils experts en santé, développez
            vos compétences en programmation et voyagez en toute tranquillité.
          </p>
        </div>

      </div>
    </div>

  )
}

export default Home
