import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Empty from '../Empty/Empty'


function DepenseMarcheUser({ ajoutQuantProduit, SetajoutQuantProduit }) {

    const { pathname } = useLocation()
    const [click, setclick] = useState(false)
    const [clickCommande, setclickCommande] = useState(false)
    const [clickSlideHidden, setclickSlideHidden] = useState(false)
    const [isList, SetiseList] = useState(true)
    const [isGraphy, SetGraphy] = useState(true)

    const Client = /^\/Client.*/
    const Produits = /^\/Produits.*/

    useEffect(() => {
        const windowClicked = () => {
            setclick(false);
            setclickSlideHidden(false);
        };

        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        };
    }, []);
    const userId = localStorage.getItem("userid");



    const [listoffdepenses, setListDepenses] = useState([]);
    const [element, setElement] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (event) => {
        const selectedItemsPerPage = parseInt(event.target.value);
        setItemsPerPage(selectedItemsPerPage);
    };
    const firstEntry = (currentPage - 1) * itemsPerPage + 1;
    const lastEntry = Math.min(currentPage * itemsPerPage, element);

    useEffect(() => {
        axios.get(` https://btrproject.burundientempsreel.com/depense/getall/${userId}?page=${currentPage}&size=${itemsPerPage}`, {
            headers: {
                accessToken: localStorage.getItem("t"),
            }
        })
            .then((response) => {
                setElement(response.data.totalItems);
                setListDepenses(response.data.depenses);
                setTotalPages(response.data.totalPages);
            }).catch((error) => {
                console.error(error.message);
                if (error.response) {
                    console.log(error.response.data);
                }

            });
    }, [currentPage, itemsPerPage, userId]);

    const formatDate = (dateString) => {
        const optionsDate = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const optionsTime = { hour: '2-digit', minute: '2-digit' };

        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('fr-FR', optionsDate);
        const formattedTime = date.toLocaleTimeString('fr-FR', optionsTime);

        return `Le ${formattedDate} à ${formattedTime}`;
    };
    const formatAmount = (amount) => {
        // Convertir le montant en nombre à virgule flottante
        const numberAmount = parseFloat(amount);

        // Vérifier si le montant est un nombre valide
        if (isNaN(numberAmount)) {
            return "Montant invalide";
        }

        // Formater le montant en utilisant les séparateurs de milliers et la virgule décimale
        const formattedAmount = numberAmount.toLocaleString('fr-FR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return formattedAmount;
    };
    return (
        <>
            <div className='bg-gray-200 sticky top-0 flex justify-between px-10 items-center border-blue-300 border-b paddings'>
                <div className="w-full overflow-hidden h-11 flex justify-center items-center mr-2">
                    <div className="text-gray-500 mot1">   Affichage des depenses</div>
                </div>
                <div class="bg-green-500 py-1 px-2 rounded text-white mr-3">
                    <Link to='/User/Depenses/AjoutDepense' title="Ajouter une nouvelle produit" className='text-[20px]'> <i class="bi bi-plus-circle"></i></Link>
                </div>
            </div>


            {listoffdepenses.length > 0 ? (
                <div class={` ${isList ? 'flex' : 'hidden'} flex flex-col h-[35em] items-end`}>
                    <div className="relative">
                        <select
                            className=" h-full rounded-l-2xl border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                        >
                            <option>5</option>
                            <option>10</option>
                            <option>20</option>
                            {(listoffdepenses.length > 20) && (<option>{listoffdepenses.length}</option>)}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                        </div>
                    </div>
                    <div className="pr-10  p-1 m-1 rounded search ">
                        <p className='text-gray-500 font-serif'>Rechercher par votre choix</p>
                        <div className="search py-1 w-full search ">
                            <form className='flex  w-[100%] '>
                                <input type="text" className='p-1 search border border-blue-700 rounded transition-all outline-none focus:border-2 focus:border-blue-500' id="" placeholder='Rechercher...' />
                                <label htmlFor='search' className='transition-all bg-blue-400 text-white hover:bg-blue-600 p-2 ml-1 cursor-pointer rounded'><i className='bi bi-search'></i></label>
                            </form>
                        </div>
                    </div>
                    <div className="w-full overflow-auto mt-4">
                        <div className="w-[98%] overflow-hidden widthTabAdmiProdui mr-4">

                            <table className="w-full mx-2" cellspacing="0 " border="0 ">
                                <thead class=" h-11 select-none bg-blue-500 border-b-2 border-blue-400 ">
                                    <tr>
                                        <th className='border-r border-gray-300 text-[white] w-[1em]'>Montant</th>
                                        <th className='border-r border-gray-300 text-[white] w-[3px]'>Date et heure</th>
                                        <th className='border-r border-gray-300 text-[white] w-[3em]'>Action</th>
                                    </tr>
                                </thead>
                                <tbody class="text-gray-5000">

                                    {listoffdepenses.map((dep) => {
                                        return (
                                            <tr className='border-r  border-b border-gray-300 h-[35px] text-gray-600 '>
                                                <td className=' border-r border-gray-300'>{formatAmount(dep.montant)}</td>
                                                <td className=' border-r border-gray-300'>{formatDate(dep.createdAt)}</td>
                                                <td className='flex justify-center items-center h-10'>
                                                    <div class="flex justify-center items-center h-full w-[70px] border rounded-lg border-blue-400 pt-2 ">
                                                        <Link to={`/User/Depenses/Dettail/${dep.id}`} class=" w-full  h-full flex justify-around">
                                                            Détaill
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                            <div class="px-5 py-5 border-t flex flex-col xs:flex-row items-center xs:justify-between">
                                <span className="text-xs xs:text-sm text-gray-900">
                                    Showing {firstEntry} to {lastEntry} of {element} Entries
                                </span>

                                <div className="inline-flex mt-2 xs:mt-0">
                                    <button
                                        className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
                                        disabled={currentPage === 1}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        Prev
                                    </button>
                                    <button
                                        className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
                                        disabled={currentPage === totalPages}
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                        Next
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div >
            )
                :
                (
                    <>

                        <Empty item="depense" />
                    </>
                )
            }


        </>
    )
}

export default DepenseMarcheUser
