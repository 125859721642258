import React from 'react'

function Equipe() {
    return (
        <div className="hidden bg-white rounded  my-9 flex justify-center flex-col  items-center">
            <h2 className="my-4 text-[30px]  text-green-700">Notre équipe</h2>
            <div className="flex flex-wrap justify-center items-center" >

                <div className="shadow1 p-2 h-max w-[15em] bg-white  m-2 relative flex flex-col items-center   rounded overflow-hidden">
                    <div className="w-[12em] h-[12em] bg-red-900 rounded-[50%]  object-cover overflow-hidden">
                        <img draggable='false' src="../../image/logbtr.jpg" className="w-full h-full" alt="" srcset="" />
                    </div>
                    <div className="">
                        <h2 className="flex  p-1 justify-start my-1">NIYOMUKIZA Varia-coeur</h2>
                        <p className="text-[15px]">

                        </p>
                    </div>
                </div>

                <div className=" shadow1 p-2 h-max w-[15em] bg-white  m-2 relative flex flex-col items-center   rounded overflow-hidden">
                    <div className="w-[12em] h-[12em] bg-red-900 rounded-[50%]  object-cover overflow-hidden">
                        <img draggable='false' src="../../image/logbtr.jpg" className="w-full h-full" alt="" srcset="" />
                    </div>
                    <div className="">
                        <h2 className="flex  p-1 justify-start my-1">IRAKOZE Jean de Dieu</h2>
                        <p className="text-[15px]">

                        </p>
                    </div>
                </div>



                <div className=" shadow1 p-2 h-max w-[15em] bg-white  m-2 relative flex flex-col items-center   rounded overflow-hidden">
                    <div className="w-[12em] h-[12em] bg-red-900 rounded-[50%]  object-cover overflow-hidden">
                        <img draggable='false' src="../../image/logbtr.jpg" className="w-full h-full" alt="" srcset="" />
                    </div>
                    <div className="">
                        <h2 className="flex  p-1 justify-start my-1">First d'el shaddi</h2>
                        <p className="text-[15px]">                       </p>
                    </div>
                </div>
                <div className=" shadow1 p-2 h-max w-[15em] bg-white  m-2 relative flex flex-col items-center   rounded overflow-hidden">
                    <div className="w-[12em] h-[12em] bg-red-900 rounded-[50%]  object-cover overflow-hidden">
                        <img draggable='false' src="../../image/logbtr.jpg" className="w-full h-full" alt="" srcset="" />
                    </div>
                    <div className="">
                        <h2 className="flex  p-1 justify-start my-1">NDAYIZEYE Télésphore</h2>
                        <p className="text-[15px]">

                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Equipe
