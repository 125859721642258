/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { openChat } from '../../../TawkTo'
function VisitorHome({ isdivScroll }) {
    const [imageLoading, SetImageLoading] = useState(true)
    const [imageLoading1, SetImageLoading1] = useState(true)


    const [size, SetSize] = useState(window.innerWidth < 911)
    const [mobil2, SetMobile2] = useState(window.innerWidth < 672)
    const [size1, SetSize1] = useState(window.innerWidth < 613)
    const [mobile3, SetMobile3] = useState(window.innerWidth < 512)

    const [mobile, SetMobile] = useState(window.innerWidth < 370)
    const [mobile1, SetMobile1] = useState(window.innerWidth < 440)
    const [mobile4, SetMobile4] = useState(window.innerWidth < 300)



    useEffect(() => {
        const hundleSize = () => {
            SetSize(window.innerWidth < 911)
            SetMobile2(window.innerWidth < 672)
            SetSize1(window.innerWidth < 613)
            SetMobile3(window.innerWidth < 512)
            SetMobile4(window.innerWidth < 300)




            SetMobile(window.innerWidth < 370)
            SetMobile1(window.innerWidth < 440)

        }

        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    const hundleScrollDiv = () => {
        isdivScroll.current.scrollTop = 0
    }

    useEffect(() => {
        isdivScroll.current.style.scrollTop = 'smooth'
    }, [])

    return (
        <div className='bg-white select-none'>

            <div className=" h-max mb-1 pb-5 rounded-b-[8%] bg-gray-100 w-full  flex justify-center ">
                <div className={`w-full rounded-3xl h-max flex py-2 ${size1 ? 'flex-col items-center' : ''}    sm:px-4 justify-around`}>
                    <h2 className={` ${size1 ? 'block' : 'hidden'}  px-3   text-[30px] text-gray-600 font-serif w-full text-center`}>  Bienvenue sur notre site web au service d'échange</h2>
                    <div className={`flex w-full  px-3 ${size1 ? 'flex-col items-center' : ''}`}>
                        <div className={` ${imageLoading ? '' : ''} animeTop  ${size1 ? 'w-[90%] h-max ' : ''}  flex    w-[30em] h-[30em] relative  bg-gray-200   rounded-[20px]   overflow-hidden `}>
                            {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                            <img draggable='false' onLoad={() => {
                                setTimeout(() => {
                                    SetImageLoading(false)
                                }, 1000)
                            }} src="../../image/exchangepremier.png" className="w-full h-full object-contain object-center" />
                        </div>
                        <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 ">
                            <h2 className={` ${size1 ? 'hidden' : 'block'}  px-3  text-[30px] text-gray-600 font-serif w-full text-center`}> Bienvenue sur notre site web au service d'échange</h2>
                            <p className="  w-full flex justify-center ">
                                <div className={`text-gray-500   text-center ${size ? 'text-[20px]  w-[90%]' : 'text-[25px]'}`}>
                                    Optimisez vos échanges de monnaie électroniques avec
                                    notre plateforme.
                                    Maximisez votre efficacité et exploitez pleinement les possibilités
                                    offertes par notre site. Accélérez votre processus d'échange et
                                    profitez de la facilité de nos services en ligne<br />
                                    <div className='w-full flex justify-center items-center mt-10'>
                                        <Link to='/Visit/connect' onClick={hundleScrollDiv} className='bg-blue-600 text-white text-[18px] cursor-pointer transition-all border hover:bg-transparent font-serif w-max px-2 py-1 hover:text-gray-700 hover:border-blue-700 rounded-2xl'>Commencez avec Varia Trade center</Link>
                                    </div>
                                </div>
                            </p>
                        </div>
                    </div>

                </div>
            </div>



            <div className={` text-gray-700 ${mobile ? 'px-2 text-[25px]' : 'px-5 text-[30px]'} w-full text-center mt-10  font-serif font-bold`}>Echange de monnaie électroniques pour tout le monde</div>

            <div className='w-full flex justify-center items-center'>
                <p className={`text-gray-500 text-[20px] w-[40em] ${mobile ? 'px-3' : 'px-10'}   text-center leading-8`}> Profitez de conversions rapides et
                    sécurisées avec Lumicash, Ecocash, CECM Akaravyo ,Bancobu eNoti, Mobinetel et pesaflash.
                    Simplifiez vos transactions et gagnez du temps précieux.
                    Bénéficiez d'une interface conviviale pour une expérience fluide.
                    Accédez à une variété de monnaies électroniques en un seul endroit.
                </p>
            </div>


            <div className='flex justify-center p-2 items-center'>
                <div className={`w-max h-max bg-white ${mobile ? 'grid grid-cols-2 gap-3 pr-3' : 'flex flex-wrap justify-center'} `}>
                    <div className={`bg-white  ${mobile ? 'w-[100%] h-[20vh]' : 'w-[10em] h-[10em]'}   m-2 rounded-2xl border border-blue-800 border-dotted overflow-hidden`}><img draggable='false' src='../image/lumitel.png' className='w-full h-full pointer-events-none select-none  object-cover  object-center' /> </div>
                    <div className={`bg-white  ${mobile ? 'w-[100%] h-[20vh]' : 'w-[10em] h-[10em]'}   m-2 rounded-2xl border border-blue-800 border-dotted overflow-hidden`}><img draggable='false' src='../image/mobinotel.png' classeName='w-full h-full pointer-events-none select-none  object-cover object-center' /></div>
                    <div className={`bg-white  ${mobile ? 'w-[100%] h-[20vh]' : 'w-[10em] h-[10em]'}   m-2 rounded-2xl border border-blue-800 border-dotted overflow-hidden`}><img draggable='false' src='../image/ecocashe.jpg' className='w-full h-full pointer-events-none select-none  object-contain  object-center' /></div>
                    <div className={`bg-white  ${mobile ? 'w-[100%] h-[20vh]' : 'w-[10em] h-[10em]'}   m-2 rounded-2xl border border-blue-800 border-dotted overflow-hidden`}><img draggable='false' src='../image/BancobueNoti.jpg' className='w-full h-full pointer-events-none select-none  object-contain  object-center' /></div>
                    <div className={`bg-white  ${mobile ? 'w-[100%] h-[20vh]' : 'w-[10em] h-[10em]'}   m-2 rounded-2xl border border-blue-800 border-dotted overflow-hidden`}><img draggable='false' src='../image/New-logo-CECM-01.jpg' className='w-full h-full pointer-events-none select-none  object-contain  object-center' /></div>
                    <div className={`bg-white  ${mobile ? 'w-[100%] h-[20vh]' : 'w-[10em] h-[10em]'}   m-2 rounded-2xl border border-blue-800 border-dotted overflow-hidden`}><img draggable='false' src='../image/pesaFlash.jpg' className='w-full h-full  pointer-events-none select-none object-cover  object-center' /></div>
                </div>

            </div>
            <div className={`flex flex-col mt-10 justify-center   p-2 items-center`}>
                <div className={`text-gray-700  ${mobile ? 'text-[23px] px-1' : 'text-[35px]'}  text-center  strockText`}>Gagnez des récompenses éléctronique</div>
                <p className={`text-center  ${mobile ? 'text-[18px] px-1 ' : 'text-[20px]'} ${mobile1 ? 'w-[95%]' : 'w-[20em]'}  text-gray-600 flex mb-4 flex-col items-center `}>
                    Gagnez jusqu'à 24 % de récompenses
                    par an en misant vos actifs sur Varia Trade center. Il suffit de quelques clics sur
                    <Link onClick={hundleScrollDiv} to="/Visit/s'inscrire" className='text-wite bg-blue-400 text-[18px] mt-3 w-max px-2 hover:bg-blue-800 transition-all py-1 rounded text-white'>Créer un compte</Link>
                </p>

                <div className='flex flex-wrap justify-center mt-9'>
                    <div className={`bg-white p-4 flex flex-col items-center m-1 ${mobile3 ? 'w-[95%]' : mobil2}  ${mobil2 ? 'w-[15em]  h-max' : 'w-[20em]'} border border-blue-700 rounded-2xl`}>
                        <p className='text-[25px] font-serif font-bold'>Simplicité</p>
                        <div className={`bg-white   m-2 rounded-2xl ${mobile3 ? 'w-[90%] h-max' : mobil2} ${mobil2 ? 'w-[13em]  h-[13em] ' : 'w-[15em] h-[15em]'} border border-blue-800 border-dotted overflow-hidden`}>
                            {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                            <img draggable='false' onLoad={() => {
                                setTimeout(() => {
                                    SetImageLoading1(false)
                                }, 1000)
                            }} src="../image/exchange.png" className='w-full h-full pointer-events-none select-none  object-contain  object-center' />
                        </div>
                        <p className='text-gray-500 text-center '>
                            Varia Trade center facilite l'achat
                            electronique en utilisant nos applications mobiles
                            sera bientôt disponible sur le Play Store. Restez à
                            l'écoute pour être parmi les premiers
                            à la télécharger et à profiter de ses fonctionnalités exceptionnelles

                            <div className={`${mobile4 ? ' flex flex-col items-center' : 'flex bg-[#0000ff33] justify-center'} rounded-xl mt-2`}>
                                <div className='bg-white m-1 w-[50px] p-1 h-[50px]  cursor-none   rounded-xl overflow-hidden'>
                                    <img src='../image/QrCodePlayeStoreExchange.png' draggable='false' className='w-full h-full object-contain object-center' />
                                </div>
                                <a target='_blank' href='https://play.google.com/store/apps/details?id=com.btr_dev.VariaTradeCenter' className='bg-gray-100 m-1 w-[100px] h-[50px]   rounded-xl overflow-hidden'>
                                    <img src='../image/playStore.jpg' draggable='false' className='w-full h-full object-contain object-center' />
                                </a>
                            </div>
                        </p>

                    </div>


                    <div className={`bg-white p-4 flex flex-col items-center m-1 ${mobile3 ? 'w-[95%]' : mobil2}  ${mobil2 ? 'w-[15em]  h-max' : 'w-[20em]'} border border-blue-700 rounded-2xl`}>
                        <p className='text-[25px] font-serif font-bold'>Echange</p>
                        <div className={`bg-white   m-2 rounded-2xl ${mobile3 ? 'w-[90%] h-max' : mobil2} ${mobil2 ? 'w-[13em]  h-[13em] ' : 'w-[15em] h-[15em]'} border border-blue-800 border-dotted overflow-hidden`}>
                            {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                            <img draggable='false' onLoad={() => {
                                setTimeout(() => {
                                    SetImageLoading1(false)
                                }, 1000)
                            }} src="../image/Depotetretrait.png" className='w-full h-full pointer-events-none select-none  object-contain  object-center' />
                        </div>
                        <p className='text-gray-500 text-center '>

                            Varia Trade center propose des carnets de commandes avec une liquidité , permettant aux utilisateurs d'échanger facilement Lumicash, Ecocash, CECM Akaravyo ,Bancobu eNoti, Mobinetel et pesaflash en temps réel
                        </p>
                    </div>



                    <div className={`bg-white p-4 flex flex-col items-center m-1  ${mobile3 ? 'w-[95%]' : mobil2}  ${mobil2 ? 'w-[15em]  h-max' : 'w-[20em]'} border border-blue-700 rounded-2xl`}>
                        <p className='text-[25px] font-serif font-bold'>Service</p>
                        <div className={`bg-white   m-2 rounded-2xl ${mobile3 ? 'w-[90%] h-max' : mobil2} ${mobil2 ? 'w-[13em]  h-[13em] ' : 'w-[15em] h-[15em]'} border border-blue-800 border-dotted overflow-hidden`}>
                            {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                            <img draggable='false' onLoad={() => {
                                setTimeout(() => {
                                    SetImageLoading1(false)
                                }, 1000)
                            }} src="../image/internet_des_objets.png" className='w-full h-full pointer-events-none select-none  object-contain  object-center' />
                        </div>
                        <p className='text-gray-500 text-center '>
                            Trouvez vos réponses instantanément dans notre centre d'assistance.
                            Ou contactez-nous 24 heures sur 24, 7 jours sur 7, 365 jours par an par chat en direct,
                            par téléphone ou par e-mail.
                        </p>
                        <div onClick={() => { openChat() }} className='w-[4em] h-[4em]  flex justify-center rounded-full bg-blue-200 items-center relative top-4 cursor-pointer mb-4'>
                            <div className='w-[3em] h-[3em]  flex justify-center rounded-full bg-blue-400 items-center '>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-messenger text-white" viewBox="0 0 16 16">
                                    <path d="M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.639.639 0 0 0-.427.03l-1.588.702a.64.64 0 0 1-.898-.566l-.044-1.423a.639.639 0 0 0-.215-.456C.956 12.108 0 10.092 0 7.76zm5.546-1.459-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 0 1 .578-.002l1.869 1.402a1.2 1.2 0 0 0 1.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 0 1-.578.002L7.281 5.98a1.2 1.2 0 0 0-1.735.32z" />
                                </svg>
                            </div>
                        </div>
                    </div>


                    <div className={`bg-white p-4 flex flex-col items-center m-1 ${mobile3 ? 'w-[95%]' : mobil2}  ${mobil2 ? 'w-[15em]  h-max' : 'w-[20em]'} border border-blue-700 rounded-2xl`}>
                        <p className='text-[25px] font-serif font-bold'>Sécurité</p>
                        <div className={`bg-white   m-2 rounded-2xl ${mobile3 ? 'w-[90%] h-max' : mobil2} ${mobil2 ? 'w-[13em]  h-[13em] ' : 'w-[15em] h-[15em]'} border border-blue-800 border-dotted overflow-hidden`}>
                            {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                            <img draggable='false' onLoad={() => {
                                setTimeout(() => {
                                    SetImageLoading1(false)
                                }, 1000)
                            }} src="../image/Comptemoneyelectronic.png" className='w-full h-full pointer-events-none select-none  object-contain  object-center' />
                        </div>
                        <p className='text-gray-500 text-center '>

                            Achetez et vendez des des de monnaie électroniques instantanément et en toute sécurité avec la rapidite exstra-ordinaire

                        </p>
                    </div>
                </div>

            </div>

            <div className='w-full flex py-4 justify-center items-center'>
                <div className='bg-gray-100 w-[95%] rounded'>
                    <p className={`font-serif ${mobile1 ? 'text-[25px] ' : 'text-[35px] '}  text-gray-600 text-center mt-9`}>
                        Demander les éléctroniques à tout moment et en tout lieu</p>
                    <div className='h-[2px] bg-gray-200 w-full'></div>
                    <div className='flex items-center justify-center mb-10'>
                        <p className={`  ${mobile1 ? 'text-[18px] p-1 w-[95%]' : 'text-[20px] p-4 w-[80%]'}    text-center`}>
                            Commencez votre voyage
                            Varia Trade center facilite le démarrage. <Link onClick={hundleScrollDiv} to="/Visit/s'inscrire" className='text-blue-600 hover:text-blue-800'>S'inscrire
                                aujourd'hui</Link> pour acheter et vendre
                            Avec notre plateforme,
                            vous pouvez effectuer des
                            échanges de manière rapide et
                            sécurisée. Nous avons mis en place des protocoles de sécurité avancés pour protéger vos transactions et assurer une confidentialité absolue de vos informations personnelles. Vous pouvez échanger
                            en toute confiance, sachant que votre argent est entre de bonnes mains.</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex relative top-10  px-6 text-gray-400 justify-center items-center'>
                <div className='w-[50em]  text-center'>
                    Nous sommes impatients de travailler avec vous pour créer un avenir financier plus efficace et connecté. Si vous avez des questions ou avez besoin d'assistance pour commencer, n'hésitez pas à nous contacter. Bienvenue dans notre équipe, et merci de faire partie de notre mission de faciliter la vie de nos clients grâce à nos services électroniques en temps réel.
                </div>
            </div>
        </div>
    )
}

export default VisitorHome


