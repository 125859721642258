/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function SanteHomeVisitor() {
    const [imageLoading, SetImageLoading] = useState(true)
    const [imageLoading1, SetImageLoading1] = useState(true)
    const [imageLoading2, SetImageLoading2] = useState(true)
    const [imageLoading3, SetImageLoading3] = useState(true)
    const [imageLoading4, SetImageLoading4] = useState(true)

    const [sizeInfer, SetsizeInfer] = useState(window.innerWidth < 650)
    const [sizeInfer1, SetsizeInfer1] = useState(window.innerWidth < 650)
    const [sizeInfer2, SetsizeInfer2] = useState(window.innerWidth < 650)
    const [sizeInfer3, SetsizeInfer3] = useState(window.innerWidth < 650)
    const [sizeInfer4, SetsizeInfer4] = useState(window.innerWidth < 650)


    const [titre, Settitre] = useState(window.innerWidth < 800.38383838282929)

    useEffect(() => {
        const hundleSize = () => {
            SetsizeInfer(window.innerWidth < 650)
            SetsizeInfer1(window.innerWidth < 650)
            SetsizeInfer2(window.innerWidth < 650)
            SetsizeInfer3(window.innerWidth < 650)
            SetsizeInfer4(window.innerWidth < 650)
            Settitre(window.innerWidth < 800.38383838282929)


        }

        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    return (
        <>

            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] textSizeTitre text-blue-600 font-serif w-full text-center`}>  Bienvenue  sur notre site web dédié à la santé</h2>

                    <div className={` ${imageLoading && sizeInfer ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading(false)
                            }, 1000)
                        }} src="../image/sante_avec_docteur.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] textSizeTitre text-blue-600 font-serif w-full text-center`}> Bienvenue  sur notre site web dédié à la santé</h2>
                        <p className="text-gray-500 p-1 text-[22px] mt-6 ">
                            <div className="reduitTailParagr text-gray-500">
                                Ici, vous trouverez une mine d'informations, des conseils pratiques et des ressources pour prendre soin de votre bien-être physique et mental.
                                <br /><br />
                                Que vous cherchiez des conseils sur la nutrition, l'exercice physique, la gestion du stress, des informations sur les maladies courantes, ou des astuces pour adopter un mode de vie sain, vous êtes au bon endroit.
                            </div>
                        </p>
                    </div>
                </div>
            </div>


            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] textSizeTitre text-blue-600 font-serif w-full text-center`}>Partenariat avec les pharmacie</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] textSizeTitre text-blue-600 font-serif w-full text-center`}> Partenariat avec les pharmacie </h2>

                        <p className="text-gray-500 p-1 text-[22px] ">
                            <div className="reduitTailParagr text-gray-500">
                                <div className="reduitTailParagr text-gray-500">
                                    Ici, où vous trouverez une multitude d'informations
                                    et de services liés à votre santé et à votre bien-être.
                                    En tant que visiteur, vous avez accès à une variété de
                                    ressources et de fonctionnalités conçues pour vous aider
                                    à prendre soin de votre santé de manière efficace et pratique.
                                    <br /><br />Que vous cherchiez des conseils sur la nutrition, l'exercice physique, la gestion du stress, des informations sur les maladies courantes, ou des astuces pour adopter un mode de vie sain, vous êtes au bon endroit.
                                </div>
                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading1 && sizeInfer1 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading1(false)
                            }, 1000)
                        }} src="../image/pharmacy.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] textSizeTitre text-blue-600 font-serif w-full text-center`}> Hospital  privé</h2>

                    <div className={` ${imageLoading2 && sizeInfer2 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading2 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading2(false)
                            }, 1000)
                        }} src="../image/hopital_prive.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] textSizeTitre text-blue-600 font-serif w-full text-center`}> Hospital  privé</h2>
                        <p className="text-gray-500 p-1 text-[25px] reduitTailParagr">
                            Chez nous, vous trouverez une équipe médicale compétente et dévouée, ainsi que des installations modernes pour répondre à vos besoins de santé.
                            <br /><br />
                            Que vous recherchiez des informations sur nos spécialités médicales, des détails sur nos praticiens qualifiés ou des renseignements sur nos services et procédures, notre équipe est là pour vous guider et vous fournir toutes les informations dont vous avez besoin.       <br />

                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>



            <div className="p-2 h-max my-1 w-full flex flex-col items-center ">
                <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] textSizeTitre text-blue-600 font-serif w-full text-center`}>Cabinet personnel</h2>

                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsive1 ">

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] textSizeTitre text-blue-600 font-serif w-full text-center`}>Cabinet personnel </h2>

                        <p className="text-gray-500 p-1 text-[22px] ">
                            <div className="reduitTailParagr text-gray-500">
                                <div className="reduitTailParagr text-gray-500">
                                    Notre cabinet de santé personnel met l'accent sur votre confort et votre confidentialité. Nous comprenons l'importance de prendre soin de votre santé de manière holistique, en prenant en compte tous les aspects de votre bien-être physique et mental.<br /><br />
                                    Nous vous offrons des services de qualité, des professionnels de la santé compétents et une approche personnalisée pour répondre à vos besoins spécifiques. Notre équipe se tient à votre disposition pour vous aider à maintenir une bonne santé et à prendre les meilleures décisions pour votre bien-être.
                                </div>
                                <div className=" w-full p-2 flex  justify-end hidden ">
                                    <Link to='/Visit/Cours' className="flex  bg-blue-400 relative mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                        <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                        Commencer
                                    </Link>
                                </div>
                            </div>

                        </p>
                    </div>
                    <div className={` ${imageLoading3 && sizeInfer3 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading3 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading3(false)
                            }, 1000)
                        }} src="../image/RDVDoctor.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                </div>
            </div>




            <div className="p-2 h-max my-1 w-full flex justify-center ">
                <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                    <h2 className={` ${titre ? 'block' : 'hidden'} text-[30px] textSizeTitre text-blue-600 font-serif w-full text-center`}> Pharmacie  aux soins vétérinaire</h2>

                    <div className={` ${imageLoading4 && sizeInfer4 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize   overflow-hidden responsive1`}>
                        {imageLoading4 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading4(false)
                            }, 1000)
                        }} src="../image/PHARMACYY.png" alt="Photos introuvable" className="w-full h-full object-contain object-center" />
                    </div>

                    <div className=" sm:w-[40em] w-full h-max p-1 rounded sm:ml-6 responsive11">
                        <h2 className={` ${titre ? 'hidden' : 'block'} text-[30px] textSizeTitre text-blue-600 font-serif w-full text-center`}> Pharmacie  aux soins vétérinaire</h2>
                        <p className="text-gray-500 p-1 text-[25px] reduitTailParagr">
                            Nous sommes ravis de vous accueillir dans notre espace en ligne où vous trouverez une multitude d'informations et de services liés à la santé, aux médicaments et aux soins pour vos animaux de compagnie.
                            <br /><br /> Que vous soyez à la recherche de conseils santé, de renseignements sur les médicaments, ou que vous ayez besoin de trouver une pharmacie ou une clinique vétérinaire près de chez vous, vous êtes au bon endroit. Notre site vous offre une plateforme complète pour répondre à vos besoins en matière de santé et de soins.
                            <div className=" w-full p-2 flex justify-end ">

                                <Link to="/Visit/s'inscrire" className="flex hidden bg-blue-400 relative hidden mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">
                                    <i class="bi text-black bi-hand-index-thumb-fill absolute indicator1"></i>
                                    S'inscrire
                                </Link>
                            </div>            </p>
                    </div>
                </div>
            </div>



        </>
    )
}

export default SanteHomeVisitor


