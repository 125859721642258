/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import NaVbarsOnglet from "./NaVbarsOnglet";
import NavBarsOngletMobille from "./NavBarsOngletMobille";
import { openChat } from "../../../TawkTo";
import { useDispatch, useSelector } from "react-redux";
import { DELETE } from "../../../../Redux/Action/Action";
import { BarLoader } from 'react-spinners'

function NavBarMarketUsers({ isdivScroll, SizeMobile, getMobille1, getSrollowerSize }) {

  const [imageLoading2, SetImageLoading2] = useState(true)
  const { pathname } = useLocation()
  const [menuClicked1, setMenuClicked1] = useState(false)
  const [BouttonLogin, setBouttonLogin] = useState(false)
  const [isMobile, SetMobile] = useState(window.innerWidth < 570.97364448849393)
  const [isMobileTros, SetMobileTros] = useState(window.innerWidth < 292.97364448849393)
  const [tropTail, SettropTail] = useState(window.innerWidth < 414.97364448849393)

  const [activeIndex, setActiveIndex] = useState(null);
  const [showManue, setshowManue] = useState(false)

  const dropdownItems = [
    { text: 'Compte', link: '/User/Profile' },
    { text: 'Se déconnecter', link: '#' }
  ];











  const [imageLoading, SetImageLoading] = useState(true)
  const [imageLoading1, SetImageLoading1] = useState(true)





  const history = useNavigate();

  const [UserDrop, SetUserDrop] = useState(false);

  function Deconnection(links) {
    if (links === '#') {
      localStorage.removeItem("t");
      localStorage.removeItem("userid");
      localStorage.removeItem("userRole");
      history("/");
      window.location.reload();
    }
  }

  const id = localStorage.getItem("userid");
  const [user, SetUser] = useState({});
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(` https://btrproject.burundientempsreel.com/user/Byid/${id}`).then((response) => {
      SetUser(response.data);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 100);
      return () => clearTimeout(timer);

    }).catch((err) => {
      console.log(err.message);
    });
  }, []);



  const hundleTawkto = () => {
    openChat()
  }

  const url = window.location.href;
  const urlObject = new URL(url);
  const chemin = urlObject.pathname;
  const panierVentes = /^\/User\/Ventes.*/;
  const panierBonus = /^\/User\/Bonus.*/;




  const [price, setPrice] = useState(0);
  const getData = useSelector((state) => state.CartsReducer.Carts);
  const [isClicked, setIsClicked] = useState(false);
  const [dropmenu, setdropmenu] = useState(false);

  getMobille1(isMobile)

  useEffect(() => {
    const hiddenOnget = () => {
      setshowManue(false)
      setBouttonLogin(false)
      SetUserDrop(false)
      setMenuClicked1(false)
    };


    function hundleScrolls(e) {

      setBouttonLogin(false)
      SetUserDrop(false)
      setMenuClicked1(false)
      getSrollowerSize(e.target.scrollTop)


    }

    const hiddenresize = () => {
      SetMobile(window.innerWidth < 555.90292837373)
      SetMobile(window.innerWidth < 573.97364448849393)
      SettropTail(window.innerWidth < 414.97364448849393)
      SetMobileTros(window.innerWidth < 292.97364448849393)

      SetMobile(window.innerWidth < 570.97364448849393)
      SettropTail(window.innerWidth < 414.97364448849393)
      SetMobileTros(window.innerWidth < 292.97364448849393)

      SetMobile(window.innerWidth < 570.97364448849393)
      SettropTail(window.innerWidth < 414.97364448849393)
      SetMobileTros(window.innerWidth < 328.97364448849393)
      SetMobile(window.innerWidth < 555.90292837373)
    }




    window.addEventListener('click', hiddenOnget);
    window.addEventListener('resize', hiddenresize);

    if (isdivScroll.current) {
      isdivScroll.current.addEventListener('scroll', hundleScrolls);

    }

    return () => {
      window.removeEventListener('click', hiddenOnget);
      window.removeEventListener('resize', hiddenresize);

      if (isdivScroll.current) {
        isdivScroll.current.removeEventListener('scroll', hundleScrolls);
      }
    };
  }, []);

























  function close() {
    setIsClicked(!isClicked);
  }

  const dispatch = useDispatch();

  const dlt = (id) => {
    dispatch(DELETE(id));
  };

  const total = () => {
    let prices = 0;
    getData.map((ele) => {
      prices = Math.trunc(ele.price) * 1000 * ele.quantity + prices;
    });
    setPrice(Math.floor(prices).toLocaleString("fr-FR"));
  };
  useEffect(() => {
    total();
  }, [total]);






  useEffect(() => {
    axios.get(` https://btrproject.burundientempsreel.com/user/Byid/${id}`).then((response) => {
      SetUser(response.data);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 100);
      return () => clearTimeout(timer);
    });
  }, []);


  return (
    <div className={` ${isMobile ? 'h-[10vh] bg-gray-300 border-b-2 border-white' : 'h-[20vh] bg-gray-300  rounded-b-2xl border-b-2 border-blue-500'} `}>
      <NavBarsOngletMobille showManue={showManue} />
      <div className="flex  h-full w-full flex-col ">
        <div className={`${isMobile ? 'h-full' : 'h-1/2 '}  flex border-b-2 items-center`} >
          <div className="flex items-center sm:w-[100%]  w-[70em]  ">
            <div className="h-[4em] w-[4em] transition-all relative overflow-hidden ml-3 rounded">
              {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover scale-[5]" /></div>}
              <img draggable='false' onLoad={() => {
                setTimeout(() => {
                  SetImageLoading(false);
                }, 1000);
              }} src={` https://btrproject.burundientempsreel.com/uploads/logo/btr.png`}
                alt="" className='h-full w-full object-cover object-center' />
            </div>
            <div className="ml-4 hiddenName">Burundi en temp reel</div>
          </div>
          <div className=" w-[100%] mr-2  h-full flex justify-end ">
            <nav class=" rounded-t-xl md:rounded-none w-max   flex  justify-between items-center  pb-1   scale-y-1">
              <i onClick={hundleTawkto} class="bi  bi-chat-dots-fill text-[1.5em] mr-3 cursor-pointer font-bold "></i>
              {getData.length ? (
                <div className=" mt-2 p-1 text-sm text-center    text-gray-700 rounded-full md:mt-2 md:ml-4 relative items-center ">
                  <span className=" absolute z-10 hover:scale-110 right-0 top-2  w-[20px] h-[20px] rounded-[500%] flex justify-center items-center text-white  bg-blue-800 text-[10px]">
                    {getData.length}
                  </span>












                  {panierVentes.test(chemin) ? (
                    <>
                      <div onClick={() => { history('/User/Ventes/Carts') }}
                        className={`relative hover:scale-110 cursor-pointer  font-bold   p-2`}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-cart4" viewBox="0 0 16 16">
                          <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                        </svg>
                      </div>
                    </>
                  ) : panierBonus.test(chemin) ? (
                    <>
                      <div onClick={() => { history('/User/Bonus/Carts') }}
                        className={`relative hover:scale-110 cursor-pointer  font-bold   p-2`}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-cart4" viewBox="0 0 16 16">
                          <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                        </svg>
                      </div>
                    </>
                  ) : (
                    <>
                      <>
                        <div onClick={() => { history('/User/Ventes/Carts') }}
                          className={`relative hover:scale-110 cursor-pointer  font-bold   p-2`}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-cart4" viewBox="0 0 16 16">
                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                          </svg>
                        </div>
                      </>
                    </>
                  )
                  }




                  <div
                    className={`transition-all duration-1 ease-linear ${isClicked
                      ? "opacity-500 pointer-events-auto "
                      : "opacity-0 pointer-events-none"
                      } z-[500] w-[max-content] px-2 py-2  h-[max-content] rounded bg-cream border border-gray-300  shadow-xl absolute right-0 `}
                  >
                    <div
                      onClick={close}
                      className=" text-black bi bi-x  left-[88%] cursor-pointer relative text-1xl w-[max-content]"
                    />
                    <div className="text-black p-2.5 flex flex-col w-[max-content] h-[max-content]">



                    </div>
                  </div>
                </div>
              ) : (
                <div className=" mt-2 p-1 text-sm text-center   pointer-events-none  text-gray-700 rounded-full md:mt-2 md:ml-4">
                  <span className=" absolute z-10 right-0 top-2  w-[20px] h-[20px] rounded-[500%]  hidden  bg-blue-800 text-[10px]" />
                  <div
                    className={`relative cursor-pointer hover:scale-110 text-gray-500  text-[2em] font-bold bi bi-cart4  p-2`}
                  >
                  </div>
                  <div
                    className={`transition-all duration-1 ease-linear ${isClicked
                      ? "opacity-500 pointer-events-auto "
                      : "opacity-0 pointer-events-none"
                      } z-[500] w-[max-content] px-2 py-2 rounded h-[max-content] bg-teal-50 shadow-md absolute right-0 `}
                  >
                    <div
                      onClick={close}
                      className=" text-black bi bi-x  left-[88%] cursor-pointer relative text-1xl w-[max-content]"
                    />
                    <p className="text-black p-2.5">Votre pannier est vide !!</p>
                  </div>
                </div>
              )}

              <button onClick={(e) => { SetUserDrop(!UserDrop); e.stopPropagation() }}
                class="  md:w-auto mt-2   md:ml-4 md:flex items-center justify-center text-right"
              >
                <div>
                  <Link class=" overflow-hidden flex justify-centerm items-center relative"                                        >
                    {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover scale-[5]" /></div>}

                    <img draggable='false' onLoad={() => {
                      setTimeout(() => {
                        SetImageLoading1(false);
                      }, 1000);
                    }}

                      className="inline-block h-8 w-8 md:h-10 md:w-10 border object-cover border-blue-500 rounded-full"
                      src={` https://btrproject.burundientempsreel.com/uploads/photosuser/${user.photo}`}
                      alt=""

                    />
                  </Link>
                </div>
                <div class="hidden md:flex  md:items-center  ml-4">
                  <div className="hidden mr-3 md:flex md:items-center ml-4">
                    {user.nom && user.prenom ? (
                      <>
                        <span className="text-gray-700 text-sm mr-2">
                          {user.nom} {user.prenom}
                        </span>
                        <div>
                          <i className="bi bi-caret-down-fill"></i>
                        </div>
                      </>
                    ) : (
                      <>
                        <BarLoader color="blue" width={50} height={2} />
                      </>
                    )}
                  </div>
                </div>
              </button>
            </nav>
            {UserDrop ? (
              <div
                className="md:block z-[1000] absolute overflow-hidden p-2 right-5 top-16 mt-2 w-48 rounded bg-gray-100 border border-gray-300 shadow-xl"
              >
                {dropdownItems.map((item, index) => (
                  <Link
                    key={index}
                    to={item.link}
                    className={`block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white w-full text-left ${index === activeIndex ? 'bg-blue-600 text-white' : ''}`}
                    onClick={() => {
                      setActiveIndex(index); Deconnection(item.link)
                    }}
                  >
                    {item.text}
                  </Link>
                ))}
              </div>

            ) : null}
          </div>
          <div className={` ${!isMobile ? 'hidden' : 'flex'} bg-gray-200 w-[40px] hover:bg-black hover:text-white transition-all p-2 h-[35px]  justify-center items-center cursor-pointer rounded mr-2`}>
            <div onClick={(e) => { setshowManue(!showManue); e.stopPropagation() }}><i class={` ${showManue ? 'bi bi-x transition-all duration-700' : 'bi bi-list transition-all duration-700'}  text-[22px] font-bold`}></i></div>

          </div>
        </div>
        <NaVbarsOnglet />
      </div>
    </div>
  );
}

export default NavBarMarketUsers;







