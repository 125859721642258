import React from 'react'
import MarketList from './MarketList'

function ManageOrderMarketComponent() {
  return (
    <>

      <div class="p-2">
        <MarketList />
      </div>
    </>
  )
}

export default ManageOrderMarketComponent
