import React from 'react'
import StandByVoirPlus from './StandByVoirPlus'
function StandByVoirPlusCompomemt() {
  return (
    <>
      <StandByVoirPlus />
    </>
  )
}

export default StandByVoirPlusCompomemt