/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
function Service() {
    const [imageLoading, SetImageLoading] = useState(true)
    const [imageLoading1, SetImageLoading1] = useState(true)
    const [imageLoading2, SetImageLoading2] = useState(true)
    const [imageLoading3, SetImageLoading3] = useState(true)
    const [imageLoading4, SetImageLoading4] = useState(true)
    const [imageLoading5, SetImageLoading5] = useState(true)
    const [imageLoading6, SetImageLoading6] = useState(true)
    const [imageLoading7, SetImageLoading7] = useState(true)
    const [imageLoading8, SetImageLoading8] = useState(true)
    const [imageLoading9, SetImageLoading9] = useState(true)

    const [sizeInfer, SetsizeInfer] = useState(window.innerWidth < 650)
    const [sizeInfer1, SetsizeInfer1] = useState(window.innerWidth < 650)
    const [sizeInfer2, SetsizeInfer2] = useState(window.innerWidth < 650)
    const [sizeInfer3, SetsizeInfer3] = useState(window.innerWidth < 650)
    const [sizeInfer4, SetsizeInfer4] = useState(window.innerWidth < 650)
    const [sizeInfer5, SetsizeInfer5] = useState(window.innerWidth < 650)
    const [sizeInfer6, SetsizeInfer6] = useState(window.innerWidth < 650)
    const [sizeInfer7, SetsizeInfer7] = useState(window.innerWidth < 650)
    const [sizeInfer8, SetsizeInfer8] = useState(window.innerWidth < 650)
    const [sizeInfer9, SetsizeInfer9] = useState(window.innerWidth < 650)

    useEffect(() => {
        const hundleSize = () => {
            SetsizeInfer(window.innerWidth < 650)
            SetsizeInfer1(window.innerWidth < 650)
            SetsizeInfer2(window.innerWidth < 650)
            SetsizeInfer3(window.innerWidth < 650)
            SetsizeInfer4(window.innerWidth < 650)
            SetsizeInfer5(window.innerWidth < 650)
            SetsizeInfer6(window.innerWidth < 650)
            SetsizeInfer7(window.innerWidth < 650)
            SetsizeInfer8(window.innerWidth < 650)
            SetsizeInfer9(window.innerWidth < 650)
        }

        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    },)


    return (
        <div className="p-2 h-max my-1 w-full flex flex-col justify-center ">

            <div className="w-full rounded-3xl h-max flex py-2   sm:px-4 justify-around responsi ">
                <div className="flex w-full  flex-wrap" >
                    <h2 className="my-4 w-full text-center  font-serif text-blue-600 text-[35px]">Notre mission</h2>
                    <div className="  bg-white rounded-[50px] h-max my-1 w-full flex justify-around  items-center">
                        <div className="w-full h-max flex p-2 justify-around items-start responsive1">
                            <div className=" w-[95%] sm:w-[40em] h-max p-1 rounded sm:ml-6  responsive11">
                                <p className="text-gray-500 p-1 text-[25px] reduitTailParagr ">
                                    Explorez notre option éducative complète,
                                    dédiée à l'apprentissage pour tous
                                    ! Bénéficiez d'une éducation accessible
                                    grâce à des cours en ligne de qualité,
                                    des livres d'experts renommés et des concours
                                    déjà réalisés avec des exemples d'exercices.
                                    Rejoignez notre communauté d'apprentissage et partagez vos
                                    connaissances avec d'autres passionnés.<br />
                                    <br />

                                </p>

                                <div className=" w-full p-2 flex justify-end">
                                    <Link to="/Visit/Education" className="flex bg-blue-400 mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">Voir plus</Link>
                                </div>
                            </div>


                            <div className='w-full flex justify-center items-center flex-col'>
                                <h2 className='text-[27px] font-serif w-full text-center sm:text-left text-blue-600 textSizeTitre'>Education</h2>

                                <div className={` ${imageLoading && sizeInfer ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                                    {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                    <img draggable='false' onLoad={() => {
                                        setTimeout(() => {
                                            SetImageLoading(false)
                                        }, 1000)
                                    }} src="../../image/Coursvideoetbibliotheque.png" alt="Logo" className="w-full h-full object-cover" />
                                    <h2 className=" rotations font-serif">Education</h2>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="  bg-white rounded-[50px] h-max my-1 w-full flex justify-around  items-center">
                        <div className="w-full h-max flex p-2 justify-around items-center responsi">
                            <div>
                                <h2 className='text-[27px] font-serif w-full text-center  text-blue-600'>Echange</h2>

                                <div className={` ${imageLoading1 && sizeInfer1 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                                    {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                    <img draggable='false' onLoad={() => {
                                        setTimeout(() => {
                                            SetImageLoading1(false)
                                        }, 1000)
                                    }} src="../../image/exchangepremier.png" alt="Logo" className="w-full h-full" />
                                    <span className=" rotations font-serif">Echange</span>
                                </div>
                            </div>
                            <div className=" sm:w-[40em] h-max p-1 rounded sm:ml-6 responsive11">
                                <p className="text-gray-500 p-1 text-[25px] reduitTailParagr ">
                                    Transformez votre expérience de transfert d'argent
                                    au Burundi avec notre service de super agent !
                                    Simplifiez vos transactions financières en échangeant de l'argent
                                    électronique en toute rapidité et sécurité. Libérez-vous des tracas
                                    liés aux transactions en espèces grâce à notre solution pratique et sécurisée.
                                    Profitez d'une connectivité sans faille avec Lumicash, Echocash, Mobinetel,
                                    CCM Akaravyo et bien plus encore.

                                </p>
                                <div className=" w-full p-2 flex justify-center">
                                    <Link to="/Visit/Exchange" className="flex hover:bg-transparent hover:text-gray-700  border hover:border-blue-700 rounded-2xl font-serif mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1   bg-blue-600">Commencez</Link>
                                </div>
                            </div>

                        </div>
                    </div>



                    <div className=" bg-white rounded-[50px] h-max  w-full flex justify-around my-3  items-center ">
                        <div className="  w-full h-max flex p-2 justify-around items-center responsive1">
                            <div className=" sm:w-[40em] h-max p-1 rounded sm:ml-6 responsive11">
                                <p className="text-gray-500 p-1 text-[25px] reduitTailParagr ">
                                    Découvrez une multitude
                                    d'activités de loisirs passionnantes
                                    sur notre plateforme de réservation en ligne !
                                    Profitez de liens sportifs dynamiques tels que le tennis,
                                    la gymnastique, les massages revigorants et le tourisme captivant.
                                    Plongez dans l'ambiance des concerts vibrants et détendez-vous dans
                                    des piscines rafraîchissantes. Notre plateforme regorge de choix pour
                                    répondre à tous les goûts et vous offrir des expériences inoubliables.


                                </p>

                                <div className=" w-full p-2 flex justify-end">
                                    <Link to="/Visit/Loisir" className="flex bg-blue-400 mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">Voir plus</Link>
                                </div>
                            </div>
                            <div>
                                <h2 className='text-[27px] font-serif w-full text-center  text-blue-600'>Loisir</h2>

                                <div className={` ${imageLoading2 && sizeInfer2 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                                    {imageLoading2 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                    <img draggable='false' onLoad={() => {
                                        setTimeout(() => {
                                            SetImageLoading2(false)
                                        }, 1000)
                                    }} src="../../image/LOISIR.png" alt="Logo" className="w-full h-full" />
                                    <span className=" rotations font-serif">Loisir</span>
                                </div>
                            </div>
                        </div>
                    </div>





                    <div className=" bg-white rounded-[50px] h-max  w-full flex justify-around my-3  items-center ">
                        <div className="  w-full h-max flex p-2 justify-around items-center responsi">
                            <div>
                                <h2 className='text-[27px] font-serif w-full text-center  text-blue-600'>Sante</h2>

                                <div className={` ${imageLoading && sizeInfer3 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                                    {imageLoading3 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                    <img draggable='false' onLoad={() => {
                                        setTimeout(() => {
                                            SetImageLoading3(false)
                                        }, 1000)
                                    }} src="../../image/sante_avec_docteur.png" alt="Logo" className="w-full h-full" />
                                    <span className=" rotations font-serif"></span>
                                </div>
                            </div>
                            <div className=" sm:w-[40em] h-max p-1 rounded sm:ml-6 responsive11">
                                <p className="text-gray-500 p-1 text-[25px] reduitTailParagr ">
                                    Profitez de notre plateforme complète qui offre une gamme
                                    étendue de services de santé et de bien-être. En partenariat avec des pharmacies,
                                    des hôpitaux privés, des cabinets médicaux et des praticiens traditionnels,
                                    nous vous offrons un accès facile à des soins de qualité. Bénéficiez de
                                    consultations en ligne avec des médecins traditionnels et de la possibilité de
                                    commander des produits pharmaceutiques en ligne. <br />


                                </p>
                                <div className=" w-full p-2 flex justify-end">
                                    <Link to="/Visit/Sante" className="flex bg-blue-400 mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">Voir plus</Link>
                                </div>
                            </div>

                        </div>
                    </div>



                    <div className=" bg-white rounded-[50px] h-max  w-full flex justify-around my-3  items-center ">
                        <div className="  w-full h-max flex p-2 justify-around items-center responsive1">
                            <div className=" sm:w-[40em] h-max p-1 rounded sm:ml-6 responsive11">
                                <p className="text-gray-500 p-1 text-[25px] reduitTailParagr ">
                                    Notre équipe expérimentée propose une gamme complète de services de développement,
                                    adaptés à tous. Nous concevons et développons des sites web, des applications web,
                                    des applications mobiles et des applications de bureau. Nous sommes également spécialisés dans
                                    l'Internet des objets (IoT), l'intelligence artificielle (IA) et le machine learning
                                    (apprentissage automatique).<br /><br></br> En outre, nous offrons des services d'administration réseau
                                    et de gestion de bases de données.
                                </p>
                                <div className=" w-full p-2 flex justify-end">
                                    <Link to="/Visit/Programmation" className="flex bg-blue-400 mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">Voir plus</Link>
                                </div>
                            </div>
                            <div>
                                <h2 className='text-[27px] font-serif w-full text-center  text-blue-600'>Programmation</h2>

                                <div className={` ${imageLoading4 && sizeInfer4 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                                    {imageLoading4 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                    <img draggable='false' onLoad={() => {
                                        setTimeout(() => {
                                            SetImageLoading4(false)
                                        }, 1000)
                                    }} src="../../image/programming.png" alt="Logo" className="w-full h-full" />
                                    <span className=" rotations font-serif">Programmation</span>
                                </div>
                            </div>
                        </div>
                    </div>






                    <div className=" bg-white rounded-[50px] h-max  w-full flex justify-around my-3  items-center ">
                        <div className="  w-full h-max flex p-2 justify-around items-center responsi">
                            <div>
                                <h2 className='text-[27px] font-serif w-full text-center  text-blue-600'>Transport</h2>

                                <div className={` ${imageLoading5 && sizeInfer5 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                                    {imageLoading5 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                    <img draggable='false' onLoad={() => {
                                        setTimeout(() => {
                                            SetImageLoading5(false)
                                        }, 1000)
                                    }} src="../../image/transport.png" alt="Logo" className="w-full h-full" />
                                    <span className=" rotations font-serif">Transport</span>
                                </div>
                            </div>
                            <div className=" sm:w-[40em] h-max p-1 rounded sm:ml-6 responsive11">
                                <p className="text-gray-500 p-1 text-[25px] reduitTailParagr ">
                                    Facilitez vos déplacements au Burundi grâce à notre partenariat avec les agences de transport locales.
                                    Réservez en ligne vos billets d'avion, de train ou de bus auprès de nos agences partenaires,
                                    offrant un service fiable et pratique. Profitez de la possibilité de louer des voitures pour
                                    vos événements spéciaux, avec un large choix de véhicules disponibles.<br /><br />
                                </p>
                                <div className=" w-full p-2 flex justify-end">
                                    <Link to="/Visit/Transport" className="flex bg-blue-400 mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">Voir plus</Link>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className=" bg-white rounded-[50px] h-max my-10 w-full flex justify-around  items-center">
                        <div className="  w-full h-max flex p-2 justify-around items-center responsive1">

                            <div className=" sm:w-[40em] h-max p-1 rounded sm:ml-6 responsive11">
                                <p className="text-gray-500 p-1 text-[25px] reduitTailParagr ">
                                    Si vous êtes un visiteur et que vous souhaitez accéder à l'option de marche, vous êtes au bon endroit. Nous vous offrons la possibilité de découvrir notre sélection de produits et de faire vos achats en toute simplicité. Que vous recherchiez des articles de mode, des équipements sportifs ou tout autre produit, nous avons ce qu'il vous faut.
                                    <br /><br></br> Explorez notre site et parcourez nos différentes catégories pour trouver les articles qui vous intéressent. Lorsque vous avez fait votre choix, il vous suffit de sélectionner l'option de marche pour ajouter les produits à votre panier. Nous nous assurons de vous offrir une expérience de navigation fluide et agréable.

                                </p>
                                <div className=" w-full p-2 flex justify-end">
                                    <Link to="/Visit/Marche" className="flex bg-blue-400 mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">Voir plus</Link>
                                </div>
                            </div>
                            <div>
                                <h2 className='text-[27px] font-serif w-full text-center  text-blue-600'>Marche</h2>

                                <div className={` ${imageLoading6 && sizeInfer6 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                                    {imageLoading6 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                    <img draggable='false' onLoad={() => {
                                        setTimeout(() => {
                                            SetImageLoading6(false)
                                        }, 1000)
                                    }} src="../../image/acceuilmarket.png" alt="Logo" className="w-full h-full" />
                                    <span className=" rotations font-serif">Marche</span>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div className=" bg-white rounded-[50px] h-max  w-full flex justify-around my-3  items-center ">
                        <div className="  w-full h-max flex p-2 justify-around items-center responsi">
                            <div>
                                <h2 className='text-[27px] font-serif w-full text-center  text-blue-600'>Reservation</h2>

                                <div className={` ${imageLoading7 && sizeInfer7 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                                    {imageLoading7 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                    <img draggable='false' onLoad={() => {
                                        setTimeout(() => {
                                            SetImageLoading7(false)
                                        }, 1000)
                                    }} src="../../image/reservationenligne.png" alt="" className="w-full h-full" />
                                    <span className=" rotations font-serif">Reservation</span>
                                </div>
                            </div>


                            <div className=" sm:w-[40em] h-max p-1 rounded sm:ml-6 responsive11">
                                <p className="text-gray-500 p-1 text-[25px] reduitTailParagr ">
                                    Bienvenue sur notre site de réservation en ligne ! Nous sommes ravis que vous ayez choisi notre service pour effectuer vos réservations. Grâce à notre plateforme conviviale et sécurisée, vous pouvez réserver vos billets, chambres d'hôtel, tables de restaurant et bien plus encore, en quelques clics.


                                    Notre système de réservation en ligne vous permet de gagner du temps et de profiter de nombreux avantages.
                                </p>
                                <div className=" w-full p-2 flex justify-end">
                                    <Link to="/Visit/Reservation" className="flex bg-blue-400 mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">Voir plus</Link>
                                </div>
                            </div>

                        </div>
                    </div>



                    <div className="bg-white rounded-[50px] h-max my-10 w-full flex justify-around  items-center">
                        <div className="  w-full h-max flex p-2 justify-around items-center responsive1">

                            <div className=" sm:w-[40em] h-max p-1 rounded sm:ml-6 responsive11">
                                <p className="text-gray-500 p-1 text-[25px] reduitTailParagr ">
                                    À la recherche d'un mentor pour vous guider dans votre parcours professionnel ou personnel ?
                                    Rejoignez notre plateforme et bénéficiez d'un accès à un réseau de mentors expérimentés.
                                    Que vous ayez besoin de conseils en entrepreneuriat,
                                    de développement personnel ou de guidance dans votre carrière, nos mentors qualifiés sont
                                    là pour vous accompagner. <br /><br />Profitez de leurs connaissances approfondies et de leur expertise pour
                                    atteindre vos objectifs et maximiser votre potentiel. </p>
                                <div className=" w-full p-2 flex justify-end">
                                    <Link to="/Visit/Mentors" className="flex bg-blue-400 mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">Voir plus</Link>
                                </div>
                            </div>
                            <div>
                                <h2 className='text-[27px] font-serif w-full text-center  text-blue-600'>Mentors</h2>

                                <div className={` ${imageLoading8 && sizeInfer8 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                                    {imageLoading8 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                    <img draggable='false' onLoad={() => {
                                        setTimeout(() => {
                                            SetImageLoading8(false)
                                        }, 1000)
                                    }} src="../../image/collaboration.png" alt="Logo" className="w-full h-full" />
                                    <span className=" rotations font-serif">Mentors</span>
                                </div>
                            </div>
                        </div>
                    </div>





                    <div className=" bg-white rounded-[50px] h-max  w-full flex justify-around my-3  items-center ">
                        <div className="  w-full h-max flex p-2 justify-around items-center responsi">
                            <div>
                                <h2 className='text-[27px] font-serif w-full text-center  text-blue-600'>Agriculture et Elev</h2>

                                <div className={` ${imageLoading9 && sizeInfer9 ? '' : ''} animeTop  flex   relative  w-[30em] h-[30em] bg-gray-200   rounded-[20px] PhotosSize  overflow-hidden responsive1`}>
                                    {imageLoading9 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                    <img draggable='false' onLoad={() => {
                                        setTimeout(() => {
                                            SetImageLoading9(false)
                                        }, 1000)
                                    }} src="../../image/agricultur_elevage.png" alt="Logo" className="w-full h-full" />
                                    <span className=" rotations font-serif">Agriculture et Elev</span>
                                </div>
                            </div>
                            <div className=" sm:w-[40em] h-max p-1 rounded sm:ml-6 responsive11">
                                <p className="text-gray-500 p-1 text-[25px] reduitTailParagr ">
                                    Simplifiez votre activité agricole et d'élevage grâce à notre plateforme complète.
                                    Recevez des conseils précieux ou posez vos questions à nos experts.<br /><br /> Consultez les
                                    marchés des produits agricoles et des produits d'élevage pour prendre des décisions
                                    éclairées. Notre plateforme vous offre une solution pratique pour améliorer votre activité
                                    et maximiser vos rendements. Rejoignez-nous dès maintenant et bénéficiez des avantages
                                    de notre plateforme
                                    agricole et d'élevage pour optimiser vos opérations et développer votre entreprise.
                                    réseau de partenaires de confiance dans le domaine du transport au Burundi.
                                </p>
                                <div className=" w-full p-2 flex justify-end">
                                    <Link to="/Visit/Agri_Ele" className="flex bg-blue-400 mr-20 transition-all text-white px-4 py-1 justify-center w-max my-1  rounded hover:bg-blue-600">Voir plus</Link>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Service
