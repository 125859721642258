import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function QuestionPropose({ recupValues, isdivScroll, SetrecupValues }) {
    const [showMotCequoiBTR, SetshowMotCequoiBTR] = useState(false)
    const [Education, SetEducation] = useState(false)
    const [vendeurs, Setvendeurs] = useState(false)
    const [echange, Setechange] = useState(false)
    const [reserver, Setreserver] = useState(false)
    const [loisir, Setloisir] = useState(false)
    const [sante, Setsante] = useState(false)
    const [program, Setprogram] = useState(false)
    const [transport, Settransport] = useState(false)
    const [mentors, Setmentors] = useState(false)
    const [agric, Setagric] = useState(false)

    return (
        <>
            <div className=' max-sm:w-full  sm:w-[90%] p-2 flex justify-center items-center h-max  '>
                <div className='bg-gray-50 shadow border border-blue-700 rounded-lg p-4'>
                    <p className='text-[35px] text-blue-700 w-full text-center font-serif max-sm:text-[20px]'>Questions fréquemment posées</p>
                    <div className='w-full'>


                        <div className='bg-gray-200 h-max m-4 rounded '>
                            <div onClick={(e) => { Setagric(false); Setmentors(false); Settransport(false); Setprogram(false); Setsante(false); Setloisir(false); Setreserver(false); Setvendeurs(false); Setechange(false); SetshowMotCequoiBTR(!showMotCequoiBTR); SetEducation(false); e.stopPropagation() }} className='flex relative cursor-pointer h-max   justify-start pl-3  pr-4 items-center  py-4    md:text-[30px]  text-[22px]'>
                                <p className='text-center   max-sm:text-[20px] question'> C'est quoi le Burundi en temps réel ?</p>
                                <i class={`bi ${showMotCequoiBTR ? 'rotate-90' : 'rotate-0'} absolute right-2 transition-all  bi-arrow-right-circle-fill text-[20px] ml-4`}></i>
                            </div>
                            <div className={`${!showMotCequoiBTR ? 'opacity-0 h-0 overflow-hidden py-0 reduitTailParagr' : 'opacity-100 h-max py-2'} px-2 transition-all  bg-white   text-gray-700 text-[20px] max-sm:text-[18px] font-serif`} >
                                Burundi en temps réel est l'entreprise
                                spécialisée dans le développement de solutions
                                web personnalisées offre une gamme complète de services comprenant
                                la création de sites web sur mesure avec des designs personnalisés,
                                le développement d'applications web, mobiles et de bureau,
                                ainsi que les services d'optimisation et de maintenance de sites existants.
                                <br /><br />
                                Notre  équipe de développeurs expérimentés s'efforce de fournir des solutions web de haute qualité pour aider les clients à atteindre leurs objectifs de développement web.
                                <br />
                                <br />
                                Chez nous, nous offrons un service complet d'hébergement de sites web pour répondre à vos besoins en ligne. L'hébergement de site web est essentiel pour mettre votre présence en ligne en action. Imaginez-le comme un emplacement sécurisé où votre site web réside, accessible à tout moment pour vos visiteurs du monde entier.
                                <br /><br />
                                Notre service d'hébergement de sites web comprend tout ce dont vous avez besoin pour rendre votre site web opérationnel et fonctionnel. Nous mettons à disposition des serveurs puissants et fiables qui hébergent vos fichiers, images et données. Que vous soyez un particulier, une petite entreprise ou une grande entreprise, notre offre d'hébergement s'adapte à vos besoins.
                                <br /><br />
                                Lorsque vous choisissez notre service d'hébergement, vous bénéficiez de la tranquillité d'esprit en sachant que votre site est entre de bonnes mains. Nous prenons en charge les aspects techniques complexes tels que la maintenance du serveur, les mises à jour de sécurité et la sauvegarde régulière de vos données. Vous pouvez vous concentrer sur la création de contenu et le développement de votre activité en ligne.

                            </div>
                        </div>



                        <div className='bg-gray-200 h-max m-4 rounded'>
                            <div onClick={(e) => { Setagric(false); Setmentors(false); Settransport(false); Setprogram(false); Setsante(false); Setloisir(false); Setreserver(false); SetEducation(!Education); Setechange(false); SetshowMotCequoiBTR(false); Setvendeurs(false); e.stopPropagation() }} className='flex relative cursor-pointer h-max   px-6 items-center py-4  md:text-[30px]  text-[22px] '>
                                <p className='text-center   max-sm:text-[20px]'> Quels sont les services éducatives ?</p>
                                <i class={`bi ${Education ? 'rotate-90' : 'rotate-0'} absolute right-2 transition-all  bi-arrow-right-circle-fill text-[20px] ml-4`}></i>
                            </div>
                            <div className={`${!Education ? 'opacity-0 h-0 overflow-hidden py-0 ' : 'opacity-100 h-max py-2'}px-2 transition-all  bg-white   text-gray-700 text-[20px] max-sm:text-[18px] font-serif`} >

                                La société Burundi en temps réel offre des ressources et des outils éducatifs variés pour
                                personnaliser votre parcours d'apprentissage. Rejoignez notre communauté
                                passionnée où l'échange d'idées et l'apprentissage collectif sont valorisés.
                                <br /><br />
                                Bienvenue dans notre application dédiée à l'éducation pour tous, où nous sommes
                                là pour vous soutenir et vous encourager à atteindre votre plein potentiel.
                            </div>

                        </div>



                        <div className='bg-gray-200 h-max m-4 rounded'>
                            <div onClick={(e) => { Setagric(false); Setmentors(false); Settransport(false); Setprogram(false); Setsante(false); Setloisir(false); Setreserver(false); Setvendeurs(!vendeurs); Setechange(false); SetEducation(false); SetshowMotCequoiBTR(false); e.stopPropagation() }} className='flex relative cursor-pointer h-max   px-6 items-center py-4  md:text-[30px]  text-[22px] '>
                                <p className='text-center   max-sm:text-[20px]'> Quels sont les services pour les vendeurs ?</p>
                                <i class={`bi ${vendeurs ? 'rotate-90' : 'rotate-0'} absolute right-2 transition-all  bi-arrow-right-circle-fill text-[20px] ml-4`}></i>
                            </div>
                            <div className={`${!vendeurs ? 'opacity-0 h-0 overflow-hidden py-0 ' : 'opacity-100 h-max py-2'}px-2 transition-all  bg-white   text-gray-700 text-[20px] max-sm:text-[18px] font-serif`} >


                                Lorsque vous décidez de devenir vendeur, nous vous demanderons quelques informations essentielles. Tout d'abord, veuillez fournir votre nom complet, votre adresse email et l'adresse physique de votre magasin. Cela nous permettra de mieux vous connaître et de vous contacter facilement.
                                <br />
                                <br />

                                Pour assurer la sécurité de notre plateforme et de nos utilisateurs, nous devons vérifier l'authenticité de chaque vendeur. Ainsi, nous vous demanderons de télécharger une copie de votre pièce d'identité. Cela permettra de confirmer votre identité en toute confidentialité. De plus, nous vous encourageons à partager des photos de votre magasin, car cela nous aidera à mieux comprendre votre activité.
                                <br />
                                <br />
                                Si vous possédez un Numéro d'Identification Fiscale (NIF), veuillez le fournir. Cela peut être nécessaire pour des raisons fiscales. De plus, indiquez-nous le nom de votre magasin. C'est grâce à ce nom que les clients vous identifieront et vous reconnaîtront.

                                Nous prenons la sécurité et la confidentialité de vos informations très au sérieux. Toutes les informations que vous partagez avec nous seront utilisées uniquement à des fins de vérification et de soutien à votre activité en tant que vendeur.
                                <br /><br />
                                Une fois que vous aurez soumis ces informations, notre équipe se chargera de les examiner rapidement. Vous serez informé de l'approbation et vous pourrez commencer à vendre sur notre plateforme. Si vous avez des questions ou des préoccupations à tout moment, notre équipe d'assistance est à votre disposition pour vous aider.
                            </div>

                        </div>



                        <div className='bg-gray-200 h-max m-4 rounded'>
                            <div onClick={(e) => { Setagric(false); Setmentors(false); Settransport(false); Setprogram(false); Setsante(false); Setloisir(false); Setreserver(false); Setechange(!echange); Setvendeurs(false); SetEducation(false); SetshowMotCequoiBTR(false); e.stopPropagation() }} className='flex relative cursor-pointer h-max   px-6 items-center py-4  md:text-[30px]  text-[22px] '>
                                <p className='text-center   max-sm:text-[20px]'> Quels sont les services pour les échangeurs ?</p>
                                <i class={`bi ${echange ? 'rotate-90' : 'rotate-0'} absolute right-2 transition-all  bi-arrow-right-circle-fill text-[20px] ml-4`}></i>
                            </div>
                            <div className={`${!echange ? 'opacity-0 h-0 overflow-hidden py-0 ' : 'opacity-100 h-max py-2'}px-2 transition-all  bg-white   text-gray-700 text-[20px] max-sm:text-[18px] font-serif`} >
                                La société Burundi en temps réel met l'accent sur la sécurité et la confidentialité de vos transactions.
                                Nous avons mis en place des mesures de sécurité avancées pour protéger vos données et
                                garantir des échanges fiables et sans souci.

                                <br />
                                <br />
                                Bienvenue sur notre plateforme dédiée aux services de transfert d'argent électronique tels que Lumicash, Ecocash, Mobinetel, CCM Akaravyo et bien d'autres. Nous sommes ravis de vous accueillir ici pour vous permettre de créer un compte et profiter de tous les avantages de notre système de monnaie électronique.

                            </div>
                        </div>

                        <div className='bg-gray-200 h-max m-4 rounded'>
                            <div onClick={(e) => { Setagric(false); Setmentors(false); Settransport(false); Setprogram(false); Setsante(false); Setloisir(false); Setreserver(!reserver); Setechange(false); Setvendeurs(false); SetEducation(false); SetshowMotCequoiBTR(false); e.stopPropagation() }} className='flex relative cursor-pointer h-max   px-6 items-center py-4  md:text-[30px]  text-[22px] '>
                                <p className='text-center   max-sm:text-[20px]'> Quels sont les services pour la reservation ?</p>
                                <i class={`bi ${reserver ? 'rotate-90' : 'rotate-0'} absolute right-2 transition-all  bi-arrow-right-circle-fill text-[20px] ml-4`}></i>
                            </div>
                            <div className={`${!reserver ? 'opacity-0 h-0 overflow-hidden py-0 ' : 'opacity-100 h-max py-2'}px-2 transition-all  bg-white   text-gray-700 text-[20px] max-sm:text-[18px] font-serif`} >
                                Ici, vous pouvez réserver facilement et rapidement
                                dans tous les domaines qui vous intéressent.<br />
                                Que vous soyez à la recherche :
                                <div className=' pl-4'>
                                    <br /> Hôtel pour votre
                                    prochain voyage <br /> Restaurant <br />Rendez-vous médicaux <br />Beauté et au bien-être,<br />Maintenance domicile,<br />Salle de conference et événement,<br />Sonolisation et club danse, <br />Décoration,<br />Cameraman,nous avons ce qu'il vous faut.
                                    <br /><br />
                                    Naviguez à travers
                                    notre large sélection d'options et trouvez celle qui correspond
                                    le mieux à vos besoins. Notre système de réservation en ligne est
                                    simple à utiliser et vous permet de réserver en quelques clics seulement.
                                    Vous bénéficierez ainsi d'un gain de temps précieux et pourrez vous
                                    concentrer sur l'essentiel
                                    : profiter pleinement de votre expérience.
                                </div>
                            </div>
                        </div>


                        <div className='bg-gray-200 h-max m-4 rounded'>
                            <div onClick={(e) => { Setagric(false); Setmentors(false); Settransport(false); Setprogram(false); Setsante(false); Setloisir(!loisir); Setreserver(false); Setechange(false); Setvendeurs(false); SetEducation(false); SetshowMotCequoiBTR(false); e.stopPropagation() }} className='flex relative cursor-pointer h-max   px-6 items-center py-4  md:text-[30px]  text-[22px] '>
                                <p className='text-center   max-sm:text-[20px]'> Quels sont les services pour les loisirs?</p>
                                <i class={`bi ${loisir ? 'rotate-90' : 'rotate-0'} absolute right-2 transition-all  bi-arrow-right-circle-fill text-[20px] ml-4`}></i>
                            </div>
                            <div className={`${!loisir ? 'opacity-0 h-0 overflow-hidden py-0 ' : 'opacity-100 h-max py-2'}px-2 transition-all  bg-white   text-gray-700 text-[20px] max-sm:text-[18px] font-serif`} >
                                Notre site regorge d'informations sur les nombreux loisirs et attractions que le Burundi a à offrir.
                                Que vous soyez intéressé par:
                                <div className='pl-4'>
                                    <br /> la nature<br /> la culture<br />
                                    les sports<br />Massage ou Sonner<br />Touristique<br />Concert<br />Piscine <br />Studio<br />Montage Video et Film<br />Design<br />les événements artistiques ou les activités de plein air.<br /><br />Nous avons une multitude de suggestions pour vous. Découvrez les magnifiques paysages, parcourez les parcs nationaux, participez à des festivals colorés, explorez les sites historiques et goûtez aux saveurs locales. Nous mettons à votre disposition un guide complet des meilleures destinations touristiques, des activités incontournables et des événements à ne pas manquer. Que vous soyez en quête d'aventure, de détente ou de moments de partager. Nous vous invitons à parcourir notre site, à trouver l'inspiration et à planifier vos prochaines escapades au Burundi
                                </div>.<br /><br /> Laissez-vous séduire par
                                la diversité des loisirs offerts et créez des souvenirs inoubliables
                            </div>
                        </div>

                        <div className='bg-gray-200 h-max m-4 rounded'>
                            <div onClick={(e) => { Setagric(false); Setmentors(false); Settransport(false); Setprogram(false); Setsante(!sante); Setloisir(false); Setreserver(false); Setechange(false); Setvendeurs(false); SetEducation(false); SetshowMotCequoiBTR(false); e.stopPropagation() }} className='flex relative cursor-pointer h-max   px-6 items-center py-4  md:text-[30px]  text-[22px] '>
                                <p className='text-center   max-sm:text-[20px]'> Quels sont les services pour la sante ?</p>
                                <i class={`bi ${sante ? 'rotate-90' : 'rotate-0'} absolute right-2 transition-all  bi-arrow-right-circle-fill text-[20px] ml-4`}></i>
                            </div>
                            <div className={`${!sante ? 'opacity-0 h-0 overflow-hidden py-0 ' : 'opacity-100 h-max py-2'}px-2 transition-all  bg-white   text-gray-700 text-[20px] max-sm:text-[18px] font-serif`} >
                                Ici, vous trouverez une mine d'informations, des conseils pratiques et des ressources pour prendre soin de votre bien-être physique et mental.

                                Que vous cherchiez des conseils sur la nutrition, l'exercice physique, la gestion du stress, des informations sur les maladies courantes, ou des astuces pour adopter un mode de vie sain, vous êtes au bon endroit.<br /><br />
                                Nous avons la Partenariat avec les pharmacie,Hospital privé,Cabinet personnel et Pharmacie aux soins vétérinaires
                            </div>
                        </div>

                        <div className='bg-gray-200 h-max m-4 rounded'>
                            <div onClick={(e) => { Setagric(false); Setmentors(false); Settransport(false); Setprogram(!program); Setsante(false); Setloisir(false); Setreserver(false); Setechange(false); Setvendeurs(false); SetEducation(false); SetshowMotCequoiBTR(false); e.stopPropagation() }} className='flex relative cursor-pointer h-max   px-6 items-center py-4  md:text-[30px]  text-[22px] '>
                                <p className='text-center   max-sm:text-[20px]'> Quels sont les services pour la programmation ?</p>
                                <i class={`bi ${program ? 'rotate-90' : 'rotate-0'} absolute right-2 transition-all  bi-arrow-right-circle-fill text-[20px] ml-4`}></i>
                            </div>
                            <div className={`${!program ? 'opacity-0 h-0 overflow-hidden py-0 ' : 'opacity-100 h-max py-2'}px-2 transition-all  bg-white   text-gray-700 text-[20px] max-sm:text-[18px] font-serif`} >
                                Ici, vous trouverez une multitude de ressources et d'informations pour vous initier et vous perfectionner dans le domaine de la programmation. Découvrez nos tutoriels, articles et exemples de code pour apprendre les langages de programmation les plus populaires tels que Python, JavaScript, C++, Java, les divers framework et bien d'autres encore.<br /><br />
                                Plongez-vous dans des projets passionnants, explorez les dernières tendances technologiques et élargissez vos compétences en programmation  pour la developemnet des:<br />
                                <div className='pl-4'>
                                    Dévellopement des logiciels<br />Dévellopement de sites web<br />Dévellopement des applications web<br />
                                    Dévellopement des applications mobiles<br />Dévellopement des applications desktop<br />
                                    Internet des objet
                                    <br />Intelligence artificielle<br />Apprentissange automatique<br />Administration des réseaux
                                    <br />Administration des base de donnee<br />
                                    Developpement avec réseax social des entreprises<br /><br />
                                    Nous sommes ravis de vous accompagner dans votre aventure de programmation et de vous aider à atteindre vos objectifs.
                                </div>
                            </div>
                        </div>

                        <div className='bg-gray-200 h-max m-4 rounded'>
                            <div onClick={(e) => { Setagric(false); Setmentors(false); Settransport(!transport); Setprogram(false); Setsante(false); Setloisir(false); Setreserver(false); Setechange(false); Setvendeurs(false); SetEducation(false); SetshowMotCequoiBTR(false); e.stopPropagation() }} className='flex relative cursor-pointer h-max   px-6 items-center py-4  md:text-[30px]  text-[22px] '>
                                <p className='text-center   max-sm:text-[20px]'> Quels sont les services pour le transport ?</p>
                                <i class={`bi ${transport ? 'rotate-90' : 'rotate-0'} absolute right-2 transition-all  bi-arrow-right-circle-fill text-[20px] ml-4`}></i>
                            </div>
                            <div className={`${!transport ? 'opacity-0 h-0 overflow-hidden py-0 ' : 'opacity-100 h-max py-2'}px-2 transition-all  bg-white   text-gray-700 text-[20px] max-sm:text-[18px] font-serif`} >
                                Que vous ayez besoin de vous déplacer en ville, de planifier un voyage ou de connaître les horaires et les itinéraires des transports en commun, nous sommes là pour vous aider. Notre équipe dévouée est prête à répondre à toutes vos questions et à vous fournir les meilleurs conseils pour rendre vos déplacements plus faciles et plus agréables en collaboration avec Compagnie aérienne,Agence transport,Reservations des taxi,Locations des véhicules
                                <br /><br />Nous sommes ravis de vous accompagner dans tous vos déplacements et de vous offrir un service de transport de qualité. Merci de nous faire confiance et bon voyage !
                            </div>
                        </div>

                        <div className='bg-gray-200 h-max m-4 rounded'>
                            <div onClick={(e) => { Setagric(false); Setmentors(!mentors); Settransport(false); Setprogram(false); Setsante(false); Setloisir(false); Setreserver(false); Setechange(false); Setvendeurs(false); SetEducation(false); SetshowMotCequoiBTR(false); e.stopPropagation() }} className='flex relative cursor-pointer h-max   px-6 items-center py-4  md:text-[30px]  text-[22px] '>
                                <p className='text-center   max-sm:text-[20px]'> Quels sont les services pour les mentors ?</p>
                                <i class={`bi ${mentors ? 'rotate-90' : 'rotate-0'} absolute right-2 transition-all  bi-arrow-right-circle-fill text-[20px] ml-4`}></i>
                            </div>
                            <div className={`${!mentors ? 'opacity-0 h-0 overflow-hidden py-0 ' : 'opacity-100 h-max py-2'}px-2 transition-all  bg-white   text-gray-700 text-[20px] max-sm:text-[18px] font-serif`} >
                                Que vous soyez un étudiant aspirant entrepreneur ou un professionnel en reconversion, notre option Mentors en Développement Entrepreneurial vous permettra de bénéficier d'un accompagnement personnalisé pour concrétiser vos idées, développer vos compétences entrepreneuriales et atteindre vos objectifs     </div>
                        </div>

                        <div className='bg-gray-200 h-max m-4 rounded'>
                            <div onClick={(e) => { Setagric(!agric); Setmentors(false); Settransport(false); Setprogram(false); Setsante(false); Setloisir(false); Setreserver(false); Setechange(false); Setvendeurs(false); SetEducation(false); SetshowMotCequoiBTR(false); e.stopPropagation() }} className='flex relative cursor-pointer h-max   px-6 items-center py-4  md:text-[30px]  text-[22px] '>
                                <p className='text-center   max-sm:text-[20px]'> Quels sont les services pour les agriculture et elevages ?</p>
                                <i class={`bi ${agric ? 'rotate-90' : 'rotate-0'} absolute right-2 transition-all  bi-arrow-right-circle-fill text-[20px] ml-4`}></i>
                            </div>
                            <div className={`${!agric ? 'opacity-0 h-0 overflow-hidden py-0 ' : 'opacity-100 h-max py-2'}px-2 transition-all  bg-white   text-gray-700 text-[20px] max-sm:text-[18px] font-serif`} >
                                Que vous soyez un agriculteur chevronné, un éleveur passionné ou simplement intéressé par le domaine de l'agriculture et de l'élevage, vous êtes au bon endroit <br />
                                Si vous êtes intéressé par les prix des produits agricoles, vous êtes au bon endroit. Nous vous fournissons les informations les plus récentes sur les prix des différentes cultures, des produits laitiers, de la viande et bien plus encore.
                            </div>
                        </div>
                    </div>
                </div>


            </div >
            <div className=' max-sm:w-full flex-col sm:w-[90%] p-2 flex justify-center items-center h-max '>
                <p className='text-[23px] max-sm:text-[17px] text-gray-400 w-full text-center'>
                    Votre opinion est précieuse pour nous et nous sommes impatients de connaître vos idées<br />
                    N'hésitez pas à partager vos suggestions pour améliorer notre service et rendre votre expérience encore meilleure. Votre contribution compte beaucoup pour nous, et nous vous remercions de prendre le temps de nous aider à progresser.
                </p>
                <Link to='/Visit/Contact' class="bi bi-chat-right-text-fill text-[30px] bg-blue-700 text-white px-4 py-2 rounded-full cursor-pointer hover:bg-white hover:text-blue-700 border-4 transition-all duration-500 border-dotted border-white hover:border-blue-800 hover:border-dotted"></Link>
            </div>
        </>
    )
}

export default QuestionPropose
