import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function NavBarsOngletMobille({ showManue }) {
    const { pathname } = useLocation();

    const Ventes = /^\/User\/Ventes.*/
    const Dettes = /^\/User\/Dettes.*/
    const Depenses = /^\/User\/Depenses.*/
    const Bonus = /^\/User\/Bonus.*/
    const Jours = /^\/User\/Jours.*/

    const AfficheProduct = /^\/User\/AffichProduct.*/
    const ManageProduct = /^\/User\/ProduitManager.*/

    const AddProduct = /^\/User\/AjouProduct.*/
    const Orders = /^\/User\/Orders.*/
    return (
        <div className={` ${showManue ? 'right-0' : '-right-full'} transition-all duration-700 z-[100] top-[10vh]  bg-gray-200 w-max flex  h-full   boder items-center  fixed`}>
            <div className={` w-[100%] h-full flex justify-around text-[15px]`}>
                <div className=" flex p-1 w-[100%] flex-col">
                    <div title="Acceuil" class="flex">

                        {pathname == "/" || AfficheProduct.test(pathname) ? (
                            <Link
                                to="/"
                                class="no-underline text-white mr-1 bg-blue-500 md:text-white px-4 w-full flex items-center py-2 "
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                                    <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z" />
                                </svg>
                                <span class=" text-center ml-4">
                                    Statistique
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/"
                                class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-blue-400  "
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-bar-chart-fill" viewBox="0 0 16 16">
                                    <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z" />
                                </svg>

                                <span class=" text-center ml-4">
                                    Statistique
                                </span>
                            </Link>

                        )}
                    </div>

                    <div title="Ventes" class="flex ">
                        {Ventes.test(pathname) ? (
                            <Link
                                to="/User/Ventes"
                                class="no-underline text-white mr-1 bg-blue-500 md:text-white px-4 w-full flex items-center py-2 "
                            >
                                <i class="bi bi-cloud-minus-fill text-[22px] mr-4"></i>
                                <span class="w-full text-left">
                                    Ventes
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/User/Ventes"
                                class="no-underline mr-1 text-gray-500  w-full flex items-center hover:text-white px-4  py-2 hover:bg-blue-400  "
                            >
                                <i class="bi bi-cloud-minus-fill text-[22px] mr-4"></i>
                                <span class="w-full text-left">
                                    Ventes
                                </span>
                            </Link>

                        )}
                    </div>

                    <div title="Dettes" class="flex ">
                        {Dettes.test(pathname) ? (
                            <Link
                                to="/User/Dettes"
                                class="no-underline text-white mr-1 bg-blue-500 md:text-white px-4 w-full flex items-center py-2 "
                            >

                                <i class="bi bi-credit-card-2-front-fill text-[22px] mr-4"></i>
                                <span class="w-full text-left">
                                    Dettes
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/User/Dettes"
                                class="no-underline mr-1 text-gray-500  w-full flex items-center hover:text-white px-4  py-2 hover:bg-blue-400  "
                            >
                                <i class="bi bi-credit-card-2-front-fill text-[22px] mr-4"></i>
                                <span class="w-full text-left">
                                    Dettes
                                </span>
                            </Link>

                        )}
                    </div>


                    <div title=" Depense" class="flex ">
                        {Depenses.test(pathname) ? (
                            <Link
                                to="/User/Depenses"
                                class="no-underline text-white mr-1 bg-blue-500 md:text-white px-4 w-full flex items-center py-2 "
                            >

                                <i class="bi bi-layers-fill text-[22px] mr-4"></i>
                                <span class="w-full text-left">
                                    Depense
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/User/Depenses"
                                class="no-underline mr-1 text-gray-500  w-full flex items-center hover:text-white px-4  py-2 hover:bg-blue-400  "
                            >
                                <i class="bi bi-layers-fill text-[22px] mr-4"></i>
                                <span class="w-full text-left">
                                    Depense
                                </span>
                            </Link>

                        )}
                    </div>

                    <div title="Bonus" class="flex ">
                        {Bonus.test(pathname) ? (
                            <Link
                                to="/User/Bonus"
                                class="no-underline text-white mr-1 bg-blue-500 md:text-white px-4 w-full flex items-center py-2 "
                            >

                                <i class="bi bi-cash-coin text-[22px] mr-4"></i>
                                <span class="w-full text-left">
                                    Bonus
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/User/Bonus"
                                class="no-underline mr-1 text-gray-500  w-full flex items-center hover:text-white px-4  py-2 hover:bg-blue-400  "
                            >
                                <i class="bi bi-cash-coin text-[22px] mr-4"></i>
                                <span class="w-full text-left">
                                    Bonus
                                </span>
                            </Link>

                        )}
                    </div>


                    <div title="Action du jour" class="flex ">
                        {Jours.test(pathname) ? (
                            <Link
                                to="/User/Jours"
                                class="no-underline text-white mr-1 bg-blue-500 md:text-white px-4 w-full flex items-center py-2 "
                            >

                                <i class="bi bi-activity text-[22px] mr-4"></i>
                                <span class="w-full text-left">
                                    Action du jour
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/User/Jours"
                                class="no-underline mr-1 text-gray-500  w-full flex items-center hover:text-white px-4  py-2 hover:bg-blue-400  "
                            >
                                <i class="bi bi-activity text-[22px] mr-4"></i>
                                <span class="w-full text-left">
                                    Action du jour
                                </span>
                            </Link>

                        )}
                    </div>

                    <div title="Gérer les produits" class="flexz">
                        {ManageProduct.test(pathname) ? (
                            <Link
                                to="/User/ProduitManager"
                                class="no-underline text-white mr-1 bg-blue-500 md:text-white px-4 w-full flex items-center py-2 "
                            >
                                <i className="bi bi-file-earmark-ppt text-[22px] mr-4"></i>
                                <span class="w-full text-left">
                                    Gérer la produit
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/User/ProduitManager"
                                class="no-underline mr-1 text-gray-500  w-full flex items-center hover:text-white px-4  py-2 hover:bg-blue-400  "
                            >
                                <i class="bi bi-window-split text-[22px] mr-4"></i>

                                <span class="w-full text-left">
                                    Gérer la produit
                                </span>
                            </Link>

                        )}
                    </div>
                    <div title="Ajouter un produit" class="flex">
                        {AddProduct.test(pathname) ? (
                            <Link
                                to="/User/AjouProduc"
                                class="no-underline text-white bg-blue-500 md:text-white px-4 w-full flex items-center py-2 "
                            >
                                <i class="bi bi-plus-circle-dotted mr-4 text-[22px]"></i>
                                <span class="w-full text-left">Ajouter un produit</span>
                            </Link>
                        ) : (
                            <Link
                                to="/User/AjouProduct"
                                class="no-underline mr-1 text-gray-500  w-full flex items-center hover:text-white px-4  py-2 hover:bg-blue-400  "
                            >
                                <i class="bi bi-plus-circle-dotted mr-4 text-[22px]"></i>
                                <span class="w-full text-left">Ajouter un produit</span>
                            </Link>
                        )}
                    </div>

                    <div title="Orders" class="flex ">
                        {Orders.test(pathname) ? (
                            <Link
                                to="/User/Orders"
                                class="no-underline text-white bg-blue-500 md:text-white px-4 w-full flex items-center py-2 "
                            >
                                <i class="bi bi-list-ul text-[22px]  mr-4"></i>
                                <span class="w-full text-left">Orders</span>
                            </Link>
                        ) : (
                            <Link

                                to="/User/Orders"
                                class="no-underline mr-1 text-gray-500  w-full flex items-center hover:text-white px-4  py-2 hover:bg-blue-400  "
                            >
                                <i class="bi bi-list-ul text-[22px]  mr-4"></i>

                                <span class="w-full text-left">Orders</span>
                            </Link>
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default NavBarsOngletMobille