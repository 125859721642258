import React from 'react'
import ConfirmPaymentSeller from './ConfirmPaymentSeller'
function ContainerContentPaymentSeller() {
  return (
    <>
      <ConfirmPaymentSeller />
    </>
  )
}

export default ContainerContentPaymentSeller
