/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { FadeLoader } from 'react-spinners'
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage';

function AvisAdmin() {
    const [moblie, GetMobile] = useState(window.innerWidth < 852)
    const [mobile, Setmobile] = useState(window.innerWidth < 380)

    const [RubriqueId, setRubriqueId] = useState(null);
    const [cancelongRubriqueId, setcancelongRubriqueId] = useState(null);
    const [loading, SetLoading] = useState(true)

    useEffect(() => {
        const HundleSize = () => {
            GetMobile(window.innerWidth < 852)
            Setmobile(window.innerWidth < 380)
        }
        window.addEventListener('resize', HundleSize)
        return () => window.removeEventListener('resize', HundleSize)

    }, [])

    const [AvisData, setAvisData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalElements, setTotalElements] = useState(0)
    useEffect(() => {
        axios.get(`https://btrproject.burundientempsreel.com/avis/all?page=${currentPage}&pageSize=${pageSize}`).then((response) => {
            setAvisData(response.data.avis);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalAvis);
            setTimeout(() => {
                SetLoading(false)
            }, 1000);
        }).catch((err) => {

            console.error('Erreur lors de la récupération des avis :', err.message);
        })
    }, [pageSize, currentPage])
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const getRange = () => {
        const from = (currentPage - 1) * pageSize + 1;
        const to = Math.min(currentPage * pageSize, totalElements);
        return `${from} à ${to} avis sur ${totalElements}`;
    };
    function getTimeDifference(createdAt) {
        const currentDate = new Date();
        const createdDate = new Date(createdAt);
        const timeDifference = currentDate.getTime() - createdDate.getTime();
        const secondsDifference = Math.floor(timeDifference / 1000);
        const minutesDifference = Math.floor(secondsDifference / 60);
        const hoursDifference = Math.floor(minutesDifference / 60);
        const daysDifference = Math.floor(hoursDifference / 24);
        const monthsDifference = Math.floor(daysDifference / 30);
        const yearsDifference = Math.floor(monthsDifference / 12);

        if (yearsDifference > 0) {
            return `Il y a ${yearsDifference} ${yearsDifference === 1 ? 'an' : 'ans'}`;
        } else if (monthsDifference > 0) {
            return `Il y a ${monthsDifference} ${monthsDifference === 1 ? 'mois' : 'mois'}`;
        } else if (daysDifference > 0) {
            return `Il y a ${daysDifference} ${daysDifference === 1 ? 'jour' : 'jours'}`;
        } else if (hoursDifference > 0) {
            return `Il y a ${hoursDifference} ${hoursDifference === 1 ? 'heure' : 'heures'}`;
        } else if (minutesDifference > 0) {
            return `Il y a ${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'}`;
        } else {
            return `Il y a quelques instants`;
        }
    }

    const handleConfirmation = async (avisId) => {
        try {
            setcancelongRubriqueId(avisId)
            const result = await Swal.fire({
                title: "Confirmation",
                text: "Vous voulez annuler ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
            });

            if (result.isConfirmed) {
                // Si l'utilisateur a confirmé la suppression
                axios.delete(`https://btrproject.burundientempsreel.com/avis/${avisId}`)
                    .then((res) => {
                        toast.success('Vous avez annulé avec succès l\' Avis!');
                        // Supprimer l'élément correspondant de la liste
                        setAvisData(AvisData.filter(Avis => Avis.id !== avisId));
                    })
                    .catch((err) => {
                        toast.warn('Erreur de l\'annulation de l\'avis');
                        setcancelongRubriqueId(null)
                    }).finally(() => {
                        setcancelongRubriqueId(null)
                    });
            } else {
                console.log('Cancelled');
                setcancelongRubriqueId(null)
            }
        } catch (error) {
            console.error("Erreur lors de la gestion de la confirmation", error);
            setRubriqueId(null)
        }
    };



    const handleAccepter = async (avisId) => {

        try {
            setRubriqueId(avisId)
            const result = await Swal.fire({
                title: "Confirmation",
                text: "Vous Voulez activé ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
            });

            if (result.isConfirmed) {
                axios.put(`https://btrproject.burundientempsreel.com/avis/activation/${avisId}`)
                    .then((res) => {
                        toast.success('Vous avez activé l\'avis !');
                        setPageSize(pageSize + 1);
                    })
                    .catch((err) => {
                        toast.warn('Erreur d\'activé l\'avis !');
                        setRubriqueId(null)
                    }).finally(() => {
                        setRubriqueId(null)
                    });
            } else {
                console.log('Cancelled');
                setRubriqueId(null)
            }

        } catch (error) {
            console.log('Error:', error.message);
            setRubriqueId(null)
        }
    };

    return (
        <div className='w-full h-max flex justify-center '>
            {loading && <SpinnerDemarage />}
            <div className='flex flex-col bg-gray-200 w-[95%] rounded-md p-2 mt-4'>
                <div className='text-gray-600  max-sm:text-[0.8rem] text-2xl sm:py-4 text-center border-b border-white '>Les avis des agents sur notre service très rapide</div>
                <div class="w-full mx-auto flex flex-wrap justify-between p-[1px] sm:p-4">
                    {AvisData.map((avis, index) => (
                        <div key={index} class={`bg-white shadow-md  rounded-md p-3 sm:p-6 mb-4 ${moblie ? 'w-full h-max' : 'w-[48%]'}`}>
                            <div class="flex items-center mb-4">
                                <div className='sm:w-10 sm:h-10 w-7 h-7 border border-fuchsia-600 rounded-full mr-2 sm:mr-4 overflow-hidden'>
                                    {avis.fashanya && avis.fashanya.echangeur && <img class=" w-full h-full object-contain" src={`https://btrproject.burundientempsreel.com/uploads/photosechange/${avis.fashanya.echangeur.profil}`} />}
                                </div>
                                <div>
                                    <p class="font-bold text-gray-800 max-sm:text-[12px]">{avis.fashanya && avis.fashanya.echangeur && `${avis.fashanya.echangeur.nom} ${avis.fashanya.echangeur.prenom}`}</p>
                                    <div className='flex items-center'>
                                        <p class="text-gray-600  sm:text-sm text-[10px] rounded-xl">{avis.serviceQuality}</p>
                                        <div className='flex justify-start  relative'>
                                            {avis.status == 1 ? (
                                                <div className={`bg-blue-300 p-[3px]  rounded-full  m-1 cursor-default  `}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi text-blue-800 font-extrabold max-sm:w-3 max-sm:h-3  bi-check2-circle" viewBox="0 0 16 16">
                                                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                                                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                                                    </svg>
                                                </div>
                                            ) : (
                                                <div className={`bg-red-500 m-1  cursor-default rounded text-white`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle max-sm:w-3 max-sm:h-3 " viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                                    </svg>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>

                                <div class="flex items-center ml-auto text-blue-800 font-semibold">
                                    <svg class="w-4 h-4 fill-current  mr-1" viewBox="0 0 20 20">
                                        <path d="M10 18a8 8 0 110-16 8 8 0 010 16zm0-2a6 6 0 100-12 6 6 0 000 12zm0-4a1 1 0 110-2 1 1 0 010 2z" />
                                    </svg>
                                    <p class="text-sm max-sm:text-[8px]"> {getTimeDifference(avis.createdAt)}</p>
                                </div>
                            </div>
                            <p class={`text-gray-700 max-sm:text-[10px]`}>{avis.content}</p>

                            <div className='flex justify-end'>
                                <div className='flex justify-end mt-2 relative '>
                                    {avis.status == 1 ? (
                                        cancelongRubriqueId === avis.id ? (
                                            <div disabled className={`relative cursor-no-drop  flex justify-center items-center rounded text-white`}>

                                                {mobile ?
                                                    <div className='absolute bg-transparent pointer-events-none top-[30%] left-[25%]  w-full h-full flex justify-center items-center z-50'>

                                                        <FadeLoader
                                                            color="rgb(255, 255, 255)"
                                                            height={10}
                                                            margin={-10}
                                                            radius={100}
                                                            speedMultiplier={1}
                                                            width={1}
                                                        />
                                                    </div>
                                                    :
                                                    <div className='absolute bg-transparent pointer-events-none top-[20%] left-[15%]  w-full h-full flex justify-center items-center z-50'>
                                                        <FadeLoader
                                                            color="rgb(255, 255, 255)"
                                                            height={10}
                                                            margin={-9}
                                                            radius={100}
                                                            speedMultiplier={1}
                                                            width={1}
                                                        />
                                                    </div>
                                                }

                                                <button onClick={() => handleConfirmation(avis.id)} className={`bg-red-800 pointer-events-none  m-1 ${mobile ? 'py-[2px] px-[4px] h-max text-[10px]' : 'py-1 px-2 '} cursor-pointer rounded text-gray-300`}>Annuler</button>
                                            </div>
                                        ) : (
                                            <>
                                                <button onClick={() => handleConfirmation(avis.id)} className={`bg-red-700 m-1 ${mobile ? 'py-[2px] px-[4px] h-max text-[10px]' : 'py-1 px-2 '} cursor-pointer rounded text-white`}>Annuler</button>
                                            </>
                                        )
                                    ) : (
                                        <>
                                            {RubriqueId === avis.id ? (
                                                <div disabled className={`relative cursor-no-drop  flex justify-center items-center rounded text-white`}>
                                                    {mobile ?
                                                        <div className='absolute bg-transparent pointer-events-none top-[30%] left-[25%]  w-full h-full flex justify-center items-center z-50'>

                                                            <FadeLoader
                                                                color="rgb(255, 255, 255)"
                                                                height={10}
                                                                margin={-10}
                                                                radius={100}
                                                                speedMultiplier={1}
                                                                width={1}
                                                            />
                                                        </div>
                                                        :
                                                        <div className='absolute bg-transparent pointer-events-none top-[20%] left-[15%]  w-full h-full flex justify-center items-center z-50'>

                                                            <FadeLoader
                                                                color="rgb(255, 255, 255)"
                                                                height={10}
                                                                margin={-9}
                                                                radius={100}
                                                                speedMultiplier={1}
                                                                width={1}
                                                            />
                                                        </div>
                                                    }
                                                    <button disabled onClick={() => handleAccepter(avis.id)} className={`bg-blue-900 m-1 ${mobile ? 'py-[2px] px-[4px] h-max text-[10px]' : 'py-1 px-2 '} pointer-events-none rounded text-gray-400`}>Valider</button>
                                                </div>
                                            ) : (
                                                <>
                                                    <button onClick={() => handleAccepter(avis.id)} className={`bg-blue-700 m-1 ${mobile ? 'py-[2px] px-[4px] h-max text-[10px]' : 'py-1 px-2 '} cursor-pointer rounded text-white`}>Valider</button>
                                                </>
                                            )}


                                            {cancelongRubriqueId === avis.id ? (
                                                <div disabled className={`relative cursor-no-drop  flex justify-center items-center rounded text-white`}>
                                                    {mobile ?
                                                        <div className='absolute bg-transparent pointer-events-none top-[30%] left-[25%]  w-full h-full flex justify-center items-center z-50'>

                                                            <FadeLoader
                                                                color="rgb(255, 255, 255)"
                                                                height={10}
                                                                margin={-10}
                                                                radius={100}
                                                                speedMultiplier={1}
                                                                width={1}
                                                            />
                                                        </div>
                                                        :
                                                        <div className='absolute bg-transparent pointer-events-none top-[20%] left-[15%]  w-full h-full flex justify-center items-center z-50'>

                                                            <FadeLoader
                                                                color="rgb(255, 255, 255)"
                                                                height={10}
                                                                margin={-9}
                                                                radius={100}
                                                                speedMultiplier={1}
                                                                width={1}
                                                            />
                                                        </div>
                                                    }
                                                    <button disabled onClick={() => handleConfirmation(avis.id)} className={`bg-red-700 m-1 ${mobile ? 'py-[2px] px-[4px] h-max text-[10px]' : 'py-1 px-2 '} pointer-events-none rounded text-white`}>Annuler</button>
                                                </div>
                                            ) : (
                                                <>
                                                    <button onClick={() => handleConfirmation(avis.id)} className={`bg-red-700 m-1 ${mobile ? 'py-[2px] px-[4px] h-max text-[10px]' : 'py-1 px-2 '} cursor-pointer rounded text-white`}>Annuler</button>
                                                </>
                                            )}

                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                    ))}

                    <div className='flex justify-center flex-col items-center w-full'> {AvisData.length > 0 && (
                        <>
                            <div className="mt-4 flex items-center justify-center">
                                <div className="flex items-center">
                                    <button
                                        disabled={currentPage === 1}
                                        onClick={handlePreviousPage}
                                        className={`mx-1 px-3 py-1 rounded bg-blue-200 font-semibold text-blue-700 ${currentPage === 1 && 'cursor-no-drop opacity-30'}`}

                                    >
                                        Précédent
                                    </button>
                                    {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                                        <button
                                            key={pageNumber}
                                            onClick={() => handlePageChange(pageNumber)}
                                            className={`mx-1 [x-1] sm:px-3 py-1 rounded ${currentPage === pageNumber ? 'bg-blue-700 text-white' : 'bg-gray-300 text-gray-700'}`}
                                        >
                                            {pageNumber}
                                        </button>
                                    ))}
                                    <button
                                        disabled={currentPage === totalPages}
                                        onClick={handleNextPage}
                                        className={`mx-1 px-3 py-1 rounded bg-blue-200 font-semibold text-blue-700 ${currentPage === totalPages && 'cursor-no-drop opacity-30'}`}

                                    >
                                        Suivant
                                    </button>
                                </div>
                            </div>

                            <div className="mt-4 flex items-center justify-center">
                                <p className="text-gray-600">{getRange()}</p>
                            </div>
                        </>
                    )}
                    </div>


                </div>
            </div>
        </div>
    )
}

export default AvisAdmin
