const UnitialState = {
  Carts: [],
};
export const CartsReducer = (State = UnitialState, action) => {
  switch (action.type) {
    case "Add_Cart":
      const { items, quantity } = action.payload;
      const itemesIndex = State.Carts.findIndex(
        (iteam) => iteam.id === items.id
      );

      if (itemesIndex >= 0) {
        State.Carts[itemesIndex].quantity += quantity;
      } else {
        const temp = { ...items, quantity: quantity };

        return {
          ...State,
          Carts: [...State.Carts, temp],
        };
      }

    // eslint-disable-next-line no-fallthrough
    case "Remove_Cart":
      const data = State.Carts.filter((el) => el.id !== action.payload);
      return {
        ...State,
        Carts: data,
      };

    // eslint-disable-next-line no-fallthrough
    case "Remove_One":
      const itemes_On_Index = State.Carts.findIndex(
        (ev) => ev.id === action.payload.id
      );
      console.log(itemes_On_Index)
      if (State.Carts[itemes_On_Index].quantity >= 1) {
        State.Carts[itemes_On_Index].quantity--;
        return {
          ...State,
          Carts: [...State.Carts],
        };
      } else if (State.Carts[itemes_On_Index].quantity === 0) {
        const data = State.Carts.filter((el) => el.id !== action.payload);

        return {
          ...State,
          Carts: [...State.Carts, data],
        };
      }
    // eslint-disable-next-line no-fallthrough

    case "Clear_Cart":
      return {
        ...State,
        Carts: [],
      };
    default:
      return State;
  }
};
