import React from "react";
import Product_List from "./Product_List";
function Content_Container({ SetSignPopUpSeller, isVisitorLogged, SetLoginPopUp, isdivScroll }) {
  return (
    <>
      {/* <!-- container --> */}
      <div class="mx-auto  text-gray-700 overflow-hidden">
        {/*    // grid md:grid-cols-4 grid-cols-2 gap-3 */}
        <div class=" items-start">
          <Product_List isdivScroll={isdivScroll} SetSignPopUpSeller={SetSignPopUpSeller} isVisitorLogged={isVisitorLogged} SetLoginPopUp={SetLoginPopUp} />
        </div>
      </div>
    </>
  );
}

export default Content_Container;
